import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { Page } from '@accedo/vdkweb-tv-ui';
import styles from './GuestFavorite.scss';
import { toggleLoginSpinner } from '#/redux/modules/spinner/actions';
import { getNoRegistration } from '#/redux/modules/accedoOne/selectors';
import authStyles from '#/components/AuthFlow/authflow.scss';
import { authScreenNames, signupStates } from '#/config/constants';
import { authFlowRoutes } from '#/utils/routes';
import { logout } from '#/redux/modules/identity/identityGuest/actions';
import { actions } from '#/redux/modules/onboarding/actions';

// Focus handling
import { FocusDiv, FocusAnchor, FocusButton } from '#/widgets/ui';
import { HEADER, HOME, FAVORITES_PAGE } from '#/utils/navigationHelper';

const GuestFavorite = () => {
  const dispatch = useDispatch();
  const { SIGN_IN, REGISTER } = authScreenNames;
  const noRegistration = useSelector(getNoRegistration);
  const {
    favoritesBlocked: { imageUrl, info1, info2, title },
  } = noRegistration;
  const infoBlocks = info2.split('·');
  useEffect(() => {
    requestAnimationFrame(() => {
      dispatch(toggleLoginSpinner(false));
    });
  }, []);
  const switchToCreateProfile = () => {
    dispatch(
      actions.setSignupState({
        signupState: signupStates.CREATE_ACCOUNT,
      }),
    );
    dispatch(logout(authFlowRoutes.ACCOUNT_CREATION));
  };
  return (
    <Page
      nav={{
        id: HOME.PAGE,
        forwardFocus: FAVORITES_PAGE.CONTAINER,
      }}
    >
      <FocusDiv
        nav={{
          id: FAVORITES_PAGE.CONTAINER,
          forwardFocus: FAVORITES_PAGE.SIGN_UP_LINK,
        }}
        className={styles.container}
      >
        <div className={styles.imgBox}>
          <img src={imageUrl} alt="Logo" />
        </div>
        <div className={styles.textBlock}>
          <h2 className={styles.guestLabel}>{title}</h2>
          <p className={styles.guestDesc}>{info1}</p>
          <div className={styles.textBlockList}>
            <ul>
              {infoBlocks
                .filter(infoText => infoText.length > 1)
                .map(infoBlock => (
                  <li>{infoBlock}</li>
                ))}
            </ul>
          </div>
        </div>
        <div className={cx(authStyles.buttonsGroup, styles.buttonsGroup)}>
          <FocusButton
            className={styles.signUpBtn}
            onClick={switchToCreateProfile}
            nav={{
              id: FAVORITES_PAGE.SIGN_UP_LINK,
              nextdown: FAVORITES_PAGE.SIGN_IN_LINK,
              nextleft: HEADER.MENU_ITEMS.FAVORITES,
            }}
            aria-labelledby={`${FAVORITES_PAGE.CONTAINER}`}
          >
            {REGISTER}
          </FocusButton>
          <div className={styles.signInFooter}>
            Have an account?
            <FocusAnchor
              nav={{
                id: FAVORITES_PAGE.SIGN_IN_LINK,
                nextup: FAVORITES_PAGE.SIGN_UP_LINK,
                nextleft: HEADER.MENU_ITEMS.FAVORITES,
              }}
              onClick={() => dispatch(logout(authFlowRoutes.SIGN_IN))}
              className={styles.signIn}
            >
              {SIGN_IN}
            </FocusAnchor>
          </div>
        </div>
      </FocusDiv>
    </Page>
  );
};
export default GuestFavorite;
