import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import screenfull from 'screenfull';
import { actions as onboardingActions } from '#/redux/modules/onboarding/actions';
import { signupStates } from '#/config/constants';
import routes, { authFlowRoutes, mainRoutes } from '#/utils/routes';
import { logout } from '#/redux/modules/identity/identityGuest/actions';
import { actions as identityActions } from '#/redux/modules/identity/actions';
import { toggleLoginSpinner } from '#/redux/modules/spinner/actions';
import { getParentMode } from '#/redux/modules/identity/selectors';
import { isGuestMode } from '#/redux/modules/user/selectors';
import { isPortable, isSmallScreen } from '#/utils/componentStyleConfig';

const Route = ({ content, location, history }) => {
  const dispatch = useDispatch();
  const checkOrientation = window?.matchMedia('(orientation: portrait)');

  const HandleResize = useCallback(() => {
    if (checkOrientation?.matches) {
      return window?.innerHeight > window?.innerWidth;
    }
    return true;
  }, []);
  window?.addEventListener('resize', HandleResize);

  useEffect(() => {
    const event = new Event('change', { bubbles: true });
    checkOrientation.dispatchEvent(event);
  }, [
    location.pathname,
    window?.screen?.orientation,
    screenfull?.isFullscreen,
    window?.innerHeight,
    window?.screen.height,
    HandleResize,
  ]);
  checkOrientation.addEventListener('change', evt => {
    const modalPopup =
      document.getElementById('LandscapeModal') ||
      document.getElementsByClassName(
        'LandscapeModal__landscape-container___Pljxf',
      )[0] ||
      {};
    if (
      evt.matches ||
      !(isPortable() && isSmallScreen()) ||
      screenfull?.isFullscreen ||
      location.pathname.includes('/player') ||
      location.pathname.includes('/livetv') ||
      window?.innerHeight > window?.innerWidth ||
      !HandleResize()
    ) {
      modalPopup.style.display = 'none';
    } else {
      modalPopup.style.display = 'flex';
    }
  });

  const lock = useRef(false);

  const guestMode = useSelector(isGuestMode);
  const parentMode = useSelector(getParentMode);

  // Added this listener to handle the back button from the browsers CUSTSUPP-21092
  // Also add "push" flag to <Redirect> to prevent replace action when redirecting to MAIN/HOME and ROOT routes.
  const onLoad = () => {
    const { pathname } = window.location;
    if (pathname === mainRoutes.HOME) {
      lock.current = false;
    } else {
      lock.current = true;
    }
  };

  window.addEventListener('popstate', () => {
    const { pathname } = window.location;
    if (
      (pathname === mainRoutes.HOME || pathname === routes.HOME) &&
      !lock.current
    ) {
      if (guestMode) {
        dispatch(
          onboardingActions.setSignupState({
            signupState: signupStates.GUEST_WELCOME,
          }),
        );
        dispatch(logout(authFlowRoutes.GUEST_PROFILE));
      } else {
        if (parentMode) {
          dispatch(identityActions.setPin(null));
        }
        dispatch(identityActions.setParentMode(true));
        dispatch(onboardingActions.setSelectedProfile(null));
        history.push(routes.PROFILE_SELECTION);
      }
    }
  });

  useEffect(() => {
    // spinner shows up for main routes only
    if (location.pathname.match(mainRoutes.ROOT)) {
      dispatch(toggleLoginSpinner(true));
    } else if (!location.pathname.match(mainRoutes.ROOT)) {
      requestAnimationFrame(() => {
        dispatch(toggleLoginSpinner(false));
      });
    }
  }, [location.pathname]);

  return <div onLoad={onLoad}>{content}</div>;
};

Route.propTypes = {
  content: PropTypes.any,
  location: PropTypes.object,
  history: PropTypes.object,
};

export default withRouter(Route);
