export const noop = () => {};

export const constructString = (template = '', mapObj = {}) => {
  const keys = Object.keys(mapObj) || [];

  let templateStr = template.slice();

  keys.forEach(key => {
    if (mapObj[key]) {
      templateStr = templateStr.replace(`{{${key}}}`, mapObj[key]);
    }
  });

  return templateStr;
};

export const chunkArray = (array = [], size = 3) =>
  array?.reduce((acc, curr) => {
    const { length } = acc;

    const chunk = length > 0 ? acc[length - 1] : acc[length];

    if (!chunk) {
      acc.push([curr]);
    } else if (chunk && chunk.length < size) {
      chunk.push(curr);
    } else {
      acc.push([curr]);
    }

    return acc;
  }, []);
