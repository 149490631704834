import { createActions } from 'redux-actions';
import accedoOneApi from '#/services/accedoOne/api';
import {
  getFAQService,
  getPrivacyPolicyService,
  getHelpService,
  getTextContent,
} from '#/services/settings/settings';

import { getTermsOfServiceUrl, getChildrenPrivacyPolicyUrl } from './selectors';

const SET_CLIENT = 'SET_CLIENT';
const SAVE_ALL_ASSETS = 'SAVE_ALL_ASSETS';
const SAVE_ALL_ENTRIES = 'SAVE_ALL_ENTRIES';
const SAVE_ALL_METADATA = 'SAVE_ALL_METADATA';
const SAVE_SENSICAL_BENEFITS = 'SAVE_SENSICAL_BENEFITS';
const LOAD_SENSICAL_FAQ = 'LOAD_SENSICAL_FAQ';
const LOAD_SENSICAL_HELP = 'LOAD_SENSICAL_HELP';
const LOAD_SENSICAL_PRIVACY_POLICY = 'LOAD_SENSICAL_PRIVACY_POLICY';
const SAVE_TERMS_OF_SERVICE = 'SAVE_TERMS_OF_SERVICE';
const SAVE_CHILDREN_PRIVACY_POLICY = 'SAVE_CHILDREN_PRIVACY_POLICY';

const actionOptions = {
  prefix: 'sensical/accedoOne',
};

export const actions = createActions(
  {},
  SET_CLIENT,
  SAVE_ALL_ENTRIES,
  SAVE_ALL_ASSETS,
  SAVE_ALL_METADATA,
  SAVE_SENSICAL_BENEFITS,
  LOAD_SENSICAL_FAQ,
  LOAD_SENSICAL_HELP,
  LOAD_SENSICAL_PRIVACY_POLICY,
  SAVE_TERMS_OF_SERVICE,
  SAVE_CHILDREN_PRIVACY_POLICY,
  actionOptions,
);

export const saveAllAssets = payload => {
  return {
    type: `${actionOptions.prefix}/${SAVE_ALL_ASSETS}`,
    payload,
  };
};

export const saveAllEntries = payload => {
  return {
    type: `${actionOptions.prefix}/${SAVE_ALL_ENTRIES}`,
    payload,
  };
};

export const saveAllMetadata = payload => {
  return {
    type: `${actionOptions.prefix}/${SAVE_ALL_METADATA}`,
    payload,
  };
};

export const saveSensicalBenefits = payload => {
  return {
    type: `${actionOptions.prefix}/${SAVE_SENSICAL_BENEFITS}`,
    payload,
  };
};

export const loadSensicalFaq = payload => {
  return {
    type: `${actionOptions.prefix}/${LOAD_SENSICAL_FAQ}`,
    payload,
  };
};

export const loadSensicalHelp = payload => {
  return {
    type: `${actionOptions.prefix}/${LOAD_SENSICAL_HELP}`,
    payload,
  };
};

export const loadSensicalPrivacyPolicy = payload => {
  return {
    type: `${actionOptions.prefix}/${LOAD_SENSICAL_PRIVACY_POLICY}`,
    payload,
  };
};

export const loadAllAssets = async (dispatch, getState) => {
  try {
    const res = await accedoOneApi.getAllAssets(
      getState().modules.accedoOne.sessionKey,
    );
    dispatch(saveAllAssets(res));
  } catch (error) {
    // do logic to a possible redispatch
  }
};

export const loadAllEntries = async (dispatch, getState) => {
  try {
    const entries = await accedoOneApi.getAllEntries(
      getState().modules.accedoOne.sessionKey,
    );
    const stateObject = {};
    entries.forEach(item => {
      stateObject[item._meta.id] = item;
    });
    dispatch(saveAllEntries(stateObject));
  } catch (error) {
    console.error(error);
  }
};

export const loadAllMetadata = async (dispatch, getState) => {
  try {
    const metadata = await accedoOneApi.getAllMetadata(
      getState().modules.accedoOne.sessionKey,
    );
    dispatch(saveAllMetadata(metadata));
  } catch (error) {
    console.error(error);
  }
};

export const loadSensicalBenefits = url => async dispatch => {
  try {
    const benefits = await accedoOneApi.getSensicalBenefits(url);
    dispatch(saveSensicalBenefits(benefits));
  } catch (error) {
    console.error(error);
  }
};

export const requestFAQ = async (dispatch, getState) => {
  try {
    const faqUrl = getState().modules?.accedoOne?.metadata?.faqUrl;
    const faq = await getFAQService(faqUrl);
    dispatch(loadSensicalFaq(faq));
  } catch (error) {
    console.error(error);
  }
};

export const requestHelp = async (dispatch, getState) => {
  try {
    const helpUrl = getState().modules?.accedoOne?.metadata?.helpUrl;
    const help = await getHelpService(helpUrl);
    dispatch(loadSensicalHelp(help));
  } catch (error) {
    console.error(error);
  }
};

export const requestPrivacyPolicy = async (dispatch, getState) => {
  try {
    const privacyPolicyUrl = getState().modules?.accedoOne?.metadata
      ?.privacyPolicyUrl;
    const privacyPolicy = await getPrivacyPolicyService(privacyPolicyUrl);
    dispatch(loadSensicalPrivacyPolicy(privacyPolicy));
  } catch (error) {
    console.error(error);
  }
};

export const loadTermsOfService = async (dispatch, getState) => {
  const termsOfServiceUrl = getTermsOfServiceUrl(getState());
  const tosText = await getTextContent(termsOfServiceUrl);
  dispatch(actions.saveTermsOfService(tosText));
};

export const loadChildrenPrivacyPolicy = async (dispatch, getState) => {
  const childrenPrivacyPolicyUrl = getChildrenPrivacyPolicyUrl(getState());
  const text = await getTextContent(childrenPrivacyPolicyUrl);
  dispatch(actions.saveChildrenPrivacyPolicy(text));
};

export const { setClient } = actions;
