import React from 'react';
import RedirectWithQuery from '#/components/App/RedirectWithQuery';

import {
  Home,
  LiveTV,
  TVViewContainer,
  AuthFlowContainer,
  PageNotFound,
  MainRoutesContainer,
} from '#/views';

import PlayerModal from '#/components/PlayerModal/PlayerModal';

import RedirectAuthFlow, {
  SignIn,
  SignUp,
  AgeGate,
  ProfileCreation,
  AccountCreation,
  EmailVerification,
  ForgotPassword,
  CodeScreen,
  TVProfileSelection,
  ProfileSelection,
  EmailVerificationConfirmation,
  ForgotPasswordConfirmation,
  GuestProfile,
} from '#/components/AuthFlow';

import {
  createGuardedRoute,
  CanNavigateAuth,
  CanNavigateUnAuth,
  CanNavigateAgeNotVerified,
  CanNavigateAgeVerified,
  CanNavigateChildContext,
  CanNavigateParentContext,
  CanNavigateDesktop,
  CanNavigateGeoBloking,
  CanNavigateDeeplink,
} from '#/components/GuardedRoute/GuardedRoute';

import DeeplinkRedirect from '#/components/DeeplinkRedirect/DeeplinkRedirect';
import SeeAll from '#/components/SeeAll/SeeAll';
import ContentDetails from '#/components/ContentDetails/ContentDetails';
import ParentZone from '#/components/ParentZone/layout';
import Explore from '#/components/Explore/Explore';
import Favorites from '#/components/Favorites/Favorites';
import { MobileGate, GeoBlockGate } from '#/components';

import routes, {
  authFlowRoutes,
  mainRoutes,
  deeplinkRoutes,
} from '#/utils/routes';
import { isWorkstation } from '#/utils/componentStyleConfig';
import packageJson from '../../package.json';
import { deeplinks } from '#/config/constants';
// import { MediaQueryTest } from '#/widgets/ui';

// Keep all the extra wards and MainRoutesContainer wrapper
// if the link is not detected as a deeplink
const createDeeplinkToMainRoute = (
  routeToDeeplink,
  routeToGuard,
  route,
  component,
  exact = false,
) => ({
  path: routeToDeeplink,
  exact,
  component: createGuardedRoute([
    { canNavigate: CanNavigateDeeplink },
    { canNavigate: CanNavigateAuth },
    { canNavigate: CanNavigateDesktop },
    {
      canNavigate: CanNavigateChildContext,
      component: MainRoutesContainer,
      routesToGuard: [routeToGuard],
    },
  ]),
  routes: [
    {
      path: route,
      component,
    },
  ],
});

/* eslint-disable react/prop-types */
export default [
  {
    path: routes.GEOBLOCK_GATE,
    exact: true,
    component: GeoBlockGate,
  },
  {
    component: createGuardedRoute({
      canNavigate: CanNavigateGeoBloking,
      component: TVViewContainer,
    }),
    routes: [
      // Index handling for Tizen
      {
        path: routes.INDEX,
        exact: true,
        component: () => <RedirectWithQuery push to={mainRoutes.HOME} />,
      },
      {
        path: routes.ROOT,
        exact: true,
        component: () => <RedirectWithQuery push to={mainRoutes.HOME} />,
      },
      // Handle deeplinks
      createDeeplinkToMainRoute(
        deeplinkRoutes.SEE_ALL_EXPLORE,
        mainRoutes.SEE_ALL_EXPLORE,
        routes.SEE_ALL_EXPLORE,
        SeeAll,
        true,
      ),
      {
        path: deeplinkRoutes.DEEPLINK_SEE_ALL_EXPLORE,
        exact: true,
        component: () => (
          <DeeplinkRedirect deeplink={deeplinks.CHANNEL_TOPIC} />
        ),
      },
      createDeeplinkToMainRoute(
        deeplinkRoutes.SEE_ALL_HOME,
        mainRoutes.SEE_ALL_HOME,
        routes.SEE_ALL_HOME,
        SeeAll,
        true,
      ),
      {
        path: deeplinkRoutes.DEEPLINK_SEE_ALL_HOME,
        exact: true,
        component: () => (
          <DeeplinkRedirect deeplink={deeplinks.COLLECTION_VIEWALL} />
        ),
      },
      createDeeplinkToMainRoute(
        deeplinkRoutes.LIVETV,
        mainRoutes.LIVETV,
        routes.LIVETV,
        LiveTV,
        true,
      ),
      {
        path: deeplinkRoutes.DEEPLINK_LIVETV,
        exact: true,
        component: () => <DeeplinkRedirect deeplink={deeplinks.LIVE_TV} />,
      },
      {
        ...createDeeplinkToMainRoute(
          deeplinkRoutes.SHOW_DETAILS,
          mainRoutes.SHOW_DETAILS,
          routes.SHOW_DETAILS,
          ContentDetails,
          true,
        ),
        isCreator: false,
      },
      {
        path: deeplinkRoutes.DEEPLINK_SHOW_DETAILS,
        exact: true,
        component: () => <DeeplinkRedirect deeplink={deeplinks.SHOW_DETAILS} />,
      },
      {
        path: deeplinkRoutes.SHOW_DETAILS_SHORT,
        exact: true,
        component: () => <DeeplinkRedirect deeplink={deeplinks.SHOW_DETAILS} />,
      },
      {
        path: authFlowRoutes.ROOT,
        component: createGuardedRoute({
          canNavigate: CanNavigateUnAuth,
          component: AuthFlowContainer,
        }),
        routes: [
          {
            path: authFlowRoutes.ROOT,
            exact: true,
            component: RedirectAuthFlow,
          },
          {
            path: authFlowRoutes.WELCOME,
            component: () => (
              <RedirectWithQuery to={authFlowRoutes.GUEST_PROFILE} />
            ),
          },
          {
            path: authFlowRoutes.AGE_GATE,
            component: createGuardedRoute({
              canNavigate: CanNavigateAgeNotVerified,
              component: AgeGate,
              fallback: RedirectAuthFlow,
            }),
          },
          {
            path: authFlowRoutes.PROFILE_CREATION,
            component: createGuardedRoute({
              canNavigate: CanNavigateAgeVerified,
              component: ProfileCreation,
              fallback: () => (
                <RedirectWithQuery to={authFlowRoutes.AGE_GATE} />
              ),
            }),
          },
          {
            path: authFlowRoutes.ACCOUNT_CREATION,
            component: createGuardedRoute({
              canNavigate: CanNavigateAgeVerified,
              component: AccountCreation,
              fallback: () => (
                <RedirectWithQuery to={authFlowRoutes.AGE_GATE} />
              ),
            }),
          },
          {
            path: authFlowRoutes.EMAIL_VERIFICATION,
            component: createGuardedRoute({
              canNavigate: CanNavigateAgeVerified,
              component: EmailVerification,
              fallback: () => (
                <RedirectWithQuery to={authFlowRoutes.AGE_GATE} />
              ),
            }),
          },
          {
            path: authFlowRoutes.GUEST_PROFILE,
            component: createGuardedRoute({
              canNavigate: CanNavigateDesktop,
              component: GuestProfile,
            }),
          },
          {
            path: authFlowRoutes.SIGN_IN,
            component: SignIn,
          },
          {
            path: authFlowRoutes.SIGN_UP,
            component: SignUp,
          },
          {
            path: authFlowRoutes.FORGOT_PASSWORD,
            component: ForgotPassword,
          },
          {
            path: authFlowRoutes.FORGOT_PIN,
            component: ForgotPassword,
          },
          {
            path: `${authFlowRoutes.ROOT}/*`,
            component: PageNotFound,
          },
        ],
      },
      {
        path: mainRoutes.ROOT,
        component: createGuardedRoute([
          { canNavigate: CanNavigateAuth },
          { canNavigate: CanNavigateDesktop },
          {
            canNavigate: CanNavigateChildContext,
            component: MainRoutesContainer,
            fallback: () => <RedirectWithQuery to={routes.PROFILE_SELECTION} />,
            routesToGuard: [
              mainRoutes.SEE_ALL_HOME,
              mainRoutes.SEE_ALL_EXPLORE,
              mainRoutes.SEE_ALL_FAVORITES,
              mainRoutes.CREATOR_DETAILS_BASE,
              mainRoutes.SHOW_DETAILS_BASE,
              mainRoutes.HOME,
              mainRoutes.RECENTLY_WATCHED,
            ],
          },
        ]),
        routes: [
          {
            path: mainRoutes.ROOT,
            exact: true,
            component: () => <RedirectWithQuery push to={mainRoutes.HOME} />,
          },
          {
            path: routes.SEE_ALL_HOME,
            component: SeeAll,
          },
          {
            path: routes.SEE_ALL_EXPLORE,
            component: SeeAll,
          },
          {
            path: routes.SEE_ALL_FAVORITES,
            component: SeeAll,
          },
          {
            path: routes.RECENTLY_WATCHED,
            component: SeeAll,
          },
          {
            path: mainRoutes.EXPLORE,
            component: Explore,
          },
          {
            path: mainRoutes.FAVORITES,
            component: Favorites,
          },
          {
            path: mainRoutes.CREATOR_DETAILS,
            component: ContentDetails,
            isCreator: true,
          },
          {
            path: mainRoutes.SHOW_DETAILS,
            component: ContentDetails,
            isCreator: false,
          },
          {
            path: mainRoutes.PARENT_ZONE,
            component: createGuardedRoute({
              canNavigate: CanNavigateParentContext,
              component: ParentZone,
              fallback: () => <RedirectWithQuery push to={routes.HOME} />,
            }),
          },
          {
            path: routes.LIVETV,
            component: LiveTV,
          },
          {
            path: mainRoutes.HOME,
            component: Home,
          },
          {
            path: `${mainRoutes.ROOT}/*`,
            component: PageNotFound,
          },
        ],
      },
      {
        path: routes.EMAIL_VERIFICATION_CONFIRMATION,
        component: EmailVerificationConfirmation,
      },
      {
        path: routes.FORGOT_PASSWORD_CONFIRMATION,
        component: ForgotPasswordConfirmation,
      },
      {
        path: routes.ACTIVATE_SIGN_IN,
        component: CodeScreen,
      },
      {
        path: routes.ACTIVATE,
        component: CodeScreen,
      },
      {
        path: routes.PROFILE_SELECTION,
        component: createGuardedRoute([
          { canNavigate: CanNavigateAuth },
          { canNavigate: CanNavigateDesktop },
          {
            canNavigate: CanNavigateParentContext,
            component: isWorkstation() ? ProfileSelection : TVProfileSelection,
            fallback: () => <RedirectWithQuery push to={routes.HOME} />,
          },
        ]),
      },
      {
        path: routes.PROFILE_ADDITION,
        component: createGuardedRoute([
          { canNavigate: CanNavigateAuth },
          {
            canNavigate: CanNavigateDesktop,
            component: ProfileCreation,
          },
        ]),
      },
      {
        path: routes.HOME,
        component: () => <RedirectWithQuery push to={mainRoutes.HOME} />,
      },
      {
        path: routes.PLAYER,
        component: createGuardedRoute([
          {
            canNavigate: CanNavigateDesktop,
            component: PlayerModal,
          },
        ]),
      },
      {
        path: routes.MOBILE_GATE,
        component: MobileGate,
      },
      {
        path: routes.WELCOME,
        component: () => (
          <RedirectWithQuery to={authFlowRoutes.GUEST_PROFILE} />
        ),
      },
      {
        path: routes.VERSION,
        component: () => (
          <>
            <div
              style={{ background: 'white', height: '100vh', width: '100vw' }}
            >
              {packageJson.version}
              {/* Uncomment following to display media query test */}
              {/* <br />
              <br />
              <MediaQueryTest
                addAny
                queries={[
                  [['hover'], ['none', 'hover']],
                  [['pointer'], ['none', 'coarse', 'fine']],
                ]}
              /> */}
            </div>
          </>
        ),
      },
      {
        path: '*',
        component: PageNotFound,
      },
    ],
  },
];
