import React from 'react';
import classNames from 'classnames';
import { withFocus } from '@accedo/vdkweb-navigation';
import PropTypes from 'prop-types';
import styles from './button.scss';

const Button = ({
  nav,
  isFocused,
  onClick,
  children,
  className,
  classNameFocused,
  isSecondary,
  ariaLabel = '',
}) => (
  <button
    tabIndex={-1}
    id={nav?.id}
    onClick={onClick}
    aria-label={ariaLabel}
    className={classNames(
      isSecondary ? styles.secondaryButton : styles.button,
      className || styles.defaultStyle,
      {
        [classNameFocused || styles.defaultFocused]: isFocused,
      },
    )}
  >
    <div aria-hidden="true">{children}</div>
  </button>
);

Button.propTypes = {
  nav: PropTypes.object,
  isFocused: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.any,
  classNameFocused: PropTypes.bool,
  isSecondary: PropTypes.bool,
  ariaLabel: PropTypes.any,
};

export default withFocus(Button);
