import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';

import { FocusDiv } from '@accedo/vdkweb-tv-ui';
import { DigitEntry, FocusButton, FocusAnchor } from '#/widgets/ui';
import BaseModal from '../../common/BaseModal/BaseModal';
import FocusModal from '../../common/FocusModal/FocusModal';

import { actions as modalActions } from '#/redux/modules/modal/actions';
import { getModalData } from '#/redux/modules/modal/selectors';
import {
  pausePlayback,
  resumePlayback,
} from '#/redux/modules/appPlayer/actions';
import {
  actions as identityActions,
  switchProfile,
  extendTimeAllowance,
} from '#/redux/modules/identity/actions';
import { getParentUserId } from '#/redux/modules/identity/selectors';
import { actions as onboardingActions } from '#/redux/modules/onboarding/actions';

import routes from '#/utils/routes';
import { vindiciaErrors } from '#/config/strings';
import styles from './TimesUpModal.scss';
import TimesUp from '#/static/images/icons/timesup.svg';
import { isVIZIO } from '#/utils/componentStyleConfig';

// XDK focus handling
import { TV_CONTENT_MODAL, VIZIO_KEYBOARD } from '#/utils/navigationHelper';

const { CONTAINER, TV_CONTENT_MODAL_ACTION_BUTTON } = TV_CONTENT_MODAL;
const navMap = {
  CONTAINER: {
    id: CONTAINER,
  },
  DONT_EXTEND: {
    id: 'DONT_EXTEND',
  },
  PIN_ENTRY: {
    id: 'PIN_ENTRY',
  },
  NO_LIMIT: {
    id: 'NO_LIMIT',
  },
  FIFTEEN_MIN: {
    id: '15_MIN',
  },
  THIRTY_MIN: {
    id: '30_MIN',
  },
  SIXTY_MIN: {
    id: '60_MIN',
  },
  NINETY_MIN: {
    id: '90_MIN',
  },
  ACTION_BUTTON: {
    id: TV_CONTENT_MODAL_ACTION_BUTTON,
  },
  KEYBOARD: {
    id: VIZIO_KEYBOARD,
    nextup: TV_CONTENT_MODAL_ACTION_BUTTON,
    parent: CONTAINER,
  },
};

const UnlockWithPin = ({ onPinEntered, pinError, onCancel }) => {
  const [pinValid, setPinValid] = useState(false);
  const [pin, setPin] = useState(null);

  return (
    <BaseModal
      showSaveButton
      saveButtonText="Continue"
      saveButtonDisabled={!pinValid}
      saveAction={() => () => onPinEntered(pin)}
      closeAction={() => () => onCancel()}
      nav={{
        id: navMap.ACTION_BUTTON.id,
        parent: navMap.CONTAINER.id,
        nextup: navMap.DONT_EXTEND.id,
        nextdown: isVIZIO() ? navMap.KEYBOARD.id : null,
      }}
    >
      <FocusDiv
        nav={{
          id: navMap.CONTAINER.id,
          forwardFocus: navMap.PIN_ENTRY.id,
        }}
        className={styles.pinModalContainer}
      >
        <img src={TimesUp} alt="timesup" />
        <h2>Time's up!</h2>
        <p className={styles.description}>
          To keep watching, ask your parent to enter their PIN.{'\n'}
        </p>
        <DigitEntry
          nav={{
            id: navMap.PIN_ENTRY.id,
            parent: navMap.CONTAINER.id,
            nextdown: navMap.DONT_EXTEND.id,
            keyboard: navMap.KEYBOARD,
          }}
          onChange={(valid, value) => {
            setPinValid(valid);
            valid && setPin(value);
          }}
          onSubmit={() => {}}
        />
        <p
          className={cx(styles.error, {
            [styles.visible]: pinError,
          })}
        >
          {pinError}
        </p>
        <span className={styles.forgotpin}>
          <FocusAnchor
            nav={{
              id: navMap.DONT_EXTEND.id,
              parent: navMap.CONTAINER.id,
              nextup: navMap.PIN_ENTRY.id,
              nextdown: navMap.ACTION_BUTTON.id,
            }}
            onClick={onCancel}
            href="#"
          >
            Exit and don't extend time
          </FocusAnchor>
        </span>
      </FocusDiv>
    </BaseModal>
  );
};
UnlockWithPin.propTypes = {
  onPinEntered: PropTypes.func,
  pinError: PropTypes.string,
  onCancel: PropTypes.func,
};

const AddingMoreTime = ({ onTimeSelected, onCancel }) => {
  return (
    <BaseModal
      showSaveButton={false}
      closeAction={() => () => onCancel()}
      nav={{
        id: navMap.ACTION_BUTTON.id,
        parent: navMap.CONTAINER.id,
        nextup: navMap.NINETY_MIN.id,
      }}
    >
      <FocusDiv
        className={styles.pinModalContainer}
        nav={{
          id: navMap.CONTAINER.id,
          forwardFocus: navMap.NO_LIMIT.id,
        }}
      >
        <img src={TimesUp} alt="timesup" />
        <h2>Adding more time</h2>
        <div className={styles.buttonContainer}>
          <div className={styles.removeLimitContainer}>
            <FocusButton
              nav={{ id: navMap.NO_LIMIT.id, nextdown: navMap.FIFTEEN_MIN.id }}
              onClick={() => onTimeSelected(0)}
            >
              Remove Limits for Today
            </FocusButton>
          </div>
          <div className={styles.buttonRow}>
            <FocusButton
              nav={{
                id: navMap.FIFTEEN_MIN.id,
                nextup: navMap.NO_LIMIT.id,
                nextdown: navMap.SIXTY_MIN.id,
                nextright: navMap.THIRTY_MIN.id,
              }}
              selected={false}
              onClick={() => onTimeSelected(15)}
            >
              Add 15 mins
            </FocusButton>
            <FocusButton
              nav={{
                id: navMap.THIRTY_MIN.id,
                nextup: navMap.NO_LIMIT.id,
                nextdown: navMap.NINETY_MIN.id,
                nextleft: navMap.FIFTEEN_MIN.id,
              }}
              selected={false}
              onClick={() => onTimeSelected(30)}
            >
              Add 30 mins
            </FocusButton>
          </div>
          <div className={styles.buttonRow}>
            <FocusButton
              nav={{
                id: navMap.SIXTY_MIN.id,
                nextup: navMap.FIFTEEN_MIN.id,
                nextright: navMap.NINETY_MIN.id,
              }}
              selected={false}
              onClick={() => onTimeSelected(60)}
            >
              Add 60 mins
            </FocusButton>
            <FocusButton
              nav={{
                id: navMap.NINETY_MIN.id,
                nextup: navMap.THIRTY_MIN.id,
                nextleft: navMap.SIXTY_MIN.id,
              }}
              selected={false}
              onClick={() => onTimeSelected(90)}
            >
              Add 90 mins
            </FocusButton>
          </div>
        </div>
      </FocusDiv>
    </BaseModal>
  );
};
AddingMoreTime.propTypes = {
  onTimeSelected: PropTypes.func,
  onCancel: PropTypes.func,
};

const TimeAddSuccess = ({ timeAdded }) => {
  return (
    <BaseModal
      showSaveButton
      saveButtonText="Continue"
      nav={{
        id: navMap.ACTION_BUTTON.id,
        parent: navMap.CONTAINER.id,
      }}
    >
      <FocusDiv
        className={styles.pinModalContainer}
        nav={{
          id: navMap.CONTAINER.id,
          forwardFocus: navMap.ACTION_BUTTON.id,
        }}
      >
        <img src={TimesUp} alt="timesup" />
        <h2>Great news!</h2>
        {timeAdded < 120 ? (
          <p>You can watch for another {timeAdded} min.</p>
        ) : (
          <p className={styles.description}>
            You can watch for another 24 hours.
          </p>
        )}
      </FocusDiv>
    </BaseModal>
  );
};
TimeAddSuccess.propTypes = {
  timeAdded: PropTypes.number,
};

const TimesUpInfo = ({ onContinue, onCancel }) => {
  return (
    <BaseModal
      showSaveButton
      saveAction={() => () => onContinue()}
      saveButtonText="Continue"
      cancelAction={() => () => onCancel()}
      nav={{
        id: navMap.ACTION_BUTTON.id,
        parent: navMap.CONTAINER.id,
      }}
    >
      <FocusDiv
        className={styles.pinModalContainer}
        nav={{
          id: navMap.CONTAINER.id,
          forwardFocus: navMap.ACTION_BUTTON.id,
        }}
      >
        <img src={TimesUp} alt="timesup" />
        <h2>Time's up!</h2>
        <p className={styles.description}>
          Your time is done for the rest of the day. Come back tomorrow or add
          more time to continue.
        </p>
      </FocusDiv>
    </BaseModal>
  );
};
TimesUpInfo.propTypes = {
  onContinue: PropTypes.func,
  onCancel: PropTypes.func,
};

const TimesUpModal = ({ history, location }) => {
  const dispatch = useDispatch();
  const parentUserId = useSelector(getParentUserId);
  const { profileId } = useSelector(getModalData);
  const [parentValidated, setParentValidated] = useState(false);
  const [pinError, setPinError] = useState(null);
  const [timeAdded, setTimeAdded] = useState(false);
  const [showTimesupInfo, setShowTimesupInfo] = useState(
    location.pathname === routes.PROFILE_SELECTION,
  );
  // Only return focus on profile selection page
  const [shouldReturnFocus, setShouldReturnFocus] = useState(
    location.pathname === routes.PROFILE_SELECTION,
  );

  // Playback control
  useEffect(() => {
    if (location.pathname === routes.PROFILE_SELECTION) {
      return;
    }

    dispatch(pausePlayback());
    return () => {
      dispatch(resumePlayback());
    };
  }, []);

  const onPinEntered = useCallback(
    async pinEntered => {
      try {
        await dispatch(
          switchProfile({
            targetProfileId: parentUserId,
            credentialType: 'pin',
            credential: pinEntered,
            setErrors: false,
          }),
        );
        setParentValidated(true);
      } catch (e) {
        setPinError(vindiciaErrors.incorrectPasswordError);
      }
    },
    [parentUserId],
  );

  const onTimeSelected = useCallback(async timeSelected => {
    const extraTimeMs =
      timeSelected === 0 ? 24 * 60 * 60 * 1000 : timeSelected * 60 * 1000;
    await dispatch(extendTimeAllowance(profileId, extraTimeMs));
    setTimeAdded(extraTimeMs / 1000 / 60);
    setShouldReturnFocus(true);
  }, []);

  const onCancel = useCallback(() => {
    dispatch(identityActions.setParentMode(true));
    history.push(routes.PROFILE_SELECTION);
    dispatch(onboardingActions.setSelectedProfile(null));
    dispatch(modalActions.closeModal());
  }, []);

  const onContinue = useCallback(() => {
    setShowTimesupInfo(false);
  }, []);

  return (
    <FocusModal isSticky {...{ shouldReturnFocus }}>
      {!parentValidated && !timeAdded && showTimesupInfo && (
        <TimesUpInfo {...{ onCancel, onContinue }} />
      )}
      {!parentValidated && !timeAdded && !showTimesupInfo && (
        <UnlockWithPin {...{ onPinEntered, pinError, onCancel }} />
      )}
      {parentValidated && !timeAdded && (
        <AddingMoreTime {...{ onTimeSelected, onCancel }} />
      )}
      {parentValidated && timeAdded && <TimeAddSuccess {...{ timeAdded }} />}
    </FocusModal>
  );
};
TimesUpModal.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(TimesUpModal);
