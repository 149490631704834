// Retrieving the NPM configuration to be able to expose
// it to the client. Can be useful for displaying the
// application version number.
//
// NOTE: be careful to only expose what you need the
// client to see.
import accedoOne from './accedoOne';

// Enabling Accedo One logging only if we have access to an Accedo One key.
const isAccedoOneLoggerEnabled = !!accedoOne.appKey;
const { title, description, version } = __FILTERED_PACKAGE_JSON__;

/*
 *** CLIENT APP CONFIGURATIONS ***
 *
 *  Configurations for the client application.
 */
export default {
  // Application title, typically to be displayed in a HTML title and
  // in an application header
  title,

  // Application description
  description,

  version,

  // Copyright info (displayed in the footer)
  copyright: '© 2016 - present, Accedo',

  // HTML head information
  head: {
    title,
    titleTemplate: `${title}: %s`,
    meta: [
      { name: 'description', content: description },
      { charset: 'utf-8' },
      { property: 'og:site_name', content: title },
      { property: 'og:image', content: 'vdkweblogo.png' },
      { property: 'og:locale', content: 'en_US' },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:version', content: version },
      { property: 'og:card', content: 'summary' },
      { property: 'og:image:width', content: '200' },
      { property: 'og:image:height', content: '200 ' },
    ],
  },

  // Settings for the client side logger
  // These will be passed to the client side @accedo/vdkweb-winston
  // logger in src/utils/logger.js
  logger: {
    // The default log level.
    // This can be overridden by the transports.
    logLevel: 'debug',

    // Defines the logging targets that we want to log
    // to whenever we're using the logger.
    transports: [
      // Logging to the browser console
      {
        name: 'BrowserConsole',
        enabled: true,
      },

      // Logging errors to Accedo One
      {
        name: 'AccedoOne',

        // If we have an Accedo One key configured we'll enable the Accedo One transport
        enabled: isAccedoOneLoggerEnabled,
        options: {
          // Only log errors to Accedo One
          level: 'error',

          // The default facility code, should be set for each error log
          facilityCode: 90,

          // The default error code, should be set for each error log
          errorCode: '000',
        },
      },
    ],
  },

  defaultScreenDimension: {
    width: 1920,
    height: 1080,
  },
};
