import fetch from '@accedo/vdkweb-fetch';
import vtt from 'vtt.js';

const fetchVTT = async url => {
  let response;

  try {
    response = await fetch(url, {
      method: 'GET',
      type: 'text',
    });

    if (!response.ok) {
      throw new Error(`Response status not ok for subtitles: ${url}`);
    }

    return await response.text();
  } catch (error) {
    console.error('Fetch failed for subtitles', url, error);
    return response.text();
  }
};

const parseVTT = async url => {
  const { WebVTT } = vtt;
  const parser = new WebVTT.Parser(window, WebVTT.StringDecoder());
  const cues = [];
  const regions = [];
  const webvtt = await fetchVTT(url);

  parser.oncue = cue => {
    cues.push(cue);
  };

  parser.onregion = region => {
    regions.push(region);
  };

  parser.parse(webvtt);
  parser.flush();

  return cues;
};

export default parseVTT;
