import { createActions } from 'redux-actions';

const SET_HEADER = 'SET_HEADER';

const actionOptions = {
  prefix: 'app/menu',
};

const actions = createActions(SET_HEADER, actionOptions);

export const { setHeader } = actions;
