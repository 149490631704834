import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { ArrowLeftButton } from '#/widgets/ui';

import { timePeriodFilters } from '../helpers/constants';
import {
  arrowContainer,
  arrowRightContainer,
  container,
} from './timePeriodSwitcher.scss';

const filterNames = Object.keys(timePeriodFilters);

const TimePeriodSwitcher = ({
  currentTimeSpan = filterNames[0],
  setCurrentTimeSpan = () => {},
}) => {
  const { displayName, id } = timePeriodFilters[currentTimeSpan];

  return (
    <div className={container}>
      <ArrowLeftButton
        onClick={() =>
          setCurrentTimeSpan(filterNames.find(filterId => filterId !== id))
        }
        className={arrowContainer}
      />
      <h3>{displayName}</h3>
      <ArrowLeftButton
        onClick={() =>
          setCurrentTimeSpan(filterNames.find(filterId => filterId !== id))
        }
        className={cx(arrowContainer, arrowRightContainer)}
      />
    </div>
  );
};

TimePeriodSwitcher.propTypes = {
  currentTimeSpan: PropTypes.oneOf(filterNames),
  setCurrentTimeSpan: PropTypes.func,
};

export default TimePeriodSwitcher;
