import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getAppStarted } from '#/redux/modules/lifecycle/selectors';
import { toggleLoginSpinner } from '#/redux/modules/spinner/actions';
import { getLoginLoadingSpinnerState } from '#/redux/modules/spinner/selectors';

export const useSpinnerOnStart = () => {
  const appStarted = useSelector(getAppStarted);
  const dispatch = useDispatch();
  const spinnerActive = useSelector(getLoginLoadingSpinnerState);
  !appStarted && !spinnerActive && dispatch(toggleLoginSpinner(true));

  useEffect(() => {
    dispatch(toggleLoginSpinner(!appStarted));
  }, [appStarted]);
};
