import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { LayoutX } from '@accedo/vdkweb-tv-ui';
import { focusManager } from '@accedo/vdkweb-navigation';
import styles from './MonthModal.scss';
import { PROFILECREATION, MONTHMODAL } from '#/utils/navigationHelper';

import {
  getDraftProfile,
  getMonths,
} from '#/redux/modules/onboarding/selectors';
import { actions } from '#/redux/modules/modal/actions';
import { setSpeech } from '#/utils/accessibility';

import { NumpadButton } from '#/widgets/ui';
import BaseModal from '../../common/BaseModal/BaseModal';

const MonthModal = ({ successAction }) => {
  const dispatch = useDispatch();
  const profile = useSelector(getDraftProfile);
  const fieldOptions = useSelector(getMonths);
  const months = fieldOptions.filter(e => {
    return e != null;
  });
  const fieldSelected = profile.birthdayMonth;
  const [selected, setSelected] = useState(fieldSelected);

  useEffect(() => {
    focusManager.changeFocus(MONTHMODAL.KEYPAD);
  }, []);

  const optionsButtons = Object.entries(months).map(([k, label]) => (
    <NumpadButton
      key={k}
      onClick={() => {
        setSelected(String(Number(k) + 1));
      }}
      label={label.slice(0, 3)}
      selected={selected === String(Number(k) + 1)}
      nav={{ id: `key-${label.slice(0, 3)}` }}
      onFocus={() => setSpeech(label.slice(0, 3))}
    />
  ));

  const onSave = () => {
    if (selected !== null) {
      successAction.payload = selected;
      dispatch(successAction);
    }
    dispatch(actions.closeModal());
    focusManager.changeFocus(PROFILECREATION.BIRTHDAY_INPUT);
  };

  return (
    <BaseModal
      className={styles.content}
      showSaveButton
      saveButtonText="Save"
      saveButtonDisabled={!selected}
      saveButtonClassName={styles.saveButton}
      saveAction={() => () => onSave()}
      nav={{
        id: MONTHMODAL.navOverride.id,
        parent: MONTHMODAL.navOverride.parent,
        nextup: MONTHMODAL.KEYPAD,
      }}
    >
      <h2 className={styles.title}>Select birth month</h2>
      <LayoutX
        className={styles.monthModalContent}
        nav={{
          id: MONTHMODAL.KEYPAD,
          forwardFocus: 'key-Jan',
          nextdown: selected ? MONTHMODAL.SAVE_BUTTON : null,
        }}
        layout={MONTHMODAL.LAYOUT}
      >
        {optionsButtons}
      </LayoutX>
    </BaseModal>
  );
};

MonthModal.propTypes = {
  successAction: PropTypes.object,
};

export default MonthModal;
