import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getModalData } from '#/redux/modules/modal/selectors';
import styles from './parentZoneModalTV.scss';

import {
  loadChildrenPrivacyPolicy,
  requestPrivacyPolicy,
  requestHelp,
  loadTermsOfService,
} from '#/redux/modules/accedoOne/actions';

const ParentZoneModalTV = () => {
  const data = useSelector(getModalData);
  const dispatch = useDispatch();
  const contentAreaRef = useRef(null);

  const getModalContent = useCallback(() => {
    return useSelector(state => data?.selector(state));
  }, [data]);

  useEffect(() => {
    dispatch(loadChildrenPrivacyPolicy);
    dispatch(requestPrivacyPolicy);
    dispatch(loadTermsOfService);
    dispatch(requestHelp);
    contentAreaRef?.current?.focus();
  }, []);

  return (
    <>
      <h3 className={styles.title}>{data?.title}</h3>
      <p ref={contentAreaRef} tabIndex={0} className={styles.modalText}>
        {getModalContent()}
      </p>
    </>
  );
};

export default ParentZoneModalTV;
