// top = yAxist + ref.offsetTop + headerHeight + marginTop > Issue with scrollRef

/**
 * Retrieves the element's styles
 * @param {node} ref element to retrieve the padding from
 * @returns {object} object containing padding-top and padding-bototm
 */
export const getStyles = ref => {
  const elementStyles = window.getComputedStyle(ref);
  const marginTop =
    parseInt(elementStyles.getPropertyValue('margin-top'), 10) || 0;
  const marginBottom =
    parseInt(elementStyles.getPropertyValue('margin-bottom'), 10) || 0;
  const paddingTop =
    parseInt(elementStyles.getPropertyValue('padding-top'), 10) || 0;
  const paddingBottom =
    parseInt(elementStyles.getPropertyValue('padding-bottom'), 10) || 0;

  return {
    marginTop,
    marginBottom,
    paddingTop,
    paddingBottom,
  };
};

/**
 * Retrieves the element's styles
 * @param {node} ref element to retrieve the padding from
 * @param {boolean} withStyles boolean to include element's styling
 * @returns {object} object containing padding-top and padding-bototm
 */
export const getClientRect = (ref, withStyles) => {
  const elementClientRect = JSON.parse(
    JSON.stringify(ref.getBoundingClientRect()),
  );

  if (withStyles) {
    return {
      ...elementClientRect,
      ...getStyles(ref),
    };
  }

  return elementClientRect;
};

export default getClientRect;
