import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import redux from '#/redux/modules';
import { getParentMode } from '#/redux/modules/identity/selectors';
import { getCurrentProfile } from '#/redux/modules/onboarding/selectors';
import { logicalSwitchToChildProfile } from '#/redux/modules/identity/actions';
import { getCurrentAgeMetadata } from '#/redux/modules/accedoOne/selectors';
import MenuIcon from '#/components/MainHeader/MenuIcon/MenuIcon';
import styles from './BottomTabBar.scss';

const {
  accedoOne: {
    selectors: { menuEntry },
  },
} = redux;

const BottomTabBar = ({ customStyles = {}, history }) => {
  const dispatch = useDispatch();
  const menu = useSelector(menuEntry);
  const parentMode = useSelector(getParentMode);
  const { id: targetProfileId } = useSelector(getCurrentProfile) || {};
  const selectedAgeMetadata = useSelector(state =>
    getCurrentAgeMetadata(state),
  );
  const onNavigateToChildRoute = useCallback(
    async route => {
      if (parentMode) {
        await dispatch(logicalSwitchToChildProfile(targetProfileId));
      }
      history.push(route);
    },
    [parentMode, targetProfileId],
  );
  return (
    <div
      className={cx(
        styles.mobileBottomTabBarContainer,
        customStyles?.mobileBottomTabBarContainer,
      )}
      style={{
        backgroundColor: selectedAgeMetadata?.colorScheme?.toolbarPrimary,
      }}
    >
      <div className={cx(styles.iconContainer, customStyles?.iconContainer)}>
        {menu?.menuItems.map(item => {
          return (
            <MenuIcon
              onNavigate={onNavigateToChildRoute}
              entryId={item}
              key={item}
            />
          );
        })}
      </div>
    </div>
  );
};

BottomTabBar.propTypes = {
  customStyles: PropTypes.object,
  history: PropTypes.object,
};

export default withRouter(BottomTabBar);
