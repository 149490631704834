import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { actions as modalActions } from '#/redux/modules/modal/actions';

import {
  titleText,
  durationContainer,
  durationText,
  timeChange,
  timeChangeUp,
  timeChangeDown,
  itemDesc,
  mainContainer,
  mostWatchedContainer,
  mostWatchedTitle,
  mostWatchedRail,
  railItemImage,
} from './learningItem.scss';

import arrowUp from '#/static/images/icons/arrow-up.svg';

import { timePeriodFilters } from '../helpers/constants';

const MOST_WATCHED = 'Top Watched';

const MostWatchedItemComp = props => {
  return (
    <div
      style={{
        width: `${100 / 5}%`,
        paddingRight: '0.23vw',
      }}
    >
      <div className={railItemImage}>
        <img src={props.imagePathUrl || ''} alt={props.showId} />
      </div>
    </div>
  );
};

const MostWatchedItem = React.memo(
  MostWatchedItemComp,
  (prevProps, nextProps) => {
    return prevProps.reference_id === nextProps.reference_id;
  },
);

export const MostWatched = ({ className, railTitle = MOST_WATCHED, items }) => {
  return (
    <div
      className={cx(mostWatchedContainer, { [className]: Boolean(className) })}
    >
      {railTitle && (
        <div className={mostWatchedTitle}>
          <span>{railTitle}</span>
        </div>
      )}
      <div className={mostWatchedRail}>
        {items?.slice(0, 5)?.map((data, idx) => (
          <MostWatchedItem {...data} key={`${data?.reference_id}-${idx}`} />
        ))}
      </div>
    </div>
  );
};

const Duration = ({ percentageWatched } = {}) => {
  const durationString = `${Math.round(percentageWatched)}% of viewing`;

  return (
    <div className={durationContainer}>
      <p className={durationText}>{durationString}</p>
    </div>
  );
};

const ViewingTimeChange = ({ timePeriod, time }) => {
  const timeInMins = Math.round(time / 1000 / 60);
  const timeChangeText = `${Math.abs(timeInMins)} mins from last ${
    timePeriodFilters[timePeriod]?.label
  }`;

  return (
    <div className={timeChange}>
      <img
        className={timeInMins < 0 ? timeChangeDown : timeChangeUp}
        src={arrowUp}
        alt="time change"
      />
      <p>{timeChangeText}</p>
    </div>
  );
};

const LearningItem = ({
  title,
  currentTimeSpan,
  description,
  timeWatchedDifference,
  percentageWatched,
  totalTimeWatched,
  mostWatched,
  mostWatchedItems,
}) => {
  const dispatch = useDispatch();

  return (
    <div className={mainContainer}>
      <div>
        <h4 className={titleText}>{title}</h4>
        <Duration
          percentageWatched={percentageWatched}
          totalTimeWatched={totalTimeWatched}
        />
        <ViewingTimeChange
          timePeriod={currentTimeSpan}
          time={timeWatchedDifference}
        />
      </div>
      <article className={itemDesc}>{description}</article>
      <MostWatched
        items={mostWatchedItems}
        viewAllAction={() => {
          dispatch(
            modalActions.openModal({
              modalId: 'MostWatchedModal',
              modalData: mostWatched,
            }),
          );
        }}
      />
    </div>
  );
};

MostWatchedItemComp.propTypes = {
  imagePathUrl: PropTypes.string,
  showId: PropTypes.number,
};

ViewingTimeChange.propTypes = {
  timePeriod: PropTypes.string,
  time: PropTypes.number,
};

MostWatched.propTypes = {
  className: PropTypes.string,
  railTitle: PropTypes.string,
  viewAllAction: PropTypes.func,
  items: PropTypes.array,
};

Duration.propTypes = {
  percentageWatched: PropTypes.number,
};

LearningItem.propTypes = {
  title: PropTypes.string,
  currentTimeSpan: PropTypes.string,
  description: PropTypes.string,
  timeWatchedDifference: PropTypes.number,
  percentageWatched: PropTypes.number,
  totalTimeWatched: PropTypes.number,
  mostWatched: PropTypes.array,
  history: PropTypes.shape({ push: PropTypes.func }),
  mostWatchedItems: PropTypes.array,
};

export default React.memo(LearningItem);
