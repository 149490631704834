import vjs from 'video.js';
/* eslint-disable-next-line no-unused-vars */
import qualityLevels from 'videojs-contrib-quality-levels';
/* eslint-disable-next-line no-unused-vars */
import vjsVttThumbnails from 'videojs-vtt-thumbnails';

import xdk, {
  klass,
  IPlayer,
  util,
  EventDispatcher,
  MediaConstants,
  PlayerConstants,
} from '@accedo/xdk-core';

import logger from '@accedo/xdk-log';
import { playerTimeFormatter } from '#/utils/time';
import { playerConstants, videoAnalytics } from '#/config/constants';
import {
  isWorkstation,
  isMobile,
  isPortable,
  isiOS,
} from '#/utils/componentStyleConfig';

vjs.setFormatTime(playerTimeFormatter);

const console = logger({ logTag: '[html5/videojs]' });

const {
  DRM: { CLEARKEY, PLAYREADY, WIDEVINE },
  PROTOCOL: { DASH, HLS },
} = MediaConstants;

const {
  BUFFERING: {
    START: BUFFERING_START,
    PROGRESS: BUFFERING_PROGRESS,
    FINISH: BUFFERING_FINISHED,
  },
  EVENT: { ERROR, FINISHED, PAUSE, TIME_UPDATE, BUFFERING },
} = PlayerConstants;

const VideoJsHtml5Player = klass.create(
  EventDispatcher,
  [IPlayer],
  {},
  {
    __id: 'sensicle-vjs-xdk-player',
    setId(id) {
      this.__id = id;
    },
    getId() {
      return this.__id;
    },

    _onLoaded: false,
    _CONTAINER_NAME: 'sensicle-vjs-html5-player-container',
    _parentNode: null,
    _playerObject: null,
    _vjs: null,
    _prepared: false,
    _volumeChanged: false,
    _volumeLevel: null,
    _volumeMuted: false,
    _duration: 0,
    _connectionTimeLimit: 20000,
    _connectionTimeOut: null,
    _startedBuffering: false,
    _connected: false,
    __hasFinished: true,
    __errorReported: false,
    __live: false,
    __isAd: false,
    _extensionManager: null,
    init(playerConfig = {}, extensionManager = {}) {
      this._extensionManager = extensionManager;

      if (playerConfig) {
        this._playerConfig = playerConfig;
      }

      this.__bindedOnLoad = util.bind(this._onLoad, this);
      this.__bindedOnTimeUpdate = util.bind(this._onTimeUpdate, this);
      this.__bindedOnWaiting = util.bind(this._onWaiting, this);
      this.__bindedOnPlaying = util.bind(this._onPlaying, this);
      this.__bindedOnPaused = util.bind(this._onPaused, this);
      this.__bindedOnFullscreen = util.bind(this._onFullScreenChange, this);
      this.__bindedOnError = util.bind(this._onError, this);
      this.__bindedOnFinished = util.bind(this._onFinished, this);
      this.__bindedOnDurationChange = util.bind(this._onDurationChange, this);
      this.__bindedOnVolumeChange = util.bind(this._onVolumeChange, this);
    },

    // Creates video DOM element and appends to parentNode opt
    prepare(opts) {
      this.__isAd = false;

      // default position to place the video object
      this._parentNode = opts?.parentNode;

      // create the player object
      this._playerObject = document.createElement('video');
      this._playerObject.setAttribute('muted', !!(isiOS() && !opts.live));
      this._playerObject.setAttribute('controls', true);
      this._playerObject.setAttribute('autoplay', true);
      this._playerObject.id = this._CONTAINER_NAME;
      this._playerObject.className = 'sensicle-vjs-xdk-player';
      if (isPortable()) {
        this._playerObject.setAttribute('nativeControlsForTouch', true);
        this._playerObject.setAttribute('playsinline', true);
      }
      this._parentNode.appendChild(this._playerObject);

      this.hide();
      this._prepared = true;
    },
    reset() {
      if (!this._prepared) {
        return;
      }
      this.hide();
      this._stopPlayback();
      this._removeVideoEventListeners(this._playerObject);
      this.deinit();
    },
    deinit() {
      if (!this._prepared) {
        return;
      }
      this._prepared = false;
      this._stopPlayback();
      vjs(this._CONTAINER_NAME).dispose();

      try {
        this._parentNode.removeChild(this._playerObject);
        /* eslint-disable-next-line no-empty */
      } catch {}
      this._playerObject = null;
      this._vjs = null;
    },
    // play(opts) {
    play() {
      this.show();

      this.__hasFinished = false;

      // make sure the speed is 1.
      if (this.getPlaybackSpeed() !== 1) {
        this._setSpeed(1);
      }

      this._doPlay();
    },
    _isPaused() {
      const { paused } = this._playerObject || {};
      return !!paused;
    },
    pause() {
      !this._isPaused() && this._playerObject?.pause?.();
    },
    stop() {
      this._stopPlayback();
      this.reset();
    },
    _stopPlayback() {
      this.pause();

      this._removeConnectionTimeOut();
      this._connected = false;
      this._volumeChanged = false;

      if (this._onLoaded && !this.__hasFinished) {
        this.__setCurrentTime(0);
      }
    },

    resume() {
      this._doPlay();
      this._setSpeed(1);
      return true;
    },
    skip(sec) {
      this.__setCurrentTime(this.getCurTime() + sec);
    },
    seek(sec) {
      if (sec === this._duration) {
        sec = Math.floor(this.getDuration() - 1);
      }
      this.__setCurrentTime(sec);
    },
    getPlaybackSpeed() {
      return this._playerObject.playbackRate;
    },
    getCurTime() {
      return this._playerObject.currentTime;
    },
    _setDuration() {
      if (this._playerObject) {
        this._duration = this._playerObject.duration;
      }
    },
    getDuration() {
      return this._duration || this._playerObject.duration;
    },

    show() {
      if (this._parentNode) {
        this._parentNode.style.display = 'block';
      }
    },
    hide() {
      if (this._parentNode) {
        this._parentNode.style.display = 'none';
      }
    },

    __enableCC(ccVal) {
      if (!ccVal) {
        vjs(this._CONTAINER_NAME)
          .textTracks()
          .forEach(track => {
            if (track.kind === 'captions') {
              track.mode = 'hidden';
            }
          });
      } else {
        vjs(this._CONTAINER_NAME).textTracks()[0].mode = 'showing';
      }
    },
    getExtensionManager() {
      return this._extensionManager;
    },
    getCapabilities() {
      return {
        protocol: [HLS, DASH],
        drm: [CLEARKEY, PLAYREADY, WIDEVINE],
      };
    },
    async load(mediaUrl, opts) {
      this.url = mediaUrl;
      if (!this._prepared) {
        return false;
      }

      // prepare the player object then do the loading
      // if the player object cannot be prepared, then skip loading
      // todo: throw exception?
      if (this._preparePlayerObject(opts)) {
        if (opts.drms?.length > 0) {
          await this._loadDRM(mediaUrl, opts);
        }
        this._doLoad(mediaUrl, opts);
      }

      return mediaUrl;
    },
    isMetadataLoaded() {
      return this._onLoaded;
    },
    _preparePlayerObject(opts) {
      if (!this._playerObject) {
        return false;
      }

      if (opts.parentNode) {
        this._parentNode = opts.parentNode;
        this._parentNode.appendChild(this._playerObject);
      }

      return true;
    },
    _doLoad(mediaUrl, opts) {
      this.__hasFinished = false;
      this.__errorReported = false;
      this._onLoaded = false;
      this._connected = false;

      const playerArgs = {
        preload: 'auto',
        autoplay: true,
        class: 'video-js vjs-fluid vjs-default-skin',
        controls: true,
        skin: false,
        muted: !!(isiOS() && !opts.live),
        responsive: true,
        nativeControlsForTouch: isPortable(),
      };
      if (isiOS() && !opts.live) {
        playerArgs.autoplay = true;
        playerArgs.muted = true;
      }

      if (!isWorkstation()) {
        playerArgs.controlBar = {
          volumePanel: false,
          playToggle: false,
        };
      }
      if (isPortable()) {
        playerArgs.controlBar = {
          volumePanel: true,
          playToggle: false,
          fullscreenToggle: true,
        };
        playerArgs.html5 = { nativeControlsForTouch: true };
      }

      const player = vjs(this._CONTAINER_NAME, playerArgs);
      this._vjs = player;

      const backgroundTitleText = document.createElement('div');
      backgroundTitleText.innerHTML = opts.title;
      backgroundTitleText.className =
        playerConstants.BACKGROUND_PLAYER_TITLE_CLASS;
      document
        .getElementsByClassName(
          playerConstants.PLAYER_CONTROLS_CONTAINER_CLASS,
        )[0]
        .appendChild(backgroundTitleText);

      // For DOM manipulating style purposes
      const currentTimeContainer = document.getElementsByClassName(
        'vjs-current-time',
      )[0];
      const durationTimeContainer = document.getElementsByClassName(
        'vjs-duration',
      )[0];
      const divider = document.getElementsByClassName('vjs-time-divider')[0];

      currentTimeContainer.appendChild(divider);
      currentTimeContainer.appendChild(durationTimeContainer);

      player.src(mediaUrl);
      player.ready(() => {
        // Event listeners need to be registered inside the ready function or else it will
        // break the iOS version
        player.on('loadedmetadata', this.__bindedOnLoad);
        player.on('fullscreenchange', this.__bindedOnFullscreen);
        player.on('volumechange', this.__bindedOnVolumeChange);
        player.on('timeupdate', this.__bindedOnTimeUpdate);
        player.on('waiting', this.__bindedOnWaiting);
        !isMobile() && player.on('seeking', this.__bindedOnWaiting);
        !isMobile() && player.on('seeked', this.__bindedOnPlaying);
        player.on('play', this.__bindedOnPlaying);
        player.on('pause', this.__bindedOnPaused);
        player.on('ended', this.__bindedOnFinished);
        player.on('error', this.__bindedOnError);

        // add cc track
        opts.ccTrack?.src && player.addRemoteTextTrack(opts.ccTrack, false);
        // add thumbnail track
        try {
          opts.thumbnailTrack &&
            player.vttThumbnails({
              src: opts.thumbnailTrack,
            });
          /* eslint-disable-next-line no-empty */
        } catch {}
        // show spinner
        player.loadingSpinner.show();
        // seek to resume point
        !opts.live && player.currentTime(opts.sec || 0);
        // adjust volume to previously set value
        // need to set volume before checking mute state so unmute sets to proper volume
        if (this._volumeMuted) {
          player.muted(true);
        } else if (this._volumeLevel) {
          player.volume(this._volumeLevel);
        } else {
          this._volumeLevel = player.volume();
          this._volumeChanged = true;
        }
        // start playback
        this.play();
      });
    },
    _doPlay() {
      this._playerObject.play();
    },
    // async _loadDRM(mediaURL, options) {
    //   const { drms } = options;
    //   const { drm: supportedDRM = [] } = this.getCapabilities();

    //   if (!drms) {
    //     return;
    //   }

    //   if (drms.filter((x) => supportedDRM.includes(x?.type.toLowerCase())).length === 0) {
    //     throw new Error("Could not find supported DRM system");
    //   }

    //   let agent;

    //   for (let drm of drms) {
    //     agent = await this._getDrmAgent(drm.type?.toLowerCase());
    //     if (agent) {
    //       break;
    //     }
    //   }

    //   return await agent.prepare(options, this);
    // },
    // async _getDrmAgent(drm) {
    //   const { __drmAgent: oldDrmAgent, __drmType: oldDrmType } = this;

    //   if (oldDrmAgent && oldDrmType === drm) {
    //     return oldDrmAgent;
    //   }

    //   this.__drmType = drm || null;
    //   this.__drmAgent = null;

    //   const { _extensionManager: extMgr } = this;

    //   if (!extMgr?.isExtensionSupported(DRM_EXTENSION)) {
    //     throw new Error("DRM extension is not defined properly.");
    //   }

    //   const extension = await extMgr.getExtension(DRM_EXTENSION);
    //   const drmAgent = await extension.createDRMAgent(drm);

    //   this.__drmAgent = drmAgent;

    //   return drmAgent;
    // },
    _onConnectionTimeout() {
      this._onError(videoAnalytics.events.NETWORK_ERROR);
      this._stopPlayback();
    },
    _onFullScreenChange() {
      this.dispatchEvent(playerConstants.FULL_SCREEN_CHANGE);
    },
    _onDurationChange() {
      this._onLoaded = true;
      this._setDuration();
    },
    _onBuffering({ buffering }) {
      if (buffering) {
        vjs(this._CONTAINER_NAME).loadingSpinner?.show();
        this.dispatchEvent(BUFFERING, BUFFERING_START);
        return;
      }

      vjs(this._CONTAINER_NAME).loadingSpinner?.hide();
      this.dispatchEvent(BUFFERING, BUFFERING_FINISHED);
    },
    _onLoad() {
      this._onDurationChange();

      // ignore the state change when speeding
      if (!this._connected || this.getPlaybackSpeed() !== 1) {
        return;
      }
      this._onBuffering({ buffering: true });
    },

    _removeVideoEventListeners(video) {
      if (video) {
        video.removeEventListener('loadedmetadata', this.__bindedOnLoad);
        video.removeEventListener(
          'fullscreenchange',
          this.__bindedOnFullscreen,
        );
        video.removeEventListener('volumechange', this.__bindedOnVolumeChange);
        video.removeEventListener('timeupdate', this.__bindedOnTimeUpdate);
        video.removeEventListener('waiting', this.__bindedOnWaiting);
        !isMobile() &&
          video.removeEventListener('seeking', this.__bindedOnWaiting);
        !isMobile() &&
          video.removeEventListener('seeked', this.__bindedOnPlaying);
        video.removeEventListener('play', this.__bindedOnPlaying);
        video.removeEventListener('pause', this.__bindedOnPaused);
        video.removeEventListener('ended', this.__bindedOnFinished);
        video.removeEventListener('error', this.__bindedOnError);
      }
    },

    _removeConnectionTimeOut() {
      if (this._connectionTimeOut) {
        clearTimeout(this._connectionTimeOut);
        this._connectionTimeOut = null;
      }
      if (this._startedBuffering) {
        this._startedBuffering = false;
      }
      this._playerObject && vjs(this._CONTAINER_NAME)?.loadingSpinner?.hide();
    },

    _onWaiting() {
      // small delay as time update can still occur for half a second
      // once waiting event has fired, which would clear the connection timeout
      this._connectionTimeOut && clearTimeout(this._connectionTimeOut);
      this._connectionTimeOut = setTimeout(() => {
        if (!this._startedBuffering) {
          this._startedBuffering = true;
          this.dispatchEvent(BUFFERING, BUFFERING_PROGRESS);
        }

        this._connectionTimeOut && clearTimeout(this._connectionTimeOut);
        this._connectionTimeOut = setTimeout(
          () => this._onConnectionTimeout(),
          this._connectionTimeLimit,
        );

        this._playerObject && vjs(this._CONTAINER_NAME)?.loadingSpinner?.show();
      }, 500);
    },

    _onPlaying() {
      vjs(this._CONTAINER_NAME).loadingSpinner.hide();
      this._removeConnectionTimeOut();
      if (!this._isPaused()) {
        this._doPlay();
        xdk.media?.play();
      } else {
        this._onPaused();
      }
      this._onBuffering({ buffering: false });
    },
    _onPaused() {
      vjs(this._CONTAINER_NAME).loadingSpinner.hide();
      this.dispatchEvent(PAUSE);
      this._removeConnectionTimeOut();
    },
    _onProgress() {},
    _onTimeUpdate() {
      this._removeConnectionTimeOut();
      this.dispatchEvent(TIME_UPDATE, Math.max(0, this.getCurrentTime()));
    },
    _onFinished() {
      this.__hasFinished = true;
      this._stopPlayback();

      if (this._playerObject) {
        this._removeVideoEventListeners(this._playerObject);
        try {
          this._parentNode.removeChild(this._playerObject);
          /* eslint-disable-next-line no-empty */
        } catch {}
        this._volumeChanged = false;
        this._playerObject = null;
        this._vjs = null;
      }

      this.dispatchEvent(FINISHED);
    },
    _onError(e, data) {
      // to avoid throw second error when it is alreay in error state or it is already finished
      if (this.__hasFinished || this.__errorReported) {
        return;
      }

      console.log(
        `Error ${this._playerObject.error?.code || e}; details: ${
          this._playerObject.error?.message
        }`,
      );

      if (
        this._playerObject.networkState === this._playerObject.NETWORK_NO_SOURCE
      ) {
        // media not found
        this._removeConnectionTimeOut();
        this._connected = false;
        this._onLoaded = false;
      } else if (e !== videoAnalytics.events.NETWORK_ERROR) {
        this._stopPlayback();
      }

      this.__errorReported = true;
      this.dispatchEvent(ERROR, {
        e,
        data,
        currentTime: this.getCurrentTime(),
      });
    },
    _onVolumeChange() {
      const volume = this._vjs.volume();

      if (this._volumeChanged) {
        if (volume === this._volumeLevel) {
          this._volumeMuted = !this._volumeMuted;
        }
        this._volumeLevel = volume;
      } else if (this._volumeLevel !== volume) {
        // initial change
        this._vjs.volume(this._volumeLevel);
      } else {
        this._volumeChanged = true;
      }
    },

    _onRateChange() {},

    _setSpeed(speed) {
      this._playerObject && (this._playerObject.playbackRate = speed);
    },

    getPlayerElement() {
      return this._playerObject;
    },

    getPlayerObject() {
      return this._vjs;
    },

    speed(speed) {
      if (!util.isNumber(speed)) {
        console.warn('Speed value must be a number');
        return;
      }

      if (speed <= 0) {
        console.warn('Negative playbackRate is not supported');
        return;
      }

      this._playerObject.playbackRate = speed;
    },

    getBitrates() {
      const bitrates = vjs(this._CONTAINER_NAME).qualityLevels();
      return bitrates.levels_[bitrates.selectedIndex];
    },
    getCurrentTime() {
      return vjs(this._CONTAINER_NAME).currentTime() || 0;
    },
    __setCurrentTime(sec) {
      vjs(this._CONTAINER_NAME)?.currentTime(sec);
    },
  },
);
export default VideoJsHtml5Player;
