import { initialAsyncState } from '../../utils/reduxHelper';

let rootSelector = state => state.async;

export const setRootSelector = selector => {
  rootSelector = selector;
};

export const getAsyncState = (callName, state) => {
  const root = rootSelector(state);

  if (root[callName]) {
    return root[callName];
  }

  return initialAsyncState;
};
