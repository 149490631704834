import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { environment } from '@accedo/xdk-core';
import { vKey } from '@accedo/xdk-virtual-key';
import { focusManager } from '@accedo/vdkweb-navigation';
import { Page } from '@accedo/vdkweb-tv-ui';

import cx from 'classnames';

import { InfoForm, InfoInput } from '#/widgets/forms';
import { FocusButton, FocusDiv } from '#/widgets/ui';
import LoginRedirect from '../LoginRedirect/LoginRedirect';
import { ErrorDispatcher } from '#/components';

import tvAuthStyles from '../tvauthflow.scss';
import styles from './TVSignIn.scss';

import { authScreenNames } from '#/config/constants';
import {
  LOGIN,
  login,
  getNewActivationCode,
  checkActivationCode,
  getUser,
  ASYNC_VERIFY_GET_ACTIVATION_CODE,
  ASYNC_IS_CODE_VALIDATED,
  actions,
} from '#/redux/modules/identity/actions';
import { sendScreenView } from '#/redux/modules/firestore/actions';
import {
  getSensicalTvConfig,
  getApiOidcEndpoints,
} from '#/redux/modules/accedoOne/selectors';
import { SIGNIN } from '#/utils/navigationHelper';

import { signIn } from '#/config/strings';

import GreyPhone from '#/static/images/icons/GreyPhone.png';

import Keyboard from '#/components/Keyboard/Keyboard';
import { isVIZIO } from '#/utils/componentStyleConfig';
import { setNativeFocus, setSpeech } from '#/utils/accessibility';

const POLL_INTERVAL = 5000;
const VIZIO_FOCUS_DELAY = 300;

const TVSignIn = () => {
  const dispatch = useDispatch();
  const [loginFields, setLoginFields] = useState({ email: '', credential: '' });

  const { extraParams } =
    useSelector(state => getApiOidcEndpoints(state)) || {};
  const { qrCodeSignedInUri: qrCodeUri } =
    useSelector(state => getSensicalTvConfig(state)) || {};
  const [activationCode, setActivationCode] = useState(null);
  const [deviceCode, setDeviceCode] = useState(null);
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [lastFocus, setLastFocus] = useState(null);
  const [emailInput, setEmailInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');

  const getCode = async () => {
    if (extraParams?.oidcClientId !== undefined) {
      await dispatch(
        getNewActivationCode({ clientId: extraParams?.oidcClientId }, res => {
          setActivationCode(res.user_code);
          setDeviceCode(res.device_code);
          return {
            type: ASYNC_VERIFY_GET_ACTIVATION_CODE,
          };
        }),
      );
    }
  };

  const checkCode = async () => {
    if (extraParams?.oidcClientId !== undefined && deviceCode !== null) {
      await dispatch(
        checkActivationCode(
          { clientId: extraParams?.oidcClientId, deviceCode },
          async res => {
            if (res?.access_token) {
              dispatch(actions.setAccessToken(res.access_token));
              dispatch(actions.setRefreshToken(res.refresh_token));
              await dispatch(getUser());
            }

            return {
              type: ASYNC_IS_CODE_VALIDATED,
            };
          },
        ),
      );
    }
  };

  const updateLoginFields = (data, isValid) => {
    if (isValid) {
      setLoginFields(data);
    }
  };

  const keyInput = keyValue => {
    const inputSetter =
      lastFocus === SIGNIN.EMAIL_INPUT ? setEmailInput : setPasswordInput;
    let nextFocus;
    switch (keyValue) {
      case 'NEXT':
        if (lastFocus === SIGNIN.EMAIL_INPUT) {
          nextFocus = SIGNIN.PASSWORD_INPUT;
        } else if (lastFocus === SIGNIN.PASSWORD_INPUT) {
          nextFocus = SIGNIN.SIGNIN_BUTTON;
          setShowKeyboard(false);
        }
        focusManager.changeFocus(nextFocus);
        setLastFocus(nextFocus);
        break;
      case 'PREV':
        if (lastFocus === SIGNIN.PASSWORD_INPUT) {
          nextFocus = SIGNIN.EMAIL_INPUT;
          focusManager.changeFocus(nextFocus);
          setLastFocus(nextFocus);
        }
        break;
      case 'DELETE':
        inputSetter(prev => prev.substring(0, prev.length - 1));
        break;
      default:
        inputSetter(prev => prev + keyValue);
    }
  };

  const onKeyDown = useCallback(({ id }) => {
    const { OK } = vKey;
    const currentFocusInput = focusManager.getCurrentFocus();
    if (id !== OK.id) {
      return;
    }
    if (
      currentFocusInput === SIGNIN.EMAIL_INPUT ||
      currentFocusInput === SIGNIN.PASSWORD_INPUT
    ) {
      setLastFocus(currentFocusInput);
      setShowKeyboard(true);
      focusManager.changeFocus(SIGNIN.KEYBOARD);
    }
  }, []);

  useEffect(() => {
    if (isVIZIO()) {
      setLoginFields({
        ...loginFields,
        email: emailInput,
        credential: passwordInput,
      });
    }
  }, [emailInput, passwordInput]);

  useEffect(() => {
    if (isVIZIO()) {
      environment.addEventListener(environment.SYSTEM.KEYDOWN, onKeyDown);
      return () => {
        environment.removeEventListener(environment.SYSTEM.KEYDOWN, onKeyDown);
      };
    }
  }, []);

  // TODO: refactor needed to properly handle enter key press from previous screen ( welcome -> sign in )
  useEffect(() => {
    if (isVIZIO()) {
      setTimeout(() => {
        setNativeFocus(SIGNIN.CONTAINER);
        focusManager.changeFocus(SIGNIN.EMAIL_INPUT);
      }, VIZIO_FOCUS_DELAY);
    }
    setNativeFocus(SIGNIN.CONTAINER);
  }, []);

  useEffect(() => {
    dispatch(sendScreenView(authScreenNames.SIGN_IN));
  }, []);

  useEffect(() => {
    getCode();
  }, [extraParams]);

  useEffect(() => {
    const loginInterval = setInterval(() => {
      checkCode();
    }, POLL_INTERVAL);
    return () => clearInterval(loginInterval);
  }, [extraParams, deviceCode]);

  return (
    <Page
      className={tvAuthStyles.background}
      autofocus
      nav={{ id: SIGNIN.PAGE, forwardFocus: SIGNIN.CONTAINER }}
    >
      <ErrorDispatcher errorId={LOGIN} />
      <LoginRedirect />
      <FocusDiv
        className={tvAuthStyles.content}
        nav={{
          id: SIGNIN.CONTAINER,
          forwardFocus: isVIZIO() ? null : SIGNIN.EMAIL_INPUT,
        }}
        role="main"
        ariaLabel={`${signIn.title}, ${signIn.subtitle}`}
        aria-describedby={`${SIGNIN.SIGNIN_CONTAINER_LEFT} ${SIGNIN.SIGNIN_CONTAINER_RIGHT}`}
      >
        <div className={tvAuthStyles.left} id={SIGNIN.SIGNIN_CONTAINER_LEFT}>
          <h1
            className={cx(styles.signInTitle, tvAuthStyles.pageTitle)}
            aria-label={signIn.title}
          >
            {signIn.title}
          </h1>
          <h6
            className={cx(styles.signInSubtitle, tvAuthStyles.pageBody)}
            aria-label={signIn.subtitle}
          >
            {signIn.subtitle}
          </h6>
          <InfoForm className={styles.tvForm} onChange={updateLoginFields}>
            <InfoInput
              fieldName="email"
              placeHolder="Enter email address"
              className={styles.tvInput}
              inputClassName={styles.tvInputField}
              inputAttributes={{ type: 'email' }}
              nav={{
                id: SIGNIN.EMAIL_INPUT,
                nextdown: SIGNIN.PASSWORD_INPUT,
                nextright: SIGNIN.NEWCODE_BUTTON,
                parent: SIGNIN.CONTAINER,
              }}
              value={isVIZIO() ? emailInput : null}
              visualFocused={isVIZIO() && lastFocus === SIGNIN.EMAIL_INPUT}
            />
            <InfoInput
              fieldName="credential"
              placeHolder="Enter password"
              className={styles.tvInput}
              inputClassName={styles.tvInputField}
              inputAttributes={{ type: 'password' }}
              nav={{
                id: SIGNIN.PASSWORD_INPUT,
                nextup: SIGNIN.EMAIL_INPUT,
                nextdown: SIGNIN.SIGNIN_BUTTON,
                nextright: SIGNIN.NEWCODE_BUTTON,
                parent: SIGNIN.CONTAINER,
              }}
              value={isVIZIO() ? passwordInput : null}
              visualFocused={isVIZIO() && lastFocus === SIGNIN.PASSWORD_INPUT}
            />
          </InfoForm>
          <FocusButton
            isTv
            tertiary
            className={styles.continueBtn}
            onClick={() => dispatch(login(loginFields))}
            nav={{
              id: SIGNIN.SIGNIN_BUTTON,
              nextup: SIGNIN.PASSWORD_INPUT,
              nextright: SIGNIN.NEWCODE_BUTTON,
              parent: SIGNIN.CONTAINER,
            }}
            onFocus={() => setSpeech(signIn.continue)}
            useNativeFocus={false}
          >
            {signIn.continue}
          </FocusButton>
          {isVIZIO() && showKeyboard && (
            <Keyboard
              nav={{
                id: SIGNIN.KEYBOARD,
              }}
              onClick={keyInput}
            />
          )}
        </div>
        <div className={tvAuthStyles.right} id={SIGNIN.SIGNIN_CONTAINER_RIGHT}>
          <img className={styles.qrCode} src={qrCodeUri} alt="QR Code" />
          <img
            className={tvAuthStyles.phone}
            src={GreyPhone}
            alt="Grey Phone"
          />
          <div className={styles.qrDesc}>
            <p className={cx(tvAuthStyles.pageBody, styles.signInActivate)}>
              {signIn.activate}
            </p>
            <p className={cx(tvAuthStyles.pageBody, styles.signInEnterCode)}>
              {signIn.enterCode}
            </p>
            <h1 className={cx(tvAuthStyles.pageTitle, styles.activationCode)}>
              {activationCode}
            </h1>
            <FocusButton
              isTv
              secondary
              className={styles.newCodeBtn}
              onClick={() => {
                getCode();
              }}
              nav={{
                id: SIGNIN.NEWCODE_BUTTON,
                nextleft: SIGNIN.EMAIL_INPUT,
                parent: SIGNIN.CONTAINER,
              }}
              ariaLabel={signIn.getNewCode}
            >
              {signIn.getNewCode}
            </FocusButton>
          </div>
        </div>
      </FocusDiv>
    </Page>
  );
};

export default TVSignIn;
