import React from 'react';
import PropTypes from 'prop-types';

// Assets
import Step1 from '#/static/images/icons/Step1.svg';
import Step1b from '#/static/images/icons/Step1b.svg';
import Step2 from '#/static/images/icons/Step2.svg';
import Step2b from '#/static/images/icons/Step2b.svg';
import Step3 from '#/static/images/icons/Step3.svg';
import Step3b from '#/static/images/icons/Step3b.svg';

const stepsMap = {
  '1': Step1,
  '1b': Step1b,
  '2': Step2,
  '2b': Step2b,
  '3': Step3,
  '3b': Step3b,
};

const StepIndicator = ({ step, className }) => (
  <img src={stepsMap[step]} className={className} alt="Step indicator" />
);

StepIndicator.propTypes = {
  step: PropTypes.string,
  className: PropTypes.string,
};

export default StepIndicator;
