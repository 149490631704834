import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { authScreenNames } from '#/config/constants';
import { getAgeUp } from '#/redux/modules/accedoOne/selectors';
import { getModalData } from '#/redux/modules/modal/selectors';
import { sendScreenView } from '#/redux/modules/firestore/actions';
import BaseModal from '../../common/BaseModal/BaseModal';
import styles from './ParentInfo.scss';

import { FocusDiv } from '#/widgets/ui';
import { isWorkstation } from '#/utils/componentStyleConfig';
import FocusModal from '../../common/FocusModal/FocusModal';
import { TV_CONTENT_MODAL } from '#/utils/navigationHelper';

const { CONTAINER, TV_CONTENT_MODAL_CANCEL_BUTTON } = TV_CONTENT_MODAL;

const ParentAgeUpModal = () => {
  const dispatch = useDispatch();
  const ageUp = useSelector(getAgeUp);
  const { title, body, age, minAge, maxAge, ageGroupValue } = useSelector(
    getModalData,
  );
  const {
    commonAgeUpModal: { watchCta },
  } = ageUp;

  useEffect(() => {
    dispatch(
      sendScreenView(
        `${authScreenNames.AGE_UP_PARENT_INFO} (${ageGroupValue})`,
      ),
    );
  }, []);

  return (
    <FocusModal>
      <BaseModal
        nav={{
          parent: CONTAINER,
          id: TV_CONTENT_MODAL_CANCEL_BUTTON,
        }}
        showCloseX={!!isWorkstation}
        saveButtonText={watchCta}
        className={styles.baseContainer}
        saveButtonContainerClassName={styles.watchButtonContainer}
        saveButtonClassName={styles.watchButton}
      >
        <FocusDiv
          className={styles.modalContainer}
          nav={{
            id: CONTAINER,
            forwardFocus: TV_CONTENT_MODAL_CANCEL_BUTTON,
          }}
        >
          <h3 className={styles.title}>{title}</h3>
          <p className={styles.description}>
            {body
              .replace('{age}', age)
              .replace('{minAge}', minAge)
              .replace('{maxAge}', maxAge)}
          </p>
        </FocusDiv>
      </BaseModal>
    </FocusModal>
  );
};

export default ParentAgeUpModal;
