// import { isWorkstation } from '../utils/componentStyleConfig';

const processEnvVars = {
  ACCEDO_ONE_KEY: process.env.ACCEDO_ONE_KEY,
  FIRESTORE_CONFIG: {
    FIRESTORE_KEY: process.env.FIRESTORE_KEY,
    FIRESTORE_DOMAIN: process.env.FIRESTORE_DOMAIN,
    FIRESTORE_PROJECT: process.env.FIRESTORE_PROJECT,
    FIRESTORE_STORAGE: process.env.FIRESTORE_STORAGE,
    FIRESTORE_MESSENGER: process.env.FIRESTORE_MESSENGER,
    FIRESTORE_APP: process.env.FIRESTORE_APP,
    FIRESTORE_MEASUREMENT: process.env.FIRESTORE_MEASUREMENT,
  },
};

// console.log('ISWORKSTATION', isWorkstation());
const environments = {
  development: {
    isProduction: false,
    ...processEnvVars,
  },
  production: {
    isProduction: true,
    adConfigId: '7b9257f9-5da1-46da-9efb-ff1148088515', // default web
    adConfigIdTizen: '73f7e883-a0d4-442e-a210-c6f529c3099b',
    adConfigIdVizio: '21ad399c-6aaf-4e76-81f0-f65732ca7ad8',
    gtmId: 'GTM-KNMCJKVW',
    ...processEnvVars,
  },
};

const environment =
  environments[process.env.NODE_ENV] || environments.development;

module.exports = {
  environments,
  envConfig: environment,
};
