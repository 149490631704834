import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import cx from 'classnames';
import RedirectWithQuery from '#/components/App/RedirectWithQuery';
import authStyles from '../authflow.scss';
import routes, { authFlowRoutes } from '#/utils/routes';
import { signupStates, authScreenNames } from '#/config/constants';

import { ErrorDispatcher } from '#/components';
import { InfoForm, InfoInput } from '#/widgets/forms';
import { Button } from '#/widgets/ui';
import LoadingSpinner from '#/components/LoadingSpinner/LoadingSpinner';

import { actions as modalActions } from '#/redux/modules/modal/actions';
import { getSensicalTvConfig } from '#/redux/modules/accedoOne/selectors';
import { getAppStarted } from '#/redux/modules/lifecycle/selectors';
import { sendScreenView } from '#/redux/modules/firestore/actions';
import { getTvCode } from '#/redux/modules/onboarding/selectors';
import { isUserLogged } from '#/redux/modules/user/selectors';
import { getError } from '#/redux/modules/identity/selectors';
import { actions } from '#/redux/modules/onboarding/actions';
import {
  ASYNC_SWITCH_PROFILE,
  LINK_DEVICE,
  linkDevice,
  switchToParentProfile,
} from '#/redux/modules/identity/actions';

import Logo from '#/static/images/icons/logo-login.svg';
import styles from './CodeScreen.scss';

const CodeScreen = ({
  location: { pathname },
  match: {
    params: { code: newTvCode },
  },
}) => {
  const dispatch = useDispatch();
  const appStarted = useSelector(getAppStarted);
  const savedTvCode = useSelector(getTvCode);
  const isUserLoggedIn = useSelector(isUserLogged);
  const linkingError = useSelector(getError(LINK_DEVICE));
  const parentalPinError = useSelector(getError(ASYNC_SWITCH_PROFILE));
  const { logoUri = Logo } =
    useSelector(state => getSensicalTvConfig(state)) || {};
  const [showSpinner, setShowSpinner] = useState(true);
  const [showPinModal, setShowPinModal] = useState(true);

  useEffect(() => {
    dispatch(sendScreenView(authScreenNames.CODE_SCREEN));

    if (!savedTvCode && routes.ACTIVATE_BASE.includes(pathname)) {
      dispatch(
        actions.setSignupState({ signupState: signupStates.CREATE_PROFILE }),
      );
    }

    setShowSpinner(false);
  }, []);

  useEffect(() => {
    setShowPinModal(!parentalPinError);
  }, [parentalPinError]);

  useEffect(() => {
    if (
      appStarted &&
      !savedTvCode &&
      newTvCode !== 'complete' &&
      isUserLoggedIn &&
      showPinModal
    ) {
      setShowSpinner(false);

      dispatch(
        modalActions.openModal({
          modalId: 'PinModal',
          successAction: async pin => {
            await dispatch(switchToParentProfile({ credential: pin }));
          },
        }),
      );

      setShowPinModal(false);
    }

    appStarted ? setShowSpinner(false) : setShowSpinner(true);
  }, [appStarted, showPinModal]);

  useEffect(() => {
    if (newTvCode && newTvCode !== 'complete') {
      dispatch(actions.setTvCode({ tvCode: newTvCode }));
    } else if (!newTvCode) {
      dispatch(actions.setTvCode({ tvCode: null }));
    }
  }, [newTvCode]);

  useEffect(() => {
    if (isUserLoggedIn && savedTvCode) {
      dispatch(linkDevice({ tvCode: savedTvCode }, actions.setTvCode));
      dispatch(actions.setTvCode({ tvCode: null }));
    }
  }, [isUserLoggedIn, savedTvCode]);

  const activateDescription = savedTvCode ? (
    <>
      <h1>Activating</h1>
      <p>Linking your TV to your account.</p>
    </>
  ) : !newTvCode ? (
    <>
      <h1>Hi, Parent</h1>
      <p>
        From your TV device, please enter the 6 digit code below to register
        your device.
      </p>
    </>
  ) : (
    <>
      <h1>You're good to go</h1>
      <p>Go to your television now and start watching.</p>
    </>
  );

  let code;
  const codeEntryForm = (
    <InfoForm
      onChange={({ activateCode }) => {
        code = activateCode?.toUpperCase();
      }}
    >
      <InfoInput
        fieldName="activateCode"
        fieldLabel="6 digit code"
        fieldValue={newTvCode}
        capitalize
        inputAttributes={{
          required: 'required',
          minLength: '6',
          maxLength: '6',
        }}
      />
      <Button
        onClick={() => dispatch(actions.setTvCode({ tvCode: code }))}
        style={{ width: '100%' }}
      >
        Activate
      </Button>
    </InfoForm>
  );

  return (
    <div className={cx(authStyles.authFlow, styles.codeScreen)}>
      <ErrorDispatcher errorId={LINK_DEVICE} />
      <ErrorDispatcher errorId={ASYNC_SWITCH_PROFILE} />
      {savedTvCode && !isUserLoggedIn && (
        <RedirectWithQuery
          to={
            routes.ACTIVATE_BASE.includes(pathname)
              ? authFlowRoutes.PROFILE_CREATION
              : authFlowRoutes.SIGN_IN
          }
        />
      )}
      {savedTvCode && isUserLoggedIn && (
        <RedirectWithQuery to={routes.ACTIVATE_COMPLETE} />
      )}
      {linkingError && <RedirectWithQuery to={routes.ACTIVATE_BASE} />}
      <img className={styles.logoWebLayout} src={logoUri} alt="Sensical" />
      <div className={authStyles.pageDescription}>{activateDescription}</div>
      <div className={authStyles.content}>
        {!savedTvCode && !newTvCode ? codeEntryForm : null}
      </div>
      <LoadingSpinner showLoading={showSpinner} />
    </div>
  );
};
CodeScreen.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
};

export default CodeScreen;
