import { klass, ISubtitleStrategy } from '@accedo/xdk-core';
import logger from '@accedo/xdk-log';

const { debug } = logger({
  logTag: '[players/videojs/InternalSubtitles]',
});
const SubtitleStrategy = klass.create(
  [ISubtitleStrategy],
  {},
  {
    __player: null,
    __subtitles: [],
    __currentSubtitleId: null,

    prepare(player) {
      if (!player) {
        debug(
          'Fail to prepare the videojs Subtitle Track Strategy without the XDK player object.',
        );
        return;
      }
      this.__player = player;
    },

    // getSubtitles is chained through xdk media -> Subtitle -> extension files
    // This sets the subtitle array within Subtitle to enable calling show() and hide() afterwards
    async getSubtitles() {
      return Promise.resolve([
        {
          id: 1, // IMPORTANT: getSubtitles must returns items with id (locally) unique field
          type: 'internal',
        },
      ]);
    },
  },
);

export default SubtitleStrategy;
