/* eslint-disable no-script-url */

import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import ErrorDispatcher from '../../ErrorDispatcher/ErrorDispatcher';
import ProfileCard from '../../ProfileCard/ProfileCard';
import { actions as modalActions } from '#/redux/modules/modal/actions';
import {
  ASYNC_SWITCH_PROFILE,
  logicalSwitchToChildProfile,
  switchToParentProfile,
  checkParentalTimeLimit,
} from '#/redux/modules/identity/actions';
import {
  getSavedProfiles,
  getCurrentProfile,
} from '#/redux/modules/onboarding/selectors';
import {
  actions as onboardingActions,
  newDraftProfile,
} from '#/redux/modules/onboarding/actions';
import { sendScreenView } from '#/redux/modules/firestore/actions';
import {
  getSensicalTvConfig,
  getWhiteLogoUri,
} from '#/redux/modules/accedoOne/selectors';
import storage from '#/utils/localStorage';

import firestoreApi from '#/services/firestore/api';
import { Button, LeftArrow, RightArrow } from '#/widgets/ui';
import { profileSelectionTitle } from '#/config/strings';
import { authScreenNames } from '#/config/constants';
import GreenBackground from '#/static/images/background-images/Green.svg';
import MobileSBackground from '#/static/images/background-images/mobileS.png';
import EditIcon from '#/static/images/icons/edit.svg';
import Logo from '#/static/images/icons/logo-login.svg';
import styles from './ProfileSelection.scss';
import routes from '#/utils/routes';
import { isMobile } from '#/utils/componentStyleConfig';

const scrollLength = 200;

const ProfileSelection = ({ history, onSwitchEnd = null }) => {
  const dispatch = useDispatch();
  const { logoUri = Logo } = useSelector(getSensicalTvConfig) || {};
  const backgroundImage = isMobile() ? MobileSBackground : GreenBackground;
  const whiteLogoUri = useSelector(getWhiteLogoUri) || '';

  // Scrolling
  const baseballCardsContainer = useRef(null);
  const [scrollLeftPosition, setScrollLeftPosition] = useState(0);
  const [showLeft, setShowLeft] = useState(false);
  const [showRight, setShowRight] = useState(false);

  const [editMode, setEditMode] = useState(false);
  const currentProfile = useSelector(getCurrentProfile);
  const profiles = useSelector(getSavedProfiles);

  const editAvatar = useCallback(id => {
    dispatch(onboardingActions.editProfile({ profileId: id }));
    dispatch(
      modalActions.openModal({
        modalId: 'AvatarModal',
        successAction: {
          type: String(onboardingActions.setProfileAvatar),
        },
        finallyAction: {
          type: String(onboardingActions.discardProfile),
        },
      }),
    );
  }, []);

  const addProfile = useCallback(() => {
    dispatch(
      modalActions.openModal({
        modalId: 'PinModal',
        successAction: async pin => {
          await dispatch(switchToParentProfile({ credential: pin }));
          await dispatch(newDraftProfile());
          history.push(routes.PROFILE_ADDITION);
        },
      }),
    );
  }, []);

  const onSwitch = useCallback(async profileId => {
    const welcomeModal = JSON.parse(storage.get('welcomeModal')) || {};
    const timeLimitReached = await dispatch(
      checkParentalTimeLimit({ profileId }),
    );
    if (!timeLimitReached) {
      const shouldReload =
        !(`${profileId}` in welcomeModal) ||
        JSON.parse(storage.get('welcomeModal'))?.[`${profileId}`]
          .showWelcomeModal;

      await dispatch(logicalSwitchToChildProfile(profileId));
      onSwitchEnd ? onSwitchEnd(profileId) : history.push(routes.HOME);
      if (!shouldReload) {
        window?.location.reload();
      }
    }
  }, []);

  const baseballCards = useMemo(() => {
    return profiles.map(profile => {
      const {
        parentalTimeLimit = 0,
        timeWatched: { duration: durationWatched = 0 } = {},
      } = profile;

      const timeLimitReached =
        parentalTimeLimit !== 0 && durationWatched > parentalTimeLimit;

      return (
        <ProfileCard
          locked={timeLimitReached}
          key={profile.id}
          profile={profile}
          editMode={editMode}
          onSwitch={() => {
            onSwitch(profile.id);
          }}
          onEdit={() => editAvatar(profile.id)}
        />
      );
    });
  }, [profiles, currentProfile, editMode]);

  // Initialize timesup data
  useEffect(() => {
    profiles.forEach(({ id: profileId }) => {
      dispatch(checkParentalTimeLimit({ profileId, showModal: false }));
    });
  }, []);

  // Scrolling
  const scrollAction = useCallback(
    ({ back = false } = {}) => {
      if (back) {
        baseballCardsContainer.current.scrollLeft -= scrollLength;
      } else {
        baseballCardsContainer.current.scrollLeft += scrollLength;
      }
    },
    [baseballCardsContainer],
  );

  useEffect(() => {
    firestoreApi.updateDefaultUserProperties({
      age_group: 'parent',
    });
    dispatch(sendScreenView(authScreenNames.PROFILE_SELECTION));

    const handler = () => {
      setScrollLeftPosition(baseballCardsContainer.current.scrollLeft);
    };
    baseballCardsContainer.current.addEventListener('scroll', handler);
    return () => {
      baseballCardsContainer.current.removeEventListener('scroll', handler);
    };
  }, []);

  useEffect(() => {
    const {
      scrollWidth,
      scrollLeft,
      offsetWidth,
    } = baseballCardsContainer.current;

    // Container is fully visible, no scrolling
    if (scrollWidth - offsetWidth <= 100) {
      setShowRight(false);
      setShowLeft(false);
      return;
    }

    // Right arrow visibility
    if (scrollWidth - scrollLeft > offsetWidth) {
      setShowRight(true);
    } else {
      setShowRight(false);
    }

    // Left arrow visibility
    if (scrollLeftPosition === 0) {
      setShowLeft(false);
    } else {
      setShowLeft(true);
    }
  }, [scrollLeftPosition, profiles]);

  return (
    <div
      className={styles.profileSelection}
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <ErrorDispatcher errorId={ASYNC_SWITCH_PROFILE} />
      <div className={styles.heading}>
        <img
          className={styles.logoWebLayout}
          src={isMobile() ? whiteLogoUri : logoUri}
          style={{ display: logoUri || whiteLogoUri ? 'inline-block' : 'none' }}
          loading="eager"
          alt="Logo"
        />
        <h2>{profileSelectionTitle}</h2>
      </div>
      <div
        ref={baseballCardsContainer}
        className={styles.baseballCardsContainer}
      >
        {!isMobile() && <LeftArrow visible={showLeft} {...{ scrollAction }} />}
        {baseballCards}
        {!isMobile() && (
          <RightArrow visible={showRight} {...{ scrollAction }} />
        )}
      </div>
      <div>
        <Button className={styles.addProfileButton} onClick={addProfile}>
          Add profile
        </Button>
        <div
          className={styles.privacyPolicy}
          onClick={() =>
            dispatch(
              modalActions.openModal({ modalId: 'PrivacyAgreementModal' }),
            )
          }
        >
          Sensical Privacy Policy
        </div>
      </div>
      <div className={styles.editModeButton}>
        {editMode ? (
          <Link to="#0" onClick={() => setEditMode(false)}>
            Done
          </Link>
        ) : (
          <Link to="#0" onClick={() => setEditMode(true)}>
            <img src={EditIcon} alt="Edit all profiles" />
          </Link>
        )}
      </div>
    </div>
  );
};

ProfileSelection.propTypes = {
  history: PropTypes.object,
  onSwitchEnd: PropTypes.func,
};

export default withRouter(ProfileSelection);
