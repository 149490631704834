import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import {
  ASYNC_UPDATE_ACCOUNT_CUSTOM_DATA,
  updateProfileCustomData,
  actions as identityActions,
} from '#/redux/modules/identity/actions';
import { actions as onboardingActions } from '#/redux/modules/onboarding/actions';
import { getProfiles } from '#/redux/modules/onboarding/selectors';

import ErrorDispatcher from '../../ErrorDispatcher/ErrorDispatcher';
import { Select } from '#/widgets/forms';
import { Button, ProfileImage } from '#/widgets/ui';

import {
  timeLimitOptions,
  maxTimeLimitOption,
  defaultParentalTimeLimit,
} from '#/config/constants';
import { vindiciaErrors } from '#/config/strings';
import DropdownHollow from '#/static/images/icons/dropdown-hollow.svg';
import styles from './ParentalControls.scss';

const { profileUpdateError } = vindiciaErrors;

if (process.env.NODE_ENV === 'development') {
  timeLimitOptions[30000] = 'DEBUG: 30 seconds';
}

const ProfileTimeLimit = ({
  id,
  name,
  avatar,
  parentalTimeLimit = 0,
  onSetTimelimit,
}) => {
  // Find the smallest time that parentalTimeLimit is less than or equal to if no exact match
  const matchedTimeLimit = Object.keys(timeLimitOptions).reduce(
    (minimumTimeFound, limit) => {
      return parentalTimeLimit <= Number(limit)
        ? Math.min(limit, minimumTimeFound)
        : minimumTimeFound;
    },
    maxTimeLimitOption,
  );

  const onSetTimelimitWrapper = useCallback(
    e => {
      const {
        target: { value },
      } = e;
      onSetTimelimit(id, Number(value));
    },
    [onSetTimelimit],
  );

  return (
    <div className={styles.profileTimeLimit}>
      <div>
        <ProfileImage className={styles.profileImage} src={avatar} />
        <h4 className={styles.name}>{name}: Time limit</h4>
      </div>
      <Select
        className={styles.timeLimitSelector}
        buttonImage={DropdownHollow}
        fieldName="parentalTimeLimit"
        fieldSelected={matchedTimeLimit}
        fieldOptions={timeLimitOptions}
        inputAttributes={{
          onChange: onSetTimelimitWrapper,
        }}
        controlledElement
      />
    </div>
  );
};
ProfileTimeLimit.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  avatar: PropTypes.string,
  parentalTimeLimit: PropTypes.number,
  onSetTimelimit: PropTypes.func,
};

export const ParentalControls = () => {
  const dispatch = useDispatch();
  const profiles = useSelector(getProfiles);
  const promiseRef = useRef(null);

  const onSetTimelimit = useCallback(async (profileId, parentalTimeLimit) => {
    try {
      dispatch(onboardingActions.editProfile({ profileId }));
      dispatch(onboardingActions.setProfileFields({ parentalTimeLimit }));
      await dispatch(updateProfileCustomData(profileId, { parentalTimeLimit }));
      dispatch(onboardingActions.saveProfile());
    } catch (e) {
      dispatch(onboardingActions.discardProfile());
      dispatch(
        identityActions.setError(ASYNC_UPDATE_ACCOUNT_CUSTOM_DATA, {
          errorMsg: { customError: profileUpdateError },
        }),
      );
    }
  }, []);

  const onResetControls = useCallback(async () => {
    if (promiseRef.current) {
      return;
    }
    try {
      promiseRef.current = Promise.all(
        profiles.map(({ id }) => {
          return dispatch(
            updateProfileCustomData(id, {
              parentalTimeLimit: defaultParentalTimeLimit,
            }),
          ).then(() => {
            return dispatch(
              onboardingActions.updateProfileData({
                storeId: id,
                parentalTimeLimit: defaultParentalTimeLimit,
              }),
            );
          });
        }),
      );
    } catch (e) {
      dispatch(
        identityActions.setError(ASYNC_UPDATE_ACCOUNT_CUSTOM_DATA, {
          errorMsg: { customError: profileUpdateError },
        }),
      );
    }
    await promiseRef.current;
    promiseRef.current = null;
  }, [profiles.length]);

  const profileTimeLimits = profiles.map(({ id, ...rest }) => (
    <ProfileTimeLimit key={id} {...{ id, ...rest, onSetTimelimit }} />
  ));

  return (
    <div className={styles.parentalControls}>
      <ErrorDispatcher errorId={ASYNC_UPDATE_ACCOUNT_CUSTOM_DATA} />
      {profileTimeLimits}
      <div className={styles.buttonContainer}>
        <Button
          onClick={onResetControls}
          selected={false}
          danger
          className={styles.resetButton}
        >
          Reset controls
        </Button>
      </div>
    </div>
  );
};

ParentalControls.propTypes = {};

export default ParentalControls;
