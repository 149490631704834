import { handleActions } from 'redux-actions';
import { actions, ONBOARDING_ACTION_PREFIX } from './actions';
import { unsavedProfileIdPrefix } from '#/config/constants';
import { persistenceLayer } from '../../utils/persistence';
import { isMobile } from '#/utils/componentStyleConfig';

const ageBrackets = [null, null, '2', '3', '4', '5', '6', '7', '8', '9', '10'];

const ageGroups = {
  TWO_UP: [2, 3, 4],
  FIVE_UP: [5, 6, 7],
  EIGHT_UP: [8, 9, 10],
};

const months = [
  null,
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const deepLink = false;
const deepLinkAgeMatch = true;

const initialState = {
  signupState: isMobile() ? 'welcome' : 'guestprofile',
  emailVerified: false,
  linkExpired: false,
  tvCode: null,
  activationCode: null,

  // Constants
  ageBrackets,
  ageGroups,
  months,
  deepLink,
  deepLinkAgeMatch,

  // Profiles
  selectedProfile: null,
  // selectedProfile: '74daf644-570e-11eb-ae93-0242ac130002',
  draftProfile: null,
  profiles: [],
  /*
  profiles: [
    {
      id: 'UNSAVED_597923e4-570e-11eb-ae93-0242ac130002',
      name: 'Jake',
      age: 3,
      birthdayMonth: 9,
      avatar: 'TWO',
    },
    {
      id: 'UNSAVED_74daf644-570e-11eb-ae93-0242ac130002',
      name: 'Tom',
      age: 7,
      birthdayMonth: 3,
      avatar: 'SIX',
    },
    {
      id: '9ee08030-570e-11eb-ae93-0242ac130002',
      name: 'Mary',
      age: 10,
      birthdayMonth: 12,
      avatar: 'ONE',
    },
  ],
  */

  // Accounts
  account: {
    email: '',
    marketingOptin: true,
  },
  /*
  account: {
    email: `User${parseInt(Math.random() * 10000)}@foobar.com`,
    marketingOptin: true,
  },
  */
  // Guest Profile
  showGuestProfileImage: false,
};

const handleSetSignupState = (
  state = initialState,
  { payload: { signupState } },
) => ({
  ...state,
  signupState,
});

const handleSetTvCode = (
  state = initialState,
  { payload: { tvCode } } = { payload: { tvCode: null } },
) => ({
  ...state,
  tvCode,
});

const handleEditProfile = (
  state = initialState,
  { payload: { profileId } = { profileId: null } },
) => ({
  ...state,
  draftProfile: profileId
    ? { ...state.profiles.find(profile => profile.id === profileId) }
    : null,
});

const handleAddProfile = (state = initialState, { payload: avatarUrl }) => ({
  ...state,
  draftProfile: {
    id: `${unsavedProfileIdPrefix}___${parseInt(Math.random() * 1000000000)}`,
    avatar: avatarUrl,
    age: null,
    birthdayMonth: null,
    syncData: null,
  },
});

const handleSaveProfile = (state = initialState) => ({
  ...state,
  draftProfile: null,
  profiles: [
    ...state.profiles.map(profile =>
      profile.id === state.draftProfile.id
        ? { ...profile, ...state.draftProfile }
        : profile,
    ),
    ...(!state.profiles.find(profile => profile.id === state.draftProfile.id)
      ? [{ ...state.draftProfile }]
      : []),
  ],
});

const handleDiscardProfile = (state = initialState) => ({
  ...state,
  draftProfile: null,
});

const handleSetProfileField = profileField => (
  state = initialState,
  { payload },
) => ({
  ...state,
  draftProfile: {
    ...state.draftProfile,
    [profileField]: payload,
  },
});

const handleSetProfileFields = (
  state = initialState,
  { payload: { age, birthdayMonth, ...restFieldUpdates } },
) => ({
  ...state,
  draftProfile: {
    ...state.draftProfile,
    ...{
      age: Number(age),
      birthdayMonth: Number(birthdayMonth),
      ...restFieldUpdates,
    },
  },
});

const handleCreateAccount = (
  state = initialState,
  { payload: { email, marketingOptin, credential } },
) => ({
  ...state,
  account: {
    ...state.account,
    email,
    marketingOptin,
    // TODO: Remove this when possible
    credential,
  },
});

const setEmailVerifiedReducer = (state = initialState, payload) => ({
  ...state,
  emailVerified: payload,
});

const setSelectedProfileReducer = (state = initialState, { payload }) => ({
  ...state,
  selectedProfile: payload,
});

const handlePopulateProfiles = (
  state = initialState,
  { payload: newProfiles },
) => {
  const oldProfilesMap = state.profiles.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});

  return {
    ...state,
    profiles: [
      ...newProfiles.map(profile => ({
        ...oldProfilesMap[profile.id],
        ...profile,
      })),
    ],
  };
};

const handleRemoveProfile = (state = initialState, { payload: id }) => ({
  ...state,
  profiles: [...state.profiles.filter(profile => profile.id !== id)],
});

const handlePopulateProfileCustomData = (
  state = initialState,
  { payload: { id, ...customData } },
) => ({
  ...state,
  profiles: [
    ...state.profiles.map(profile =>
      profile.id !== id ? profile : { ...profile, ...customData },
    ),
  ],
});

const handleUpdateProfileData = (
  state = initialState,
  { payload: { storeId, ...data } },
) => ({
  ...state,
  profiles: [
    ...state.profiles.map(profile =>
      profile.id !== storeId ? profile : { ...profile, ...data },
    ),
  ],
});

const handleSetProfileDeepLink = (
  state = initialState,
  { payload: deepLinkValue },
) => ({
  ...state,
  deepLink: deepLinkValue,
});

const handleSetDeepLinkCorrectAge = (
  state = initialState,
  { payload: deepLinkAgeMatchValue },
) => ({
  ...state,
  deepLinkAgeMatch: deepLinkAgeMatchValue,
});

const handleShowGuestProfileImage = (state = initialState, { payload }) => ({
  ...state,
  showGuestProfileImage: payload,
});

const handleResetState = () => ({ ...initialState });

const reducer = handleActions(
  {
    [actions.setSignupState]: handleSetSignupState,
    [actions.setTvCode]: handleSetTvCode,
    [actions.populateProfiles]: handlePopulateProfiles,
    [actions.populateProfileCustomData]: handlePopulateProfileCustomData,
    [actions.updateProfileData]: handleUpdateProfileData,
    [actions.removeProfile]: handleRemoveProfile,
    [actions.setSelectedProfile]: setSelectedProfileReducer,
    [actions.editProfile]: handleEditProfile,
    [actions.addProfile]: handleAddProfile,
    [actions.saveProfile]: handleSaveProfile,
    [actions.discardProfile]: handleDiscardProfile,
    [actions.setProfileName]: handleSetProfileField('name'),
    [actions.setProfileAge]: handleSetProfileField('age'),
    [actions.setProfileBirthdayMonth]: handleSetProfileField('birthdayMonth'),
    [actions.setProfileAvatar]: handleSetProfileField('avatar'),
    [actions.setProfileSyncData]: handleSetProfileField('syncData'),
    [actions.setProfileFields]: handleSetProfileFields,
    [actions.setProfileDeepLink]: handleSetProfileDeepLink,
    [actions.setDeepLinkCorrectAge]: handleSetDeepLinkCorrectAge,
    [actions.createAccount]: handleCreateAccount,
    [actions.setEmailVerified]: setEmailVerifiedReducer,
    [actions.resetState]: handleResetState,
    [actions.showGuestProfileImage]: handleShowGuestProfileImage,
  },
  initialState,
);

// export default reducer;
export default persistenceLayer(
  reducer,
  initialState,
  ONBOARDING_ACTION_PREFIX,
);
