import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './ModalManager.scss';
import ModalContainer from '#/views/ModalContainer/ModalContainer';

import { sendScreenView } from '#/redux/modules/firestore/actions';

import {
  isOpen,
  getModal,
  getSuccessAction,
  getFinallyAction,
} from '#/redux/modules/modal/selectors';

import { modalScreenNames } from '#/config/constants';

import * as commonModals from '#/widgets/modals/common';
import * as modalModules from '../modalIds';

/*
 * modalManager renders out a modal component inside a base modal component. modalIds
 * re-exports modules of different modal types (widgets/modals/content, widgets/modals/custom, etc). ModalManager
 * searches the modules for the first occurance of modalName (pulled from react store).
 * The module name under which the target modal component is found (content, custom, etc) is used to determine the base modal componenet.
 * The base modal component will be one of the components found under widget/modals/common and its name is the pascal case
 * version of the value of modalName appended with 'Modal' except for modals found under the widget/modals/custom module,
 * where the base modal name will be BaseModal. Ie. if modalName is 'TocModal' (found under widgets/modals/content module), the
 * base modal name will be 'ContentModal'. If modalName is 'AgeModal' (found under widget/modals/custom module), the
 * base modal name will be 'BaseModal'
 * */

const ModalManager = () => {
  const dispatch = useDispatch();
  const modalOpen = useSelector(isOpen);
  const modalName = useSelector(getModal);
  const successAction = useSelector(getSuccessAction);
  const finallyAction = useSelector(getFinallyAction);

  // Track modal views
  useEffect(() => {
    dispatch(sendScreenView(modalScreenNames[modalName]));
  }, [modalName]);

  const [moduleName, moduleModals] =
    Object.entries(modalModules).find(([, modals]) => !!modals[modalName]) ||
    [];

  // Error handling
  if (!modalOpen) {
    return <span className={styles.modalWarning}>Modal not open</span>;
  }
  if (!moduleName) {
    return <span className={styles.modalError}>Modal not found</span>;
  }

  const ModalComponent = moduleModals[modalName];
  if (moduleName === 'custom') {
    return (
      <ModalContainer>
        <ModalComponent
          successAction={successAction}
          finallyAction={finallyAction}
        />
      </ModalContainer>
    );
  }

  const baseModalName = `${moduleName.charAt(0).toUpperCase() +
    moduleName.slice(1)}Modal`;
  const BaseModalComponent = commonModals[baseModalName];

  return (
    <ModalContainer>
      <BaseModalComponent
        successAction={successAction}
        finallyAction={finallyAction}
      >
        <ModalComponent
          successAction={successAction}
          finallyAction={finallyAction}
        />
      </BaseModalComponent>
    </ModalContainer>
  );
};

export default ModalManager;
