/* eslint-disable no-useless-escape */
const webLink = /(((https?\:\/\/)|(www\.))(\S+))/gim;
const mailLink = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;

export const parseTextForLinks = inputText =>
  inputText
    .replace(webLink, '<a href="http://$1" target="_blank">$1</a>')
    .replace(mailLink, '<a href="mailto:$1">$1</a>');

export default parseTextForLinks;
