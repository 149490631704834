import React from 'react';
import { useSelector } from 'react-redux';
// import cx from 'classnames';
import { getModalData } from '#/redux/modules/modal/selectors';
import { AgeCircle } from '#/widgets/ui/AgeCircle/AgeCircle';
import BaseModal from '../../common/BaseModal/BaseModal';
import styles from './AgeSelectionGuest.scss';

// focus handling
import FocusModal from '../../common/FocusModal/FocusModal';
import { FocusDiv } from '#/widgets/ui';
import { TV_CONTENT_MODAL } from '#/utils/navigationHelper';
import { isWorkstation } from '#/utils/componentStyleConfig';

const { CONTAINER, TV_CONTENT_MODAL_ACTION_BUTTON } = TV_CONTENT_MODAL;

const buttonText = 'Start watching';

const AgeSelectionGuest = () => {
  const { ageRange, color, info1, info2, info3 } = useSelector(getModalData);
  const infoBlocks = info2.split('·');

  return (
    <FocusModal>
      <BaseModal
        saveButtonText={buttonText}
        saveButtonContainerClassName={styles.saveBtn}
        nav={{ parent: CONTAINER, id: TV_CONTENT_MODAL_ACTION_BUTTON }}
        showCloseX={!!isWorkstation}
      >
        <FocusDiv
          className={styles.container}
          nav={{ id: CONTAINER, forwardFocus: TV_CONTENT_MODAL_ACTION_BUTTON }}
        >
          <AgeCircle
            ageRange={ageRange}
            color={color}
            styleClasses={{
              containerClass: styles.circleContainer,
              textClass: styles.circleContent,
              rangeClass: styles.circleContent,
            }}
            size={185}
          />
          <div className={styles.textBlock}>
            <p>{info1}</p>
            <div className={styles.textBlockList}>
              <ul>
                {infoBlocks
                  .filter(infoText => infoText.length > 1)
                  .map(infoBlock => (
                    <li>{infoBlock}</li>
                  ))}
              </ul>
            </div>
            <p>{info3}</p>
          </div>
        </FocusDiv>
      </BaseModal>
    </FocusModal>
  );
};

export default AgeSelectionGuest;
