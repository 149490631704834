import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { authFlowRoutes } from '#/utils/routes';

import { actions as userActions } from '#/redux/modules/user/actions';
import { actions as onboardingActions } from '#/redux/modules/onboarding/actions';
import ExitBlack from '#/static/images/icons/exit-black.svg';
import { isGuestMode } from '#/redux/modules/user/selectors';
import { logout } from '#/redux/modules/identity/identityGuest/actions';

import { cancelBtn } from '../authflow.scss';

const { resetState } = onboardingActions;
const { resetUserState } = userActions;

const CancelButton = ({ history, staticContext, ...props }) => {
  const dispatch = useDispatch();
  const guestMode = useSelector(isGuestMode);

  const onCancelClick = useCallback(() => {
    dispatch(resetState());
    dispatch(resetUserState());
    guestMode && dispatch(logout(authFlowRoutes.GUEST_PROFILE));
    history.push(authFlowRoutes.GUEST_PROFILE);
  }, []);

  return (
    <button onClick={onCancelClick} className={cancelBtn} {...props}>
      <img src={ExitBlack} alt="cancel" />
      Cancel
    </button>
  );
};

CancelButton.propTypes = {
  history: PropTypes.object,
  staticContext: PropTypes.any,
};

export default withRouter(CancelButton);
