import React, { useEffect, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Page } from '@accedo/vdkweb-tv-ui';
import classNames from 'classnames';
import moment from 'moment-mini';
import {
  ageGroupsKeys,
  streamsAgeGroup,
  playerConstants,
} from '#/config/constants';
import {
  sendVideoLiveHistory,
  loadEpg,
} from '#/redux/modules/appPlayer/actions';

import { getCurrentProfileAgeGroup } from '#/redux/modules/onboarding/selectors';
import { getEpgProgramList } from '#/redux/modules/appPlayer/selectors';
import styles from './LiveTV.scss';

import LivePlayer from '#/components/LivePlayer/LivePlayer';
import PlayerCCButton from '#/widgets/ui/Buttons/PlayerCCButton';
import PlayerBackButton from '#/widgets/ui/Buttons/PlayerBackButton';
import liveLogoUri from '#/static/images/icons/live-logo.svg';
import liveLogoBlack from '#/static/images/icons/live-mask.svg';
import liveLogoTVri from '#/static/images/icons/live-logo-tv.svg';
import { LIVE_TV } from '#/utils/navigationHelper';
import { getDeepLinkStarted } from '#/redux/modules/lifecycle/selectors';

import {
  isWorkstation,
  isTouchDevice,
  // isSmallScreen,
  isPortable,
} from '#/utils/componentStyleConfig';

import { FocusDiv } from '#/widgets/ui';

const { PAGE, CONTAINER, PLAYER_CC_BUTTON } = LIVE_TV;

const navMap = {
  PAGE: {
    id: PAGE,
    forwardFocus: CONTAINER,
  },
  CONTAINER: {
    id: CONTAINER,
    nextup: PLAYER_CC_BUTTON,
  },
  PLAYER_CC_BUTTON: {
    id: PLAYER_CC_BUTTON,
    nextdown: CONTAINER,
  },
};
const VOLUME_CONTROL_CLASS = 'vjs-volume-panel';

const decodeHtmlCharCodes = s => {
  const temp = document.createElement('p');
  temp.innerHTML = s || '';
  const str = temp.textContent || temp.innerText;
  return str || '';
};

const BackButton = () => {
  return (
    <>
      {isTouchDevice() ? (
        <div className={styles.backBtnContainer}>
          <PlayerBackButton isLivePlayer />
        </div>
      ) : (
        (isWorkstation() && <PlayerBackButton isLivePlayer />) || null
      )}
    </>
  );
};

const LiveOverlay = ({
  timeRange,
  title,
  desc,
  nextProgram,
  logoUri,
  nextProgramDetails,
  topOverlay,
}) => {
  const liveLogo = isPortable()
    ? liveLogoBlack
    : !isWorkstation()
    ? liveLogoTVri
    : liveLogoUri;

  return (
    <div id="topOverlay" className={styles.topOverlay} ref={topOverlay}>
      <BackButton />
      <div className={styles.detailsContent}>
        <p>{timeRange}</p>
        <h2>{decodeHtmlCharCodes(title)}</h2>
        <p>{decodeHtmlCharCodes(desc)}</p>
      </div>
      <div className={styles.toggles}>
        <img className={styles.liveLogo} src={liveLogo} alt="live" />
        <div>
          <PlayerCCButton
            containerClassName={styles.ccButtonContainer}
            className={styles.ccButton}
            nav={navMap.PLAYER_CC_BUTTON}
          />
        </div>
      </div>
      <div className={styles.gradient}>
        {!isWorkstation() && nextProgram && (
          <div className={styles.nextProgram}>
            <p>{nextProgramDetails.upNextAt}</p>
            <h2>{nextProgramDetails.title}</h2>
          </div>
        )}
        <img className={styles.logoImg} src={logoUri} alt={title} />
      </div>
    </div>
  );
};

LiveOverlay.propTypes = {
  timeRange: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
  nextProgram: PropTypes.object,
  logoUri: PropTypes.string,
  nextProgramDetails: PropTypes.object,
  topOverlay: PropTypes.object,
};

const LiveTV = ({ content, match }) => {
  const [currentProgram, setCurrentProgram] = useState();
  const [nextProgram, setNextProgram] = useState();
  const dispatch = useDispatch();
  const currentPath = 'livetv';
  const topOverlay = useRef();
  const topOverlayHideTimer = useRef();
  const epgUpdateTimer = useRef();
  const currentProgramIndex = useRef(0);
  const epgProgramData = useSelector(getEpgProgramList);

  // Deep linking ------------------------------------------------------------------
  // Only deeplinked route will have age param.
  // If that's the case, then force the stream
  const streamAge = match?.params?.age || 0;
  const deepLinkStarted = useSelector(getDeepLinkStarted);
  // Deep linking END --------------------------------------------------------------

  const selectedAgeGroup = useSelector(state =>
    getCurrentProfileAgeGroup(state),
  );
  const selectedStream = useMemo(() => {
    if (deepLinkStarted) {
      return (
        streamsAgeGroup?.[`STREAM_${streamAge}Up`] || streamsAgeGroup.STREAM_8Up
      );
    }
    switch (selectedAgeGroup) {
      case ageGroupsKeys.TWO_UP:
        return streamsAgeGroup.STREAM_2Up;
      case ageGroupsKeys.FIVE_UP:
        return streamsAgeGroup.STREAM_5Up;
      default:
        return streamsAgeGroup.STREAM_8Up;
    }
  }, [selectedAgeGroup, deepLinkStarted, streamAge]);

  const [liveStreamUrl, epgUrl] = useMemo(() => {
    const { key, epgKey } = selectedStream || {};
    return [key, epgKey].map(listKey => content?.[currentPath]?.[listKey]);
  }, [content, selectedStream]);

  useEffect(() => {
    if (epgUrl && !epgProgramData[epgUrl]) {
      dispatch(loadEpg(epgUrl));
    }
  }, [epgUrl]);

  useEffect(() => {
    if (!epgProgramData[epgUrl] || epgProgramData[epgUrl].length <= 0) {
      return;
    }

    const epgUpdate = () => {
      clearTimeout(epgUpdateTimer);

      const programList = epgProgramData[epgUrl];
      const currentTime = Date.now() / 1000;

      // check if same program is running for early return
      if (
        currentTime >= programList[currentProgramIndex.current]?.start &&
        currentTime <= programList[currentProgramIndex.current]?.end
      ) {
        epgUpdateTimer.current = setTimeout(
          epgUpdate,
          (60 - new Date().getSeconds()) * 1000,
        );
        return;
      }

      const programIndex = programList.findIndex(
        program => currentTime >= program.start && currentTime <= program.stop,
      );

      if (programIndex > -1) {
        currentProgramIndex.current = programIndex;
        setCurrentProgram(programList[programIndex]);

        setNextProgram(programList[programIndex + 1]);
        epgUpdateTimer.current = setTimeout(
          epgUpdate,
          (60 - new Date().getSeconds()) * 1000,
        );
      } else {
        currentProgramIndex.current = 0;
        dispatch(loadEpg(epgUrl));
        epgUpdateTimer.current = null;
      }
    };
    epgUpdate();

    return () => clearTimeout(epgUpdateTimer.current);
  }, [epgProgramData]);

  useEffect(() => {
    const mainRoutesContainer = document.getElementById(
      'MainRoutesContainer-container',
    );
    mainRoutesContainer.classList.add(styles.mainRoutesContainer);
    return () => {
      mainRoutesContainer.classList.remove(styles.mainRoutesContainer);
      // send live history event when unmounted
      dispatch(
        sendVideoLiveHistory(`Sensical${selectedStream.title}`, liveStreamUrl),
      );
    };
  }, []);

  // Effect to transition overlay opacity for animation
  useEffect(() => {
    if (topOverlay.current) {
      const volumeControl = topOverlay.current.previousSibling.getElementsByClassName(
        VOLUME_CONTROL_CLASS,
      )[0];
      requestAnimationFrame(() => {
        topOverlay.current.style.opacity = 1;
        topOverlay.current.style.visibility = 'visible';
        volumeControl && (volumeControl.style.opacity = 1);
        volumeControl && (volumeControl.style.visibility = 'visible');
      });

      const onMouseInteraction = () => {
        topOverlay.current.style.opacity = 1;
        volumeControl && (volumeControl.style.visibility = 'visible');
        volumeControl && (volumeControl.style.opacity = 1);
        document.body.style.cursor = '';
        clearTimeout(topOverlayHideTimer.current);
        topOverlayHideTimer.current = setTimeout(() => {
          topOverlay.current.style.opacity = 0;
          volumeControl && (volumeControl.style.opacity = 0);
          volumeControl && (volumeControl.style.visibility = 'hidden');
          document.body.style.cursor = 'none';
        }, playerConstants.HIDE_OVERLAY_TIMEOUT);
      };

      const onKeyDown = () => {
        if (isWorkstation()) {
          return;
        }
        topOverlay.current.style.opacity = 1;
        clearTimeout(topOverlayHideTimer.current);
        topOverlayHideTimer.current = setTimeout(() => {
          topOverlay.current.style.opacity = 0;
        }, playerConstants.HIDE_OVERLAY_TIMEOUT);
      };

      if (isPortable()) {
        document.addEventListener('touchstart', onMouseInteraction);
        document.addEventListener('touchmove', onMouseInteraction);
        onMouseInteraction(); // initial interaction for timer
      }

      document.addEventListener('mousemove', onMouseInteraction);
      document.addEventListener('click', onMouseInteraction);
      document.addEventListener('keydown', onKeyDown);
      return () => {
        document.body.style.cursor = '';
        clearTimeout(topOverlayHideTimer.current);
        document.removeEventListener('mousemove', onMouseInteraction);
        document.removeEventListener('click', onMouseInteraction);
        document.removeEventListener('keydown', onKeyDown);
        document.removeEventListener('touchstart', onMouseInteraction);
        document.removeEventListener('touchmove', onMouseInteraction);
      };
    }
    document.body.style.cursor = '';
    clearTimeout(topOverlayHideTimer.current);
  }, [topOverlay.current]);

  const { title, timeRange, desc } = currentProgram || {};
  const { logoUri } = selectedStream || {};
  const nextProgramDetails = {
    title: nextProgram?.title,
    upNextAt: `Up next at ${moment.unix(nextProgram?.start).format('h:mm A')}`,
  };

  return (
    <Page nav={navMap.PAGE}>
      <FocusDiv nav={navMap.CONTAINER}>
        <div
          className={classNames(styles.liveTvContainer, {
            [styles.isTv]: !isWorkstation(),
          })}
        >
          <LivePlayer key={liveStreamUrl} url={liveStreamUrl} />
          <LiveOverlay
            timeRange={timeRange}
            title={title}
            desc={currentProgram?.['sub-title'] || desc}
            nextProgram={nextProgram}
            logoUri={logoUri}
            nextProgramDetails={nextProgramDetails}
            topOverlay={topOverlay}
          />
        </div>
      </FocusDiv>
    </Page>
  );
};

LiveTV.propTypes = {
  content: PropTypes.object,
  // history: PropTypes.object,
  match: PropTypes.object,
};

export default LiveTV;
