import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Skiddle from '#/static/images/icons/skiddle-green.svg';
import ErrorDispatcher from '../../ErrorDispatcher/ErrorDispatcher';
import { getAppStarted } from '#/redux/modules/lifecycle/selectors';
import {
  ASYNC_CONFIRM_TOKEN,
  confirmToken,
} from '#/redux/modules/identity/actions';
import { getVerificationEmail } from '#/redux/modules/identity/selectors';
import {
  tokenConfirmationTitle,
  tokenConfirmationDesc,
} from '#/config/strings';

import styles from './EmailVerificationConfirmation.scss';

const TokenConfirmInProgress = () => {
  return (
    <>
      <h1 className={styles.title}>Confirming email</h1>
      <p>Please wait while we confirm your email.</p>
    </>
  );
};

const TokenConfirmed = ({ verificationEmail }) => {
  return (
    <>
      <h1 className={styles.title}>{tokenConfirmationTitle}</h1>
      {verificationEmail ? <p>Hi there, {verificationEmail}</p> : null}
      <p className={styles.tokenConfirmationDescription}>
        {tokenConfirmationDesc}
      </p>
    </>
  );
};
TokenConfirmed.propTypes = {
  verificationEmail: PropTypes.string,
};

const TokenError = () => {
  return (
    <>
      <h1 className={styles.title}>Error confirming email</h1>
      <p>There was a problem confirming your email address.</p>
    </>
  );
};

const EmailVerificationConfirmation = ({ location: { search } }) => {
  const dispatch = useDispatch();
  const token = new URLSearchParams(search).get('token_value');
  const appStarted = useSelector(getAppStarted);
  const verificationEmail = useSelector(getVerificationEmail);
  const [inProgress, setInProgress] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    async function dispatchConfirm() {
      try {
        setInProgress(true);
        setError(false);
        await dispatch(confirmToken(token));
        setConfirmed(true);
      } catch (e) {
        setError(true);
      } finally {
        setInProgress(false);
      }
    }
    if (appStarted) {
      dispatchConfirm();
    }
  }, [appStarted, token]);

  return (
    <div className={styles.emailVerificationConfirmation}>
      <ErrorDispatcher errorId={ASYNC_CONFIRM_TOKEN} />
      <div className={styles.content}>
        <img className={styles.logo} src={Skiddle} alt="Logo" />
        {inProgress && <TokenConfirmInProgress />}
        {confirmed && <TokenConfirmed {...{ verificationEmail }} />}
        {error && <TokenError />}
        {!inProgress && !confirmed && !error && <TokenConfirmInProgress />}
      </div>
    </div>
  );
};
EmailVerificationConfirmation.propTypes = {
  location: PropTypes.any,
};

export default EmailVerificationConfirmation;
