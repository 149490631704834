import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { usePrevious } from '#/utils/hooks';

import { getError } from '#/redux/modules/identity/selectors';
import { actions as modalActions } from '#/redux/modules/modal/actions';
import { actions as identityActions } from '#/redux/modules/identity/actions';

const ErrorDispatcher = ({ errorId }) => {
  const dispatch = useDispatch();
  const errors = useSelector(getError(errorId));
  const previousErrors = usePrevious(errors);

  useEffect(() => {
    if (errors !== previousErrors) {
      if (errors?.errorMessage) {
        dispatch(
          modalActions.openModal({
            modalId: 'ErrorModal',
            modalData: { errorMessage: errors.errorMessage },
            finallyAction: {
              type: String(identityActions.discardError),
              payload: errorId,
            },
            successAction: {
              type: String(identityActions.discardError),
              payload: errorId,
            },
          }),
        );
      }
    }
  }, [errors, previousErrors]);

  return <></>;
};
ErrorDispatcher.propTypes = {
  errorId: PropTypes.string,
};

export default ErrorDispatcher;
