/* eslint-disable react/no-danger */

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { requestPrivacyPolicy } from '#/redux/modules/accedoOne/actions';
import { getPrivacyPolicy } from '#/redux/modules/accedoOne/selectors';

import parseTextForLinks from '#/utils/parseTextForLinks';
import styles from './PrivacyPolicy.scss';

export const PrivacyPolicy = () => {
  const dispatch = useDispatch();

  const privacyPolicyText = useSelector(state => getPrivacyPolicy(state));

  useEffect(() => {
    dispatch(requestPrivacyPolicy);
  }, []);

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: privacyPolicyText && parseTextForLinks(privacyPolicyText),
      }}
      className={styles.privacyPolicyText}
    />
  );
};

PrivacyPolicy.propTypes = {};

export default PrivacyPolicy;
