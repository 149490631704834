import * as selectorsGuest from '#/redux/modules/guest/selectors';
import * as selectorsUser from './identityUser/selectors';

/**
 * Dynamically change to the right selector (user or guest)
 * each selector will point to its own entry on the store.
 * If the selector is not implemented, it will default to the
 * selector in the other store.
 */

let guestModeActive = false;
export const setGuestMode = isGuest => {
  guestModeActive = isGuest;
};

const guest = 'guest';
const user = 'user';
const {
  getRootState: userRootSelector,
  setRootSelector: setRootSelectorUser,
} = selectorsUser;
const { getRootState: guestRootSelector } = selectorsGuest;

const enableLogs = false;
const getDefaultSelector = (name, fallbackSelector, missing, fallback) => {
  // Dummy selector that calls the fallback selector
  if (process.env.NODE_ENV === 'production' || !enableLogs) {
    return (...args) => fallbackSelector(...args);
  }

  // WARNING: Due to the render nature of react, logs can be bloated
  // Enable logs with caution
  return (...args) => {
    // eslint-disable-next-line no-console
    console.log(
      `[Selector] ${name} not implemented on ${missing}.`,
      `Fallback to ${fallback} selector`,
    );
    return fallbackSelector(...args);
  };
};

const getAllNames = (userSelectors, guestSelectors) => [
  ...Object.keys(guestSelectors),
  ...Object.keys(userSelectors),
];

const allExports = getAllNames(selectorsUser, selectorsGuest);

const identitySelectors = allExports.reduce((selectors, name) => {
  const userSelector =
    selectorsUser[name] ||
    getDefaultSelector(name, selectorsGuest[name], user, guest);
  const guestSelector =
    selectorsGuest[name] ||
    getDefaultSelector(name, selectorsUser[name], guest, user);

  const selector = (...args) =>
    guestModeActive
      ? guestSelector(...args, userRootSelector, guestRootSelector)
      : userSelector(...args, userRootSelector, guestRootSelector);

  return {
    ...selectors,
    [name]: selector,
  };
}, {});

// Export right 'setRootSelector' function for identity module
export const setRootSelector = setRootSelectorUser;

// Base selectors
export const {
  getProcessId,
  getVerificationEmail,
  getPkat,
  getGroupId,
  getParentUserId,
  getParentAccount,
  getPin,
  getError,
  getParentMode,
  getOnboardingSuccess,
  getCustomDataVideoBookmark,
  getCustomDataShowsFavorites,
  getCustomDataShowsFavoritesById,
  getCustomDataPodcastsFavorites,
  getCustomDataPodcastsFavoritesById,
  getCustomDataVideoFavorites,
  getCustomDataVideoFavoritesById,
  getCustomDataPodcastsEpisodesFavorites,
  getCustomDataPodcastsEpisodesFavoritesById,
  getCustomDataTopicsFavorites,
  getCustomDataTopicsFavoritesById,
  getAccessToken,
  getRefreshToken,
  getSessionData,
  getCustomDataRecentlyWatched,
  getCustomDataRecentlyWatchedIds,
  getCustomDataDisplayedModals,
  getCustomDataVideosBlocked,
  getCustomDataShowsBlocked,
} = identitySelectors;
