import { handleActions } from 'redux-actions';
import { actions } from './actions';

const initialState = {
  loadingSpinner: false,
};

const toggleSpinnerReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    loadingSpinner: payload,
  };
};

const toggleLoginSpinnerReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    loadingLoginSpinner: payload,
  };
};

const reducer = handleActions(
  {
    [actions.toggleSpinner]: toggleSpinnerReducer,
    [actions.toggleLoginSpinner]: toggleLoginSpinnerReducer,
  },
  initialState,
);

export default reducer;
