import React from 'react';
import { useSelector } from 'react-redux';

import { getModalData } from '#/redux/modules/modal/selectors';

import { MostWatched } from '#/components/ParentZone/LearningReports/LearningItem/LearningItem';

import { chunkArray } from '#/config/helpers';

import BaseModal from '../../common/BaseModal/BaseModal';

import { container, content, mostWatchedRail } from './mostWatchedModal.scss';

const MostWatchedModal = () => {
  const data = useSelector(getModalData);

  const chunkedData = chunkArray(data || [], 5);

  return (
    <BaseModal className={container} showSaveButton={false}>
      <h1>Most Watched</h1>
      <div className={content}>
        {chunkedData.map((row, idx) => (
          <MostWatched
            key={`row-${idx}`}
            items={row}
            railTitle={null}
            className={mostWatchedRail}
          />
        ))}
      </div>
    </BaseModal>
  );
};

export default MostWatchedModal;
