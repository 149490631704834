import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isSinkInFocus, getLastUpdate } from '#/redux/modules/focus/selectors';

const FocusSink = () => {
  const sinkFocused = useSelector(isSinkInFocus);
  const lastUpdate = useSelector(getLastUpdate);
  const focusSink = useRef(null);

  useEffect(() => {
    if (sinkFocused) {
      focusSink.current.focus();
    }
  }, [lastUpdate, sinkFocused]);

  return (
    <button
      style={{ position: 'fixed', top: '0px', display: 'none' }}
      ref={focusSink}
      id="focus-sink"
      aria-hidden="true"
    />
  );
};

export default FocusSink;
