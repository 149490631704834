import React, {
  useEffect,
  useCallback,
  useMemo,
  useState,
  useRef,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';

import ProfileCard from '../../ProfileCard/ProfileCard';
import ErrorDispatcher from '../../ErrorDispatcher/ErrorDispatcher';
import { Avatar, Button } from '#/widgets/ui';
import {
  InfoForm,
  InfoInput,
  Select,
  AvatarModalSelect,
} from '#/widgets/forms';
import { sendScreenView } from '#/redux/modules/firestore/actions';
import {
  actions as onboardingActions,
  newDraftProfile,
  updateProfileId,
} from '#/redux/modules/onboarding/actions';
import {
  getSavedProfiles,
  getCurrentProfile,
  getDraftProfile,
  getIsDraftProfileUnsaved,
  getMonths,
  getAgeBrackets,
} from '#/redux/modules/onboarding/selectors';
import {
  actions as identityActions,
  ADD_UNSAVED_PROFILES,
  addUnsavedProfiles,
  UPDATE_PROFILE,
  updateProfile,
  ASYNC_REMOVE_PROFILE,
  removeProfile,
  clearCustomDataDisplayedModals,
} from '#/redux/modules/identity/actions';
import { actions as modalActions } from '#/redux/modules/modal/actions';
import { chunkArray } from '#/config/helpers';

import { generalErrors, modalDialogs } from '#/config/strings';
import { parentZoneProfileScreenNames } from '#/config/constants';
import styles from './KidsProfile.scss';

const EditProfile = () => {
  const dispatch = useDispatch();
  const currentProfile = useSelector(getCurrentProfile);
  const draftProfile = useSelector(getDraftProfile);
  const isDraftProfileUnsaved = useSelector(getIsDraftProfileUnsaved);
  const months = useSelector(getMonths);
  const ageBrackets = useSelector(getAgeBrackets);
  const [editMode, setEditMode] = useState(isDraftProfileUnsaved);
  const [isProfileValid, setProfileValid] = useState(false);
  let currentBdayMonth = useRef(0);
  let currentBdayDay = useRef(0);

  useEffect(() => {
    currentBdayDay.current = currentProfile?.age;
    currentBdayMonth.current = currentProfile?.birthdayMonth;
  }, []);
  const avatarDisplay = useMemo(() => <Avatar profile={draftProfile} />, [
    draftProfile,
  ]);

  useEffect(() => {
    editMode
      ? dispatch(sendScreenView(parentZoneProfileScreenNames.KIDS_PROFILE_EDIT))
      : dispatch(
          sendScreenView(parentZoneProfileScreenNames.KIDS_PROFILE_OVERVIEW),
        );
  }, [editMode]);

  const handleSaveProfile = useCallback(async () => {
    if (
      currentBdayDay.current !== draftProfile?.age ||
      currentBdayMonth.current !== draftProfile?.birthdayMonth
    ) {
      dispatch(clearCustomDataDisplayedModals());
    }
    if (isDraftProfileUnsaved) {
      try {
        dispatch(onboardingActions.saveProfile());
        dispatch(addUnsavedProfiles(updateProfileId));
      } catch (e) {
        dispatch(onboardingActions.removeProfile(draftProfile.id));
      }
    } else {
      try {
        await dispatch(updateProfile(draftProfile.id, draftProfile));
        dispatch(onboardingActions.saveProfile());
      } catch (e) {
        dispatch(onboardingActions.discardProfile());
      }
    }
  }, [draftProfile]);

  const handleRemoveProfile = useCallback(async () => {
    const removeProfileCallback = async () => {
      await dispatch(removeProfile(draftProfile.id));
      dispatch(onboardingActions.removeProfile(draftProfile.id));
      dispatch(onboardingActions.discardProfile());
    };

    if (currentProfile.id === draftProfile.id) {
      return dispatch(
        identityActions.setError(ASYNC_REMOVE_PROFILE, {
          errorMsg: { customError: generalErrors.cantRemovecurrentProfile },
        }),
      );
    }

    dispatch(sendScreenView(parentZoneProfileScreenNames.KIDS_PROFILE_DELETE));
    dispatch(
      modalActions.openModal({
        modalId: 'ConfirmModal',
        successAction: removeProfileCallback,
        modalData: modalDialogs.confirmDeleteKidProfile,
      }),
    );
  }, [draftProfile, currentProfile]);

  return (
    <div className={styles.editProfileContainer}>
      <ErrorDispatcher errorId={UPDATE_PROFILE} />
      <ErrorDispatcher errorId={ASYNC_REMOVE_PROFILE} />
      <div className={cx(styles.editProfile, { [styles.hidden]: !editMode })}>
        <AvatarModalSelect
          avatarDisplay={avatarDisplay}
          modalName="AvatarModal"
          successActionType={String(onboardingActions.setProfileAvatar)}
        />
        <InfoForm
          onChange={(data, isValid) => {
            setProfileValid(isValid);
            isValid && dispatch(onboardingActions.setProfileFields(data));
          }}
        >
          <InfoInput
            fieldName="name"
            fieldLabel="Kid's name"
            fieldValue={draftProfile.name}
            placeHolder="Name"
            inputAttributes={{
              type: 'text',
              pattern: '[a-zA-Z]+',
              required: 'required',
            }}
          />
          <Select
            fieldName="age"
            fieldLabel="Kid's age"
            fieldSelected={draftProfile.age}
            fieldOptions={ageBrackets}
            inputAttributes={{
              required: 'required',
              placeholder: 'Select age',
            }}
          />
          <Select
            fieldName="birthdayMonth"
            fieldLabel="Kid's birthday month"
            fieldSelected={draftProfile.birthdayMonth}
            fieldOptions={months}
            inputAttributes={{
              required: 'required',
              placeholder: 'Select month',
            }}
          />
        </InfoForm>
        <div className={styles.saveButtonGroup}>
          <Button
            onClick={handleSaveProfile}
            selected={isProfileValid && !!draftProfile.avatar}
            disabled={!isProfileValid || !draftProfile.avatar}
          >
            Save
          </Button>
          <Button onClick={() => dispatch(onboardingActions.discardProfile())}>
            Cancel
          </Button>
        </div>
      </div>
      <div className={cx(styles.viewProfile, { [styles.hidden]: editMode })}>
        <div className={styles.profileDataContainer}>
          <Avatar profile={draftProfile} />
          <div>
            <p className={styles.label}>Name</p>
            <p className={styles.value}>{draftProfile.name}</p>
            <p className={styles.label}>Age</p>
            <p className={styles.value}>{ageBrackets[draftProfile.age]}</p>
            <p className={styles.label}>Birthday month</p>
            <p className={styles.value}>{months[draftProfile.birthdayMonth]}</p>
          </div>
        </div>
        <Button selected={false} onClick={handleRemoveProfile}>
          Delete profile
        </Button>
        <Button
          className={styles.backArrowButton}
          onClick={() => dispatch(onboardingActions.discardProfile())}
        >
          Back
        </Button>
        <Button className={styles.editButton} onClick={() => setEditMode(true)}>
          Edit
        </Button>
      </div>
    </div>
  );
};

export const KidsProfile = () => {
  const dispatch = useDispatch();
  const profiles = useSelector(getSavedProfiles);
  const draftProfile = useSelector(getDraftProfile);

  const baseballCards = useMemo(() => {
    if (!profiles) {
      return [];
    }

    return chunkArray(
      profiles.map(profile => (
        <ProfileCard
          className={styles.profileCard}
          key={profile.id}
          profile={profile}
          editMode={false}
          onSwitch={() => {
            dispatch(onboardingActions.editProfile({ profileId: profile.id }));
          }}
          onEdit={() => {}}
        />
      )),
    );
  }, [profiles]);

  const kidsProfilePage = useMemo(
    () => (
      <div className={styles.kidsProfilePage}>
        <ErrorDispatcher errorId={ADD_UNSAVED_PROFILES} />
        <h4>Select a Kid to View</h4>
        {baseballCards?.map((row, idx) => (
          <div
            key={`baseball-row-${idx}`}
            className={styles.baseballCardsContainer}
          >
            {row}
          </div>
        ))}
        <Button
          className={styles.addProfileButton}
          onClick={() => dispatch(newDraftProfile())}
        >
          Add profile
        </Button>
      </div>
    ),
    [baseballCards],
  );

  return !draftProfile ? kidsProfilePage : <EditProfile />;
};
KidsProfile.propTypes = {};

export default KidsProfile;
