import React, { useCallback } from 'react';
import xdk from '@accedo/xdk-core';
import screenfull from 'screenfull';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setVideoTime, exitPlayback } from '#/redux/modules/appPlayer/actions';
import { isGuestMode } from '#/redux/modules/user/selectors';
import { logout } from '#/redux/modules/identity/identityGuest/actions';
import { actions as identityActions } from '#/redux/modules/identity/actions';
import { getParentUserId } from '#/redux/modules/identity/selectors';
import { authFlowRoutes } from '#/utils/routes';
import {
  getCurrentProfileDeepLink,
  getCurrentProfileId,
} from '#/redux/modules/onboarding/selectors';
// import { actions as onboardingActions } from '#/redux/modules/onboarding/actions';
import {
  // deepLinkQuit,
  exitDeeplinkWorkflow,
} from '#/redux/modules/lifecycle/actions';
import {
  getDeeplinkOutsidePlayer,
  getDeepLinkStarted,
} from '#/redux/modules/lifecycle/selectors';
import styles from './PlayerBackButton.scss';
import BackArrow from '#/static/images/icons/arrow-back.svg';
import BackArrowBlack from '#/static/images/icons/arrow-back-black.svg';
import { isPortable } from '#/utils/componentStyleConfig';

const BackButton = ({ history, setTime = false, isLivePlayer = false }) => {
  const dispatch = useDispatch();
  const deepLinked = useSelector(getCurrentProfileDeepLink);
  const guestMode = useSelector(isGuestMode);
  const deeplinkOutsidePlayer = useSelector(getDeeplinkOutsidePlayer);
  const profileId = useSelector(getCurrentProfileId);
  const parentUserId = useSelector(getParentUserId);
  const deepLinkStarted = useSelector(getDeepLinkStarted);
  const fromMainPageRouter = history.location.state?.fromMainPage;

  const buttonClick = useCallback(() => {
    if (screenfull.isFullscreen) {
      screenfull.exit();
      return;
    }

    // if deeplinked from anywhere but the player itself
    if (deeplinkOutsidePlayer) {
      setTime && dispatch(setVideoTime(xdk.media?.getCurrentTime()));
      dispatch(exitPlayback(history));
      return;
    }

    dispatch(exitDeeplinkWorkflow());
    if (deepLinked && guestMode) {
      dispatch(logout(authFlowRoutes.GUEST_PROFILE));
    } else {
      if (profileId === parentUserId && deepLinkStarted) {
        dispatch(identityActions.setParentMode(true));
      }
      setTime && dispatch(setVideoTime(xdk.media?.getCurrentTime()));
      if (isLivePlayer && fromMainPageRouter) {
        history.go(0);
        return;
      }
      isLivePlayer ? history.replace('/') : dispatch(exitPlayback(history));
    }
  });

  return (
    <img
      className={styles.playerBackButton}
      src={isPortable() ? BackArrowBlack : BackArrow}
      onClick={buttonClick}
      alt="BACK"
      role="presentation"
    />
  );
};
BackButton.propTypes = {
  history: PropTypes.any,
  setTime: PropTypes.bool,
  isLivePlayer: PropTypes.bool,
};

const PlayerBackButton = withRouter(BackButton);

export default PlayerBackButton;
