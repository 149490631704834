/*
 * Export the Redux modules that you would like to
 * use with state management.
 *
 * This will be used by redux/reducer.js to walkthrough
 * all registered modules and add root selectors to them for
 * providing the base state of each module.
 */

/* TV modules */
import { pageRedux } from '@accedo/vdkweb-tv-ui';

/* Internal modules */
import * as config from './config';
import * as async from './async';
import * as lifecycle from './lifecycle';
import * as menu from './menu';
import * as status from './status';
import * as keys from './keys';
import * as user from './user';
import * as scroll from './scroll';
import * as spinner from './spinner';
import * as appPlayer from './appPlayer';
import * as platform from './platform';
import * as system from './system';
import * as onboarding from './onboarding';
import * as accedoOne from './accedoOne';
import * as modal from './modal';
import * as identity from './identity';
import * as guest from './guest';
import * as brightcove from './brightcove';
import * as firestore from './firestore';
import * as focus from './focus';

export default {
  config,
  lifecycle,
  menu,
  status,
  keys,
  async,
  user,
  pageRedux,
  scroll,
  appPlayer,
  spinner,
  platform,
  system,
  onboarding,
  accedoOne,
  modal,
  identity,
  guest,
  brightcove,
  firestore,
  focus,
};
