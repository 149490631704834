import React, { useState, useMemo, useEffect, useReducer, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import xdk, { util } from '@accedo/xdk-core';
import cx from 'classnames';
import styles from './playerModal.scss';
import { mainRoutes } from '#/utils/routes';
import {
  playerConstants,
  fullscreenPlayerScreenName,
  ageCategoryGroup,
} from '#/config/constants';
import VODPlayer from '#/components/Player/VODPlayer';
import {
  setVideoTime,
  setVideoContent,
  clearVideoFullscreenBookmark,
  setAdDetailsLoaded,
  setAdPlayed,
  clearPrerollAds,
  setBackgroundPlayerFullscreen,
} from '#/redux/modules/appPlayer/actions';
import {
  getCurrentVideo,
  getBookmarkedVideoContent,
  getFullscreenBookmarkedVideoContent,
  getPrerollAdDuration,
  getAdDetailsLoaded,
  getAdPlayed,
  getBackgroundPlayerFullscreen,
} from '#/redux/modules/appPlayer/selectors';

import { actions as onboardingActions } from '#/redux/modules/onboarding/actions';
import {
  getCurrentProfileId,
  getCurrentProfileMinAge,
} from '#/redux/modules/onboarding/selectors';
import { getAppStarted } from '#/redux/modules/lifecycle/selectors';
import {
  getAssetsByReferenceIds,
  getSearchData,
} from '#/redux/modules/brightcove/selectors';
import {
  clearSearchQuery,
  clearSearchData,
  loadAssetsByReferenceIds,
  saveSearchQuery,
  performSearch,
} from '#/redux/modules/brightcove/actions';

import {
  loadCustomDataVideoBookmark,
  clearCustomDataVideoBookmark,
  guestMode,
  actions as identityActions,
} from '#/redux/modules/identity/actions';
import { sendScreenView } from '#/redux/modules/firestore/actions';
import {
  getCustomDataVideoBookmark,
  getParentMode,
  getParentUserId,
} from '#/redux/modules/identity/selectors';

import useToggleHeader from '#/hooks/useToggleHeader';
import { isWorkstation, isPortable } from '#/utils/componentStyleConfig';
import assembleCurrentVideo from '#/utils/assembleCurrentVideo';

import LogoutRedirect from '#/components/LogoutRedirect/LogoutRedirect';

const FULLSCREEN_DELAY = 8000;

const PlayerModal = props => {
  const dispatch = useDispatch();
  const [key, forceUpdate] = useReducer(x => x + 1, 0);
  const [showVOD, setShowVOD] = useState(true);
  const currentVideo = useSelector(getCurrentVideo);
  const bookmarkedVideoContent = useSelector(getBookmarkedVideoContent);
  const fullscreenCustomBookmark = useSelector(getCustomDataVideoBookmark);
  const fullscreenBookmarkedVideoContent = useSelector(
    getFullscreenBookmarkedVideoContent,
  );
  const prerollAdDuration = useSelector(getPrerollAdDuration);
  const isAdDetailsLoaded = useSelector(getAdDetailsLoaded);
  const adPlayed = useSelector(getAdPlayed);
  const parentMode = useSelector(getParentMode);
  const playerContainer = useRef();
  const isBackgroundPlayerReady = useRef(false);
  const isFullscreenPlayerReady = useRef(false);
  const isFullScreenPlayer = useMemo(
    () => props.location.pathname.startsWith('/player'),
    [props.location.pathname],
  );
  const isBackgroundPlayer = useMemo(
    () => props.location.pathname === mainRoutes.HOME,
    [props.location.pathname],
  );

  const isBackgroundPlayerFullscreen = useSelector(state =>
    getBackgroundPlayerFullscreen(state),
  );

  const toggleHeader = useToggleHeader();

  const playbackBegun = xdk.media?.getState() === 'playing';

  // Deep linking
  const appStarted = useSelector(getAppStarted);
  const referenceId = props.match?.params?.referenceId;
  const videoAssetByReferenceId = useSelector(getAssetsByReferenceIds);
  const queryData = useSelector(state => getSearchData(state));
  const profileId = useSelector(getCurrentProfileId);
  const isUserNormalLogged = util.isNumber(profileId);
  const [deepLinkLanding, setDeepLinkLanding] = useState(false);
  const currentMinAge = useSelector(getCurrentProfileMinAge);
  const parentUserId = useSelector(getParentUserId);

  const { search } = window.location;

  useEffect(() => {
    // let's run this logic once, so we can understand if we reached this page using deep link
    if (!appStarted && referenceId) {
      setDeepLinkLanding(true);
    }
  }, []);

  useEffect(() => {
    if (!appStarted && deepLinkLanding) {
      dispatch(onboardingActions.setProfileDeepLink(true));
    }
  }, [deepLinkLanding]);

  // Effects for deep linking, load video assets if referenceId is present in url then set the playback content accordingly.
  useEffect(() => {
    if (appStarted && referenceId) {
      dispatch(loadAssetsByReferenceIds([{ referenceIds: [referenceId] }]));
    }
  }, [appStarted, referenceId]);

  useEffect(() => {
    if (!referenceId) {
      return;
    }

    if (deepLinkLanding) {
      props.history.replace('/');
      props.history.push(
        `/player/${referenceId}${search !== undefined ? search : ''}`,
      );
    } else {
      props.history.replace(
        `/player/${referenceId}${search !== undefined ? search : ''}`,
      );
    }

    if (videoAssetByReferenceId?.count) {
      const videoContent = videoAssetByReferenceId.videos[0];
      const { show_id, season_number, age_range } = videoContent?.custom_fields;
      const searchOpts = {
        show_id,
        season_number,
        age: age_range?.charAt(0),
        limit: queryData?.limit,
        page: 0,
        offset: 0,
      };
      const minAge = !Number.isNaN(age_range?.charAt(0))
        ? parseInt(age_range?.charAt(0))
        : ageCategoryGroup.MIN_AGE_TWO_UP;
      if (!isUserNormalLogged && deepLinkLanding) {
        if (!parentMode) {
          dispatch(guestMode({ age: minAge }));
        } else {
          dispatch(onboardingActions.setSelectedProfile(parentUserId));
          dispatch(identityActions.setParentMode(false));
        }
      }
      if (currentMinAge < minAge) {
        dispatch(onboardingActions.setDeepLinkCorrectAge(false));
      } else {
        dispatch(onboardingActions.setDeepLinkCorrectAge(true));
      }

      // IMPORTANT: at the moment the requirement is to always allow deep linked url
      // even if the logged user has an incompatible age with the show
      // if this policy changes, we need to compare age_range (of video) with age_range of the current kid profile

      dispatch(saveSearchQuery(searchOpts));
      dispatch(performSearch([searchOpts]));
      dispatch(
        setVideoContent({
          currentVideo: assembleCurrentVideo(videoContent),
          playlists: [queryData?.containerId],
          currentPlaylistIndex: 0,
          currentVideoIndex: 0,
        }),
      );
    }
  }, [
    videoAssetByReferenceId,
    queryData?.containerId,
    deepLinkLanding,
    isUserNormalLogged,
  ]);

  useEffect(() => {
    return () => {
      dispatch(clearSearchData());
      dispatch(clearSearchQuery());
    };
  }, []);

  const goFullscreen = () => {
    dispatch(setBackgroundPlayerFullscreen(true));
    toggleHeader(false);
  };

  useEffect(() => {
    if (!isWorkstation() && isBackgroundPlayer && showVOD && playbackBegun) {
      let timer = setTimeout(goFullscreen, FULLSCREEN_DELAY);

      const resetTimer = () => {
        clearTimeout(timer);
        timer = setTimeout(goFullscreen, FULLSCREEN_DELAY);
      };

      xdk.environment.addEventListener(
        xdk.environment.SYSTEM.KEYDOWN,
        resetTimer,
      );

      return () => {
        clearTimeout(timer);
        xdk.environment.removeEventListener(
          xdk.environment.SYSTEM.KEYDOWN,
          resetTimer,
        );
      };
    }
  }, [
    showVOD,
    isBackgroundPlayerFullscreen,
    isBackgroundPlayer,
    playbackBegun,
  ]);

  // TV background player
  useEffect(() => {
    if (
      !isWorkstation() &&
      isBackgroundPlayer &&
      showVOD &&
      !isBackgroundPlayerReady.current
    ) {
      if (
        fullscreenBookmarkedVideoContent?.currentVideo?.videoId ===
        currentVideo.videoId
      ) {
        fullscreenBookmarkedVideoContent.currentVideo.time = Math.max(
          currentVideo.time,
          fullscreenBookmarkedVideoContent.currentVideo.time,
        );
        dispatch(setVideoContent(fullscreenBookmarkedVideoContent));
      }
      isBackgroundPlayerReady.current = true;
      forceUpdate();
    }
  }, [
    isBackgroundPlayer,
    fullscreenBookmarkedVideoContent,
    showVOD,
    currentVideo,
  ]);

  useEffect(() => {
    if (isBackgroundPlayer && showVOD && !isBackgroundPlayerReady.current) {
      if (bookmarkedVideoContent) {
        if (
          currentVideo.videoId === bookmarkedVideoContent.currentVideo.videoId
        ) {
          // Use case is bookmark is set when going to any other page away from home
          // except for the live player, which sets the current video time on the video content.
          // Avoids issue of overriding BG player bookmark when coming to Live TV from a FS player.
          // i.e. Home -> explore -> fullscreen (changes video content) -> exit -> Live TV
          // User will see video where they left off regardless of which page they go to.
          bookmarkedVideoContent.currentVideo.time = Math.max(
            currentVideo.time,
            bookmarkedVideoContent.currentVideo.time,
          );
        }
        dispatch(setVideoContent(bookmarkedVideoContent));
      }
      isBackgroundPlayerReady.current = true;
      forceUpdate();
    }
  }, [isBackgroundPlayer, bookmarkedVideoContent, showVOD]);

  useEffect(() => {
    if (isFullScreenPlayer && showVOD && !isFullscreenPlayerReady.current) {
      if (
        fullscreenBookmarkedVideoContent &&
        !props.location.state?.fromDetails
      ) {
        if (
          currentVideo.videoId !==
          fullscreenBookmarkedVideoContent.currentVideo.videoId
        ) {
          dispatch(setVideoContent(fullscreenBookmarkedVideoContent));
        }
        dispatch(clearVideoFullscreenBookmark());
      }

      isFullscreenPlayerReady.current = true;
      forceUpdate();
    }
  }, [isFullScreenPlayer, fullscreenBookmarkedVideoContent, showVOD]);

  useEffect(() => {
    const currentPath = props.location.pathname.toLowerCase();
    switch (true) {
      case currentPath === mainRoutes.HOME:
        isFullscreenPlayerReady.current = false;
        setShowVOD(true);
        forceUpdate();
        break;
      case currentPath.startsWith('/player'):
        dispatch(sendScreenView(fullscreenPlayerScreenName));
        isBackgroundPlayerReady.current = false;
        xdk.media?.deinit();
        setShowVOD(true);
        forceUpdate();
        break;
      case currentPath === mainRoutes.LIVETV:
        xdk.media?.deinit();
        setShowVOD(false);
        break;
      default:
        isFullscreenPlayerReady.current = false;
        if (
          xdk.media?.getState() !== 'paused' &&
          xdk.media?.getState() !== 'stopped'
        ) {
          xdk.media?.pause();
        }
        setShowVOD(false);
        break;
    }
  }, [props.location]);

  useEffect(() => {
    if (!playerConstants.CUSTOM_BOOKMARKING_ENABLED) {
      return;
    }
    forceUpdate();
    if (
      currentVideo.videoReferenceId &&
      isFullScreenPlayer &&
      (!fullscreenBookmarkedVideoContent || props.location.state?.fromDetails)
    ) {
      // If the video has changed into fullscreen and we aren't resuming fullscreen content
      dispatch(loadCustomDataVideoBookmark());
      dispatch(setAdDetailsLoaded(false));
      dispatch(setAdPlayed(false));
      dispatch(clearPrerollAds());
    }
  }, [
    currentVideo.videoId,
    fullscreenBookmarkedVideoContent,
    isFullScreenPlayer,
  ]);

  useEffect(() => {
    if (!playerConstants.CUSTOM_BOOKMARKING_ENABLED) {
      return;
    }
    if (
      isFullScreenPlayer &&
      fullscreenCustomBookmark?.videoId === currentVideo.videoId &&
      isAdDetailsLoaded &&
      adPlayed
    ) {
      // custom data bookmark is in milliseconds
      dispatch(
        setVideoTime(
          (prerollAdDuration + fullscreenCustomBookmark.progress) / 1000,
        ),
      );
      dispatch(clearCustomDataVideoBookmark());
    }
  }, [
    fullscreenCustomBookmark,
    prerollAdDuration,
    isAdDetailsLoaded,
    adPlayed,
  ]);

  const tvPropsOverride = {
    isFullScreenPlayer: true,
    fromHome: isBackgroundPlayer,
    withUI: isBackgroundPlayerFullscreen || isFullScreenPlayer,
    isBackgroundPlayerFullscreen,
  };

  return (
    <>
      <LogoutRedirect />
      <div className={styles.container}>
        <div
          ref={playerContainer}
          className={cx(styles.playerWrapper, {
            [styles.backgroundPlayer]: isBackgroundPlayerFullscreen,
          })}
        >
          {currentVideo.videoId &&
            showVOD &&
            ((isFullScreenPlayer && isFullscreenPlayerReady.current) ||
              (isBackgroundPlayer && isBackgroundPlayerReady.current)) && (
              <VODPlayer
                key={key}
                videoId={currentVideo.videoId}
                videoTitle={currentVideo.videoTitle}
                videoTime={currentVideo.time || 0}
                videoTextTracks={currentVideo.text_tracks}
                isFullScreenPlayer={isPortable() ? false : isFullScreenPlayer}
                container={playerContainer.current}
                {...(!isWorkstation() && tvPropsOverride)}
              />
            )}
        </div>
        {props.children}
      </div>
    </>
  );
};

PlayerModal.propTypes = {
  children: PropTypes.any,
  location: PropTypes.any,
  match: PropTypes.object,
  history: PropTypes.object,
};

export default withRouter(PlayerModal);
