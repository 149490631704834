import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withFocus, focusManager } from '@accedo/vdkweb-navigation';
import classNames from 'classnames';
import { customRoutes, imgixTileResizeParameters } from '#/config/constants';
import { getPodcastsControls } from '#/redux/modules/accedoOne/selectors';
import getVw from '#/utils/getVw';
import imgixUrl from '#/utils/imgix';
import styles from './showcard.scss';
import { isWorkstation } from '#/utils/componentStyleConfig';
import { FocusDiv } from '#/widgets/ui';
import PodcastIcon from '#/static/images/icons/PodcastIcon.png';

const Container = ({
  nav,
  children,
  className,
  onClick,
  onMouseOver,
  style,
  ariaLabel,
}) => {
  return !isWorkstation() ? (
    <FocusDiv
      nav={nav}
      className={`${className} ${nav.isFocused ? styles.focused : ''}`}
      onClick={onClick}
      style={style}
      ariaLabel={ariaLabel}
    >
      {children}
    </FocusDiv>
  ) : (
    <div
      onClick={onClick}
      onMouseOver={onMouseOver}
      className={className}
      style={style}
    >
      {children}
    </div>
  );
};

const FocusContainer = withFocus(Container);

const ShowCard = ({
  nav = {},
  id,
  imgUrl,
  name,
  hero = false,
  roundedCorners = true,
  className,
  exploreClassName,
  onItemFocus,
  history,
  playlistId = '0',
  ariaLabel,
  podcastShow = false,
}) => {
  const { imgixParamsForCover } = useSelector(getPodcastsControls);
  const imgSrc = useMemo(() => {
    // eslint-disable-next-line prefer-const
    let newUrl = '';
    // if (!hero) {
    //   newUrl = `linear-gradient(
    //   225deg
    //   , transparent, rgba(0, 0, 0, 0.6)), `;
    // }
    if (imgUrl) {
      return `${newUrl} url(${
        podcastShow
          ? `${imgUrl}?${imgixParamsForCover}`
          : imgixUrl(imgUrl, imgixTileResizeParameters.SHOWS)
      })`;
    }
    return 'black';
  }, [imgUrl, hero]);
  const onFocus = useCallback(() => {
    if (onItemFocus) {
      onItemFocus(nav.id);
    }
    focusManager.changeFocus(nav.id);
  }, []);

  return (
    <>
      <FocusContainer
        nav={nav}
        className={classNames(styles.card, className, {
          [styles.isTv]: !isWorkstation(),
          [styles.heroShow]: hero,
        })}
        onClick={() => {
          const pathname =
            playlistId !== '0'
              ? `${customRoutes.SHOW_DETAILS}/${id}/${playlistId}`
              : `${customRoutes.SHOW_DETAILS}/${id}`;
          history.push({ pathname });
        }}
        onMouseOver={!isWorkstation() ? () => {} : onFocus}
        onFocus={onFocus}
        style={{
          borderRadius: roundedCorners ? getVw(24) : '0px',
          outline: 'none',
        }}
        ariaLabel={ariaLabel || name}
      >
        <div
          className={classNames(styles.cardImageContainer, exploreClassName)}
          style={{ background: imgSrc }}
          aria-hidden="true"
        >
          {podcastShow && (
            <img
              src={PodcastIcon}
              className={styles.podcastIcon}
              alt="Podcast Icon"
            />
          )}
          {!hero && (
            <div
              className={styles.textContainer}
              style={exploreClassName && { left: '1vw' }}
            >
              <p
                className={styles.cardTitle}
                style={
                  exploreClassName && {
                    lineHeight: 'normal',
                    margin: ' 0 0 2vw 2vw',
                  }
                }
              >
                {name}
              </p>
            </div>
          )}
        </div>
      </FocusContainer>
    </>
  );
};

Container.propTypes = {
  nav: PropTypes.object,
  children: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  style: PropTypes.object,
  ariaLabel: PropTypes.string,
};

ShowCard.propTypes = {
  // isFocused: PropTypes.bool,
  // onFocus: PropTypes.func,
  nav: PropTypes.object,
  id: PropTypes.string,
  imgUrl: PropTypes.string,
  name: PropTypes.string,
  hero: PropTypes.bool,
  roundedCorners: PropTypes.bool,
  className: PropTypes.string,
  exploreClassName: PropTypes.string,
  onItemFocus: PropTypes.func,
  history: PropTypes.object,
  playlistId: PropTypes.string,
  ariaLabel: PropTypes.string,
  podcastShow: PropTypes.bool,
};

export default withRouter(ShowCard);
