import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { focusManager } from '@accedo/vdkweb-navigation';
import { FocusDiv } from '@accedo/vdkweb-tv-ui';

import classNames from 'classnames';
import Button from './Button';
import KeyWithIcon from '#/components/Keyboard/KeyWithIcon';

import { setSpeech } from '#/utils/accessibility';
import { KEYS } from './keyMap';
import styles from './keyboard.scss';

const UPPERCASE = 'UPPERCASE';
const LOWERCASE = 'LOWERCASE';
const NUMBERS = 'NUMBERS';
const SPECIAL_CHARS = 'SPECIAL_CHARS';
const NEXT = 'NEXT';
const PREV = 'PREV';

const Keyboard = ({
  nav,
  onClick,
  updateScroll,
  wrapperClassName,
  isNextDisabled = false,
  isPrevDisabled = false,
  digitMode = false,
}) => {
  const [firstRowChars, setFirstRowChars] = useState();
  let focusKeyboard;
  if (updateScroll) {
    focusKeyboard = () => {
      updateScroll();
    };
  }

  const onClickSpecialKeys = value => {
    switch (value) {
      case UPPERCASE:
        firstRowChars === UPPERCASE
          ? setFirstRowChars(LOWERCASE)
          : setFirstRowChars(UPPERCASE);
        break;
      case NUMBERS:
        firstRowChars === NUMBERS
          ? setFirstRowChars(LOWERCASE)
          : setFirstRowChars(NUMBERS);
        break;
      case SPECIAL_CHARS:
        firstRowChars === SPECIAL_CHARS
          ? setFirstRowChars(LOWERCASE)
          : setFirstRowChars(SPECIAL_CHARS);
        break;
      case NEXT:
        !isNextDisabled && onClick(value);
        break;
      case PREV:
        !isPrevDisabled && onClick(value);
        break;
      default:
        onClick(value);
    }
  };

  const onKeyFocus = useCallback(
    value => {
      if (focusManager.getCurrentFocus()?.startsWith('PASSWORD')) {
        return;
      }
      setSpeech(value);
    },
    [KEYS],
  );

  useEffect(() => {
    setFirstRowChars(digitMode ? NUMBERS : LOWERCASE);
  }, [digitMode]);

  return (
    <FocusDiv
      onFocus={focusKeyboard}
      nav={{ ...nav, forwardFocus: digitMode ? '0' : KEYS[0].nav.id }}
      className={classNames(styles.wrapper, wrapperClassName)}
    >
      <div className={styles.row}>
        {KEYS.filter(item => {
          if (firstRowChars === NUMBERS) {
            return item.row === 3;
          }

          if (firstRowChars === SPECIAL_CHARS) {
            return item.row === 4;
          }
          return item.row === 1;
        }).map(item => {
          const value =
            firstRowChars === UPPERCASE && item.altText
              ? item.altText
              : item.text;

          const customSize = `size${item.customSize
            ?.toString()
            .replace('.', 'x')}`;

          const keyNav = {
            ...item.nav,
            nextdown: item.nav.nextdown,
            parent: nav.id,
          };

          return (
            <Button
              key={item.text}
              nav={keyNav}
              className={`${styles.key} ${
                firstRowChars === NUMBERS
                  ? styles.keyNumberSize
                  : styles[customSize]
              }`}
              classNameFocused={styles.keyFocused}
              onClick={() => {
                onClick(value);
              }}
              onFocus={() => onKeyFocus(item.ariaLabel)}
            >
              {value}
            </Button>
          );
        })}
      </div>

      <div className={styles.row}>
        {KEYS.filter(item => item.row === 2).map(item => {
          const customSize = `size${item.customSize
            ?.toString()
            .replace('.', 'x')}`;

          let navUp = item.nav;

          if (firstRowChars === 'NUMBERS') {
            navUp = { ...item.nav, ...item.numbersNav };
          } else if (firstRowChars === SPECIAL_CHARS) {
            navUp = { ...item.nav, ...item.specialNav };
          }
          const keyNav = {
            ...navUp,
            parent: nav?.id,
          };

          return item.icon ? (
            <KeyWithIcon
              key={item.text}
              nav={keyNav}
              item={item}
              className={`${styles.key} ${styles[customSize]}`}
              classNameFocused={styles.keyFocused}
              classNameIcon={styles.buttonIcon}
              onClick={onClickSpecialKeys}
              onFocus={() => onKeyFocus(item.ariaLabel)}
            />
          ) : (
            <Button
              key={item.text}
              nav={keyNav}
              className={`${styles.key} ${styles[customSize]}`}
              classNameFocused={styles.keyFocused}
              ariaLabel={item.ariaLabel}
              onClick={() => {
                onClickSpecialKeys(item.value);
              }}
              onFocus={() => onKeyFocus(item.ariaLabel)}
            >
              {item.text}
            </Button>
          );
        })}
      </div>
    </FocusDiv>
  );
};

Keyboard.propTypes = {
  nav: PropTypes.object,
  onClick: PropTypes.func,
  updateScroll: PropTypes.func,
  wrapperClassName: PropTypes.func,
  isNextDisabled: PropTypes.bool,
  isPrevDisabled: PropTypes.bool,
  digitMode: PropTypes.bool,
};

export default Keyboard;
