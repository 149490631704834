import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from './Button';
import styles from './keyboard.scss';

const KeyWithIcon = ({
  onClick,
  item,
  nav,
  className,
  classNameFocused,
  classNameIcon,
  classNameOnFocus = '',
  ariaLabel,
  onFocus,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <Button
      nav={nav}
      className={classNames(className)}
      classNameFocused={classNameFocused}
      ariaLabel={ariaLabel}
      onClick={() => {
        onClick(item?.value);
      }}
      onBlur={() => {
        setIsFocused(false);
      }}
      onFocus={() => {
        if (onFocus) {
          onFocus();
        }
        setIsFocused(true);
      }}
    >
      {item?.icon ? (
        <img
          src={item.icon.src}
          className={classNames(classNameIcon, {
            [styles.white]: !isFocused,
            [classNameOnFocus]: isFocused,
          })}
          alt={item?.text}
          aria-hidden="true"
        />
      ) : (
        item?.text
      )}
    </Button>
  );
};

KeyWithIcon.propTypes = {
  onClick: PropTypes.func,
  item: PropTypes.object,
  nav: PropTypes.object,
  className: PropTypes.any,
  classNameFocused: PropTypes.bool,
  classNameIcon: PropTypes.bool,
  classNameOnFocus: PropTypes.any,
  ariaLabel: PropTypes.string,
  onFocus: PropTypes.func,
};

export default KeyWithIcon;
