import React, { useEffect, useState, useCallback } from 'react';
import { environment } from '@accedo/xdk-core';
import { vKey } from '@accedo/xdk-virtual-key';
import { focusManager } from '@accedo/vdkweb-navigation';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Page } from '@accedo/vdkweb-tv-ui';
import cx from 'classnames';
import routes, { authFlowRoutes, mainRoutes } from '#/utils/routes';
import { sendScreenView } from '#/redux/modules/firestore/actions';
import { actions as modalActions } from '#/redux/modules/modal/actions';
import authStyles from '../authflow.scss';
import styles from './profilecreation.scss';
import { isWorkstation, isVIZIO, isMobile } from '#/utils/componentStyleConfig';
import { setNativeInput } from '#/utils/nativeDOM';

import { isUserLogged } from '#/redux/modules/user/selectors';
import {
  getAgeBrackets,
  getMonths,
  getDraftProfile,
  getProfiles,
} from '#/redux/modules/onboarding/selectors';
import {
  actions,
  newDraftProfile,
  updateProfileId,
} from '#/redux/modules/onboarding/actions';
import { getAvatars } from '#/redux/modules/firestore/selectors';
import {
  getGreenLogoUri,
  getAgeGroupConfig,
} from '#/redux/modules/accedoOne/selectors';
import { addUnsavedProfiles } from '#/redux/modules/identity/actions';
import checkmark from '#/static/images/icons/checkmark.svg';
import {
  profileEditTitle,
  profileEditDesc,
  profileEditTitleMobile,
  profileEditExtraKidMobile,
  accountCreationStep1Mobile,
  accountCreationStep2Mobile,
} from '#/config/strings';
import { authScreenNames } from '#/config/constants';
import { PROFILECREATION } from '#/utils/navigationHelper';

import Keyboard from '#/components/Keyboard/Keyboard';
import { setNativeFocus } from '#/utils/accessibility';

import {
  StepIndicator,
  Avatar,
  Button,
  FocusButton,
  FocusDiv,
} from '#/widgets/ui';

import GreenBackground from '#/static/images/background-images/Green.svg';
import GoldBackground from '#/static/images/background-images/Gold.svg';
import RedBackground from '#/static/images/background-images/Red.svg';
import BlueBackground from '#/static/images/background-images/Blue.svg';

import {
  InfoForm,
  InfoInput,
  Select,
  AvatarModalSelect,
} from '#/widgets/forms';

import { getHasCustomDataByAge } from '#/redux/modules/guest/selectors';
import { getAgeRange } from '#/utils/getAgeRange';
import CancelButton from '../CancelButton';

const ageGroupMap = {
  general: GreenBackground,
  TWO_UP: GoldBackground,
  FIVE_UP: RedBackground,
  EIGHT_UP: BlueBackground,
};

const ProfileEdit = ({ profile, history }) => {
  const dispatch = useDispatch();
  const isUserLoggedIn = useSelector(isUserLogged);
  const months = useSelector(getMonths);
  const ageBrackets = useSelector(getAgeBrackets);
  const avatarOptions = useSelector(getAvatars);
  const ageGroupConfig = useSelector(getAgeGroupConfig);
  const [isProfileValid, setIsProfileValid] = useState(false);
  const [lastFocus, setLastFocus] = useState(null);
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [nameInput, setNameInput] = useState('');
  const [selectedAge, setSelectedAge] = useState(undefined);
  const ageRange = getAgeRange(profile?.age);
  const profiles = useSelector(getProfiles);
  const hasCustomData = useSelector(state =>
    getHasCustomDataByAge(state, ageRange),
  );

  const rand = Math.floor(Math.random() * avatarOptions.length);
  const defaultAvatar = avatarOptions[rand];

  useEffect(() => {
    dispatch(sendScreenView(authScreenNames.KID_PROFILE_CREATION));
    profile.avatar = defaultAvatar;
    dispatch({
      type: String(actions.setProfileAvatar),
      payload: defaultAvatar,
    });
  }, []);

  // dispatch input event when profile fields are updated via modal
  const event = new Event('input', { bubbles: true });

  useEffect(() => {
    if (isWorkstation() && profile.age) {
      dispatch(sendScreenView(authScreenNames.AGE_MODAL));
    }
    if (!isWorkstation()) {
      document.getElementById(PROFILECREATION.AGE_INPUT)?.dispatchEvent(event);
    }
  }, [profile.age]);

  useEffect(() => {
    if (isWorkstation() && profile.birthdayMonth) {
      dispatch(sendScreenView(authScreenNames.BIRTH_MONTH_MODAL));
    }
    if (!isWorkstation()) {
      document
        .getElementById(PROFILECREATION.BIRTHDAY_INPUT)
        ?.dispatchEvent(event);
    }
  }, [profile.birthdayMonth]);

  const keyInput = keyValue => {
    let nextFocus;
    switch (keyValue) {
      case 'NEXT':
        if (lastFocus === PROFILECREATION.NAME_INPUT) {
          nextFocus = PROFILECREATION.AGE_INPUT;
          setShowKeyboard(false);
        }
        focusManager.changeFocus(nextFocus);
        setLastFocus(nextFocus);
        break;
      case 'PREV':
        break;
      case 'DELETE':
        setNameInput(prev => prev.substring(0, prev.length - 1));
        break;
      default:
        setNameInput(prev => prev + keyValue);
    }
  };

  const onKeyDown = useCallback(({ id }) => {
    const { OK } = vKey;
    const currentFocusInput = focusManager.getCurrentFocus();
    if (id !== OK.id) {
      return;
    }
    if (currentFocusInput === PROFILECREATION.NAME_INPUT) {
      setLastFocus(currentFocusInput);
      setShowKeyboard(true);
      focusManager.changeFocus(PROFILECREATION.KEYBOARD);
    }
  }, []);

  useEffect(() => {
    if (isVIZIO()) {
      setNativeInput(PROFILECREATION.NAME_INPUT, nameInput);
      setNativeInput(PROFILECREATION.AGE_INPUT, profile.age);
      setNativeInput(
        PROFILECREATION.BIRTHDAY_INPUT,
        months[Number(profile.birthdayMonth)],
      );
    }
  }, [nameInput, profile.birthdayMonth, profile.age]);

  useEffect(() => {
    if (isVIZIO()) {
      environment.addEventListener(environment.SYSTEM.KEYDOWN, onKeyDown);
      return () => {
        environment.removeEventListener(environment.SYSTEM.KEYDOWN, onKeyDown);
      };
    }
  }, []);

  useEffect(() => {
    setNativeFocus(PROFILECREATION.PAGE_DESCRIPTION);
  }, []);

  useEffect(() => {
    if (selectedAge === undefined) {
      return;
    }

    let ageGroup = 2;
    const parsedAge = parseInt(selectedAge);

    if (parsedAge > 7) {
      ageGroup = 8;
    } else if (parsedAge > 4) {
      ageGroup = 5;
    }

    if (ageGroupConfig === undefined) {
      return;
    }

    const ageGroupInfo = ageGroupConfig[`configurations${ageGroup}Up`];

    const {
      noRegistrationConfig: { info1, info2, info3 },
    } = ageGroupInfo;

    dispatch(
      modalActions.openModal({
        modalId: 'AboutAgeGroupModal',
        modalData: {
          imageUrl: profile.avatar,
          minAge: ageGroupInfo.minAge.toString(),
          maxAge: ageGroupInfo.maxAge.toString(),
          info1,
          info2,
          info3,
        },
      }),
    );
  }, [selectedAge]);

  const onProfileSave = useCallback(() => {
    const saveProfile = () => {
      dispatch(actions.saveProfile());
      if (isUserLoggedIn) {
        dispatch(addUnsavedProfiles(updateProfileId));
        history.push(routes.PROFILE_SELECTION);
      }
    };

    // Show modal if there is guest data
    if (hasCustomData) {
      dispatch(
        modalActions.openModal({
          modalId: 'SyncCustomDataModal',
          modalData: {
            ageRange,
            saveProfile,
          },
        }),
      );
      return;
    }

    // Continue with regular flow
    saveProfile();
  }, [isUserLoggedIn, ageRange, hasCustomData]);

  const onAddAnotherKid = useCallback(() => {
    const saveProfile = () => {
      dispatch(actions.saveProfile());
      if (isUserLoggedIn) {
        dispatch(addUnsavedProfiles(updateProfileId));
      }
    };

    // Show modal if there is guest data
    if (hasCustomData) {
      dispatch(
        modalActions.openModal({
          modalId: 'SyncCustomDataModal',
          modalData: {
            ageRange,
            saveProfile,
            addAnother: true,
          },
        }),
      );
      return;
    }

    // Continue with regular flow
    saveProfile();
    history.push(routes.PROFILE_CREATION);
  }, [isUserLoggedIn, ageRange, hasCustomData]);

  const avatarDisplay = (
    <Avatar
      profile={{
        ...profile,
        birthdayMonth: months[profile.birthdayMonth],
        age: ageBrackets[profile.age],
      }}
    />
  );

  return (
    <>
      <Page
        autofocus
        nav={{
          id: PROFILECREATION.PAGE,
          forwardFocus: PROFILECREATION.CONTAINER,
        }}
      >
        <div tabIndex="-1" id={PROFILECREATION.PAGE_DESCRIPTION}>
          <div className={authStyles.pageDescription}>
            <h1 className={authStyles.profileEditTitleText}>
              {/* eslint-disable-next-line no-nested-ternary */}
              {!isMobile()
                ? profileEditTitle
                : profiles.length
                ? profileEditExtraKidMobile
                : profileEditTitleMobile}
            </h1>
            <p>{!isMobile() && profileEditDesc}</p>
          </div>
        </div>

        <FocusDiv
          className={cx(authStyles.content, authStyles.editProfile)}
          nav={{
            id: PROFILECREATION.CONTAINER,
            forwardFocus: PROFILECREATION.FORM,
          }}
        >
          <AvatarModalSelect
            avatarDisplay={avatarDisplay}
            modalName="AvatarModal"
            successActionType={String(actions.setProfileAvatar)}
            onClick={() => {
              dispatch(
                modalActions.openModal({
                  modalId: isWorkstation() ? 'AvatarModal' : 'AvatarModalTV',
                  successAction: {
                    type: String(actions.setProfileAvatar),
                  },
                  modalData: {
                    returnFocus: PROFILECREATION.SELECT_AVATAR,
                  },
                }),
              );
            }}
            nav={
              !isWorkstation()
                ? {
                    id: PROFILECREATION.SELECT_AVATAR,
                    nextright: PROFILECREATION.FORM,
                    parent: PROFILECREATION.CONTAINER,
                  }
                : {}
            }
          />
          <FocusDiv
            nav={{
              id: PROFILECREATION.FORM,
              forwardFocus: PROFILECREATION.NAME_INPUT,
              useLastFocus: true,
            }}
            className={styles.formWrap}
          >
            <InfoForm
              onChange={(data, isValid) => {
                setIsProfileValid(isValid);
                isValid && dispatch(actions.setProfileFields(data));
                if (data.age !== undefined && selectedAge !== data.age) {
                  setSelectedAge(data.age);
                }
              }}
            >
              <InfoInput
                fieldName="name"
                fieldLabel={"Kid's first name"}
                fieldValue={profile.name}
                placeHolder={isWorkstation() ? 'Name' : "Enter kid's name"}
                inputAttributes={{
                  type: 'text',
                  pattern: '[a-zA-Z]+',
                  required: 'required',
                }}
                nav={
                  !isWorkstation()
                    ? {
                        id: PROFILECREATION.NAME_INPUT,
                        nextdown: PROFILECREATION.AGE_INPUT,
                        nextleft: PROFILECREATION.SELECT_AVATAR,
                        parent: PROFILECREATION.FORM,
                      }
                    : {}
                }
              />
              {!isWorkstation() && (
                <>
                  <InfoInput
                    fieldName="age"
                    fieldLabel="Kid's age"
                    fieldValue={profile.age}
                    onClick={() => {
                      dispatch(
                        modalActions.openModal({
                          modalId: 'AgeModal',
                          successAction: {
                            type: String(actions.setProfileAge),
                          },
                        }),
                      );
                    }}
                    placeHolder="Enter kid's age"
                    inputAttributes={{
                      type: 'text',
                      required: 'required',
                    }}
                    nav={{
                      id: PROFILECREATION.AGE_INPUT,
                      nextup: PROFILECREATION.NAME_INPUT,
                      nextdown: PROFILECREATION.BIRTHDAY_INPUT,
                      nextleft: PROFILECREATION.SELECT_AVATAR,
                      parent: PROFILECREATION.FORM,
                    }}
                    nativeFocusable={false}
                  />
                  <InfoInput
                    fieldName="birthdayMonth"
                    fieldLabel="Kid's birthday month"
                    fieldValue={months[Number(profile.birthdayMonth)]}
                    onClick={() => {
                      dispatch(
                        modalActions.openModal({
                          modalId: 'MonthModal',
                          successAction: {
                            type: String(actions.setProfileBirthdayMonth),
                          },
                        }),
                      );
                    }}
                    placeHolder="Enter month"
                    inputAttributes={{
                      type: 'text',
                      required: 'required',
                    }}
                    nav={{
                      id: PROFILECREATION.BIRTHDAY_INPUT,
                      nextup: PROFILECREATION.AGE_INPUT,
                      nextdown:
                        isProfileValid && !!profile.avatar
                          ? PROFILECREATION.SAVE_BUTTON
                          : null,
                      nextleft: PROFILECREATION.SELECT_AVATAR,
                      parent: PROFILECREATION.FORM,
                    }}
                    nativeFocusable={false}
                  />
                </>
              )}
              {isWorkstation() && (
                <>
                  <Select
                    fieldName="age"
                    fieldLabel="Kid's age"
                    fieldSelected={profile.age}
                    fieldOptions={ageBrackets}
                    inputAttributes={{
                      required: 'required',
                      placeholder: 'Select age',
                    }}
                  />
                  <Select
                    fieldName="birthdayMonth"
                    fieldLabel="Kid's birthday month"
                    fieldSelected={profile.birthdayMonth}
                    fieldOptions={months}
                    inputAttributes={{
                      required: 'required',
                      placeholder: 'Select month',
                    }}
                  />
                </>
              )}
            </InfoForm>
            {isMobile() && (
              <FocusButton
                onClick={onAddAnotherKid}
                selected={isProfileValid && !!profile.avatar}
                disabled={!isProfileValid || !profile.avatar}
                ariaLabel="Add a new kid"
                className={styles.addAnotherButton}
              >
                Add another kid
              </FocusButton>
            )}
            <FocusButton
              onClick={onProfileSave}
              selected={isProfileValid && !!profile.avatar}
              disabled={!isProfileValid || !profile.avatar}
              nav={
                !isWorkstation()
                  ? {
                      id: PROFILECREATION.SAVE_BUTTON,
                      nextup: PROFILECREATION.BIRTHDAY_INPUT,
                      nextleft: PROFILECREATION.SELECT_AVATAR,
                      parent: PROFILECREATION.FORM,
                    }
                  : {}
              }
              ariaLabel="Save profile"
              className={styles.saveProfileButton}
            >
              {!isMobile() ? 'Save profile' : 'Start watching'}
            </FocusButton>
          </FocusDiv>
        </FocusDiv>
      </Page>

      {isVIZIO() && showKeyboard && (
        <Keyboard
          nav={{
            id: PROFILECREATION.KEYBOARD,
          }}
          onClick={keyInput}
          wrapperClassName={styles.keyboardWrapper}
        />
      )}
    </>
  );
};
ProfileEdit.propTypes = {
  profile: PropTypes.object,
  history: PropTypes.object,
};

const ProfileList = ({ profiles }) => {
  const months = useSelector(getMonths);
  const ageBrackets = useSelector(getAgeBrackets);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(sendScreenView(authScreenNames.KID_PROFILE_CREATION_COMPLETE));
  }, []);

  const [lastProfile] = profiles.slice(-1);
  const lastProfilesCard = (
    <Avatar
      className={styles.avatarImg}
      profile={{
        ...lastProfile,
        birthdayMonth: months[lastProfile.birthdayMonth],
        age: ageBrackets[lastProfile.age],
      }}
    />
  );
  return (
    <>
      <div className={authStyles.pageDescription}>
        <h1>Nice, {lastProfile.name}'s Profile Is Ready To Go.</h1>
        <p>
          {lastProfile.name} is ready to start watching shows and videos
          designed to keep them entertained and inspired.{' '}
          <button
            className={styles.textBtn}
            onClick={() => {
              dispatch(modalActions.openModal({ modalId: 'LikeWhatModal' }));
            }}
          >
            <span>Like what?</span>
          </button>
        </p>
      </div>
      <div className={styles.cardContainer}>
        {lastProfilesCard}
        <div className={authStyles.buttonsGroup}>
          <Button onClick={() => dispatch(newDraftProfile())}>
            Yes, add another
          </Button>
          <div>
            {!isMobile() ? (
              <Link to={authFlowRoutes.ACCOUNT_CREATION}>
                Not now, continue
              </Link>
            ) : (
              <Link to={mainRoutes.HOME}>Not now, continue</Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
ProfileList.propTypes = {
  profiles: PropTypes.array,
};

const ProfileCreation = ({ history }) => {
  const dispatch = useDispatch();
  const isUserLoggedIn = useSelector(isUserLogged);
  const currentProfile = useSelector(getDraftProfile);
  const profiles = useSelector(getProfiles);
  const greenLogoUri = useSelector(getGreenLogoUri) || '';

  useEffect(() => {
    if (!profiles.length) {
      dispatch(newDraftProfile());
    }
    return () => {
      dispatch(actions.editProfile());
    };
  }, [profiles.length]);

  const BackgroundImage = ageGroupMap.general;
  const stepIndicator = profiles.length ? (
    <StepIndicator step="2b" />
  ) : (
    <StepIndicator step="2" />
  );

  const profileView =
    (currentProfile && (
      <ProfileEdit profile={currentProfile} history={history} />
    )) ||
    (profiles.length && <ProfileList profiles={profiles} />) ||
    null;

  const profileMode = (currentProfile && 'edit') || (profiles.length && 'list');

  return (
    <div
      className={cx(
        authStyles.authFlow,
        authStyles.onboardFlow,
        styles.profileCreation,
        styles[profileMode],
      )}
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      {!isUserLoggedIn ? <CancelButton /> : null}
      {isMobile() && (
        <img className={styles.logo} src={greenLogoUri} alt="Logo" />
      )}
      {!isMobile() && isUserLoggedIn && !profiles.length ? stepIndicator : null}
      {isMobile() && isUserLoggedIn && !profiles.length ? (
        <div className={styles.mobileStepIndicatorContainer}>
          <div className={styles.stepContainter}>
            <div className={styles.indigoCircle}>
              <img
                className={styles.checkmark}
                src={checkmark}
                alt="Check Mark"
              />
            </div>
            <div>{accountCreationStep1Mobile}</div>
          </div>
          <div className={styles.horzitonalLine} />
          <div className={styles.stepContainter}>
            <div className={styles.greenCircle}>2</div>
            <div>{accountCreationStep2Mobile}</div>
          </div>
        </div>
      ) : null}
      {profileView}
    </div>
  );
};
ProfileCreation.propTypes = {
  history: PropTypes.object,
};

export default ProfileCreation;
