import moment from 'moment-mini';

export const aggregateBirthdate = (age, birthdayMonth) => {
  const years =
    (moment().month() + 1 === birthdayMonth && moment().date() > 15) ||
    moment().month() + 1 > birthdayMonth
      ? age
      : age + 1;
  const dateOfBirth = moment()
    .subtract(years, 'years')
    .month(birthdayMonth - 1)
    .date(15)
    .format('YYYY-MM-DD');
  return dateOfBirth;
};
