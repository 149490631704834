export const getAgeRange = age => {
  let ageValue;
  switch (typeof age) {
    case 'number':
      ageValue = age;
      break;
    case 'string': {
      const fromString = Number(age);
      // eslint-disable-next-line no-restricted-globals
      if (age === '' || isNaN(fromString)) {
        return null;
      }
      ageValue = age;
      break;
    }
    default:
      return null;
  }

  if (ageValue > 7) {
    return 8;
    // eslint-disable-next-line no-else-return
  } else if (ageValue > 4) {
    return 5;
  }

  return 2;
};
