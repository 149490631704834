import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getModalData } from '#/redux/modules/modal/selectors';
import { actions } from '#/redux/modules/modal/actions';
import BaseModal from '#/widgets/modals/common/BaseModal/BaseModal';
import styles from './GuestProfileModal.scss';
import { FocusDiv, FocusAnchor } from '#/widgets/ui';
import { authScreenNames } from '#/config/constants';
import { authFlowRoutes } from '#/utils/routes';
import { logout } from '#/redux/modules/identity/identityGuest/actions';
import { getNoRegistration } from '#/redux/modules/accedoOne/selectors';

// focus handling
import FocusModal from '../../common/FocusModal/FocusModal';

import {
  TV_CONTENT_MODAL,
  GUEST_PROFILE_MODAL,
} from '#/utils/navigationHelper';
import { isWorkstation } from '#/utils/componentStyleConfig';

const { CONTAINER, TV_CONTENT_MODAL_ACTION_BUTTON } = TV_CONTENT_MODAL;
const { SIGN_IN_LINK } = GUEST_PROFILE_MODAL;

const GuestProfileModal = () => {
  const { SIGN_IN, REGISTER } = authScreenNames;
  const dispatch = useDispatch();
  const { modalType } = useSelector(getModalData);
  const noRegistration = useSelector(getNoRegistration);
  const { imageUrl, info1, info2, info3, title } = noRegistration[modalType];
  const infoBlocks = info2.split('·');
  const footerSignIn = () => {
    return (
      <div className={styles.footer}>
        Have an account?
        <FocusAnchor
          nav={{
            id: SIGN_IN_LINK,
            nextup: TV_CONTENT_MODAL_ACTION_BUTTON,
          }}
          className={styles.signInBtn}
          onClick={() => {
            dispatch(logout(authFlowRoutes.SIGN_IN));
            dispatch(actions.closeModal());
          }}
        >
          {SIGN_IN}
        </FocusAnchor>
      </div>
    );
  };

  return (
    <FocusModal>
      <BaseModal
        nav={{
          parent: CONTAINER,
          id: TV_CONTENT_MODAL_ACTION_BUTTON,
          nextdown: SIGN_IN_LINK,
        }}
        showCloseX={!!isWorkstation}
        saveButtonClassName={styles.saveBtn}
        saveButtonText={REGISTER}
        saveButtonContainerClassName={styles.saveBtn}
        footerContent={footerSignIn()}
      >
        <FocusDiv
          className={styles.container}
          nav={{ id: CONTAINER, forwardFocus: TV_CONTENT_MODAL_ACTION_BUTTON }}
        >
          <div className={styles.modalImage}>
            <img alt="guest" src={imageUrl} />
          </div>
          <div className={styles.textBlock}>
            <h2 className={styles.label}>{title}</h2>
            {info1 && <p className={styles.desc}>{info1}</p>}
            <div className={styles.textBlockList}>
              <ul>
                {infoBlocks
                  .filter(infoText => infoText.length > 1)
                  .map(infoBlock => (
                    <li>{infoBlock}</li>
                  ))}
              </ul>
            </div>
            {info3 && <p>{info3}</p>}
          </div>
        </FocusDiv>
      </BaseModal>
    </FocusModal>
  );
};

export default GuestProfileModal;
