import { createActions } from 'redux-actions';

const TOGGLE_SPINNER = 'TOGGLE_SPINNER';
const TOGGLE_LOGIN_SPINNER = 'TOGGLE_LOGIN_SPINNER';

const actionOptions = {
  prefix: 'app/spinner',
};

export const actions = createActions(
  {},
  TOGGLE_SPINNER,
  TOGGLE_LOGIN_SPINNER,
  actionOptions,
);

export const toggleSpinner = payload => {
  return {
    type: `${actionOptions.prefix}/${TOGGLE_SPINNER}`,
    payload,
  };
};

export const toggleLoginSpinner = payload => {
  return {
    type: `${actionOptions.prefix}/${TOGGLE_LOGIN_SPINNER}`,
    payload,
  };
};
