import { handleActions } from 'redux-actions';
import { actions } from './actions';

const initialState = {
  isUserLogged: false,
  appFirstLogin: true,
  ageVerified: false,
  logoutRedirectPath: null,
  guestMode: false,
};

const loginReceived = (state = initialState) => ({
  ...state,
  isUserLogged: true,
});

const logoutUser = (state = initialState) => ({
  ...state,
  isUserLogged: false,
});

const handleAgeVerified = (
  state = initialState,
  { payload: { ageVerified } },
) => ({
  ...state,
  ageVerified,
});

const handleSetLogoutRedirectPath = (state = initialState, { payload }) => ({
  ...state,
  logoutRedirectPath: payload,
});
const handleClearLogoutRedirectPath = (state = initialState) => ({
  ...state,
  logoutRedirectPath: null,
});

const handleResetUserState = () => ({ ...initialState });

const handleEnableGuestMode = (state = initialState) => ({
  ...state,
  guestMode: true,
});

const handleDisableGuestMode = (state = initialState) => ({
  ...state,
  guestMode: false,
});

const reducer = handleActions(
  {
    [actions.resetUserState]: handleResetUserState,
    [actions.loginReceived]: loginReceived,
    [actions.authCodeSuccess]: loginReceived,
    [actions.logoutUser]: logoutUser,
    [actions.setAgeVerified]: handleAgeVerified,
    [actions.setLogoutRedirectPath]: handleSetLogoutRedirectPath,
    [actions.clearLogoutRedirectPath]: handleClearLogoutRedirectPath,
    [actions.enableGuestMode]: handleEnableGuestMode,
    [actions.disableGuestMode]: handleDisableGuestMode,
  },
  initialState,
);

export default reducer;
