import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import BaseModal from '../../common/BaseModal/BaseModal';
import { ageMetadata } from '#/components/AuthFlow/GuestProfile/GuestProfile';
import { authScreenNames } from '#/config/constants';
import {
  getAgeGroupConfig,
  getAgeUp,
} from '#/redux/modules/accedoOne/selectors';
import { getModalData } from '#/redux/modules/modal/selectors';
import { getCurrentProfile } from '#/redux/modules/onboarding/selectors';
import { updateCustomDataDisplayedModals } from '#/redux/modules/identity/actions';
import { getCustomDataDisplayedModals } from '#/redux/modules/identity/selectors';
import { actions as modalActions } from '#/redux/modules/modal/actions';
import { sendScreenView } from '#/redux/modules/firestore/actions';
import info from '#/static/images/icons/info_default.svg';
import { AgeCircle } from '#/widgets/ui/AgeCircle/AgeCircle';
import styles from './AgeUpModal.scss';
import FocusModal from '../../common/FocusModal/FocusModal';
import { AGE_UP_MODAL } from '#/utils/navigationHelper';
import { FocusDiv } from '#/widgets/ui';

const { CONTAINER, PARENT_INFO_MODAL, WATCH_NOW } = AGE_UP_MODAL;

const AgeUpModal = () => {
  const dispatch = useDispatch();
  const currentProfile = useSelector(getCurrentProfile);
  const {
    ageGroupValue,
    kidAgeUpDuringBirthMonth,
    kidAgeUpAfterBirthMonth,
  } = useSelector(getModalData);

  const ageGroupConfig = useSelector(getAgeGroupConfig);
  const ageUp = useSelector(getAgeUp);
  const modalStatus = useSelector(getCustomDataDisplayedModals);
  const {
    afterBirthMonth: {
      kidsModalBody: kidsModalBodyAfterBirthMonth,
      kidsModalTitle: kidsModalTitleAfterBirthMonth,
      parentsModalBody: parentsModalBodyAfterBirthMonth,
      parentsModalTitle: parentsModalTitleAfterBirthMonth,
    },
    duringBirthMonth: {
      kidsModalBody: kidsModalBodyDuringBirthMonth,
      kidsModalTitle: kidsModalTitleDuringBirthMonth,
      parentsModalBody: parentsModalBodyDuringBirthMonth,
      parentsModalTitle: parentsModalTitleDuringBirthMonth,
    },
    commonAgeUpModal: { moreCta, watchCta },
  } = ageUp;

  useEffect(() => {
    if (kidAgeUpDuringBirthMonth) {
      dispatch(
        sendScreenView(
          `${authScreenNames.AGE_UP_DURING_BIRTH_MONTH} (${ageGroupValue})`,
        ),
      );
    }
    if (kidAgeUpAfterBirthMonth) {
      dispatch(
        sendScreenView(
          `${authScreenNames.AGE_UP_AFTER_BIRTH_MONTH} (${ageGroupValue})`,
        ),
      );
    }
  }, [kidAgeUpDuringBirthMonth, kidAgeUpAfterBirthMonth]);

  const modal = useMemo(() => {
    const entries = Object.entries(ageGroupConfig || []);

    let kidsModalTitle;
    let parentsModalTitle;
    let kidsModalBody;
    let parentsModalBody;

    if (kidAgeUpDuringBirthMonth) {
      kidsModalTitle = kidsModalTitleDuringBirthMonth;
      kidsModalBody = kidsModalBodyDuringBirthMonth;
      parentsModalTitle = parentsModalTitleDuringBirthMonth;
      parentsModalBody = parentsModalBodyDuringBirthMonth;
    }

    if (kidAgeUpAfterBirthMonth) {
      kidsModalTitle = kidsModalTitleAfterBirthMonth;
      kidsModalBody = kidsModalBodyAfterBirthMonth;
      parentsModalTitle = parentsModalTitleAfterBirthMonth;
      parentsModalBody = parentsModalBodyAfterBirthMonth;
    }

    return entries.map(([, value], idx) => {
      if (value.ageGroupValue === ageGroupValue) {
        const ageRange = `${entries[idx][1].minAge} - ${entries[idx][1].maxAge}`;
        const { color } = ageMetadata[`${entries[idx][1].ageGroupValue}`];

        const res = {
          id: `ageUp_${entries[idx][1].minAge}up`,
          displayTime: Date.now(),
        };

        dispatch(updateCustomDataDisplayedModals(res));
        return (
          <FocusModal>
            <BaseModal
              nav={{
                parent: CONTAINER,
                id: WATCH_NOW,
                nextdown: PARENT_INFO_MODAL,
              }}
              navCancel={{
                parent: CONTAINER,
                id: PARENT_INFO_MODAL,
                nextup: WATCH_NOW,
              }}
              cancelButtonDisabled={false}
              showCancelButton={true}
              saveButtonText={watchCta}
              cancelAction={() =>
                dispatch(
                  modalActions.openModal({
                    modalId: 'ParentAgeUpModal',
                    modalData: {
                      title: parentsModalTitle,
                      body: parentsModalBody,
                      age: currentProfile.age,
                      minAge: entries[idx][1].minAge,
                      maxAge: entries[idx][1].maxAge,
                      ageGroupValue,
                    },
                  }),
                )
              }
              className={styles.baseContainer}
              saveButtonContainerClassName={styles.watchButtonContainer}
              saveButtonClassName={styles.watchButton}
              cancelButtonText={
                <FocusDiv>
                  <img
                    src={info}
                    className={styles.infoIcon}
                    alt="Info"
                    aria-hidden="true"
                  />
                  {moreCta}
                </FocusDiv>
              }
              cancelButtonClassName={styles.footer}
            >
              <FocusDiv
                className={styles.modalContainer}
                nav={{
                  id: CONTAINER,
                  forwardFocus: WATCH_NOW,
                }}
              >
                <AgeCircle
                  ageRange={ageRange}
                  color={color}
                  styleClasses={{
                    containerClass: cx(styles.circleContainer),
                    borderClass: cx(styles.circleBorder),
                    circleClass: cx(styles.circle),
                    whiteBorderClass: cx(styles.whiteCircleBorder),
                  }}
                />
                <img
                  src={currentProfile.avatar}
                  className={cx(styles.avatar)}
                  alt="Profile avatar"
                />
                <h2 className={styles.title}>
                  {kidsModalTitle.replace('{name}', currentProfile.name)}
                </h2>
                <p className={styles.description}>
                  {kidsModalBody.replace('{age}', currentProfile.age)}
                </p>
              </FocusDiv>
            </BaseModal>
          </FocusModal>
        );
      }
      return null;
    });
  }, [ageGroupConfig, currentProfile.id, modalStatus]);

  return <>{modal}</>;
};

export default AgeUpModal;
