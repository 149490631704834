import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';

import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import cx from 'classnames';
import { withFocus } from '@accedo/vdkweb-navigation';

import { actions } from '#/redux/modules/modal/actions';
import { actions as focusActions } from '#/redux/modules/focus/actions';

import styles from './FormFields.scss';
import EyeOpen from '#/static/images/icons/eye-open.svg';
import Eye from '#/static/images/icons/eye.svg';
import { EditButton } from '#/widgets/ui';
import { isWorkstation, isVIZIO } from '#/utils/componentStyleConfig';

import Keyboard from '#/components/Keyboard/Keyboard';
import { setNativeInput } from '#/utils/nativeDOM';

// Input fields
const inputPropTypes = {
  fieldName: PropTypes.string,
  fieldLabel: PropTypes.node,
  className: PropTypes.string,
  externalErrorMessage: PropTypes.string,
  inputAttributes: PropTypes.object,
  controlledElement: PropTypes.bool,
};

export const InfoInput = withFocus(
  ({
    isFocused,
    onValidInput,
    fieldName,
    fieldLabel,
    fieldValue,
    placeHolder,
    tooltip = null,
    capitalize = false,
    inputAttributes = { type: 'text' },
    className,
    inputClassName,
    externalErrorMessage,
    value,
    visualFocused,
    nav,
    nativeFocusable = true,
  }) => {
    const dispatch = useDispatch();
    const inputField = useRef(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [shouldValidate, setShouldValidate] = useState(false);
    const [fieldType, setFieldType] = useState(inputAttributes.type);
    const [keyboardActive, setKeyboardActive] = useState(false);

    const attributes = {
      ...inputAttributes,
      type: fieldType,
    };
    const handleOnChange = e => {
      setShouldValidate(true);
      setErrorMessage(e.currentTarget.validationMessage);
      e.currentTarget.validationMessage
        ? onValidInput(false)
        : onValidInput(true);
    };

    const handleKeyDown = e => {
      // prevent form submit
      if (e.key === 'Enter' || e.key === 'Select') {
        e.preventDefault();
        return false;
      }
    };

    useEffect(() => {
      if (isFocused && nativeFocusable) {
        inputField?.current?.focus();
        nav?.keyboard && setKeyboardActive(true);
      } else {
        dispatch(focusActions.toggleFocusSink(true));
      }
    }, [isFocused]);

    const onKeyboardClick = useCallback(val => {
      const inputElement = inputField?.current;
      switch (value) {
        case 'DELETE':
          setNativeInput(
            nav?.id,
            inputElement?.value.substring(0, inputElement?.value.length - 1),
          );
          break;
        default:
          setNativeInput(nav?.id, inputElement?.value + val);
      }
    });

    // useEffect(() => {
    //   const onFocus = async () => {
    //     await dispatch(actions.inputFocus(true));
    //   };
    //   const onBlur = async () => {
    //     await dispatch(actions.inputFocus(false));
    //   };
    //   inputField.current.addEventListener('focus', onFocus);
    //   inputField.current.addEventListener('blur', onBlur);
    //   return () => {
    //     inputField.current.removeEventListener('focus', onFocus);
    //     inputField.current.removeEventListener('blur', onBlur);
    //   };
    // }, [inputField.current]);
    return (
      <>
        <div
          className={cx(
            styles.fieldWrap,
            styles[inputAttributes.type],
            className,
          )}
        >
          <div className={styles.labelContainer}>
            <label htmlFor={fieldName} className={styles.inputLabel}>
              {fieldLabel}
            </label>
            <a
              data-text={tooltip}
              className={cx(styles.toolTip, { [styles.hidden]: !tooltip })}
            >
              What's this?
            </a>
          </div>
          <div className={styles.inputContainer}>
            <input
              ref={inputField}
              {...attributes}
              id={nav?.id ? nav.id : fieldName}
              name={fieldName}
              placeholder={placeHolder}
              className={cx(
                styles.inputField,
                inputClassName,
                { [styles.capitalize]: capitalize },
                { [styles.validate]: shouldValidate },
                { [styles.invalid]: !!externalErrorMessage },
                { [styles.tvInputField]: !isWorkstation() },
                { [styles.focused]: isWorkstation() && isFocused },
                {
                  [styles.tvFocused]:
                    (!isWorkstation() && isFocused) || visualFocused,
                },
              )}
              defaultValue={fieldValue}
              onChange={handleOnChange}
              onKeyDown={handleKeyDown}
              {...(value && { value })}
              nav={nav}
            />
            {inputAttributes.type === 'password' && (
              <span
                onClick={() =>
                  fieldType === 'password'
                    ? setFieldType('text')
                    : setFieldType('password')
                }
              >
                {isWorkstation() && (
                  <img
                    className={cx(styles.eye, styles[inputAttributes.type], {
                      [styles.conceal]: fieldType === 'text',
                    })}
                    src={Eye}
                    alt="Unmask"
                  />
                )}
              </span>
            )}
          </div>

          <label htmlFor={fieldName} className={styles.inputCustom}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
            </svg>
          </label>
          {isWorkstation() && (
            <span className={cx(styles.error, 'error')}>
              {errorMessage || externalErrorMessage}
            </span>
          )}
        </div>
        {isVIZIO() && keyboardActive && (
          <Keyboard
            nav={nav?.keyboard}
            onClick={onKeyboardClick}
            isNextDisabled
            isPrevDisabled
          />
        )}
      </>
    );
  },
);
InfoInput.propTypes = {
  ...inputPropTypes,
  fieldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeHolder: PropTypes.string,
  capitalize: PropTypes.bool,
  onChange: PropTypes.func,
  nav: PropTypes.object,
  isFocused: PropTypes.bool,
  value: PropTypes.string,
  visualFocused: PropTypes.bool,
};

// Pin input for mobile
export const MobilePinInput = withFocus(
  ({
    isFocused,
    fieldName,
    fieldLabel,
    fieldValue,
    placeHolder,
    tooltip = null,
    capitalize = false,
    inputAttributes = { type: 'text' },
    className,
    inputClassName,
    externalErrorMessage,
    value,
    visualFocused,
    nav,
    nativeFocusable = true,
    onFocusHandler,
  }) => {
    const dispatch = useDispatch();
    const inputField = useRef(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [shouldValidate, setShouldValidate] = useState(false);
    const [fieldType, setFieldType] = useState(inputAttributes.type);
    const [keyboardActive, setKeyboardActive] = useState(false);

    const attributes = {
      ...inputAttributes,
      type: fieldType,
    };

    const handleOnChange = e => {
      setShouldValidate(true);
      setErrorMessage(e.currentTarget.validationMessage);
    };

    const handleKeyDown = e => {
      // prevent form submit
      if (e.key === 'Enter' || e.key === 'Select') {
        e.preventDefault();
        return false;
      }
    };

    useEffect(() => {
      if (isFocused && nativeFocusable) {
        inputField?.current?.focus();
        nav?.keyboard && setKeyboardActive(true);
      } else {
        dispatch(focusActions.toggleFocusSink(true));
      }
    }, [isFocused]);

    const onKeyboardClick = useCallback(val => {
      const inputElement = inputField?.current;
      switch (value) {
        case 'DELETE':
          setNativeInput(
            nav?.id,
            inputElement?.value.substring(0, inputElement?.value.length - 1),
          );
          break;
        default:
          setNativeInput(nav?.id, inputElement?.value + val);
      }
    });
    // useEffect(() => {
    //   const onFocus = async () => {
    //     await dispatch(actions.inputFocus(true));
    //   };
    //   const onBlur = async () => {
    //     await dispatch(actions.inputFocus(false));
    //   };
    //   inputField.current.addEventListener('focus', onFocus);
    //   inputField.current.addEventListener('blur', onBlur);
    //   return () => {
    //     inputField.current.removeEventListener('focus', onFocus);
    //     inputField.current.removeEventListener('blur', onBlur);
    //   };
    // }, [inputField.current]);
    return (
      <>
        <div
          className={cx(
            styles.fieldWrap,
            styles[inputAttributes.type],
            className,
          )}
        >
          <div className={styles.labelContainer}>
            <label htmlFor={fieldName} className={styles.inputLabel}>
              {fieldLabel}
            </label>
            <a
              data-text={tooltip}
              className={cx(styles.toolTip, { [styles.hidden]: !tooltip })}
            >
              What's this?
            </a>
          </div>
          <input
            ref={inputField}
            {...attributes}
            id={nav?.id ? nav.id : fieldName}
            name={fieldName}
            placeholder={placeHolder}
            className={cx(
              styles.inputField,
              inputClassName,
              { [styles.capitalize]: capitalize },
              { [styles.validate]: shouldValidate },
              { [styles.invalid]: !!externalErrorMessage },
              { [styles.tvInputField]: !isWorkstation() },
              { [styles.focused]: isWorkstation() && isFocused },
              {
                [styles.tvFocused]:
                  (!isWorkstation() && isFocused) || visualFocused,
              },
            )}
            defaultValue={fieldValue}
            onChange={handleOnChange}
            onKeyDown={handleKeyDown}
            onFocus={onFocusHandler}
            {...(value && { value })}
            nav={nav}
          />
          <span className={styles.inputCustom}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
            </svg>
          </span>
          {isWorkstation() && (
            <span className={cx(styles.error, 'error')}>
              {errorMessage || externalErrorMessage}
            </span>
          )}
          <span
            onClick={() =>
              fieldType === 'password'
                ? setFieldType('text')
                : setFieldType('password')
            }
          >
            {isWorkstation() && (
              <img
                className={cx(
                  styles.eye,
                  styles[inputAttributes.type],
                  styles[fieldName],
                  {
                    [styles.conceal]: fieldType === 'text',
                  },
                )}
                src={Eye}
                alt="Unmask"
              />
            )}
          </span>
        </div>
        {isVIZIO() && keyboardActive && (
          <Keyboard
            nav={nav?.keyboard}
            onClick={onKeyboardClick}
            isNextDisabled
            isPrevDisabled
          />
        )}
      </>
    );
  },
);
MobilePinInput.propTypes = {
  ...inputPropTypes,
  fieldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeHolder: PropTypes.string,
  capitalize: PropTypes.bool,
  onChange: PropTypes.func,
  nav: PropTypes.object,
  isFocused: PropTypes.bool,
  value: PropTypes.string,
  visualFocused: PropTypes.bool,
  onFocus: PropTypes.func,
};

// Select based boxes
const selectPropTypes = {
  ...inputPropTypes,
  fieldSelected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fieldOptions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  buttonImage: PropTypes.string,
};
export const Select = ({
  fieldName,
  fieldLabel,
  fieldSelected,
  fieldOptions,
  className,
  buttonImage,
  controlledElement = false,
  inputAttributes,
}) => {
  const selectField = useRef(null);
  const options = useMemo(
    () =>
      Object.entries(fieldOptions)
        .filter(([, v]) => !!v)
        .map(([k, v]) => (
          <option key={k} value={k}>
            {v}
          </option>
        )),
    [fieldOptions],
  );

  return (
    <div className={cx(styles.fieldWrap, className)}>
      <label htmlFor={fieldName} className={styles.inputLabel}>
        {fieldLabel}
      </label>
      <span className={styles.selectWrap}>
        <select
          {...(!controlledElement
            ? { defaultValue: (fieldSelected && String(fieldSelected)) || '' }
            : {})}
          {...(controlledElement ? { value: fieldSelected } : {})}
          ref={selectField}
          id={fieldName}
          name={fieldName}
          className={styles.inputField}
          type="text"
          {...inputAttributes}
        >
          <option value="" disabled>
            {inputAttributes?.placeholder}
          </option>
          {options}
        </select>
        <img src={buttonImage || EyeOpen} alt="Open" />
      </span>
    </div>
  );
};
Select.propTypes = selectPropTypes;

// Modal-based input fields
const modalSelectPropTypes = {
  ...selectPropTypes,
  modalName: PropTypes.string,
  successActionType: PropTypes.string,
};

export const ModalSelect = ({
  fieldName,
  fieldLabel,
  fieldSelected,
  fieldOptions,
  modalName,
  successActionType,
  inputAttributes,
}) => {
  const dispatch = useDispatch();
  const selectField = useRef(null);

  const handleSelect = e => {
    e.preventDefault();
    selectField.current.focus();
    dispatch(
      actions.openModal({
        modalId: modalName,
        successAction: {
          type: successActionType,
        },
      }),
    );
  };

  const options = Object.entries(fieldOptions)
    .filter(([v]) => !!v)
    .map(([k, v]) => (
      <option key={k} value={k}>
        {v}
      </option>
    ));

  return (
    <div className={styles.fieldWrap}>
      <label htmlFor={fieldName} className={styles.inputLabel}>
        {fieldLabel}
      </label>
      <span className={styles.selectWrap}>
        <select
          readOnly
          value={String(fieldSelected)}
          onMouseDown={handleSelect}
          onKeyDown={handleSelect}
          ref={selectField}
          id={fieldName}
          className={styles.inputField}
          type="text"
          {...inputAttributes}
        >
          {options}
        </select>
        <img src={EyeOpen} alt="Open" />
      </span>
    </div>
  );
};
ModalSelect.propTypes = modalSelectPropTypes;

export const AvatarModalSelect = withFocus(
  ({ isFocused, avatarDisplay, modalName, successActionType }) => {
    const dispatch = useDispatch();

    const handleSelect = () => {
      dispatch(
        actions.openModal({
          modalId: modalName,
          successAction: {
            type: successActionType,
          },
        }),
      );
    };

    return (
      <div
        onClick={handleSelect}
        className={cx(styles.avatar, {
          [styles.focused]: !isWorkstation() && isFocused,
        })}
      >
        {avatarDisplay}
        <span className={styles.editButton}>
          <EditButton />
        </span>
      </div>
    );
  },
);

AvatarModalSelect.propTypes = {
  ...inputPropTypes,
  avatarDisplay: PropTypes.node,
  modalName: PropTypes.string,
  successActionType: PropTypes.string,
};
