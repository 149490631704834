import { handleActions } from 'redux-actions';
import { setHeader } from './actions';

const initialState = {
  isHeaderActive: true,
};

const setHeaderReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    isHeaderActive: payload,
  };
};

const reducer = handleActions(
  {
    [setHeader]: setHeaderReducer,
  },
  initialState,
);

export default reducer;
