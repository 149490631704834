import { createSelector } from 'reselect';

import {
  unsavedProfileIdPrefix,
  ageGroupsKeys,
  ageRanges,
  ageGroupsMap,
  containersAgeGroup,
  firestoreTopShowsAgeGroup,
  ageCategoryGroup,
} from '#/config/constants';

let rootSelector = state => state.onboarding;

export const setRootSelector = selector => {
  rootSelector = selector;
};

export const signupState = state => rootSelector(state)?.signupState;
export const getEmailVerified = state => rootSelector(state)?.emailVerified;
export const getLinkExpired = state => rootSelector(state)?.linkExpired;
export const getTvCode = state => rootSelector(state)?.tvCode;

// Constants
export const getAgeBrackets = state => rootSelector(state)?.ageBrackets;
export const getAgeGroups = state => rootSelector(state)?.ageGroups;
export const getMonths = state => rootSelector(state)?.months;

// Profiles
// Gets all profiles in the store, even if custom data is not loaded or age is invalid
export const getAllProfiles = state => rootSelector(state)?.profiles;
// Gets both unsaved and saved profiles with valid age custom data
export const getProfiles = state =>
  rootSelector(state)?.profiles.filter(
    profile => profile.age !== undefined && profile.birthdayMonth !== undefined,
  );
export const getUnsavedProfiles = state =>
  getProfiles(state)?.filter(profile =>
    String(profile.id).includes(unsavedProfileIdPrefix),
  );
export const getSavedProfiles = state =>
  getProfiles(state)?.filter(
    profile => !String(profile.id).includes(unsavedProfileIdPrefix),
  );

export const getMinAge = (state, age) => {
  const ageGroups = getAgeGroups(state);

  return Object.values(ageGroups || {}).find(
    range => age >= range[0] && age <= range[range.length - 1],
  )?.[0];
};

export const getDraftProfile = state => rootSelector(state)?.draftProfile;

export const getIsDraftProfileUnsaved = state =>
  String(rootSelector(state)?.draftProfile.id).includes(unsavedProfileIdPrefix);

export const getCurrentProfile = state => {
  const profileId = rootSelector(state)?.selectedProfile;
  return getProfiles(state).find(profile => profile.id === profileId);
};

export const getCurrentProfileId = state => {
  return rootSelector(state)?.selectedProfile;
};

export const getProfileAgeGroup = ({ age } = {}) => state => {
  const ageGroups = getAgeGroups(state);

  const [ageGroup] = Object.entries(ageGroups).find(([, ages]) =>
    ages.includes(age),
  ) || [ageGroupsKeys.EIGHT_UP];
  if (!ageGroup) {
    throw new Error('Age entered to find ageGroup is invalid');
  }
  return ageGroup;
};

export const getNewestProfileAgeGroup = state => {
  const [newestProfile] = getProfiles(state)?.slice(-1);
  return getProfileAgeGroup(newestProfile)(state);
};

export const getCurrentProfileBookmarkById = (state, id) => {
  if (!id || id === '') {
    return {};
  }

  const profile = getCurrentProfile(state) || {};
  const result = profile?.bookmarks_v2?.find(item => item.videoId === id) ?? {};
  return result;
};

export const getCurrentProfileAgeGroup = state => {
  const currentProfile = getCurrentProfile(state);
  return getProfileAgeGroup(currentProfile)(state);
};

export const getCurrentProfileAgeContainerId = createSelector(
  getCurrentProfileAgeGroup,
  currentProfileAgeGroup => {
    switch (currentProfileAgeGroup) {
      case ageGroupsKeys.TWO_UP:
        return containersAgeGroup.CONTAINER_2Up;
      case ageGroupsKeys.FIVE_UP:
        return containersAgeGroup.CONTAINER_5Up;
      default:
        return containersAgeGroup.CONTAINER_8Up;
    }
  },
);

export const getTopShowsCollectionName = createSelector(
  getCurrentProfileAgeGroup,
  currentProfileAgeGroup => {
    switch (currentProfileAgeGroup) {
      case ageGroupsKeys.TWO_UP:
        return firestoreTopShowsAgeGroup.TOP_SHOWS_2UP;
      case ageGroupsKeys.FIVE_UP:
        return firestoreTopShowsAgeGroup.TOP_SHOWS_5UP;
      default:
        return firestoreTopShowsAgeGroup.TOP_SHOWS_8UP;
    }
  },
);

export const getCurrentProfileMinAge = createSelector(
  getCurrentProfileAgeGroup,
  currentProfileAgeGroup => {
    switch (currentProfileAgeGroup) {
      case ageGroupsKeys.TWO_UP:
        return ageCategoryGroup.MIN_AGE_TWO_UP;
      case ageGroupsKeys.FIVE_UP:
        return ageCategoryGroup.MIN_AGE_FIVE_UP;
      default:
        return ageCategoryGroup.MIN_AGE_EIGHT_UP;
    }
  },
);

export const getCurrentProfileAgeRange = state => {
  const age = getCurrentProfileMinAge(state);
  const ageRange = ageRanges[ageGroupsMap[age]];
  return ageRange;
};

// Account
export const getAccount = state => rootSelector(state)?.account;

// Get whether the app was started through deeplinking
export const getCurrentProfileDeepLink = state => {
  return rootSelector(state)?.deepLink;
};
export const getDeepLinkAgeMatch = state => {
  return rootSelector(state)?.deepLinkAgeMatch;
};

export const getShowGuestProfileImage = state => {
  return rootSelector(state)?.showGuestProfileImage;
};
