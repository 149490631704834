import { createHorizontalKeyNav } from './keyboardUtils';
import UppercaseArrow from '#/static/images/icons/keyboard/arrow-up.svg';
import Backspace from '#/static/images/icons/keyboard/backspace.svg';

const ALPHABET = [
  {
    text: 'a',
    value: 'a',
    altText: 'A',
    altValue: 'A',
    ariaLabel: 'a',
    nav: {
      id: 'a',
      nextdown: 'SPECIAL_CHARS',
    },
    row: 1,
  },
  {
    text: 'b',
    value: 'b',
    altText: 'B',
    altValue: 'B',
    ariaLabel: 'b',
    nav: {
      id: 'b',
      nextdown: 'SPECIAL_CHARS',
    },
    row: 1,
  },
  {
    text: 'c',
    value: 'c',
    altText: 'C',
    altValue: 'C',
    ariaLabel: 'c',
    nav: {
      id: 'c',
      nextdown: 'SPECIAL_CHARS',
    },
    row: 1,
  },
  {
    text: 'd',
    value: 'd',
    altText: 'D',
    altValue: 'D',
    ariaLabel: 'd',
    nav: {
      id: 'd',
      nextdown: 'SPECIAL_CHARS',
    },
    row: 1,
  },
  {
    text: 'e',
    value: 'e',
    altText: 'E',
    altValue: 'E',
    ariaLabel: 'e',
    nav: {
      id: 'e',
      nextdown: 'SPECIAL_CHARS',
    },
    row: 1,
  },
  {
    text: 'f',
    value: 'f',
    altText: 'F',
    altValue: 'F',
    ariaLabel: 'f',
    nav: {
      id: 'f',
      nextdown: 'SPECIAL_CHARS',
    },
    row: 1,
  },
  {
    text: 'g',
    value: 'g',
    altText: 'G',
    altValue: 'G',
    ariaLabel: 'g',
    nav: {
      id: 'g',
      nextdown: 'SPECIAL_CHARS',
    },
    row: 1,
  },
  {
    text: 'h',
    value: 'h',
    altText: 'H',
    altValue: 'H',
    ariaLabel: 'h',
    nav: {
      id: 'h',
      nextdown: 'SPECIAL_CHARS',
    },
    row: 1,
  },
  {
    text: 'i',
    value: 'i',
    altText: 'I',
    altValue: 'I',
    ariaLabel: 'i',
    nav: {
      id: 'i',
      nextdown: 'UPPERCASE',
    },
    row: 1,
  },
  {
    text: 'j',
    value: 'j',
    altText: 'J',
    altValue: 'J',
    ariaLabel: 'j',
    nav: {
      id: 'j',
      nextdown: 'UPPERCASE',
    },
    row: 1,
  },
  {
    text: 'k',
    value: 'k',
    altText: 'K',
    altValue: 'K',
    ariaLabel: 'k',
    nav: {
      id: 'k',
      nextdown: 'UPPERCASE',
    },
    row: 1,
  },
  {
    text: 'l',
    value: 'l',
    altText: 'L',
    altValue: 'L',
    ariaLabel: 'l',
    nav: {
      id: 'l',
      nextdown: 'NUMBERS',
    },
    row: 1,
  },
  {
    text: 'm',
    value: 'm',
    altText: 'M',
    altValue: 'M',
    ariaLabel: 'm',
    nav: {
      id: 'm',
      nextdown: 'NUMBERS',
    },
    row: 1,
  },
  {
    text: 'n',
    value: 'n',
    altText: 'N',
    altValue: 'N',
    ariaLabel: 'n',
    nav: {
      id: 'n',
      nextdown: 'PREV',
    },
    row: 1,
  },
  {
    text: 'o',
    value: 'o',
    altText: 'O',
    altValue: 'O',
    ariaLabel: 'o',
    nav: {
      id: 'o',
      nextdown: 'PREV',
    },
    row: 1,
  },
  {
    text: 'p',
    value: 'p',
    altText: 'P',
    altValue: 'P',
    ariaLabel: 'p',
    nav: {
      id: 'p',
      nextdown: 'PREV',
    },
    row: 1,
  },
  {
    text: 'q',
    value: 'q',
    altText: 'Q',
    altValue: 'Q',
    ariaLabel: 'q',
    nav: {
      id: 'q',
      nextdown: 'NEXT',
    },
    row: 1,
  },
  {
    text: 'r',
    value: 'r',
    altText: 'R',
    altValue: 'R',
    ariaLabel: 'r',
    nav: {
      id: 'r',
      nextdown: 'NEXT',
    },
    row: 1,
  },
  {
    text: 's',
    value: 's',
    altText: 'S',
    altValue: 'S',
    ariaLabel: 's',
    nav: {
      id: 's',
      nextdown: 'NEXT',
    },
    row: 1,
  },
  {
    text: 't',
    value: 't',
    altText: 'T',
    altValue: 'T',
    ariaLabel: 't',
    nav: {
      id: 't',
      nextdown: 'NEXT',
    },
    row: 1,
  },
  {
    text: 'u',
    value: 'u',
    altText: 'U',
    altValue: 'U',
    ariaLabel: 'u',
    nav: {
      id: 'u',
      nextdown: 'DELETE',
    },
    row: 1,
  },
  {
    text: 'v',
    value: 'v',
    altText: 'V',
    altValue: 'V',
    ariaLabel: 'v',
    nav: {
      id: 'v',
      nextdown: 'DELETE',
    },
    row: 1,
  },
  {
    text: 'w',
    value: 'w',
    altText: 'W',
    altValue: 'W',
    ariaLabel: 'w',
    nav: {
      id: 'w',
      nextdown: 'DELETE',
    },
    row: 1,
  },
  {
    text: 'x',
    value: 'x',
    altText: 'X',
    altValue: 'X',
    ariaLabel: 'x',
    nav: {
      id: 'x',
      nextdown: 'DELETE',
    },
    row: 1,
  },
  {
    text: 'y',
    value: 'y',
    altText: 'Y',
    altValue: 'Y',
    ariaLabel: 'y',
    nav: {
      id: 'y',
      nextdown: 'DELETE',
    },
    row: 1,
  },
  {
    text: 'z',
    value: 'z',
    altText: 'Z',
    altValue: 'Z',
    ariaLabel: 'z',
    nav: {
      id: 'z',
      nextdown: 'DELETE',
    },
    row: 1,
  },
];

const SECOND_ROW = [
  {
    text: '@ & #',
    value: 'SPECIAL_CHARS',
    special: true,
    ariaLabel: 'Special characters',
    nav: {
      id: 'SPECIAL_CHARS',
      nextup: 'b',
    },
    numbersNav: {
      nextup: '1',
    },
    specialNav: {
      nextup: '!',
    },
    otherCharsNav: {
      nextup: 'ó',
    },
    row: 2,
    customSize: 3.5,
  },
  {
    text: '^',
    icon: {
      src: UppercaseArrow,
    },
    value: 'UPPERCASE',
    special: true,
    ariaLabel: 'Uppercase',
    nav: {
      id: 'UPPERCASE',
      nextup: 'f',
    },
    numbersNav: {
      nextup: '2',
    },
    specialNav: {
      nextup: '#',
    },
    otherCharsNav: {
      nextup: 'â',
    },
    row: 2,
    customSize: 3,
  },
  {
    text: '1 2 3',
    value: 'NUMBERS',
    special: true,
    ariaLabel: 'Numbers',
    nav: {
      id: 'NUMBERS',
      nextup: 'u',
    },
    numbersNav: {
      nextup: '8',
    },
    specialNav: {
      nextup: ',',
    },
    otherCharsNav: {
      nextup: 'å',
    },
    row: 2,
    customSize: 1.5,
  },
  {
    text: 'PREV',
    value: 'PREV',
    special: false,
    ariaLabel: 'Previous',
    nav: {
      id: 'PREV',
      nextup: 'w',
    },
    numbersNav: {
      nextup: '8',
    },
    specialNav: {
      nextup: ';',
    },
    row: 2,
    customSize: 3.5,
  },
  {
    text: 'NEXT',
    value: 'NEXT',
    special: false,
    ariaLabel: 'Next',
    nav: {
      id: 'NEXT',
      nextup: 'w',
    },
    numbersNav: {
      nextup: '8',
    },
    specialNav: {
      nextup: ';',
    },
    row: 2,
    customSize: 3.5,
  },
  {
    text: 'Delete',
    value: 'DELETE',
    icon: {
      src: Backspace,
    },
    special: true,
    ariaLabel: 'Delete',
    nav: {
      id: 'DELETE',
      nextup: 'z',
    },
    numbersNav: {
      nextup: '9',
    },
    specialNav: {
      nextup: '\\',
    },
    otherCharsNav: {
      nextup: '¿',
    },
    row: 2,
    customSize: 1.5,
  },
];

const NUMBERS = [
  {
    text: '0',
    value: '0',
    ariaLabel: '0',
    nav: {
      id: '0',
      nextdown: 'SPECIAL_CHARS',
    },
    row: 3,
  },
  {
    text: '1',
    value: '1',
    ariaLabel: '1',
    nav: {
      id: '1',
      nextdown: 'SPECIAL_CHARS',
    },
    row: 3,
  },
  {
    text: '2',
    value: '2',
    ariaLabel: '2',
    nav: {
      id: '2',
      nextdown: 'SPECIAL_CHARS',
    },
    row: 3,
  },
  {
    text: '3',
    value: '3',
    ariaLabel: '3',
    nav: {
      id: '3',
      nextdown: 'UPPERCASE',
    },
    row: 3,
  },
  {
    text: '4',
    value: '4',
    ariaLabel: '4',
    nav: {
      id: '4',
      nextdown: 'NUMBERS',
    },
    row: 3,
  },
  {
    text: '5',
    value: '5',
    ariaLabel: '5',
    nav: {
      id: '5',
      nextdown: 'PREV',
    },
    row: 3,
  },
  {
    text: '6',
    value: '6',
    ariaLabel: '6',
    nav: {
      id: '6',
      nextdown: 'NEXT',
    },
    row: 3,
  },
  {
    text: '7',
    value: '7',
    ariaLabel: '7',
    nav: {
      id: '7',
      nextdown: 'NEXT',
    },
    row: 3,
  },
  {
    text: '8',
    value: '8',
    ariaLabel: '8',
    nav: {
      id: '8',
      nextdown: 'DELETE',
    },
    row: 3,
    customSize: 3,
  },
  {
    text: '9',
    value: '9',
    ariaLabel: '9',
    nav: {
      id: '9',
      nextdown: 'DELETE',
    },
    row: 3,
    customSize: 3,
  },
];

const SPECIAL_KEYS = [
  {
    text: '.',
    value: '.',
    ariaLabel: '.',
    nav: {
      id: '.',
      nextdown: 'SPECIAL_CHARS',
    },
    row: 4,
  },
  {
    text: '-',
    value: '-',
    ariaLabel: '-',
    nav: {
      id: '-',
      nextdown: 'SPECIAL_CHARS',
    },
    row: 4,
  },
  {
    text: '_',
    value: '_',
    ariaLabel: '_',
    nav: {
      id: '_',
      nextdown: 'SPECIAL_CHARS',
    },
    row: 4,
  },
  {
    text: '!',
    value: '!',
    ariaLabel: '!',
    nav: {
      id: '!',
      nextdown: 'SPECIAL_CHARS',
    },
    row: 4,
  },
  {
    text: '@',
    value: '@',
    ariaLabel: '@',
    nav: {
      id: '@',
      nextdown: 'SPECIAL_CHARS',
    },
    row: 4,
  },
  {
    text: '#',
    value: '#',
    ariaLabel: '#',
    nav: {
      id: '#',
      nextdown: 'SPECIAL_CHARS',
    },
    row: 4,
  },
  {
    text: '$',
    value: '$',
    ariaLabel: '$',
    nav: {
      id: '$',
      nextdown: 'SPECIAL_CHARS',
    },
    row: 4,
  },
  {
    text: '%',
    value: '%',
    ariaLabel: '%',
    nav: {
      id: '%',
      nextdown: 'SPECIAL_CHARS',
    },
    row: 4,
  },
  {
    text: '^',
    value: '^',
    ariaLabel: '^',
    nav: {
      id: '^',
      nextdown: 'UPPERCASE',
    },
    row: 4,
  },
  {
    text: '&',
    value: '&',
    ariaLabel: '&',
    nav: {
      id: '&',
      nextdown: 'UPPERCASE',
    },
    row: 4,
  },
  {
    text: '*',
    value: '*',
    ariaLabel: '*',
    nav: {
      id: '*',
      nextdown: 'UPPERCASE',
    },
    row: 4,
  },
  {
    text: "'",
    value: "'",
    ariaLabel: "'",
    nav: {
      id: "'",
      nextdown: 'NUMBERS',
    },
    row: 4,
  },
  {
    text: '+',
    value: '+',
    ariaLabel: '+',
    nav: {
      id: '+',
      nextdown: 'NUMBERS',
    },
    row: 4,
  },
  {
    text: '/',
    value: '/',
    ariaLabel: '/',
    nav: {
      id: '/',
      nextdown: 'PREV',
    },
    row: 4,
  },
  {
    text: '=',
    value: '=',
    ariaLabel: '=',
    nav: {
      id: '=',
      nextdown: 'PREV',
    },
    row: 4,
  },
  {
    text: '?',
    value: '?',
    ariaLabel: '?',
    nav: {
      id: '?',
      nextdown: 'PREV',
    },
    row: 4,
  },
  {
    text: '~',
    value: '~',
    ariaLabel: '~',
    nav: {
      id: '~',
      nextdown: 'NEXT',
    },
    row: 4,
  },
  {
    text: '"',
    value: '"',
    ariaLabel: '"',
    nav: {
      id: '"',
      nextdown: 'NEXT',
    },
    row: 4,
  },
  {
    text: '(',
    value: '(',
    ariaLabel: '(',
    nav: {
      id: '(',
      nextdown: 'NEXT',
    },
    row: 4,
  },
  {
    text: ')',
    value: ')',
    ariaLabel: ')',
    nav: {
      id: ')',
      nextdown: 'NEXT',
    },
    row: 4,
  },
  {
    text: ',',
    value: ',',
    ariaLabel: ',',
    nav: {
      id: ',',
      nextdown: 'DELETE',
    },
    row: 4,
  },
  {
    text: ':',
    value: ':',
    ariaLabel: ':',
    nav: {
      id: ':',
      nextdown: 'DELETE',
    },
    row: 4,
  },
  {
    text: ';',
    value: ';',
    ariaLabel: ';',
    nav: {
      id: ';',
      nextdown: 'DELETE',
    },
    row: 4,
  },
  {
    text: '<',
    value: '<',
    ariaLabel: '<',
    nav: {
      id: '<',
      nextdown: 'DELETE',
    },
    row: 4,
  },
  {
    text: '>',
    value: '>',
    ariaLabel: '>',
    nav: {
      id: '>',
      nextdown: 'DELETE',
    },
    row: 4,
  },
  {
    text: '\\',
    value: '\\',
    ariaLabel: '',
    nav: {
      id: '\\',
      nextdown: 'DELETE',
    },
    row: 4,
  },
];

export const KEYS = [
  ...createHorizontalKeyNav(ALPHABET),
  ...createHorizontalKeyNav(SECOND_ROW),
  ...createHorizontalKeyNav(NUMBERS),
  ...createHorizontalKeyNav(SPECIAL_KEYS),
];
