import { handleActions } from 'redux-actions';
import { actions } from './actions';

const initialState = {
  topics: [],
  shows: [],
  topShows: [],
  containerShows: {},
  containerPodcasts: {
    collection: null,
    items: [],
  },
  hero: {
    collection: null,
    items: [],
  },
  contents: [],
  avatars: [],
};

const saveAllTopicsReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    topics: payload,
  };
};

const saveAllShowsReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    shows: payload,
  };
};

const saveTopShowsReducer = (state = initialState, { payload: topShows }) => ({
  ...state,
  topShows,
});

const clearContainerShowsReducer = (state = initialState) => ({
  ...state,
  containerShows: {},
});

const saveContainerShowsReducer = (state = initialState, { payload }) => ({
  ...state,
  containerShows: {
    ...state.containerShows,
    [payload.collectionName]: {
      collection: payload.collectionName,
      items: payload.data,
    },
  },
});
const saveContainerPodcastsReducer = (state = initialState, { payload }) => ({
  ...state,
  containerPodcasts: {
    collection: payload.collectionName,
    items: payload.data,
  },
});

const saveHeroContentReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    hero: {
      collection: payload.collectionName,
      items: payload.data,
    },
  };
};

const clearHeroContentReducer = (state = initialState) => {
  return {
    ...state,
    hero: initialState.hero,
  };
};

const saveContentReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    contents: payload,
  };
};

const clearContentDetailsReducer = (state = initialState) => ({
  ...state,
  contents: [],
});

const saveAvatarsReducer = (state = initialState, { payload }) => ({
  ...state,
  avatars: [...payload],
});

const reducer = handleActions(
  {
    [actions.saveAllTopics]: saveAllTopicsReducer,
    [actions.saveAllShows]: saveAllShowsReducer,
    [actions.saveTopShows]: saveTopShowsReducer,
    [actions.saveHeroContent]: saveHeroContentReducer,
    [actions.clearHeroContent]: clearHeroContentReducer,
    [actions.clearContainerShows]: clearContainerShowsReducer,
    [actions.saveContainerShows]: saveContainerShowsReducer,
    [actions.saveContainerPodcasts]: saveContainerPodcastsReducer,
    [actions.saveContent]: saveContentReducer,
    [actions.clearContentDetails]: clearContentDetailsReducer,
    [actions.saveAvatars]: saveAvatarsReducer,
  },
  initialState,
);

export default reducer;
