import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import RedirectWithQuery from '#/components/App/RedirectWithQuery';

import ErrorDispatcher from '../ErrorDispatcher/ErrorDispatcher';

import { actions as modalActions } from '#/redux/modules/modal/actions';
import { actions as onboardingActions } from '#/redux/modules/onboarding/actions';
import {
  actions as identityActions,
  ASYNC_SWITCH_PROFILE,
  switchToParentProfile,
  loadProfiles,
} from '#/redux/modules/identity/actions';
import { getPin, getParentMode } from '#/redux/modules/identity/selectors';

export const enterPin = dispatch =>
  dispatch(
    modalActions.openModal({
      modalId: 'PinModal',
      successAction: {
        type: String(identityActions.setPin),
      },
    }),
  );

// PinGateRedirect switches to the parent profile
const PinGateRedirect = ({
  to,
  unsetProfile = false,
  unsetPin = true,
  history,
}) => {
  const dispatch = useDispatch();
  const pin = useSelector(getPin);
  const parentMode = useSelector(getParentMode);
  const {
    location: { pathname },
  } = history;

  // If pin is set, switch profile
  useEffect(() => {
    if (!to || !pin) {
      return;
    }
    // Already in parent mode
    if (parentMode) {
      return;
    }
    dispatch(switchToParentProfile({ unsetPin }));
  }, [to, pin, parentMode]);

  // Profile data can be incomplete in child context, when profile has been switched to the parent, load fresh profile data
  useEffect(() => {
    if (!to || !parentMode) {
      return;
    }
    dispatch(loadProfiles());
    if (unsetProfile) {
      dispatch(onboardingActions.setSelectedProfile(null));
    }
  }, [to, parentMode]);

  return (
    <>
      <ErrorDispatcher errorId={ASYNC_SWITCH_PROFILE} />
      {to && pathname !== to && parentMode ? (
        <RedirectWithQuery to={to} />
      ) : null}
    </>
  );
};
PinGateRedirect.propTypes = {
  to: PropTypes.string,
  unsetProfile: PropTypes.bool,
  unsetPin: PropTypes.bool,
  history: PropTypes.any,
};

export default withRouter(PinGateRedirect);
