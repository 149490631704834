import { handleActions } from 'redux-actions';

import { actions } from './actions';

const noopAction = { type: 'noop' };

const initialState = {
  open: false,
  modalId: null,
  successAction: noopAction,
  finallyAction: noopAction,
  modalData: {},
  inputFocus: false,
};

const openModal = (state = initialState, { payload }) => ({
  ...state,
  ...payload,
  open: true,
});

const closeModal = (state = initialState) => ({
  ...state,
  ...initialState,
});

const inputFocus = (state = initialState, { payload }) => ({
  ...state,
  inputFocus: payload,
});

const reducer = handleActions(
  {
    [actions.openModal]: openModal,
    [actions.closeModal]: closeModal,
    [actions.inputFocus]: inputFocus,
  },
  initialState,
);

export default reducer;
