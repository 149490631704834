import React, { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ContentLayer from '#/components/ContentLayer/ContentLayer';
import { loadContainers } from '#/redux/modules/brightcove/actions';
import {
  getCurrentProfile,
  getCurrentProfileAgeGroup,
} from '#/redux/modules/onboarding/selectors';
import {
  getCustomDataVideosBlocked,
  getCustomDataShowsBlocked,
} from '#/redux/modules/identity/selectors';
import { toggleLoginSpinner } from '#/redux/modules/spinner/actions';
import {
  ageGroupsKeys,
  containersAgeGroup,
  containersTypeAlias,
} from '#/config/constants';
import { actions as modalActions } from '#/redux/modules/modal/actions';
import {
  getCurrentAgeMetadata,
  getEntryById,
} from '#/redux/modules/accedoOne/selectors';
import { isGuestMode } from '#/redux/modules/user/selectors';
import { useLocalStorage } from '#/hooks/useLocalStorage';

import storage from '#/utils/localStorage';

const initialWelcomeData = (profileId, ageRange) => {
  const data = JSON.parse(storage.get('welcomeModal')) || {};

  if (profileId === 'UNKNOWN') {
    const updatedData = data;

    if (!('guest' in data)) {
      updatedData.guest = {};
    }
    updatedData.guest[`${ageRange}`] = {
      showWelcomeModal: true,
    };

    return updatedData;
  }

  if (!(profileId in data)) {
    const updatedData = data;
    updatedData[`${profileId}`] = {
      showWelcomeModal: true,
    };

    return updatedData;
  }

  return data;
};

const Home = ({ content, history, entries }) => {
  const currentPath = history.location.pathname.split('/').slice(-1)[0];
  const dispatch = useDispatch();
  const customDataShowsBlocked = useSelector(getCustomDataShowsBlocked);
  const customDataVideosBlocked = useSelector(getCustomDataVideosBlocked);
  const guestMode = useSelector(isGuestMode);
  const currentProfile = useSelector(getCurrentProfile) || {};
  const selectedProfileId = currentProfile?.id;
  const selectedAgeGroup = useSelector(state =>
    getCurrentProfileAgeGroup(state),
  );

  const selectedAgeMetadata = useSelector(state =>
    getCurrentAgeMetadata(state),
  );
  const welcomeModalEntryData = useSelector(state =>
    getEntryById(state, selectedAgeMetadata?.welcomeModals),
  );

  const [showWelcomeModal, setShowWelcomeModal] = useLocalStorage(
    'welcomeModal',
    initialWelcomeData(selectedProfileId),
  );

  const selectedContainers = useMemo(() => {
    switch (selectedAgeGroup) {
      case ageGroupsKeys.TWO_UP:
        return containersAgeGroup.CONTAINER_2Up;
      case ageGroupsKeys.FIVE_UP:
        return containersAgeGroup.CONTAINER_5Up;
      default:
        return containersAgeGroup.CONTAINER_8Up;
    }
  }, [selectedAgeGroup]);

  const getContainers = useMemo(() => {
    if (Object.keys(content).length > 0) {
      const containers = [];
      content?.[currentPath]?.[selectedContainers]?.forEach(item => {
        const menuItem = entries?.[item];
        if (menuItem) {
          containers.push(menuItem);
        }
      });

      dispatch(
        loadContainers({
          queries: containers
            .filter(
              container =>
                container._meta.typeAlias ===
                containersTypeAlias.CONTAINER_OVP_PLAYLIST,
            )
            .map(container => ({
              playlistId: container.playlistId,
              containerId: container._meta.id,
            })),
        }),
      );

      return containers;
    }
    return null;
  }, [content, customDataShowsBlocked, customDataVideosBlocked]);

  useEffect(() => {
    if (content) {
      requestAnimationFrame(() => {
        dispatch(toggleLoginSpinner(false));
      });
    }
  }, []);

  useEffect(() => {
    if (guestMode) {
      const getWelcomeModalData =
        showWelcomeModal?.guest?.[`${selectedAgeMetadata.ageGroupValue}`]
          ?.showWelcomeModal;

      if (
        (getWelcomeModalData || getWelcomeModalData === undefined) &&
        selectedAgeMetadata.welcomeModals &&
        welcomeModalEntryData
      ) {
        dispatch(
          modalActions.openModal({
            modalId: 'WelcomeModal',
            modalData: {
              modalId: selectedAgeMetadata.welcomeModals,
            },
          }),
        );

        const newWelcomeModalData = showWelcomeModal;

        if (!('guest' in newWelcomeModalData)) {
          newWelcomeModalData.guest = {};
        }

        newWelcomeModalData.guest[`${selectedAgeMetadata.ageGroupValue}`] = {
          showWelcomeModal: false,
        };
        setShowWelcomeModal(newWelcomeModalData);
      }
    } else {
      const getWelcomeModalData =
        showWelcomeModal?.[`${selectedProfileId}`]?.showWelcomeModal;

      if (
        (getWelcomeModalData || getWelcomeModalData === undefined) &&
        selectedAgeMetadata.welcomeModals &&
        welcomeModalEntryData
      ) {
        dispatch(
          modalActions.openModal({
            modalId: 'WelcomeModal',
            modalData: {
              modalId: selectedAgeMetadata.welcomeModals,
            },
          }),
        );

        const newWelcomeModalData = showWelcomeModal;
        newWelcomeModalData[`${selectedProfileId}`] = {
          showWelcomeModal: false,
        };
        setShowWelcomeModal(newWelcomeModalData);
      }
    }
  }, [guestMode, selectedProfileId, showWelcomeModal]);

  return (
    <>
      {Object.keys(content).length > 0 ? (
        <ContentLayer containers={getContainers} />
      ) : null}
    </>
  );
};

Home.propTypes = {
  content: PropTypes.object,
  history: PropTypes.object,
  entries: PropTypes.object,
};

export default Home;
