import { getAccedoOneClient } from '../../../services/accedoOne/accedoOne';
import { actions } from '.';

const EXPITY_TIME_IN_MS = 3600000;

export const forceNewSession = async (dispatch, action) => {
  const client = getAccedoOneClient();
  const sessionIdTs = new Date();
  const sessionKey = await client.createSession();
  const {
    config: { appKey, deviceId },
  } = client;
  return dispatch(
    action({
      sessionKey,
      appKey,
      deviceId,
      sessionIdTs: sessionIdTs.getTime(),
    }),
  );
};

export const verifyToken = async (user, dispatcher) => {
  const currentTS = new Date().getTime();
  if (user.sessionIdTs && currentTS - user.sessionIdTs > EXPITY_TIME_IN_MS) {
    await forceNewSession(dispatcher, actions.setClient);
  }
};

export const accedoOneMiddleware = store => next => async action => {
  if (action == null) {
    console.warn('[AccedoOne] Caught undefined action');
    return;
  }
  let result;
  if (typeof action === 'function') {
    const state = store.getState();
    const {
      modules: { accedoOne },
    } = state;
    await verifyToken(accedoOne, store.dispatch);
    result = action(store.dispatch, store.getState);
  } else if (
    action.type?.indexOf('accedoOne') > -1 &&
    !(action.type.indexOf('SET_CLIENT') > -1)
  ) {
    const state = store.getState();
    const {
      modules: { accedoOne },
    } = state;
    await verifyToken(accedoOne, store.dispatch);
    result = next(action);
  } else {
    result = next(action);
  }
  return result;
};
