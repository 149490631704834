import moment from 'moment-mini';

export const playerTimeFormatter = (seconds, guide) => {
  if (Number.isNaN(seconds) || seconds === Infinity) {
    return '-- : --';
  }
  seconds = seconds < 0 ? 0 : seconds;

  if (guide < 600) {
    return moment.utc(seconds * 1000).format('m:ss');
  }
  if (guide < 3600) {
    return moment.utc(seconds * 1000).format('mm:ss');
  }
  return moment.utc(seconds * 1000).format('h:mm:ss');
};

export const videoTileTimeFormatter = milliseconds => {
  milliseconds = milliseconds < 0 ? 0 : milliseconds;

  if (milliseconds < 600000) {
    return moment.utc(milliseconds).format('m:ss');
  }
  if (milliseconds < 3600000) {
    return moment.utc(milliseconds).format('mm:ss');
  }
  return moment.utc(milliseconds).format('h:mm:ss');
};

// expects a moment object for item
export const livePlayerFormatter = (item, start) =>
  item?.local().format(`h:mm${start ? '' : 'a'}`);

export default {
  playerTimeFormatter,
  videoTileTimeFormatter,
  livePlayerFormatter,
};
