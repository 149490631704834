import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withFocus, focusManager } from '@accedo/vdkweb-navigation';
import classNames from 'classnames';
import getVw from '#/utils/getVw';
import { subRoutes } from '#/utils/routes';
import { containersTypeAlias } from '#/config/constants';
import styles from './topicCard.scss';
import { isWorkstation } from '#/utils/componentStyleConfig';
import { FocusDiv } from '#/widgets/ui';

const { CONTAINER_TOPICS, CONTAINER_OVP_PLAYLIST } = containersTypeAlias;

const Container = ({
  nav,
  children,
  className,
  onClick,
  onMouseOver,
  style,
  ariaLabel,
}) => {
  return !isWorkstation() ? (
    <FocusDiv
      nav={nav}
      className={`${className} ${nav.isFocused ? styles.focused : ''}`}
      onClick={onClick}
      style={style}
      ariaLabel={ariaLabel}
    >
      {children}
    </FocusDiv>
  ) : (
    <div
      onClick={onClick}
      onMouseOver={onMouseOver}
      className={className}
      style={style}
    >
      {children}
    </div>
  );
};

const RouteContainer = withRouter(Container);
const FocusContainer = withFocus(RouteContainer);

const TopicCard = ({
  className,
  nav,
  imgUrl,
  id,
  title,
  roundedCorners,
  onItemFocus,
  history,
  location,
  onClick,
  playlistId = '0',
  ariaLabel,
}) => {
  const onFocus = useCallback(() => {
    if (onItemFocus) {
      onItemFocus(nav.id);
    }
    focusManager.changeFocus(nav.id);
  }, []);
  const [, path1, path2] = location?.pathname?.split('/');
  return (
    <>
      <FocusContainer
        nav={nav}
        className={classNames(styles.card, className, {
          [styles.isTv]: !isWorkstation(),
        })}
        onClick={() => {
          if (onClick) {
            onClick();
          } else {
            const path = `/${path1}/${path2}/${subRoutes.SEE_ALL}/${id}/${title}`;
            const pathname =
              playlistId === '0'
                ? `${path}/${CONTAINER_TOPICS}`
                : `${path}/${CONTAINER_OVP_PLAYLIST}/${playlistId}`;
            history.push({ pathname });
          }
        }}
        onMouseOver={!isWorkstation() ? () => {} : onFocus}
        onFocus={onFocus}
        style={{
          borderRadius: roundedCorners ? getVw(24) : '0px',
          outline: 'none',
        }}
        ariaLabel={ariaLabel || title}
      >
        <div
          style={{ backgroundImage: `url(${imgUrl})` }}
          className={styles.cardImageContainer}
          aria-hidden="true"
        />
      </FocusContainer>
    </>
  );
};

Container.propTypes = {
  nav: PropTypes.object,
  children: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  style: PropTypes.object,
  ariaLabel: PropTypes.string,
};

TopicCard.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
  nav: PropTypes.object,
  imgUrl: PropTypes.string,
  roundedCorners: PropTypes.bool,
  onItemFocus: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  onClick: PropTypes.func,
  playlistId: PropTypes.string,
  ariaLabel: PropTypes.string,
};

export default withRouter(TopicCard);
