import React, { useRef, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { getMonths } from '#/redux/modules/onboarding/selectors';

import styles from './InfoForm.scss';

const InfoForm = ({
  children,
  onChange = () => {},
  className,
  protectedFields = [],
}) => {
  const months = useSelector(getMonths);
  const form = useRef(null);
  const handleUpdate = useCallback(
    (formData, isValid) => {
      const data = {};
      formData.forEach((v, k) => {
        if (k in protectedFields) {
          return;
        }
        data[k] = v === 'true' ? true : v;
      });
      if (Number.isNaN(Number(data.birthdayMonth))) {
        data.birthdayMonth = String(months.indexOf(data.birthdayMonth));
      }
      onChange(data, isValid);
    },
    [protectedFields, onChange],
  );

  useEffect(() => {
    handleUpdate(new FormData(form.current), form.current.checkValidity());
  }, []);

  return (
    <form
      ref={form}
      onChange={() =>
        handleUpdate(new FormData(form.current), form.current.checkValidity())
      }
      className={cx(styles.infoForm, { [className]: Boolean(className) })}
    >
      {children}
    </form>
  );
};

InfoForm.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onChange: PropTypes.func,
  protectedFields: PropTypes.array,
};

export default InfoForm;
