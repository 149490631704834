import { videoAnalytics } from '#/config/constants';
import { clearAssetData } from '#/redux/modules/brightcove/actions';
import {
  setVideoContent,
  setVideoBookmark,
  setBackgroundPlayerFullscreen,
} from '#/redux/modules/appPlayer/actions';
import { setHeader } from '#/redux/modules/menu/actions';
import { isWorkstation } from '#/utils/componentStyleConfig';

import assembleCurrentVideo from '#/utils/assembleCurrentVideo';

export const getItemId = (swimlaneId, index) => `${swimlaneId}-ITEM-${index}`;

export const setSwimlaneNav = swimlaneNav => ({
  ...swimlaneNav,
  forwardFocus: getItemId(swimlaneNav.id, 0),
  useLastFocus: true,
});

export const onVideoTileClick = (
  containerIds,
  dispatch,
  fromHome = false,
  doBookmark = false,
) => {
  return containerIndex => (item, videoIndex) => {
    return initiation => {
      doBookmark && dispatch(setVideoBookmark());
      !fromHome && dispatch(clearAssetData());
      dispatch(
        setVideoContent({
          playlists: containerIds,
          currentPlaylistIndex: containerIndex,
          currentVideoIndex: videoIndex,
          currentVideo: assembleCurrentVideo(item),
          initiation: initiation || videoAnalytics.initiation.AUTO,
          bgBookmarkEnabled: false,
        }),
      );
      if (fromHome && !isWorkstation()) {
        dispatch(setBackgroundPlayerFullscreen(true));
        dispatch(setHeader(false));
      }
    };
  };
};
