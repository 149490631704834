import xdk from '@accedo/xdk-core';

const DEFAULT_VALUE = 'as broadcast';

const TEXT_EDGES_VALUES = {
  NONE: 'none',
  RAISED: 'raised',
  DEPRESSED: 'depressed',
  UNIFORM: 'uniform',
  DROP_SHADOW: 'drop shadow',
};

export const OPACITY_VALUES = {
  SOLID: 'solid',
  TRANSLUCENT: 'translucent',
  TRANSPARENT: 'transparent',
  FLASHING: 'flashing',
};

const stringToRgb = colorName => {
  switch (colorName?.toLowerCase()) {
    case 'black':
      return '0, 0, 0';
    case 'white':
      return '255, 255, 255';
    case 'green':
      return '0, 128, 0';
    case 'blue':
      return '0, 0, 255';
    case 'red':
      return '255, 0, 0';
    case 'cyan':
      return '0, 255, 255';
    case 'yellow':
      return '255, 255, 0';
    case 'magenta':
      return '255, 0, 255';
    default:
      return '255, 255, 255';
  }
};

const parseOpacity = vizioValue => {
  if (typeof vizioValue === 'number') {
    return vizioValue;
  }

  switch (vizioValue?.toLowerCase()) {
    case OPACITY_VALUES.SOLID:
      return 1;
    case OPACITY_VALUES.TRANSLUCENT:
      return 0.5;
    case OPACITY_VALUES.TRANSPARENT:
      return 0;
    default:
      return 1;
  }
};

const stringRgbToRgba = (rgb, opacity) => {
  if (typeof opacity === 'string') {
    return rgb
      .replace('rgb', 'rgba')
      .replace(')', `,${parseOpacity(opacity)})`);
  }

  return rgb?.replace('rgb', 'rgba').replace(')', `,${opacity})`);
};

const parseEdges = (edgeType, color) => {
  switch (edgeType?.toLowerCase()) {
    case TEXT_EDGES_VALUES.RAISED:
      return `-2px 0px ${color}`;
    case TEXT_EDGES_VALUES.DEPRESSED:
      return `2px 0px ${color}`;
    case TEXT_EDGES_VALUES.UNIFORM:
      return `0px 0px 2px ${color}`;
    case TEXT_EDGES_VALUES.DROP_SHADOW:
      return `3px 0px ${color}`;
    default:
      return undefined;
  }
};

const parseColorAndOpacityToRgba = (color, opacity) => {
  return `rgba(${stringToRgb(color)}, ${parseOpacity(opacity)})`;
};

export const vw = (pixels, context = 1920) => {
  const VWContext = context * 0.01 * 1;

  return `${pixels / VWContext}vw`;
};

export const isFlashing = opacity => {
  if (!opacity) {
    return false;
  }

  return opacity?.toLowerCase() === OPACITY_VALUES.FLASHING;
};

export const isDefaultValue = value => {
  if (!value) {
    return false;
  }

  return value?.toLowerCase() === DEFAULT_VALUE;
};

export const getWindowBackground = (ccStyles, ccDefaultStyles) => {
  if (!ccStyles) {
    return stringRgbToRgba(
      ccDefaultStyles.windowColor,
      ccDefaultStyles.windowOpacity,
    );
  }

  const finalOpacity = isDefaultValue(ccStyles.window_opacity)
    ? ccDefaultStyles.windowOpacity
    : ccStyles.window_opacity;

  if (isDefaultValue(ccStyles.window_color)) {
    return stringRgbToRgba(ccDefaultStyles.windowColor, finalOpacity);
  }

  return parseColorAndOpacityToRgba(ccStyles.window_color, finalOpacity);
};

export const getBackground = (ccStyles, ccDefaultStyles) => {
  if (!ccStyles) {
    return stringRgbToRgba(
      ccDefaultStyles.backgroundColor,
      ccDefaultStyles.backgroundOpacity,
    );
  }

  const finalOpacity = isDefaultValue(ccStyles.background_opacity)
    ? ccDefaultStyles.backgroundOpacity
    : ccStyles.background_opacity;

  if (isDefaultValue(ccStyles.background_color)) {
    return stringRgbToRgba(ccDefaultStyles.backgroundColor, finalOpacity);
  }

  return parseColorAndOpacityToRgba(ccStyles.background_color, finalOpacity);
};

export const getEdgesStyle = (ccStyles, ccDefaultStyles) => {
  if (!ccStyles) {
    return parseEdges(
      ccDefaultStyles.textEdges,
      ccDefaultStyles.textEdgesColor,
    );
  }

  const finalEdgeType = isDefaultValue(ccStyles.text_edges)
    ? ccDefaultStyles.textEdges
    : ccStyles.text_edges;

  if (isDefaultValue(ccStyles.text_edges_color)) {
    return parseEdges(finalEdgeType, ccDefaultStyles.textEdgesColor);
  }

  return parseEdges(finalEdgeType, ccStyles.text_edges_color);
};

export const getTextColor = (ccStyles, ccDefaultStyles) => {
  if (!ccStyles) {
    return stringRgbToRgba(
      ccDefaultStyles.textColor,
      ccDefaultStyles.textOpacity,
    );
  }

  if (ccStyles?.text_opacity?.toLowerCase() === OPACITY_VALUES.TRANSPARENT) {
    return undefined;
  }

  const finalOpacity = isDefaultValue(ccStyles.text_opacity)
    ? ccDefaultStyles.textOpacity.toString()
    : ccStyles.text_opacity;

  if (isDefaultValue(ccStyles.text_color)) {
    return stringRgbToRgba(ccDefaultStyles.textColor, finalOpacity);
  }

  return parseColorAndOpacityToRgba(ccStyles.text_color, finalOpacity);
};

export const getActiveCue = () => {
  const textTracks = xdk.media?.__player?._vjs?.textTracks();
  const textTrack = textTracks
    ? Array.from(textTracks).find(track => track.kind !== 'metadata')
    : undefined;
  const { activeCues = [] } = textTrack || {};
  const { text: activeCue = '' } =
    activeCues && activeCues.length > 0 ? activeCues[0] : {};
  return activeCue;
};
