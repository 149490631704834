import { createActions } from 'redux-actions';
import { actions as userActions } from '#/redux/modules/user/actions';
import {
  RESET_STATE,
  SAVE_CUSTOM_DATA_RECENTLY_WATCHED,
  CLEAR_CUSTOM_DATA_RECENTLY_WATCHED,
  SAVE_CUSTOM_DATA_VIDEO_BOOKMARK,
  CLEAR_CUSTOM_DATA_VIDEO_BOOKMARK,
  logout as identityUserLogout,
  isValidAge,
  // canUpdateCustomData,
} from '#/redux/modules/identity/identityUser/actions';
import { getParentUserId } from '#/redux/modules/identity/identityUser/selectors';
import {
  getStoredCustomData,
  getRecentlyWatchedByAge,
  getBookmaksByAge,
} from '#/redux/modules/guest/selectors';
import {
  getCurrentProfile,
  getCurrentProfileId,
} from '#/redux/modules/onboarding/selectors';
import {
  getRecentlyWatchedLimit,
  getBookmarksLimit,
} from '#/redux/modules/accedoOne/selectors';

import {
  actions as brightcoveActions,
  loadRecentlyWatchedAssetsByReferenceIds,
  stopLoadingRecentlyWatched,
} from '#/redux/modules/brightcove/actions';
import { getRecentlyWatchedAssetsByReferenceIds } from '#/redux/modules/brightcove/selectors';
import { getCurrentVideo } from '#/redux/modules/appPlayer/selectors';

import {
  updateRecentlyWatchedList,
  addToRecentlyWatchedList,
} from '#/utils/recentlyWatchedUtils';
import { aggregateBirthdate } from '#/utils/aggregateBirthdate';

/**
 * New strings can be exported and they will be include as part of
 * identity.js exports.
 * Strings do NOT have a counterpart for guest. You can import them
 * from identityUser.
 * String with same name will be replaced favoring identityUser strings
 */

// prefix for identityGuest actions
export const IDENTITY_GUEST_ACTION_PREFIX = 'app/guest';
const actionOptions = {
  prefix: IDENTITY_GUEST_ACTION_PREFIX,
};

export const UPDATE_CUSTOM_DATA_BOOKMARKS = 'UPDATE_CUSTOM_DATA_BOOKMARKS';
export const ADD_SINGLE_CUSTOM_DATA_RECENTLY_WATCHED =
  'ADD_SINGLE_CUSTOM_DATA_RECENTLY_WATCHED';
export const WIPE_STORED_CUSTOM_DATA = 'WIPE_STORED_CUSTOM_DATA';
export const OVERRIDE_STORED_CUSTOM_DATA = 'OVERRIDE_STORED_CUSTOM_DATA';

// override "actions" object
// implementation for guest is optional
// can be overriden only the necessary ones for guest use
/**
 * NOTE: new guest specific actions can be implemented
 * without a counterpar of identityUser actions.
 * identityUser actions will use the default action function
 * if missing a guest version.
 *
 * These actions will use 'app/guest' prefix
 */
export const actions = createActions(
  RESET_STATE,
  UPDATE_CUSTOM_DATA_BOOKMARKS,
  SAVE_CUSTOM_DATA_VIDEO_BOOKMARK,
  CLEAR_CUSTOM_DATA_VIDEO_BOOKMARK,
  SAVE_CUSTOM_DATA_RECENTLY_WATCHED,
  CLEAR_CUSTOM_DATA_RECENTLY_WATCHED,
  ADD_SINGLE_CUSTOM_DATA_RECENTLY_WATCHED,
  WIPE_STORED_CUSTOM_DATA,
  OVERRIDE_STORED_CUSTOM_DATA,
  actionOptions,
);

export const {
  saveCustomDataVideoBookmark,
  clearCustomDataVideoBookmark,
} = actions;

// override high level actions
// implementation off all actions is optional

// logout here will work different and it can call
// actions from identityUser
export const logout = redirectPath => async dispatch => {
  await dispatch(userActions.setLogoutRedirectPath(redirectPath));
  await dispatch(actions.resetState());
  await dispatch(identityUserLogout());
  await dispatch(userActions.disableGuestMode());
};

export const loadProfileCustomData = (profileId, successAction) => (
  dispatch,
  getState,
) => {
  const { age, ...rest } = getCurrentProfile(getState());
  const stored = getStoredCustomData(getState(), age);
  // Update profile action won't work unless birthdate is present
  const month = ((new Date().getMonth() + 2) % 12) + 1;
  const birthdate = aggregateBirthdate(age, month);
  dispatch(successAction({ ...rest, ...stored, age, birthdate }));
};

export const clearCustomDataRecentlyWatched = () => dispatch => {
  dispatch(brightcoveActions.clearRecentlyWatchedAssetsByReference());
  return {
    type: `${actionOptions.prefix}/${CLEAR_CUSTOM_DATA_RECENTLY_WATCHED}`,
  };
};

export const loadCustomDataRecentlyWatched = (
  containerId = null,
  // queryData = {},
  // mergeData = false,
) => async (dispatch, getState) => {
  const { age } = getCurrentProfile(getState());
  const list = getRecentlyWatchedByAge(getState(), age);

  if (list?.length) {
    dispatch(actions.saveCustomDataRecentlyWatched(list));
    dispatch(
      loadRecentlyWatchedAssetsByReferenceIds(
        containerId,
        [
          {
            referenceIds: list.map(i => i.videoId),
            limit: list.length,
          },
        ],
        false,
      ),
    );
  } else {
    dispatch(actions.saveCustomDataRecentlyWatched([]));
    dispatch(
      brightcoveActions.saveRecentlyWatchedAssetsByReference({
        videos: [],
      }),
    );
  }

  dispatch(stopLoadingRecentlyWatched());
};

export const updateProfileRecentlyWatched = videoInfo => async (
  dispatch,
  getState,
) => {
  if (!isValidAge(getState)) {
    return;
  }
  const profileId = getCurrentProfileId(getState());
  const parentUserId = getParentUserId(getState());
  if (profileId === parentUserId) {
    return;
  }
  const { reference_id: videoId } = videoInfo;

  if (!videoId) {
    // Cannot add to recently watched without reference_id
    console.warn('[Identity] No reference_id. Ignoring on recently watched.');
    return;
  }

  const { age } = getCurrentProfile(getState());
  const limit = getRecentlyWatchedLimit(getState());
  const recentlyWatchedList = getRecentlyWatchedByAge(getState(), age);
  const recentlyWatchedData = getRecentlyWatchedAssetsByReferenceIds(
    getState(),
  );

  if (recentlyWatchedList == null || recentlyWatchedData == null) {
    // Not loaded yet, delay 2 seconds.
    setTimeout(() => {
      dispatch(updateProfileRecentlyWatched(videoInfo));
    }, 2 * 1000);
    return;
  }

  let filterFunction;
  const elementInList = recentlyWatchedList.find(i => i.videoId === videoId);
  const lastUpdate = Date.now();
  const newEntry = { videoId, lastUpdate };

  if (elementInList) {
    filterFunction = updateRecentlyWatchedList;
  } else {
    filterFunction = addToRecentlyWatchedList;
  }

  const [updatedList, updatedData] = filterFunction(
    newEntry,
    recentlyWatchedList,
    videoInfo,
    recentlyWatchedData,
  ).map(arr => arr.slice(0, limit));

  // local storage
  dispatch(
    actions.addSingleCustomDataRecentlyWatched({ age, list: updatedList }),
  );
  // Used for rendering
  dispatch(
    brightcoveActions.saveRecentlyWatchedAssetsByReference({
      videos: updatedData,
    }),
  );
};

export const loadCustomDataVideoBookmark = () => async (dispatch, getState) => {
  const { age } = getCurrentProfile(getState());
  const bookmarks = getBookmaksByAge(getState(), age);
  const { videoId, videoReferenceId } = getCurrentVideo(getState());

  const bookmark = bookmarks.find(b => b.videoId === videoReferenceId);

  if (bookmark) {
    dispatch(
      saveCustomDataVideoBookmark({
        ...bookmark,
        videoId: Number(videoId),
      }),
    );
  }
};

export const deleteCustomDataVideoBookmark = videoId => async (
  dispatch,
  getState,
) => {
  if (!isValidAge(getState)) {
    return;
  }
  const { age } = getCurrentProfile(getState());
  const bookmarks = getBookmaksByAge(getState(), age);
  const newBookmarks = bookmarks.filter(b => b.videoId !== videoId);

  dispatch(actions.updateCustomDataBookmarks({ age, bookmarks: newBookmarks }));
};

export const updateProfileBookmark = progress => async (dispatch, getState) => {
  if (!isValidAge(getState)) {
    return;
  }
  const { age } = getCurrentProfile(getState());
  const bookmarks = getBookmaksByAge(getState(), age);
  const { videoReferenceId } = getCurrentVideo(getState());
  const filtered = bookmarks.filter(b => b.videoId !== videoReferenceId);
  const limit = getBookmarksLimit(getState());
  const newBookmark = {
    videoId: videoReferenceId,
    progress: Math.floor(progress * 1000),
    lastUpdate: Date.now(),
  };

  filtered.push(newBookmark);
  const trimmed = filtered.slice(-limit);
  dispatch(actions.updateCustomDataBookmarks({ age, bookmarks: trimmed }));
};
