import React from 'react';
import PropTypes from 'prop-types';
import { renderRoutes } from 'react-router-config';

const AuthFlowContainer = ({ route }) => {
  return <>{renderRoutes(route.routes)}</>;
};

AuthFlowContainer.propTypes = {
  route: PropTypes.object,
};

export default AuthFlowContainer;
