/* eslint-disable no-script-url */

import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';
import RedirectWithQuery from '#/components/App/RedirectWithQuery';
import authStyles from '../authflow.scss';
import styles from './AccountCreation.scss';

import { authFlowRoutes } from '#/utils/routes';
import {
  vindiciaErrors,
  passwordHintText,
  accountCreationDesc,
  accountCreationTitle,
  accountCreationTitleMobile,
  accountCreationStep1Mobile,
  accountCreationStep2Mobile,
  dataSharePolicyTitleMobile,
} from '#/config/strings';
import { authScreenNames, signupStates } from '#/config/constants';

import {
  getOnboardingSuccess,
  getError,
} from '#/redux/modules/identity/selectors';
import { getAccount } from '#/redux/modules/onboarding/selectors';
import {
  getRequireEmailVerification,
  getGreenLogoUri,
} from '#/redux/modules/accedoOne/selectors';

import { actions } from '#/redux/modules/onboarding/actions';
import { loadTermsOfService } from '#/redux/modules/accedoOne/actions';
import {
  login,
  CREATE_ACCOUNT,
  createAccount,
} from '#/redux/modules/identity/actions';
import { actions as modalActions } from '#/redux/modules/modal/actions';
import {
  sendScreenView,
  sendRegistrationComplete,
} from '#/redux/modules/firestore/actions';

import ErrorDispatcher from '#/components/ErrorDispatcher/ErrorDispatcher';
import LoginRedirect from '#/components/AuthFlow/LoginRedirect/LoginRedirect';
import { Button, StepIndicator } from '#/widgets/ui';
import { InfoForm, InfoInput, MobilePinInput } from '#/widgets/forms';
import GreenBackground from '#/static/images/background-images/Green.svg';

import CancelButton from '../CancelButton';
import { isMobile } from '#/utils/componentStyleConfig';
import { toggleLoginSpinner } from '#/redux/modules/spinner/actions';

const {
  fields: { default: defaultFieldError },
} = vindiciaErrors;

const AccountCreation = () => {
  const dispatch = useDispatch();
  const requireEmailVerification = useSelector(getRequireEmailVerification);
  const greenLogoUri = useSelector(getGreenLogoUri) || '';

  // Profile form and account info
  const [isProfileValid, setIsProfileValid] = useState(false);
  const [{ pin, credential }, setSecrets] = useState({
    pin: '',
    credential: '',
  });
  const account = useSelector(getAccount);

  // Onboarding state and errors
  const errors = useSelector(getError(CREATE_ACCOUNT));
  const { fieldErrors } = errors || {};
  const userCreated = useSelector(getOnboardingSuccess);

  // Mobile pin number
  const [mobilePin, setMobilePin] = useState(null);

  const handleMobilePinInputOnFocus = useCallback(() => {
    dispatch(
      modalActions.openModal({
        modalId: 'PinModal',
        modalData: {
          isNewAccountMobile: true,
        },
        successAction: async parentPin => {
          setMobilePin(parentPin);
        },
      }),
    );
  }, []);

  useEffect(() => {
    dispatch(loadTermsOfService);
    dispatch(sendScreenView(authScreenNames.PARENT_ACCOUNT_CREATION));
  }, []);

  useEffect(() => {
    if (pin) {
      dispatch(sendScreenView(authScreenNames.PIN_MODAL));
    }
  }, [pin]);

  const tocLabel = (
    <span>
      I have read and agree to the{' '}
      <a
        href="javascript:void(0);"
        onClick={() =>
          dispatch(modalActions.openModal({ modalId: 'TocModal' }))
        }
      >
        Terms & Conditions
      </a>{' '}
      and{' '}
      <a
        href="javascript:void(0);"
        onClick={() =>
          dispatch(modalActions.openModal({ modalId: 'PrivacyAgreementModal' }))
        }
      >
        Privacy Policy
      </a>
    </span>
  );
  const markteingLabel = (
    <span>Include information from Sensical sponsors and partner</span>
  );

  const createAccountCallback = useCallback(async () => {
    try {
      dispatch(toggleLoginSpinner(true));
      await dispatch(
        createAccount(account.email, credential, pin, account.marketingOptin),
      );
      await dispatch(
        actions.setSignupState({ signupState: signupStates.GUEST_WELCOME }),
      );
      if (!requireEmailVerification) {
        dispatch(sendRegistrationComplete());
        /* eslint-disable no-undef */
        dataLayer.push({ event: 'registrationComplete' });
        /* eslint-enable no-undef */
        await dispatch(login(account));
      }
    } finally {
      dispatch(toggleLoginSpinner(false));
    }
  }, [account, credential, pin, requireEmailVerification]);

  return (
    <div
      className={cx(
        authStyles.authFlow,
        authStyles.onboardFlow,
        styles.accountCreation,
      )}
      style={{ backgroundImage: `url(${GreenBackground})` }}
    >
      <ErrorDispatcher errorId={CREATE_ACCOUNT} />
      <CancelButton />
      {userCreated && requireEmailVerification && (
        <RedirectWithQuery to={authFlowRoutes.EMAIL_VERIFICATION} />
      )}
      {userCreated &&
        !requireEmailVerification &&
        (isMobile() ? (
          <RedirectWithQuery to={authFlowRoutes.PROFILE_CREATION} />
        ) : (
          <LoginRedirect />
        ))}
      {isMobile() && (
        <img className={styles.logo} src={greenLogoUri} alt="Logo" />
      )}
      {!isMobile() ? (
        <StepIndicator step="3" className={styles.stepIndicator} />
      ) : (
        <div className={styles.mobileStepIndicatorContainer}>
          <div className={styles.stepContainter}>
            <div className={styles.greenCircle}>1</div>
            <div>{accountCreationStep1Mobile}</div>
          </div>
          <div className={styles.horzitonalLine} />
          <div className={styles.stepContainter}>
            <div className={styles.whiteCircle}>2</div>
            <div>{accountCreationStep2Mobile}</div>
          </div>
        </div>
      )}
      <div className={authStyles.pageDescription}>
        <h1>
          {isMobile() ? accountCreationTitleMobile : accountCreationTitle}
        </h1>
        <p>{!isMobile() && accountCreationDesc}</p>
      </div>
      <div className={cx(authStyles.content, styles.formContainer)}>
        <InfoForm
          onChange={(data, isValid) => {
            setIsProfileValid(isValid);
            if (!isValid) {
              return;
            }
            dispatch(actions.createAccount(data));
            setSecrets({ pin: data.pin, credential: data.credential });
          }}
        >
          <InfoInput
            fieldName="email"
            fieldLabel="Your email address"
            placeHolder="Enter email address"
            fieldValue={account.email}
            inputAttributes={{ type: 'email', required: 'required' }}
            externalErrorMessage={fieldErrors?.email && defaultFieldError}
          />
          <div className={styles.dataSharePolicyContainer}>
            <span className={styles.dataSharePolicyText}>
              {dataSharePolicyTitleMobile}
            </span>{' '}
            <a
              className={styles.dataSharePolicyLink}
              href="javascript:void(0);"
              onClick={() =>
                dispatch(
                  modalActions.openModal({ modalId: 'DataSharePolicyModal' }),
                )
              }
            >
              Learn more
            </a>
          </div>
          <InfoInput
            fieldName="credential"
            fieldLabel="Create password"
            placeHolder="Minimum 8 characters"
            inputAttributes={{
              type: 'password',
              pattern: '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$',
              required: 'required',
            }}
            externalErrorMessage={fieldErrors?.credential && defaultFieldError}
          />
          <p className={styles.passwordHint}>{passwordHintText}</p>
          {!isMobile() ? (
            <InfoInput
              fieldName="pin"
              fieldLabel="Parent PIN"
              placeHolder="Set PIN"
              tooltip="A PIN allows you to access your account, set time limits & access usage reports"
              className={styles.pin}
              inputAttributes={{
                type: 'password',
                required: 'required',
                minLength: '4',
                maxLength: '4',
                pattern: '[0-9]+',
              }}
              externalErrorMessage={fieldErrors?.pin && defaultFieldError}
            />
          ) : (
            <MobilePinInput
              fieldName="pin"
              fieldLabel="Parent PIN"
              placeHolder="Set PIN"
              tooltip="A PIN allows you to access your account, set time limits & access usage reports"
              className={styles.pin}
              onFocusHandler={handleMobilePinInputOnFocus}
              fieldValue={mobilePin}
              inputAttributes={{
                type: 'password',
                required: 'required',
                minLength: '4',
                maxLength: '4',
                pattern: '[0-9]+',
              }}
              externalErrorMessage={fieldErrors?.pin && defaultFieldError}
            />
          )}
          <InfoInput
            fieldName="toc"
            fieldLabel={tocLabel}
            fieldValue="true"
            inputAttributes={{ type: 'checkbox', required: 'required' }}
          />
          <InfoInput
            fieldName="marketingOptin"
            fieldLabel={markteingLabel}
            fieldValue="true"
            inputAttributes={{
              type: 'checkbox',
              defaultChecked: account.marketingOptin,
            }}
          />
        </InfoForm>
      </div>
      <Button
        onClick={createAccountCallback}
        selected={isProfileValid}
        disabled={!isProfileValid}
        className={styles.createAccountButton}
      >
        {!isMobile() ? 'Create account' : 'Sign up'}
      </Button>
    </div>
  );
};

export default AccountCreation;
