let rootSelector = state => state.keys;
const isPreventDefaultBackSelector = state => rootSelector(state).preventBack;

// Required to set the root selector externally, since the module isn't aware of the
// global state shape.
export const setRootSelector = selector => {
  rootSelector = selector;
};

export const getMenuState = state => rootSelector(state);
export const getIsBackPrevented = state => isPreventDefaultBackSelector(state);
