import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import ErrorDispatcher from '../../ErrorDispatcher/ErrorDispatcher';
import { Button } from '#/widgets/ui';
import { InfoForm, InfoInput } from '#/widgets/forms';

import {
  getParentAccount,
  getParentUserId,
} from '#/redux/modules/identity/selectors';
import {
  actions as identityActions,
  UPDATE_ACCOUNT,
  updateAccount,
  ASYNC_DELETE_ACCOUNT,
  deleteAccount,
  logout,
} from '#/redux/modules/identity/actions';
import { sendScreenView } from '#/redux/modules/firestore/actions';
import { actions as modalActions } from '#/redux/modules/modal/actions';

import nameIcon from '#/static/images/icons/accountinfo-person.svg';
import emailIcon from '#/static/images/icons/accountinfo-mail.svg';
import passwordIcon from '#/static/images/icons/accountinfo-shield.svg';
import pinIcon from '#/static/images/icons/accountinfo-category.svg';
import emailOptInIcon from '#/static/images/icons/accountinfo-inbox.svg';
// import connectedDevicesIcon from '#/static/images/icons/accountinfo-tv.svg';

import { modalDialogs } from '#/config/strings';
import { parentZoneProfileScreenNames } from '#/config/constants';
import styles from './AccountInfo.scss';

const AccountInfoViewItem = ({ icon, label, value }) => {
  return (
    <div>
      <img src={icon} alt="label" />
      <div>
        <p className={styles.label}>{label}</p>
        <p className={styles.value}>{value}</p>
      </div>
    </div>
  );
};
AccountInfoViewItem.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
};

const AccountInfoView = ({ parentAccount, onEdit, onDelete }) => {
  const {
    displayName,
    email,
    // NOTE: Disabled on CSN request but keeping functionality
    // mobileNumber
    marketingInfo,
    learningReports,
    // connectedDevices: {
    //   Roku = {},
    //   AndroidMobile = {},
    //   iPhoneMobile = {},
    //   AppleTV = {},
    // } = {},
  } = parentAccount;

  return (
    <div className={styles.accountInfoView}>
      <div className={styles.accountInfoDisplayContainer}>
        <AccountInfoViewItem
          icon={nameIcon}
          label="Name"
          value={displayName || 'None'}
        />
        <AccountInfoViewItem
          icon={emailIcon}
          label="Email address"
          value={email}
        />
        <AccountInfoViewItem
          icon={passwordIcon}
          label="Password"
          value="••••••••••••••••••••"
        />
        <AccountInfoViewItem
          icon={pinIcon}
          label="Parental PIN code"
          value="****"
        />
        <AccountInfoViewItem
          icon={emailOptInIcon}
          label="Email opt in"
          value={[
            learningReports && 'Learning report',
            marketingInfo && 'Marketing info',
            !learningReports && !marketingInfo && 'None',
          ]
            .filter(v => !!v)
            .join(', ')}
        />
        {/* NOTE: Disabled on CSN request but keeping functionality
        <p className={styles.label}>Mobile phone number</p>
        <p className={styles.value}>{mobileNumber}</p>
        */}
        {/* NOTE: temp disabling as Vindica sorts out backend SENSICAL-1898 
        <p className={styles.label}>Connected Devices</p>
        <p className={styles.value}>
          {Object.values(Roku)
            .concat(Object.values(AndroidMobile))
            .concat(Object.values(iPhoneMobile))
            .concat(Object.values(AppleTV))
            .join(', ')}
        </p> */}
      </div>
      <div className={styles.buttonContainer}>
        <Button onClick={onEdit} className={styles.editButton}>
          Edit
        </Button>
        <Button
          onClick={onDelete}
          selected={false}
          className={styles.deleteButton}
        >
          Delete account
        </Button>
      </div>
    </div>
  );
};
AccountInfoView.propTypes = {
  parentAccount: PropTypes.object,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

const AccountInfoEdit = ({ parentAccount, onSave, onCancel }) => {
  const [accountInfo, setAccountInfo] = useState(parentAccount);
  const [valid, setValid] = useState(false);
  const {
    displayName,
    // NOTE: Disabled on CSN request but keeping functionality
    // mobileNumber
    email,
    newPassword,
    marketingInfo,
    learningReports,
  } = accountInfo;

  return (
    <div className={styles.accountInfoEdit}>
      <ErrorDispatcher errorId={UPDATE_ACCOUNT} />
      <InfoForm
        onChange={(data, isValid) => {
          setValid(isValid);
          if (isValid) {
            setAccountInfo({
              learningReports: false,
              marketingInfo: false,
              ...data,
            });
          }
        }}
      >
        <InfoInput
          fieldName="displayName"
          fieldLabel="Name"
          fieldValue={displayName}
          inputAttributes={{
            type: 'text',
            pattern: '[a-zA-Z]+',
          }}
        />
        <InfoInput
          fieldName="email"
          fieldLabel="Email address"
          fieldValue={email}
          inputAttributes={{
            type: 'email',
            required: 'required',
            disabled: true,
          }}
        />
        <InfoInput
          fieldName="oldPassword"
          fieldLabel="Existing password"
          inputAttributes={{
            type: 'password',
            required: !!newPassword,
          }}
        />
        <InfoInput
          fieldName="newPassword"
          fieldLabel="New password"
          inputAttributes={{
            type: 'password',
            pattern: '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$',
          }}
        />
        {/* NOTE: Disabled on CSN request but keeping functionality
        <InfoInput
          fieldName="mobileNumber"
          fieldLabel="Mobile phone number"
          fieldValue={mobileNumber}
          inputAttributes={{
            type: 'tel',
            minLength: '6',
            maxLength: '13',
            pattern: '[0-9]+',
          }}
        />
        */}
        <InfoInput
          fieldName="pin"
          fieldLabel="PIN code"
          inputAttributes={{
            type: 'password',
            minLength: '4',
            maxLength: '4',
            pattern: '[0-9]+',
          }}
        />
        <div className={styles.emailPreferencesContainer}>
          <div className={styles.emailPreferences}>
            <InfoInput
              fieldName="learningReports"
              fieldLabel="Learning report"
              fieldValue="true"
              inputAttributes={{
                type: 'checkbox',
                defaultChecked: learningReports,
              }}
              className={styles.emailToggle}
            />
            <InfoInput
              fieldName="marketingInfo"
              fieldLabel="Marketing Info"
              fieldValue="true"
              inputAttributes={{
                type: 'checkbox',
                defaultChecked: marketingInfo,
              }}
              className={styles.emailToggle}
            />
          </div>
        </div>
      </InfoForm>
      <div className={styles.saveButtonGroup}>
        <Button
          onClick={() => onSave({ ...parentAccount, ...accountInfo })}
          disabled={!valid}
          selected={valid}
        >
          Save
        </Button>
        <Button onClick={onCancel}>Cancel</Button>
      </div>
    </div>
  );
};
AccountInfoEdit.propTypes = {
  parentAccount: PropTypes.object,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

export const AccountInfo = () => {
  const dispatch = useDispatch();
  const parentAccount = useSelector(getParentAccount);
  const parentUserId = useSelector(getParentUserId);
  const [editMode, setEditMode] = useState(false);

  const onSave = useCallback(async account => {
    const { marketingInfo, learningReports } = account;
    await dispatch(updateAccount(account));
    dispatch(identityActions.setParentAccountInfo(account));
    dispatch(
      identityActions.setParentCustomData({ marketingInfo, learningReports }),
    );
    setEditMode(false);
  }, []);

  const onDelete = useCallback(async () => {
    const removeAccountCallback = async () => {
      await dispatch(deleteAccount(parentUserId));
      await dispatch(logout());
    };

    dispatch(sendScreenView(parentZoneProfileScreenNames.MY_ACCOUNT_DELETE));
    dispatch(
      modalActions.openModal({
        modalId: 'ConfirmModal',
        successAction: removeAccountCallback,
        modalData: modalDialogs.confirmDeleteAccount,
      }),
    );
  }, [parentUserId]);

  return (
    <div className={styles.accountPage}>
      <ErrorDispatcher errorId={ASYNC_DELETE_ACCOUNT} />
      {editMode ? (
        <AccountInfoEdit
          {...{ parentAccount, onSave }}
          onCancel={() => setEditMode(false)}
        />
      ) : (
        <AccountInfoView
          {...{ parentAccount, onDelete }}
          onEdit={() => setEditMode(true)}
        />
      )}
    </div>
  );
};

export default AccountInfo;
