import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { Page } from '@accedo/vdkweb-tv-ui';
import {
  getFeatureSteps,
  getSensicalTvConfig,
  getMobileRedirectInfo,
} from '#/redux/modules/accedoOne/selectors';
import { sendScreenView } from '#/redux/modules/firestore/actions';
import { authFlowRoutes } from '#/utils/routes';
import { authScreenNames } from '#/config/constants';

import { WELCOME } from '#/utils/navigationHelper';
import { FocusButton, FocusDiv } from '#/widgets/ui';
import { welcomeTitleWeb, welcomeTitleTv } from '#/config/strings';
import styles from './Welcome.scss';
import authStyles from '../authflow.scss';
import BgFeatureTour from '#/static/images/background-images/BgFeatureTour.png';
import { SignInButton } from '../SignInButton';
import { isWorkstation, isMobile } from '#/utils/componentStyleConfig';
import FlyingGroup from '#/static/images/background-images/FlyingGroup.png';
import GreenFrog from '#/static/images/background-images/GreenFrog.png';
import LaughingGroup from '#/static/images/background-images/LaughingGroup.png';
import MayatheBee from '#/static/images/background-images/MayatheBee.png';
import mobileS from '#/static/images/background-images/mobileS.png';
import MobileHalfCircle from '#/static/images/background-images/MobileHalfCircle.png';

const { WELCOME: WELCOME_MESSAGE, SIGN_IN, SIGN_UP } = authScreenNames;

const TourCard = ({ displayText, subtitle, image: [{ fileUrl: url }] }) => {
  const subtitles = subtitle.split('\n\n');
  return (
    <div className={styles.tourCard}>
      <img src={url} alt="Step" />
      <div className={styles.caption}>
        <h3 className={styles.title}>{displayText}</h3>
        {subtitles.map((sub, i) => (
          <p key={i} className={styles.subtitle}>
            {sub}
          </p>
        ))}
      </div>
    </div>
  );
};
TourCard.propTypes = {
  displayText: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.array,
};

const FeatureSteps = () => {
  const featureSteps = useSelector(getFeatureSteps);
  return featureSteps.map((step, i) => <TourCard key={i} {...step} />);
};

const Welcome = ({ history }) => {
  const dispatch = useDispatch();
  const mobileRedirectInfo = useSelector(getMobileRedirectInfo);
  const { description, subtitle, title } = mobileRedirectInfo || '';
  const { logoUri = '' } =
    useSelector(state => getSensicalTvConfig(state)) || {};

  useEffect(() => {
    dispatch(sendScreenView(WELCOME_MESSAGE));
  }, []);

  const webComponent = (
    <div className={cx(authStyles.authFlow, styles.welcome)}>
      <div className={cx(authStyles.content, styles.content)}>
        <img
          className={styles.logoWebLayout}
          src={logoUri?.replace('Black', 'Green')}
          alt="Sensical"
        />
        <h1 className={authStyles.welcomeTitle}>{welcomeTitleWeb}</h1>
        <img
          className={styles.logoWebLayoutShort}
          src={logoUri?.replace('Green', 'White').replace('Black', 'White')}
          alt="Sensical"
        />
        <div className={styles.welcomeTitle}>
          <h1>{title}</h1>
          <h1>{subtitle}</h1>
        </div>
        <div className={styles.tourCardContainer}>
          <FeatureSteps />
          <img
            src={mobileS}
            className={styles.backgroundS}
            alt="S Background"
          />
          <img
            src={MobileHalfCircle}
            className={styles.backgroundHalf}
            alt="Background Half Circle"
          />
          <img
            src={MayatheBee}
            className={styles.backgroundBee}
            alt="Background Half Circle"
          />
          <img
            src={LaughingGroup}
            className={styles.backgroundLaughing}
            alt="Background Half Circle"
          />
          <img
            src={GreenFrog}
            className={styles.backgroundFrog}
            alt="Background Half Circle"
          />
          <img
            src={FlyingGroup}
            className={styles.backgroundFlying}
            alt="Background Half Circle"
          />
        </div>
        <div className={styles.footerTitle}>
          <p>{description}</p>
        </div>
        <div className={cx(authStyles.buttonsGroup, styles.buttonsGroup)}>
          <SignInButton className={styles.signUpBtn} />
          {!isMobile() && (
            <div>
              Have an account?{'  '}
              <Link to={authFlowRoutes.SIGN_IN} className={styles.signIn}>
                {SIGN_IN}
              </Link>
            </div>
          )}
        </div>
      </div>
      <div className={styles.background} alt="Feature Background" />
    </div>
  );

  const tvComponent = (
    <Page
      className={cx(authStyles.authFlow, styles.welcome)}
      autofocus
      nav={{ id: WELCOME.PAGE, forwardFocus: WELCOME.CONTAINER }}
    >
      <FocusDiv
        className={cx(authStyles.content, styles.contentTv)}
        nav={{
          id: WELCOME.CONTAINER,
          forwardFocus: WELCOME.SIGNUP_BUTTON,
        }}
      >
        <img
          className={styles.logoTvLayout}
          src={logoUri?.replace('Black', 'Green')}
          alt="Sensical"
        />
        <h1 className={authStyles.welcomeTitleTv}>{welcomeTitleTv}</h1>
        <div className={styles.tourCardContainer}>
          <FeatureSteps />
        </div>
        <div className={authStyles.buttonsGroupTv}>
          <FocusButton
            nav={{
              id: WELCOME.SIGNUP_BUTTON,
              nextright: WELCOME.SIGNIN_BUTTON,
              parent: WELCOME.CONTAINER,
            }}
            isTv
            primary
            className={styles.onboardTvBtn}
            onClick={() => {
              history.push(authFlowRoutes.SIGN_UP);
            }}
            ariaLabel="Sign up"
          >
            {SIGN_UP}
          </FocusButton>
          <FocusButton
            className={styles.onboardTvBtn}
            secondary
            isTv
            nav={{
              id: WELCOME.SIGNIN_BUTTON,
              nextleft: WELCOME.SIGNUP_BUTTON,
              parent: WELCOME.CONTAINER,
            }}
            onClick={() => {
              history.push(authFlowRoutes.SIGN_IN);
            }}
            ariaLabel="Sign in"
          >
            {SIGN_IN}
          </FocusButton>
        </div>
      </FocusDiv>
      <img
        src={BgFeatureTour}
        className={styles.backgroundTv}
        alt="Feature Background"
      />
    </Page>
  );

  return isWorkstation() ? webComponent : tvComponent;
};

export default Welcome;
