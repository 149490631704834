import React from 'react';
import { useSelector } from 'react-redux';
import { getTermsOfService } from '#/redux/modules/accedoOne/selectors';
import { termsOfServiceTitle } from '#/config/strings';

import styles from './TocModal.scss';

const TocModal = () => {
  const content = useSelector(getTermsOfService);

  return (
    <>
      <h2>{termsOfServiceTitle}</h2>
      <p className={styles.textContent}>{content}</p>
    </>
  );
};
export default TocModal;
