import xdk from '@accedo/xdk-core';

let rootSelector = state => state.appPlayer;

export const setRootSelector = selector => {
  rootSelector = selector;
};

export const getPlayerState = state => rootSelector(state)?.playerState;

export const getVideoFullScreen = state => rootSelector(state)?.fullScreen;

export const getVideoCaptionEnabled = state =>
  rootSelector(state)?.videoCaptionEnabled;

export const getVideoCaptionStyles = state =>
  rootSelector(state)?.videoCaptionStyles;

export const hasVideoEnded = state => rootSelector(state)?.playerEnded;

export const getVideoContent = state => rootSelector(state)?.videoContent;

export const getCurrentVideo = state =>
  rootSelector(state)?.videoContent.currentVideo;

export const getBookmarkedVideo = state =>
  rootSelector(state)?.bookmarkedVideoContent?.currentVideo;

export const getBookmarkedVideoContent = state =>
  rootSelector(state)?.bookmarkedVideoContent;

export const getFullscreenBookmarkedVideoContent = state =>
  rootSelector(state)?.fullscreenBookmarkedVideoContent;

export const getPlaylistIds = state =>
  rootSelector(state)?.videoContent.playlists;

export const getPlayerVideoIndex = state =>
  rootSelector(state)?.videoContent.currentVideoIndex;

export const getPlayerContainerIndex = state =>
  rootSelector(state)?.videoContent.currentPlaylistIndex;

export const getVideoPlayTime = state =>
  rootSelector(state)?.analytics?.playTime;

export const getEpgProgramList = state => rootSelector(state)?.epgData;

export const getPrerollAdDuration = state =>
  rootSelector(state)?.adDetails?.preroll?.duration;

export const getPrerollAdByTime = (state, playTimeMs) =>
  rootSelector(state)?.adDetails?.preroll?.ads.reduce(
    (previousAdsDuration, currentAdDuration, i, ads) => {
      const cumulativeDuration = previousAdsDuration + currentAdDuration;

      // Already found current ad
      if (typeof previousAdsDuration === 'object') {
        return previousAdsDuration;
      }

      // Find first ad which has not been fully played, if all played, default to last one
      if (playTimeMs > cumulativeDuration && i < ads.length - 1) {
        return cumulativeDuration;
      }

      return {
        adSequence: i + 1,
        adEndTime: cumulativeDuration,
      };
    },
    0,
  );

export const getAdsCount = state =>
  rootSelector(state)?.adDetails?.preroll?.ads?.length;

export const getAdDetailsLoaded = state => rootSelector(state)?.adDetailsLoaded;

export const getAdPlayed = state => rootSelector(state)?.adPlayed;

export const getNormalizedVideoPosition = state => {
  const prerollAdDuration = getPrerollAdDuration(state);
  const normalizedPosition =
    xdk.media?.getCurrentTime() - prerollAdDuration / 1000;
  return normalizedPosition < 0 ? 0 : normalizedPosition;
};

export const getVideoAnalytics = state => rootSelector(state)?.analytics;

export const getPlaybackTimer = state => rootSelector(state)?.playbackTimer;

export const getBackgroundPlayerFullscreen = state =>
  rootSelector(state)?.backgroundPlayerFullscreen;
