export const signInPageTitle = "Welcome back, Parents. We're glad you're here.";
export const signInPageTitleMobile = "Hi, We're glad you're here";
export const profileSelectionTitle = "Hi, Who's Watching?";
export const parentZoneTitle = 'ParentZone';
export const accountCreationTitle = "Now, Let's Create Your Parent Account.";
export const accountCreationTitleMobile = 'Join For Free!';
export const accountCreationDesc =
  'This lets you sign in to watch videos and access the Sensical ParentZone on any device.';
export const passwordHintText =
  'It must contain at least one uppercase letter, one lowercase letter, and one number';
export const tokenConfirmationTitle = 'Your registration is complete';
export const tokenConfirmationDesc =
  'Great news. Your Sensical account is good to go. Starting right now, you’re free to explore thousands of videos across tons of safe, age-appropriate channels built around the topics kids love.';
export const profileEditTitle = "Awesome, Let's Set Up A Profile.";
export const profileEditDesc = `This helps us pick the best content for your child to watch. You can
add more profiles later.`;
export const profileEditTitleMobile = 'Create Your First Kids Profile.';
export const profileEditExtraKidMobile = 'Create Your Next Kid Profile';
export const accountCreationStep1Mobile = 'Sign up';
export const accountCreationStep2Mobile = 'Add kids';
export const dataSharePolicyTitleMobile =
  'We will never sell or share your data.';
export const dataSharePolicyDescMobile =
  'Sensical maintains the highest standard for your privacy and for the privacy of your kids. Please refer to our Privacy Policy for more information and to learn more about our commitment.';

export const signIn = {
  title: 'Sign in',
  subtitle:
    'Sign in with your Sensical account, phone or through your computer',
  activate: 'Scan the QR code or head to sensical.tv/activatesignedin',
  enterCode:
    'Then, enter this code and follow the instructions on your device:',
  continue: 'Continue',
  getNewCode: 'Get new code',
};

export const signUp = {
  title: 'Sign up with your phone or computer.',
  activate: 'Scan the QR code or head to sensical.tv/activate',
  enterCode:
    'Then, enter this code and follow the instructions on your device:',
};

export const vindiciaErrors = {
  fields: {
    default: 'This field is not valid',
  },
  operation: {
    alreadyExistEmail: 'An account with this email already exists.',
    tvCodeExpired: 'The code you entered is expired or invalid.',
    userCodeAlreadyApproved: 'The code has already been approved.',
    authenticationRequired:
      'The email address or password you entered is incorrect.',
    invalidPin: 'The pin you entered is invalid.',
    wrongPasswordError: 'Incorrect password entered',
  },
  emailValidationError: 'Could not validate email address',
  passwordResetError: 'Could not reset password',
  editPhoneError: 'Could not edit phone number',
  accountUpdateError: 'Could not update account details',
  profileUpdateError: 'Could not update profile',
  unknownError: 'Hmm... Something went wrong. Try again soon.',
  incorrectPasswordError: 'Wrong credentials',
};

export const generalErrors = {
  cantRemovecurrentProfile: 'Cannot remove currently selected profile',
};

export const modalDialogs = {
  confirmDeleteKidProfile: {
    title: 'Delete Profile?',
    message:
      'Any info about what your child has watched or added to their favorites will be deleted. You can’t undo this.',
  },
  confirmDeleteAccount: {
    title: 'Delete account?',
    message:
      "Your Sensical account will be removed from every device it's been added to. Also, all info about you or your kids' profiles will be deleted, including what your kids have watched.\n\nYou cant't undo this.",
  },
};
export const welcomeTitleBase = 'Welcome to Sensical';
export const welcomeTitleTv = `${welcomeTitleBase}... Let's start watching`;
export const welcomeTitleWeb =
  'Register Free for Safe, Educational and Fun Videos';
export const onboardingStepOneTitle = 'Hey Parents!';
export const onboardingStepOneDesc =
  'First, please confirm you’re an adult. Enter your birth year. We won’t store this information.';
export const onboardingStepOneDescMobile =
  'First, please confirm you’re an adult. We won’t store this information.';
export const termsOfServiceTitle = 'Terms Of Service';
export const privacyPolicyTitle = 'Privacy Policy';
export const onboardingStepOnePrivacy =
  "Keeping your family's privacy is top priority for us. For more information, read our ";
export const enterBirthYearHelperText = 'Enter birth year';
export const likeWhatModalDialogs = {
  title:
    '{{name}} will find thousands of entertaining and educational videos suitable for kids aged {{range}} years old on Sensical.',
  body: {
    TWO_UP:
      'Kids at this age benefit from content that supports basic vocabulary, counting skills and lots of repetition. Rest easy knowing that every video your child watches was approved by a team of child development experts as developmentally appropriate.',
    FIVE_UP:
      'Kids at this age benefit from content that supports school-readiness, early reading, social-emotional skills and problem solving. Rest easy knowing that every video your child watches was approved by a team of child development experts as developmentally appropriate.',
    EIGHT_UP:
      'Kids at this age benefit from content that is thought-provoking, exposes them to diverse experiences, encourages them to think about other people’s lives, and complements what they may be learning in school. Rest easy knowing that every video your child watches was approved by a team of child development experts as developmentally appropriate.',
  },
  extra:
    'Now your child can EXPLORE their passions, LEARN something new, and ENJOY themselves along the way!',
};

export const myStuffTexts = {
  NO_FAVORITES_YET: 'No Favorites Yet',
  NO_FAVORITES_DESCRIPTION:
    "Click the star icon on any video, show or topic you like. Then, you'll see the things you love here.",
};

export const parentZone = {
  title:
    ' You can access the ParentZone by visiting sensical.tv or your Sensical mobile application.',
  subtitle:
    'The ParentZone let’s you see what your kids have been watching, set viewing time limits, access your account information, edit kids’ profiles and more.',
  termsOfService: 'Terms of service',
  privacyPolicy: 'Privacy policy',
  childrenPrivacyPolicy: "Children's privacy policy",
  help: 'Help',
  accountInfo: 'Account info',
  kidsProfile: 'Kids’ profiles',
  parentalControls: 'Parental controls',
  contentFiltering: 'Content Filtering',
  faq: 'FAQ',
  learningReports: 'Learning reports',
};

export const guestProfileTitle = 'Choose your kid’s age to watch for free';

export const guestFooterSignUpFree = 'Sign up for free';

export const tvGuestFooterDesc =
  'Sign up to access exclusive features like individual kid profiles, full parental controls, and customized activity reports.';
export const guestFooterDesc =
  'to access parental controls and other great features';
export const guestProfileScreenName = 'Guest Age Picker';

export const MobileParentZoneTitle =
  'Access ParentZone through Sensical mobile app or on desktop';
export const MobileParentZoneDesc =
  'The ParentZone let’s you see what your kids have been watching, set viewing time limits, access your account information, edit kids’ profiles and more.';
export const updatedGuestFooterDesc =
  'Sign up for free to access parental controls and other great features';
