import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Page, FocusDiv } from '@accedo/vdkweb-tv-ui';

import classNames from 'classnames';
import { focusManager } from '@accedo/vdkweb-navigation';
import { mainRoutes } from '#/utils/routes';

// Components
import { TabView } from '#/components';
import { EXPLORE, HEADER } from '#/utils/navigationHelper';
import TopicCard from '#/components/TopicCard/TopicCard';
import ShowCard from '#/components/ShowCard/ShowCard';
import { FSCollectionSwimlaneFactory } from '#/components/SwimlaneFactory';
import {
  TV_CONTENT_HEADER,
  TVContentHeader,
} from '#/components/TVContentHeader/TVContentHeader';

// Selectors and constants
import { getCurrentAgeMetadata } from '#/redux/modules/accedoOne/selectors';
import { getCurrentProfileMinAge } from '#/redux/modules/onboarding/selectors';
import {
  getShows,
  getTopics,
  getTopShows,
} from '#/redux/modules/firestore/selectors';
import { EXPLORE_SCREEN, mainScreenNames } from '#/config/constants';

import { toggleLoginSpinner } from '#/redux/modules/spinner/actions';

import styles from './Explore.scss';
import Scroll from '#/components/Scroll/Scroll';
import useUpdateScroll from '#/hooks/useUpdateScroll';
import { isWorkstation, isMobile } from '#/utils/componentStyleConfig';
import { getGridItemNav, getItemId } from '#/utils/gridNavUtils';

const {
  PAGE,
  CONTAINER,
  TOP_SHOWS_CONTAINER,
  TABS_CONTAINER,
  GRID_CONTAINER,
  TAB_ITEM_0,
} = EXPLORE;

const navMap = {
  PAGE: {
    id: PAGE,
    forwardFocus: CONTAINER,
  },
  CONTAINER: {
    id: CONTAINER,
    forwardFocus: GRID_CONTAINER,
    nextleft: HEADER.MENU_ITEMS.EXPLORE,
  },
  TOPSHOWS: {
    id: TOP_SHOWS_CONTAINER,
    nextdown: TABS_CONTAINER,
    parent: CONTAINER,
    useLastFocus: true,
    forwardFocus: `${TOP_SHOWS_CONTAINER}-item-0`,
  },
  TABS: {
    id: TABS_CONTAINER,
    nextdown: GRID_CONTAINER,
    parent: CONTAINER,
    useLastFocus: true,
    forwardFocus: TAB_ITEM_0,
  },
};

const TopicMapper = ({ item: topic, nav, tileRounded, onFocus }) => {
  const minAge = useSelector(getCurrentProfileMinAge);

  const getImagePath = useCallback(
    item => item?.images?.find(e => e.age === minAge)?.image_path || '',
    [minAge],
  );
  const getPlaylistId = useCallback(
    item => item?.playlists?.find(e => e.age === minAge)?.playlist_id || '0',
    [minAge],
  );

  return (
    <TopicCard
      nav={nav}
      imgUrl={getImagePath(topic)}
      playlistId={getPlaylistId(topic)}
      id={topic.id}
      title={topic.name}
      roundedCorners={tileRounded}
      className={styles.topicCard}
      onItemFocus={onFocus}
    />
  );
};
TopicMapper.propTypes = {
  item: PropTypes.object,
  nav: PropTypes.string,
  onFocus: PropTypes.func,
  tileRounded: PropTypes.bool,
};

const ShowMapper = ({ item, nav, tileRounded, onFocus }) => {
  const getShowPlaylistId = useCallback(show => show?.playlist_id || '0', []);
  return (
    <ShowCard
      nav={nav}
      id={item.id}
      name={item.name}
      imgUrl={item.key_art_horizontal_image_path}
      className={styles.showCard}
      exploreClassName={styles.exploreShowCard}
      roundedCorners={tileRounded}
      onItemFocus={onFocus}
      playlistId={getShowPlaylistId(item)}
      podcastShow={item.media_type?.toLowerCase() === 'podcast'}
    />
  );
};
ShowMapper.propTypes = {
  item: PropTypes.object,
  nav: PropTypes.object,
  onFocus: PropTypes.func,
  tileRounded: PropTypes.bool,
};

const Grid = ({
  items,
  ItemMapper,
  parentContainerNavId,
  ageTheme,
  onFocus,
}) => {
  // Focus
  const gridNavMap = {
    id: GRID_CONTAINER,
    parent: parentContainerNavId,
    forwardFocus: getItemId(0),
    nextup: TABS_CONTAINER,
  };
  return (
    <FocusDiv nav={gridNavMap} classsName={styles.contentContainer}>
      <div
        className={classNames(styles.gridCardContainer, {
          [styles.isTv]: !isWorkstation(),
        })}
      >
        {items.map((item, index) => (
          <ItemMapper
            item={item}
            key={getItemId(index)}
            nav={getGridItemNav(GRID_CONTAINER, index, 4, items)}
            tileRounded={ageTheme?.tileRounded}
            onFocus={onFocus}
          />
        ))}
      </div>
    </FocusDiv>
  );
};
Grid.propTypes = {
  ageTheme: PropTypes.shape({ tileRounded: PropTypes.bool }),
  items: PropTypes.array,
  ItemMapper: PropTypes.func,
  parentContainerNavId: PropTypes.string,
  onFocus: PropTypes.func,
};

const Explore = ({ history, location }) => {
  const dispatch = useDispatch();
  const isPathEqualtoTopics =
    location?.pathname.indexOf(EXPLORE_SCREEN.TOPICS) >= 0;
  const [tabId, setTabId] = useState(
    isPathEqualtoTopics ? EXPLORE_SCREEN.TOPICS : EXPLORE_SCREEN.SHOWS,
  );
  const selectedAgeMetadata = useSelector(getCurrentAgeMetadata);

  const topShowsContainer = {
    displayText: EXPLORE_SCREEN.TOPSHOWS,
  };
  const topShowsCollection = useSelector(getTopShows);

  const shows = useSelector(getShows);
  const topics = useSelector(getTopics);

  const [focusedElement, updateScroll] = useUpdateScroll(CONTAINER);
  const scrollContainer = useRef(null);

  useEffect(() => {
    const isTop = isPathEqualtoTopics;
    const isShows = !isTop;
    requestAnimationFrame(() => {
      dispatch(toggleLoginSpinner(false));
    });
    if (
      (isTop && tabId !== EXPLORE_SCREEN.TOPICS) ||
      (isShows && tabId !== EXPLORE_SCREEN.SHOWS)
    ) {
      setTabId(isTop ? EXPLORE_SCREEN.TOPICS : EXPLORE_SCREEN.SHOWS);
    }
  }, [location?.pathname, tabId]);

  const gridProps = {
    ageTheme: selectedAgeMetadata,
    parentContainerNavId: CONTAINER,
  };

  const topShowsView = useMemo(() => {
    if (!topShowsCollection.length) {
      return null;
    }
    return (
      <FSCollectionSwimlaneFactory
        collectionItems={topShowsCollection}
        ageTheme={selectedAgeMetadata}
        nav={navMap.TOPSHOWS}
        key="id-topshows-container"
        title={topShowsContainer.displayText}
        onFocus={updateScroll}
      />
    );
  }, [topShowsContainer, topShowsCollection, navMap, selectedAgeMetadata]);

  const onTabClickCB = useCallback(
    newId => {
      scrollContainer.current.scrollTo(0, 0);
      if (location.pathname.indexOf(newId) === -1) {
        history.push(`${mainRoutes.EXPLORE}/${newId}`);
      }
    },
    [tabId, location?.pathname, history],
  );

  useEffect(() => {
    if (!isWorkstation()) {
      const nextFocus = topShowsCollection?.length
        ? TOP_SHOWS_CONTAINER
        : GRID_CONTAINER;
      focusManager.changeFocus(nextFocus);
      navMap.TABS.nextup = topShowsCollection?.length
        ? TOP_SHOWS_CONTAINER
        : null;
    }
  }, [topShowsCollection]);

  return (
    <div
      ref={scrollContainer}
      className={classNames(styles.scrollContainer, {
        [styles.isTv]: !isWorkstation(),
      })}
    >
      <Page className={styles.exploreContainer} nav={navMap.PAGE}>
        <FocusDiv nav={navMap.CONTAINER}>
          <TVContentHeader
            visible={!isWorkstation()}
            title={mainScreenNames.explore}
          />
          <Scroll
            enabled={!isWorkstation()}
            focusedElement={focusedElement}
            focusScrollPush={180}
            headerHeightOverride={
              document.getElementById(TV_CONTENT_HEADER)?.offsetHeight
            }
          >
            <FocusDiv nav={navMap.TOPSHOWS}>
              <div className={styles.topShows}>{topShowsView}</div>
            </FocusDiv>
            <TabView
              nav={navMap.TABS}
              tabsSchema={EXPLORE_SCREEN.tabsSchema}
              initialTabId={EXPLORE_SCREEN.tabsSchema}
              currentTabId={tabId}
              onTabClick={onTabClickCB}
              onTabViewFocused={updateScroll}
              className={!isWorkstation() && styles.tabViewContainer}
            >
              <Grid
                {...gridProps}
                data-tab-id={EXPLORE_SCREEN.SHOWS}
                items={shows}
                ItemMapper={ShowMapper}
                onFocus={updateScroll}
              />
              <Grid
                {...gridProps}
                data-tab-id={EXPLORE_SCREEN.TOPICS}
                items={topics}
                ItemMapper={TopicMapper}
                onFocus={updateScroll}
              />
            </TabView>
            {isMobile() && <div className={styles.bottomSpacerMobile} />}
          </Scroll>
        </FocusDiv>
      </Page>
    </div>
  );
};
Explore.propTypes = {
  content: PropTypes.object,
  entries: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
};

export default Explore;
