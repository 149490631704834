export const getFAQService = url => {
  return fetch(url, {
    method: 'GET',
  })
    .then(res => {
      return res.json();
    })
    .then(data => {
      return data;
    })
    .catch(error => {
      return {
        error: 'Could not retrieve FAQ',
        e: error,
      };
    });
};

export const getHelpService = url => {
  return fetch(url, {
    method: 'GET',
  })
    .then(res => {
      return res.text();
    })
    .then(data => {
      return data;
    })
    .catch(error => {
      return {
        error: 'Could not retrieve Help Page',
        e: error,
      };
    });
};

export const getPrivacyPolicyService = url => {
  return fetch(url, {
    method: 'GET',
  })
    .then(res => {
      return res.text();
    })
    .then(data => {
      return data;
    })
    .catch(error => {
      return {
        error: 'Could not retrieve Privacy Policy',
        e: error,
      };
    });
};

export const getTextContent = async url => {
  const result = await fetch(url);
  return result.text();
};
