import React from 'react';
import PropTypes from 'prop-types';
import logoUri from '#/static/images/logos/SensicalCSM.svg';
import styles from './tvContentHeader.scss';

export const TV_CONTENT_HEADER = 'TV_CONTENT_HEADER';

export const TVContentHeader = ({ visible = false, title = '' }) => {
  return visible ? (
    <div id={TV_CONTENT_HEADER} className={styles.container}>
      <div className={styles.content}>
        <div className={styles.titleWrap}>
          <div className={styles.title}>{title}</div>
        </div>
        <div className={styles.logoWrap}>
          <img className={styles.logo} src={logoUri} alt="Logo" />
        </div>
      </div>
    </div>
  ) : null;
};
TVContentHeader.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
};
