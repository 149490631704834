const FULL_HD = 1920;

const getViewport = n => {
  const context = FULL_HD * 0.01;
  const vWidth = n / context || 0;

  return `${vWidth.toFixed(3)}vw`;
};

export default getViewport;
