import { handleActions } from 'redux-actions';
import { actions } from './actions';

const initialState = {
  sessionKey: null,
  appKey: null,
  deviceId: null,
  sessionIdTs: null,
  assets: null,
  entries: null,
  metadata: null,
  onboarding: null,
  benefits: null,
  faq: null,
  help: null,
  privacyPolicy: null,
  termsOfService: null,
  childrenPrivacyPolicy: null,
};

const setClientReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    sessionKey: payload.sessionKey,
    appKey: payload.appKey,
    deviceId: payload.deviceId,
    sessionIdTs: payload.sessionIdTs,
  };
};

const saveAllAssetsReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    assets: payload,
  };
};

const saveAllEntriesReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    entries: payload,
  };
};

const saveAllMetadataReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    metadata: payload,
  };
};

const saveSensicalBenefitsReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    benefits: payload,
  };
};

const loadSensicalFaqReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    faq: payload,
  };
};

const loadSensicalHelpReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    help: payload,
  };
};

const loadSensicalPrivacyPolicyReducer = (
  state = initialState,
  { payload },
) => {
  return {
    ...state,
    privacyPolicy: payload,
  };
};

const saveTermsOfServiceReducer = (
  state = initialState,
  { payload: termsOfService },
) => ({
  ...state,
  termsOfService,
});

const saveChildrenPrivacyPolicyReducer = (
  state = initialState,
  { payload: childrenPrivacyPolicy },
) => ({
  ...state,
  childrenPrivacyPolicy,
});

const reducer = handleActions(
  {
    [actions.setClient]: setClientReducer,
    [actions.saveAllAssets]: saveAllAssetsReducer,
    [actions.saveAllEntries]: saveAllEntriesReducer,
    [actions.saveAllMetadata]: saveAllMetadataReducer,
    [actions.saveSensicalBenefits]: saveSensicalBenefitsReducer,
    [actions.loadSensicalFaq]: loadSensicalFaqReducer,
    [actions.loadSensicalHelp]: loadSensicalHelpReducer,
    [actions.loadSensicalPrivacyPolicy]: loadSensicalPrivacyPolicyReducer,
    [actions.saveTermsOfService]: saveTermsOfServiceReducer,
    [actions.saveChildrenPrivacyPolicy]: saveChildrenPrivacyPolicyReducer,
  },
  initialState,
);

export default reducer;
