/* eslint-disable no-use-before-define */

import { createActions } from 'redux-actions';
import moment from 'moment-mini';
import firestoreApi from '#/services/firestore/api';

import {
  createFetchAction,
  actions as asyncActions,
} from '#/redux/modules/async/actions';
import { actions as onboardingActions } from '#/redux/modules/onboarding/actions';
import { actions as userActions } from '#/redux/modules/user/actions';
import { actions as modalActions } from '#/redux/modules/modal/actions';
import { userAutoloaded } from '#/redux/modules/lifecycle/actions';
import {
  getAllProfiles,
  getProfiles,
  getUnsavedProfiles,
  getCurrentProfileId,
  getTvCode,
  getCurrentProfileAgeGroup,
  getCurrentProfileMinAge,
} from '#/redux/modules/onboarding/selectors';

import {
  getApiIdentityEndpoints,
  getApiOidcEndpoints,
  getApiCustomFieldsEndpoints,
  getOidcClientId,
  getAgeGroupConfig,
  getRecentlyWatchedLimit,
} from '#/redux/modules/accedoOne/selectors';
import {
  getGroupId,
  getParentUserId,
  getParentAccount,
  getPin,
  getProcessId,
  getAccessToken,
  getRefreshToken,
  getSessionData,
  getCustomDataRecentlyWatched,
} from '#/redux/modules/identity/selectors';
import { getCurrentVideo } from '#/redux/modules/appPlayer/selectors';
import { isTouchDevice } from '#/utils/componentStyleConfig';

import {
  apiIds,
  customDataEndpoints,
  customDataFieldTypes,
  defaultParentalTimeLimit,
  ageGroupAnalyticsValues,
} from '#/config/constants';

import { isUserLogged, isGuestMode } from '#/redux/modules/user/selectors';
import { decrypt } from '#/utils/encryptionUtils';
import { getAgeRange } from '#/utils/getAgeRange';
import {
  updateRecentlyWatchedList,
  addToRecentlyWatchedList,
} from '#/utils/recentlyWatchedUtils';

import { vindiciaErrors } from '#/config/strings';
import { authFlowRoutes } from '#/utils/routes';
import {
  getRecentlyWatchedAssetsByReferenceIds,
  getAssetData,
} from '#/redux/modules/brightcove/selectors';
import {
  actions as brightcoveActions,
  loadRecentlyWatchedAssetsByReferenceIds,
  stopLoadingRecentlyWatched,
} from '#/redux/modules/brightcove/actions';
import { aggregateBirthdate } from '#/utils/aggregateBirthdate';

// Vindicia actions
export const IDENTITY_ACTION_PREFIX = 'app/vindicia';

const actionOptions = {
  prefix: IDENTITY_ACTION_PREFIX,
};

export const RESET_STATE = 'RESET_STATE';
export const SET_VERIFICATION_EMAIL = 'SET_VERIFICATION_EMAIL';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN';
export const SET_PKAT = 'SET_PKAT';
export const SET_GROUP_ID = 'SET_GROUP_ID';
export const SET_PARENT_USER_ID = 'SET_PARENT_USER_ID';
export const SET_PARENT_ACCOUNT_INFO = 'SET_PARENT_ACCOUNT_INFO';
export const SET_PARENT_CUSTOM_DATA = 'SET_PARENT_CUSTOM_DATA';
export const SET_ERROR = 'SET_ERROR';
export const DISCARD_ERROR = 'DISCARD_ERROR';
export const SET_PIN = 'SET_PIN';
export const SET_PARENT_MODE = 'SET_PARENT_MODE';
export const SET_ONBOARDING_SUCCESS = 'SET_ONBOARDING_SUCCESS';
export const SET_PROCESS_ID = 'SET_PROCESS_ID';
export const SAVE_CUSTOM_DATA_VIDEO_BOOKMARK =
  'SAVE_CUSTOM_DATA_VIDEO_BOOKMARK';
export const SAVE_CUSTOM_DATA_SHOWS_FAVORITES =
  'SAVE_CUSTOM_DATA_SHOWS_FAVORITES';
export const SAVE_CUSTOM_DATA_RECENTLY_WATCHED =
  'SAVE_CUSTOM_DATA_RECENTLY_WATCHED';
export const SAVE_CUSTOM_DATA_PODCASTS_FAVORITES =
  'SAVE_CUSTOM_DATA_PODCASTS_FAVORITES';
export const SAVE_CUSTOM_DATA_VIDEO_FAVORITES =
  'SAVE_CUSTOM_DATA_VIDEO_FAVORITES';
export const SAVE_CUSTOM_DATA_PODCASTS_EPISODES_FAVORITES =
  'SAVE_CUSTOM_DATA_PODCASTS_EPISODES_FAVORITES';
export const SAVE_CUSTOM_DATA_DISPLAYED_MODALS =
  'SAVE_CUSTOM_DATA_DISPLAYED_MODALS';
export const DELETE_CUSTOM_DATA_DISPLAYED_MODALS =
  'DELETE_CUSTOM_DATA_DISPLAYED_MODALS';
export const SAVE_CUSTOM_DATA_VIDEOS_BLOCKED =
  'SAVE_CUSTOM_DATA_VIDEOS_BLOCKED';
export const SAVE_CUSTOM_DATA_SHOWS_BLOCKED = 'SAVE_CUSTOM_DATA_SHOWS_BLOCKED';
export const SAVE_CUSTOM_DATA_TOPICS_FAVORITES =
  'SAVE_CUSTOM_DATA_TOPICS_FAVORITES';
export const ADD_SINGLE_CUSTOM_DATA_TOPICS_FAVORITE =
  'ADD_SINGLE_CUSTOM_DATA_TOPICS_FAVORITE';
export const DELETE_SINGLE_CUSTOM_DATA_TOPICS_FAVORITE =
  'DELETE_SINGLE_CUSTOM_DATA_TOPICS_FAVORITE';
export const ADD_SINGLE_CUSTOM_DATA_SHOWS_FAVORITE =
  'ADD_SINGLE_CUSTOM_DATA_SHOWS_FAVORITE';
export const DELETE_SINGLE_CUSTOM_DATA_SHOWS_FAVORITE =
  'DELETE_SINGLE_CUSTOM_DATA_SHOWS_FAVORITE';
export const ADD_SINGLE_CUSTOM_DATA_PODCASTS_FAVORITE =
  'ADD_SINGLE_CUSTOM_DATA_PODCASTS_FAVORITE';
export const DELETE_SINGLE_CUSTOM_DATA_PODCASTS_FAVORITE =
  'DELETE_SINGLE_CUSTOM_DATA_PODCASTS_FAVORITE';
export const ADD_SINGLE_CUSTOM_DATA_VIDEO_FAVORITE =
  'ADD_SINGLE_CUSTOM_DATA_VIDEO_FAVORITE';
export const DELETE_SINGLE_CUSTOM_DATA_VIDEO_FAVORITE =
  'DELETE_SINGLE_CUSTOM_DATA_VIDEO_FAVORITE';
export const ADD_SINGLE_CUSTOM_DATA_PODCASTS_EPISODES_FAVORITE =
  'ADD_SINGLE_CUSTOM_DATA_PODCASTS_EPISODES_FAVORITE';
export const DELETE_SINGLE_CUSTOM_DATA_PODCASTS_EPISODES_FAVORITE =
  'DELETE_SINGLE_CUSTOM_DATA_PODCASTS_EPISODES_FAVORITE';
export const CLEAR_CUSTOM_DATA_VIDEO_BOOKMARK =
  'CLEAR_CUSTOM_DATA_VIDEO_BOOKMARK';
export const SAVE_SESSION_DATA = 'SAVE_SESSION_DATA';
export const CLEAR_SESSION_DATA = 'CLEAR_SESSION_DATA';
export const CLEAR_CUSTOM_DATA_RECENTLY_WATCHED =
  'CLEAR_CUSTOM_DATA_RECENTLY_WATCHED';
export const DELETE_SINGLE_CUSTOM_DATA_VIDEO_BLOCKED =
  'DELETE_SINGLE_CUSTOM_DATA_VIDEO_BLOCKED';
export const ADD_SINGLE_CUSTOM_DATA_VIDEO_BLOCKED =
  'ADD_SINGLE_CUSTOM_DATA_VIDEO_BLOCKED';
export const DELETE_SINGLE_CUSTOM_DATA_SHOW_BLOCKED =
  'DELETE_SINGLE_CUSTOM_DATA_SHOW_BLOCKED';
export const ADD_SINGLE_CUSTOM_DATA_SHOW_BLOCKED =
  'ADD_SINGLE_CUSTOM_DATA_SHOW_BLOCKED';

export const actions = createActions(
  {
    SET_GROUP_ID: ([{ id }]) => id,
    SET_PARENT_ACCOUNT_INFO: ({
      displayName,
      email,
      mobileNumber,
      attributes: {
        emails: [{ email: emailAttribute }] = [{}],
        mobiles: [{ number: mobileNumberAttribute }] = [{}],
      } = { emails: [{}], mobiles: [{}] },
    }) => ({
      displayName,
      email: email || emailAttribute,
      mobileNumber: mobileNumber || mobileNumberAttribute,
    }),
    SET_ERROR: (actionType, { errorMsg: errors }) => ({ actionType, errors }),
  },
  RESET_STATE,
  SET_VERIFICATION_EMAIL,
  SET_ACCESS_TOKEN,
  SET_REFRESH_TOKEN,
  SET_PKAT,
  SET_PARENT_USER_ID,
  SET_PARENT_CUSTOM_DATA,
  SET_PIN,
  DISCARD_ERROR,
  SET_PARENT_MODE,
  SET_ONBOARDING_SUCCESS,
  SET_PROCESS_ID,
  SAVE_CUSTOM_DATA_VIDEO_BOOKMARK,
  SAVE_CUSTOM_DATA_SHOWS_FAVORITES,
  SAVE_CUSTOM_DATA_RECENTLY_WATCHED,
  SAVE_CUSTOM_DATA_PODCASTS_FAVORITES,
  SAVE_CUSTOM_DATA_VIDEO_FAVORITES,
  SAVE_CUSTOM_DATA_PODCASTS_EPISODES_FAVORITES,
  SAVE_CUSTOM_DATA_DISPLAYED_MODALS,
  DELETE_CUSTOM_DATA_DISPLAYED_MODALS,
  SAVE_CUSTOM_DATA_VIDEOS_BLOCKED,
  SAVE_CUSTOM_DATA_SHOWS_BLOCKED,
  SAVE_CUSTOM_DATA_TOPICS_FAVORITES,
  ADD_SINGLE_CUSTOM_DATA_TOPICS_FAVORITE,
  DELETE_SINGLE_CUSTOM_DATA_TOPICS_FAVORITE,
  ADD_SINGLE_CUSTOM_DATA_SHOWS_FAVORITE,
  ADD_SINGLE_CUSTOM_DATA_PODCASTS_FAVORITE,
  DELETE_SINGLE_CUSTOM_DATA_SHOWS_FAVORITE,
  DELETE_SINGLE_CUSTOM_DATA_PODCASTS_FAVORITE,
  ADD_SINGLE_CUSTOM_DATA_VIDEO_FAVORITE,
  ADD_SINGLE_CUSTOM_DATA_PODCASTS_EPISODES_FAVORITE,
  DELETE_SINGLE_CUSTOM_DATA_VIDEO_FAVORITE,
  DELETE_SINGLE_CUSTOM_DATA_PODCASTS_EPISODES_FAVORITE,
  CLEAR_CUSTOM_DATA_VIDEO_BOOKMARK,
  SAVE_SESSION_DATA,
  CLEAR_SESSION_DATA,
  CLEAR_CUSTOM_DATA_RECENTLY_WATCHED,
  ADD_SINGLE_CUSTOM_DATA_SHOW_BLOCKED,
  DELETE_SINGLE_CUSTOM_DATA_VIDEO_BLOCKED,
  ADD_SINGLE_CUSTOM_DATA_VIDEO_BLOCKED,
  DELETE_SINGLE_CUSTOM_DATA_SHOW_BLOCKED,
  actionOptions,
);

export const {
  saveCustomDataVideoBookmark,
  clearCustomDataVideoBookmark,
} = actions;

const {
  CREATE_CHILD_CUSTOM_DATA,
  READ_CHILD_CUSTOM_DATA,
  UPDATE_CHILD_CUSTOM_DATA,
  DELETE_CHILD_CUSTOM_DATA,
} = customDataEndpoints;

const customUserDataKeys = {
  watchHistory: [],
  liveHistory: [],
  recentlyWatched: [],
  recentlyWatched_v2: [],
  bookmarks: [],
  bookmarks_v2: [],
  topicsFavorites: [],
  showsFavorites: [],
  podcastsFavorites: [],
  videoFavorites: [],
  PodcastsEpisodesFavorites: [],
  timeWatched: {
    duration: 0,
    lastUpdate: Date.now(),
  },
  showsWatched: [],
  parentalZoneWeekly: {
    totalTimeWatched: 0,
    timeWatchedDifference: 0,
    benefits: [],
    topics: [],
  },
  parentalZoneMonthly: {
    totalTimeWatched: 0,
    timeWatchedDifference: 0,
    benefits: [],
    topics: [],
  },
  parentalTimeLimit: 0,
  displayedModals: [],
  videosBlocked: [],
  showsBlocked: [],
};

export const isValidAge = getState => {
  const currentMinAge = getCurrentProfileMinAge(getState())?.toString();
  const { tags = [] } = getAssetData(getState()) || {};

  return tags.includes(currentMinAge);
};

export const isParentProfile = getState => {
  const profileId = getCurrentProfileId(getState());
  const parentUserId = getParentUserId(getState());
  return profileId === parentUserId;
};

export const canUpdateCustomData = getState => {
  return !isParentProfile(getState) && isValidAge(getState);
};

const REQUEST_IN_PROGRESS = 'Request already in progress';
// Errors TODO: Refactor to services
class VindiciaError extends Error {
  constructor(payload) {
    super(JSON.stringify(payload?.operationError || payload));
    this.payload = payload;
    this.name = 'VindiciaError';
  }
}

// Action creator wrappers
const createErrorWrappedAction = actionCreator => (
  ...rest
) => async dispatch => {
  const thunkReturnValue = await dispatch(actionCreator(...rest));
  if (!thunkReturnValue) {
    // Request is in progress, throw an error
    throw new VindiciaError({ errorMsg: REQUEST_IN_PROGRESS });
  }
  const { type, payload } = thunkReturnValue;
  if (type === String(asyncActions.failure)) {
    throw new VindiciaError(payload);
  }
  return thunkReturnValue;
};

const retryFetchCall = async (
  href,
  fetchParams,
  dispatch,
  getState,
  retries = 1,
) => {
  const response = await fetch(href, fetchParams);
  const [ok, status, responseJson] = await Promise.all([
    response.ok,
    response.status,
    response.json().catch(() => ({})),
  ]);
  const returnError = {
    error: {
      statusCode: status,
      ...responseJson,
    },
  };

  // Exclude hard profile switch calls
  if (status === 401 && retries > 0 && !href.includes('switch')) {
    try {
      // If access token error, refresh token
      if (fetchParams.headers?.Authorization?.includes('Bearer')) {
        await dispatch(refreshAccessToken());
        const accessToken = getAccessToken(getState());
        fetchParams.headers.Authorization = `Bearer ${accessToken}`;
        return retryFetchCall(
          href,
          fetchParams,
          dispatch,
          getState,
          retries - 1,
        );
        // If session cookie elapses, refresh cookie with user call
      }
      if (isUserLogged(getState())) {
        // Retry custom data call
        if (href.toLowerCase().includes('customdata')) {
          try {
            await dispatch(getUserCall());
          } catch {
            await dispatch(recreateSession());
          }
        } else {
          await dispatch(recreateSession());
        }
        return retryFetchCall(
          href,
          fetchParams,
          dispatch,
          getState,
          retries - 1,
        );
      }
    } catch {
      return returnError;
    }
  }

  if (!ok) {
    return returnError;
  }
  return responseJson;
};

const createVindiciaAction = (
  asyncId,
  apiId,
  endpointId,
  successAction = () => () => {},
) =>
  createErrorWrappedAction(
    createFetchAction(
      asyncId,
      successAction,
      async (getState, data, dispatch) => {
        // For safety, resolve requests if guest mode is active
        const guestMode = isGuestMode(getState());
        if (guestMode) {
          return Promise.resolve({});
        }
        const { url: host, endpoints } = {
          [apiIds.IDENTITY_API]: getApiIdentityEndpoints,
          [apiIds.OIDC_API]: getApiOidcEndpoints,
          [apiIds.CUSTOM_DATA_API]: getApiCustomFieldsEndpoints,
        }[apiId](getState());
        const { path, method } = endpoints[endpointId];

        let pathWithParams = Object.entries(data?.params || {}).reduce(
          (pathString, [paramName, paramValue]) => {
            return pathString.replace(`{{${paramName}}}`, paramValue);
          },
          path,
        );

        if (data.path) {
          pathWithParams += data.path;
        }

        const requestUrl = new URL(`${host}${pathWithParams}`);
        requestUrl.search = new URLSearchParams({ ...data?.query });

        const jsonBody =
          (data.formUrlEncoded && data.body) ||
          (data?.body && JSON.stringify({ ...data.body }));

        const accessToken = getAccessToken(getState());

        const fetchParams = {
          credentials: 'include',
          method,
          headers: data?.headers
            ? data.headers
            : {
                'Content-Type': 'application/json',
              },
          body: jsonBody,
        };

        if (accessToken) {
          fetchParams.headers.Authorization = `Bearer ${accessToken}`;
        }

        return retryFetchCall(requestUrl.href, fetchParams, dispatch, getState);
      },
    ),
  );

export const ASYNC_REFRESH_ACCESS_TOKEN = 'ASYNC_REFRESH_ACCESS_TOKEN';
const refreshAccessToken = () => async (dispatch, getState) => {
  const clientId = getOidcClientId(getState());
  const refreshToken = getRefreshToken(getState());

  try {
    await dispatch(
      createVindiciaAction(
        ASYNC_REFRESH_ACCESS_TOKEN,
        apiIds.OIDC_API,
        'isCodeValidated',
        ({ access_token }) => () => {
          const setAccessTokenActionResponse = dispatch(
            actions.setAccessToken(access_token),
          );
          return setAccessTokenActionResponse;
        },
      )({
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        formUrlEncoded: true,
        body: formUrlEncode({
          client_id: clientId,
          refresh_token: refreshToken,
          grant_type: 'refresh_token',
        }),
      }),
    );
  } catch (e) {
    dispatch(actions.setAccessToken(null));
    dispatch(actions.setRefreshToken(null));
    throw e;
  }
};

// Processes
export const ASYNC_PROCESS_STEP = 'ASYNC_PROCESS_STEP';
export const processStep = ({ processId, parameters }, successAction) =>
  createVindiciaAction(
    ASYNC_PROCESS_STEP,
    apiIds.IDENTITY_API,
    'processStep',
    successAction,
  )({
    body: {
      processId,
      parameters,
    },
  });

const formUrlEncode = body => {
  const formBody = Object.keys(body)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(body[key])}`)
    .join('&');
  return formBody;
};

export const ASYNC_VERIFY_GET_ACTIVATION_CODE =
  'ASYNC_VERIFY_GET_ACTIVATION_CODE';
export const getNewActivationCode = ({ clientId }, successAction) =>
  createVindiciaAction(
    ASYNC_VERIFY_GET_ACTIVATION_CODE,
    apiIds.OIDC_API,
    'getDeviceCode',
    successAction,
  )({
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    formUrlEncoded: true,
    body: formUrlEncode({
      client_id: clientId,
      scope: 'openid profile email com.uxpsystems.ulm offline_access',
    }),
  });

export const ASYNC_IS_CODE_VALIDATED = 'ASYNC_IS_CODE_VALIDATED';
export const checkActivationCode = ({ clientId, deviceCode }, successAction) =>
  createVindiciaAction(
    ASYNC_IS_CODE_VALIDATED,
    apiIds.OIDC_API,
    'isCodeValidated',
    successAction,
  )({
    query: {
      device_code: deviceCode,
      client_id: clientId,
      grant_type: 'urn:ietf:params:oauth:grant-type:device_code',
    },
  });

// Device linking
export const ASYNC_VERIFY_USER_CODE = 'ASYNC_VERIFY_USER_CODE';
export const verifyUserCode = ({ tvCode }, successAction) =>
  createVindiciaAction(
    ASYNC_VERIFY_USER_CODE,
    apiIds.OIDC_API,
    'verifyCode',
    successAction,
  )({
    query: {
      user_code: tvCode,
    },
  });

export const ASYNC_APPROVE_USER_CODE = 'ASYNC_APPROVE_USER_CODE';
export const approveUserCode = ({ tvCode }, successAction) =>
  createVindiciaAction(
    ASYNC_APPROVE_USER_CODE,
    apiIds.OIDC_API,
    'approveCode',
    successAction,
  )({
    query: {
      user_code: tvCode,
      user_oauth_approval: true,
    },
  });

export const LINK_DEVICE = 'LINK_DEVICE';
export const linkDevice = ({ tvCode }, successAction) => async dispatch => {
  let needsApproval = false;

  try {
    await dispatch(
      verifyUserCode({ tvCode }, ({ deviceCode: { approved } }) => () => {
        if (!approved) {
          needsApproval = true;
        }
      }),
    );
    if (needsApproval) {
      return await dispatch(approveUserCode({ tvCode }, successAction));
    }
  } catch (e) {
    if (e instanceof VindiciaError) {
      dispatch(actions.setError(LINK_DEVICE, e.payload));
    }
    throw e;
  }
};

// Child profiles management
export const ASYNC_LOAD_GROUP = 'ASYNC_LOAD_GROUP';
export const loadGroup = () =>
  createVindiciaAction(
    ASYNC_LOAD_GROUP,
    apiIds.IDENTITY_API,
    'getGroup',
    actions.setGroupId,
  )({});

export const ASYNC_REMOVE_PROFILE = 'ASYNC_REMOVE_PROFILE';
export const removeProfile = userId => async (dispatch, getState) => {
  try {
    const groupId = getGroupId(getState());
    await dispatch(
      createVindiciaAction(
        ASYNC_REMOVE_PROFILE,
        apiIds.IDENTITY_API,
        'deleteChildProfile',
      )({
        params: {
          groupId,
        },
        body: {
          userId,
        },
      }),
    );
  } catch (e) {
    if (e instanceof VindiciaError) {
      dispatch(actions.setError(ASYNC_REMOVE_PROFILE, e.payload));
    }
    throw e;
  }
};

export const ASYNC_ADD_PROFILE_BASIC = 'ASYNC_ADD_PROFILE_BASIC';
export const addProfileBasic = (
  groupId,
  { displayName, avatarUrl },
  successAction,
) =>
  createVindiciaAction(
    `${ASYNC_ADD_PROFILE_BASIC}_${displayName}`,
    apiIds.IDENTITY_API,
    'createChildProfile',
    successAction,
  )({
    params: {
      groupId,
    },
    body: {
      groupPermission: 'access',
      user: {
        displayName,
        avatarUrl,
      },
    },
  });

export const ASYNC_ADD_PROFILE_CUSTOM_DATA = 'ASYNC_ADD_PROFILE_CUSTOM_DATA';
export const addProfileCustomData = (
  profileId,
  { dateOfBirth: birthdate, parentalTimeLimit },
  guestCustomData = {},
) =>
  createVindiciaAction(
    `${ASYNC_ADD_PROFILE_CUSTOM_DATA}_${profileId}`,
    apiIds.CUSTOM_DATA_API,
    CREATE_CHILD_CUSTOM_DATA,
  )({
    params: {
      sonUserId: profileId,
    },
    body: {
      ...customUserDataKeys,
      ...guestCustomData,
      birthdate,
      parentalTimeLimit,
    },
  });

export const ASYNC_UPDATE_PROFILE_BASIC = 'ASYNC_UPDATE_PROFILE_BASIC';
export const updateProfileBasic = (
  profileId,
  { displayName, avatarUrl },
  successAction,
  failureAction = () => () => {},
) => async (dispatch, getState) => {
  try {
    const groupId = getGroupId(getState());
    await dispatch(
      createVindiciaAction(
        ASYNC_UPDATE_PROFILE_BASIC,
        apiIds.IDENTITY_API,
        'updateChildProfile',
        successAction,
      )({
        params: {
          groupId,
        },
        body: {
          user: {
            id: profileId,
            avatarUrl,
            ...((displayName && { displayName }) || undefined),
          },
        },
      }),
    );
  } catch (e) {
    dispatch(failureAction());
    if (e instanceof VindiciaError) {
      dispatch(actions.setError(ASYNC_UPDATE_PROFILE_BASIC, e.payload));
    }
    throw e;
  }
};

export const ASYNC_UPDATE_PROFILE_CUSTOM_DATA =
  'ASYNC_UPDATE_PROFILE_CUSTOM_DATA';
export const updateProfileCustomData = (
  profileId,
  { dateOfBirth: birthdate, parentalTimeLimit, timeWatched } = {},
) =>
  createVindiciaAction(
    `${ASYNC_UPDATE_PROFILE_CUSTOM_DATA}_${profileId}`,
    apiIds.CUSTOM_DATA_API,
    UPDATE_CHILD_CUSTOM_DATA,
  )({
    params: {
      sonUserId: profileId,
    },
    body: {
      birthdate,
      parentalTimeLimit,
      timeWatched,
    },
  });

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const updateProfile = (
  profileId,
  { name, avatar, age, birthdayMonth, parentalTimeLimit },
) => async dispatch => {
  try {
    await dispatch(
      updateProfileBasic(profileId, { avatarUrl: avatar, displayName: name }),
    );
    await dispatch(
      updateProfileCustomData(profileId, {
        dateOfBirth: aggregateBirthdate(age, birthdayMonth),
        parentalTimeLimit,
      }),
    );
  } catch (e) {
    if (e instanceof VindiciaError) {
      dispatch(actions.setError(UPDATE_PROFILE, e.payload));
    }
    throw e;
  }
};

// Parental time limit
export const ASYNC_UPDATE_PARENTAL_TIME_LIMIT =
  'ASYNC_UPDATE_PARENTAL_TIME_LIMIT';
export const updateParentalTimeLimit = (
  profileId,
  timeDelta,
) => async dispatch => {
  let profileCustomData;
  await dispatch(
    loadProfileCustomData(profileId, customData => () => {
      profileCustomData = customData;
    }),
  );

  const {
    parentalTimeLimit = 0,
    timeWatched: { duration = 0, lastUpdate = null } = {},
  } = profileCustomData;

  const isDurationReset =
    !lastUpdate ||
    lastUpdate <
      moment()
        .subtract(1, 'day')
        .valueOf() ||
    moment(lastUpdate).date() !== moment().date();

  const durationUpdate = isDurationReset ? 0 : duration + timeDelta;

  const updatedTimeWatched = {
    duration: durationUpdate,
    lastUpdate: isDurationReset ? Date.now() : undefined,
  };

  await dispatch(
    updateProfileCustomData(profileId, {
      [customDataFieldTypes.TIME_WATCHED]: updatedTimeWatched,
    }),
  );
  dispatch(
    onboardingActions.updateProfileData({
      storeId: profileId,
      [customDataFieldTypes.TIME_WATCHED]: updatedTimeWatched,
    }),
  );

  return { durationWatched: durationUpdate, parentalTimeLimit };
};

export const ASYNC_EXTEND_TIME_ALLOWANCE = 'ASYNC_EXTEND_TIME_ALLOWANCE';
export const extendTimeAllowance = (profileId, extraTime) => async dispatch => {
  let profileCustomData;
  await dispatch(
    loadProfileCustomData(profileId, customData => () => {
      profileCustomData = customData;
    }),
  );

  const { timeWatched: { duration = 0 } = {} } = profileCustomData;

  const updatedTimeWatched = { duration: duration - extraTime };

  const response = await dispatch(
    updateProfileCustomData(profileId, {
      [customDataFieldTypes.TIME_WATCHED]: updatedTimeWatched,
    }),
  );
  dispatch(
    onboardingActions.updateProfileData({
      storeId: profileId,
      [customDataFieldTypes.TIME_WATCHED]: updatedTimeWatched,
    }),
  );
  return response;
};

export const checkParentalTimeLimit = ({
  profileId,
  timeDelta = 0,
  showModal = true,
}) => async dispatch => {
  const { durationWatched, parentalTimeLimit } = await dispatch(
    updateParentalTimeLimit(profileId, timeDelta),
  );

  if (parentalTimeLimit !== 0 && durationWatched > parentalTimeLimit) {
    showModal &&
      dispatch(
        modalActions.openModal({
          modalId: 'TimesUpModal',
          modalData: { profileId },
        }),
      );
    return true;
  }
  return false;
};

export const parentalTimeLimitHeartbeat = timeDelta => async (
  dispatch,
  getState,
) => {
  const profileId = getCurrentProfileId(getState());
  dispatch(checkParentalTimeLimit({ profileId, timeDelta }));
};

export const ASYNC_UPDATE_PROFILE_WATCH_HISTORY =
  'ASYNC_UPDATE_PROFILE_WATCH_HISTORY';
export const updateProfileWatchHistory = startTime => async (
  dispatch,
  getState,
) => {
  if (!canUpdateCustomData(getState)) {
    return;
  }

  const profileId = getCurrentProfileId(getState());
  const { videoReferenceId } = getCurrentVideo(getState());

  try {
    const createResponse = await dispatch(
      createVindiciaAction(
        ASYNC_UPDATE_PROFILE_WATCH_HISTORY,
        apiIds.CUSTOM_DATA_API,
        CREATE_CHILD_CUSTOM_DATA,
      )({
        path: `/${customDataFieldTypes.WATCH_HISTORY}`,
        params: {
          sonUserId: profileId,
        },
        body: {
          videoId: videoReferenceId,
          startWatch: startTime,
          endWatch: Date.now(),
        },
      }),
    );

    return createResponse;
  } catch (e) {
    if (e instanceof VindiciaError) {
      dispatch(actions.setError(ASYNC_UPDATE_PROFILE_WATCH_HISTORY, e.payload));
    }
  }
};

export const ASYNC_UPDATE_PROFILE_LIVE_HISTORY =
  'ASYNC_UPDATE_PROFILE_LIVE_HISTORY';
export const updateProfileLiveHistory = (
  streamId,
  streamUrl,
  startTime,
) => async (dispatch, getState) => {
  if (isParentProfile(getState)) {
    return;
  }

  const profileId = getCurrentProfileId(getState());

  try {
    const createResponse = await dispatch(
      createVindiciaAction(
        ASYNC_UPDATE_PROFILE_WATCH_HISTORY,
        apiIds.CUSTOM_DATA_API,
        CREATE_CHILD_CUSTOM_DATA,
      )({
        path: `/${customDataFieldTypes.LIVE_HISTORY}`,
        params: {
          sonUserId: profileId,
        },
        body: {
          streamId,
          url: streamUrl,
          startWatch: startTime,
          endWatch: Date.now(),
        },
      }),
    );

    return createResponse;
  } catch (e) {
    if (e instanceof VindiciaError) {
      dispatch(actions.setError(ASYNC_UPDATE_PROFILE_LIVE_HISTORY, e.payload));
    }
  }
};

export const ASYNC_LOAD_PROFILE_BOOKMARK_BY_ID =
  'ASYNC_LOAD_PROFILE_BOOKMARK_BY_ID';
export const loadCustomDataVideoBookmark = () => async (dispatch, getState) => {
  const profileId = getCurrentProfileId(getState());
  const { videoId, videoReferenceId } = getCurrentVideo(getState());

  try {
    const getResponse = await dispatch(
      createVindiciaAction(
        ASYNC_LOAD_PROFILE_BOOKMARK_BY_ID,
        apiIds.CUSTOM_DATA_API,
        READ_CHILD_CUSTOM_DATA,
        payload => {
          if (payload.length) {
            payload[0].brightcoveId = videoId;
            dispatch(saveCustomDataVideoBookmark(payload));
          }

          return {
            type: ASYNC_LOAD_PROFILE_BOOKMARK_BY_ID,
          };
        },
      )({
        path: `/${customDataFieldTypes.BOOKMARKS_V2}`,
        params: {
          sonUserId: profileId,
        },
        query: {
          filter: `videoId eq "${videoReferenceId}"`,
        },
      }),
    );
    return getResponse;
  } catch (e) {
    if (e instanceof VindiciaError) {
      dispatch(actions.setError(ASYNC_LOAD_PROFILE_BOOKMARK_BY_ID, e.payload));
    }
  }
};

export const ASYNC_DELETE_PROFILE_BOOKMARK_BY_ID =
  'ASYNC_DELETE_PROFILE_BOOKMARK_BY_ID';
export const deleteCustomDataVideoBookmark = videoId => async (
  dispatch,
  getState,
) => {
  if (!canUpdateCustomData(getState)) {
    return;
  }

  const profileId = getCurrentProfileId(getState());

  try {
    const deleteResponse = await dispatch(
      createVindiciaAction(
        ASYNC_DELETE_PROFILE_BOOKMARK_BY_ID,
        apiIds.CUSTOM_DATA_API,
        DELETE_CHILD_CUSTOM_DATA,
      )({
        path: `/${customDataFieldTypes.BOOKMARKS_V2}`,
        params: {
          sonUserId: profileId,
        },
        query: {
          filter: `videoId eq "${videoId}"`,
        },
      }),
    );

    return deleteResponse;
  } catch (e) {
    if (e instanceof VindiciaError) {
      dispatch(actions.setError(ASYNC_LOAD_PROFILE_BOOKMARK_BY_ID, e.payload));
    }
  }
};

export const ASYNC_LOOKUP_BOOKMARK = 'ASYNC_LOOKUP_BOOKMARK';
export const ASYNC_UPDATE_PROFILE_BOOKMARK = 'ASYNC_UPDATE_PROFILE_BOOKMARK';
export const updateProfileBookmark = progress => async (dispatch, getState) => {
  if (!canUpdateCustomData(getState)) {
    return;
  }

  const profileId = getCurrentProfileId(getState());
  const { videoReferenceId } = getCurrentVideo(getState());
  const item = {
    videoId: videoReferenceId,
    progress: Math.floor(progress * 1000),
    lastUpdate: Date.now(),
  };

  try {
    // need to do get to determine if bookmark exists to decide between post/patch
    const getResponse = await dispatch(
      createVindiciaAction(
        ASYNC_LOOKUP_BOOKMARK,
        apiIds.CUSTOM_DATA_API,
        READ_CHILD_CUSTOM_DATA,
        payload => {
          dispatch(
            createVindiciaAction(
              ASYNC_UPDATE_PROFILE_BOOKMARK,
              apiIds.CUSTOM_DATA_API,
              payload?.length
                ? UPDATE_CHILD_CUSTOM_DATA
                : CREATE_CHILD_CUSTOM_DATA,
            )({
              path: `/${customDataFieldTypes.BOOKMARKS_V2}`,
              params: {
                sonUserId: profileId,
              },
              query: {
                filter: `videoId eq "${videoReferenceId}"`,
              },
              body: item,
            }),
          );

          return {
            type: ASYNC_UPDATE_PROFILE_BOOKMARK,
          };
        },
      )({
        path: `/${customDataFieldTypes.BOOKMARKS_V2}`,
        params: {
          sonUserId: profileId,
        },
        query: {
          filter: `videoId eq "${videoReferenceId}"`,
        },
      }),
    );

    return getResponse;
  } catch (e) {
    if (e instanceof VindiciaError) {
      dispatch(actions.setError(ASYNC_UPDATE_PROFILE_BOOKMARK, e.payload));
      if (e?.payload?.errorMsg?.statusCode === 404) {
        // No Bookmarks_v2 entry. Do a post.
        return dispatch(
          createVindiciaAction(
            ASYNC_UPDATE_PROFILE_BOOKMARK,
            apiIds.CUSTOM_DATA_API,
            CREATE_CHILD_CUSTOM_DATA,
          )({
            path: `/${customDataFieldTypes.BOOKMARKS_V2}`,
            params: {
              sonUserId: profileId,
            },
            query: {
              filter: `videoId eq "${videoReferenceId}"`,
            },
            body: item,
          }),
        );
      }
    }
  }
};

export const ASYNC_LOAD_PROFILE_SHOWS_FAVORITES =
  'ASYNC_LOAD_PROFILE_SHOWS_FAVORITES';

export const loadCustomDataShowsFavorites = () => async (
  dispatch,
  getState,
) => {
  const profileId = getCurrentProfileId(getState());
  try {
    const getResponse = await dispatch(
      createVindiciaAction(
        ASYNC_LOAD_PROFILE_SHOWS_FAVORITES,
        apiIds.CUSTOM_DATA_API,
        READ_CHILD_CUSTOM_DATA,
        actions.saveCustomDataShowsFavorites,
      )({
        path: `/${customDataFieldTypes.SHOWS_FAVORITES}`,
        params: {
          sonUserId: profileId,
        },
      }),
    );
    return getResponse;
  } catch (e) {
    if (e instanceof VindiciaError) {
      dispatch(actions.setError(ASYNC_LOAD_PROFILE_SHOWS_FAVORITES, e.payload));
      if (e?.payload?.errorMsg?.statusCode === 404) {
        dispatch(actions.saveCustomDataShowsFavorites([]));
      }
    }
  }
};

export const clearCustomDataRecentlyWatched = () => dispatch => {
  dispatch(brightcoveActions.clearRecentlyWatchedAssetsByReference());
  return {
    type: `${actionOptions.prefix}/${CLEAR_CUSTOM_DATA_RECENTLY_WATCHED}`,
  };
};

export const ASYNC_LOAD_PROFILE_RECENTLY_WATCHED =
  'ASYNC_LOAD_PROFILE_RECENTLY_WATCHED';

export const loadCustomDataRecentlyWatched = (
  containerId = null,
  queryData = {},
  mergeData = false,
) => async (dispatch, getState) => {
  const query = {
    size: queryData?.size || getRecentlyWatchedLimit(getState()),
    orderBy: '-lastUpdate',
    page: queryData?.page || 0,
  };
  const profileId = getCurrentProfileId(getState());
  try {
    const getResponse = await dispatch(
      createVindiciaAction(
        ASYNC_LOAD_PROFILE_RECENTLY_WATCHED,
        apiIds.CUSTOM_DATA_API,
        READ_CHILD_CUSTOM_DATA,
        async data => {
          if (data?.length) {
            const filtered = []; // remove duplicate entries from vindicia
            const temp = {}; // temporal object to detect duplicates
            const ids = []; // array of ids for brightcove request

            if (mergeData) {
              // Prepend existing data on results
              const loaded = getCustomDataRecentlyWatched(getState()) || [];
              loaded.forEach(i => {
                filtered.push(i);
                temp[i.videoId] = true;
              });
            }

            // Filter duplicate entries
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < data.length; i++) {
              if (!temp[data[i].videoId]) {
                temp[data[i].videoId] = true;
                filtered.push(data[i]);
                ids.push(data[i].videoId);
              }
            }

            if (ids.length === 0) {
              // If no more new entries stop the fetching process
              dispatch(stopLoadingRecentlyWatched());
            } else {
              dispatch(actions.saveCustomDataRecentlyWatched(filtered));
              dispatch(
                loadRecentlyWatchedAssetsByReferenceIds(
                  containerId,
                  [
                    {
                      referenceIds: ids,
                      limit: ids.length,
                    },
                  ],
                  mergeData,
                ),
              );
            }
          } else {
            if (!mergeData) {
              // First call didn't yied any result
              // only first calls use mergeData = false
              dispatch(actions.saveCustomDataRecentlyWatched([]));
              dispatch(
                brightcoveActions.saveRecentlyWatchedAssetsByReference({
                  videos: [],
                }),
              );
            }
            dispatch(stopLoadingRecentlyWatched());
          }
          return {
            type: ASYNC_LOAD_PROFILE_RECENTLY_WATCHED,
          };
        },
      )({
        path: `/${customDataFieldTypes.RECENTLY_WATCHED_V2}`,
        params: {
          sonUserId: profileId,
        },
        query,
      }),
    );
    return getResponse;
  } catch (e) {
    if (e instanceof VindiciaError) {
      dispatch(
        actions.setError(ASYNC_LOAD_PROFILE_RECENTLY_WATCHED, e.payload),
      );
      if (e?.payload?.errorMsg?.statusCode === 404) {
        dispatch(actions.saveCustomDataRecentlyWatched([]));
        dispatch(
          brightcoveActions.saveRecentlyWatchedAssetsByReference({
            videos: [],
          }),
        );
        dispatch(stopLoadingRecentlyWatched());
      }
    }
  }
};

export const ASYNC_LOAD_PROFILE_PODCASTS_FAVORITES =
  'ASYNC_LOAD_PROFILE_PODCASTS_FAVORITES';

export const loadCustomDataPodcastsFavorites = () => async (
  dispatch,
  getState,
) => {
  const profileId = getCurrentProfileId(getState());
  try {
    const getResponse = await dispatch(
      createVindiciaAction(
        ASYNC_LOAD_PROFILE_PODCASTS_FAVORITES,
        apiIds.CUSTOM_DATA_API,
        READ_CHILD_CUSTOM_DATA,
        actions.saveCustomDataPodcastsFavorites,
      )({
        path: `/${customDataFieldTypes.PODCASTS_FAVORITES}`,
        params: {
          sonUserId: profileId,
        },
      }),
    );
    return getResponse;
  } catch (e) {
    if (e instanceof VindiciaError) {
      dispatch(
        actions.setError(ASYNC_LOAD_PROFILE_PODCASTS_FAVORITES, e.payload),
      );
      if (e?.payload?.errorMsg?.statusCode === 404) {
        dispatch(actions.saveCustomDataPodcastsFavorites([]));
      }
    }
  }
};

// TODO: try to merge this with the other similar func of favorites
export const ASYNC_LOOKUP_SHOW_FAVORITE = 'ASYNC_LOOKUP_SHOW_FAVORITE';
export const ASYNC_UPDATE_SHOW_FAVORITE = 'ASYNC_UPDATE_SHOW_FAVORITE';
export const updateShowFavoriteById = (id, toAdd, item) => async (
  dispatch,
  getState,
) => {
  if (isParentProfile(getState)) {
    return;
  }

  const profileId = getCurrentProfileId(getState());

  try {
    // need to do get to determine if bookmark exists to decide between post/patch
    const getResponse = await dispatch(
      createVindiciaAction(
        ASYNC_LOOKUP_SHOW_FAVORITE,
        apiIds.CUSTOM_DATA_API,
        READ_CHILD_CUSTOM_DATA,
        payload => {
          if (toAdd && payload.length === 0) {
            dispatch(
              createVindiciaAction(
                ASYNC_UPDATE_SHOW_FAVORITE,
                apiIds.CUSTOM_DATA_API,
                CREATE_CHILD_CUSTOM_DATA,
              )({
                path: `/${customDataFieldTypes.SHOWS_FAVORITES}`,
                params: {
                  sonUserId: profileId,
                },
                body: {
                  ...item,
                },
              }),
            );
            dispatch(actions.addSingleCustomDataShowsFavorite(item));
          } else if (!toAdd && payload.length > 0) {
            dispatch(
              createVindiciaAction(
                ASYNC_UPDATE_SHOW_FAVORITE,
                apiIds.CUSTOM_DATA_API,
                DELETE_CHILD_CUSTOM_DATA,
              )({
                path: `/${customDataFieldTypes.SHOWS_FAVORITES}`,
                params: {
                  sonUserId: profileId,
                },
                query: {
                  filter: `id eq "${id}"`,
                },
              }),
            );
            dispatch(actions.deleteSingleCustomDataShowsFavorite(id));
          }

          return {
            type: ASYNC_UPDATE_SHOW_FAVORITE,
          };
        },
      )({
        path: `/${customDataFieldTypes.SHOWS_FAVORITES}`,
        params: {
          sonUserId: profileId,
        },
        query: {
          filter: `id eq "${id}"`,
        },
      }),
    );

    return getResponse;
  } catch (e) {
    if (e?.payload?.errorMsg?.statusCode === 404) {
      if (toAdd) {
        dispatch(
          createVindiciaAction(
            ASYNC_UPDATE_SHOW_FAVORITE,
            apiIds.CUSTOM_DATA_API,
            UPDATE_CHILD_CUSTOM_DATA,
          )({
            params: {
              sonUserId: profileId,
            },
            body: {
              showsFavorites: [item],
            },
          }),
        );

        dispatch(actions.addSingleCustomDataShowsFavorite(item));

        return {
          type: ASYNC_UPDATE_SHOW_FAVORITE,
        };
      }
    }

    if (e instanceof VindiciaError) {
      dispatch(actions.setError(ASYNC_UPDATE_SHOW_FAVORITE, e.payload));
    }
  }
};

export const ASYNC_LOOKUP_PODCAST_FAVORITE = 'ASYNC_LOOKUP_PODCAST_FAVORITE';
export const ASYNC_UPDATE_PODCAST_FAVORITE = 'ASYNC_UPDATE_PODCAST_FAVORITE';
export const updatePodcastFavoriteById = (id, toAdd, item) => async (
  dispatch,
  getState,
) => {
  if (isParentProfile(getState)) {
    return;
  }

  const profileId = getCurrentProfileId(getState());

  try {
    // need to do get to determine if bookmark exists to decide between post/patch
    const getResponse = await dispatch(
      createVindiciaAction(
        ASYNC_LOOKUP_PODCAST_FAVORITE,
        apiIds.CUSTOM_DATA_API,
        READ_CHILD_CUSTOM_DATA,
        payload => {
          if (toAdd && payload.length === 0) {
            dispatch(
              createVindiciaAction(
                ASYNC_UPDATE_PODCAST_FAVORITE,
                apiIds.CUSTOM_DATA_API,
                CREATE_CHILD_CUSTOM_DATA,
              )({
                path: `/${customDataFieldTypes.PODCASTS_FAVORITES}`,
                params: {
                  sonUserId: profileId,
                },
                body: {
                  ...item,
                },
              }),
            );
            dispatch(actions.addSingleCustomDataPodcastsFavorite(item));
          } else if (!toAdd && payload.length > 0) {
            dispatch(
              createVindiciaAction(
                ASYNC_UPDATE_PODCAST_FAVORITE,
                apiIds.CUSTOM_DATA_API,
                DELETE_CHILD_CUSTOM_DATA,
              )({
                path: `/${customDataFieldTypes.PODCASTS_FAVORITES}`,
                params: {
                  sonUserId: profileId,
                },
                query: {
                  filter: `id eq "${id}"`,
                },
              }),
            );
            dispatch(actions.deleteSingleCustomDataPodcastsFavorite(id));
          }

          return {
            type: ASYNC_UPDATE_PODCAST_FAVORITE,
          };
        },
      )({
        path: `/${customDataFieldTypes.PODCASTS_FAVORITES}`,
        params: {
          sonUserId: profileId,
        },
        query: {
          filter: `id eq "${id}"`,
        },
      }),
    );

    return getResponse;
  } catch (e) {
    if (e?.payload?.errorMsg?.statusCode === 404) {
      if (toAdd) {
        dispatch(
          createVindiciaAction(
            ASYNC_UPDATE_PODCAST_FAVORITE,
            apiIds.CUSTOM_DATA_API,
            UPDATE_CHILD_CUSTOM_DATA,
          )({
            params: {
              sonUserId: profileId,
            },
            body: {
              podcastsFavorites: [item],
            },
          }),
        );

        dispatch(actions.addSingleCustomDataPodcastsFavorite(item));

        return {
          type: ASYNC_UPDATE_PODCAST_FAVORITE,
        };
      }
    }

    if (e instanceof VindiciaError) {
      dispatch(actions.setError(ASYNC_UPDATE_PODCAST_FAVORITE, e.payload));
    }
  }
};

export const ASYNC_ADD_SHOWS_FAVORITE_CUSTOM_DATA =
  'ASYNC_ADD_SHOWS_FAVORITE_CUSTOM_DATA';
export const addShowFavoritesCustomData = data => async (
  dispatch,
  getState,
) => {
  if (isParentProfile(getState)) {
    return;
  }

  const profileId = getCurrentProfileId(getState());

  try {
    const createResponse = await dispatch(
      createVindiciaAction(
        ASYNC_ADD_SHOWS_FAVORITE_CUSTOM_DATA,
        apiIds.CUSTOM_DATA_API,
        CREATE_CHILD_CUSTOM_DATA,
      )({
        path: `/${customDataFieldTypes.SHOWS_FAVORITES}`,
        params: {
          sonUserId: profileId,
        },
        body: {
          ...data,
        },
      }),
    );

    return createResponse;
  } catch (e) {
    if (e instanceof VindiciaError) {
      dispatch(
        actions.setError(ASYNC_ADD_SHOWS_FAVORITE_CUSTOM_DATA, e.payload),
      );
    }
  }
};

export const ASYNC_ADD_PODCASTS_FAVORITE_CUSTOM_DATA =
  'ASYNC_ADD_PODCASTS_FAVORITE_CUSTOM_DATA';
export const addPodcastFavoritesCustomData = data => async (
  dispatch,
  getState,
) => {
  if (isParentProfile(getState)) {
    return;
  }

  const profileId = getCurrentProfileId(getState());

  try {
    const createResponse = await dispatch(
      createVindiciaAction(
        ASYNC_ADD_PODCASTS_FAVORITE_CUSTOM_DATA,
        apiIds.CUSTOM_DATA_API,
        CREATE_CHILD_CUSTOM_DATA,
      )({
        path: `/${customDataFieldTypes.PODCASTS_FAVORITES}`,
        params: {
          sonUserId: profileId,
        },
        body: {
          ...data,
        },
      }),
    );

    return createResponse;
  } catch (e) {
    if (e instanceof VindiciaError) {
      dispatch(
        actions.setError(ASYNC_ADD_PODCASTS_FAVORITE_CUSTOM_DATA, e.payload),
      );
    }
  }
};

export const ASYNC_DELETE_SHOWS_FAVORITE_CUSTOM_DATA =
  'ASYNC_DELETE_SHOWS_FAVORITE_CUSTOM_DATA';
export const deleteShowFavoritesCustomData = id => async (
  dispatch,
  getState,
) => {
  if (isParentProfile(getState)) {
    return;
  }

  const profileId = getCurrentProfileId(getState());

  try {
    const createResponse = await dispatch(
      createVindiciaAction(
        ASYNC_DELETE_SHOWS_FAVORITE_CUSTOM_DATA,
        apiIds.CUSTOM_DATA_API,
        DELETE_CHILD_CUSTOM_DATA,
      )({
        path: `/${customDataFieldTypes.SHOWS_FAVORITES}`,
        params: {
          sonUserId: profileId,
        },
        query: {
          filter: `id eq "${id}"`,
        },
      }),
    );

    return createResponse;
  } catch (e) {
    if (e instanceof VindiciaError) {
      dispatch(
        actions.setError(ASYNC_DELETE_SHOWS_FAVORITE_CUSTOM_DATA, e.payload),
      );
    }
  }
};

export const ASYNC_DELETE_PODCASTS_FAVORITE_CUSTOM_DATA =
  'ASYNC_DELETE_PODCASTS_FAVORITE_CUSTOM_DATA';
export const deletePodcastFavoritesCustomData = id => async (
  dispatch,
  getState,
) => {
  if (isParentProfile(getState)) {
    return;
  }

  const profileId = getCurrentProfileId(getState());

  try {
    const createResponse = await dispatch(
      createVindiciaAction(
        ASYNC_DELETE_PODCASTS_FAVORITE_CUSTOM_DATA,
        apiIds.CUSTOM_DATA_API,
        DELETE_CHILD_CUSTOM_DATA,
      )({
        path: `/${customDataFieldTypes.PODCASTS_FAVORITES}`,
        params: {
          sonUserId: profileId,
        },
        query: {
          filter: `id eq "${id}"`,
        },
      }),
    );

    return createResponse;
  } catch (e) {
    if (e instanceof VindiciaError) {
      dispatch(
        actions.setError(ASYNC_DELETE_PODCASTS_FAVORITE_CUSTOM_DATA, e.payload),
      );
    }
  }
};

export const ASYNC_LOAD_PROFILE_VIDEO_FAVORITES =
  'ASYNC_LOAD_PROFILE_VIDEO_FAVORITES';

export const loadCustomDataVideoFavorites = () => async (
  dispatch,
  getState,
) => {
  const profileId = getCurrentProfileId(getState());
  try {
    const getResponse = await dispatch(
      createVindiciaAction(
        ASYNC_LOAD_PROFILE_VIDEO_FAVORITES,
        apiIds.CUSTOM_DATA_API,
        READ_CHILD_CUSTOM_DATA,
        actions.saveCustomDataVideoFavorites,
      )({
        path: `/${customDataFieldTypes.VIDEO_FAVORITES}`,
        params: {
          sonUserId: profileId,
        },
      }),
    );
    return getResponse;
  } catch (e) {
    if (e instanceof VindiciaError) {
      dispatch(actions.setError(ASYNC_LOAD_PROFILE_VIDEO_FAVORITES, e.payload));
      if (e?.payload?.errorMsg?.statusCode === 404) {
        dispatch(actions.saveCustomDataVideoFavorites([]));
      }
    }
  }
};

export const ASYNC_LOAD_PROFILE_PODCASTS_EPISODES_FAVORITES =
  'ASYNC_LOAD_PROFILE_PODCASTS_EPISODES_FAVORITES';

export const loadCustomDataPodcastsEpisodesFavorites = () => async (
  dispatch,
  getState,
) => {
  const profileId = getCurrentProfileId(getState());
  try {
    const getResponse = await dispatch(
      createVindiciaAction(
        ASYNC_LOAD_PROFILE_PODCASTS_EPISODES_FAVORITES,
        apiIds.CUSTOM_DATA_API,
        READ_CHILD_CUSTOM_DATA,
        actions.saveCustomDataPodcastsEpisodesFavorites,
      )({
        path: `/${customDataFieldTypes.PODCASTS_EPISODES_FAVORITES}`,
        params: {
          sonUserId: profileId,
        },
      }),
    );
    return getResponse;
  } catch (e) {
    if (e instanceof VindiciaError) {
      dispatch(
        actions.setError(
          ASYNC_LOAD_PROFILE_PODCASTS_EPISODES_FAVORITES,
          e.payload,
        ),
      );
      if (e?.payload?.errorMsg?.statusCode === 404) {
        dispatch(actions?.saveCustomDataPodcastsEpisodesFavorites([]));
      }
    }
  }
};

// TODO: try to merge this with the other similar func of favorites
export const ASYNC_LOOKUP_VIDEO_FAVORITE = 'ASYNC_LOOKUP_VIDEO_FAVORITE';
export const ASYNC_UPDATE_VIDEO_FAVORITE = 'ASYNC_UPDATE_VIDEO_FAVORITE';
export const updateVideoFavoriteById = (id, toAdd, item) => async (
  dispatch,
  getState,
) => {
  if (!canUpdateCustomData(getState)) {
    return;
  }

  const profileId = getCurrentProfileId(getState());

  try {
    // need to do get to determine if bookmark exists to decide between post/patch
    const getResponse = await dispatch(
      createVindiciaAction(
        ASYNC_LOOKUP_VIDEO_FAVORITE,
        apiIds.CUSTOM_DATA_API,
        READ_CHILD_CUSTOM_DATA,
        payload => {
          if (toAdd && payload.length === 0) {
            dispatch(
              createVindiciaAction(
                ASYNC_UPDATE_VIDEO_FAVORITE,
                apiIds.CUSTOM_DATA_API,
                CREATE_CHILD_CUSTOM_DATA,
              )({
                path: `/${customDataFieldTypes.VIDEO_FAVORITES}`,
                params: {
                  sonUserId: profileId,
                },
                body: {
                  ...item,
                },
              }),
            );
            dispatch(actions.addSingleCustomDataVideoFavorite(item));
          } else if (!toAdd && payload.length > 0) {
            dispatch(
              createVindiciaAction(
                ASYNC_UPDATE_VIDEO_FAVORITE,
                apiIds.CUSTOM_DATA_API,
                DELETE_CHILD_CUSTOM_DATA,
              )({
                path: `/${customDataFieldTypes.VIDEO_FAVORITES}`,
                params: {
                  sonUserId: profileId,
                },
                query: {
                  filter: `id eq "${id}"`,
                },
              }),
            );
            dispatch(actions.deleteSingleCustomDataVideoFavorite(id));
          }

          return {
            type: ASYNC_UPDATE_VIDEO_FAVORITE,
          };
        },
      )({
        path: `/${customDataFieldTypes.VIDEO_FAVORITES}`,
        params: {
          sonUserId: profileId,
        },
        query: {
          filter: `id eq "${id}"`,
        },
      }),
    );

    return getResponse;
  } catch (e) {
    if (e?.payload?.errorMsg?.statusCode === 404) {
      if (toAdd) {
        dispatch(
          createVindiciaAction(
            ASYNC_UPDATE_VIDEO_FAVORITE,
            apiIds.CUSTOM_DATA_API,
            UPDATE_CHILD_CUSTOM_DATA,
          )({
            params: {
              sonUserId: profileId,
            },
            body: {
              videoFavorites: [item],
            },
          }),
        );

        dispatch(actions.addSingleCustomDataVideoFavorite(item));

        return {
          type: ASYNC_UPDATE_VIDEO_FAVORITE,
        };
      }
    }

    if (e instanceof VindiciaError) {
      dispatch(actions.setError(ASYNC_UPDATE_VIDEO_FAVORITE, e.payload));
    }
  }
};
export const ASYNC_LOOKUP_PODCASTS_EPISODES_FAVORITE =
  'ASYNC_LOOKUP_PODCASTS_EPISODES_FAVORITE';
export const ASYNC_UPDATE_PODCASTS_EPISODES_FAVORITE =
  'ASYNC_UPDATE_PODCASTS_EPISODES_FAVORITE';
export const updatePodcastsEpisodesFavoriteById = (id, toAdd, item) => async (
  dispatch,
  getState,
) => {
  if (!canUpdateCustomData(getState)) {
    return;
  }

  const profileId = getCurrentProfileId(getState());

  try {
    // need to do get to determine if bookmark exists to decide between post/patch
    const getResponse = await dispatch(
      createVindiciaAction(
        ASYNC_LOOKUP_PODCASTS_EPISODES_FAVORITE,
        apiIds.CUSTOM_DATA_API,
        READ_CHILD_CUSTOM_DATA,
        payload => {
          if (toAdd && payload.length === 0) {
            dispatch(
              createVindiciaAction(
                ASYNC_UPDATE_PODCASTS_EPISODES_FAVORITE,
                apiIds.CUSTOM_DATA_API,
                CREATE_CHILD_CUSTOM_DATA,
              )({
                path: `/${customDataFieldTypes.PODCASTS_EPISODES_FAVORITES}`,
                params: {
                  sonUserId: profileId,
                },
                body: {
                  ...item,
                },
              }),
            );
            dispatch(actions.addSingleCustomDataPodcastsEpisodesFavorite(item));
          } else if (!toAdd && payload.length > 0) {
            dispatch(
              createVindiciaAction(
                ASYNC_UPDATE_PODCASTS_EPISODES_FAVORITE,
                apiIds.CUSTOM_DATA_API,
                DELETE_CHILD_CUSTOM_DATA,
              )({
                path: `/${customDataFieldTypes.PODCASTS_EPISODES_FAVORITES}`,
                params: {
                  sonUserId: profileId,
                },
                query: {
                  filter: `id eq "${id}"`,
                },
              }),
            );
            dispatch(
              actions.deleteSingleCustomDataPodcastsEpisodesFavorite(id),
            );
          }

          return {
            type: ASYNC_UPDATE_PODCASTS_EPISODES_FAVORITE,
          };
        },
      )({
        path: `/${customDataFieldTypes.PODCASTS_EPISODES_FAVORITES}`,
        params: {
          sonUserId: profileId,
        },
        query: {
          filter: `id eq "${id}"`,
        },
      }),
    );

    return getResponse;
  } catch (e) {
    if (e?.payload?.errorMsg?.statusCode === 404) {
      if (toAdd) {
        dispatch(
          createVindiciaAction(
            ASYNC_UPDATE_PODCASTS_EPISODES_FAVORITE,
            apiIds.CUSTOM_DATA_API,
            UPDATE_CHILD_CUSTOM_DATA,
          )({
            params: {
              sonUserId: profileId,
            },
            body: {
              podcastsEpisodesFavorites: [item],
            },
          }),
        );

        dispatch(actions.addSingleCustomDataPodcastsEpisodesFavorite(item));

        return {
          type: ASYNC_UPDATE_PODCASTS_EPISODES_FAVORITE,
        };
      }
    }

    if (e instanceof VindiciaError) {
      dispatch(
        actions.setError(ASYNC_UPDATE_PODCASTS_EPISODES_FAVORITE, e.payload),
      );
    }
  }
};

export const ASYNC_ADD_VIDEO_FAVORITE_CUSTOM_DATA =
  'ASYNC_ADD_VIDEO_FAVORITE_CUSTOM_DATA';
export const addVideoFavoritesCustomData = data => async (
  dispatch,
  getState,
) => {
  if (isParentProfile(getState)) {
    return;
  }

  const profileId = getCurrentProfileId(getState());

  try {
    const createResponse = await dispatch(
      createVindiciaAction(
        ASYNC_ADD_VIDEO_FAVORITE_CUSTOM_DATA,
        apiIds.CUSTOM_DATA_API,
        CREATE_CHILD_CUSTOM_DATA,
      )({
        path: `/${customDataFieldTypes.VIDEO_FAVORITES}`,
        params: {
          sonUserId: profileId,
        },
        body: {
          ...data,
        },
      }),
    );

    return createResponse;
  } catch (e) {
    if (e instanceof VindiciaError) {
      dispatch(
        actions.setError(ASYNC_ADD_VIDEO_FAVORITE_CUSTOM_DATA, e.payload),
      );
    }
  }
};

export const ASYNC_ADD_PODCASTS_EPISODES_FAVORITE_CUSTOM_DATA =
  'ASYNC_ADD_PODCASTS_EPISODES_FAVORITE_CUSTOM_DATA';
export const addPodcastsEpisodesFavoritesCustomData = data => async (
  dispatch,
  getState,
) => {
  if (isParentProfile(getState)) {
    return;
  }

  const profileId = getCurrentProfileId(getState());

  try {
    const createResponse = await dispatch(
      createVindiciaAction(
        ASYNC_ADD_PODCASTS_EPISODES_FAVORITE_CUSTOM_DATA,
        apiIds.CUSTOM_DATA_API,
        CREATE_CHILD_CUSTOM_DATA,
      )({
        path: `/${customDataFieldTypes.PODCASTS_EPISODES_FAVORITES}`,
        params: {
          sonUserId: profileId,
        },
        body: {
          ...data,
        },
      }),
    );

    return createResponse;
  } catch (e) {
    if (e instanceof VindiciaError) {
      dispatch(
        actions.setError(
          ASYNC_ADD_PODCASTS_EPISODES_FAVORITE_CUSTOM_DATA,
          e.payload,
        ),
      );
    }
  }
};

export const ASYNC_DELETE_VIDEO_FAVORITE_CUSTOM_DATA =
  'ASYNC_DELETE_VIDEO_FAVORITE_CUSTOM_DATA';
export const deleteVideoFavoritesCustomData = id => async (
  dispatch,
  getState,
) => {
  if (isParentProfile(getState)) {
    return;
  }

  const profileId = getCurrentProfileId(getState());

  try {
    const createResponse = await dispatch(
      createVindiciaAction(
        ASYNC_DELETE_VIDEO_FAVORITE_CUSTOM_DATA,
        apiIds.CUSTOM_DATA_API,
        DELETE_CHILD_CUSTOM_DATA,
      )({
        path: `/${customDataFieldTypes.VIDEO_FAVORITES}`,
        params: {
          sonUserId: profileId,
        },
        query: {
          filter: `id eq "${id}"`,
        },
      }),
    );

    return createResponse;
  } catch (e) {
    if (e instanceof VindiciaError) {
      dispatch(
        actions.setError(ASYNC_DELETE_VIDEO_FAVORITE_CUSTOM_DATA, e.payload),
      );
    }
  }
};

export const ASYNC_DELETE_PODCASTS_EPISODES_FAVORITE_CUSTOM_DATA =
  'ASYNC_DELETE_PODCASTS_EPISODES_FAVORITE_CUSTOM_DATA';
export const deletePodcastsEpisodesFavoritesCustomData = id => async (
  dispatch,
  getState,
) => {
  if (isParentProfile(getState)) {
    return;
  }

  const profileId = getCurrentProfileId(getState());

  try {
    const createResponse = await dispatch(
      createVindiciaAction(
        ASYNC_DELETE_PODCASTS_EPISODES_FAVORITE_CUSTOM_DATA,
        apiIds.CUSTOM_DATA_API,
        DELETE_CHILD_CUSTOM_DATA,
      )({
        path: `/${customDataFieldTypes.PODCASTS_EPISODES_FAVORITES}`,
        params: {
          sonUserId: profileId,
        },
        query: {
          filter: `id eq "${id}"`,
        },
      }),
    );

    return createResponse;
  } catch (e) {
    if (e instanceof VindiciaError) {
      dispatch(
        actions.setError(
          ASYNC_DELETE_PODCASTS_EPISODES_FAVORITE_CUSTOM_DATA,
          e.payload,
        ),
      );
    }
  }
};

export const ASYNC_LOAD_PROFILE_TOPICS_FAVORITES =
  'ASYNC_LOAD_PROFILE_TOPICS_FAVORITES';

export const loadCustomDataTopicsFavorites = () => async (
  dispatch,
  getState,
) => {
  const profileId = getCurrentProfileId(getState());
  try {
    const getResponse = await dispatch(
      createVindiciaAction(
        ASYNC_LOAD_PROFILE_TOPICS_FAVORITES,
        apiIds.CUSTOM_DATA_API,
        READ_CHILD_CUSTOM_DATA,
        actions.saveCustomDataTopicsFavorites,
      )({
        path: `/${customDataFieldTypes.TOPICS_FAVORITES}`,
        params: {
          sonUserId: profileId,
        },
      }),
    );
    return getResponse;
  } catch (e) {
    if (e instanceof VindiciaError) {
      dispatch(
        actions.setError(ASYNC_LOAD_PROFILE_TOPICS_FAVORITES, e.payload),
      );
      if (e?.payload?.errorMsg?.statusCode === 404) {
        dispatch(actions.saveCustomDataTopicsFavorites([]));
      }
    }
  }
};

// TODO: try to merge this with the other similar func of favorites
export const ASYNC_LOOKUP_TOPIC_FAVORITE = 'ASYNC_LOOKUP_TOPIC_FAVORITE';
export const ASYNC_UPDATE_TOPIC_FAVORITE = 'ASYNC_UPDATE_TOPIC_FAVORITE';
export const updateTopicFavoriteById = (id, toAdd, item) => async (
  dispatch,
  getState,
) => {
  if (isParentProfile(getState)) {
    return;
  }

  const profileId = getCurrentProfileId(getState());

  try {
    // need to do get to determine if bookmark exists to decide between post/patch
    const getResponse = await dispatch(
      createVindiciaAction(
        ASYNC_LOOKUP_TOPIC_FAVORITE,
        apiIds.CUSTOM_DATA_API,
        READ_CHILD_CUSTOM_DATA,
        payload => {
          if (toAdd && payload.length === 0) {
            dispatch(
              createVindiciaAction(
                ASYNC_UPDATE_TOPIC_FAVORITE,
                apiIds.CUSTOM_DATA_API,
                CREATE_CHILD_CUSTOM_DATA,
              )({
                path: `/${customDataFieldTypes.TOPICS_FAVORITES}`,
                params: {
                  sonUserId: profileId,
                },
                body: {
                  ...item,
                },
              }),
            );
            dispatch(actions.addSingleCustomDataTopicsFavorite(item));
          } else if (!toAdd && payload.length > 0) {
            dispatch(
              createVindiciaAction(
                ASYNC_UPDATE_TOPIC_FAVORITE,
                apiIds.CUSTOM_DATA_API,
                DELETE_CHILD_CUSTOM_DATA,
              )({
                path: `/${customDataFieldTypes.TOPICS_FAVORITES}`,
                params: {
                  sonUserId: profileId,
                },
                query: {
                  filter: `id eq "${id}"`,
                },
              }),
            );
            dispatch(actions.deleteSingleCustomDataTopicsFavorite(id));
          }

          return {
            type: ASYNC_UPDATE_TOPIC_FAVORITE,
          };
        },
      )({
        path: `/${customDataFieldTypes.TOPICS_FAVORITES}`,
        params: {
          sonUserId: profileId,
        },
        query: {
          filter: `id eq "${id}"`,
        },
      }),
    );

    return getResponse;
  } catch (e) {
    if (e?.payload?.errorMsg?.statusCode === 404) {
      if (toAdd) {
        dispatch(
          createVindiciaAction(
            ASYNC_UPDATE_TOPIC_FAVORITE,
            apiIds.CUSTOM_DATA_API,
            UPDATE_CHILD_CUSTOM_DATA,
          )({
            params: {
              sonUserId: profileId,
            },
            body: {
              topicsFavorites: [item],
            },
          }),
        );

        dispatch(actions.addSingleCustomDataTopicsFavorite(item));

        return {
          type: ASYNC_UPDATE_TOPIC_FAVORITE,
        };
      }
    }

    if (e instanceof VindiciaError) {
      dispatch(actions.setError(ASYNC_UPDATE_TOPIC_FAVORITE, e.payload));
    }
  }
};

export const ASYNC_ADD_TOPICS_FAVORITE_CUSTOM_DATA =
  'ASYNC_ADD_TOPICS_FAVORITE_CUSTOM_DATA';
export const addTopicFavoritesCustomData = data => async (
  dispatch,
  getState,
) => {
  if (isParentProfile(getState)) {
    return;
  }

  const profileId = getCurrentProfileId(getState());

  try {
    const createResponse = await dispatch(
      createVindiciaAction(
        ASYNC_ADD_TOPICS_FAVORITE_CUSTOM_DATA,
        apiIds.CUSTOM_DATA_API,
        CREATE_CHILD_CUSTOM_DATA,
      )({
        path: `/${customDataFieldTypes.TOPICS_FAVORITES}`,
        params: {
          sonUserId: profileId,
        },
        body: {
          ...data,
        },
      }),
    );

    return createResponse;
  } catch (e) {
    if (e instanceof VindiciaError) {
      dispatch(
        actions.setError(ASYNC_ADD_TOPICS_FAVORITE_CUSTOM_DATA, e.payload),
      );
    }
  }
};

export const ASYNC_DELETE_TOPICS_FAVORITE_CUSTOM_DATA =
  'ASYNC_DELETE_TOPICS_FAVORITE_CUSTOM_DATA';
export const deleteTopicFavoritesCustomData = id => async (
  dispatch,
  getState,
) => {
  if (isParentProfile(getState)) {
    return;
  }

  const profileId = getCurrentProfileId(getState());

  try {
    const createResponse = await dispatch(
      createVindiciaAction(
        ASYNC_DELETE_TOPICS_FAVORITE_CUSTOM_DATA,
        apiIds.CUSTOM_DATA_API,
        DELETE_CHILD_CUSTOM_DATA,
      )({
        path: `/${customDataFieldTypes.TOPICS_FAVORITES}`,
        params: {
          sonUserId: profileId,
        },
        query: {
          filter: `id eq "${id}"`,
        },
      }),
    );

    return createResponse;
  } catch (e) {
    if (e instanceof VindiciaError) {
      dispatch(
        actions.setError(ASYNC_DELETE_TOPICS_FAVORITE_CUSTOM_DATA, e.payload),
      );
    }
  }
};

export const ASYNC_GET_PROFILE_SINGLE_RECENTLY_WATCHED_ITEM =
  'ASYNC_GET_PROFILE_SINGLE_RECENTLY_WATCHED_ITEM';

const getSingleRecentlyWatched = async (videoId, profileId, dispatch) => {
  return new Promise(async res => {
    try {
      await dispatch(
        createVindiciaAction(
          ASYNC_GET_PROFILE_SINGLE_RECENTLY_WATCHED_ITEM,
          apiIds.CUSTOM_DATA_API,
          READ_CHILD_CUSTOM_DATA,
          data => {
            res(data?.length ? data[0] : null);
            return {
              type: ASYNC_GET_PROFILE_SINGLE_RECENTLY_WATCHED_ITEM,
            };
          },
        )({
          path: `/${customDataFieldTypes.RECENTLY_WATCHED_V2}`,
          params: {
            sonUserId: profileId,
          },
          query: {
            filter: `videoId eq "${videoId}"`,
          },
        }),
      );
    } catch (e) {
      if (e instanceof VindiciaError) {
        dispatch(
          actions.setError(
            ASYNC_GET_PROFILE_SINGLE_RECENTLY_WATCHED_ITEM,
            e.payload,
          ),
        );
      }
      res(null);
    }
  }).catch(() => null);
};

export const ASYNC_UPDATE_PROFILE_RECENTLY_WATCHED =
  'ASYNC_UPDATE_PROFILE_RECENTLY_WATCHED';
export const updateProfileRecentlyWatched = videoInfo => async (
  dispatch,
  getState,
) => {
  if (!canUpdateCustomData(getState)) {
    return;
  }

  const profileId = getCurrentProfileId(getState());
  const { reference_id: videoId } = videoInfo;

  if (!videoId) {
    // Cannot add to recently watched without reference_id
    console.warn('[Identity] No reference_id. Ignoring on recently watched.');
    return;
  }

  const recentlyWatchedList = getCustomDataRecentlyWatched(getState());
  const recentlyWatchedData = getRecentlyWatchedAssetsByReferenceIds(
    getState(),
  );

  if (recentlyWatchedList == null || recentlyWatchedData == null) {
    // Not loaded yet, delay 2 seconds.
    setTimeout(() => {
      dispatch(updateProfileRecentlyWatched(videoInfo));
    }, 2 * 1000);
    return;
  }

  let elementInList = null;

  // Try to get item to identify if request should be
  // a post or a patch
  try {
    elementInList = await getSingleRecentlyWatched(
      videoId,
      profileId,
      dispatch,
    );
    // eslint-disable-next-line no-empty
  } catch (e) {
    /* Supress if not found */
  }

  let endpointId;
  let filterFunction;
  const lastUpdate = Date.now();
  const newEntry = { videoId, lastUpdate };
  const requestArgs = {
    path: `/${customDataFieldTypes.RECENTLY_WATCHED_V2}`,
    params: {
      sonUserId: profileId,
    },
    body: newEntry,
  };

  if (elementInList) {
    endpointId = UPDATE_CHILD_CUSTOM_DATA;
    filterFunction = updateRecentlyWatchedList;
    requestArgs.query = {
      filter: `videoId eq "${videoId}"`,
    };
  } else {
    endpointId = CREATE_CHILD_CUSTOM_DATA;
    filterFunction = addToRecentlyWatchedList;
  }

  try {
    const [updatedList, updatedData] = filterFunction(
      newEntry,
      recentlyWatchedList,
      videoInfo,
      recentlyWatchedData,
    );

    dispatch(actions.saveCustomDataRecentlyWatched(updatedList));
    dispatch(
      brightcoveActions.saveRecentlyWatchedAssetsByReference({
        videos: updatedData,
      }),
    );

    const createResponse = await dispatch(
      createVindiciaAction(
        ASYNC_UPDATE_PROFILE_RECENTLY_WATCHED,
        apiIds.CUSTOM_DATA_API,
        endpointId,
      )(requestArgs),
    );
    return createResponse;
  } catch (e) {
    if (e instanceof VindiciaError) {
      dispatch(
        actions.setError(ASYNC_UPDATE_PROFILE_RECENTLY_WATCHED, e.payload),
      );
    }
  }
};

export const ASYNC_LOAD_PROFILES_BASIC = 'ASYNC_LOAD_PROFILES_BASIC';
export const loadProfilesBasic = (groupId, successAction) =>
  createVindiciaAction(
    ASYNC_LOAD_PROFILES_BASIC,
    apiIds.IDENTITY_API,
    'getAllUsers',
    successAction,
  )({
    params: {
      groupId,
    },
  });

export const ASYNC_LOAD_PROFILE_CUSTOM_DATA = 'ASYNC_LOAD_PROFILE_CUSTOM_DATA';
export const loadProfileCustomData = (profileId, successAction) => {
  if (!profileId) {
    return { type: 'noop' };
  }

  return createVindiciaAction(
    `${ASYNC_LOAD_PROFILE_CUSTOM_DATA}_${profileId}`,
    apiIds.CUSTOM_DATA_API,
    READ_CHILD_CUSTOM_DATA,
    successAction,
  )({
    params: {
      sonUserId: profileId,
    },
  });
};

// TODO: Account info too intertwined with profile loading, break out into separate function to
// be called on getUser, login, and switch profile
export const LOAD_PROFILES = 'LOAD_PROFILES';
export const loadProfiles = () => async (dispatch, getState) => {
  const groupId = getGroupId(getState());
  await dispatch(
    loadProfilesBasic(groupId, profiles => () => {
      dispatch(onboardingActions.populateProfiles(profiles));
      const { user: parentProfile } = profiles.filter(
        ({ user: { type } }) => type === 'user.RegularUser',
      )[0];
      const { id } = parentProfile;
      dispatch(actions.setParentAccountInfo(parentProfile));
      return dispatch(actions.setParentUserId(id));
    }),
  );

  const parentUserId = getParentUserId(getState());
  // Do not await parent custom data, as it is not available in child context
  dispatch(
    loadProfileCustomData(parentUserId, customData =>
      actions.setParentCustomData(customData),
    ),
  );

  return Promise.all(
    getAllProfiles(getState()).map(({ id: profileId }) =>
      dispatch(
        loadProfileCustomData(profileId, customData =>
          onboardingActions.populateProfileCustomData({
            id: profileId,
            ...customData,
          }),
        ),
      ).catch(error => {
        if (!(error instanceof VindiciaError)) {
          throw error;
        }
        const {
          payload: {
            errorMsg: { statusCode },
          },
        } = error;
        if (statusCode !== 404) {
          throw error;
        }
        // Otherwise swallow the error, no custom data uploaded
      }),
    ),
  );
};

export const ADD_PROFILE = 'ADD_PROFILE';
export const addProfile = (
  groupId,
  {
    displayName,
    avatarUrl,
    dateOfBirth,
    age,
    syncData,
    parentalTimeLimit = defaultParentalTimeLimit,
  },
  successAction = () => () => {},
) => async dispatch => {
  // require access to guest action 'overrideStoredCustomData'
  // If more actions are required across the module,
  // it will require refactor to prevent conflics of circular dependencies
  const { actions: guestActions } = await import('../identityGuest/actions');
  const ageRange = getAgeRange(age);
  let profileId = null;

  const responsePromise = dispatch(
    addProfileBasic(
      groupId,
      { displayName, avatarUrl },
      ({ user: { id } }) => () => {
        profileId = id;
      },
    ),
  );

  try {
    const response = await responsePromise;
    await dispatch(
      addProfileCustomData(
        profileId,
        { dateOfBirth, parentalTimeLimit },
        syncData,
      ),
    );
    await dispatch(successAction(profileId));
    return response;
  } catch (error) {
    if (syncData) {
      // If error, recover the custom data
      guestActions.overrideStoredCustomData({
        age: ageRange,
        data: syncData,
      });
    }
    throw error;
  }
};

export const ADD_UNSAVED_PROFILES = 'ADD_UNSAVED_PROFILES';
export const addUnsavedProfiles = (successAction = () => () => {}) => async (
  dispatch,
  getState,
) => {
  try {
    const groupId = getGroupId(getState());
    const profileUploadResults = Promise.all(
      getUnsavedProfiles(getState()).map(
        ({
          id: storeProfileId,
          name: displayName,
          avatar: avatarUrl,
          age,
          birthdayMonth,
          syncData,
        }) => {
          const dateOfBirth = aggregateBirthdate(age, birthdayMonth);
          return dispatch(
            addProfile(
              groupId,
              { displayName, avatarUrl, dateOfBirth, age, syncData },
              (...args) => successAction(storeProfileId, ...args),
            ),
          );
        },
      ),
    );
    return profileUploadResults;
  } catch (e) {
    if (e instanceof VindiciaError) {
      dispatch(actions.setError(ADD_UNSAVED_PROFILES, e.payload));
    }
    throw e;
  }
};

// Profile switching
export const ASYNC_SWITCH_PROFILE = 'ASYNC_SWITCH_PROFILE';
export const switchProfile = ({
  targetProfileId,
  credentialType,
  credential,
  successAction = () => () => {},
  unsetPin = true,
  blocking = false,
  setErrors = true,
}) => async (dispatch, getState) => {
  try {
    const groupId = getGroupId(getState());
    const parentUserId = getParentUserId(getState());
    const savedPin = credentialType === 'pin' ? getPin(getState()) : null;
    const switchResponse = await dispatch(
      createVindiciaAction(
        ASYNC_SWITCH_PROFILE,
        apiIds.IDENTITY_API,
        'switchSession',
        successAction,
      )({
        body: {
          groupId,
          targetUserId: targetProfileId,
          authentication: {
            userId: parentUserId,
            credentialType,
            credential: credential || savedPin,
          },
        },
      }),
    );
    return switchResponse;
  } catch (e) {
    if (e instanceof VindiciaError && blocking) {
      dispatch(
        modalActions.openModal({
          modalId: 'ErrorModal',
          modalData: { errorMessage: 'The pin you entered is invalid.' },
        }),
      );
    } else if (e instanceof VindiciaError && setErrors) {
      dispatch(actions.setError(ASYNC_SWITCH_PROFILE, e.payload));
    }
    throw e;
  } finally {
    if (unsetPin) {
      dispatch(actions.setPin(null));
    }
  }
};

export const ASYNC_SWITCH_TO_PARENT_PROFILE = 'ASYNC_SWITCH_TO_PARENT_PROFILE';
export const switchToParentProfile = ({
  credentialType = 'pin',
  credential = null,
  unsetPin = true,
  blocking = false,
} = {}) => async (dispatch, getState) => {
  const parentUserId = getParentUserId(getState());
  const switchResponse = await dispatch(
    switchProfile({
      targetProfileId: parentUserId,
      credentialType,
      credential,
      unsetPin,
      blocking,
    }),
  );
  !blocking && dispatch(actions.setParentMode(true));
  return switchResponse;
};

export const ASYNC_SWITCH_TO_CHILD_PROFILE = 'ASYNC_SWITCH_TO_CHILD_PROFILE';
export const switchToChildProfile = ({
  targetProfileId,
  credentialType = 'pin',
  credential = null,
  unsetPin = true,
}) => async (dispatch, getState) => {
  const switchResponse = await dispatch(
    switchProfile({
      targetProfileId,
      credentialType,
      credential,
      successAction: ({ userId }) =>
        onboardingActions.setSelectedProfile(userId),
      unsetPin,
    }),
  );
  dispatch(actions.setParentMode(false));

  const ageGroup = getCurrentProfileAgeGroup(getState());
  const ageGroupValue = ageGroupAnalyticsValues[ageGroup];
  firestoreApi.setUserId(targetProfileId);
  firestoreApi.updateDefaultUserProperties({
    age_group: ageGroupValue,
  });
  return switchResponse;
};

/*
 * These actions switch the application state as needed without replacing the
 * Vindicia auth information
 * */
export const LOGICAL_SWITCH_TO_CHILD_PROFILE =
  'LOGICAL_SWITCH_TO_CHILD_PROFILE';
export const logicalSwitchToChildProfile = targetProfileId => (
  dispatch,
  getState,
) => {
  dispatch(actions.setPin(null));

  const targetProfileExists = getProfiles(getState()).some(
    ({ id }) => targetProfileId === id,
  );
  if (!targetProfileExists) {
    console.warn('Target profile not found in profile list');
    dispatch(onboardingActions.setSelectedProfile(null));
    dispatch(actions.setParentMode(true));
    return;
  }
  dispatch(onboardingActions.setSelectedProfile(targetProfileId));
  dispatch(actions.setParentMode(false));

  const ageGroup = getCurrentProfileAgeGroup(getState());
  const ageGroupValue = ageGroupAnalyticsValues[ageGroup];
  firestoreApi.setUserId(targetProfileId);
  firestoreApi.updateDefaultUserProperties({
    age_group: ageGroupValue,
  });
};

// User authentication
export const ASYNC_SESSION_START = 'ASYNC_SESSION_START';
export const sessionStart = ({ authnIdentifier, credential }) =>
  createVindiciaAction(
    ASYNC_SESSION_START,
    apiIds.IDENTITY_API,
    'login',
    ({ userId }) => {
      if (!userId) {
        // TODO: Replace with Vindicia error, and create error component to consume it in Account Creation
        throw new Error('Onboarding is not complete');
      }
      return actions.setParentUserId(userId);
    },
  )({
    body: {
      authnIdentifier,
      credential,
    },
    query: {
      rememberMe: true,
    },
  });

export const ASYNC_SESSION_END = 'ASYNC_SESSION_END';
export const sessionEnd = () =>
  createVindiciaAction(ASYNC_SESSION_END, apiIds.IDENTITY_API, 'logout')({});

export const LOGOUT = 'LOGOUT';
export const logout = () => async dispatch => {
  await dispatch(sessionEnd());
  await dispatch(actions.clearSessionData());
  firestoreApi.setUserId(null);
  firestoreApi.updateDefaultUserProperties({
    group_id: null,
    parent_id: null,
    age_group: null,
  });
  dispatch(userActions.logoutUser());
  dispatch(onboardingActions.resetState());
  dispatch(actions.resetState());
};

export const LOGIN = 'LOGIN';
export const login = ({ email, credential }) => async (dispatch, getState) => {
  try {
    const loginResponse = await dispatch(
      sessionStart({ authnIdentifier: email, credential }),
    );
    await dispatch(loadGroup());
    await dispatch(loadProfiles());
    const savedTvCode = getTvCode(getState());
    if (!isTouchDevice()) {
      const [{ id }, ...restProfiles] = getProfiles(getState());
      if (restProfiles.length === 0 && !savedTvCode) {
        await dispatch(logicalSwitchToChildProfile(id));
      } else {
        dispatch(actions.setParentMode(true));
      }
    } else {
      dispatch(actions.setParentMode(true));
    }
    dispatch(userActions.loginReceived());

    const groupId = getGroupId(getState());
    const parentUserId = getParentUserId(getState());
    firestoreApi.updateDefaultUserProperties({
      group_id: groupId,
      parent_id: parentUserId,
    });

    await dispatch(
      actions.saveSessionData({
        encAuthnIdentifier: email,
        encCredential: credential,
      }),
    );

    return loginResponse;
  } catch (e) {
    if (e instanceof VindiciaError) {
      return dispatch(actions.setError(LOGIN, e.payload));
    }
    throw e;
  }
};

// Action to start guess mode
export const guestMode = ({ age }) => async (dispatch, getState) => {
  await dispatch(actions.setPin(null));
  await dispatch(actions.setParentMode(false));
  await dispatch(userActions.enableGuestMode());
  const ageGroupConfig = getAgeGroupConfig(getState());
  const {
    noRegistrationConfig: { analytics, name },
  } = ageGroupConfig[`configurations${age}Up`];
  // get gest account from local storage or create a new one
  // const guestAccount = getGuestProfile(age) || newGestProfile(age);
  const guestAccount = newGestProfile({ age, name, id: analytics.userId });

  await dispatch(onboardingActions.setSelectedProfile(guestAccount.id));
  await dispatch(
    onboardingActions.populateProfiles([
      {
        user: {
          type: 'user.SoftProfile',
          id: guestAccount.id,
          displayName: guestAccount.name,
          avatarUrl: guestAccount.avatar,
        },
      },
    ]),
  );
  await dispatch(onboardingActions.populateProfileCustomData(guestAccount));

  const ageGroup = getCurrentProfileAgeGroup(getState());
  const ageGroupValue = ageGroupAnalyticsValues[ageGroup];
  // guest Mode analytics, value is set to "UNKNOWN"
  firestoreApi.setUserId(analytics.userId);
  firestoreApi.updateDefaultUserProperties({
    group_id: analytics.groupId,
    parent_id: analytics.parentId,
    age_group: ageGroupValue,
  });
  dispatch(userActions.loginReceived());
};
const newGestProfile = ({ age, name, id }) => {
  // Get a different month than current (1-12)
  const month = ((new Date().getMonth() + 2) % 12) + 1;
  return {
    ...customUserDataKeys,
    id,
    name,
    avatar: `https://sensical-platform.imgix.net/production/profile/avatars/${age}.png`,
    // use here the image age png when available
    age,
    birthdate: aggregateBirthdate(age, month),
    birthdayMonth: month,
    lastUpdateTime: 0,
  };
};

// Parent account management
export const ASYNC_ADD_ACCOUNT_CUSTOM_DATA = 'ASYNC_ADD_ACCOUNT_CUSTOM_DATA';
export const addAccountCustomData = (
  userId,
  { marketingInfo, learningReports = true },
) =>
  createVindiciaAction(
    ASYNC_ADD_ACCOUNT_CUSTOM_DATA,
    apiIds.CUSTOM_DATA_API,
    CREATE_CHILD_CUSTOM_DATA,
  )({
    params: {
      sonUserId: userId,
    },
    body: {
      marketingInfo,
      learningReports,
      't&c': true,
    },
  });

export const ASYNC_UPDATE_ACCOUNT_CUSTOM_DATA =
  'ASYNC_UPDATE_ACCOUNT_CUSTOM_DATA';
export const updateAccountCustomData = (
  userId,
  { marketingInfo = false, learningReports = false },
) =>
  createVindiciaAction(
    ASYNC_UPDATE_ACCOUNT_CUSTOM_DATA,
    apiIds.CUSTOM_DATA_API,
    UPDATE_CHILD_CUSTOM_DATA,
  )({
    params: {
      sonUserId: userId,
    },
    body: {
      marketingInfo,
      learningReports,
    },
  });

export const ASYNC_UPDATE_ACCOUNT_DATA = 'ASYNC_UPDATE_ACCOUNT_DATA';
export const updateAccountData = (userId, { displayName, pin = null }) =>
  createVindiciaAction(
    ASYNC_UPDATE_ACCOUNT_DATA,
    apiIds.IDENTITY_API,
    'updateUserAttributes',
  )({
    params: {
      userId,
    },
    body: {
      ...(displayName ? { displayName } : undefined),
      ...(pin ? { pin } : undefined),
    },
  });

export const ASYNC_UPDATE_AUTHN_IDENTIFIER = 'ASYNC_UPDATE_AUTHN_IDENTIFIER';
export const updateAuthnIdentifier = (
  oldAuthnIdentifier,
  newAuthnIdentifier,
) => async (dispatch, getState) => {
  try {
    await dispatch(
      createVindiciaAction(
        ASYNC_UPDATE_AUTHN_IDENTIFIER,
        apiIds.IDENTITY_API,
        'updatePhone',
        ({ processId }) => actions.setProcessId(processId),
      )({}),
    );
    const processId = getProcessId(getState());
    await dispatch(
      processStep({
        processId,
        parameters: {
          oldAuthnIdentifier,
          newAuthnIdentifier,
        },
      }),
    );
  } catch (e) {
    throw new VindiciaError({
      errorMsg: { customError: vindiciaErrors.editPhoneError },
    });
  }
};

export const ASYNC_UPDATE_PASSWORD = 'ASYNC_UPDATE_PASSWORD';
export const updatePassword = (oldPassword, newPassword) => async (
  dispatch,
  getState,
) => {
  await dispatch(
    createVindiciaAction(
      ASYNC_UPDATE_PASSWORD,
      apiIds.IDENTITY_API,
      'updatePassword',
      ({ processId }) => actions.setProcessId(processId),
    )({}),
  );
  const processId = getProcessId(getState());
  await dispatch(
    processStep({
      processId,
      parameters: {
        oldPassword,
        newPassword,
      },
    }),
  );
};

export const UPDATE_ACCOUNT = 'UPDATE_ACCOUNT';
export const updateAccount = ({
  displayName,
  pin,
  oldPassword,
  newPassword,
  mobileNumber: newMobileNumber,
  marketingInfo,
  learningReports,
}) => async (dispatch, getState) => {
  try {
    const parentId = getParentUserId(getState());
    const pAccountUpdates = Promise.all([
      dispatch(updateAccountData(parentId, { displayName, pin })),
      dispatch(
        updateAccountCustomData(parentId, { marketingInfo, learningReports }),
      ),
    ]);
    const { mobileNumber: oldMobileNumber } = getParentAccount(getState());
    const pPhoneUpdate =
      newMobileNumber && newMobileNumber !== oldMobileNumber
        ? dispatch(updateAuthnIdentifier(oldMobileNumber, newMobileNumber))
        : Promise.resolve();
    const pPasswordUpdate = newPassword
      ? dispatch(updatePassword(oldPassword, newPassword))
      : Promise.resolve();
    return await Promise.all([
      pPhoneUpdate,
      pPasswordUpdate.then(async result => {
        newPassword &&
          (await dispatch(
            actions.saveSessionData({
              encCredential: newPassword,
            }),
          ));
        return result;
      }),
      pAccountUpdates.catch(() => {
        throw new VindiciaError({
          errorMsg: { customError: vindiciaErrors.accountUpdateError },
        });
      }),
    ]);
  } catch (e) {
    if (e instanceof VindiciaError) {
      dispatch(actions.setError(UPDATE_ACCOUNT, e.payload));
    }
    throw e;
  }
};

export const ASYNC_ONBOARD_USER = 'ASYNC_ONBOARD_USER';
export const onboardWithEmail = ({ email, credential, pin }, successAction) =>
  createVindiciaAction(
    ASYNC_ONBOARD_USER,
    apiIds.IDENTITY_API,
    'onboardUser',
    successAction,
  )({
    body: {
      parameters: {
        email,
        credential,
        pin,
      },
    },
  });

export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const createAccount = (
  email,
  credential,
  pin,
  marketingOptin = false,
) => async (dispatch, getState) => {
  try {
    await dispatch(sessionEnd());
    // TODO: Refactor success action to onboard user call
    await dispatch(
      onboardWithEmail(
        { email, credential, pin },
        ({ userId, output: { pkat } }) => () =>
          dispatch(actions.setPkat(pkat)) &&
          dispatch(actions.setParentUserId(userId)),
      ),
    );
    await dispatch(loadGroup());
    const userId = getParentUserId(getState());
    const pAddAccountCustomData = dispatch(
      addAccountCustomData(userId, { marketingInfo: marketingOptin }),
    );
    const pAddUnsavedProfiles = dispatch(addUnsavedProfiles());
    const onboardingEffectsResult = await Promise.all([
      pAddAccountCustomData,
      pAddUnsavedProfiles,
    ]);
    await dispatch(actions.setOnboardingSuccess());
    return onboardingEffectsResult;
  } catch (e) {
    if (e instanceof VindiciaError) {
      dispatch(actions.setError(CREATE_ACCOUNT, e.payload));
    }
    throw e;
  }
};

export const ASYNC_DELETE_ACCOUNT = 'ASYNC_DELETE_ACCOUNT';
export const deleteAccount = userId =>
  createVindiciaAction(
    ASYNC_DELETE_ACCOUNT,
    apiIds.IDENTITY_API,
    'deleteParentAccount',
  )({
    params: {
      userId,
    },
    body: {
      status: 'suspended',
    },
  });

// User
export const ASYNC_GET_CURRENT_USER = 'ASYNC_GET_CURRENT_USER';
export const getUserCall = successAction =>
  createVindiciaAction(
    ASYNC_GET_CURRENT_USER,
    apiIds.IDENTITY_API,
    'getCurrentUser',
    successAction,
  )({});

export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const getUser = () => async (dispatch, getState) => {
  try {
    const currentProfileId = getCurrentProfileId(getState());
    let currentUserId = null;
    let currentUserType = null;
    const getUserResponse = await dispatch(
      getUserCall(({ id, type }) => async () => {
        currentUserId = id;
        currentUserType = type;
      }),
    );
    await dispatch(loadGroup());
    await dispatch(loadProfiles()).catch(() => {});
    if (currentUserType === 'user.SoftProfile') {
      // Legacy fallback for users logged in with child profile server-side
      await dispatch(logicalSwitchToChildProfile(currentUserId));
    } else if (currentProfileId) {
      await dispatch(logicalSwitchToChildProfile(currentProfileId));
    } else {
      dispatch(actions.setParentMode(true));
    }
    dispatch(userActions.loginReceived());
    // there is a case where groupId is null in logicalSwitchToChildProfile, so set group id here too
    const groupId = getGroupId(getState());
    const parentUserId = getParentUserId(getState());
    firestoreApi.updateDefaultUserProperties({
      group_id: groupId,
      parent_id: parentUserId,
    });
    await dispatch(userAutoloaded(true));
    return getUserResponse;
  } catch (e) {
    await dispatch(userAutoloaded(false));
    // TODO: Handle errors
  }
};

// Token redemption
export const ASYNC_SUBMIT_TOKEN = 'ASYNC_SUBMIT_TOKEN';
export const submitToken = (token, successAction) =>
  createVindiciaAction(
    ASYNC_SUBMIT_TOKEN,
    apiIds.IDENTITY_API,
    'sessionToken',
    successAction,
  )({
    query: {
      value: token,
    },
  });

export const CONFIRM_PASSWORD = 'CONFIRM_PASSWORD';
export const confirmPassword = (token, newPassword) => async (
  dispatch,
  getState,
) => {
  try {
    await dispatch(sessionEnd());
    await dispatch(
      submitToken(token, ({ processId }) => () => {
        return dispatch(actions.setProcessId(processId));
      }),
    );
    const processId = getProcessId(getState());
    return await dispatch(
      processStep({
        processId,
        parameters: { newPassword },
      }),
    );
  } catch (e) {
    if (e instanceof VindiciaError) {
      dispatch(
        actions.setError(CONFIRM_PASSWORD, {
          errorMsg: { customError: vindiciaErrors.passwordResetError },
        }),
      );
    }
    throw e;
  }
};

export const ASYNC_CONFIRM_TOKEN = 'ASYNC_CONFIRM_TOKEN';
export const confirmToken = token => async dispatch => {
  try {
    await dispatch(sessionEnd());
    return await dispatch(
      createVindiciaAction(
        ASYNC_CONFIRM_TOKEN,
        apiIds.IDENTITY_API,
        'sessionToken',
        ({
          output: {
            activatedAuthenticationIdentifier: { type, value },
          },
        }) =>
          type.toLowerCase() === 'email'
            ? actions.setVerificationEmail(value)
            : () => {},
      )({
        query: {
          value: token,
        },
      }),
    );
  } catch (e) {
    dispatch(
      actions.setError(ASYNC_CONFIRM_TOKEN, {
        errorMsg: { customError: vindiciaErrors.emailValidationError },
      }),
    );
    throw e;
  }
};

// Forgot password
export const ASYNC_RECOVER_PASSWORD = 'ASYNC_RECOVER_PASSWORD';
export const recoverPassword = email => async (dispatch, getState) => {
  try {
    await dispatch(sessionEnd());
    await dispatch(
      createVindiciaAction(
        ASYNC_RECOVER_PASSWORD,
        apiIds.IDENTITY_API,
        'passwordRecovery',
        ({ processId }) => actions.setProcessId(processId),
      )({}),
    );
    const processId = getProcessId(getState());
    await dispatch(
      processStep({
        processId,
        parameters: {
          authnIdentifier: email,
        },
      }),
    );
  } catch (e) {
    if (e instanceof VindiciaError) {
      dispatch(actions.setError(ASYNC_RECOVER_PASSWORD, e.payload));
    }
    throw e;
  }
};

const recreateSession = () => async (dispatch, getState) => {
  const { encAuthnIdentifier, encCredential, encData } =
    getSessionData(getState()) || {};

  if (!(encAuthnIdentifier && encCredential && encData)) {
    await terminateCurrentSession(dispatch);
    throw new Error('No encrypted data found');
  }

  try {
    const authnIdentifier = decrypt(encAuthnIdentifier, encData);
    const credential = decrypt(encCredential, encData);
    const sessionStartResponse = await dispatch(
      sessionStart({ authnIdentifier, credential }),
    );
    await dispatch(actions.clearSessionData());
    await dispatch(
      actions.saveSessionData({
        encAuthnIdentifier: authnIdentifier,
        encCredential: credential,
      }),
    );
    return sessionStartResponse;
  } catch (error) {
    await terminateCurrentSession(dispatch);
    throw error;
  }
};

const terminateCurrentSession = async dispatch => {
  await dispatch(userActions.setLogoutRedirectPath(authFlowRoutes.SIGN_IN));
  await dispatch(logout());
};

export const ASYNC_LOAD_PROFILE_DISPLAYED_MODALS =
  'ASYNC_LOAD_PROFILE_DISPLAYED_MODALS';
export const loadCustomDataDisplayedModals = () => async (
  dispatch,
  getState,
) => {
  const profileId = getCurrentProfileId(getState());
  try {
    const getResponse = await dispatch(
      createVindiciaAction(
        ASYNC_LOAD_PROFILE_DISPLAYED_MODALS,
        apiIds.CUSTOM_DATA_API,
        READ_CHILD_CUSTOM_DATA,
        actions.saveCustomDataDisplayedModals,
      )({
        path: `/${customDataFieldTypes.DISPLAYED_MODALS}`,
        params: {
          sonUserId: profileId,
        },
      }),
    );
    return getResponse;
  } catch (e) {
    if (e instanceof VindiciaError) {
      dispatch(
        actions.setError(ASYNC_LOAD_PROFILE_DISPLAYED_MODALS, e.payload),
      );
      if (e?.payload?.errorMsg?.statusCode === 404) {
        dispatch(actions.saveCustomDataDisplayedModals([]));
      }
    }
  }
};

export const ASYNC_DELETE_DISPLAYED_MODALS = 'ASYNC_LOOKUP_DISPLAYED_MODALS';
export const ASYNC_DELETE_PROFILE_DISPLAYED_MODALS =
  'ASYNC_DELETE_PROFILE_DISPLAYED_MODALS';
export const clearCustomDataDisplayedModals = () => async (
  dispatch,
  getState,
) => {
  if (isParentProfile(getState)) {
    return;
  }

  const profileId = getCurrentProfileId(getState());

  try {
    const getResponse = await dispatch(
      createVindiciaAction(
        ASYNC_DELETE_DISPLAYED_MODALS,
        apiIds.CUSTOM_DATA_API,
        DELETE_CHILD_CUSTOM_DATA,
        actions.deleteCustomDataDisplayedModals,
      )({
        path: `/${customDataFieldTypes.DISPLAYED_MODALS}`,
        params: {
          sonUserId: profileId,
        },
      }),
    );
    return getResponse;
  } catch (e) {
    if (e instanceof VindiciaError) {
      dispatch(
        actions.setError(ASYNC_DELETE_PROFILE_DISPLAYED_MODALS, e.payload),
      );
    }
  }
};

export const ASYNC_LOOKUP_DISPLAYED_MODALS = 'ASYNC_LOOKUP_DISPLAYED_MODALS';
export const ASYNC_UPDATE_PROFILE_DISPLAYED_MODALS =
  'ASYNC_UPDATE_PROFILE_DISPLAYED_MODALS';
export const updateCustomDataDisplayedModals = data => async (
  dispatch,
  getState,
) => {
  if (isParentProfile(getState)) {
    return;
  }

  const profileId = getCurrentProfileId(getState());

  try {
    const getResponse = await dispatch(
      createVindiciaAction(
        ASYNC_LOOKUP_DISPLAYED_MODALS,
        apiIds.CUSTOM_DATA_API,
        READ_CHILD_CUSTOM_DATA,
        payload => {
          dispatch(
            createVindiciaAction(
              ASYNC_UPDATE_PROFILE_DISPLAYED_MODALS,
              apiIds.CUSTOM_DATA_API,
              payload?.length
                ? UPDATE_CHILD_CUSTOM_DATA
                : CREATE_CHILD_CUSTOM_DATA,
            )({
              path: `/${customDataFieldTypes.DISPLAYED_MODALS}`,
              params: {
                sonUserId: profileId,
              },
              body: data,
            }),
          );

          return {
            type: ASYNC_UPDATE_PROFILE_DISPLAYED_MODALS,
          };
        },
      )({
        path: `/${customDataFieldTypes.DISPLAYED_MODALS}`,
        params: {
          sonUserId: profileId,
        },
      }),
    );
    return getResponse;
  } catch (e) {
    if (e instanceof VindiciaError) {
      dispatch(
        actions.setError(ASYNC_UPDATE_PROFILE_DISPLAYED_MODALS, e.payload),
      );
      if (e?.payload?.errorMsg?.statusCode === 404) {
        // No displayedModals entry. Do a post.
        return dispatch(
          createVindiciaAction(
            ASYNC_UPDATE_PROFILE_DISPLAYED_MODALS,
            apiIds.CUSTOM_DATA_API,
            CREATE_CHILD_CUSTOM_DATA,
          )({
            path: `/${customDataFieldTypes.DISPLAYED_MODALS}`,
            params: {
              sonUserId: profileId,
            },
            body: data,
          }),
        );
      }
    }
  }
};

export const ASYNC_LOAD_PROFILE_VIDEOS_BLOCKED =
  'ASYNC_LOAD_PROFILE_VIDEOS_BLOCKED';
export const loadCustomDataVideosBlocked = profileId => async dispatch => {
  try {
    const getResponse = await dispatch(
      createVindiciaAction(
        ASYNC_LOAD_PROFILE_VIDEOS_BLOCKED,
        apiIds.CUSTOM_DATA_API,
        READ_CHILD_CUSTOM_DATA,
        actions.saveCustomDataVideosBlocked,
      )({
        path: `/${customDataFieldTypes.VIDEOS_BLOCKED}`,
        params: {
          sonUserId: profileId,
        },
      }),
    );
    return getResponse;
  } catch (e) {
    if (e instanceof VindiciaError) {
      dispatch(actions.setError(ASYNC_LOAD_PROFILE_VIDEOS_BLOCKED, e.payload));
      if (e?.payload?.errorMsg?.statusCode === 404) {
        dispatch(actions.saveCustomDataVideosBlocked([]));
      }
    }
  }
};

export const ASYNC_LOOKUP_VIDEOS_BLOCKED = 'ASYNC_LOOKUP_VIDEOS_BLOCKED';
export const ASYNC_UPDATE_VIDEOS_BLOCKED = 'ASYNC_UPDATE_VIDEOS_BLOCKED';
export const updateVideosBlockedById = (id, toAdd, item) => async (
  dispatch,
  getState,
) => {
  const profileId = getCurrentProfileId(getState());

  try {
    const getResponse = await dispatch(
      createVindiciaAction(
        ASYNC_LOOKUP_VIDEOS_BLOCKED,
        apiIds.CUSTOM_DATA_API,
        READ_CHILD_CUSTOM_DATA,
        payload => {
          if (toAdd && payload.length === 0) {
            dispatch(
              createVindiciaAction(
                ASYNC_UPDATE_VIDEOS_BLOCKED,
                apiIds.CUSTOM_DATA_API,
                CREATE_CHILD_CUSTOM_DATA,
                actions.saveCustomDataVideosBlocked,
              )({
                path: `/${customDataFieldTypes.VIDEOS_BLOCKED}`,
                params: {
                  sonUserId: profileId,
                },
                body: {
                  ...item,
                },
              }),
            );
            dispatch(actions.addSingleCustomDataVideoBlocked(item));
          } else if (!toAdd && payload.length > 0) {
            dispatch(
              createVindiciaAction(
                ASYNC_UPDATE_VIDEOS_BLOCKED,
                apiIds.CUSTOM_DATA_API,
                DELETE_CHILD_CUSTOM_DATA,
                actions.saveCustomDataVideosBlocked,
              )({
                path: `/${customDataFieldTypes.VIDEOS_BLOCKED}`,
                params: {
                  sonUserId: profileId,
                },
                query: {
                  filter: `id eq "${id}"`,
                },
              }),
            );
            dispatch(actions.deleteSingleCustomDataVideoBlocked(id));
          }

          return {
            type: ASYNC_UPDATE_VIDEOS_BLOCKED,
          };
        },
      )({
        path: `/${customDataFieldTypes.VIDEOS_BLOCKED}`,
        params: {
          sonUserId: profileId,
        },
        query: {
          filter: `id eq "${id}"`,
        },
      }),
    );

    return getResponse;
  } catch (e) {
    if (e?.payload?.errorMsg?.statusCode === 404) {
      if (toAdd) {
        dispatch(
          createVindiciaAction(
            ASYNC_UPDATE_VIDEOS_BLOCKED,
            apiIds.CUSTOM_DATA_API,
            UPDATE_CHILD_CUSTOM_DATA,
          )({
            params: {
              sonUserId: profileId,
            },
            body: {
              videosBlocked: [item],
            },
          }),
        );

        dispatch(actions.addSingleCustomDataVideoBlocked(item));

        return {
          type: ASYNC_UPDATE_VIDEOS_BLOCKED,
        };
      }
    }

    if (e instanceof VindiciaError) {
      dispatch(actions.setError(ASYNC_UPDATE_VIDEOS_BLOCKED, e.payload));
    }
  }
};

export const ASYNC_LOAD_PROFILE_SHOWS_BLOCKED =
  'ASYNC_LOAD_PROFILE_SHOWS_BLOCKED';
export const loadCustomDataShowsBlocked = profileId => async dispatch => {
  try {
    const getResponse = await dispatch(
      createVindiciaAction(
        ASYNC_LOAD_PROFILE_SHOWS_BLOCKED,
        apiIds.CUSTOM_DATA_API,
        READ_CHILD_CUSTOM_DATA,
        actions.saveCustomDataShowsBlocked,
      )({
        path: `/${customDataFieldTypes.SHOWS_BLOCKED}`,
        params: {
          sonUserId: profileId,
        },
      }),
    );
    return getResponse;
  } catch (e) {
    if (e instanceof VindiciaError) {
      dispatch(actions.setError(ASYNC_LOAD_PROFILE_SHOWS_BLOCKED, e.payload));
      if (e?.payload?.errorMsg?.statusCode === 404) {
        dispatch(actions.saveCustomDataShowsBlocked([]));
      }
    }
  }
};

export const ASYNC_LOOKUP_SHOWS_BLOCKED = 'ASYNC_LOOKUP_SHOWS_BLOCKED';
export const ASYNC_UPDATE_SHOWS_BLOCKED = 'ASYNC_UPDATE_SHOWS_BLOCKED';
export const updateShowsBlockedById = (id, toAdd, item) => async (
  dispatch,
  getState,
) => {
  const profileId = getCurrentProfileId(getState());

  try {
    const getResponse = await dispatch(
      createVindiciaAction(
        ASYNC_LOOKUP_SHOWS_BLOCKED,
        apiIds.CUSTOM_DATA_API,
        READ_CHILD_CUSTOM_DATA,
        payload => {
          if (toAdd && payload.length === 0) {
            dispatch(
              createVindiciaAction(
                ASYNC_UPDATE_SHOWS_BLOCKED,
                apiIds.CUSTOM_DATA_API,
                CREATE_CHILD_CUSTOM_DATA,
                actions.saveCustomDataShowsBlocked,
              )({
                path: `/${customDataFieldTypes.SHOWS_BLOCKED}`,
                params: {
                  sonUserId: profileId,
                },
                body: {
                  ...item,
                },
              }),
            );
            dispatch(actions.addSingleCustomDataShowBlocked(item));
          } else if (!toAdd && payload.length > 0) {
            dispatch(
              createVindiciaAction(
                ASYNC_UPDATE_SHOWS_BLOCKED,
                apiIds.CUSTOM_DATA_API,
                DELETE_CHILD_CUSTOM_DATA,
                actions.saveCustomDataShowsBlocked,
              )({
                path: `/${customDataFieldTypes.SHOWS_BLOCKED}`,
                params: {
                  sonUserId: profileId,
                },
                query: {
                  filter: `id eq "${id}"`,
                },
              }),
            );
            dispatch(actions.deleteSingleCustomDataShowBlocked(id));
          }

          return {
            type: ASYNC_UPDATE_SHOWS_BLOCKED,
          };
        },
      )({
        path: `/${customDataFieldTypes.SHOWS_BLOCKED}`,
        params: {
          sonUserId: profileId,
        },
        query: {
          filter: `id eq "${id}"`,
        },
      }),
    );
    return getResponse;
  } catch (e) {
    if (e?.payload?.errorMsg?.statusCode === 404) {
      if (toAdd) {
        dispatch(
          createVindiciaAction(
            ASYNC_UPDATE_SHOWS_BLOCKED,
            apiIds.CUSTOM_DATA_API,
            UPDATE_CHILD_CUSTOM_DATA,
          )({
            params: {
              sonUserId: profileId,
            },
            body: {
              showsBlocked: [item],
            },
          }),
        );

        dispatch(actions.addSingleCustomDataShowBlocked(item));

        return {
          type: ASYNC_UPDATE_SHOWS_BLOCKED,
        };
      }
    }

    if (e instanceof VindiciaError) {
      dispatch(actions.setError(ASYNC_UPDATE_SHOWS_BLOCKED, e.payload));
    }
  }
};
