export const timePeriodFilters = {
  parentalZoneWeekly: {
    id: 'parentalZoneWeekly',
    label: '7 days',
    labelLearnedText: 'week',
    displayName: 'Last 7 days',
  },
  parentalZoneMonthly: {
    id: 'parentalZoneMonthly',
    label: '30 days',
    labelLearnedText: 'month',
    displayName: 'Last 30 days',
  },
};
