import { createActions } from 'redux-actions';

const OPEN_MODAL = 'OPEN_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';
const INPUT_FOCUS = 'INPUT_FOCUS';

const actionOptions = {
  prefix: 'app/modal',
};

export const actions = createActions(
  OPEN_MODAL,
  CLOSE_MODAL,
  INPUT_FOCUS,
  actionOptions,
);
