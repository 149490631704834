import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Page } from '@accedo/vdkweb-tv-ui';
import { FocusButton, FocusDiv } from '#/widgets/ui';
import LoginRedirect from '../LoginRedirect/LoginRedirect';

import authStyles from '../tvauthflow.scss';
import styles from './SignUp.scss';

import { signUp } from '#/config/strings';
import { SIGNUP } from '#/utils/navigationHelper';
import {
  getNewActivationCode,
  checkActivationCode,
  getUser,
  ASYNC_VERIFY_GET_ACTIVATION_CODE,
  ASYNC_IS_CODE_VALIDATED,
  actions,
} from '#/redux/modules/identity/actions';
import {
  getSensicalTvConfig,
  getApiOidcEndpoints,
} from '#/redux/modules/accedoOne/selectors';
import { setNativeFocus } from '#/utils/accessibility';

import BluePhone from '#/static/images/icons/BluePhone.png';

const NEW_CODE = 'Get new code';
const POLL_INTERVAL = 5000;

const SignUp = () => {
  const dispatch = useDispatch();
  const { extraParams } =
    useSelector(state => getApiOidcEndpoints(state)) || {};
  const { qrCodeUri } = useSelector(state => getSensicalTvConfig(state)) || {};
  const [activationCode, setActivationCode] = useState(null);
  const [deviceCode, setDeviceCode] = useState(null);

  const getCode = async () => {
    if (extraParams?.oidcClientId !== undefined) {
      await dispatch(
        getNewActivationCode({ clientId: extraParams?.oidcClientId }, res => {
          setActivationCode(res.user_code);
          setDeviceCode(res.device_code);
          return {
            type: ASYNC_VERIFY_GET_ACTIVATION_CODE,
          };
        }),
      );
    }
  };

  const checkCode = async () => {
    if (extraParams?.oidcClientId !== undefined && deviceCode !== null) {
      await dispatch(
        checkActivationCode(
          { clientId: extraParams?.oidcClientId, deviceCode },
          async res => {
            if (res?.access_token) {
              dispatch(actions.setAccessToken(res.access_token));
              dispatch(actions.setRefreshToken(res.refresh_token));
              await dispatch(getUser());
            }

            return {
              type: ASYNC_IS_CODE_VALIDATED,
            };
          },
        ),
      );
    }
  };

  useEffect(() => {
    getCode();
  }, [extraParams]);

  useEffect(() => {
    const loginInterval = setInterval(() => {
      checkCode();
    }, POLL_INTERVAL);
    return () => clearInterval(loginInterval);
  }, [extraParams, deviceCode]);

  return (
    <Page
      className={authStyles.background}
      autofocus
      nav={{ id: SIGNUP.PAGE, forwardFocus: SIGNUP.NEWCODE_BUTTON }}
    >
      <LoginRedirect />
      <FocusDiv
        className={authStyles.content}
        nav={{ id: SIGNUP.CONTAINER }}
        ariaLabel={`${signUp.title}, ${signUp.activate}, ${signUp.enterCode}, ${NEW_CODE}`}
      >
        <div className={authStyles.left}>
          <h1 className={authStyles.pageTitle}>{signUp.title}</h1>
          <h6 className={authStyles.signUpActivate}>{signUp.activate}</h6>
          <h6 className={authStyles.pageBody}>{signUp.enterCode}</h6>
          <div className={styles.codeDiv}>
            <h1>{activationCode}</h1>
            <FocusButton
              isTv
              primary
              className={styles.newCodeBtn}
              onClick={() => {
                getCode();
                setNativeFocus();
              }}
              nav={{ id: SIGNUP.NEWCODE_BUTTON, parent: SIGNUP.PAGE }}
              ariaLabel={NEW_CODE}
              aria-describedby={`${SIGNUP.CONTAINER}`}
            >
              {NEW_CODE}
            </FocusButton>
          </div>
        </div>
        <div className={authStyles.right}>
          <img className={styles.qrCode} src={qrCodeUri} alt="QR Code" />
          <img className={authStyles.phone} src={BluePhone} alt="Blue Phone" />
        </div>
      </FocusDiv>
    </Page>
  );
};

export default SignUp;
