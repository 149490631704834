import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import DOMPurify from 'dompurify';
import classNames from 'classnames';
import { getVideoCaptionStyles } from '#/redux/modules/appPlayer/selectors';
import useLatest from '#/hooks/useLatest';
import { isLowEndDevice } from '#/utils/componentStyleConfig';
import { playerConstants } from '#/config/constants';
import {
  vw,
  getBackground,
  getTextColor,
  isDefaultValue,
  getEdgesStyle,
  getWindowBackground,
  isFlashing,
  OPACITY_VALUES,
  getActiveCue,
} from '#/utils/closedCaptionUtils';
import parseVTT from '#/utils/parseVTT';

import styles from './closedCaption.scss';

const { CC_DEFAULT_STYLES } = playerConstants;

const ClosedCaption = ({
  adDuration,
  currentTime,
  captionUrl,
  isHidden,
  parentStyles,
}) => {
  const [cc, setCC] = useState(null);
  const latestCC = useLatest(cc);

  const { ccStyles } = useSelector(getVideoCaptionStyles) || {};

  const [background, setBackground] = useState();
  const [windowBackground, setWindowBackground] = useState();
  const [edgesStyle, setEdgesStyle] = useState();
  const [textColor, setTextColor] = useState();

  const fetchCC = useCallback(async ccUrl => {
    setCC(await parseVTT(ccUrl));
  }, []);

  const getText = useCallback(time => {
    let adjustedTime = time;
    // Time adjusted based on Ad duration so the video time is properly aligned with CC
    if (adDuration > 0) {
      const prerollAdDuration = adDuration / 1000;

      if (prerollAdDuration > time) {
        return '';
      }
      adjustedTime -= prerollAdDuration;
    }

    return (
      latestCC.current &&
      (
        latestCC.current.find(
          cue => adjustedTime >= cue.startTime && adjustedTime <= cue.endTime,
        ) || {}
      ).text
    );
  }, []);

  useEffect(() => {
    if (captionUrl) {
      fetchCC(captionUrl);
    }
  }, [captionUrl]);

  useEffect(() => {
    setWindowBackground(getWindowBackground(ccStyles, CC_DEFAULT_STYLES));
  }, [ccStyles?.window_color, ccStyles?.window_opacity]);

  useEffect(() => {
    setBackground(getBackground(ccStyles, CC_DEFAULT_STYLES));
  }, [ccStyles?.background_color, ccStyles?.background_opacity]);

  useEffect(() => {
    setTextColor(getTextColor(ccStyles, CC_DEFAULT_STYLES));
  }, [ccStyles?.text_color, ccStyles?.text_opacity]);

  useEffect(() => {
    setEdgesStyle(getEdgesStyle(ccStyles, CC_DEFAULT_STYLES));
  }, [ccStyles?.text_edges, ccStyles?.text_edges_color]);

  const text = cc && currentTime ? getText(currentTime) : getActiveCue();

  return (
    <div
      className={classNames(
        styles.container,
        {
          [styles.isHidden]: isHidden,
          [styles.animation]: !isLowEndDevice(),
        },
        parentStyles,
      )}
    >
      <div className={styles.textTrack}>
        <div
          className={classNames(styles.window, {
            [styles.flashingBackground]: isFlashing(ccStyles?.window_opacity),
          })}
          style={{
            backgroundColor: windowBackground,
          }}
        >
          <div
            className={classNames(styles.cc, {
              [styles.textTransparent]:
                ccStyles?.text_opacity?.toLowerCase() ===
                OPACITY_VALUES.TRANSPARENT,
              [styles.flashingText]:
                ccStyles && isFlashing(ccStyles?.text_opacity),
              [styles.flashingBackground]:
                ccStyles && isFlashing(ccStyles?.background_opacity),
            })}
            style={{
              backgroundColor: background,
              color: textColor,
              textShadow: edgesStyle,
              fontSize:
                ccStyles && !isDefaultValue(ccStyles?.text_size)
                  ? ccStyles?.text_size
                  : vw(CC_DEFAULT_STYLES.textSize),
              fontFamily:
                ccStyles && !isDefaultValue(ccStyles?.text_style)
                  ? ccStyles?.text_style
                  : CC_DEFAULT_STYLES.textStyle,
            }}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(text),
            }}
          />
        </div>
      </div>
    </div>
  );
};

ClosedCaption.propTypes = {
  adDuration: PropTypes.number,
  currentTime: PropTypes.number,
  captionUrl: PropTypes.string,
  isHidden: PropTypes.bool,
  parentStyles: PropTypes.string,
};

export default ClosedCaption;
