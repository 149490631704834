import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { withFocus } from '@accedo/vdkweb-navigation';

import styles from './Buttons.scss';
import { isWorkstation } from '#/utils/componentStyleConfig';
import { setNativeFocus } from '#/utils/accessibility';

const buttonProps = {
  children: PropTypes.node,
  selected: PropTypes.bool,
  hidden: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
  styles: PropTypes.object,
  type: PropTypes.string,
  danger: PropTypes.bool,
  disabled: PropTypes.bool,
};

export const Button = ({
  children,
  className,
  danger = false,
  selected = true,
  hidden = false,
  type = 'button',
  disabled = false,
  ...props
}) => (
  <button
    {...props}
    disabled={disabled}
    className={cx(
      isWorkstation() ? styles.btn : styles.btnTv,
      { [styles.btnDelete]: danger },
      { [styles.btnUnselected]: !selected },
      { [styles.hidden]: hidden },
      { [styles.disabled]: disabled },
      className,
    )}
    type={type}
  >
    {children}
  </button>
);
Button.propTypes = buttonProps;

const FocButton = ({
  nav = { id: '' },
  isFocused,
  children,
  className,
  // primary = false,
  secondary = false,
  tertiary = false,
  danger = false,
  selected = true,
  hidden = false,
  type = 'button',
  ariaLabel,
  useNativeFocus = true,
  ...props
}) => {
  const cl = isWorkstation() ? 'btn' : 'btnTv';
  const secondaryCl = `${cl}Secondary`;
  const tertiaryCl = `${cl}Tertiary`;
  const buttonEl = useRef(null);

  useEffect(() => {
    if (useNativeFocus && nav?.isFocused) {
      setNativeFocus(nav?.id);
    }
  }, [nav]);

  return (
    <button
      {...props}
      ref={buttonEl}
      id={nav?.id}
      className={cx(
        [styles[cl]],
        { [styles[secondaryCl]]: secondary },
        { [styles[tertiaryCl]]: tertiary },
        // TODO: uncomment this when key navigation is supported on web as well
        // { [styles.focused]: isWorkstation() && isFocused },
        { [styles.btnDelete]: danger },
        { [styles.btnUnselected]: !selected },
        { [styles.hidden]: hidden },
        { [styles.tvFocused]: !isWorkstation() && isFocused },
        className,
      )}
      type={type}
      aria-label={ariaLabel}
    >
      <div aria-hidden="true">{children}</div>
    </button>
  );
};

FocButton.propTypes = {
  ...buttonProps,
  nav: PropTypes.object,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  ariaLabel: PropTypes.string,
  useNativeFocus: PropTypes.bool,
};

export const FocusButton = withFocus(FocButton);

export const OnboardButton = ({ children = 'Save', ...props }) => (
  <Button {...props} className={styles.btnOnboard}>
    {children}
  </Button>
);
OnboardButton.propTypes = buttonProps;

export const SaveButton = ({ children = 'Save', ...props }) => (
  <Button {...props} className={styles.btnSave}>
    {children}
  </Button>
);
SaveButton.propTypes = buttonProps;

export const TvSecondaryButton = ({ children = 'Save', ...props }) => (
  <FocusButton {...props} className={styles.btnTvSecondary}>
    {children}
  </FocusButton>
);
TvSecondaryButton.propTypes = buttonProps;

export const NumpadButton = withFocus(
  ({ isFocused, selected = false, label, ...props }) => (
    <button
      {...props}
      className={cx(styles.btnNumpad, {
        [styles.btnNumpadUnselected]: !selected,
        [styles.btnNumpadFocused]: !isWorkstation() && isFocused,
      })}
    >
      {label}
    </button>
  ),
);
NumpadButton.propTypes = {
  ...buttonProps,
};

export const CloseX = props => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1.65295C0.550723 1.10222 1.10222 0.550723 1.65295 0C4.10961 2.45667 6.56628 4.89039
       9 7.34705C11.4567 4.89039 13.9133 2.45667 16.3471 0L18 1.65295C15.5661 4.10961 13.1096 6.56628
        10.6757 9C13.1096 11.4567 15.5661 13.9133 18 16.3471C17.4493 16.8978 16.8978 17.4493 16.3471 18C13.9131 
        15.5661 11.4567 13.1096 9 10.6757C6.56609 13.1096 4.10961 15.5661 1.65295 18L0 16.3471C2.45667 13.9131 4.91333 11.4567 7.34705 9L0 1.65295Z"
      fill="#333333"
    />
  </svg>
);
CloseX.propTypes = buttonProps;

export const EditButton = props => (
  <svg
    {...props}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="19.5" fill="white" stroke="#D0D0D0" />
    <path
      d="M11.2111 23.3247L10.0296 28.435C9.98881 28.6218 9.99021 28.8154 10.0337 29.0016C10.0771 29.1878 10.1615 29.3619 10.2807 29.5112C10.3999 29.6605 10.5509 29.7813 10.7226 29.8646C10.8944 29.948 11.0825 29.9919 11.2733 29.993C11.3622 30.002 11.4518 30.002 11.5407 29.993L16.6712 28.8089L26.5217 18.9747L21.0243 13.478L11.2111 23.3247Z"
      fill="#5EB8E5"
    />
    <path
      d="M29.6228 14.0031L25.9907 10.371C25.7519 10.1334 25.4287 10 25.0919 10C24.755 10 24.4318 10.1334 24.1931 10.371L22.1738 12.3902L27.6097 17.8261L29.6289 15.8069C29.7471 15.6881 29.8407 15.5472 29.9044 15.3922C29.968 15.2372 30.0005 15.0712 29.9999 14.9037C29.9993 14.7362 29.9657 14.5704 29.901 14.4159C29.8363 14.2613 29.7418 14.1211 29.6228 14.0031Z"
      fill="#5EB8E5"
    />
  </svg>
);

export const ArrowLeftButton = ({ ...rest }) => (
  <svg
    {...rest}
    viewBox="0 0 10 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.32898 12.7557L1.63172 7.4073C1.39166 7.18216 1.39166 6.81843 1.63172 6.5927L7.32898 1.24429C7.67552 0.918569 8.23934 0.918569 8.58649 1.24429C8.93303 1.57002 8.93303 2.09866 8.58649 2.42438L3.71279 7.00029L8.58649 11.575C8.93303 11.9013 8.93303 12.43 8.58649 12.7557C8.23934 13.0814 7.67552 13.0814 7.32898 12.7557Z"
      fill="#0E0E2C"
    />
    <path
      d="M7.32898 12.7557L1.63172 7.4073C1.39166 7.18216 1.39166 6.81843 1.63172 6.5927L7.32898 1.24429C7.67552 0.918569 8.23934 0.918569 8.58649 1.24429C8.93303 1.57002 8.93303 2.09866 8.58649 2.42438L3.71279 7.00029L8.58649 11.575C8.93303 11.9013 8.93303 12.43 8.58649 12.7557C8.23934 13.0814 7.67552 13.0814 7.32898 12.7557"
      stroke="#0E0E2C"
    />
  </svg>
);

ArrowLeftButton.propTypes = {
  ...buttonProps,
  height: PropTypes.number,
  width: PropTypes.number,
};
EditButton.propTypes = buttonProps;
