/* eslint-disable react/no-danger */

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { requestHelp } from '#/redux/modules/accedoOne/actions';
import { getHelp } from '#/redux/modules/accedoOne/selectors';

import parseTextForLinks from '#/utils/parseTextForLinks';
import styles from './Help.scss';

export const Help = () => {
  const dispatch = useDispatch();
  const helpText = useSelector(state => getHelp(state));

  useEffect(() => {
    dispatch(requestHelp);
  }, []);

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: helpText && parseTextForLinks(helpText),
      }}
      className={styles.helpText}
    />
  );
};

Help.propTypes = {};

export default Help;
