import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

const RedirectWithQuery = ({ to, push }) => {
  const { search } = window.location;

  return <Redirect push={push} to={`${to}${search}`} />;
};

RedirectWithQuery.propTypes = {
  to: PropTypes.string,
  push: PropTypes.bool,
};

export default RedirectWithQuery;
