import { handleActions } from 'redux-actions';
import { preventDefaultBack } from './actions';

const initialState = {
  preventBack: false,
};

const preventDefaultBackReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    preventBack: payload,
  };
};

const reducer = handleActions(
  {
    [preventDefaultBack]: preventDefaultBackReducer,
  },
  initialState,
);

export default reducer;
