import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTermsOfService } from '#/redux/modules/accedoOne/selectors';
import { loadTermsOfService } from '#/redux/modules/accedoOne/actions';
import { parentZone } from '#/config/strings';
import kidSafe from '#/static/images/icons/sensical_small_kidSafe.svg';
import styles from './TermsOfServiceModal.scss';

const TermsOfServiceModal = () => {
  const dispatch = useDispatch();
  const content = useSelector(getTermsOfService);
  const contentAreaRef = useRef(null);

  useEffect(() => {
    if (!content) {
      dispatch(loadTermsOfService);
    }
    contentAreaRef?.current?.focus();
  }, []);

  return (
    <>
      <h2 className={(styles.title, styles.modalTitle)}>
        {parentZone.termsOfService}
      </h2>
      <div className={styles.kidImg}>
        <img src={kidSafe} alt="Kid Safe" />
      </div>
      <p ref={contentAreaRef} tabIndex={0} className={styles.textContent}>
        {content}
      </p>
    </>
  );
};

export default TermsOfServiceModal;
