import liveStreamLogo from '#/static/images/transparent-pixel.png';

export const signupStates = {
  WELCOME: 'welcome',
  CREATE_PROFILE: 'createprofile',
  CREATE_ACCOUNT: 'createaccount',
  GUEST_WELCOME: 'guestprofile',
};

export const mainPageNames = {
  HOME: 'home',
  LIVE: 'livetv',
  EXPLORE: 'explore',
  FAVORITES: 'favorites',
};

export const ageGroupsKeys = {
  TWO_UP: 'TWO_UP',
  FIVE_UP: 'FIVE_UP',
  EIGHT_UP: 'EIGHT_UP',
};

export const playerConstants = {
  PLAYER_CONTROLS_CONTAINER_CLASS: 'vjs-control-bar',
  FULL_SCREEN_CLASS: 'custom-sensicle-fullscreen',
  BACKGROUND_PLAYER_TITLE_CLASS: 'background-player-title-text',
  MOBILE_OVERLAY: 'mobile-overlay',
  MOBILE_LIVE: 'mobile-live',
  MOBILE_VOD: 'mobile-vod',
  MOBILE_VOD_PODCAST: 'mobile-vod-podcast',
  IOS_VIDEO: 'ios-video',
  HIDE_OVERLAY_TIMEOUT: 6000,
  UP_NEXT_ITEM_COUNT: 15,
  UP_NEXT_CAROUSEL_ID: 'id-player-more-content-carousel',
  FULL_SCREEN_CHANGE: 'fullscreenchange',
  WATCH_HISTORY_TIMEOUT: 10000,
  CUSTOM_BOOKMARKING_ENABLED: true,
  BOOKMARKING_TIMEOUT: 60000,
  HEARTBEAT_TIMEOUT: 10000,
  CC_DEFAULT_STYLES: {
    textStyle: 'TextaAlt-Medium',
    textSize: '55',
    textColor: 'rgb(255, 255, 255)',
    textOpacity: '1',
    textEdges: '',
    textEdgesColor: '',
    backgroundColor: 'rgb(0, 0, 0)',
    backgroundOpacity: '1',
    windowColor: 'rgb(0, 0, 0)',
    windowOpacity: '1',
  },
};

export const unsavedProfileIdPrefix = 'UNSAVED';

export const customDataEndpoints = {
  CREATE_CHILD_CUSTOM_DATA: 'createChildCustomData',
  READ_CHILD_CUSTOM_DATA: 'getChildCustomData',
  UPDATE_CHILD_CUSTOM_DATA: 'updateChildCustomData',
  DELETE_CHILD_CUSTOM_DATA: 'deleteChildCustomData',
};

export const apiIds = {
  IDENTITY_API: 'apiIdentityEndpoint',
  OIDC_API: 'apiOidcEndpoint',
  CUSTOM_DATA_API: 'apiCustomFieldsEndpoint',
};

export const customDataFieldTypes = {
  TIME_WATCHED: 'timeWatched',
  RECENTLY_WATCHED: 'recentlyWatched',
  RECENTLY_WATCHED_V2: 'recentlyWatched_v2',
  LIVE_HISTORY: 'liveHistory',
  WATCH_HISTORY: 'watchHistory',
  BOOKMARKS: 'bookmarks',
  BOOKMARKS_V2: 'bookmarks_v2',
  SHOWS_FAVORITES: 'showsFavorites',
  PODCASTS_FAVORITES: 'podcastsFavorites',
  TOPICS_FAVORITES: 'topicsFavorites',
  VIDEO_FAVORITES: 'videoFavorites',
  PODCASTS_EPISODES_FAVORITES: 'podcastsEpisodesFavorites',
  DISPLAYED_MODALS: 'displayedModals',
  VIDEOS_BLOCKED: 'videosBlocked',
  SHOWS_BLOCKED: 'showsBlocked',
};

export const containersAgeGroup = {
  CONTAINER_2Up: 'containers2Up',
  CONTAINER_5Up: 'containers5Up',
  CONTAINER_8Up: 'containers8Up',
};

export const firestoreTopShowsAgeGroup = {
  TOP_SHOWS_2UP: 'topshows2up',
  TOP_SHOWS_5UP: 'topshows5up',
  TOP_SHOWS_8UP: 'topshows8up',
};

export const streamsAgeGroup = {
  STREAM_2Up: {
    epgKey: 'epg2Up',
    key: 'stream2Up',
    title: '2Up',
    logoUri: liveStreamLogo,
  },
  STREAM_5Up: {
    epgKey: 'epg5Up',
    key: 'stream5Up',
    title: '5Up',
    logoUri: liveStreamLogo,
  },
  STREAM_8Up: {
    epgKey: 'epg8Up',
    key: 'stream8Up',
    title: '8Up',
    logoUri: liveStreamLogo,
  },
};

export const containersTypeAlias = {
  CONTAINER_HERO: 'container-hero',
  CONTAINER_OVP_PLAYLIST: 'container-ovp-playlist',
  CONTAINER_RECENTLY_WATCHED: 'container-recently-watched',
  CONTAINER_SHOWS: 'container-shows',
  CONTAINER_TOPICS: 'container-topics',
  CONTAINER_VIDEO: 'container-video',
  TVCONTAINER_FAV_VIDEOS: 'container-favorite-videos',
  TVCONTAINER_FAV_SHOWS: 'container-favorite-shows',
  TVCONTAINER_FAV_TOPICS: 'container-favorite-topics',
};

export const ageCategoryGroup = {
  MIN_AGE_TWO_UP: 2,
  MIN_AGE_FIVE_UP: 5,
  MIN_AGE_EIGHT_UP: 8,
};

export const customRoutes = {
  CREATOR_DETAILS: '/main/home/creatordetails',
  SHOW_DETAILS: '/main/home/showdetails',
};

// Note these are maximal values that tiles can resize to, good for scaling down
// If design requires larger images, increase these values
export const imgixTileResizeParameters = {
  VIDEOS: {
    fit: 'crop',
    h: 173,
    w: 316,
  },
  SHOWS: {
    fit: 'crop',
    h: 300,
    w: 550,
  },
  CONTENT_DETAILS: {
    fit: 'crop',
    h: 390,
    w: 630,
  },
  SWIMLANE: {
    fit: 'scale',
    h: 200,
    w: 1000,
  },
};

export const SWIMLANE_LENGTH = 15;
export const SWIMLANE_LEFT_ARROW = 'SWIMLANE_LEFT_ARROW';
export const SWIMLANE_RIGHT_ARROW = 'SWIMLANE_RIGHT_ARROW';

export const videoAnalytics = {
  initiation: {
    MANUAL: 'manual',
    AUTO: 'autoplay',
  },
  streamType: {
    VOD: 'On Demand',
    LIVE: 'Linear',
  },
  player: {
    BACKGROUND: 'background',
    FULLSCREEN: 'fullscreen',
  },
  events: {
    VIDEO_INITIATED: 'video_initiated',
    VIDEO_START: 'video_start',
    VIDEO_PROGRESS: 'video_progress',
    VIDEO_COMPLETE: 'video_complete',
    VIDEO_CONTROL: 'video_control',
    VIDEO_HEARTBEAT: 'video_heartbeat',
    VIDEO_INTERRUPTION: 'video_interruption',
    VIDEO_BUFFER: 'buffering_start',
    VIDEO_QUALITY_DOWNSHIFT: 'video_quality_downshift',
    VIDEO_QUALITY_UPSHIFT: 'video_quality_upshift',
    NETWORK_ERROR: 'network_loss',
    GENERIC_ERROR: 'generic_video_error',
  },
  controls: {
    play: 'Resume',
    pause: 'Pause',
    seek: 'Seek Start',
    seeked: 'Seek End',
    enterFullscreen: 'Fullscreen Start',
    exitFullscreen: 'Fullscreen End',
  },
};

export const screenViewAnalyticsEvent = 'screen_view';
export const addProfileAnalyticsEvent = 'add_profile';
export const registrationCompleteAnalyticsEvent = 'registration_complete';

export const ageRanges = {
  TWO_UP: '2-4',
  FIVE_UP: '5-7',
  EIGHT_UP: '8-10',
};

export const guestTileTitle = {
  'on-demand': 'Explore On Demand',
  live: 'Watch Live Now',
};

export const ageGroupsMap = {
  '2': ageGroupsKeys.TWO_UP,
  '5': ageGroupsKeys.FIVE_UP,
  '8': ageGroupsKeys.EIGHT_UP,
};

export const timeLimitOptions = {
  1800000: '30 min / day',
  3600000: '1 hr / day',
  7200000: '2 hrs / day',
  10800000: '3 hrs / day',
  14400000: '4 hrs / day',
  18000000: '5 hrs / day',
  0: 'No limit',
};

export const mainScreenNames = {
  home: 'Home',
  livetv: 'Video Player',
  explore: 'Explore',
  favorites: 'Favorites',
  creatordetails: 'Creator Detail',
  showdetails: 'Show Detail',
  parentzone: 'Learning Report',
  seeall: 'View All',
};

export const authScreenNames = {
  WELCOME: 'Welcome Page',
  AGE_MODAL: 'Age Year Modal',
  BIRTH_MONTH_MODAL: 'Birth Month Modal',
  CREATE_PIN_MODAL: 'Create PIN Modal',
  SIGN_IN: 'Parent Sign-in',
  SIGN_UP: 'Sign up free',
  REGISTER: 'Register for free',
  GO_TO_APP: 'Go to the app',
  AGE_GATE: 'Age Verification',
  KID_PROFILE_CREATION: 'Profile Setup',
  KID_PROFILE_CREATION_COMPLETE: 'Profile Setup Complete',
  PARENT_ACCOUNT_CREATION: 'Parent Account Setup',
  EMAIL_VERIFICATION: 'Email Verification Prompt',
  EMAIL_VERIFICATION_COMPLETE: 'Email Verification Confirmation',
  PROFILE_SELECTION: 'Select Profile (for use)',
  FORGOT_PASSWORD: 'Forgot Password',
  CODE_SCREEN: 'Enter 10ft Code',
  AGE_UP_DURING_BIRTH_MONTH: 'Age Up - In Month',
  AGE_UP_AFTER_BIRTH_MONTH: 'Age Up - After Month',
  AGE_UP_PARENT_INFO: 'Age Up - Parent Info',
};

export const fullscreenPlayerScreenName = 'Video Player';

export const modalScreenNames = {
  PrivacyAgreementModal: 'Privacy Policy Preview',
  TocModal: 'Terms of Use Preview',
  TimesUpModal: 'Time Up Gate',
  BenefitModal: 'Benefits Explanation',
  PinModal: 'Enter PIN',
  AvatarModal: 'Select Profile Avatar',
  DataSharePolicyModal: 'We will never sell or share your data',
  ContentBlockingModal: 'Content Blocking Modal',
  ContentUnblockingModal: 'Content Unblocking Modal',
  ContentBlockingContentControls: 'Content Blocking Content Controls',
  WelcomeModal: 'CTA Welcome Modal',
};

export const parentZoneProfileScreenNames = {
  MY_ACCOUNT_DELETE: 'Delete Account Confirmation',
  KIDS_PROFILE_OVERVIEW: 'Profile Overview',
  KIDS_PROFILE_EDIT: 'Select Profile (to edit)',
  KIDS_PROFILE_DELETE: 'Delete Profile Confirmation',
};

export const parentZoneScreenName = 'ParentZone';

export const parentZoneLayoutIdToScreenNames = [
  null,
  'My Account',
  null,
  'Parental Controls',
  'Privacy Policy',
  'FAQ',
  'Help',
];

export const parentZoneSignOutButtonText = 'Sign Out';

export const ageGroupAnalyticsValues = {
  [ageGroupsKeys.TWO_UP]: '2 up',
  [ageGroupsKeys.FIVE_UP]: '5 up',
  [ageGroupsKeys.EIGHT_UP]: '8 up',
};

export const REDUX_STORE_SAVED_KEYS = [
  ['modules', 'onboarding', 'selectedProfile'],
  ['modules', 'identity', 'parentMode'],
  ['modules', 'identity', 'sessionData'],
  ['modules', 'identity', 'accessToken'],
  ['modules', 'identity', 'refreshToken'],
  ['modules', 'guest', 'localStorage'],
  ['modules', 'onboarding', 'localStorage'],
];

export const maxTimeLimitOption = 18000000;

export const defaultParentalTimeLimit = 0;

export const marketingUrl = 'http://sensicaltv.com/';

export const palSdkSource =
  'https://imasdk.googleapis.com/pal/sdkloader/pal.js';

export const XMLTV_TIME_FORMAT = 'YYYYMMDDHHmmss Z';

export const VIDEO_FAVORITES_PLAYLIST_ID = 'video_favorites_playlist';

export const PODCASTS_EPISODES_FAVORITES_PLAYLIST_ID =
  'podcasts_episodes_favorites_playlist';

export const splashFadeTime = 1000;

export const EXPLORE_SCREEN = {
  SHOWS: 'shows',
  TOPICS: 'topics',
  TOPSHOWS: 'Top Shows',
  tabsSchema: [
    {
      label: 'All Shows (A-Z)',
      id: 'shows',
    },
    {
      label: 'All Channels',
      id: 'topics',
    },
  ],
};

export const FAVORITES_SCREEN = {
  SHOWS: 'Shows',
  PODCASTS: 'Podcasts',
  CHANNELS: 'Channels',
  VIDEOS: 'Videos',
  PODCASTS_EPISODES: 'Podcasts Episodes',
};

export const SEE_ALL_SCREEN = {
  FAVORITES_TITLE_PREFIX: 'All Favorite',
};
export const GUEST_MODAL_TYPE = {
  favoritesBlocked: 'favoritesBlocked',
  parentZoneBlocked: 'parentZoneBlocked',
  contentBlockingBlocked: 'contentBlockingBlocked',
};
export const smartBanner = {
  smartBannerUrl: {
    ios: 'https://apps.apple.com/us/app/sensical/id1557563426',
    android:
      'https://play.google.com/store/apps/details?id=tv.sensical.android&hl=en&gl=US',
    windows: '',
    kindle: '',
  },
  smartBannerStoreText: {
    ios: 'GET - On the App Store',
    android: 'GET - In Google Play',
    windows: 'GET - On the App Store',
    kindle: 'GET - On the App Store',
  },
  smartBannerPrice: {
    ios: '',
    android: '',
    windows: '',
    kindle: '',
  },
  smartBannerTitle: 'Sensical',
  smartBannerAuthor: 'Common Sense Networks',
  smartBannerButton: 'Download',
};

export const deeplinks = {
  LIVE_TV: 'live_tv',
  COLLECTION_VIEWALL: 'collection_viewall',
  CHANNEL_TOPIC: 'channel_topic',
  SHOW_DETAILS: 'show_details',
};

export const RECENTLY_WATCHED_TITLE = 'Recently Watched';

export const featureSpecificConfigs = {
  GUEST_MODE_DATA_SYNC_UP: 'guestModeDataSyncUp',
  RECENTLY_WATCHED: 'recentlyWatched',
  AGE_UP: 'ageUp',
  CONTENT_BLOCKING: 'contentBlocking',
};

export const HIDDEN_CONTENT = {
  SHOWS: 'shows',
  VIDEOS: 'videos',
};

export const blockContentAnalyticsEvent = 'block_content';
export const unblockContentAnalyticsEvent = 'unblock_content';
export const welcomeModalAnalyticsEvent = 'page_view';
export const welcomeModalCTAClickedAnalyticsEvent = 'WM_CTA_Clicked';
export const welcomeModalPlayIconClickedAnalyticsEvent = 'WM_playicon_Clicked';
export const welcomeModalCancelClickedAnalyticsEvent = 'WM_Cancel';
export const guestAgeGroupCTAClickedAnalyticsEvent = age =>
  `homepage_guest_${age}_selected`;
export const guestAgeGroupLiveCTAClickedAnalyticsEvent = age =>
  `homepage_live_tile_selected_${age}`;
