import React, { useMemo, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';
import { Helmet } from 'react-helmet';
import SmartBanner from 'react-smartbanner';
import { isIOS, isSafari, isChrome, isAndroid } from 'react-device-detect';
import ReactDOM from 'react-dom';
import { Page } from '@accedo/vdkweb-tv-ui';
import GreenBackground from '#/static/images/background-images/WebHalfCircle.png';
import PlaceholderProfileImage from '#/static/images/guest/contentImage-858x1872_3x.png';
import PlaceholderProfileImageSmall from '#/static/images/guest/contentImageSmall-1020x198_3x.png';
import {
  getWhiteLogoUri,
  getAgeGroupConfig,
  getGuestProfileContent,
} from '#/redux/modules/accedoOne/selectors';
import styles from './GuestProfile.scss';
import {
  isWorkstation,
  isiOS,
  isPortable,
  isAndroid as checkIsAndroid,
} from '#/utils/componentStyleConfig';
import { actions as modalActions } from '#/redux/modules/modal/actions';
import {
  updatedGuestFooterDesc,
  guestProfileTitle,
  guestProfileScreenName,
} from '#/config/strings';
import LoginRedirect from '#/components/AuthFlow/LoginRedirect/LoginRedirect';
import { authFlowRoutes, mainRoutes } from '#/utils/routes';
import {
  ageCategoryGroup,
  ageRanges,
  smartBanner,
  guestTileTitle,
  guestAgeGroupCTAClickedAnalyticsEvent,
  guestAgeGroupLiveCTAClickedAnalyticsEvent,
  signupStates,
} from '#/config/constants';
import smallMonster from '#/static/images/icons/guests/small-monster.svg';
import mediumMonster from '#/static/images/icons/guests/medium-monster.svg';
import bigMonster from '#/static/images/icons/guests/big-monster.svg';
import sensicalIcon from '#/static/images/icons/Sensical-app-icon.svg';
import { guestMode } from '#/redux/modules/identity/actions';
import { actions as onboardingActions } from '#/redux/modules/onboarding/actions';
import {
  sendScreenView,
  sendGuestAgeGroupClicked,
  sendGuestAgeGroupLiveClicked,
} from '#/redux/modules/firestore/actions';
import { getLoginLoadingSpinnerState } from '#/redux/modules/spinner/selectors';

// Focus handling
import { FocusDiv, FocusButton } from '#/widgets/ui';

import { isTTSEnabled } from '#/redux/modules/system/selectors';
import { setNativeFocus } from '#/utils/accessibility';
import { toggleLoginSpinner } from '#/redux/modules/spinner/actions';

import { GUEST_PROFILE_SELECTION } from '#/utils/navigationHelper';
import { getShowGuestProfileImage } from '#/redux/modules/onboarding/selectors';
import { getAppStarted } from '#/redux/modules/lifecycle/selectors';

const { LIVETV } = mainRoutes;

const {
  GUEST_PROFILE_PAGE,
  GUEST_PROFILE_CONTAINER,
  GUEST_PROFILE_FOOTER,
  SIGN_IN_LINK,
  PROFILE_CARDS_CONTAINER,
  PROFILE_CARD,
  SIGN_UP_LINK,
} = GUEST_PROFILE_SELECTION;

// info not available in control
export const ageMetadata = {
  [`${ageCategoryGroup.MIN_AGE_TWO_UP}`]: {
    color: 'green',
    image: smallMonster,
    size: 'small',
    ageRange: ageRanges.TWO_UP,
  },
  [`${ageCategoryGroup.MIN_AGE_FIVE_UP}`]: {
    color: 'purple',
    image: mediumMonster,
    size: 'medium',
    ageRange: ageRanges.FIVE_UP,
  },
  [`${ageCategoryGroup.MIN_AGE_EIGHT_UP}`]: {
    color: 'yellow',
    image: bigMonster,
    size: 'big',
    ageRange: ageRanges.EIGHT_UP,
  },
};

const GuestCard = ({
  ageGroupValue = 0,
  contentImageUrl,
  contentImageLargeUrl,
  contentImageSmallUrl,
  tileDescription,
  tileDescriptionMobile,
  typeOfContent,
  ageRange,
  info1,
  info2,
  info3,
  history,
  tagBackgroundColor,
  labelText,
  labelTextTablet,
  labelTextMobile,
  pillPosition,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const showGuestProfileImage = useSelector(getShowGuestProfileImage);
  const { color = '' } = ageGroupValue ? ageMetadata[`${ageGroupValue}`] : {};
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(sendScreenView(guestProfileScreenName));
  }, [guestProfileScreenName]);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const guestLiveTvByAge = async age => {
    const pathname = `${LIVETV}/${age}`;
    const newEntry = {
      pathname,
      state: { fromMainPage: authFlowRoutes.GUEST_PROFILE },
    };
    await dispatch(
      sendGuestAgeGroupLiveClicked(
        signupStates.GUEST_WELCOME,
        guestAgeGroupLiveCTAClickedAnalyticsEvent(ageGroupValue),
      ),
    );
    await dispatch(guestMode({ age }));
    history.replace('/');
    history.push(newEntry);
  };

  const renderImage = (imageUrl, label, zoneStyle) => {
    return (
      <div
        className={cx(styles.imageZone, {
          [zoneStyle]: !!imageUrl,
          [styles.isSafariBrowser]: isSafari,
          [styles.isIosChromeBrowser]: isChrome && isIOS,
        })}
      >
        <img src={imageUrl} alt={`${tileDescription}.`} />
        {label && pillPosition && tagBackgroundColor && (
          <h6
            className={`${styles.tagCard} ${
              label === labelText ? styles.desktopPill : ''
            } ${label === labelTextTablet ? styles.tabletPill : ''} ${
              label === labelTextMobile ? styles.mobilePill : ''
            } ${pillPosition === 'top-left' ? styles.topLeft : ''} ${
              pillPosition === 'bottom-left' ? styles.bottomLeft : ''
            } ${pillPosition === 'top-right' ? styles.topRight : ''} ${
              pillPosition === 'bottom-right' ? styles.bottomRight : ''
            }`}
            style={{ backgroundColor: tagBackgroundColor }}
          >
            {label}
          </h6>
        )}
      </div>
    );
  };
  return (
    <div aria-label={`${tileDescription}.`} className={styles.card}>
      <div
        className={`${styles.cardTitle} ${
          isSafari ? styles.isSafariBrowser : ''
        }`}
        onClick={() => {
          typeOfContent !== 'live'
            ? dispatch(
                modalActions.openModal({
                  modalId: 'AgeSelectionGuest',
                  modalData: {
                    ageRange,
                    color,
                    info1,
                    info2,
                    info3,
                  },
                  successAction: () => {
                    dispatch(
                      sendGuestAgeGroupClicked(
                        signupStates.GUEST_WELCOME,
                        guestAgeGroupCTAClickedAnalyticsEvent(ageGroupValue),
                      ),
                    );
                    dispatch(guestMode({ age: ageGroupValue }));
                  },
                }),
              )
            : guestLiveTvByAge(ageGroupValue);
        }}
      >
        {windowWidth > 1024 &&
          renderImage(
            showGuestProfileImage ? contentImageUrl : PlaceholderProfileImage,
            labelText,
            styles.desktopImageZone,
          )}
        {windowWidth <= 1024 &&
          windowWidth > 480 &&
          renderImage(
            showGuestProfileImage
              ? contentImageLargeUrl
              : PlaceholderProfileImage,
            labelTextTablet,
            styles.largeImageZone,
          )}
        {windowWidth <= 480 &&
          renderImage(
            showGuestProfileImage
              ? contentImageSmallUrl
              : PlaceholderProfileImageSmall,
            labelTextMobile,
            styles.smallImageZone,
          )}
        {tileDescription && (
          <h2 className={styles.desktopLabel}>{tileDescription}</h2>
        )}
        {tileDescriptionMobile && (
          <h2 className={styles.mobileLabel}>{tileDescriptionMobile}</h2>
        )}
      </div>
    </div>
  );
};

GuestCard.propTypes = {
  ageGroupValue: PropTypes.number,
  ageRange: PropTypes.string,
  info1: PropTypes.string,
  info2: PropTypes.string,
  info3: PropTypes.string,
  typeOfContent: PropTypes.string,
  contentImageUrl: PropTypes.string,
  contentImageLargeUrl: PropTypes.string,
  contentImageSmallUrl: PropTypes.string,
  tileDescription: PropTypes.string,
  tileDescriptionMobile: PropTypes.string,
  history: PropTypes.object,
  tagBackgroundColor: PropTypes.string,
  labelText: PropTypes.string,
  labelTextTablet: PropTypes.string,
  labelTextMobile: PropTypes.string,
  pillPosition: PropTypes.string,
};

const Cards = ({ history, guestProfileContent }) => {
  const ageGroupConfig = useSelector(getAgeGroupConfig);
  const guestCards = useMemo(() => {
    const getAllKeyEntriesByContent = Object.keys(
      guestProfileContent.setAllEntriesByContent,
    );
    const getAgeGroupConfigValues = Object.values(ageGroupConfig || []);

    return Object.values(guestProfileContent.setAllEntriesByContent ?? {}).map(
      (groupContent, index, array) => {
        return (
          <div
            className={styles.guestTitleText}
            key={getAllKeyEntriesByContent[index]}
          >
            <h2 className={styles.guestHorizontalLine}>
              {guestTileTitle[getAllKeyEntriesByContent[index]]}
            </h2>
            <div
              className={cx({ [styles.isIosChromeBrowser]: isChrome && isIOS })}
            >
              {groupContent.map((group, idx) => {
                const {
                  ageGroupValue,
                  contentImageUrl,
                  contentImageLargeUrl,
                  contentImageSmallUrl,
                  tileDescription,
                  tileDescriptionMobile,
                  title,
                  typeOfContent,
                  tagBackgroundColor,
                  labelText,
                  labelTextTablet,
                  labelTextMobile,
                  pillPosition,
                } = group;
                const {
                  minAge = '',
                  maxAge = '',
                  noRegistrationConfig: { info1, info2, info3 } = {},
                } = ageGroupValue
                  ? getAgeGroupConfigValues.find(
                      c => c.ageGroupValue === ageGroupValue,
                    )
                  : {};
                const ageRange = minAge && maxAge ? `${minAge}-${maxAge}` : '';
                const cardIndex = index * (array.length + 1) + idx;
                return (
                  <GuestCard
                    history={history}
                    key={cardIndex}
                    {...{
                      ageGroupValue,
                      contentImageUrl,
                      contentImageLargeUrl,
                      contentImageSmallUrl,
                      tileDescription,
                      tileDescriptionMobile,
                      title,
                      typeOfContent,
                      cardIndex,
                      ageRange,
                      info1,
                      info2,
                      info3,
                      tagBackgroundColor,
                      labelText,
                      labelTextTablet,
                      labelTextMobile,
                      pillPosition,
                    }}
                  />
                );
              })}
            </div>
          </div>
        );
      },
    );
  }, [guestProfileContent, ageGroupConfig]);

  return (
    <FocusDiv
      nav={{
        id: PROFILE_CARDS_CONTAINER,
        forwardFocus: `${PROFILE_CARD}-0`,
      }}
      className={styles.cardsArea}
    >
      {guestCards}
    </FocusDiv>
  );
};
Cards.propTypes = {
  history: PropTypes.object,
  guestProfileContent: PropTypes.object,
};

const GuestCards = ({ history, showButtons }) => {
  const guestProfileContent = useSelector(getGuestProfileContent);
  return (
    <>
      <Cards history={history} guestProfileContent={guestProfileContent} />
      <div
        className={styles.tvFooter}
        id={GUEST_PROFILE_FOOTER}
        style={{ opacity: showButtons ? 1 : 0 }}
      >
        <div>
          <FocusButton
            className={styles.tvButton}
            nav={{
              id: SIGN_IN_LINK,
              nextup: PROFILE_CARDS_CONTAINER,
              nextright: SIGN_UP_LINK,
            }}
            onClick={() => {
              history.push(authFlowRoutes.SIGN_IN);
            }}
            ariaLabel={guestProfileContent.parentSignInButtonText}
          >
            {guestProfileContent.parentSignInButtonText}
          </FocusButton>
          <FocusButton
            className={styles.tvButton}
            nav={{
              id: SIGN_UP_LINK,
              nextup: PROFILE_CARDS_CONTAINER,
              nextleft: SIGN_IN_LINK,
            }}
            onClick={() => {
              history.push(
                isWorkstation()
                  ? authFlowRoutes.AGE_GATE
                  : authFlowRoutes.SIGN_UP,
              );
            }}
            ariaLabel={guestProfileContent.signUpButtonText}
            aria-describedby={updatedGuestFooterDesc}
          >
            {guestProfileContent.signUpButtonText}
          </FocusButton>
        </div>
        <h4 className={cx(styles.tvFooterText)}>{updatedGuestFooterDesc}</h4>
      </div>
    </>
  );
};

GuestCards.propTypes = {
  history: PropTypes.object,
  showButtons: PropTypes.bool,
};

const GuestProfile = ({ history }) => {
  const [tagsReady, setTagsReady] = useState(false);
  const dispatch = useDispatch();
  const logoUri = useSelector(getWhiteLogoUri) || '';
  const ttsEnabled = useSelector(isTTSEnabled);
  const guestProfileContent = useSelector(getGuestProfileContent);
  const spinnerActive = useSelector(getLoginLoadingSpinnerState);
  const appStarted = useSelector(getAppStarted);
  const imageLoadRef = useRef(false);
  const [hideSpinner, setHideSpinner] = useState(false);
  // Dispatch spinner asap to avoid displaying unloaded images
  !appStarted && !spinnerActive && dispatch(toggleLoginSpinner(true));

  useEffect(() => {
    if (ttsEnabled) {
      setNativeFocus(GUEST_PROFILE_CONTAINER);
    }
  }, [ttsEnabled]);

  useEffect(() => {
    dispatch(onboardingActions.showGuestProfileImage(false));
    return () => {
      dispatch(onboardingActions.showGuestProfileImage(false));
    };
  }, []);

  useEffect(() => {
    // Keep switching spinner to true until app and images are loaded.
    dispatch(toggleLoginSpinner(!(appStarted && hideSpinner)));
    // dispatch(toggleLoginSpinner(true));
  }, [spinnerActive, appStarted, hideSpinner]);

  useEffect(() => {
    if (!guestProfileContent.totalCount || imageLoadRef.current) {
      return;
    }

    imageLoadRef.current = true;

    // Extract all guest profile image urls in a single array
    const imageUrls = [
      ...Object.values(guestProfileContent.setAllEntriesByContent)
        .reduce((acc, c) => [...acc, ...c], [])
        .reduce(
          (
            urls,
            { contentImageLargeUrl, contentImageSmallUrl, contentImageUrl },
          ) => {
            urls.add(contentImageUrl);
            urls.add(contentImageLargeUrl);
            urls.add(contentImageSmallUrl);

            return urls;
          },
          new Set(),
        ),
    ];

    // Preload all images. Resolve when all are done.
    Promise.allSettled([
      imageUrls.map(
        url =>
          new Promise(res => {
            const image = new Image();
            image.src = url;
            const callback = () => {
              image.removeEventListener('load', callback);
              image.removeEventListener('error', callback);
              res();
            };
            image.addEventListener('load', callback);
            image.addEventListener('error', callback);
          }),
      ),
    ]).then(() => {
      setHideSpinner(true);
      dispatch(onboardingActions.showGuestProfileImage(true));
    });
  }, [guestProfileContent?.totalCount]);

  const {
    smartBannerTitle,
    smartBannerStoreText,
    smartBannerPrice,
    smartBannerButton,
    smartBannerUrl,
    smartBannerAuthor,
  } = smartBanner;
  const banner = () => {
    if (isiOS() && isSafari) {
      return;
    }
    return ReactDOM.createPortal(
      <SmartBanner
        ignoreIosVersion
        title={smartBannerTitle}
        storeText={smartBannerStoreText}
        price={smartBannerPrice}
        button={smartBannerButton}
        url={smartBannerUrl}
        author={smartBannerAuthor}
      />,
      document.getElementById('root'),
    );
  };

  return (
    <Page
      nav={{
        id: GUEST_PROFILE_PAGE,
        forwardFocus: PROFILE_CARDS_CONTAINER,
      }}
    >
      <LoginRedirect />
      {tagsReady && banner()}
      <Helmet
        onChangeClientState={newState => {
          const { metaTags } = newState;
          metaTags.filter(tags => tags.name === 'apple-itunes-app').length >
            0 && setTagsReady(true);
        }}
      >
        <meta
          name="apple-itunes-app"
          content="app-id=1557563426"
          app-argument="https://apps.apple.com/us/app/sensical/id1557563426"
        />
        <meta name="google-play-app" content="app-id=tv.sensical.android" />
        <meta name="msApplication-ID" content="microsoft.build.App" />
        <meta
          name="msApplication-PackageFamilyName"
          content="microsoft.build_8wekyb3d8bbwe"
        />
        <meta name="robots" content="noindex" />
        <link rel="apple-touch-icon" href={sensicalIcon} />
        <link rel="android-touch-icon" href={sensicalIcon} />
      </Helmet>
      <div className={styles.pageBackground}>
        <div
          className={styles.profileSelection}
          style={{ backgroundImage: `url(${GreenBackground})` }}
        >
          <FocusDiv
            role="main"
            nav={{ id: GUEST_PROFILE_CONTAINER }}
            className={cx(styles.container, {
              [styles.webContainer]: !isIOS && !isAndroid && !isPortable(),
              [styles.androidContainer]: isAndroid || checkIsAndroid(),
              [styles.iosContainer]: isIOS,
            })}
            aria-label={`Sensical, ${guestProfileTitle}`}
            aria-describedby={`${PROFILE_CARDS_CONTAINER} ${GUEST_PROFILE_FOOTER}`}
          >
            <img
              src={logoUri?.replace('Black', 'Green')}
              alt="Sensical"
              className={styles.logo}
              style={{ display: logoUri ? 'inline-block' : 'none' }}
              loading="eager"
            />
            <GuestCards history={history} showButtons={hideSpinner} />
          </FocusDiv>
        </div>
      </div>
    </Page>
  );
};
GuestProfile.propTypes = {
  history: PropTypes.object,
};

export default withRouter(GuestProfile);
