import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
} from 'react';
import ReactScrollDetector from 'react-scroll-detector';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { focusManager } from '@accedo/vdkweb-navigation';
import { Page, FocusDiv } from '@accedo/vdkweb-tv-ui';
import FavoriteButton from '#/widgets/ui/Buttons/FavoriteButton';

import {
  performSearch,
  getVideosCount,
  saveSearchQuery,
  clearSearchQuery,
  clearSearchData,
  clearAssetData,
  // playlist
  clearPlaylistData,
  clearPlaylistQuery,
  savePlaylistQuery,
  performPlaylistQuery,
} from '#/redux/modules/brightcove/actions';
import { toggleLoginSpinner } from '#/redux/modules/spinner/actions';
import {
  getContentById,
  clearContentDetails,
  sendScreenView,
} from '#/redux/modules/firestore/actions';
import { setVideoContent } from '#/redux/modules/appPlayer/actions';

import {
  getSensicalTvConfig,
  getCurrentAgeMetadata,
} from '#/redux/modules/accedoOne/selectors';
import {
  // getAppStarted,
  getDeepLinkStarted,
} from '#/redux/modules/lifecycle/selectors';
import {
  getContainerData,
  getSearchQueryParams,
  getSearchData,
  getLoadingState,
  getPlaylistQueryParams,
  getPlaylistData,
} from '#/redux/modules/brightcove/selectors';
import { getContentDetails } from '#/redux/modules/firestore/selectors';
import {
  getCurrentProfileAgeGroup,
  getCurrentProfile,
  getCurrentProfileId,
} from '#/redux/modules/onboarding/selectors';
import { getFullscreenBookmarkedVideoContent } from '#/redux/modules/appPlayer/selectors';

import { hex2rgba } from '#/utils/hex2rgba';
import { CONTENT_DETAILS, MOBILEBOTTOMTABBAR } from '#/utils/navigationHelper';
import { TabView } from '#/components';
import VideoCard from '#/components/VideoCard/VideoCard';

import {
  videoAnalytics,
  ageGroupsKeys,
  ageCategoryGroup,
  mainScreenNames,
  signupStates,
  GUEST_MODAL_TYPE,
} from '#/config/constants';
import About from './about/About';
import Logo from '#/static/images/icons/logo-login.svg';
import BackArrowButton from '#/widgets/ui/Buttons/BackArrowButton';
import styles from './contentDetails.scss';

import Scroll from '#/components/Scroll/Scroll';
import useUpdateScroll from '#/hooks/useUpdateScroll';
import { isWorkstation, isTouchDevice } from '#/utils/componentStyleConfig';
import { getGridItemNav, getItemId } from '#/utils/gridNavUtils';

import {
  getCustomDataShowsFavoritesById,
  getCustomDataPodcastsFavoritesById,
  getCustomDataVideosBlocked,
  getCustomDataShowsBlocked,
} from '#/redux/modules/identity/selectors';
import { getParentUserId } from '#/redux/modules/identity/identityUser/selectors';

import {
  updateShowFavoriteById,
  updatePodcastFavoriteById,
} from '#/redux/modules/identity/actions';

import assembleCurrentVideo from '#/utils/assembleCurrentVideo';

import { isGuestMode } from '#/redux/modules/user/selectors';
import { actions as modalActions } from '#/redux/modules/modal/actions';
import { actions as onboardingActions } from '#/redux/modules/onboarding/actions';
import { logout } from '#/redux/modules/identity/identityGuest/actions';
import { authFlowRoutes } from '#/utils/routes';
import info from '#/static/images/icons/info_default.svg';
import exitBtn from '#/static/images/icons/exit-black.svg';
import BottomTabBar from '#/components/BottomTabBar/BottomTabBar';
import { exitDeeplinkWorkflow } from '#/redux/modules/lifecycle/actions';
import { isContentBlocked } from '../../utils/isContentBlocked';

const {
  PAGE,
  CONTAINER,
  TABS,
  FAVORITE_BUTTON,
  TABVIEW_CONTAINER,
  TAGS_CONTAINER,
  TAB_ITEM_0,
} = CONTENT_DETAILS;
const navMap = {
  PAGE: {
    id: PAGE,
    forwardFocus: CONTAINER,
  },
  CONTAINER: {
    id: CONTAINER,
    forwardFocus: TABVIEW_CONTAINER,
  },
  FAVORITE_BUTTON: {
    id: FAVORITE_BUTTON,
    nextdown: TABS,
    parent: CONTAINER,
  },
  TABS: {
    id: TABS,
    nextdown: TABVIEW_CONTAINER,
    nextup: FAVORITE_BUTTON,
    parent: CONTAINER,
    useLastFocus: true,
    forwardFocus: TAB_ITEM_0,
  },
  TABVIEW_CONTAINER: {
    id: TABVIEW_CONTAINER,
    nextup: TABS,
    parent: CONTAINER,
    forwardFocus: getItemId(0),
  },
  TAGS_CONTAINER: {
    id: TAGS_CONTAINER,
    nextup: TABS,
    useLastFocus: true,
  },
};
const ContentDetails = ({ match, route }) => {
  const dispatch = useDispatch();
  const currentProfile = useSelector(getCurrentProfile);

  const id = match?.params?.id;
  const isCreator = route?.isCreator;
  const playlistId = match?.params?.playlistId || '0';
  const [tabId, setTabId] = useState(1);
  const [count, setCount] = useState(0);
  const [screenNameSent, setScreenNameSent] = useState(false);

  const [focusedElement, updateScroll] = useUpdateScroll(
    navMap.TABVIEW_CONTAINER,
  );
  const gridRef = useRef();
  const loadingState = useSelector(state => getLoadingState(state));
  const fullscreenBookmark = useSelector(state =>
    getFullscreenBookmarkedVideoContent(state),
  );

  const content = useSelector(state => getContentDetails(state));
  const fetchPlaylist = playlistId !== '0';
  const saveQuery = fetchPlaylist ? savePlaylistQuery : saveSearchQuery;

  const selectedAgeGroup = useSelector(state =>
    getCurrentProfileAgeGroup(state),
  );
  const selectedAgeMetadata = useSelector(state =>
    getCurrentAgeMetadata(state),
  );

  // Deep linking ------------------------------------------------------------------
  const deepLinkStarted = useSelector(getDeepLinkStarted);
  const profileId = useSelector(getCurrentProfileId);
  const parentUserId = useSelector(getParentUserId);

  const customDataShowsBlocked = useSelector(getCustomDataShowsBlocked);
  const customDataVideosBlocked = useSelector(getCustomDataVideosBlocked);

  const HandleAgeMatch = () => {
    if (!deepLinkStarted) {
      return true;
    }
    if (profileId === parentUserId) {
      return false;
    }
    switch (selectedAgeGroup) {
      case ageGroupsKeys.TWO_UP:
        return content?.ages.includes(ageCategoryGroup.MIN_AGE_TWO_UP);
      case ageGroupsKeys.FIVE_UP:
        return content?.ages.includes(ageCategoryGroup.MIN_AGE_FIVE_UP);
      default:
        return content?.ages.includes(ageCategoryGroup.MIN_AGE_EIGHT_UP);
    }
  };
  const deepLinkAgeMatch = HandleAgeMatch();
  const onNavigateBack = useMemo(() => {
    return deepLinkStarted ? () => dispatch(exitDeeplinkWorkflow()) : () => {};
  }, [deepLinkStarted]);

  // TODO: review if this effect can be removed
  useEffect(() => {
    if (!deepLinkStarted) {
      return;
    }
    dispatch(onboardingActions.setDeepLinkCorrectAge(!!deepLinkAgeMatch));
  }, [content, deepLinkStarted, deepLinkAgeMatch]);
  // Deep linking END --------------------------------------------------------------

  const ageGradient = useMemo(() => {
    if (!isWorkstation()) {
      return `linear-gradient(180deg, rgba(233, 248, 221, 0.8) 0%, rgba(233, 248, 221, 0.8) 24.91%, ${selectedAgeMetadata.colorScheme.backgroundPrimary} 64.72%)`;
    }
    if (selectedAgeMetadata?.colorScheme) {
      return `linear-gradient(180deg, rgba(14, 14, 14, 1) 2%, rgba(0,0,0,0) 15%, 
        ${hex2rgba(
          selectedAgeMetadata.colorScheme.backgroundPrimary,
          0.1,
        )} 30%, 
        ${hex2rgba(selectedAgeMetadata.colorScheme.backgroundPrimary)} 55%
      )`;
    }
    return '';
  }, [selectedAgeMetadata]);

  const ageCategory = useMemo(() => {
    if (deepLinkStarted) {
      return content?.ages?.[0];
    }
    switch (selectedAgeGroup) {
      case ageGroupsKeys.TWO_UP:
        return ageCategoryGroup.MIN_AGE_TWO_UP;
      case ageGroupsKeys.FIVE_UP:
        return ageCategoryGroup.MIN_AGE_FIVE_UP;
      default:
        return ageCategoryGroup.MIN_AGE_EIGHT_UP;
    }
  }, [selectedAgeGroup, content]);

  let queryData;
  let queryParams;

  if (fetchPlaylist) {
    queryData = useSelector(state => getPlaylistData(state));
    queryParams = useSelector(state => getPlaylistQueryParams(state));
    useEffect(() => {
      if (!content) {
        return;
      }

      if (!queryParams.creator_id && queryParams.playlistId !== playlistId) {
        const queryObj = { ...queryParams };
        if (isCreator) {
          queryObj.creator_id = id;
        } else {
          queryObj.limit = queryData.limit;
          queryObj.offset = 0;
          queryObj.playlistId = playlistId;
          queryObj.id = id;
        }
        dispatch(savePlaylistQuery(queryObj));
      } else if (queryParams.playlistId === playlistId) {
        dispatch(performPlaylistQuery([queryParams]));
      }
    }, [queryParams, content]);
  } else {
    queryData = useSelector(state => getSearchData(state));
    queryParams = useSelector(state => getSearchQueryParams(state));
    useEffect(() => {
      if (!content) {
        return;
      }

      if (!queryParams.creator_id && queryParams.show_id !== id) {
        const queryObj = { ...queryParams };
        if (isCreator) {
          queryObj.creator_id = id;
        } else {
          // Use min age if ageCategory does not match
          // Likely to be from a deeplink
          const age = content?.ages?.includes(ageCategory)
            ? ageCategory
            : Math.min(...content.ages);

          queryObj.show_id = id;
          queryObj.limit = queryData.limit;
          queryObj.offset = 0;
          queryObj.age = age;
          queryObj.season_number =
            content?.seasons?.length > 0
              ? content?.seasons[0].season_number
              : null;
        }
        dispatch(saveSearchQuery(queryObj));
      } else if (queryParams.show_id === id) {
        dispatch(performSearch([queryParams]));
      }
    }, [queryParams, content]);
  }

  const showFavorite = useSelector(state =>
    getCustomDataShowsFavoritesById(state, id),
  );
  // const showsFavorites = useSelector(getCustomDataShowsFavorites);

  const podcastFavorite = useSelector(state =>
    getCustomDataPodcastsFavoritesById(state, id),
  );

  const containerItems =
    useSelector(state => getContainerData(state, [queryData?.containerId])) ||
    {};

  const { logoUri = Logo } = useSelector(getSensicalTvConfig) || {};

  const tabSchema = useMemo(() => {
    let seasons = fetchPlaylist ? [] : content?.seasons || [];
    seasons = seasons.map(season => ({
      label:
        season.season_number > 0
          ? `Season ${season.season_number}`
          : season.tabTitle,
      id: season.season_number,
      hasInfoIcon: false,
    }));

    if (seasons?.length === 0 || isCreator) {
      seasons = [{ label: 'Videos', id: 1 }];
    }

    seasons.push({
      label: `About ${content?.name || ''}`,
      id: -1,
      hasInfoIcon: true,
    });

    return seasons;
  }, [content]);

  const items = useMemo(
    () =>
      containerItems && queryData?.containerId
        ? containerItems[queryData?.containerId]
        : [],
    [containerItems, queryData],
  );

  const videos = useMemo(() => {
    // if current playing video matches item.id, AND current playing video matches fullscreen bookmarked content
    // pass prop to video card to do back navigate instead of setVideoContent and route to player
    return items?.map((item, index) => (
      <VideoCard
        fromDetails
        onItemFocus={updateScroll}
        key={getItemId(index)}
        nav={getGridItemNav(TABVIEW_CONTAINER, index, 4, items)}
        referenceId={item.reference_id}
        imgUrl={
          item.custom_fields?.episode_key_art_horizontal ||
          item.custom_fields?.episode_key_art_square ||
          item.thumbnail
        }
        title={item.name}
        // seasonInfo={item.seasonInfo} not needed in current design
        showTitle={item.custom_fields?.subtitle}
        duration={item.duration}
        classes={{
          videoCard: styles.videoCard,
          videoCardInfo: styles.videoCardInfo,
          videoCardProgress: styles.videoCardProgress,
          videoCardImage: styles.videoCardImage,
        }}
        roundedCorners={selectedAgeMetadata?.tileRounded}
        onClick={() => {
          dispatch(clearAssetData());
          dispatch(
            setVideoContent({
              playlists: [queryData?.containerId],
              currentPlaylistIndex: 0,
              currentVideoIndex: index,
              currentVideo: assembleCurrentVideo(item),
              initiation: videoAnalytics.initiation.MANUAL,
            }),
          );
        }}
        goBack={fullscreenBookmark?.currentVideo?.videoId === Number(item.id)}
        podcastShow={
          item.custom_fields?.media_type?.toLowerCase() === 'podcast'
        }
        blocked={isContentBlocked(currentProfile, item)}
      />
    ));
  }, [items, customDataShowsBlocked, customDataVideosBlocked]);

  useEffect(() => {
    dispatch(getContentById(id, isCreator ? 'channels' : 'shows'));
    !isCreator ? dispatch(getVideosCount([{ show_id: id }])) : null;

    return () => {
      dispatch(clearSearchData());
      dispatch(clearSearchQuery());
      dispatch(clearContentDetails());
      dispatch(clearPlaylistQuery());
      dispatch(clearPlaylistData());
    };
  }, []);

  useEffect(() => {
    if (content && !screenNameSent) {
      const seasons = content?.seasons || [];
      const screen = seasons?.length !== 0 ? 'showdetails' : 'creatordetails';
      dispatch(sendScreenView(mainScreenNames[screen]));
      setScreenNameSent(true);
    }
  }, [content]);

  useEffect(() => {
    if (loadingState) {
      dispatch(toggleLoginSpinner(true));
    } else if (loadingState === false) {
      requestAnimationFrame(() => {
        dispatch(toggleLoginSpinner(false));
      });
    }
  }, [loadingState]);

  useEffect(() => {
    !isCreator ? setCount(queryData?.count) : setCount(items?.length);
  }, [queryData, items]);

  useEffect(() => {
    const nextNavDown = tabId === -1 ? TAGS_CONTAINER : TABVIEW_CONTAINER;
    navMap.TABS.nextdown = nextNavDown;
  }, [tabId]);

  useEffect(() => {
    if (content && items?.length) {
      focusManager.changeFocus(getItemId(0));
    }
  }, [items, content]);

  const onTabChange = useCallback(seasonNumber => {
    if (!fetchPlaylist && seasonNumber > 0) {
      dispatch(clearSearchData());
      const queryObj = {
        ...queryParams,
        show_id: id,
        season_number: seasonNumber,
        limit: queryData.limit,
        page: 0,
        offset: 0,
        age: ageCategory,
      };
      dispatch(saveSearchQuery(queryObj));
    }
    setTabId(seasonNumber);
  });

  const handleScrollBottom = useCallback(() => {
    if (
      !loadingState &&
      (queryParams.page < queryData.totalPages - 1 || queryData?.hasMoreResults)
    ) {
      const queryObj = {
        ...queryParams,
        // eslint-disable-next-line no-plusplus
        page: ++queryParams.page,
      };
      queryObj.offset = queryData.limit * queryParams.page;
      dispatch(saveQuery(queryObj));
    }
  });

  const isInGuestMode = useSelector(isGuestMode);
  const onToggleShowFavorite = useCallback(async () => {
    if (isInGuestMode) {
      dispatch(
        modalActions.openModal({
          modalId: 'GuestProfileModal',
          modalData: {
            modalType: GUEST_MODAL_TYPE.favoritesBlocked,
          },
          successAction: () => {
            dispatch(
              onboardingActions.setSignupState({
                signupState: signupStates.CREATE_ACCOUNT,
              }),
            );
            dispatch(logout(authFlowRoutes.ACCOUNT_CREATION));
          },
        }),
      );
    } else if (content.media_type?.toLowerCase() === 'podcast') {
      requestAnimationFrame(() => {
        dispatch(toggleLoginSpinner(true));
      });
      const podcastData = {
        id,
        name: content?.name,
        key_art_horizontal_image_path: content?.key_art_horizontal_image_path,
        key_art_square_image_path: content?.key_art_square_image_path,
        startDate: content?.start_date?.seconds,
        endDate: content?.end_date?.seconds,
      };
      await dispatch(
        updatePodcastFavoriteById(id, podcastFavorite === null, podcastData),
      );
      requestAnimationFrame(() => {
        dispatch(toggleLoginSpinner(false));
      });
    } else {
      requestAnimationFrame(() => {
        dispatch(toggleLoginSpinner(true));
      });
      const showData = {
        id,
        name: content?.name,
        key_art_horizontal_image_path: content?.key_art_horizontal_image_path,
        startDate: content?.start_date?.seconds,
        endDate: content?.end_date?.seconds,
      };
      await dispatch(
        updateShowFavoriteById(id, showFavorite === null, showData),
      );
      requestAnimationFrame(() => {
        dispatch(toggleLoginSpinner(false));
      });
    }
  }, [content, showFavorite, podcastFavorite, id, isInGuestMode]);

  return (
    <ReactScrollDetector
      debounceTime={500}
      accuracy={70}
      onScrollBottom={handleScrollBottom}
    >
      <Page
        className={classNames(styles.mainContainer, {
          [styles.isTv]: !isWorkstation(),
        })}
        style={{
          background: hex2rgba(
            selectedAgeMetadata.colorScheme.backgroundPrimary,
          ),
        }}
        autofocus
        nav={navMap.PAGE}
      >
        <FocusDiv className={styles.contentContainer} nav={navMap.CONTAINER}>
          {isTouchDevice() && tabId !== -1 ? (
            <div className={styles.mobileNavBar}>
              <img
                src={info}
                onClick={() => onTabChange(-1)}
                className={styles.mobileInfoIcon}
                alt="Info"
                aria-hidden="true"
              />
              <div className={styles.mobileNavTitle}>
                <p className={styles.titleText}>{content?.name}</p>
                {id && deepLinkAgeMatch && (
                  <FavoriteButton
                    isFavorited={
                      content?.media_type?.toLowerCase() === 'podcast'
                        ? podcastFavorite !== null
                        : showFavorite !== null
                    }
                    onClick={onToggleShowFavorite}
                    nav={navMap.FAVORITE_BUTTON}
                    favoriteButtonClassName={styles.favoriteButton}
                  />
                )}
              </div>
              <BackArrowButton
                black
                className={styles.backButton}
                onNavigateBack={onNavigateBack}
              />
            </div>
          ) : (
            <BackArrowButton
              className={styles.backButton}
              onNavigateBack={onNavigateBack}
            />
          )}
          <img src={logoUri} alt="Sensicle" className={styles.logo} />
          <div
            className={styles.videoOverlay}
            style={{ background: ageGradient }}
          />
          <div className={styles.heroContainerWrapper}>
            <img
              src={
                content?.key_art_horizontal_image_path ||
                content?.key_art_square_image_path
              }
              className={styles.heroContainer}
              alt="Hero"
            />
          </div>

          <div className={styles.contentDetails}>
            <div className={styles.detailsContainer}>
              <div className={styles.titleWrapper}>
                <p className={styles.titleText}>{content?.name}</p>
                {id && deepLinkAgeMatch && (
                  <FavoriteButton
                    isFavorited={
                      content?.media_type?.toLowerCase() === 'podcast'
                        ? podcastFavorite !== null
                        : showFavorite !== null
                    }
                    onClick={onToggleShowFavorite}
                    nav={navMap.FAVORITE_BUTTON}
                    favoriteButtonClassName={styles.favoriteButton}
                  />
                )}
              </div>
              <p className={styles.videoText}>{count} Videos</p>
            </div>
            <TabView
              className={styles.tabs}
              tabsSchema={tabSchema}
              currentTabId={tabId}
              onTabClick={onTabChange}
              nav={navMap.TABS}
            />
            <FocusDiv nav={navMap.TABVIEW_CONTAINER}>
              {tabId === -1 ? (
                isTouchDevice() ? (
                  <div className={styles.aboutTab}>
                    <img
                      src={exitBtn}
                      onClick={() => onTabChange(1)}
                      className={styles.aboutTabExit}
                      alt="Exit"
                      aria-hidden="true"
                    />

                    <About
                      content={content}
                      ageTheme={selectedAgeMetadata}
                      nav={navMap.TAGS_CONTAINER}
                    />
                  </div>
                ) : (
                  <About
                    content={content}
                    ageTheme={selectedAgeMetadata}
                    nav={navMap.TAGS_CONTAINER}
                  />
                )
              ) : (
                <div ref={gridRef}>
                  <Scroll
                    enabled={!isWorkstation()}
                    focusedElement={focusedElement}
                    focusScrollPush={150}
                    headerHeightOverride={
                      gridRef.current?.getBoundingClientRect().top
                    }
                  >
                    <div className={styles.grid}>{videos}</div>
                  </Scroll>
                </div>
              )}
            </FocusDiv>
          </div>
        </FocusDiv>
        {isTouchDevice() ? (
          <div
            id={MOBILEBOTTOMTABBAR.CONTAINER}
            style={{
              backgroundColor: selectedAgeMetadata?.colorScheme?.toolbarPrimary,
            }}
          >
            <BottomTabBar customStyles={styles} />
          </div>
        ) : null}
      </Page>
    </ReactScrollDetector>
  );
};

ContentDetails.propTypes = {
  match: PropTypes.object,
  route: PropTypes.object,
};

export default ContentDetails;
