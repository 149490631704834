import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { InfoForm, InfoInput } from '#/widgets/forms';
import { Button } from '#/widgets/ui';
import ErrorDispatcher from '../../ErrorDispatcher/ErrorDispatcher';

import {
  ASYNC_RECOVER_PASSWORD,
  recoverPassword,
} from '#/redux/modules/identity/actions';
import { sendScreenView } from '#/redux/modules/firestore/actions';
import { authScreenNames } from '#/config/constants';
import Skiddle from '#/static/images/icons/skiddle-green.svg';
import styles from './ForgotPassword.scss';

const EmailSent = () => {
  return (
    <div className={styles.content}>
      <img className={styles.logo} src={Skiddle} alt="Logo" />
      <h1 className={styles.title}>Email Sent</h1>
      <p className={styles.text}>Email sent, please check your inbox.</p>
    </div>
  );
};

const ForgotPasswordEmailEntry = ({ onSend }) => {
  const [email, setEmail] = useState(null);

  return (
    <div className={styles.content}>
      <img className={styles.logo} src={Skiddle} alt="Logo" />
      <h1 className={styles.title}>Forgot Password</h1>
      <p className={styles.text}>
        Enter your email address, and we'll send instructions to reset your
        password.
      </p>
      <InfoForm
        onChange={(data, isValid) => {
          isValid && setEmail(data.email);
        }}
      >
        <InfoInput
          fieldName="email"
          fieldLabel="Your email address"
          inputAttributes={{ type: 'email', required: 'required' }}
        />
        <Button className={styles.sendButton} onClick={() => onSend(email)}>
          Send Email
        </Button>
      </InfoForm>
    </div>
  );
};
ForgotPasswordEmailEntry.propTypes = {
  onSend: PropTypes.func,
};

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const [success, setSuccess] = useState(false);

  const sendEmail = useCallback(async email => {
    await dispatch(recoverPassword(email));
    setSuccess(true);
  }, []);

  useEffect(() => {
    dispatch(sendScreenView(authScreenNames.FORGOT_PASSWORD));
  }, []);

  return (
    <div className={styles.forgotPassword}>
      <ErrorDispatcher errorId={ASYNC_RECOVER_PASSWORD} />
      {success ? (
        <EmailSent />
      ) : (
        <ForgotPasswordEmailEntry onSend={sendEmail} />
      )}
    </div>
  );
};

export default ForgotPassword;
