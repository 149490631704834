import React from 'react';

import { useSelector } from 'react-redux';
import { authFlowRoutes } from '#/utils/routes';
import { signupState } from '#/redux/modules/onboarding/selectors';
import RedirectWithQuery from '#/components/App/RedirectWithQuery';
import { signupStates } from '#/config/constants';

const AuthFlow = () => {
  const onboardingPage = useSelector(signupState);
  switch (onboardingPage) {
    case signupStates.WELCOME:
      // NOTE: Welcome route "/welcome" is no longer in use.
      // Leave this to redirect all to "/auth/guestprofile"
      // which is the new landing page for the application.
      return <RedirectWithQuery to={authFlowRoutes.GUEST_PROFILE} />;
    case signupStates.GUEST_WELCOME:
      return <RedirectWithQuery to={authFlowRoutes.GUEST_PROFILE} />;
    case signupStates.CREATE_PROFILE:
      return <RedirectWithQuery to={authFlowRoutes.PROFILE_CREATION} />;
    case signupStates.CREATE_ACCOUNT:
      return <RedirectWithQuery to={authFlowRoutes.ACCOUNT_CREATION} />;
    case signupStates.SIGN_UP:
      return <RedirectWithQuery to={authFlowRoutes.SIGN_UP} />;
    default:
      return <RedirectWithQuery to={authFlowRoutes.GUEST_PROFILE} />;
  }
};

export default AuthFlow;
