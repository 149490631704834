import React from 'react';

const title =
  'Congrats! Your child can access content appropriate for 8 years old and above.';
const content =
  '8 year old kids and above can explore their interest and search for a wide variety of topics, including songs, cartoons, crafts, and more. Our systems work hard to exclude content not suitable for young kids, as all videos have been manually reviewed. If you find something inappropriate that we missed, you can block it or flag it for fast review.';

const AgeAppropriateModal = () => {
  return (
    <>
      <h4>{title}</h4>
      <p>{content}</p>
    </>
  );
};

export default AgeAppropriateModal;
