/**
 * The client side entry where the app is being rendered
 * to the selected DOM element.
 *
 * Allowing for hot module replacement in DEVELOPMENT mode.
 */
import '#/config/preflight';
import xdk from '@accedo/xdk-core';
import { focusManager } from '@accedo/vdkweb-navigation';
import TagManager from 'react-gtm-module';

import React from 'react';
import ReactDOM from 'react-dom';
import { renderRoutes } from 'react-router-config';

import historyConfig from '#/config/history';
import { envConfig } from '#/config/env';
import App from '#/components/App/App';
import Route from '#/components/App/Route';
import { configureStore } from '#/redux/store';
import { getClientServices } from '#/services';
import { cleanAccedoOneSessionInClient } from '#/services/accedoOne/accedoOne';

const { appHistory, Router } = historyConfig;

// require('./addWhyDidYouRender').default(); // NOSONAR

cleanAccedoOneSessionInClient();

const { store } = configureStore({
  historyType: appHistory,
  initialState: undefined,
  services: getClientServices(),
});
// The app render function which will mount the application's
// component tree to the DOM node with id 'root'.
const render = async () => {
  await xdk.load();
  xdk.environment.addEventListener(xdk.environment.SYSTEM.KEYDOWN, ({ id }) => {
    switch (id) {
      case 'device:vkey:right':
      case 'device:vkey:down':
      case 'device:vkey:left':
      case 'device:vkey:up': {
        const direction = `next${id.split(':')[2]}`;
        focusManager.navigateFocus(direction);
        break;
      }
      case 'device:vkey:ok':
        focusManager.click();
        break;
      case 'device:vkey:back':
        break;
      default:
        break;
    }
  });

  // Needed to prevent browser default behavior
  // DO NOT REMOVE
  document.onkeydown = e => {
    if (e.key === 'Enter') {
      return false;
    }
  };

  // Google tag manager
  TagManager.initialize({ gtmId: envConfig.gtmId });

  // Need to require these on the fly for HMR to work.
  const routes = require('#/config/routes').default;

  const mountNode = document.getElementById('root');
  const router = (
    <Router>
      <Route content={renderRoutes(routes)} />
    </Router>
  );
  const method = window.__ssr ? 'hydrate' : 'render';

  ReactDOM[method](<App store={store} content={router} />, mountNode);
};

// If we're in dev mode we'll allow for hot reloading of the
// application whenever any module below our 'routes' module is changing.
if (__LOCAL__ && module.hot) {
  module.hot.accept('#/config/routes', render);
}

// Render the component tree to the DOM
// unless it is a test run.
if (__TEST__) {
  module.exports = render;
} else {
  render();
}
