import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FocusDiv } from '@accedo/vdkweb-tv-ui';
import classNames from 'classnames';

import ShowCard from '#/components/ShowCard/ShowCard';
import VideoCard from '#/components/VideoCard/VideoCard';
import { modalScreenNames, HIDDEN_CONTENT } from '#/config/constants';
import { parentZone } from '#/config/strings';
import useUpdateScroll from '#/hooks/useUpdateScroll';
import { getContentBlocking } from '#/redux/modules/accedoOne/selectors';
import {
  loadCustomDataVideosBlocked,
  loadCustomDataShowsBlocked,
  updateVideosBlockedById,
  updateShowsBlockedById,
} from '#/redux/modules/identity/actions';
import {
  sendScreenView,
  sendUnblockContent,
} from '#/redux/modules/firestore/actions';
import { actions as modalActions } from '#/redux/modules/modal/actions';

import {
  getCurrentProfile,
  getProfiles,
} from '#/redux/modules/onboarding/selectors';
import { isWorkstation } from '#/utils/componentStyleConfig';
import { getGridItemNav, getItemId } from '#/utils/gridNavUtils';
import { FILTER_CONTENT } from '#/utils/navigationHelper';
import { Button } from '#/widgets/ui';
import ProfileSwitcher from '../LearningReports/ProfileSwitcher/ProfileSwitcher';
import HideIcon from '../hide-icon.svg';
import styles from './ContentFiltering.scss';

const { CONTAINER, GRID_CONTAINER } = FILTER_CONTENT;

const VideoMapper = ({
  item,
  nav,
  tileRounded,
  onFocus,
  setUnblockVideo,
  handleBlocking,
}) => {
  const dispatch = useDispatch();
  const contentBlocking = useSelector(getContentBlocking);
  const {
    allowContentModal: { title, description, ctaAllowContent },
  } = contentBlocking;
  const unblockVideo = async () => {
    dispatch(
      modalActions.openModal({
        modalId: 'ConfirmModal',
        successAction: async () => {
          const videoAnalyticsData = {
            video_id: `${item.id}`,
          };
          setUnblockVideo(item);
          await dispatch(sendUnblockContent(videoAnalyticsData));
          handleBlocking.current = item;
        },
        modalData: {
          title,
          message: description,
          saveButtonText: ctaAllowContent,
        },
      }),
    );
    dispatch(
      sendScreenView(`${modalScreenNames.ContentUnblockingModal} (${item.id})`),
    );
  };
  return (
    <div className={styles.videoMapperContainer}>
      <VideoCard
        onItemFocus={onFocus}
        nav={nav}
        referenceId={item.reference_id}
        imgUrl={
          item.custom_fields?.episode_key_art_horizontal ||
          item.custom_fields?.episode_key_art_square ||
          item.thumbnail
        }
        title={item.name}
        showTitle={item.custom_fields?.subtitle}
        duration={item.duration}
        classes={{
          videoCard: styles.videoCard,
          videoCardInfo: styles.videoCardInfo,
          videoCardProgress: styles.videoCardProgress,
          videoCardImage: styles.videoCardImage,
        }}
        roundedCorners={tileRounded}
      />
      <Button onClick={unblockVideo} className={styles.allowButton}>
        {ctaAllowContent}
      </Button>
    </div>
  );
};
VideoMapper.propTypes = {
  item: PropTypes.object,
  nav: PropTypes.object,
  onFocus: PropTypes.func,
  tileRounded: PropTypes.bool,
  setUnblockVideo: PropTypes.any,
  handleBlocking: PropTypes.any,
};

const ShowMapper = ({
  item,
  nav,
  tileRounded,
  onFocus,
  setUnblockShow,
  handleBlocking,
}) => {
  const dispatch = useDispatch();
  const contentBlocking = useSelector(getContentBlocking);
  const getShowPlaylistId = useCallback(show => show?.playlist_id || '0', []);
  const {
    allowContentModal: { title, description, ctaAllowContent },
  } = contentBlocking;
  const unblockShow = async () => {
    dispatch(
      modalActions.openModal({
        modalId: 'ConfirmModal',
        successAction: async () => {
          const showAnalyticsData = {
            show_id: `${item.id}`,
          };
          setUnblockShow(item);
          await dispatch(sendUnblockContent(showAnalyticsData));
          handleBlocking.current = item;
        },
        modalData: {
          title,
          message: description,
          saveButtonText: ctaAllowContent,
        },
      }),
    );
  };
  return (
    <div className={styles.showMapperContainer}>
      <ShowCard
        nav={nav}
        id={item.id}
        name={item.name}
        imgUrl={item.key_art_horizontal_image_path}
        className={styles.showCard}
        exploreClassName={styles.exploreShowCard}
        roundedCorners={tileRounded}
        onItemFocus={onFocus}
        playlistId={getShowPlaylistId(item)}
      />
      <Button onClick={unblockShow} className={styles.allowButton}>
        {ctaAllowContent}
      </Button>
    </div>
  );
};
ShowMapper.propTypes = {
  item: PropTypes.object,
  nav: PropTypes.object,
  onFocus: PropTypes.func,
  tileRounded: PropTypes.bool,
  setUnblockShow: PropTypes.any,
  handleBlocking: PropTypes.any,
};

const Grid = ({
  items,
  ItemMapper,
  parentContainerNavId,
  ageTheme,
  onFocus,
  setUnblockVideo,
  setUnblockShow,
  handleBlocking,
}) => {
  const gridNavMap = {
    id: GRID_CONTAINER,
    parent: parentContainerNavId,
    forwardFocus: getItemId(0),
  };
  return (
    <FocusDiv nav={gridNavMap} classsName={styles.contentContainer}>
      <div
        className={classNames(styles.gridCardContainer, {
          [styles.isTv]: !isWorkstation(),
        })}
      >
        {items.map((item, index) => (
          <ItemMapper
            item={item}
            key={getItemId(index)}
            nav={getGridItemNav(GRID_CONTAINER, index, 4, items)}
            tileRounded={ageTheme?.tileRounded}
            onFocus={onFocus}
            setUnblockVideo={setUnblockVideo}
            setUnblockShow={setUnblockShow}
            handleBlocking={handleBlocking}
          />
        ))}
      </div>
    </FocusDiv>
  );
};

Grid.propTypes = {
  ageTheme: PropTypes.shape({ tileRounded: PropTypes.bool }),
  items: PropTypes.array,
  ItemMapper: PropTypes.func,
  parentContainerNavId: PropTypes.string,
  onFocus: PropTypes.func,
  setUnblockVideo: PropTypes.any,
  setUnblockShow: PropTypes.any,
  handleBlocking: PropTypes.any,
};

export const ContentFiltering = () => {
  const dispatch = useDispatch();
  const [unblockVideo, setUnblockVideo] = useState(undefined);
  const [unblockShow, setUnblockShow] = useState(undefined);
  const handleBlocking = useRef(null);
  const currentProfile = useSelector(getCurrentProfile) || {};
  const [selectedProfileId, setSelectedProfileId] = useState(
    currentProfile?.id,
  );
  const prevProfileId = useRef(currentProfile?.id);
  const profiles = useSelector(getProfiles) || [];
  const contentBlocking = useSelector(getContentBlocking);
  const [updateScroll] = useUpdateScroll(CONTAINER);
  const {
    parentZoneScreen: {
      profileSelectionTitle,
      blockedContentDescription,
      noBlockedContentTitle,
      noBlockedContentDescription,
      hideContentIconTitle,
      hideContentIconDescription,
      blockedShowsTitle,
      blockedVideosTitle,
    },
  } = contentBlocking;

  const gridProps = {
    parentContainerNavId: CONTAINER,
  };

  const profileMap = useMemo(() => {
    if (!profiles?.length) {
      return {};
    }

    return profiles.reduce(
      (acc, curr) => ({
        ...acc,
        [curr?.id]: curr,
      }),
      {},
    );
  }, [profiles?.length]);
  const [videosBlocked, setVideosBlocked] = useState(
    profileMap[selectedProfileId]?.videosBlocked,
  );
  const [showsBlocked, setShowsBlocked] = useState(
    profileMap[selectedProfileId]?.showsBlocked,
  );

  // const { videosBlocked, showsBlocked } = profileMap[selectedProfileId] || {};

  useEffect(() => {
    let tempShows = null;
    let tempVideos = null;
    if (handleBlocking.current) {
      tempShows = showsBlocked.filter(
        show => show?.id !== handleBlocking.current?.id,
      );
      tempVideos = videosBlocked.filter(
        video => video?.id !== handleBlocking.current?.id,
      );
    }
    setVideosBlocked(
      tempVideos || profileMap[selectedProfileId]?.videosBlocked,
    );
    setShowsBlocked(tempShows || profileMap[selectedProfileId]?.showsBlocked);
    tempShows = null;
    tempVideos = null;
  }, [handleBlocking.current, selectedProfileId]);

  useEffect(() => {
    dispatch(loadCustomDataVideosBlocked(selectedProfileId));
    dispatch(loadCustomDataShowsBlocked(selectedProfileId));
    dispatch(
      sendScreenView(`${modalScreenNames.ContentBlockingContentControls}`),
    );
  }, []);

  useEffect(() => {
    const profileIdChanged = prevProfileId.current !== selectedProfileId;
    if (profileIdChanged) {
      dispatch(loadCustomDataVideosBlocked(selectedProfileId));
      dispatch(loadCustomDataShowsBlocked(selectedProfileId));

      prevProfileId.current = selectedProfileId;
    }
  }, [selectedProfileId]);

  useEffect(() => {
    if (unblockVideo) {
      dispatch(updateVideosBlockedById(unblockVideo.id, false, unblockVideo));
      dispatch(loadCustomDataVideosBlocked(selectedProfileId));
    }
  }, [unblockVideo, handleBlocking.current]);

  useEffect(() => {
    if (unblockShow) {
      dispatch(updateShowsBlockedById(unblockShow.id, false, unblockShow));
      dispatch(loadCustomDataShowsBlocked(selectedProfileId));
    }
  }, [unblockShow, handleBlocking.current]);

  useEffect(() => {
    dispatch(loadCustomDataVideosBlocked(selectedProfileId));
  }, [videosBlocked, handleBlocking.current]);

  useEffect(() => {
    dispatch(loadCustomDataShowsBlocked(selectedProfileId));
  }, [showsBlocked, handleBlocking.current]);

  return (
    <div className={styles.pageContainer}>
      <h2 className={styles.pageTitle}>{profileSelectionTitle}</h2>
      <ProfileSwitcher
        profiles={profiles}
        selectedProfileId={selectedProfileId}
        onProfileSelect={setSelectedProfileId}
      />
      <div className={styles.contentFiltering}>
        <h3>{parentZone.contentFiltering}</h3>
        <h5>{blockedContentDescription}</h5>
      </div>
      {(!videosBlocked || videosBlocked.length === 0) &&
        (!showsBlocked || showsBlocked.length === 0) && (
          <>
            <div className={styles.noHiddenContentInfo}>
              <h3>{noBlockedContentTitle}</h3>
              <h5>{noBlockedContentDescription}</h5>
            </div>
            <div className={styles.hideContentInfo}>
              <img src={HideIcon} alt="hide content icon" />
              <h4>{hideContentIconTitle}</h4>
              <h5>{hideContentIconDescription}</h5>
            </div>
          </>
        )}
      {showsBlocked?.length > 0 && (
        <>
          <h3 className={styles.heading}>{blockedShowsTitle}</h3>
          <Grid
            {...gridProps}
            data-tab-id={HIDDEN_CONTENT.SHOWS}
            items={showsBlocked}
            ItemMapper={ShowMapper}
            onFocus={updateScroll}
            setUnblockShow={setUnblockShow}
            handleBlocking={handleBlocking}
          />
        </>
      )}
      {videosBlocked?.length > 0 && (
        <>
          <h3 className={styles.heading}>{blockedVideosTitle}</h3>
          <Grid
            {...gridProps}
            data-tab-id={HIDDEN_CONTENT.VIDEOS}
            items={videosBlocked}
            ItemMapper={VideoMapper}
            onFocus={updateScroll}
            setUnblockVideo={setUnblockVideo}
            handleBlocking={handleBlocking}
          />
        </>
      )}
    </div>
  );
};

ContentFiltering.propTypes = {};

export default ContentFiltering;
