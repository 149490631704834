const routes = {
  INDEX: '/index.html',
  ROOT: '/',
  HOME: '/home',
  LIVETV: '/main/livetv/:age?',
  DETAILS: '/details/:id',
  PLAYER: '/player/:referenceId?',
  CREATOR_DETAILS: '/main/home/creatordetails/:id',
  SHOW_DETAILS: '/main/home/showdetails/:id/:playlistId?',
  BASE_PLAYER: '/player',
  TRAILER: '/trailer/:showId/:trailerId',
  ACTIVATE_SIGN_IN_BASE: '/activatesignedin/',
  ACTIVATE_SIGN_IN: '/activatesignedin/:code?',
  ACTIVATE_BASE: '/activate/',
  ACTIVATE: '/activate/:code?',
  ACTIVATE_COMPLETE: '/activate/complete',
  SEE_ALL_HOME: '/main/home/seeall/:id/:title/:type/:playlistId?',
  SEE_ALL_EXPLORE: '/main/explore/seeall/:id/:title/:type/:playlistId?',
  SEE_ALL_FAVORITES: '/main/favorites/seeall/:id/:title/:type/:playlistId?',
  VERSION: '/version',
  PROFILE_SELECTION: '/selectprofile',
  PROFILE_ADDITION: '/addprofile',
  EMAIL_VERIFICATION_CONFIRMATION: '/confirm',
  FORGOT_PASSWORD_CONFIRMATION: '/reset',
  MOBILE_GATE: '/mobilegate',
  GEOBLOCK_GATE: '/geoblockgate',
  // TODO: The route welcome and the Welcome component are
  // no longer in use. Leaving this here to avoid breaking something
  // Review and remove later.
  WELCOME: '/welcome',
  RECENTLY_WATCHED: '/main/home/recentlyWatched/:id/:type',
};

export const deeplinkRoutes = {
  ROOT: '/deeplink',

  // Deeplinks need two entries, the route to be catched
  // and a route with /deeplink prepended
  SEE_ALL_EXPLORE: '/main/explore/seeall/:id',
  DEEPLINK_SEE_ALL_EXPLORE: '/deeplink/main/explore/seeall/:id',

  LIVETV: '/main/livetv',
  DEEPLINK_LIVETV: '/deeplink/main/livetv',

  SEE_ALL_HOME: '/main/home/seeall/:id',
  DEEPLINK_SEE_ALL_HOME: '/deeplink/main/home/seeall/:id',

  SHOW_DETAILS_SHORT: '/showdetails/:id',
  SHOW_DETAILS: '/main/home/showdetails/:id',
  DEEPLINK_SHOW_DETAILS: '/deeplink/main/home/showdetails/:id',
};

export const subRoutes = {
  SEE_ALL: 'seeall',
};

export const mainRoutes = {
  ROOT: '/main',
  HOME: '/main/home',
  LIVETV: '/main/livetv',
  TOPICS: '/main/topics',
  FAVORITES: '/main/favorites',
  EXPLORE: '/main/explore',
  SEE_ALL_HOME: '/main/home/seeall',
  SEE_ALL_EXPLORE: '/main/explore/seeall',
  SEE_ALL_FAVORITES: '/main/favorites/seeall',
  CREATOR_DETAILS_BASE: '/main/home/creatordetails/',
  CREATOR_DETAILS: '/main/home/creatordetails/:id',
  SHOW_DETAILS_BASE: '/main/home/showdetails/',
  SHOW_DETAILS: '/main/home/showdetails/:id/:playlistId?',
  PARENT_ZONE: '/main/home/parentzone',
  NOT_FOUND: '/main/notfound',
  RECENTLY_WATCHED: '/main/home/recentlyWatched',
  TIKTOK: '/main/home',
};

export const authFlowRoutes = {
  ROOT: '/auth',
  WELCOME: '/auth/welcome',
  AGE_GATE: '/auth/verifyage',
  FEATURE_TOUR: '/auth/tour',
  PROFILE_CREATION: '/auth/createprofile',
  ACCOUNT_CREATION: '/auth/createaccount',
  EMAIL_VERIFICATION: '/auth/emailverification',
  SIGN_IN: '/auth/signin',
  SIGN_UP: '/auth/signup',
  FORGOT_PASSWORD: '/auth/forgotpassword',
  FORGOT_PIN: '/auth/forgotpin',
  GUEST_PROFILE: '/auth/guestprofile',
};

export default routes;
