import React, { useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { withFocus, focusManager } from '@accedo/vdkweb-navigation';
import { environment } from '@accedo/xdk-core';
import { vKey } from '@accedo/xdk-virtual-key';
import cx from 'classnames';
import { setNativeFocus } from '#/utils/accessibility';

import { actions as focusActions } from '#/redux/modules/focus/actions';

import styles from './FocusElements.scss';
import { isWorkstation } from '#/utils/componentStyleConfig';

export const FocusAnchor = withFocus(
  ({ isFocused, nav, className, children, ...props }) => {
    useEffect(() => {
      if (isFocused) {
        setNativeFocus(nav?.id);
      }
    }, [isFocused]);

    return (
      <a
        {...props}
        id={nav?.id}
        className={cx(className, {
          [styles.tvFocused]: !isWorkstation() && isFocused,
        })}
      >
        {children}
      </a>
    );
  },
);
FocusAnchor.propTypes = {
  isFocused: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

export const FocusScroll = withFocus(
  ({ children, nav, isFocused, ...props }) => {
    const dispatch = useDispatch();
    const elementRef = useRef(null);

    useEffect(() => {
      if (isFocused) {
        elementRef?.current?.focus();
      } else {
        dispatch(focusActions.toggleFocusSink(true));
      }
    }, [isFocused]);

    const handleScroll = useCallback(() => {
      const { scrollTop } = elementRef.current;
      const { offsetHeight } = elementRef.current;
      const { scrollHeight } = elementRef.current;
      if (scrollTop + offsetHeight >= scrollHeight) {
        focusManager.changeFocus(nav.nextScrollDown);
      }
    }, [elementRef]);

    const onKeyDownScroll = ({ id }) => {
      const { DOWN } = vKey;

      if (id !== DOWN.id || elementRef?.current.scrollTop > 0) {
        return;
      }

      if (
        elementRef?.current.offsetHeight >= elementRef?.current.scrollHeight
      ) {
        focusManager.changeFocus(nav.nextScrollDown);
      }
    };

    // fallback method in case of unscrollable/short text
    useEffect(() => {
      environment.addEventListener(environment.SYSTEM.KEYDOWN, onKeyDownScroll);
      return () => {
        environment.removeEventListener(
          environment.SYSTEM.KEYDOWN,
          onKeyDownScroll,
        );
      };
    }, []);

    return (
      <p ref={elementRef} tabIndex={0} onScroll={handleScroll} {...props}>
        {children}
      </p>
    );
  },
);
FocusScroll.propTypes = {
  children: PropTypes.node,
  nav: PropTypes.object,
  isFocused: PropTypes.bool,
};
