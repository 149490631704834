import React, { useRef, useCallback, useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { withFocus } from '@accedo/vdkweb-navigation';

import Keyboard from '#/components/Keyboard/Keyboard';
import { setNativeInput } from '#/utils/nativeDOM';
import { isVIZIO } from '#/utils/componentStyleConfig';
import { actions as focusActions } from '#/redux/modules/focus/actions';

import styles from './DigitEntry.scss';

const allowedKeys = ['Backspace', 'Enter', 'Select'];

const DigitEntry = ({
  isFocused,
  onChange,
  validator = () => true,
  onSubmit,
  nav,
}) => {
  const dispatch = useDispatch();
  const firstInput = useRef(null);
  const underlineContainer = useRef();
  const [keyboardActive, setKeyboardActive] = useState(false);
  const [curPos, setCurPos] = useState(0);

  useEffect(() => {
    if (isFocused) {
      firstInput.current.focus();
      nav?.keyboard && setKeyboardActive(true);
    } else {
      dispatch(focusActions.toggleFocusSink(true));
    }
  }, [isFocused]);

  const handleInputChange = useCallback(
    e => {
      if (e.target.value) {
        e.target.nextSibling?.focus();
      }

      let value = '';
      const parent = e.target.parentElement;
      for (let i = 0; i < 4; i += 1) {
        if (!/^\d+$/.test(parent.childNodes[i].value)) {
          // check if value is a valid number, needed for android mobile browsers
          underlineContainer.current.childNodes[i].classList.remove(
            styles.filled,
          );
          onChange(false);
          return;
        }
        underlineContainer.current.childNodes[i].classList.add(styles.filled);
        value += parent.childNodes[i].value;
      }
      onChange(validator(Number(value)), value);
    },
    [onChange],
  );

  const handleKeyDown = useCallback(
    e => {
      if (allowedKeys.find(k => k === e.key)) {
        if (e.key === 'Backspace' && !e.target.value) {
          e.target.previousSibling?.focus();
        }
        if (
          (e.key === 'Enter' || e.key === 'Select') &&
          !!e.target.value &&
          !e.target.nextSibling &&
          onSubmit
        ) {
          onSubmit();
        }
        return true;
      }

      /* TODO: Remove once tested with Android browsers
      if (e.key === 'Unidentified' || (e.keyCode || e.which) === 229) {
        // specifically for android browsers to prevent special characters
        e.preventDefault();
        e.target.value = '';
        return false;
      }
      */
      if (!/^\d$/.test(e.key)) {
        e.preventDefault();
        return false;
      }
      e.target.value = '';
      return true;
    },
    [allowedKeys, onSubmit],
  );

  const handleMouseDown = useCallback(e => {
    e.preventDefault();

    const parent = e.target.parentElement;
    for (let i = 0; i < 4; i += 1) {
      if (!parent.childNodes[i].value) {
        parent.childNodes[i].focus();
        return;
      }
    }

    parent.childNodes[3].focus();
  }, []);

  const onKeyboardClick = useCallback(value => {
    const inputElements = document
      .getElementById(nav?.keyboard?.parent)
      .getElementsByTagName('input');
    switch (value) {
      case 'DELETE':
        if (curPos > 0) {
          setNativeInput(null, '', inputElements[curPos - 1]);
          inputElements[curPos - 1]?.focus();
          setCurPos(curPos - 1);
        }
        break;
      default:
        if (curPos < inputElements?.length && /^\d$/.test(value)) {
          setNativeInput(null, value, inputElements[curPos]);
          setCurPos(curPos + 1);
        }
    }
  });

  return (
    <>
      <div className={styles.ageField}>
        <div>
          <input
            ref={firstInput}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onMouseDown={handleMouseDown}
            type="number"
            pattern="\d*"
          />
          <input
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onMouseDown={handleMouseDown}
            type="number"
            pattern="\d*"
          />
          <input
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onMouseDown={handleMouseDown}
            type="number"
            pattern="\d*"
          />
          <input
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onMouseDown={handleMouseDown}
            type="number"
            pattern="\d*"
            maxLength={1}
          />
        </div>
        <div className={styles.digitsContainer} ref={underlineContainer}>
          <span className={styles.digit} />
          <span className={styles.digit} />
          <span className={styles.digit} />
          <span className={styles.digit} />
        </div>
      </div>
      {isVIZIO() && keyboardActive && (
        <Keyboard
          digitMode
          nav={nav?.keyboard}
          onClick={onKeyboardClick}
          isNextDisabled
          isPrevDisabled
        />
      )}
    </>
  );
};
DigitEntry.propTypes = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  validator: PropTypes.func,
  isFocused: PropTypes.bool,
  nav: PropTypes.object,
};

export default withFocus(DigitEntry);
