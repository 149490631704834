export const updateRecentlyWatchedList = (
  newIdEntry,
  rwIds,
  rwDataEntry,
  rwData,
) => {
  // Remove from both and unshift
  const newIds = rwIds.filter(i => i.videoId !== newIdEntry.videoId);
  const newData = rwData.filter(i => i.reference_id !== newIdEntry.videoId);
  // If performance is an issue, update using index may be faster
  // it requires that rwIds and rwData have 1:1 equivalents
  // const index = rwIds.indexOf(elementInList);
  // const newIds = rwIds.slice(0);
  // const newData = rwData.slice(0);
  // newIds.splice(index, 1);
  // newData.splice(index, 1);
  newIds.unshift(newIdEntry);
  newData.unshift(rwDataEntry);
  return [newIds, newData];
};

export const addToRecentlyWatchedList = (
  newIdEntry,
  rwIds,
  rwDataEntry,
  rwData,
) => {
  const newIds = rwIds.slice(0);
  const newData = rwData.slice(0);
  newIds.unshift(newIdEntry);
  newData.unshift(rwDataEntry);
  return [newIds, newData];
};
