import { HEADER } from '#/utils/navigationHelper';

export const isItemOutsideScreen = id => {
  const item = document.getElementById(id);

  if (!item) {
    return false;
  }
  const tvHeaderWidth =
    document.getElementById(HEADER.CONTAINER)?.firstChild.offsetWidth || 0;

  const { left, right } = item.getBoundingClientRect();
  const { innerWidth } = window;
  const { clientWidth } = document?.documentElement;

  if (left < tvHeaderWidth || right > (innerWidth || clientWidth)) {
    return true;
  }
  return false;
};
