let rootSelector = state => state.spinner;

export const setRootSelector = selector => {
  rootSelector = selector;
};

export const getLoadingSpinnerState = state =>
  rootSelector(state)?.loadingSpinner;

export const getLoginLoadingSpinnerState = state =>
  rootSelector(state)?.loadingLoginSpinner;
