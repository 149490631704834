import React from 'react';
import { useDispatch } from 'react-redux';
import { MobileParentZoneTitle, MobileParentZoneDesc } from '#/config/strings';
import BaseModal from '../../common/BaseModal/BaseModal';
import { actions as modalActions } from '#/redux/modules/modal/actions';
import styles from './MobileParentZone.scss';
import parentZoneImg from '#/static/images/icons/mobile_parent_zone.svg';
import kidSafe from '#/static/images/icons/sensical_small_kidSafe.svg';

const MobileParentZone = () => {
  const dispatch = useDispatch();

  const Handlefooter = () => {
    return (
      <div className={styles.footer}>
        <img src={kidSafe} alt="Kid Safe" />
        <div className={styles.footerContent}>
          <div className={styles.content}>
            <a
              onClick={() =>
                dispatch(
                  modalActions.openModal({ modalId: 'PrivacyAgreementModal' }),
                )
              }
            >
              Privacy policy
            </a>
            |
            <a
              onClick={() =>
                dispatch(
                  modalActions.openModal({
                    modalId: 'ChildrenPrivacyPolicyModal',
                  }),
                )
              }
            >
              Children’s privacy policy
            </a>
            <div className={styles.separator}>|</div>
          </div>
          <div className={styles.content}>
            <a
              onClick={() =>
                dispatch(
                  modalActions.openModal({ modalId: 'TermsOfServiceModal' }),
                )
              }
            >
              Terms of service
            </a>
            |
            <a
              onClick={() =>
                dispatch(modalActions.openModal({ modalId: 'HelpModal' }))
              }
            >
              Help
            </a>
          </div>
        </div>
      </div>
    );
  };
  return (
    <BaseModal
      saveButtonText="Sign out"
      showSaveButton
      className={styles.modalContainer}
      saveButtonClassName={styles.btn}
      footerContent={Handlefooter()}
      saveButtonContainerClassName={styles.btnContainer}
    >
      <div className={styles.contentContainer}>
        <img
          src={parentZoneImg}
          className={styles.modalImg}
          alt="Parent Zone"
        />
        <h2 className={styles.modalTitle}>{MobileParentZoneTitle}</h2>
        <p className={styles.textContent}>{MobileParentZoneDesc}</p>
      </div>
    </BaseModal>
  );
};
export default MobileParentZone;
