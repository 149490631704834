import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPrivacyPolicy } from '#/redux/modules/accedoOne/selectors';
import { requestPrivacyPolicy } from '#/redux/modules/accedoOne/actions';
import { privacyPolicyTitle } from '#/config/strings';
import styles from './PrivacyAgreementModal.scss';

const PrivacyAgreementModal = () => {
  const dispatch = useDispatch();
  const content = useSelector(getPrivacyPolicy);
  const contentAreaRef = useRef(null);

  useEffect(() => {
    if (!content) {
      dispatch(requestPrivacyPolicy);
    }
    contentAreaRef?.current?.focus();
  }, []);

  return (
    <>
      <h2 className={(styles.title, styles.modalTitle)}>
        {privacyPolicyTitle}
      </h2>
      <p ref={contentAreaRef} tabIndex={0} className={styles.textContent}>
        {content}
      </p>
    </>
  );
};

export default PrivacyAgreementModal;
