import React, { useCallback, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withFocus } from '@accedo/vdkweb-navigation';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { getTopics } from '#/redux/modules/firestore/selectors';
import { getEntryById } from '#/redux/modules/accedoOne/selectors';

import getVw from '#/utils/getVw';
import { subRoutes } from '#/utils/routes';
import {
  containersTypeAlias,
  SWIMLANE_LENGTH,
  SWIMLANE_RIGHT_ARROW,
  SWIMLANE_LEFT_ARROW,
  mainPageNames,
} from '#/config/constants';

import TopicCard from '#/components/TopicCard/TopicCard';
import Swimlane from '#/components/Swimlane/Swimlane';
import styles from './topicSwimlaneFactory.scss';
import usePrevious from '#/hooks/usePrevious';
import { isWorkstation } from '#/utils/componentStyleConfig';
import { isItemOutsideScreen } from '#/utils/isItemOutsideScreen';

const SeeAllCard = ({ nav, onClick, className, style, children }) => {
  return (
    <div
      id={nav.id}
      onClick={onClick}
      className={classNames(className, { [styles.isFocused]: nav.isFocused })}
      style={style}
    >
      {children}
    </div>
  );
};
const FocusSeeAllCard = withFocus(SeeAllCard);
SeeAllCard.propTypes = {
  nav: PropTypes.object,
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.any,
  style: PropTypes.any,
};

// topic data loaded on profile switch from TVAppContent
const TopicSwimlaneFactory = ({
  topics,
  title,
  nav,
  ageTheme,
  history,
  enableSeeAll = true,
  onFocus,
  location,
  containerId,
}) => {
  const items = topics || useSelector(getTopics) || [];
  const { separatorColor, backgroundColor } =
    useSelector(state => getEntryById(state, containerId)) || {};
  const getItemId = useCallback(index => `${nav.id}-TOPIC-${index}`, []);
  const SEE_ALL = `${nav.id}-see-all`;
  const [currentIndex, setCurrentIndex] = useState(0);
  const previousIndex = usePrevious(currentIndex);
  const currentPath = location?.pathname?.split('/').slice(-1)[0];

  const [rowChanged, setRowChanged] = useState(true);

  const getImagePath = useCallback(
    item =>
      item?.images?.find(e => e.age === ageTheme?.minAge).image_path ||
      item?.image_path ||
      '',
    [ageTheme],
  );
  const getPlaylistId = useCallback(
    item =>
      item?.playlists?.find(e => e.age === ageTheme?.minAge)?.playlist_id ||
      '0',
    [ageTheme],
  );

  const handleNavigation = useCallback((nextIndex, itemsLength) => {
    if (nextIndex < 0 || nextIndex > itemsLength - 1) {
      return;
    }
    setCurrentIndex(nextIndex);
    setRowChanged(false);
    return false;
  }, []);

  const generateItemNavs = useCallback(
    i => {
      return {
        parent: nav.id,
        id: getItemId(i),
        ...(i !== 0 ? { nextleft: getItemId(i - 1) } : {}),
        ...(i + 1 === SWIMLANE_LENGTH && enableSeeAll
          ? { nextright: SEE_ALL }
          : i !== items.length - 1
          ? { nextright: getItemId(i + 1) }
          : {}),
        internal: {
          nextright: () => handleNavigation(i + 1, items.length),
          nextleft: () => handleNavigation(i - 1, items.length),
          nextup: () => setRowChanged(true),
          nextdown: () => setRowChanged(true),
        },
      };
    },
    [items],
  );

  useEffect(() => {
    const currentItemId =
      currentIndex === SWIMLANE_LENGTH && enableSeeAll
        ? SEE_ALL
        : getItemId(currentIndex);
    if (!isWorkstation() && isItemOutsideScreen(currentItemId)) {
      if (previousIndex && previousIndex < currentIndex) {
        document.getElementById(SWIMLANE_RIGHT_ARROW + nav.id)?.click();
      } else if (previousIndex && previousIndex > currentIndex) {
        document.getElementById(SWIMLANE_LEFT_ARROW + nav.id)?.click();
      }
    }
  }, [currentIndex, previousIndex]);

  const createAriaLabel = useCallback(
    item => {
      const label = `${item.name}`;
      if (rowChanged) {
        return `${title || 'Channels'}, ${label}`;
      }
      return label;
    },
    [rowChanged],
  );

  const topicCards = useMemo(() => {
    const cards = items
      .slice(0, enableSeeAll ? SWIMLANE_LENGTH : items.length)
      .map((item, index) => (
        <TopicCard
          className={classNames(styles.cardContainer, currentPath || '')}
          nav={generateItemNavs(index)}
          key={getItemId(index)}
          id={item.id}
          title={item.name}
          imgUrl={getImagePath(item)}
          roundedCorners={ageTheme?.tileRounded}
          onItemFocus={onFocus}
          playlistId={getPlaylistId(item)}
          ariaLabel={createAriaLabel(item)}
        />
      ));

    if (items.length > SWIMLANE_LENGTH && enableSeeAll) {
      const path = history.location?.pathname?.split('/');
      const containerAlias =
        currentPath === mainPageNames.FAVORITES && !isWorkstation()
          ? containersTypeAlias.TVCONTAINER_FAV_TOPICS
          : containersTypeAlias.CONTAINER_TOPICS;
      cards.push(
        <FocusSeeAllCard
          nav={{
            id: SEE_ALL,
            nextleft: getItemId(SWIMLANE_LENGTH - 1),
            parent: nav.id,
          }}
          key={SEE_ALL}
          onItemFocus={onFocus}
          onClick={() => {
            history.push(
              `/${path[1]}/${path[2]}/${subRoutes.SEE_ALL}/${items[SWIMLANE_LENGTH].id}/${title}/${containerAlias}`,
            );
          }}
          className={classNames(styles.seeAllTopics, {
            [styles.isTv]: !isWorkstation(),
          })}
          style={{ borderRadius: ageTheme?.tileRounded ? getVw(24) : '0px' }}
        >
          <div
            className={styles.seeAllImage}
            style={{
              backgroundImage: `url(${getImagePath(items[SWIMLANE_LENGTH])})`,
            }}
          >
            <div />
          </div>
          <div className={styles.seeAllText}>
            <p>See all</p>
          </div>
        </FocusSeeAllCard>,
      );
    }

    return cards;
  }, [items, rowChanged]);

  const topicsSwimlaneNav = {
    ...nav,
    forwardFocus: `${nav.id}-TOPIC-0`,
    useLastFocus: true,
  };
  return topicCards.length ? (
    <Swimlane
      isTv={!isWorkstation()}
      items={topicCards}
      title={title}
      nav={topicsSwimlaneNav}
      navId={nav.id}
      separatorColor={separatorColor}
      backgroundColor={backgroundColor}
    />
  ) : null;
};
TopicSwimlaneFactory.propTypes = {
  topics: PropTypes.array,
  title: PropTypes.string,
  nav: PropTypes.object,
  ageTheme: PropTypes.object,
  history: PropTypes.object,
  enableSeeAll: PropTypes.bool,
  onFocus: PropTypes.func,
  location: PropTypes.object,
  containerId: PropTypes.string,
};

export default withRouter(TopicSwimlaneFactory);
