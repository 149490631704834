import React from 'react';
import {
  dataSharePolicyTitleMobile,
  dataSharePolicyDescMobile,
} from '#/config/strings';
import styles from './DataSharePolicyModal.scss';

const DataSharePolicyModal = () => {
  return (
    <>
      <h2 className={styles.modalTitle}>{dataSharePolicyTitleMobile}</h2>
      <p className={styles.textContent}>{dataSharePolicyDescMobile}</p>
    </>
  );
};
export default DataSharePolicyModal;
