import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { LayoutX } from '@accedo/vdkweb-tv-ui';
import { withFocus, focusManager } from '@accedo/vdkweb-navigation';
import { useDispatch, useSelector } from 'react-redux';
import styles from './AvatarModalTV.scss';
import { Avatar } from '#/widgets/ui';
import BaseModal from '../../common/BaseModal/BaseModal';
import FocusModal from '../../common/FocusModal/FocusModal';
import { AVATARMODAL } from '#/utils/navigationHelper';
import { actions } from '#/redux/modules/modal/actions';

import { getDraftProfile } from '#/redux/modules/onboarding/selectors';
import { getModalData } from '#/redux/modules/modal/selectors';

import { getAvatars } from '#/redux/modules/firestore/selectors';

const AvatarModalTV = ({ successAction }) => {
  const dispatch = useDispatch();
  const profile = useSelector(getDraftProfile);
  const fieldOptions = useSelector(getAvatars);
  const { returnFocus } = useSelector(getModalData);
  const fieldSelected = profile.avatar;
  const [selected, setSelected] = useState(fieldSelected);

  const keyIds = fieldOptions.map((el, i) => `key-${i}`);
  const layout = keyIds.reduce((a, b, i) =>
    i % 4 === 0 ? `${a}\n${b}` : `${a} ${b}`,
  );

  useEffect(() => {
    focusManager.changeFocus(AVATARMODAL.SELECT);
  }, []);

  const FocusSpan = withFocus(({ isFocused, className, ...props }) => (
    <span
      {...props}
      className={cx(className, styles.avatar, { [styles.focused]: isFocused })}
    />
  ));

  const onSave = () => {
    if (selected !== null) {
      successAction.payload = selected;
      dispatch(successAction);
    }
    dispatch(actions.closeModal());
    focusManager.changeFocus(returnFocus);
  };

  const optionsButtons = Object.entries(fieldOptions).map(([k, v]) => (
    <FocusSpan
      key={k}
      nav={{ id: keyIds[k] }}
      onClick={() => {
        setSelected(v);
      }}
      className={{ [styles.selected]: selected === v }}
    >
      <Avatar profile={{ avatar: v }} />
    </FocusSpan>
  ));

  return (
    <FocusModal>
      <BaseModal
        className={styles.content}
        showSaveButton
        saveButtonText="Save"
        saveButtonClassName={styles.saveButton}
        saveAction={() => () => onSave()}
        nav={{
          id: AVATARMODAL.navOverride.id,
          parent: AVATARMODAL.navOverride.parent,
        }}
      >
        <h2 className={styles.title}>Pick a profile icon</h2>
        <LayoutX
          className={styles.avatarModalContent}
          nav={{
            id: AVATARMODAL.SELECT,
            forwardFocus: 'key-0',
            nextdown: AVATARMODAL.SAVE_BUTTON,
          }}
          layout={layout}
        >
          {optionsButtons}
        </LayoutX>
      </BaseModal>
    </FocusModal>
  );
};

AvatarModalTV.propTypes = {
  successAction: PropTypes.object,
};

export default AvatarModalTV;
