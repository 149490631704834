/* global firebase */
import config from '#/config/firebase';
import { screenViewAnalyticsEvent } from '#/config/constants';

const isClient = typeof window !== 'undefined';

const defaultAnalyticsParams = {
  device_type: 'web',
  device_model: isClient ? window.navigator.userAgent : '',
};

let defaultUserProperties = {};

const api = {
  // Add default properties so user properties are guaranteed to be reset before analytics calls
  updateDefaultUserProperties(properties) {
    defaultUserProperties = {
      ...defaultUserProperties,
      ...properties,
    };
  },
};
const bufferedScreenEvents = [];
const bufferedEvents = [];

// If client browser, load firestore scripts, avoid during webpack compilation

if (isClient) {
  const firebaseAppScript = document.createElement('script');
  firebaseAppScript.src = config.FIREBASE_APP_SCRIPT;
  firebaseAppScript.onload = () => {
    // Init after script load
    firebase.initializeApp({
      apiKey: config.apiKey,
      authDomain: config.authDomain,
      projectId: config.projectId,
      storageBucket: config.storageBucket,
      messagingSenderId: config.messagingSenderId,
      appId: config.appId,
      measurementId: config.measurementId,
    });

    const firebaseInstallationScript = document.createElement('script');
    firebaseInstallationScript.src = config.FIREBASE_INSTALLATIONS_SCRIPT;
    firebaseInstallationScript.onload = async () => {
      const installationId = await firebase.installations().getId();
      defaultAnalyticsParams.instance_id = installationId;

      // load analytics and firestore script
      const firestoreScript = document.createElement('script');
      firestoreScript.src = config.FIREBASE_STORE_SCRIPT;

      const firebaseAnalyticsScript = document.createElement('script');
      firebaseAnalyticsScript.src = config.FIREBASE_ANALYTICS_SCRIPT;
      firebaseAnalyticsScript.onload = () => {
        const { logEvent } = firebase.analytics();
        api.sendAnalytics = (evt, payload = {}) =>
          logEvent(evt, {
            ...payload,
            ...defaultAnalyticsParams,
          });

        api.setUserProperties = firebase.analytics().setUserProperties;
        api.setUserId = firebase.analytics().setUserId;
        api.setCurrentScreen = firebase.analytics().setCurrentScreen;

        api.sendScreenViewAnalytics = screenName => {
          api.setCurrentScreen(screenName);
          api.setUserProperties(defaultUserProperties);
          api.sendAnalytics(screenViewAnalyticsEvent, {
            screen_name: screenName,
          });
        };

        bufferedEvents.forEach(event =>
          api.sendAnalytics(event.evt, event.payload),
        );
        bufferedScreenEvents.forEach(api.sendScreenViewAnalytics);
      };

      document.head.appendChild(firestoreScript);
      document.head.appendChild(firebaseAnalyticsScript);
    };

    document.head.appendChild(firebaseInstallationScript);
  };

  document.head.appendChild(firebaseAppScript);
}

api.sendAnalytics = (evt, payload = {}) =>
  bufferedEvents.push({ evt, payload });
api.sendScreenViewAnalytics = screenName =>
  bufferedScreenEvents.push(screenName);

api.getAllByCollection = async (collectionName, orderBy, age) => {
  const firestore = firebase?.firestore();
  const response = orderBy
    ? age
      ? await firestore
          .collection(collectionName)
          .where('ages', 'array-contains', age)
          .orderBy(orderBy, 'asc')
          .where('hide', '==', false)
          .get()
      : await firestore
          .collection(collectionName)
          .orderBy(orderBy, 'asc')
          .where('hide', '==', false)
          .get()
    : await firestore
        .collection(collectionName)
        .where('hide', '==', false)
        .get();

  const currentSeconds = Date.now() / 1000;
  return response.docs.reduce((result, doc) => {
    const data = doc.data();
    if (!data.start_date || !data.end_date) {
      result.push(data);
      return result;
    }

    if (
      data.start_date.seconds < currentSeconds &&
      data.end_date.seconds > currentSeconds
    ) {
      result.push(data);
    }
    return result;
  }, []);
};

api.getAvailableTopics = async age => {
  const firestore = firebase?.firestore();
  const query = firestore
    .collection('topics')
    .where('hide', '==', false)
    .where('ages', 'array-contains', age)
    .orderBy('name', 'asc');
  const response = await query.get();
  return response.docs.map(doc => doc.data());
};

api.getTopic = async id => {
  const firestore = firebase?.firestore();
  const query = firestore
    .collection('topics')
    .where('hide', '==', false)
    .where('id', '==', id);
  const response = await query.get();
  return response.docs.map(doc => doc.data());
};

api.getAvailableShows = async age => {
  const firestore = firebase?.firestore();
  const query = firestore
    .collection('shows')
    .where('hide', '==', false)
    .where('ages', 'array-contains', age)
    .orderBy('name', 'asc');
  const response = await query.get();
  const currentSeconds = Date.now() / 1000;
  return response.docs.reduce((result, doc) => {
    const data = doc.data();
    if (
      data.start_date.seconds < currentSeconds &&
      data.end_date.seconds > currentSeconds
    ) {
      result.push(data);
    }
    return result;
  }, []);
};

api.getContentById = async (contentId, collectionName) => {
  const firestore = firebase?.firestore();
  const response = await firestore
    .collection(collectionName)
    .where('id', '==', contentId)
    .get();
  return response.docs.map(doc => doc.data());
};

api.getAvatars = async () => {
  const firestore = firebase?.firestore();
  const response = await firestore.collection('avatars').get();
  return response.docs.map(doc => doc.data());
};

export default api;
