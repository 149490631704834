import React, { useMemo, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withFocus } from '@accedo/vdkweb-navigation';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import {
  loadContainerShows,
  clearContainerShows,
} from '#/redux/modules/firestore/actions';
import {
  getContainerShows,
  getContainerPodcasts,
} from '#/redux/modules/firestore/selectors';
import { getEntryById } from '#/redux/modules/accedoOne/selectors';
import getVw from '#/utils/getVw';
import imgixUrl from '#/utils/imgix';
import { subRoutes } from '#/utils/routes';
import {
  containersTypeAlias,
  imgixTileResizeParameters,
  SWIMLANE_LENGTH,
  SWIMLANE_RIGHT_ARROW,
  SWIMLANE_LEFT_ARROW,
  mainPageNames,
} from '#/config/constants';

import ShowCard from '#/components/ShowCard/ShowCard';
import Swimlane from '#/components/Swimlane/Swimlane';
import styles from './fsCollectionSwimlaneFactory.scss';
import { isWorkstation } from '#/utils/componentStyleConfig';
import { setSpeech } from '#/utils/accessibility';
import { isItemOutsideScreen } from '#/utils/isItemOutsideScreen';
import usePrevious from '#/hooks/usePrevious';

const SeeAllCard = ({
  nav,
  onClick,
  className,
  style,
  children,
  ariaLabel,
}) => {
  return (
    <div
      tabIndex={!isWorkstation() ? -1 : 0}
      id={nav.id}
      onClick={onClick}
      className={classNames(className, { [styles.isFocused]: nav.isFocused })}
      style={style}
      aria-label={ariaLabel}
    >
      <div aria-hidden="true">{children}</div>
    </div>
  );
};
const FocusSeeAllCard = withFocus(SeeAllCard);
SeeAllCard.propTypes = {
  nav: PropTypes.object,
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.any,
  style: PropTypes.any,
  ariaLabel: PropTypes.string,
};

const FSCollectionSwimlaneFactory = ({
  collectionItems,
  collectionName,
  title,
  nav,
  ageTheme,
  history,
  enableSeeAll = true,
  onFocus,
  location,
  enablePodcasts = false,
  containerId,
}) => {
  const { separatorColor, backgroundColor } =
    useSelector(state => getEntryById(state, containerId)) || {};
  const dispatch = useDispatch();
  const getItemId = useCallback(index => `${nav.id}-ITEM-${index}`, []);
  const SEE_ALL = `${nav.id}-see-all`;
  const [currentIndex, setCurrentIndex] = useState(0);
  const previousIndex = usePrevious(currentIndex);
  const currentPath = location?.pathname?.split('/').slice(-1)[0];
  const containerItem = () => {
    if (collectionName?.includes('podcast')) {
      return useSelector(getContainerPodcasts);
    }

    return (
      useSelector(getContainerShows)[collectionName] || {
        collection: null,
        items: [],
      }
    );
  };

  const { collection = '', items } = collectionItems
    ? { items: collectionItems }
    : containerItem();
  const [rowChanged, setRowChanged] = useState(true);

  useEffect(() => {
    if (
      !items.length &&
      !collectionItems &&
      collectionName &&
      !collectionName?.includes('podcast')
    ) {
      dispatch(loadContainerShows(collectionName));
    }

    // return () => {
    //   dispatch(clearContainerShows());
    // };
  }, [items, collection, collectionItems, collectionName]);

  // load podcast for selected age after age change
  useEffect(() => {
    dispatch(clearContainerShows());

    if (
      collectionName?.includes('podcast') &&
      !collectionItems &&
      collectionName
    ) {
      dispatch(loadContainerShows(collectionName));
    }
  }, [ageTheme?.ageGroupValue]);

  const getShowPlaylistId = useCallback(show => show?.playlist_id || '0', []);
  const handleNavigation = useCallback((nextIndex, itemsLength) => {
    if (nextIndex < 0 || nextIndex > itemsLength - 1) {
      return;
    }
    setRowChanged(false);
    setCurrentIndex(nextIndex);

    return false;
  }, []);

  const generateItemNavs = useCallback(
    i => {
      return {
        parent: nav.id,
        id: getItemId(i),
        ...(i !== 0 ? { nextleft: getItemId(i - 1) } : {}),
        ...(i + 1 === SWIMLANE_LENGTH && enableSeeAll
          ? { nextright: SEE_ALL }
          : i !== items.length - 1
          ? { nextright: getItemId(i + 1) }
          : {}),
        internal: {
          nextright: () => handleNavigation(i + 1, items.length),
          nextleft: () => handleNavigation(i - 1, items.length),
          nextup: () => setRowChanged(true),
          nextdown: () => setRowChanged(true),
        },
      };
    },
    [items],
  );

  useEffect(() => {
    const currentItemId =
      currentIndex === SWIMLANE_LENGTH && enableSeeAll
        ? SEE_ALL
        : getItemId(currentIndex);
    if (!isWorkstation() && isItemOutsideScreen(currentItemId)) {
      if (previousIndex && previousIndex < currentIndex) {
        document.getElementById(SWIMLANE_RIGHT_ARROW + nav.id)?.click();
      } else if (previousIndex && previousIndex > currentIndex) {
        document.getElementById(SWIMLANE_LEFT_ARROW + nav.id)?.click();
      }
    }
  }, [currentIndex, previousIndex]);

  const createAriaLabel = useCallback(
    item => {
      const label = `${item.name}`;
      if (rowChanged) {
        return `${title || 'Shows'}, ${label}`;
      }
      return label;
    },
    [rowChanged],
  );

  const showCards = useMemo(() => {
    const cards = items
      .slice(0, enableSeeAll ? SWIMLANE_LENGTH : items.length)
      .sort((itemA, itemB) => (itemA?.position > itemB?.position ? 1 : -1))
      .map((item, index) => (
        <ShowCard
          nav={generateItemNavs(index)}
          key={getItemId(index)}
          className={classNames(styles.cardContainer, currentPath || '')}
          id={item.id}
          name={item.name}
          imgUrl={
            item?.media_type?.toLowerCase() === 'podcast'
              ? item.key_art_square_image_path
              : item.key_art_horizontal_image_path ||
                item.key_art_square_image_path
          }
          roundedCorners={ageTheme?.tileRounded}
          onItemFocus={onFocus}
          ariaLabel={createAriaLabel(item)}
          playlistId={getShowPlaylistId(item)}
          podcastShow={
            enablePodcasts || item?.media_type?.toLowerCase() === 'podcast'
          }
        />
      ));

    if (items.length > SWIMLANE_LENGTH && enableSeeAll) {
      const path = history.location?.pathname?.split('/');
      const containerAlias =
        currentPath === mainPageNames.FAVORITES && !isWorkstation()
          ? containersTypeAlias.TVCONTAINER_FAV_SHOWS
          : containersTypeAlias.CONTAINER_SHOWS;
      cards.push(
        <FocusSeeAllCard
          nav={{
            id: SEE_ALL,
            nextleft: getItemId(SWIMLANE_LENGTH - 1),
            parent: nav.id,
          }}
          key={SEE_ALL}
          onClick={() =>
            history.push(
              `/${path[1]}/${path[2]}/${subRoutes.SEE_ALL}/${items[SWIMLANE_LENGTH].id}/${title}/${containerAlias}`,
            )
          }
          style={{
            borderRadius: ageTheme?.tileRounded ? getVw(24) : '0px',
            outline: 'none',
          }}
          className={classNames(styles.seeAllShows, {
            [styles.isTv]: !isWorkstation(),
          })}
          onFocus={() => {
            setSpeech(`See all, ${title}`);
            onFocus();
          }}
          ariaLabel="See All"
        >
          <div
            aria-hidden="true"
            id={SEE_ALL}
            className={styles.seeAllImage}
            style={{
              backgroundImage: `url(${imgixUrl(
                items[SWIMLANE_LENGTH].key_art_horizontal_image_path,
                imgixTileResizeParameters.SHOWS,
              )})`,
            }}
          >
            <div />
          </div>
          <div className={styles.seeAllText} aria-hidden="true">
            <p>See all</p>
          </div>
        </FocusSeeAllCard>,
      );
    }

    return cards;
  }, [items, rowChanged]);

  const collectionSwimlaneNav = {
    ...nav,
    forwardFocus: `${nav.id}-ITEM-0`,
    useLastFocus: true,
  };
  return showCards.length ? (
    <Swimlane
      isTv={!isWorkstation()}
      items={showCards}
      title={title}
      nav={collectionSwimlaneNav}
      navId={nav.id}
      separatorColor={separatorColor}
      backgroundColor={backgroundColor}
    />
  ) : null;
};
FSCollectionSwimlaneFactory.propTypes = {
  collectionItems: PropTypes.array,
  collectionName: PropTypes.string,
  title: PropTypes.string,
  nav: PropTypes.object,
  ageTheme: PropTypes.object,
  history: PropTypes.object,
  enableSeeAll: PropTypes.bool,
  enablePodcasts: PropTypes.bool,
  onFocus: PropTypes.any,
  location: PropTypes.object,
  containerId: PropTypes.string,
};

export default withFocus(withRouter(FSCollectionSwimlaneFactory));
