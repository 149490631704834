import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import PlaylistSwimlaneFactory from '../PlaylistSwimlaneFactory/PlaylistSwimlaneFactory';

import {
  //
  getRecentlyWatchedAssetsByReferenceIds,
} from '#/redux/modules/brightcove/selectors';
import { actions as brightcoveActions } from '#/redux/modules/brightcove/actions';
import { isGuestMode } from '#/redux/modules/user/selectors';
import { getEntryById } from '#/redux/modules/accedoOne/selectors';

const RecentlyWatchedSwimlaneFactory = ({
  containerId,
  ageTheme,
  nav,
  onTileClick,
  title,
  onFocus,
  fromHome,
}) => {
  const dispatch = useDispatch();
  const recentlyWatchedVideos =
    useSelector(getRecentlyWatchedAssetsByReferenceIds) || [];
  const isGuest = useSelector(isGuestMode);
  const { displayText, displayTextForGuestMode } = useSelector(state =>
    getEntryById(state, containerId),
  );
  const swimlaneTitle = isGuest
    ? displayTextForGuestMode
    : title || displayText;

  useEffect(() => {
    if (recentlyWatchedVideos.length === 0) {
      return;
    }
    const preparePlaylist = async () => {
      // Set the containerId on brightcove
      await dispatch(
        brightcoveActions.saveContainerData({
          [containerId]: [...recentlyWatchedVideos],
        }),
      );
    };
    preparePlaylist();
  }, [recentlyWatchedVideos]);

  if (recentlyWatchedVideos.length === 0) {
    return null;
  }

  return (
    <>
      <PlaylistSwimlaneFactory
        containerId={containerId}
        // pass videos down through containerId
        // videos={recentlyWatchedVideos}
        ageTheme={ageTheme}
        nav={nav}
        key={nav}
        onTileClick={onTileClick}
        title={swimlaneTitle}
        onFocus={onFocus}
        fromHome={fromHome}
        recentlyWatched
      />
    </>
  );
};

RecentlyWatchedSwimlaneFactory.propTypes = {
  title: PropTypes.string,
  containerId: PropTypes.string,
  onTileClick: PropTypes.func,
  nav: PropTypes.object,
  ageTheme: PropTypes.object,
  onFocus: PropTypes.func,
  fromHome: PropTypes.bool,
};

export default withRouter(RecentlyWatchedSwimlaneFactory);
