import { handleActions } from 'redux-actions';
import { persistenceLayer } from '#/redux/utils/persistence';

import {
  IDENTITY_GUEST_ACTION_PREFIX,
  actions,
} from '#/redux/modules/identity/identityGuest/actions';

import { customDataFieldTypes } from '#/config/constants';

const {
  // TIME_WATCHED,
  RECENTLY_WATCHED,
  RECENTLY_WATCHED_V2,
  // LIVE_HISTORY,
  // WATCH_HISTORY,
  // BOOKMARKS,
  BOOKMARKS_V2,
  // SHOWS_FAVORITES,
  // PODCASTS_FAVORITES,
  // TOPICS_FAVORITES,
  // VIDEO_FAVORITES,
  // PODCASTS_EPISODES_FAVORITES,
} = customDataFieldTypes;

/*
 * It should be possible to use the same scheema for initial state
 */
const getInitialAgeCustomData = () => ({
  bookmarks_v2: [],
  recentlyWatched_v2: [],
  showsFavorites: [],
  topicsFavorites: [],
  videoFavorites: [],
});

// Initial state
const initialState = {
  processId: null,
  verificationEmail: null,
  accessToken: null,
  refreshToken: null,
  pkat: null,
  groupId: null,
  parentUserId: null,
  parentAccount: {},
  parentMode: false,
  onboardingSuccess: false,
  pin: null,
  customData: {
    bookmark: null,
    bookmarks: [],
    recentlyWatched: [],
    showsFavorites: [],
    topicsFavorites: [],
    videoFavorites: [],
  },
  errors: {},
  sessionData: {},
  // Structure reference: https://accedobroadband.jira.com/wiki/spaces/SENSICAL/pages/2495710024/Guest+mode
  localStorage: {
    '2': getInitialAgeCustomData(),
    '5': getInitialAgeCustomData(),
    '8': getInitialAgeCustomData(),
  },
};

const handleResetState = (state = initialState) => {
  // Preserve localStorage
  const { localStorage } = state;
  return {
    ...initialState,
    localStorage,
  };
};

const handleClearCustomDataRecentlyWatched = (state = initialState) => {
  return {
    ...state,
    customData: {
      ...state.customData,
      [RECENTLY_WATCHED]: null,
    },
  };
};

const handleSaveCustomDataRecentlyWatched = (
  state = initialState,
  { payload = [] },
) => {
  return {
    ...state,
    customData: {
      ...state.customData,
      [RECENTLY_WATCHED]: payload,
    },
  };
};

const handleAddSingleCustomDataRecentlyWatched = (
  state = initialState,
  { payload: { age, list } = {} },
) => {
  return {
    ...state,
    customData: {
      ...state.customData,
      [RECENTLY_WATCHED]: list,
    },
    localStorage: {
      ...state.localStorage,
      [age]: {
        ...state.localStorage[age],
        [RECENTLY_WATCHED_V2]: list,
      },
    },
  };
};

const handleWipeStoredCustomData = (state = initialState, { payload }) => {
  return {
    ...state,
    localStorage: {
      ...state.localStorage,
      [payload]: getInitialAgeCustomData(),
    },
  };
};

const handleOverrideStoredCustomData = (
  state = initialState,
  { payload: { age, data } = {} },
) => {
  return {
    ...state,
    localStorage: {
      ...state.localStorage,
      [age]: data,
    },
  };
};

const handleSaveCustomDataVideoBookmark = (
  state = initialState,
  { payload = {} },
) => {
  return {
    ...state,
    customData: {
      ...state.customData,
      bookmark: payload,
    },
  };
};

const handleClearCustomDataVideoBookmark = (state = initialState) => ({
  ...state,
  customData: {
    ...state.customData,
    bookmark: {},
  },
});

const handleUpdateCustomDataBookmarks = (
  state = initialState,
  { payload: { age, bookmarks } = {} },
) => {
  return {
    ...state,
    // Uncomment if needed on customData
    // customData: {
    //   ...state.customData,
    //   [BOOKMARKS_V2]: bookmarks,
    // },
    localStorage: {
      ...state.localStorage,
      [age]: {
        ...state.localStorage[age],
        [BOOKMARKS_V2]: bookmarks,
      },
    },
  };
};

const reducer = handleActions(
  {
    [actions.resetState]: handleResetState,
    [actions.saveCustomDataRecentlyWatched]: handleSaveCustomDataRecentlyWatched,
    [actions.handleClearCustomDataRecentlyWatched]: handleClearCustomDataRecentlyWatched,
    [actions.addSingleCustomDataRecentlyWatched]: handleAddSingleCustomDataRecentlyWatched,
    [actions.wipeStoredCustomData]: handleWipeStoredCustomData,
    [actions.overrideStoredCustomData]: handleOverrideStoredCustomData,
    [actions.saveCustomDataVideoBookmark]: handleSaveCustomDataVideoBookmark,
    [actions.clearCustomDataVideoBookmark]: handleClearCustomDataVideoBookmark,
    [actions.updateCustomDataBookmarks]: handleUpdateCustomDataBookmarks,
  },
  initialState,
);

export default persistenceLayer(
  reducer,
  initialState,
  IDENTITY_GUEST_ACTION_PREFIX,
);
