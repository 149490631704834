import { createActions } from 'redux-actions';
import { getAsyncState } from '#/redux/modules/async/selectors';

const IN_PROGRESS = 'IN_PROGRESS';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';
const DISMISS_AUTH_ERROR = 'DISMISS_AUTH_ERROR';

const actionOptions = {
  prefix: 'async',
};

export const actions = createActions(
  {},
  IN_PROGRESS,
  SUCCESS,
  FAILURE,
  DISMISS_AUTH_ERROR,
  actionOptions,
);

export const createFetchAction = (
  asyncId,
  successAction,
  fetchAction,
) => data => async (dispatch, getState) => {
  const { inProgress } = getAsyncState(asyncId, getState());

  if (inProgress) {
    console.warn(`Request in progress: ${asyncId}`);
    return Promise.resolve();
  }

  dispatch(actions.inProgress(asyncId));

  const response = await fetchAction(getState, data, dispatch);

  if (response && !response.error) {
    // Ensure successAction cannot make async action permanently pending
    await dispatch(successAction(response)).catch(e => {
      console.warn(e);
    });
    return dispatch(actions.success(asyncId));
  }

  return dispatch(
    actions.failure({
      id: asyncId,
      errorMsg: response.error || 'Error fetching data',
    }),
  );
};
