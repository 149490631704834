import React from 'react';
import { useSelector } from 'react-redux';
import { FocusScroll } from '#/widgets/ui';

import { getModalData } from '#/redux/modules/modal/selectors';
import contentModalStyles from '../../common/ContentModal/ContentModal.scss';
import { TV_CONTENT_MODAL } from '#/utils/navigationHelper';

const { CONTENT_AREA, TV_CONTENT_MODAL_ACTION_BUTTON } = TV_CONTENT_MODAL;
const navMap = {
  CONTENT_AREA: {
    id: CONTENT_AREA,
  },
  ACTION_BUTTON: {
    id: TV_CONTENT_MODAL_ACTION_BUTTON,
  },
};

const ErrorModal = () => {
  const { errorMessage } = useSelector(getModalData);
  const title = 'Error';
  const content = errorMessage;

  return (
    <>
      <h2>{title}</h2>
      <FocusScroll
        nav={{ id: navMap.CONTENT_AREA.id, nextdown: navMap.ACTION_BUTTON.id }}
        className={contentModalStyles.textContent}
      >
        {content}
      </FocusScroll>
    </>
  );
};

export default ErrorModal;
