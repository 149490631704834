import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { environment as sEnv } from '@accedo/xdk-core';
import { vKey } from '@accedo/xdk-virtual-key';
import PropTypes from 'prop-types';
import cx from 'classnames';

import BaseModal from '../../common/BaseModal/BaseModal';
import { modalScreenNames } from '#/config/constants';
import {
  getAgeGroupConfig,
  getEntryById,
} from '#/redux/modules/accedoOne/selectors';
import { actions } from '#/redux/modules/modal/actions';
import { getModalData } from '#/redux/modules/modal/selectors';
import { getCurrentProfile } from '#/redux/modules/onboarding/selectors';
import { getCustomDataDisplayedModals } from '#/redux/modules/identity/selectors';
import {
  sendWelcomeModal,
  sendWelcomeModalCTAClicked,
  sendWelcomeModalPlayIconClicked,
  sendWelcomeModalCancelClicked,
} from '#/redux/modules/firestore/actions';
import styles from './WelcomeModal.scss';
import FocusModal from '../../common/FocusModal/FocusModal';
import { WELCOME_MODAL } from '#/utils/navigationHelper';
import { FocusDiv } from '#/widgets/ui';
import { isGuestMode } from '#/redux/modules/user/selectors';

const { CONTAINER, CONTENT_AREA, WATCH_NOW } = WELCOME_MODAL;

const WelcomeModal = ({ history }) => {
  const dispatch = useDispatch();
  const currentProfile = useSelector(getCurrentProfile);
  const { modalId } = useSelector(getModalData);

  const ageGroupConfig = useSelector(getAgeGroupConfig);
  const modalStatus = useSelector(getCustomDataDisplayedModals);
  const guestMode = useSelector(isGuestMode);
  const welcomeModalEntryData =
    useSelector(state => getEntryById(state, modalId)) || null;

  useEffect(() => {
    dispatch(sendWelcomeModal(modalScreenNames.WelcomeModal));
  }, []);

  const handleWatchButton = async () => {
    if (welcomeModalEntryData?.sponsorPlaylistId) {
      history.replace(
        `${welcomeModalEntryData?.ctaDeeplinkUrl}?collectionid=${welcomeModalEntryData?.sponsorPlaylistId}`,
      );
    } else {
      history.replace(`${welcomeModalEntryData?.ctaDeeplinkUrl}`);
    }

    dispatch(
      sendWelcomeModalCTAClicked(
        modalScreenNames.WelcomeModal,
        welcomeModalEntryData?.title,
      ),
    );

    dispatch(actions.closeModal());
  };

  const handlePlayIcon = async () => {
    history.replace(`${welcomeModalEntryData?.ctaDeeplinkUrl}`);

    dispatch(
      sendWelcomeModalPlayIconClicked(
        modalScreenNames.WelcomeModal,
        welcomeModalEntryData?.title,
      ),
    );

    dispatch(actions.closeModal());
  };

  const handleClose = async () => {
    dispatch(
      sendWelcomeModalCancelClicked(
        modalScreenNames.WelcomeModal,
        welcomeModalEntryData?.title,
      ),
    );

    if (!guestMode) {
      window?.location.reload();
    } else {
      dispatch(actions.closeModal());
    }
  };

  const handleCancel = async () => {
    dispatch(
      sendWelcomeModalCancelClicked(
        modalScreenNames.WelcomeModal,
        welcomeModalEntryData?.title,
      ),
    );
    dispatch(actions.closeModal());
  };

  // TV back button handler
  useEffect(() => {
    const onBackNav = ({ id }) => {
      const { BACK } = vKey;

      if (id === BACK.id) {
        dispatch(
          sendWelcomeModalCancelClicked(
            modalScreenNames.WelcomeModal,
            welcomeModalEntryData?.title,
          ),
        );
        dispatch(actions.closeModal());
      }
    };

    sEnv.addEventListener(sEnv.SYSTEM.KEYDOWN, onBackNav);

    return () => {
      sEnv.removeEventListener(sEnv.SYSTEM.KEYDOWN, onBackNav);
    };
  }, []);

  const modal = useMemo(() => {
    return (
      <FocusModal>
        <BaseModal
          nav={{
            parent: CONTAINER,
            id: WATCH_NOW,
            nextup: CONTENT_AREA,
          }}
          className={styles.baseContainer}
          saveButtonContainerClassName={styles.watchButtonContainer}
          saveButtonClassName={styles.watchButton}
          cancelButtonClassName={cx(styles.watchButton, styles.shadowTrans)}
          closeIconClassName={styles.closex}
          saveButtonText={
            welcomeModalEntryData?.displayActionButton
              ? welcomeModalEntryData?.buttonActionText
              : welcomeModalEntryData?.buttonText
          }
          saveAction={handleWatchButton}
          closeAction={handleClose}
          cancelAction={handleCancel}
          showSaveButton
          showCancelButton={welcomeModalEntryData?.buttonDismissText}
          cancelButtonText={welcomeModalEntryData?.buttonDismissText}
        >
          <FocusDiv
            className={styles.modalContainer}
            nav={{
              id: CONTAINER,
              forwardFocus: WATCH_NOW,
            }}
          >
            {(welcomeModalEntryData?.sponsorText ||
              welcomeModalEntryData?.sponsorLogoUrl) && (
              <div
                className={`${
                  welcomeModalEntryData?.sponsorLabelRight
                    ? styles.titleHolder
                    : ''
                }`}
              >
                <div
                  className={cx(
                    styles.titleContainer,
                    !welcomeModalEntryData?.sponsorText &&
                      styles.titleContainerImageOnly,
                  )}
                >
                  {welcomeModalEntryData?.sponsorText && (
                    <>
                      <span>{`${welcomeModalEntryData?.sponsorText}`}</span>{' '}
                      <span>&nbsp;</span>
                    </>
                  )}
                  {welcomeModalEntryData?.sponsorLogoUrl && (
                    <img
                      src={welcomeModalEntryData?.sponsorLogoUrl}
                      alt="sponsor logo"
                    />
                  )}
                </div>
                {welcomeModalEntryData?.sponsorLabelRight ? (
                  <div>{welcomeModalEntryData?.sponsorLabelRight}</div>
                ) : (
                  ''
                )}
              </div>
            )}
            <FocusDiv
              className={styles.modalBodyContainer}
              nav={{
                parent: CONTAINER,
                id: CONTENT_AREA,
                nextdown: WATCH_NOW,
              }}
            >
              <img
                className={styles.modalImage}
                src={welcomeModalEntryData?.imageUrl}
                alt="modal url"
              />
              {welcomeModalEntryData?.displayPlayButton && (
                <div
                  onClick={handlePlayIcon}
                  className={styles.modalImageOverlayContainer}
                >
                  <img
                    className={styles.modalImageOverlay}
                    src={welcomeModalEntryData?.playIconImage.fileUrl}
                    alt="Play"
                  />
                </div>
              )}
              {welcomeModalEntryData?.imageLabel && (
                <div className={styles.overlay}>
                  {welcomeModalEntryData?.imageLabel}
                </div>
              )}
            </FocusDiv>
            <h3 className={styles.displayText}>
              {welcomeModalEntryData?.displayText}
            </h3>
          </FocusDiv>
        </BaseModal>
      </FocusModal>
    );
  }, [ageGroupConfig, currentProfile.id, modalStatus, welcomeModalEntryData]);

  return <>{modal}</>;
};

WelcomeModal.propTypes = {
  history: PropTypes.object,
};

export default withRouter(WelcomeModal);
