import React, { useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Page, FocusDiv } from '@accedo/vdkweb-tv-ui';
import classNames from 'classnames';
import { focusManager } from '@accedo/vdkweb-navigation';
import { setVideoContent } from '#/redux/modules/appPlayer/actions';
import { getContainerData } from '#/redux/modules/brightcove/selectors';

import {
  getBookmarkedVideo,
  getFullscreenBookmarkedVideoContent,
} from '#/redux/modules/appPlayer/selectors';

import { getCurrentAgeMetadata } from '#/redux/modules/accedoOne/selectors';
import { hex2rgba } from '#/utils/hex2rgba';
import { getVerticalNav, HOME, HEADER } from '#/utils/navigationHelper';

import {
  TopicSwimlaneFactory,
  PlaylistSwimlaneFactory,
  HeroSwimlaneFactory,
  FSCollectionSwimlaneFactory,
  RecentlyWatchedSwimlaneFactory,
} from '#/components/SwimlaneFactory';

import { onVideoTileClick } from '#/components/Swimlane/utils';
import { containersTypeAlias, videoAnalytics } from '#/config/constants';

import styles from './contentLayer.scss';
import assembleCurrentVideo from '#/utils/assembleCurrentVideo';
import { isWorkstation } from '#/utils/componentStyleConfig';
import Scroll from '#/components/Scroll/Scroll';
import useUpdateScroll from '#/hooks/useUpdateScroll';

const { CAROUSEL_CONTAINER, CONTAINER, PAGE } = HOME;

const navMap = {
  PAGE: {
    id: PAGE,
    forwardFocus: CONTAINER,
  },
  CONTAINER: {
    id: CONTAINER,
    forwardFocus: CAROUSEL_CONTAINER,
    useLastFocus: true,
    nextleft: HEADER.CONTAINER,
  },
  ...getVerticalNav([CAROUSEL_CONTAINER], {
    parent: CONTAINER,
  }),
};

export default function ContentLayer({ containers = [] }) {
  const dispatch = useDispatch();
  const [focusedElement, updateScroll] = useUpdateScroll(navMap.CONTAINER);
  const [hasBackgroundVideo, setHasBackgroundVideo] = useState(false);
  const fullscreenBookmarkedVideoContent = useSelector(
    getFullscreenBookmarkedVideoContent,
  );

  // Hooks related to getting age category
  const selectedAgeMetadata = useSelector(state =>
    getCurrentAgeMetadata(state),
  );

  const ageGradient = useMemo(() => {
    if (selectedAgeMetadata?.colorScheme) {
      // Force enables background video playback
      // TODO: Pull this value from control, maybe isBackgroundVideoEnabled
      if (false) {
        return hex2rgba(selectedAgeMetadata.colorScheme.backgroundPrimary);
      }
      return `linear-gradient(180deg, rgba(0,0,0,0) 0%, 
          ${hex2rgba(
            selectedAgeMetadata.colorScheme.backgroundPrimary,
            0.1,
          )} 30%, 
          ${hex2rgba(selectedAgeMetadata.colorScheme.backgroundPrimary)} 55%
        )`;
    }
    return '';
  }, [selectedAgeMetadata?.colorScheme]);

  // Hooks related to getting container and video data
  const bcContainerIds = useMemo(
    () =>
      containers
        .filter(
          container =>
            container._meta.typeAlias ===
            containersTypeAlias.CONTAINER_OVP_PLAYLIST,
        )
        .map(container => container._meta.id),
    [containers],
  );
  const bcContainerItems = useSelector(state =>
    getContainerData(state, bcContainerIds),
  );
  const bookmarkedVideo = useSelector(state => getBookmarkedVideo(state));

  const containersWithData = containers
    ?.map(item =>
      useSelector(state => getContainerData(state, [item?._meta?.id])),
    )
    .filter(item => item.hasData)
    .map(item => Object.keys(item)[0]);

  const navIds = useMemo(() => {
    if (!containers.length) {
      return {};
    }
    const defaultNav = getVerticalNav(
      containers?.map(carousel => `id-${carousel._meta.id}`),
      {
        parent: CAROUSEL_CONTAINER,
      },
    );

    // to ensure that we only add navs to containers with content available
    if (containersWithData?.length && !isWorkstation()) {
      containers
        .filter(
          item =>
            item.fsCollectionName &&
            item._meta.typeAlias !== containersTypeAlias.CONTAINER_HERO,
        )
        .forEach(item => containersWithData.push(item?._meta?.id));

      const tvNavOverride = getVerticalNav(
        containersWithData?.map(containerId => `id-${containerId}`),
        {
          parent: CAROUSEL_CONTAINER,
        },
      );
      return tvNavOverride;
    }
    return defaultNav;
  }, [containers.length, containersWithData.length]);

  // SENSICAL-2931: TV effect to play first item in background
  useEffect(() => {
    if (!isWorkstation() && bcContainerItems.hasData) {
      bcContainerIds.some(id => {
        if (!bcContainerItems[id]?.length) {
          return false;
        }
        const videoToPlay = fullscreenBookmarkedVideoContent || {
          playlists: [id],
          currentPlaylistIndex: 0,
          currentVideoIndex: 0,
          currentVideo: assembleCurrentVideo(bcContainerItems[id][0]),
          initiation: videoAnalytics.initiation.AUTO,
        };
        setHasBackgroundVideo(true);
        dispatch(setVideoContent(videoToPlay));

        return true;
      });
    }
  }, [bcContainerItems.hasData]);

  // Effect to start playback of first item on app launch when no content has been previously selected
  useEffect(() => {
    // check if no bookmark set and if there is content in the container to play
    if (
      isWorkstation() &&
      !bookmarkedVideo &&
      bcContainerItems.hasData &&
      !containers?.[0]?.disableInWeb
    ) {
      // iterate through containers to play video of first populated swimlane
      bcContainerIds.some(id => {
        if (!bcContainerItems[id]?.length) {
          return false;
        }
        const videoToPlay = bcContainerItems[id][0];
        setHasBackgroundVideo(true);
        dispatch(
          setVideoContent({
            playlists: [id],
            currentPlaylistIndex: 0,
            currentVideoIndex: 0,
            currentVideo: assembleCurrentVideo(videoToPlay),
            initiation: videoAnalytics.initiation.AUTO,
          }),
        );
        return true;
      });
    }
  }, [bookmarkedVideo, bcContainerItems.hasData]);

  useEffect(() => {
    if (!isWorkstation() && containers.length) {
      const swimlanes = containers?.filter(
        item => item._meta.typeAlias !== containersTypeAlias.CONTAINER_HERO,
      );
      let navId = `id-${swimlanes[0]._meta.id}`;

      if (fullscreenBookmarkedVideoContent) {
        const {
          currentVideoIndex,
          playlists,
        } = fullscreenBookmarkedVideoContent;
        navId = `id-${playlists[0]}-VIDEO-${currentVideoIndex}`;
      }
      focusManager.changeFocus(navId);
    }
  }, [containers, fullscreenBookmarkedVideoContent]);

  return (
    <>
      <div
        className={`${styles.videoOverlay}`}
        style={{ background: ageGradient }}
      />
      <Page
        className={classNames(
          containers?.[0]?.disableInWeb
            ? styles.mainHeroContainer
            : styles.mainContainer,
          { [styles.isTv]: !isWorkstation() },
        )}
        autofocus={false}
        nav={navMap.PAGE}
      >
        <FocusDiv nav={navMap.CONTAINER}>
          <Scroll
            enabled={!isWorkstation()}
            focusedElement={focusedElement}
            focusScrollPush={100}
            hasBackgroundVideo={hasBackgroundVideo}
          >
            {/* TODO:: fix focus forwarding */}
            {containers.length && (
              <FocusDiv
                nav={navMap.CAROUSEL_CONTAINER}
                className={classNames(styles.contentContainer, {
                  [styles.isTv]: !isWorkstation(),
                })}
              >
                {containers?.map(item => {
                  switch (item._meta.typeAlias) {
                    case containersTypeAlias.CONTAINER_RECENTLY_WATCHED:
                      // TODO: When API created to retrieve items
                      return (
                        <RecentlyWatchedSwimlaneFactory
                          containerId={item._meta.id}
                          ageTheme={selectedAgeMetadata}
                          nav={navIds[`id-${item._meta.id}`]}
                          key={`id-${item._meta.id}`}
                          onTileClick={onVideoTileClick(
                            [item._meta.id],
                            dispatch,
                            true,
                            true,
                          )(0)}
                          title={item.displayText}
                          onFocus={updateScroll}
                          fromHome={!isWorkstation()}
                          recentlyWatched
                        />
                      );
                    case containersTypeAlias.CONTAINER_OVP_PLAYLIST:
                      return (
                        <PlaylistSwimlaneFactory
                          containerId={item._meta.id}
                          playlistId={item.playlistId}
                          ageTheme={selectedAgeMetadata}
                          nav={navIds[`id-${item._meta.id}`]}
                          key={`id-${item._meta.id}`}
                          onTileClick={onVideoTileClick(
                            [item._meta.id],
                            dispatch,
                            true,
                            true,
                          )(0)}
                          title={item.displayText}
                          onFocus={updateScroll}
                          fromHome={!isWorkstation()}
                          livestreamTileEntry={
                            item?.livestreamTilePosition >= 0
                              ? item?.livestreamTileEntry
                              : null
                          }
                          livestreamTilePosition={item?.livestreamTilePosition}
                          sponsoredTilePosition={item?.sponsoredTilePosition}
                          swimlaneSponsor={item?.swimlaneSponsor}
                          sponsoredTileEntry={
                            item?.sponsoredTilePosition >= 0
                              ? item?.sponsoredTileEntry
                              : null
                          }
                        />
                      );
                    case containersTypeAlias.CONTAINER_TOPICS:
                      return (
                        <TopicSwimlaneFactory
                          containerId={item._meta.id}
                          ageTheme={selectedAgeMetadata}
                          nav={navIds[`id-${item._meta.id}`]}
                          key={`id-${item._meta.id}`}
                          title={item.displayText}
                          onFocus={updateScroll}
                        />
                      );
                    case containersTypeAlias.CONTAINER_SHOWS:
                      return (
                        <FSCollectionSwimlaneFactory
                          containerId={item._meta.id}
                          collectionName={item.fsCollectionName}
                          ageTheme={selectedAgeMetadata}
                          nav={navIds[`id-${item._meta.id}`]}
                          key={`id-${item._meta.id}`}
                          title={item.displayText}
                          onFocus={updateScroll}
                        />
                      );
                    case containersTypeAlias.CONTAINER_HERO:
                      if (item.disableInWeb && isWorkstation()) {
                        return (
                          <HeroSwimlaneFactory
                            containerId={item._meta.id}
                            ageTheme={selectedAgeMetadata}
                            nav={navIds[`id-${item._meta.id}`]}
                            key={`id-${item._meta.id}`}
                            onFocus={updateScroll}
                          />
                        );
                      }
                      return null;
                    default:
                      return null;
                  }
                })}
              </FocusDiv>
            )}
          </Scroll>
        </FocusDiv>
      </Page>
    </>
  );
}

ContentLayer.propTypes = {
  containers: PropTypes.array,
};
