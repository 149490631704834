import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import styles from './Document.scss';

const Document = ({ selector }) => {
  const content = useSelector(selector);

  return (
    <div>
      <p className={styles.textContent}>{content}</p>
    </div>
  );
};
Document.propTypes = {
  selector: PropTypes.func,
};

export default Document;
