import { handleActions } from 'redux-actions';

import { setPlatformData } from './actions';

const initialState = {
  platform: '',
  model: '',
  version: '',
  isFireTV: false,
  apiLevel: 0,
};

const setPlatformDataReducer = (state = initialState, { payload }) => {
  if (!payload.platform) {
    return state;
  }
  return {
    ...state,
    platform: payload.platform,
    isFireTV: payload.isFireTV,
    model: payload.model,
    version: payload.version,
    apiLevel: payload.apiLevel,
  };
};

const reducer = handleActions(
  {
    [setPlatformData]: setPlatformDataReducer,
  },
  initialState,
);

export default reducer;
