import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { noop } from '#/config/helpers';

import blankAvatar from '#/static/images/icons/selectAvatar.svg';

import {
  profileImage,
  profileName,
  profileNameSelected,
  profileImageContainer,
  selectedProfileImage,
  unselectedProfileImage,
} from './profileImage.scss';

const ProfileImage = ({
  src,
  name,
  onClick = noop,
  className,
  style,
  isSelected,
}) => (
  <span className={cx(profileImageContainer, className)} onClick={onClick}>
    <img
      style={style}
      className={cx(profileImage, {
        [className]: Boolean(className),
        [selectedProfileImage]: isSelected === true,
        [unselectedProfileImage]: isSelected === false,
      })}
      src={src || blankAvatar}
      alt="Profile"
    />
    {name && (
      <p className={isSelected ? profileNameSelected : profileName}>{name}</p>
    )}
  </span>
);

ProfileImage.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  style: PropTypes.object,
  isSelected: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func,
};

export default ProfileImage;
