import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getModalData } from '#/redux/modules/modal/selectors';
import styles from './benefitModal.scss';
import { isWorkstation } from '#/utils/componentStyleConfig';

const BenefitModal = () => {
  const data = useSelector(getModalData);
  const contentAreaRef = useRef(null);

  useEffect(() => {
    contentAreaRef?.current?.focus();
  }, []);

  return (
    <div
      tabIndex={0}
      ref={contentAreaRef}
      className={!isWorkstation() ? styles.tvmodalContent : styles.modalContent}
    >
      <p className={styles.title}>{data?.title}</p>
      <img src={data.image_path} className={styles.image} alt={data?.name} />
      <p className={styles.description}>{data?.description}</p>
    </div>
  );
};

export default BenefitModal;
