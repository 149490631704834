export class SecureRequest {
  public endpoints: { [key: string]: any };
  private url = '';
  private headers = new Headers();

  constructor(initator: { [key: string]: any }) {
    this.url = initator.url;
    this.endpoints = initator.endpoints;
    this.headers.append('Content-Type', 'application/json');
  }

  public async performSecureRequest(
    endpointKey: string,
    additionalSettings: any,
    params: string,
    sessionEndCondition: boolean = false,
  ): Promise<any> {
    let response;
    const preSettings: { [key: string]: any } = {};
    let requestUrl = this.url + this.endpoints[endpointKey].path;
    // In case request needs a variable in its URL
    if (requestUrl.indexOf('{{') > -1) {
      const route: string = requestUrl.replace(
        /\{\{([^}]+)\}\}/,
        replaceString => {
          const term: string = replaceString
            .replace(/\{/g, '')
            .replace(/\}/g, '');
          if (additionalSettings[term]) {
            const replaceTerm = `${additionalSettings[term]}`;
            delete additionalSettings[term];
            return replaceTerm;
          } else {
            throw new Error(`Parameter ${term} is required`);
          }
        },
      );
      requestUrl = route;
    }
    // In case request needs params
    if (params) {
      requestUrl = requestUrl + params;
    }
    preSettings.method = this.endpoints[endpointKey].method;
    preSettings.headers = this.headers;
    if (sessionEndCondition) {
      await this.endSession();
    }
    try {
      response = await fetch(requestUrl, {
        ...preSettings,
        body: JSON.stringify(additionalSettings),
      });
      return await response.json();
    } catch (error) {
      throw new Error(`Fetch failed for ${this.url}: ${error}`);
    }
  }

  private async endSession(): Promise<any> {
    let response;
    const requestUrl = this.url + '/session/end';
    try {
      response = await fetch(requestUrl, { method: 'POST' });
      return response;
    } catch (error) {
      throw new Error(`Fetch failed for ${requestUrl}`);
    }
  }
}
