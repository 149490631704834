export const assembleCurrentVideo = (videoItem, isPodcast = false) => {
  return {
    videoId: Number(videoItem.id),
    videoTitle: videoItem.name,
    videoShowTitle: videoItem.custom_fields?.subtitle,
    videoReferenceId: videoItem.reference_id,
    thumbnail: videoItem.thumbnail,
    episode_key_art_horizontal:
      videoItem.custom_fields.episode_key_art_horizontal,
    episode_key_art_square: videoItem.custom_fields?.episode_key_art_square,
    duration: videoItem.duration,
    text_tracks: videoItem.text_tracks,
    isPodcast:
      isPodcast ||
      videoItem.custom_fields?.media_type?.toLowerCase() === 'podcast',
  };
};

export default assembleCurrentVideo;
