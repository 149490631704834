import env from './env';
import app from './app';
import server from './server';
import accedoOne from './accedoOne';

/*
 * Exporting the aggregated configurations.
 */
export default {
  server,
  app,
  accedoOne,
  env,
};
