import { createActions } from 'redux-actions';

const PREVENT_DEFAULT_BACK = 'PREVENT_DEFAULT_BACK';

const actionOptions = {
  prefix: 'app/keys',
};

const actions = createActions(PREVENT_DEFAULT_BACK, actionOptions);

export const { preventDefaultBack } = actions;
