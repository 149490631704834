import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { authFlowRoutes } from '#/utils/routes';
import { signupStates, authScreenNames } from '#/config/constants';
import { isMobile } from '#/utils/componentStyleConfig';
import { actions } from '#/redux/modules/onboarding/actions';
import { Button } from '#/widgets/ui';
import {
  getDynamicLinks,
  getMobileRedirectInfo,
} from '#/redux/modules/accedoOne/selectors';

const { SIGN_UP } = authScreenNames;

const DesktopButton = ({ className }) => {
  const dispatch = useDispatch();
  return (
    <Link to={authFlowRoutes.PROFILE_CREATION}>
      <Button
        className={className}
        onClick={() =>
          dispatch(
            actions.setSignupState({
              signupState: signupStates.CREATE_PROFILE,
            }),
          )
        }
      >
        {SIGN_UP}
      </Button>
    </Link>
  );
};

DesktopButton.propTypes = {
  className: PropTypes.string,
};

const MobileButton = ({ className }) => {
  const deepLink = useSelector(getDynamicLinks)?.[0]?.url || '';
  const { cta } = useSelector(getMobileRedirectInfo) || '';
  return (
    <a href={deepLink}>
      <Button className={className}>{cta}</Button>
    </a>
  );
};

MobileButton.propTypes = {
  className: PropTypes.string,
};

export const SignInButton = isMobile() ? MobileButton : DesktopButton;
