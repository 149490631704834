const setNativeInput = (id, text, el = null) => {
  const input = el || document.getElementById(id);
  Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    'value',
  ).set.call(input, text);
  input.dispatchEvent(new Event('change', { bubbles: true }));
};

export { setNativeInput };
