import React from 'react';
import PropTypes from 'prop-types';
import { Provider as StateProvider } from 'react-redux';

import AppShell from './AppShell';

import 'react-smartbanner/dist/main.css';

const App = ({ store, content }) => (
  <StateProvider store={store}>
    <AppShell>{content}</AppShell>
  </StateProvider>
);

App.contextTypes = {
  router: PropTypes.object,
};

App.propTypes = {
  store: PropTypes.object,
  content: PropTypes.any,
};

export default App;
