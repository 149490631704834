import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import styles from './LoadingSpinner.scss';
import {
  isPortable,
  isSmallScreen,
  isLandscapeScreen,
  isGeneralDesktopScreen,
  isMediumDesktopScreen,
  isSmallDesktopScreen,
} from '#/utils/componentStyleConfig';
import backgroundDesktopSmall from '#/static/images/spinner/background_desktop_1280x832.png';
import backgroundDesktopMedium from '#/static/images/spinner/background_desktop_1728x1117.png';
import backgroundDesktopLarge from '#/static/images/spinner/background_desktop_1920x1080.png';
import backgroundMobileLandscape from '#/static/images/spinner/background_ipad-mini_landscape.png';
import backgroundMobilePortrait from '#/static/images/spinner/background_ipad-mini_portrait.png';
import spinnerIconFallback from '#/static/images/spinner/sensical_spinner.png';

const getBackgroundImage = spinnerBckg => {
  let url = '';
  if (isPortable() || isSmallScreen() || isSmallDesktopScreen()) {
    if (isLandscapeScreen()) {
      url = `url(${spinnerBckg?.ipadMiniLandscape ||
        backgroundMobileLandscape})`;
    } else {
      url = `url(${spinnerBckg?.ipadMiniPotrait || backgroundMobilePortrait})`;
    }
  } else if (isGeneralDesktopScreen()) {
    url = `url(${spinnerBckg?.desktopBckgLoader || backgroundDesktopSmall})`;
  } else if (isMediumDesktopScreen()) {
    url = `url(${spinnerBckg?.mediumDesktopBckgLoader ||
      backgroundDesktopMedium})`;
  } else {
    url = `url(${spinnerBckg?.largeDesktopBckgLoader ||
      backgroundDesktopLarge})`;
  }
  return url;
};

const LoadingSpinner = ({
  showLoading,
  showLoginSpinner,
  spinnerIcon,
  spinnerBckg,
}) => {
  const [imagesPreloaded, setImagesPreloaded] = useState(false);
  const loadingImagesRef = useRef(false);
  const loginSpinner = showLoginSpinner;
  // Prevent showing both spinners. Login spinner has precedence
  // over simple spinner.
  const simpleSpinner = !loginSpinner && showLoading;

  useEffect(() => {
    if (
      !loadingImagesRef.current ||
      !Object.keys(spinnerBckg)?.length ||
      !spinnerIcon
    ) {
      return;
    }

    loadingImagesRef.current = true;

    const imageUrls = [
      spinnerIcon,
      ...Object.values(spinnerBckg).reduce((urls, imageUrl) => {
        urls.add(imageUrl);

        return urls;
      }, new Set()),
    ];

    // Preload all images. Resolve when all are done.
    Promise.allSettled([
      imageUrls.map(
        url =>
          new Promise(res => {
            const image = new Image();
            image.src = url;
            const callback = () => {
              image.removeEventListener('load', callback);
              image.removeEventListener('error', callback);
              res();
            };
            image.addEventListener('load', callback);
            image.addEventListener('error', callback);
          }),
      ),
    ]).then(() => {
      setImagesPreloaded(true);
    });
  }, [spinnerBckg, spinnerIcon]);

  return (
    <>
      <div
        className={`${styles.loadingBackground} ${
          loginSpinner ? styles.show : ''
        }`}
        style={{
          backgroundImage: getBackgroundImage(
            imagesPreloaded ? spinnerBckg : {},
          ),
          backgroundPosition: '40%  0%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 50%',
        }}
      >
        <img
          src={
            imagesPreloaded
              ? spinnerIcon || spinnerIconFallback
              : spinnerIconFallback
          }
          alt="Spinner"
          className={`${styles.loadingIcon} ${styles.loadingIconLogo}`}
        />
      </div>
      <div
        className={`${styles.loadingBackground} ${
          simpleSpinner ? styles.show : ''
        }`}
      >
        <div className={styles.loadingIcon} />
      </div>
    </>
  );
};

LoadingSpinner.propTypes = {
  showLoading: PropTypes.bool,
  showLoginSpinner: PropTypes.bool,
  spinnerIcon: PropTypes.string,
  spinnerBckg: PropTypes.object,
};

export default LoadingSpinner;
