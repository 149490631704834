import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
// import cx from 'classnames';
import { getModalData } from '#/redux/modules/modal/selectors';
import routes from '#/utils/routes';
import styles from './SyncCustomDataModal.scss';

import { FocusDiv } from '#/widgets/ui';
import { TV_CONTENT_MODAL } from '#/utils/navigationHelper';
import { isWorkstation } from '#/utils/componentStyleConfig';
import { getRWSyncUpConfirmationModal } from '#/redux/modules/accedoOne/selectors';
import {
  ageRanges,
  ageGroupsMap,
  customDataFieldTypes,
} from '#/config/constants';
import { getStoredCustomData } from '#/redux/modules/guest/selectors';
import { actions as onboardingActions } from '#/redux/modules/onboarding/actions';
import { actions as guestActions } from '#/redux/modules/identity/identityGuest/actions';
import { actions } from '#/redux/modules/modal/actions';
import BaseModal from '../../common/BaseModal/BaseModal';
// focus handling
import FocusModal from '../../common/FocusModal/FocusModal';

const { CONTAINER, TV_CONTENT_MODAL_ACTION_BUTTON } = TV_CONTENT_MODAL;
const { BOOKMARKS_V2, RECENTLY_WATCHED_V2 } = customDataFieldTypes;

const SyncCustomDataModal = ({ history }) => {
  const dispatch = useDispatch();
  const { ctaNo = '', ctaYes = '', title = '', description = '' } = useSelector(
    getRWSyncUpConfirmationModal,
  );
  const { ageRange, saveProfile, addAnother = false } = useSelector(
    getModalData,
  );
  const customData = useSelector(state => getStoredCustomData(state, ageRange));
  const ageGroup = ageRanges[ageGroupsMap[ageRange]];

  const textBlocks = description
    .replace('{{ageGroup}}', ageGroup)
    .replace('{{assetsWatched}}', customData[RECENTLY_WATCHED_V2]?.length)
    .replace('{{bookmarksStored}}', customData[BOOKMARKS_V2]?.length)
    .split('\n');

  const onContinue = async () => {
    await saveProfile();
    dispatch(actions.closeModal());
    addAnother && history.push(routes.PROFILE_CREATION);
  };
  const onSyncData = async () => {
    await dispatch(onboardingActions.setProfileSyncData(customData));
    await dispatch(guestActions.wipeStoredCustomData(ageRange));
    onContinue();
  };

  return (
    <FocusModal>
      <BaseModal
        // saveButtonContainerClassName={styles.saveBtn}
        nav={{ parent: CONTAINER, id: TV_CONTENT_MODAL_ACTION_BUTTON }}
        showCloseX={!!isWorkstation()}
        className={styles.contentModal}
        saveButtonContainerClassName={styles.containerBtns}
        // sync options
        showSaveButton
        saveButtonText={ctaYes}
        saveButtonDisabled={false}
        saveAction={onSyncData}
        saveButtonClassName={styles.saveBtn}
        // no sync options
        showCancelButton
        cancelButtonText={ctaNo}
        cancelButtonDisabled={false}
        cancelAction={onContinue}
        cancelButtonClassName={styles.cancelBtn}
      >
        <FocusDiv
          className={styles.container}
          nav={{ id: CONTAINER, forwardFocus: TV_CONTENT_MODAL_ACTION_BUTTON }}
        >
          <h2 className={styles.title}>{title}</h2>
          <br />
          {textBlocks.map(block =>
            block === '' ? <br /> : <p className={styles.text}>{block}</p>,
          )}
        </FocusDiv>
      </BaseModal>
    </FocusModal>
  );
};

SyncCustomDataModal.propTypes = {
  history: PropTypes.object,
};

export default SyncCustomDataModal;
