import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withFocus } from '@accedo/vdkweb-navigation';
import { FocusDiv } from '#/widgets/ui';
import styles from './TVPlayerControls.scss';
import { PLAYER, PLAYER_TOP_OVERLAY } from '#/utils/navigationHelper';
import FF from '#/static/images/icons/tvPlayerControl/FF.png';
import RW from '#/static/images/icons/tvPlayerControl/RW.png';
import PLAY from '#/static/images/icons/tvPlayerControl/Play.png';
import PAUSE from '#/static/images/icons/tvPlayerControl/Pause.png';
import { ROLES } from '#/utils/accessibility';

const FocusImg = withFocus(
  ({ nav, ariaLabel, isFocused, className, alt, ...props }) => (
    <FocusDiv nav={nav} ariaLabel={ariaLabel}>
      <img
        {...props}
        className={classNames(className, { [styles.isFocused]: isFocused })}
        alt={alt}
        aira-hidden="true"
      />
    </FocusDiv>
  ),
);

const TVPlayerControls = ({
  isVOD = true,
  onRWClick,
  onFFClick,
  onPlayPauseClick,
  isVideoPlaying,
}) => {
  return (
    <FocusDiv
      nav={{
        id: PLAYER.TRANSPORT_CONTROLS,
        nextdown: PLAYER.CONTROL,
        nextup: PLAYER_TOP_OVERLAY.CONTAINER,
        parent: PLAYER.CONTAINER,
        forwardFocus: PLAYER.PLAY_PAUSE,
        useLastFocus: true,
      }}
      className={styles.container}
    >
      {isVOD && (
        <FocusImg
          className={styles.icon}
          nav={{
            id: PLAYER.RW,
            nextright: PLAYER.PLAY_PAUSE,
            parent: PLAYER.TRANSPORT_CONTROLS,
          }}
          src={RW}
          onClick={onRWClick}
          alt="RW"
          ariaLabel={`Rewind ${ROLES.BUTTON}`}
        />
      )}
      <FocusImg
        className={styles.icon}
        nav={{
          id: PLAYER.PLAY_PAUSE,
          nextleft: isVOD ? PLAYER.RW : null,
          nextright: isVOD ? PLAYER.FF : null,
          parent: PLAYER.TRANSPORT_CONTROLS,
        }}
        src={!isVideoPlaying ? PLAY : PAUSE}
        ariaLabel={
          !isVideoPlaying ? `play ${ROLES.BUTTON}` : `pause ${ROLES.BUTTON}`
        }
        onClick={onPlayPauseClick}
        alt="PlayPause"
      />
      {isVOD && (
        <FocusImg
          className={styles.icon}
          nav={{
            id: PLAYER.FF,
            nextleft: PLAYER.PLAY_PAUSE,
            parent: PLAYER.TRANSPORT_CONTROLS,
          }}
          src={FF}
          onClick={onFFClick}
          ariaLabel={`Fast forward ${ROLES.BUTTON}`}
          alt="FF"
        />
      )}
    </FocusDiv>
  );
};

TVPlayerControls.propTypes = {
  isVOD: PropTypes.bool,
  onRWClick: PropTypes.func,
  onFFClick: PropTypes.func,
  onPlayPauseClick: PropTypes.func,
  isVideoPlaying: PropTypes.bool,
};

export default withFocus(TVPlayerControls);
