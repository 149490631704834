import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { focusManager, withFocus } from '@accedo/vdkweb-navigation';

import { FocusDiv } from '#/widgets/ui';
import info from '#/static/images/icons/info_default.svg';
import styles from './tabView.scss';
import { getHorizontalNav } from '#/utils/navigationHelper';

import { isWorkstation, isPortable } from '#/utils/componentStyleConfig';
import { setSpeech } from '#/utils/accessibility';

const TAB_ITEM_PREFIX = 'TAB_ITEM';
const getItemId = index => `${TAB_ITEM_PREFIX}_${index}`;

const TabView = ({
  className,
  initialTabId,
  tabsSchema = [],
  children,
  currentTabId,
  onTabClick,
  nav = {},
  onTabViewFocused,
}) => {
  const [selectedTabId, setSelectedTabId] = useState(initialTabId);

  const tabId = currentTabId || selectedTabId;
  const setTabId = onTabClick || setSelectedTabId;
  const [currentFocusId, setCurrentFocusID] = useState(null);

  const onFocus = useCallback(() => {
    if (Object.keys(nav).length && onTabViewFocused) {
      onTabViewFocused(nav.id);
    }
  }, []);

  const tabItems = tabsSchema?.map((item, index) => getItemId(index));
  const tabItemNavMaps = {
    ...getHorizontalNav(tabItems, {
      parent: nav?.id,
    }),
  };

  useEffect(() => {
    if (!focusManager.getCurrentFocus()?.startsWith(TAB_ITEM_PREFIX)) {
      setCurrentFocusID(null);
    }
  }, [focusManager.getCurrentFocus()]);

  return (
    <div
      className={cx(className, {
        [styles.isTv]: !isWorkstation(),
      })}
    >
      {(isPortable() ? !tabsSchema[0].label.startsWith('Videos') : true) && (
        <ul className={styles.tabsTitles}>
          {tabsSchema.map(
            ({ label, id, hasInfoIcon }, index) =>
              (isPortable() ? !label.startsWith('About') : true) && (
                <div
                  key={id}
                  className={cx(styles.tabsWrapper, {
                    [styles.isFocused]: id === currentFocusId,
                  })}
                >
                  {hasInfoIcon && (
                    <img
                      src={info}
                      className={styles.tabsInfoIcon}
                      alt="Info"
                      aria-hidden="true"
                    />
                  )}
                  <FocusDiv
                    nav={tabItemNavMaps[getItemId(index)]}
                    onClick={() => setTabId(id)}
                    onFocus={() => {
                      onFocus();
                      setCurrentFocusID(id);
                      id === -1 && setSpeech(label);
                    }}
                    className={cx(styles.focusTab, {
                      [styles.isFocused]: nav.isFocused,
                    })}
                    ariaLabel={label}
                    {...(id === -1 && {
                      useNativeFocus: false,
                    })}
                  >
                    <li
                      className={cx(styles.tabTitle, {
                        [styles.tabTitleActive]: id === tabId,
                        [styles.tabTitleWithInfoIcon]: hasInfoIcon,
                        [styles.isFocused]: id === currentFocusId,
                        [styles.isTv]: !isWorkstation(),
                      })}
                    >
                      <a title={label} aria-hidden="true">
                        {label}
                      </a>
                    </li>
                  </FocusDiv>
                </div>
              ),
          )}
        </ul>
      )}
      {children?.length && (
        <div className={styles.tabContent}>
          {children?.filter(element => element.props['data-tab-id'] === tabId)}
        </div>
      )}
    </div>
  );
};

TabView.propTypes = {
  className: PropTypes.string,
  tabsSchema: PropTypes.array,
  children: PropTypes.node,
  initialTabId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentTabId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onTabClick: PropTypes.func,
  nav: PropTypes.object,
  onTabViewFocused: PropTypes.func,
};

export default withFocus(TabView);
