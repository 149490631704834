import React from 'react';
import PropTypes from 'prop-types';

import styles from './AdIndicator.scss';
import borderEllipse from '#/static/images/icons/ellipse-ad-countdown.svg';

const AdIndicator = ({ playTime, adSequence, adEndTime, adsCount }) => {
  const remainingTime = parseInt(adEndTime / 1000 - playTime);

  return (
    <div className={styles.adIndicator}>
      <h4>
        Ad {adSequence} of {adsCount}
      </h4>
      <div
        className={styles.timeContainer}
        style={{ backgroundImage: `url(${borderEllipse})` }}
      >
        <div className={styles.timeIndicator}>
          <h2>{remainingTime}</h2>
        </div>
      </div>
      <h4>Remaining</h4>
    </div>
  );
};
AdIndicator.propTypes = {
  playTime: PropTypes.number,
  adSequence: PropTypes.number,
  adEndTime: PropTypes.number,
  adsCount: PropTypes.number,
};

export default AdIndicator;
