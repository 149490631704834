import { createAction as actionCreatorFactory } from 'redux-actions';
import { actions as onboardingActions } from '#/redux/modules/onboarding/actions';

export const APP_START = 'app/APP_START';
export const APP_QUIT = 'app/APP_QUIT';
export const DEEPLINK_START = 'app/DEEPLINK_START';
export const DEEPLINK_QUIT = 'app/DEEPLINK_QUIT';
export const USER_AUTOLOADED = 'app/USER_AUTOLOADED';
export const DEEPLINK_OUTSIDE_PLAYER = 'app/DEEPLINK_OUTSIDE_PLAYER';

export const appStart = actionCreatorFactory(APP_START);
export const appQuit = actionCreatorFactory(APP_QUIT);
export const deepLinkStart = actionCreatorFactory(DEEPLINK_START);
export const deepLinkQuit = actionCreatorFactory(DEEPLINK_QUIT);
export const userAutoloaded = actionCreatorFactory(USER_AUTOLOADED);
export const deeplinkOutsidePlayer = actionCreatorFactory(
  DEEPLINK_OUTSIDE_PLAYER,
);
export const exitDeeplinkWorkflow = () => async dispatch => {
  dispatch(deepLinkQuit());
  dispatch(deeplinkOutsidePlayer(false));
  dispatch(onboardingActions.setProfileDeepLink(false));
};
