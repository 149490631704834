import { focusManager } from '@accedo/vdkweb-navigation';
import { isWorkstation, isVIZIO, isTizen } from './componentStyleConfig';

const ROLES = {
  NAVIGATION: 'navigation',
  DOCUMENT: 'document',
  BUTTON: 'button',
  TEXTBOX: 'textbox',
};

export const setSpeech = text => {
  if (isVIZIO() && window.VIZIO) {
    const activeEl = document.activeElement.getAttribute('id');
    if (activeEl) {
      document.getElementById(activeEl).blur();
    }
    window.VIZIO.Chromevox?.play(text);
  }
  if (isTizen()) {
    // TODO: Add Tizen speak function
  }
};

const getDurationLabel = ms => {
  const msToSeconds = Math.floor(ms / 1000);
  const hours = Math.floor(msToSeconds / 3600);
  const minutes = Math.floor((msToSeconds % 3600) / 60);
  const seconds = Math.floor((msToSeconds % 3600) % 60);
  const hDisplay =
    hours > 0 ? hours + (hours === 1 ? ' hour, ' : ' hours, ') : '';
  const mDisplay =
    minutes > 0 ? minutes + (minutes === 1 ? ' minute, ' : ' minutes, ') : '';
  const sDisplay =
    seconds > 0 ? seconds + (seconds === 1 ? ' second' : ' seconds') : '';

  return hDisplay + mDisplay + sDisplay;
};

const setNativeFocus = elementId => {
  if (!isWorkstation()) {
    const curFocusedEl = document.getElementById(
      elementId || focusManager.getCurrentFocus(),
    );
    curFocusedEl?.focus({ preventScroll: true });
  }
};

export { ROLES, setNativeFocus, getDurationLabel };
