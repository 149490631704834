import React from 'react';
import PropTypes from 'prop-types';
import { renderRoutes } from 'react-router-config';
import { ModalManager } from '#/widgets/modals';

import styles from '#/views/views.scss';

const TVViewContainer = ({ route }) => {
  return (
    <>
      <ModalManager />
      <div className={styles.viewport}>
        <div className={styles.wrapper}>
          <div className={styles.content}>{renderRoutes(route.routes)}</div>
        </div>
      </div>
    </>
  );
};

TVViewContainer.propTypes = {
  route: PropTypes.object,
};

export default TVViewContainer;
