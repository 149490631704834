import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Page, FocusDiv } from '@accedo/vdkweb-tv-ui';
import cx from 'classnames';

import Logo from '#/static/images/icons/logo-login.svg';
import { InfoForm, InfoInput } from '#/widgets/forms';
import { FocusButton, Footer } from '#/widgets/ui';
import LoginRedirect from '../LoginRedirect/LoginRedirect';
import { ErrorDispatcher } from '#/components';

import authStyles from '../authflow.scss';
import styles from './SignIn.scss';
import { authFlowRoutes } from '#/utils/routes';

import { signInPageTitle, signInPageTitleMobile } from '#/config/strings';
import { authScreenNames } from '#/config/constants';
import { LOGIN, login } from '#/redux/modules/identity/actions';
import { toggleLoginSpinner } from '#/redux/modules/spinner/actions';
import { sendScreenView } from '#/redux/modules/firestore/actions';
import { getSensicalTvConfig } from '#/redux/modules/accedoOne/selectors';
import { SIGNIN } from '#/utils/navigationHelper';

import { isWorkstation, isMobile } from '#/utils/componentStyleConfig';
import TVSignIn from '../TVSignIn/TVSignIn';

const SignIn = () => {
  const dispatch = useDispatch();
  const { logoUri = Logo } =
    useSelector(state => getSensicalTvConfig(state)) || {};
  const [loginFields, setLoginFields] = useState({ email: '', credential: '' });

  useEffect(() => {
    dispatch(sendScreenView(authScreenNames.SIGN_IN));
  }, []);

  const handleLogin = useCallback(async () => {
    try {
      dispatch(toggleLoginSpinner(true));
      await dispatch(login(loginFields));
    } finally {
      dispatch(toggleLoginSpinner(false));
    }
  }, [loginFields]);

  const [orientation, setOrientation] = useState(null);
  useEffect(() => {
    let timer;
    const checkOrientation = evt => {
      const { angle } = evt?.target?.screen?.orientation || {};
      timer = setTimeout(() => {
        setOrientation(angle);
      }, 100);
    };
    window.addEventListener('orientationchange', checkOrientation);
    return () => {
      clearTimeout(timer);
      window.removeEventListener('orientationchange', checkOrientation);
    };
  }, []);

  const webComponent = (
    <Page
      className={cx(authStyles.authFlow, styles.signIn)}
      autofocus
      nav={{ id: SIGNIN.PAGE, forwardFocus: SIGNIN.CONTAINER }}
    >
      <ErrorDispatcher errorId={LOGIN} />
      <LoginRedirect />
      <FocusDiv
        className={authStyles.content}
        nav={{ id: SIGNIN.CONTAINER, forwardFocus: SIGNIN.EMAIL_INPUT }}
      >
        <img src={logoUri} alt="Logo" />
        <h1 className={styles.signInHeading}>
          {isMobile() ? signInPageTitleMobile : signInPageTitle}
        </h1>
        <InfoForm onChange={(data, isValid) => isValid && setLoginFields(data)}>
          <InfoInput
            fieldName="email"
            fieldLabel="Email address"
            placeHolder={isMobile() ? 'Enter email address' : ''}
            inputAttributes={{ type: 'email' }}
            nav={{
              id: SIGNIN.EMAIL_INPUT,
              nextdown: SIGNIN.PASSWORD_INPUT,
              parent: SIGNIN.CONTAINER,
            }}
          />
          <InfoInput
            fieldName="credential"
            fieldLabel="Enter Password"
            placeHolder={isMobile() ? 'Minimum 8 characters' : ''}
            inputAttributes={{ type: 'password' }}
            nav={{
              id: SIGNIN.PASSWORD_INPUT,
              nextup: SIGNIN.EMAIL_INPUT,
              nextdown: SIGNIN.SIGNIN_BUTTON,
              parent: SIGNIN.CONTAINER,
            }}
          />
          <span
            key={orientation}
            className={
              isMobile()
                ? styles.forgotPasswordLinkMobile
                : styles.forgotPasswordLink
            }
          >
            <Link to={authFlowRoutes.FORGOT_PASSWORD}>Forgot password?</Link>
          </span>
        </InfoForm>
        <FocusButton
          onClick={handleLogin}
          primary
          className={styles.signInButton}
          nav={{
            id: SIGNIN.SIGNIN_BUTTON,
            nextup: SIGNIN.PASSWORD_INPUT,
            parent: SIGNIN.CONTAINER,
          }}
        >
          Sign In
        </FocusButton>
      </FocusDiv>
      <Footer />
    </Page>
  );

  return isWorkstation() ? webComponent : <TVSignIn />;
};

export default SignIn;
