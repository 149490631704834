import { createActions } from 'redux-actions';

const SET_ISSUSPENDED = 'SET_ISSUSPENDED';
const SET_TEXT_TO_SPEECH = 'SET_TEXT_TO_SPEECH';

const actionOptions = {
  prefix: 'app/system',
};

const actions = createActions(
  SET_ISSUSPENDED,
  SET_TEXT_TO_SPEECH,
  actionOptions,
);

export const { setIsSuspended, setTextToSpeech } = actions;
