import { BrowserRouter, MemoryRouter } from 'react-router-dom';

import { createBrowserHistory, createMemoryHistory } from 'history';

let appHistory;
let Router;

if (typeof global.document === 'undefined' || __TEST__) {
  appHistory = createMemoryHistory();
  Router = MemoryRouter;
} else {
  appHistory = createBrowserHistory();
  Router = BrowserRouter;
}

export default {
  appHistory,
  Router,
};
