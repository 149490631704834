import { createActions } from 'redux-actions';

export const SET_SCROLLED = 'SET_SCROLLED';
export const SCROLL_TRIGGER = 'SCROLL_TRIGGER';

const actionOptions = {
  prefix: 'quibi/scroll',
};

const actions = createActions(SET_SCROLLED, SCROLL_TRIGGER, actionOptions);

export const { setScrolled, scrollTrigger } = actions;
