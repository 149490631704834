let rootSelector = state => state.accedoOne;

export const setRootSelector = selector => {
  rootSelector = selector;
};

export const getContainerData = (state, ids) => {
  const containerData = rootSelector(state)?.containers;
  if (ids && ids.length > 0) {
    const specificContainers = {};
    ids.forEach(id => {
      if (id) {
        specificContainers[id] = containerData[id];
        specificContainers.hasData =
          specificContainers.hasData || containerData[id] !== undefined;
      }
    });
    return specificContainers;
  }
  return containerData;
};

export const getContainers = state =>
  Object.values(rootSelector(state)?.containers);

export const getLoadingState = state => {
  return rootSelector(state)?.loading;
};

export const getAssetData = state => {
  return rootSelector(state)?.asset;
};

export const getSearchQueryParams = state => {
  const { searchQuery } = rootSelector(state);
  return searchQuery;
};

export const getSearchData = state => {
  const { searchData } = rootSelector(state);
  return searchData;
};

export const getTopicQueryParams = state => {
  const { topicQuery } = rootSelector(state);
  return topicQuery;
};

export const getTopicData = state => {
  const { topicData } = rootSelector(state);
  return topicData;
};

export const getPlaylistQueryParams = state => {
  const { playlistQuery } = rootSelector(state);
  return playlistQuery;
};

export const getRecentlyWatchedQueryParams = state => {
  const { recentlyWatchedQuery } = rootSelector(state);
  return recentlyWatchedQuery;
};

export const getPlaylistData = state => {
  const { playlistData } = rootSelector(state);
  return playlistData;
};

export const getRecentlyWatchedData = state => {
  const { recentlyWatchedData } = rootSelector(state);
  return recentlyWatchedData;
};

export const getAssetsByReferenceIds = state => {
  const { assetsByReferenceId } = rootSelector(state);
  return assetsByReferenceId;
};

export const getRecentlyWatchedAssetsByReferenceIds = state => {
  const { recentlyWatchedAssetsByReferenceId } = rootSelector(state);
  return recentlyWatchedAssetsByReferenceId;
};

export const getPlaylistFetch = state => {
  return rootSelector(state)?.playlistFetch;
};

export const getSponsoredVideos = state => {
  return rootSelector(state)?.sponsoredVideos;
};
