import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { FocusDiv } from '#/widgets/ui';
import BlockContent from '#/components/ParentZone/hide-icon.svg';
import { ROLES } from '#/utils/accessibility';
import styles from './PlayerHideButton.scss';

const PlayerHideButton = ({
  containerClassName,
  className,
  nav = {},
  onClick,
}) => {
  return (
    <FocusDiv
      className={cx(styles.container, containerClassName)}
      classNameFocused={styles.isFocused}
      onClick={onClick}
      nav={nav}
      ariaLabel="Block Content"
      role={ROLES.BUTTON}
    >
      <img
        className={cx(styles.playerHideButton, {
          [className]: Boolean(className),
        })}
        src={BlockContent}
        alt="HIDE"
        aria-hidden="true"
        role="presentation"
      />
    </FocusDiv>
  );
};

PlayerHideButton.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  nav: PropTypes.object,
  onClick: PropTypes.func,
};

export default PlayerHideButton;
