import {
  getAccedoOneClient,
  getAccedoOneServices,
} from '#/services/accedoOne/accedoOne';
import accedoOneConfig from '#/config/accedoOne';

const getServices = accedoOneClient => {
  const accedoOneServices = getAccedoOneServices(accedoOneClient);
  // TODO: Vikimap's root is exposed here
  return {
    configuration: accedoOneServices.configuration,
    status: accedoOneServices.status,
  };
};

const getNoAppKeyServices = () => ({
  configuration: {
    get: () => ({}),
  },
  status: {
    getAppStatus: () => ({ status: 'nokey' }),
  },
});

/**
 * Get the services that need to be used in redux on server side
 *
 * @param  {Object} res           The HTTP response object
 * @return {Object}               The services will be used in redux
 */
export const getServerServices = res => {
  return getServices(res.locals.accedoOneClient);
};

/**
 * Get the services that need to be used in redux on client side
 *
 * @return {Object}               The services will be used in redux
 */
export const getClientServices = () => {
  if (!accedoOneConfig?.appKey) {
    return getNoAppKeyServices();
  }
  const accedoOneClient = getAccedoOneClient();
  return accedoOneClient ? getServices(accedoOneClient) : null;
};
