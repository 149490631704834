import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import xdk from '@accedo/xdk-core';

import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import screenfull from 'screenfull';
import classNames from 'classnames';
import { FocusDiv } from '@accedo/vdkweb-tv-ui';
import { focusManager } from '@accedo/vdkweb-navigation';
import Clamp from 'react-multiline-clamp';
import {
  getVideoFullScreen,
  getCurrentVideo,
} from '#/redux/modules/appPlayer/selectors';
import { isOpen } from '#/redux/modules/modal/selectors';
import {
  getDeepLinkStarted,
  getDeeplinkOutsidePlayer,
} from '#/redux/modules/lifecycle/selectors';
import { deepLinkStart } from '#/redux/modules/lifecycle/actions';
import {
  getAssetData,
  getSponsoredVideos,
} from '#/redux/modules/brightcove/selectors';
import { sendScreenView } from '#/redux/modules/firestore/actions';
import styles from './playerOverlay.scss';
import PlayerMoreContent from './PlayerMoreContent';
import {
  modalScreenNames,
  playerConstants,
  signupStates,
  GUEST_MODAL_TYPE,
} from '#/config/constants';
import PlayerCCButton from '#/widgets/ui/Buttons/PlayerCCButton';
import FavoriteButton from '#/widgets/ui/Buttons/FavoriteButton';
import PlayerBackButton from '#/widgets/ui/Buttons/PlayerBackButton';
import PlayerEpisodesButton from '#/widgets/ui/Buttons/PlayerEpisodesButton';
import PlayerFullscreenButton from '#/widgets/ui/Buttons/PlayerFullscreenButton';
import TVPlayerControls from '#/components/Player/TVPlayerControls';

import {
  getCustomDataVideoFavoritesById,
  getCustomDataPodcastsEpisodesFavoritesById,
} from '#/redux/modules/identity/selectors';

import { toggleLoginSpinner } from '#/redux/modules/spinner/actions';

import {
  isWorkstation,
  isMobile,
  isiOS,
  isPortable,
} from '#/utils/componentStyleConfig';

import {
  updateVideoFavoriteById,
  updatePodcastsEpisodesFavoriteById,
  switchToParentProfile,
} from '#/redux/modules/identity/actions';
import { actions } from '#/redux/modules/appPlayer';
import useToggleHeader from '#/hooks/useToggleHeader';
import { isGuestMode } from '#/redux/modules/user/selectors';
import {
  getCurrentProfileId,
  getCurrentProfileMinAge,
} from '#/redux/modules/onboarding/selectors';
import { getParentUserId } from '#/redux/modules/identity/identityUser/selectors';
import { actions as modalActions } from '#/redux/modules/modal/actions';
import { actions as onboardingActions } from '#/redux/modules/onboarding/actions';
import { logout } from '#/redux/modules/identity/identityGuest/actions';
import { authFlowRoutes } from '#/utils/routes';
import muteBtn from '#/static/images/icons/muteBtn.svg';
import { PLAYER, PLAYER_TOP_OVERLAY, HOME } from '#/utils/navigationHelper';
import PlayerHideButton from '../../widgets/ui/Buttons/PlayerHideButton';

const PlayerOverlay = ({
  history,
  container,
  fullscreenContainer,
  fromHome = false,
  currentTime,
  children,
}) => {
  const isModalOpen = useSelector(isOpen);
  const isFullScreen = useSelector(state => getVideoFullScreen(state));
  const currentVideo = useSelector(state => getCurrentVideo(state));
  const { duration, id: assetId, tags = [] } = useSelector(getAssetData) || {};
  const sponsoredVideos = useSelector(getSponsoredVideos) || [];
  const isVideoSponsored = useRef(false);
  useEffect(() => {
    for (let i = 0; i < sponsoredVideos.length; i += 1) {
      if (Number(sponsoredVideos[i]) === currentVideo?.videoId) {
        isVideoSponsored.current = true;
      }
    }
  }, [sponsoredVideos]);

  const videoFavorite = useSelector(state =>
    getCustomDataVideoFavoritesById(state, currentVideo?.videoId),
  );
  const podcastsEpisodesFavorite = useSelector(state =>
    getCustomDataPodcastsEpisodesFavoritesById(state, currentVideo?.videoId),
  );
  const [portalContainer] = useState(document.createElement('div'));
  const [showContentFilteringModal, setShowContentFilteringModal] = useState(
    false,
  );
  const mouseTimer = useRef();
  // const timerChecker = useRef();
  const overlayContainerRef = useRef();
  const toggleHeader = useToggleHeader();
  const dispatch = useDispatch();
  const player = xdk.media?.__player?._vjs;

  // ------------------------- CUSTSUPP-21085 ------------------- //
  // Check if the age group of the video match the selected group
  // if not supress the all videos and favorites button
  const selectedAge = useSelector(getCurrentProfileMinAge);
  const calledDeepLinkStarted = useRef(false);

  const deepLinkStarted = useSelector(state => getDeepLinkStarted(state));

  const deeplinkFromOutside = useSelector(getDeeplinkOutsidePlayer);
  const profileId = useSelector(getCurrentProfileId);
  const parentUserId = useSelector(getParentUserId);
  const deepLinkedAgeMatch = useMemo(() => {
    const {
      location: { pathname },
    } = history;

    if (profileId === parentUserId && deepLinkStarted) {
      return false;
    }
    if (
      pathname.match('/player/')?.input ||
      deepLinkStarted ||
      deeplinkFromOutside
    ) {
      const { videoReferenceId, videoId } = currentVideo;

      // only call dispatch once to prevent self update every time
      if (
        !calledDeepLinkStarted.current &&
        pathname === `/player/${videoReferenceId}` &&
        !deepLinkStarted
      ) {
        dispatch(deepLinkStart());
        // prevent calling on unload
        calledDeepLinkStarted.current = true;
      }

      return (
        deepLinkStarted &&
        Number(assetId) === videoId &&
        tags.includes(`${selectedAge}`)
      );
    }
    return true;
  }, [
    tags,
    selectedAge,
    deepLinkStarted,
    deeplinkFromOutside,
    calledDeepLinkStarted,
  ]);

  // ------------------------------------------------------------- //

  // todo: modify FF/RW behaviour when finalized
  const skip = (time, direction = 'ff') => {
    let seekTime = direction === 'ff' ? currentTime + time : currentTime - time;
    if (seekTime < 0) {
      seekTime = 0;
    }
    xdk.media?.seek(seekTime);
  };

  const playPause = () => {
    !player?.paused() ? player?.pause() : player?.play();
  };

  const exitHomeFullscreen = () => {
    dispatch(actions.setBackgroundPlayerFullscreen(false));
    toggleHeader(true);
    focusManager.changeFocus(HOME.PAGE);
  };
  // TV back button handler
  const handleBack = evt => {
    if (evt.id === 'device:vkey:back') {
      if (isModalOpen) {
        return;
      }
      if (screenfull.isFullscreen) {
        screenfull.exit();
        return;
      }
      dispatch(actions.setVideoTime(xdk.media?.getCurrentTime()));

      fromHome ? exitHomeFullscreen() : dispatch(actions.exitPlayback(history));
    }
  };

  useEffect(() => {
    if (isPortable()) {
      overlayContainerRef.current.classList.add(playerConstants.MOBILE_OVERLAY);
    }
  }, [overlayContainerRef.current]);

  // effect for portal into native player controls on mount and dismount
  useEffect(() => {
    container.prepend(portalContainer);
    return () => {
      container.removeChild(portalContainer);
    };
  }, [container]);

  useEffect(() => {
    xdk.environment.addEventListener(
      xdk.environment.SYSTEM.KEYDOWN,
      handleBack,
    );

    return () => {
      xdk.environment.removeEventListener(
        xdk.environment.SYSTEM.KEYDOWN,
        handleBack,
      );
    };
  }, [isModalOpen]);

  // effect for hide mouse
  useEffect(() => {
    if (isPortable()) {
      const videoContainer = document.getElementById(
        'sensicle-vjs-html5-player-container',
      );
      videoContainer.classList.add('hide-controls-opacity');
    }

    const playerControlsEl = document.getElementsByClassName(
      playerConstants.PLAYER_CONTROLS_CONTAINER_CLASS,
    )[0];

    const onMouseMove = () => {
      clearTimeout(mouseTimer.current);
      overlayContainerRef.current?.parentNode.classList.remove(
        styles.sneakPeekContainer,
      );
      playerControlsEl?.classList?.remove?.(styles.portal);
      if (playerControlsEl && !isPortable()) {
        playerControlsEl.style.opacity = 1;
        playerControlsEl.style.cursor = '';
      }
      if (!isWorkstation() && !isModalOpen) {
        focusManager.changeFocus(PLAYER.CONTAINER);
      }
      mouseTimer.current = setTimeout(() => {
        if (playerControlsEl && !isPortable()) {
          playerControlsEl.style.opacity = 0;
          playerControlsEl.style.cursor = 'none';
        }
        if (!isWorkstation() && !isModalOpen) {
          focusManager.changeFocus(PLAYER_TOP_OVERLAY.CC_BUTTON);
        }
      }, playerConstants.HIDE_OVERLAY_TIMEOUT);
    };

    const onMouseClick = evt => {
      if (
        parseInt(container.style.opacity, 10) === 1 &&
        evt.target === overlayContainerRef?.current
      ) {
        if (!isPortable()) {
          container.style.opacity = 0;
          container.style.cursor = 'none';
        }
      } else {
        onMouseMove();
      }
    };

    if (!isWorkstation()) {
      xdk.environment.addEventListener(
        xdk.environment.SYSTEM.KEYDOWN,
        onMouseMove,
      );
    }

    if (isPortable()) {
      onMouseMove(); // initial interaction for timer
    } else {
      playerControlsEl?.parentElement.addEventListener(
        'mousemove',
        onMouseMove,
      );
      playerControlsEl?.parentElement.addEventListener('click', onMouseClick);
      playerControlsEl?.classList.add(styles.portal);
      onMouseMove();
    }
    return () => {
      clearTimeout(mouseTimer.current);
      container.style.opacity = 1;
      container.style.cursor = '';
      container.parentElement?.removeEventListener('mousemove', onMouseMove);
      container.parentElement?.removeEventListener('click', onMouseClick);
      if (!isWorkstation()) {
        xdk.environment.removeEventListener(
          xdk.environment.SYSTEM.KEYDOWN,
          onMouseMove,
        );
      }
    };
  }, [isModalOpen, isFullScreen]);

  // Hide the player controls when fullscreen
  useEffect(() => {
    if (isPortable()) {
      if (isFullScreen) {
        overlayContainerRef.current.classList.add(styles.fullscreenContainer);
      } else {
        overlayContainerRef.current.classList.remove(
          styles.fullscreenContainer,
        );
      }
    }
    if (isiOS()) {
      if (isFullScreen) {
        overlayContainerRef.current.classList.add(styles.hideControlsDisplay);
        player?.paused() && player?.play();
      } else {
        overlayContainerRef.current.classList.remove(
          styles.hideControlsDisplay,
        );
        player?.paused() && player?.play();
      }
    }
  }, [isFullScreen]);

  // Hide the progress when playing sponsored video
  useEffect(() => {
    if (!currentVideo) {
      return;
    }
    const playerProgressControl = document.getElementsByClassName(
      'vjs-progress-control vjs-control',
    )[0];
    if (isVideoSponsored.current && playerProgressControl) {
      playerProgressControl.style.display = 'none';
    } else if (playerProgressControl) {
      playerProgressControl.style.display = 'block';
    }
  }, [currentVideo]);

  useEffect(() => {
    if (isWorkstation()) {
      const playerControlsEl = document.getElementsByClassName(
        playerConstants.PLAYER_CONTROLS_CONTAINER_CLASS,
      )[0];

      const playBtn =
        document.getElementsByClassName(
          'vjs-play-control vjs-control vjs-button vjs-paused',
        )[0] ||
        document.getElementsByClassName(
          'vjs-play-control vjs-control vjs-button vjs-playing',
        )[0] ||
        document.getElementsByClassName(
          'vjs-play-control vjs-control vjs-button',
        )[0];

      const onMouseMove = () => {
        playerControlsEl?.parentElement.classList.remove('vjs-user-inactive');
        playerControlsEl?.parentElement.classList.add('vjs-user-active');
        clearTimeout(mouseTimer.current);
        mouseTimer.current = setTimeout(() => {
          if (isPortable() || playBtn?.title?.toLowerCase() === 'pause') {
            playerControlsEl?.parentElement.classList.remove('vjs-user-active');
            playerControlsEl?.parentElement.classList.add('vjs-user-inactive');
          }
        }, playerConstants.HIDE_OVERLAY_TIMEOUT + 200);
      };

      xdk.environment.addEventListener(
        xdk.environment.SYSTEM.KEYDOWN,
        onMouseMove,
      );

      if (overlayContainerRef.current) {
        overlayContainerRef.current.addEventListener('mousemove', onMouseMove);
      }

      if (isPortable()) {
        document.addEventListener('touchstart', onMouseMove);
        document.addEventListener('touchmove', onMouseMove);
        onMouseMove(); // initial timer
      }

      return () => {
        clearTimeout(mouseTimer.current);
        container.style.opacity = 1;
        container.style.cursor = '';
        overlayContainerRef.current?.removeEventListener(
          'mousemove',
          onMouseMove,
        );
        xdk.environment.removeEventListener(
          xdk.environment.SYSTEM.KEYDOWN,
          onMouseMove,
        );
        document.removeEventListener('touchstart', onMouseMove);
        document.removeEventListener('touchmove', onMouseMove);
      };
    }
  }, [container]);

  useEffect(() => {
    if (showContentFilteringModal) {
      dispatch(
        modalActions.openModal({
          modalId: 'ContentFilteringModal',
          finallyAction: async () => {
            setShowContentFilteringModal(false);
          },
        }),
      );
    }
  }, [showContentFilteringModal]);

  const guestMode = useSelector(isGuestMode);
  const onToggleVideoFavorite = useCallback(async () => {
    if (guestMode) {
      if (screenfull.isFullscreen) {
        screenfull.exit();
      }
      !player?.paused() && playPause();
      dispatch(
        modalActions.openModal({
          modalId: 'GuestProfileModal',
          modalData: {
            modalType: GUEST_MODAL_TYPE.favoritesBlocked,
          },
          successAction: () => {
            dispatch(
              onboardingActions.setSignupState({
                signupState: signupStates.CREATE_ACCOUNT,
              }),
            );
            dispatch(logout(authFlowRoutes.ACCOUNT_CREATION));
          },
        }),
      );
    } else if (currentVideo?.isPodcast) {
      requestAnimationFrame(() => {
        dispatch(toggleLoginSpinner(true));
      });
      const podcastsEpisodesData = {
        id: `${currentVideo.videoId}`,
        name: currentVideo.videoTitle,
        reference_id: currentVideo.videoReferenceId,
        thumbnail: currentVideo.thumbnail,
        custom_fields: {
          subtitle: currentVideo.videoShowTitle,
          episode_key_art_horizontal: currentVideo.episode_key_art_horizontal,
          episode_key_art_square: currentVideo?.episode_key_art_square,
        },
        duration,
      };
      await dispatch(
        updatePodcastsEpisodesFavoriteById(
          currentVideo.videoId,
          podcastsEpisodesFavorite === null,
          podcastsEpisodesData,
        ),
      );
      requestAnimationFrame(() => {
        dispatch(toggleLoginSpinner(false));
      });
    } else {
      requestAnimationFrame(() => {
        dispatch(toggleLoginSpinner(true));
      });
      const videoData = {
        id: `${currentVideo.videoId}`,
        name: currentVideo.videoTitle,
        reference_id: currentVideo.videoReferenceId,
        thumbnail: currentVideo.thumbnail,
        custom_fields: {
          subtitle: currentVideo.videoShowTitle,
          episode_key_art_horizontal: currentVideo.episode_key_art_horizontal,
        },
        duration,
      };
      await dispatch(
        updateVideoFavoriteById(
          currentVideo.videoId,
          videoFavorite === null,
          videoData,
        ),
      );
      requestAnimationFrame(() => {
        dispatch(toggleLoginSpinner(false));
      });
    }
  }, [
    currentVideo,
    videoFavorite,
    podcastsEpisodesFavorite,
    duration,
    guestMode,
  ]);

  const onToggleHideContent = useCallback(async () => {
    if (guestMode) {
      if (screenfull.isFullscreen) {
        screenfull.exit();
      }
      !player?.paused() && playPause();
      dispatch(
        modalActions.openModal({
          modalId: 'GuestProfileModal',
          modalData: {
            modalType: GUEST_MODAL_TYPE.contentBlockingBlocked,
          },
          successAction: () => {
            dispatch(
              onboardingActions.setSignupState({
                signupState: signupStates.CREATE_ACCOUNT,
              }),
            );
            dispatch(logout(authFlowRoutes.ACCOUNT_CREATION));
          },
        }),
      );
    } else {
      requestAnimationFrame(() => {
        dispatch(toggleLoginSpinner(true));
      });
      if (screenfull.isFullscreen) {
        screenfull.exit();
      }
      await dispatch(
        modalActions.openModal({
          modalId: 'PinModal',
          successAction: async pin => {
            await dispatch(
              switchToParentProfile({ credential: pin, blocking: true }),
            );
            setShowContentFilteringModal(true);
          },
        }),
      );
      requestAnimationFrame(() => {
        dispatch(toggleLoginSpinner(false));
      });
    }
    dispatch(
      sendScreenView(
        `${modalScreenNames.ContentBlockingModal} (${currentVideo.videoId})`,
      ),
    );
  }, [guestMode]);

  // display a mute button then remove it after player is unmute
  const [playerMute, setPlayerMute] = useState(player?.muted());
  const HandleMute = () => {
    player?.muted(false);
    setPlayerMute(false);
  };
  useEffect(() => {
    !player?.muted() && HandleMute();
  }, [player?.muted()]);

  return ReactDOM.createPortal(
    <FocusDiv
      className={classNames(styles.sneakPeekContainer, {
        [styles.fullscreen]: isFullScreen,
      })}
      nav={{
        id: PLAYER.CONTAINER,
        forwardFocus: PLAYER_TOP_OVERLAY.CONTAINER,
        useLastFocus: true,
      }}
    >
      <div
        id="playerOverlayContainer"
        ref={overlayContainerRef}
        className={classNames(styles.container, {
          [styles.isTv]: !isWorkstation(),
        })}
      >
        {isPortable() ? (
          <FocusDiv
            className={styles.topContainer}
            nav={{
              id: PLAYER_TOP_OVERLAY.CONTAINER,
              forwardFocus: PLAYER_TOP_OVERLAY.CC_BUTTON,
              useLastFocus: true,
              parent: PLAYER.CONTAINER,
            }}
          >
            {children}
            <div className={styles.metaBackBtnContainer}>
              {isWorkstation() && <PlayerBackButton setTime />}
              <div className={styles.metaContainer}>
                <h5>{currentVideo.videoShowTitle}</h5>
                <Clamp
                  lines={2}
                  maxLines={8}
                  withToggle
                  showMoreElement={({ toggle }) => (
                    <span type="button" onClick={toggle}>
                      Show more
                    </span>
                  )}
                  showLessElement={({ toggle }) => (
                    <span type="button" onClick={toggle}>
                      Show less
                    </span>
                  )}
                >
                  <h2>{currentVideo.videoTitle}</h2>
                </Clamp>
              </div>
            </div>
            <div className={styles.buttons}>
              <PlayerEpisodesButton
                nav={{
                  id: PLAYER_TOP_OVERLAY.ALL_EPISODES_BUTTON,
                  nextleft: PLAYER_TOP_OVERLAY.FAV_BUTTON,
                  nextdown: PLAYER.TRANSPORT_CONTROLS,
                  parent: PLAYER_TOP_OVERLAY.CONTAINER,
                }}
              />
              {!isVideoSponsored.current && (
                <PlayerHideButton
                  nav={{
                    id: PLAYER_TOP_OVERLAY.HIDE_BUTTON,
                    nextleft: PLAYER_TOP_OVERLAY.CC_BUTTON,
                    nextright: PLAYER_TOP_OVERLAY.FAV_BUTTON,
                    nextdown: PLAYER.TRANSPORT_CONTROLS,
                    parent: PLAYER_TOP_OVERLAY.CONTAINER,
                  }}
                  onClick={onToggleHideContent}
                />
              )}
              {!isVideoSponsored.current &&
              (deepLinkedAgeMatch || guestMode) ? (
                <FavoriteButton
                  favoriteButtonClassName={styles.favoriteButton}
                  isFavorited={
                    podcastsEpisodesFavorite !== null || videoFavorite !== null
                  }
                  nav={{
                    id: PLAYER_TOP_OVERLAY.FAV_BUTTON,
                    nextleft: PLAYER_TOP_OVERLAY.HIDE_BUTTON,
                    nextright: PLAYER_TOP_OVERLAY.ALL_EPISODES_BUTTON,
                    nextdown: PLAYER.TRANSPORT_CONTROLS,
                    parent: PLAYER_TOP_OVERLAY.CONTAINER,
                  }}
                  onClick={onToggleVideoFavorite}
                />
              ) : null}
            </div>
          </FocusDiv>
        ) : (
          <FocusDiv
            className={styles.topContainer}
            nav={{
              id: PLAYER_TOP_OVERLAY.CONTAINER,
              forwardFocus: PLAYER_TOP_OVERLAY.CC_BUTTON,
              useLastFocus: true,
              parent: PLAYER.CONTAINER,
            }}
          >
            {isWorkstation() && <PlayerBackButton setTime />}
            <div className={styles.metaContainer}>
              <h2>{currentVideo.videoTitle}</h2>
              <h5>{currentVideo.videoShowTitle}</h5>
            </div>
            <PlayerCCButton
              nav={{
                id: PLAYER_TOP_OVERLAY.CC_BUTTON,
                nextright: PLAYER_TOP_OVERLAY.HIDE_BUTTON,
                nextdown: PLAYER.TRANSPORT_CONTROLS,
                parent: PLAYER_TOP_OVERLAY.CONTAINER,
              }}
            />
            {!isVideoSponsored.current && (
              <PlayerHideButton
                nav={{
                  id: PLAYER_TOP_OVERLAY.HIDE_BUTTON,
                  nextleft: PLAYER_TOP_OVERLAY.CC_BUTTON,
                  nextright: PLAYER_TOP_OVERLAY.FAV_BUTTON,
                  nextdown: PLAYER.TRANSPORT_CONTROLS,
                  parent: PLAYER_TOP_OVERLAY.CONTAINER,
                }}
                onClick={onToggleHideContent}
              />
            )}
            <>
              {!isVideoSponsored.current &&
              (deepLinkedAgeMatch || guestMode) ? (
                <FavoriteButton
                  usePlayerStyle
                  isFavorited={
                    podcastsEpisodesFavorite !== null || videoFavorite !== null
                  }
                  nav={{
                    id: PLAYER_TOP_OVERLAY.FAV_BUTTON,
                    nextleft: PLAYER_TOP_OVERLAY.HIDE_BUTTON,
                    nextright: PLAYER_TOP_OVERLAY.ALL_EPISODES_BUTTON,
                    nextdown: PLAYER.TRANSPORT_CONTROLS,
                    parent: PLAYER_TOP_OVERLAY.CONTAINER,
                  }}
                  onClick={onToggleVideoFavorite}
                />
              ) : null}
              <PlayerEpisodesButton
                nav={{
                  id: PLAYER_TOP_OVERLAY.ALL_EPISODES_BUTTON,
                  nextleft: PLAYER_TOP_OVERLAY.FAV_BUTTON,
                  nextdown: PLAYER.TRANSPORT_CONTROLS,
                  parent: PLAYER_TOP_OVERLAY.CONTAINER,
                }}
              />
            </>
          </FocusDiv>
        )}
        {!isVideoSponsored.current && !isWorkstation() && (
          <TVPlayerControls
            onRWClick={() => skip(10, 'rw')}
            onFFClick={() => skip(10, 'ff')}
            onPlayPauseClick={() => playPause()}
            isVideoPlaying={!player?.paused()}
          />
        )}
        <div className={styles.contentContainer}>
          <PlayerMoreContent fromHome={fromHome} />
        </div>
      </div>
      {((screenfull.isEnabled && isWorkstation()) || isMobile()) && (
        <div id="PlayerFullscreenButton">
          <PlayerFullscreenButton
            fullscreenLayout={isFullScreen}
            container={fullscreenContainer}
          />
        </div>
      )}
      {playerMute && isiOS() && player?.muted() && (
        <div
          className={styles.muteBtn}
          onClick={HandleMute}
          style={{ display: playerMute ? 'flex' : 'none' }}
        >
          <img alt="unmute" src={muteBtn} />
          <p>Tap to unmute</p>
        </div>
      )}

      <FocusDiv nav={{ id: PLAYER.FOCUS_SINK }} />
    </FocusDiv>,
    portalContainer,
  );
};

PlayerOverlay.propTypes = {
  container: PropTypes.object,
  fullscreenContainer: PropTypes.object,
  fromHome: PropTypes.bool,
  currentTime: PropTypes.number,
  children: PropTypes.any,
};

export default withRouter(PlayerOverlay);
