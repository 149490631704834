import { createActions } from 'redux-actions';
import moment from 'moment-mini';
import { sendAddProfile } from '#/redux/modules/firestore/actions';

const SET_SIGNUP_STATE = 'SET_SIGNUP_STATE';
const SET_TV_CODE = 'SET_TV_CODE';

// Profile loading and population
export const POPULATE_PROFILES = 'POPULATE_PROFILES';
export const POPULATE_PROFILE_CUSTOM_DATA = 'POPULATE_PROFILE_CUSTOM_DATA';
export const UPDATE_PROFILE_DATA = 'UPDATE_PROFILE_DATA';
export const REMOVE_PROFILE = 'REMOVE_PROFILE';

// Profile actions
const SET_SELECTED_PROFILE = 'SET_SELECTED_PROFILE';
const EDIT_PROFILE = 'EDIT_PROFILE';
const ADD_PROFILE = 'ADD_PROFILE';
const SAVE_PROFILE = 'SAVE_PROFILE';
const DISCARD_PROFILE = 'DISCARD_PROFILE';
const RESET_STATE = 'RESET_STATE';

// Profile edit actions
const SET_PROFILE_FIELDS = 'SET_PROFILE_FIELDS';
const SET_PROFILE_NAME = 'SET_PROFILE_NAME';
const SET_PROFILE_AGE = 'SET_PROFILE_AGE';
const SET_PROFILE_BIRTHDAY_MONTH = 'SET_PROFILE_BIRTHDAY_MONTH';
const SET_PROFILE_SYNC_DATA = 'SET_PROFILE_SYNC_DATA';
const SET_PROFILE_AVATAR = 'SET_PROFILE_AVATAR';
const SET_PROFILE_DEEP_LINK = 'SET_PROFILE_DEEP_LINK';
const SET_DEEP_LINK_CORRECT_AGE = 'SET_DEEP_LINK_CORRECT_AGE';

// Account actions
const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
const SET_EMAIL_VERIFIED = 'SET_EMAIL_VERIFIED';

// Guest Profile actions
const SHOW_GUEST_PROFILE_IMAGE = 'SHOW_GUEST_PROFILE_IMAGE';

export const ONBOARDING_ACTION_PREFIX = 'app/onboarding';

const actionOptions = {
  prefix: ONBOARDING_ACTION_PREFIX,
};

export const actions = createActions(
  {
    // Profile actions
    POPULATE_PROFILES: profiles =>
      profiles
        .filter(({ user: { type } }) => type === 'user.SoftProfile')
        .map(({ user: { id, displayName: name, avatarUrl: avatar } }) => ({
          id,
          name,
          avatar,
        })),
    POPULATE_PROFILE_CUSTOM_DATA: ({ birthdate, ...rest }) => {
      if (!birthdate) {
        return {};
      }
      const m = moment(birthdate, 'YYYY-MM-DD');
      const now = moment();
      const age =
        (m.month() === now.month() && now.date() >= m.date()) ||
        now.month() > m.month()
          ? now.year() - m.year()
          : now.year() - m.year() - 1;
      const birthdayMonth = m.month() + 1;
      return {
        age,
        birthdayMonth,
        ...rest,
      };
    },
  },
  RESET_STATE,
  SET_SIGNUP_STATE,
  SET_TV_CODE,

  UPDATE_PROFILE_DATA,
  REMOVE_PROFILE,

  // Profile actions
  SET_SELECTED_PROFILE,
  EDIT_PROFILE,
  ADD_PROFILE,
  SAVE_PROFILE,
  DISCARD_PROFILE,

  // Profile edit actions
  SET_PROFILE_FIELDS,
  SET_PROFILE_NAME,
  SET_PROFILE_AGE,
  SET_PROFILE_BIRTHDAY_MONTH,
  SET_PROFILE_AVATAR,
  SET_PROFILE_SYNC_DATA,
  SET_PROFILE_DEEP_LINK,
  SET_DEEP_LINK_CORRECT_AGE,

  // Account actions
  CREATE_ACCOUNT,
  SET_EMAIL_VERIFIED,

  // Guest Profile actions
  SHOW_GUEST_PROFILE_IMAGE,

  actionOptions,
);

// Profile actions
export const newDraftProfile = () => async dispatch => {
  dispatch(sendAddProfile());
  return dispatch(actions.addProfile(null));
};

export const updateProfileId = (storeProfileId, serverProfileId) =>
  actions.updateProfileData({ storeId: storeProfileId, id: serverProfileId });
