import { handleActions } from 'redux-actions';
import { setScrolled, scrollTrigger } from './actions';

const initialState = {
  isScrolled: false,
  scrollTrigger: false,
};

const setScrolledReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    isScrolled: payload,
  };
};

const setTriggerReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    direction: payload,
    scrollTrigger: !state.scrollTrigger,
  };
};

const reducer = handleActions(
  {
    [setScrolled]: setScrolledReducer,
    [scrollTrigger]: setTriggerReducer,
  },
  initialState,
);

export default reducer;
