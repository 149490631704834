import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FocusDiv } from '#/widgets/ui';

import styles from './FavoriteButton.scss';

import FavDefault from '#/static/images/icons/favorite_default.svg';
import FavDefaultBlack from '#/static/images/icons/favorite_default_black.svg';
import FavFelected from '#/static/images/icons/favorite_selected.svg';
import { isWorkstation } from '#/utils/componentStyleConfig';
import { ROLES } from '#/utils/accessibility';

const FavoriteButton = ({
  nav = {},
  isFavorited,
  onClick,
  usePlayerStyle = false,
  favoriteButtonClassName = '',
}) => {
  let iconToUse = FavFelected;
  if (!isFavorited && usePlayerStyle) {
    iconToUse = FavDefault;
  } else if (!isFavorited && !usePlayerStyle) {
    iconToUse = FavDefaultBlack;
  }

  return (
    <FocusDiv
      className={cx(styles.container, {
        [styles.containerInfo]: !usePlayerStyle,
      })}
      classNameFocused={styles.isFocused}
      onClick={onClick}
      nav={nav}
      ariaLabel="Favorite"
      role={ROLES.BUTTON}
    >
      <img
        className={cx(styles.favoriteButton, favoriteButtonClassName, {
          [styles.imageInfo]: !usePlayerStyle && isWorkstation(),
        })}
        src={iconToUse}
        alt="FAV"
        role="presentation"
        aria-hidden="true"
      />
    </FocusDiv>
  );
};

FavoriteButton.propTypes = {
  isFavorited: PropTypes.bool,
  onClick: PropTypes.func,
  usePlayerStyle: PropTypes.bool,
  nav: PropTypes.object,
  favoriteButtonClassName: PropTypes.string,
};

export default FavoriteButton;
