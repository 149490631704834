import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { LayoutX } from '@accedo/vdkweb-tv-ui';
import { focusManager } from '@accedo/vdkweb-navigation';
import styles from './AgeModal.scss';
import { PROFILECREATION, AGEMODAL } from '#/utils/navigationHelper';

import {
  getDraftProfile,
  getAgeBrackets,
} from '#/redux/modules/onboarding/selectors';
import { actions } from '#/redux/modules/modal/actions';
import { setSpeech } from '#/utils/accessibility';

import { NumpadButton } from '#/widgets/ui';
import BaseModal from '../../common/BaseModal/BaseModal';

const AgeModal = ({ successAction }) => {
  const dispatch = useDispatch();
  const profile = useSelector(getDraftProfile);
  const fieldOptions = useSelector(getAgeBrackets);
  const ageBrackets = fieldOptions.filter(e => {
    return e != null;
  });
  const fieldSelected = profile.age;
  const [selected, setSelected] = useState(fieldSelected);

  useEffect(() => {
    focusManager.changeFocus(AGEMODAL.KEYPAD);
  }, []);

  const optionsButtons = Object.entries(ageBrackets).map(([k, label]) => (
    <NumpadButton
      key={k}
      onClick={() => {
        setSelected(label);
      }}
      label={label}
      selected={selected === label}
      nav={{ id: `key-${label}` }}
      onFocus={() => setSpeech(label)}
    />
  ));

  const onSave = () => {
    if (selected !== null) {
      successAction.payload = selected;
      dispatch(successAction);
    }
    dispatch(actions.closeModal());
    focusManager.changeFocus(PROFILECREATION.AGE_INPUT);
  };

  // todo: hide close X and handle key back
  return (
    <BaseModal
      className={styles.content}
      showSaveButton
      saveButtonText="Save"
      saveButtonDisabled={!selected}
      saveButtonClassName={styles.saveButton}
      saveAction={() => () => onSave()}
      nav={{
        id: AGEMODAL.navOverride.id,
        parent: AGEMODAL.navOverride.parent,
        nextup: AGEMODAL.KEYPAD,
      }}
    >
      <h2 className={styles.title}>Select age</h2>
      <LayoutX
        className={styles.ageModalContent}
        nav={{
          id: AGEMODAL.KEYPAD,
          forwardFocus: 'key-2',
          nextdown: selected ? AGEMODAL.SAVE_BUTTON : null,
        }}
        layout={AGEMODAL.LAYOUT}
      >
        {optionsButtons}
      </LayoutX>
    </BaseModal>
  );
};

AgeModal.propTypes = {
  successAction: PropTypes.object,
};

export default AgeModal;
