import React from 'react';
import { useSelector } from 'react-redux';

import BaseModal from '../../common/BaseModal/BaseModal';

import { getModalData } from '#/redux/modules/modal/selectors';
import styles from './ConfirmModal.scss';

const ConfirmModal = () => {
  const { title, message, saveButtonText = 'Delete' } = useSelector(
    getModalData,
  );

  return (
    <BaseModal showSaveButton saveButtonText={saveButtonText} showCancelButton>
      <div className={styles.modalContainer}>
        <h2>{title}</h2>
        <p className={styles.description}>{message}</p>
      </div>
    </BaseModal>
  );
};

export default ConfirmModal;
