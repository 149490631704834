import React, { useMemo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { renderRoutes } from 'react-router-config';
import MainHeader from '#/components/MainHeader/MainHeader';
import PlayerModal from '#/components/PlayerModal/PlayerModal';
import redux from '#/redux/modules';
import { mainRoutes } from '#/utils/routes';
import {
  mainPageNames,
  customRoutes,
  mainScreenNames,
} from '#/config/constants';
import styles from './MainRoutesContainer.scss';
import LogoutRedirect from '#/components/LogoutRedirect/LogoutRedirect';
import { isWorkstation } from '#/utils/componentStyleConfig';

const {
  accedoOne: {
    selectors: { menuEntry, heroBannerEnabled },
  },
  brightcove: {
    actions: { clearContainerData },
  },
  firestore: {
    actions: { sendScreenView },
  },
  identity: {
    actions: { loadProfileCustomData },
  },
  onboarding: {
    actions: { actions: onboardingActions },
    selectors: { getCurrentProfileId },
  },
} = redux;

const MainFlowContainer = ({ route, location }) => {
  const dispatch = useDispatch();
  const menu = useSelector(menuEntry);
  const disableBackgroundPlayer = useSelector(heroBannerEnabled).enabled;
  const entries = useSelector(state => state.modules.accedoOne?.entries);
  const profileId = useSelector(getCurrentProfileId);
  const [prevSubpage, setPrevSubpage] = useState(null);
  const [prevMainpage, setPrevMainpage] = useState(null);

  const getContent = useMemo(() => {
    const content = {};
    menu?.menuItems?.forEach(item => {
      const menuItem = entries?.[item];
      const page = entries?.[menuItem.page];
      if (page) {
        content[page.template] = page;
      }
    });
    return content;
  }, [entries]);

  useEffect(() => {
    dispatch(
      loadProfileCustomData(profileId, customData =>
        onboardingActions.populateProfileCustomData({
          id: profileId,
          ...customData,
        }),
      ),
    );
  }, [location.pathname, profileId]);

  // Effect for clearing containers when changing between main routes
  useEffect(() => {
    const [, currentBase, mainPage, subPage] = location.pathname.split('/');

    // Clear containers on any main route change to save on memory (player needs data from main route page)
    if (currentBase === mainRoutes.ROOT) {
      dispatch(clearContainerData());
    }

    // SENSICAL-2793: don't send this screen name if route hasn't changed (clicking a profile in parentzone
    // was sending this again)
    // SENSICAL-2793: send show details/creator details screen call after determining type
    if (
      subPage !== 'showdetails' &&
      (prevSubpage !== subPage || prevMainpage !== mainPage)
    ) {
      dispatch(sendScreenView(mainScreenNames[subPage || mainPage]));
    }

    setPrevSubpage(subPage);
    setPrevMainpage(mainPage);
  }, [location]);

  return (
    <>
      <LogoutRedirect />
      <MainHeader
        menu={menu}
        hide={
          location.pathname.split('/')[2] === mainPageNames.LIVE ||
          location.pathname.indexOf(customRoutes.CREATOR_DETAILS) !== -1 ||
          location.pathname.indexOf(customRoutes.SHOW_DETAILS) !== -1
        }
      />

      {/* overriding flag to force load PlayerModal on TV platforms, 
          need to determine which flag should be used in control */}
      {!disableBackgroundPlayer || !isWorkstation() ? (
        <PlayerModal>
          {renderRoutes(route.routes, { content: getContent, entries })}
        </PlayerModal>
      ) : (
        <div id="MainRoutesContainer-container" className={styles.container}>
          {renderRoutes(route.routes, { content: getContent, entries })}
        </div>
      )}
    </>
  );
};

MainFlowContainer.propTypes = {
  route: PropTypes.object,
  location: PropTypes.object,
};

export default MainFlowContainer;
