import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import xdk from '@accedo/xdk-core';
import { useDispatch, useSelector } from 'react-redux';
import { FocusDiv } from '#/widgets/ui';
import styles from './PlayerCCButton.scss';
import CC from '#/static/images/icons/player-cc.svg';
import CCBlack from '#/static/images/icons/player-cc-black.svg';
import CCselected from '#/static/images/icons/player-cc-selected.svg';
import { getVideoCaptionEnabled } from '#/redux/modules/appPlayer/selectors';
import { ROLES } from '#/utils/accessibility';
import { actions } from '#/redux/modules/appPlayer';
import { isMobile } from '#/utils/componentStyleConfig';

const PlayerCCButton = ({ containerClassName, className, nav = {} }) => {
  const dispatch = useDispatch();
  const ccEnabled = useSelector(getVideoCaptionEnabled);
  const buttonIcon = isMobile() ? CCBlack : CC;

  const toggleSubtitle = useCallback(() => {
    !ccEnabled ? xdk.media.showSubtitle(0) : xdk.media.hideSubtitle();
    dispatch(actions.setVideoCaption({ videoCaptionEnabled: !ccEnabled }));
  }, [ccEnabled]);

  return (
    <FocusDiv
      className={cx(styles.container, containerClassName)}
      classNameFocused={styles.isFocused}
      onClick={toggleSubtitle}
      nav={nav}
      ariaLabel="Closed caption"
      role={ROLES.BUTTON}
    >
      <img
        className={cx(styles.playerClosedCaptionsButton, {
          [className]: Boolean(className),
        })}
        src={ccEnabled ? CCselected : buttonIcon}
        alt="CC"
        aria-hidden="true"
        role="presentation"
      />
    </FocusDiv>
  );
};

PlayerCCButton.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  nav: PropTypes.object,
};

export default PlayerCCButton;
