import { AES } from 'jscrypto/es6/AES';
import { SHA256 } from 'jscrypto/es6/SHA256';
import { Utf8 } from 'jscrypto/es6/Utf8';

export const generateKey = (len = 0) => {
  const hash = SHA256.hash(
    Math.random()
      .toString()
      .substr(2, 15),
  ).toString();

  return len ? hash.substr(0, len) : hash;
};

export const encrypt = (data, key) => {
  return AES.encrypt(data, key).toString();
};

export const decrypt = (data, key) => {
  return AES.decrypt(data, key).toString(Utf8);
};

export const encryptObject = (obj, key) => {
  return Object.entries(obj).reduce(
    (acc, [k, v]) => ({ ...acc, [k]: encrypt(v, key) }),
    {},
  );
};
