import React from 'react';
import PropTypes from 'prop-types';
import styles from './ModalContainer.scss';

const ModalContainer = ({ children }) => {
  return <div className={styles.modalContainer}>{children}</div>;
};

ModalContainer.propTypes = {
  children: PropTypes.node,
};

export default ModalContainer;
