import React from 'react';
import { withRouter } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Button, FocusDiv } from '#/widgets/ui';
import BaseModal from '../../common/BaseModal/BaseModal';

import { getContentBlocking } from '#/redux/modules/accedoOne/selectors';
import { getCurrentVideo } from '#/redux/modules/appPlayer/selectors';
import { getAssetData } from '#/redux/modules/brightcove/selectors';
import { sendBlockContent } from '#/redux/modules/firestore/actions';
import { actions } from '#/redux/modules/modal/actions';
import {
  updateVideosBlockedById,
  updateShowsBlockedById,
} from '#/redux/modules/identity/actions';

import styles from './ContentFilteringModal.scss';
import { isWorkstation } from '#/utils/componentStyleConfig';

const ContentFilteringModal = ({ history }) => {
  const dispatch = useDispatch();
  const contentBlocking = useSelector(getContentBlocking);
  const currentVideo = useSelector(state => getCurrentVideo(state));
  const videoInfo = useSelector(state => getAssetData(state));

  const {
    blockContentModal: {
      title,
      description,
      ctaBlockShowVideos,
      ctaBlockVideo,
      ctaCancel,
    },
  } = contentBlocking;

  const hideVideo = async () => {
    const videoData = {
      id: `${currentVideo.videoId}`,
      name: currentVideo.videoTitle,
      reference_id: currentVideo.videoReferenceId,
      thumbnail: currentVideo.thumbnail,
      custom_fields: {
        subtitle: currentVideo.videoShowTitle,
        episode_key_art_horizontal: currentVideo.episode_key_art_horizontal,
      },
      duration: videoInfo.duration,
    };
    const videoAnalyticsData = {
      video_id: `${currentVideo.videoId}`,
    };
    await dispatch(
      updateVideosBlockedById(currentVideo.videoId, true, videoData),
    );
    await dispatch(sendBlockContent(videoAnalyticsData));
    await dispatch(actions.closeModal());
    history.goBack();
    window?.location.reload();
  };

  const hideShow = async () => {
    const showData = {
      id: `${videoInfo.custom_fields.show_id}`,
      name: videoInfo.custom_fields.series_show_name,
      key_art_horizontal_image_path: videoInfo.thumbnail,
    };
    const showAnalyticsData = {
      show_id: `${videoInfo.custom_fields.show_id}`,
    };
    await dispatch(
      updateShowsBlockedById(
        `${videoInfo.custom_fields.show_id}`,
        true,
        showData,
      ),
    );
    await dispatch(sendBlockContent(showAnalyticsData));
    await dispatch(actions.closeModal());
    history.goBack();
    window?.location.reload();
  };

  return (
    <BaseModal
      showSaveButton={false}
      showCloseX={!!isWorkstation}
      showCancelButton
      cancelButtonText={ctaCancel}
      className={styles.baseContainer}
      saveButtonContainerClassName={styles.cancelButtonContainer}
      cancelButtonClassName={styles.cancelButton}
      cancelButtonDisabled={false}
    >
      <FocusDiv className={styles.modalContainer}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}>{description}</p>
        <Button onClick={hideVideo} className={styles.hideButton}>
          {ctaBlockVideo}
        </Button>
        <Button onClick={hideShow} className={styles.hideButton}>
          {ctaBlockShowVideos}
        </Button>
      </FocusDiv>
    </BaseModal>
  );
};

ContentFilteringModal.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ContentFilteringModal);
