import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './progressBar.scss';

const getProgress = (duration, progress) => {
  const currentProgress = (progress / duration) * 100;
  return `${currentProgress.toFixed()}%`;
};

const ProgressBar = ({ className = '', durationMs, progressMs }) => (
  <div className={classNames(styles.wrapper, className)}>
    <div className={styles.fullBar}>
      <div
        className={styles.completed}
        style={{
          width: getProgress(durationMs, progressMs),
        }}
      />
    </div>
  </div>
);

ProgressBar.propTypes = {
  className: PropTypes.string,
  durationMs: PropTypes.number.isRequired,
  progressMs: PropTypes.number.isRequired,
};

export default ProgressBar;
