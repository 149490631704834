import React from 'react';
import { useSelector } from 'react-redux';
import {
  getSensicalTvConfig,
  getGeoBlocking,
} from '#/redux/modules/accedoOne/selectors';
import styles from './GeoBlockGate.scss';

const imgColorRegex = /(Green)|(Black)/;

const GeoBlockGate = () => {
  const { logoUri = '' } = useSelector(getSensicalTvConfig) || {};
  const { geoBlockedErrorDescription = '', geoBlockedErrorTitle = '' } =
    useSelector(getGeoBlocking) || {};

  return (
    <div className={styles.geoblockGate}>
      <div className={styles.content}>
        <img
          className={styles.logo}
          src={logoUri?.replace(imgColorRegex, 'White')}
          alt="Logo"
        />
        <h1 className={styles.title}>{geoBlockedErrorTitle}</h1>
        <p className={styles.description}>{geoBlockedErrorDescription}</p>
      </div>
    </div>
  );
};

export default GeoBlockGate;
