import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import cx from 'classnames';
import { withFocus } from '@accedo/vdkweb-navigation';

import { actions as onboardingActions } from '#/redux/modules/onboarding/actions';
import { getDraftProfile } from '#/redux/modules/onboarding/selectors';
import { updateProfileBasic } from '#/redux/modules/identity/actions';
import { isOpen } from '#/redux/modules/modal/selectors';

import {
  profileCard,
  nameContainer,
  nameCenter,
  nameSpaced,
  editButtonContainer,
  hidden,
  profileLockedContainer,
  focused,
  tvCard,
} from './ProfileCard.scss';

import { Avatar, FocusDiv } from '#/widgets/ui';
import EditBlueIcon from '#/static/images/icons/edit-blue.svg';
import Lock from '#/static/images/icons/lock.svg';

import { isWorkstation } from '#/utils/componentStyleConfig';

const ProfileCard = ({
  className,
  profile,
  editMode,
  onSwitch,
  onEdit,
  locked,
  isFocused,
  nav,
}) => {
  const dispatch = useDispatch();
  const { id, name: originalName, avatar } = profile;
  const draftProfile = useSelector(getDraftProfile);
  const isModalOpen = useSelector(isOpen);
  const name =
    originalName?.length > 8
      ? `${originalName.substring(0, 8)}...`
      : originalName;

  // Avatar editing
  useEffect(() => {
    // Not in edit mode
    if (!editMode && isWorkstation()) {
      return;
    }
    // Not in edit mode for current profile
    if (!draftProfile || draftProfile.id !== id) {
      return;
    }
    // Not done editing yet
    if (isModalOpen) {
      return;
    }
    // Edit mode but avatar not changed
    if (draftProfile.avatar === avatar) {
      return;
    }
    dispatch(
      updateProfileBasic(
        id,
        { avatarUrl: draftProfile.avatar },
        onboardingActions.saveProfile,
        onboardingActions.discardProfile,
      ),
    );
  }, [profile, draftProfile, isModalOpen]);

  return (
    <FocusDiv
      nav={nav}
      className={cx(profileCard, className, {
        [focused]: isFocused,
        [tvCard]: !isWorkstation(),
      })}
      useNativeFocus={false}
    >
      <Link to="#" onClick={editMode ? onEdit : onSwitch} aria-hidden="true">
        <div className={cx(profileLockedContainer)}>
          <Avatar profile={profile} />
          <img
            className={cx({ [hidden]: !locked })}
            src={Lock}
            alt="Profile locked"
          />
        </div>
        <div
          className={cx(nameContainer, !editMode ? nameCenter : nameSpaced)}
          aria-hidden="true"
        >
          <h4>{name}</h4>
          <div className={editButtonContainer}>
            <img
              className={cx({ [hidden]: !editMode })}
              src={EditBlueIcon}
              alt="Edit avatar"
            />
          </div>
        </div>
      </Link>
    </FocusDiv>
  );
};
ProfileCard.propTypes = {
  className: PropTypes.string,
  profile: PropTypes.object,
  editMode: PropTypes.bool,
  onSwitch: PropTypes.func,
  onEdit: PropTypes.func,
  locked: PropTypes.bool,
  nav: PropTypes.object,
  isFocused: PropTypes.bool,
};

export default withFocus(ProfileCard);
