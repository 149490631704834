import { handleActions } from 'redux-actions';
import { actions } from './actions';

const initialState = {
  lastUpdate: Date.now(),
  sinkInFocus: true,
};

const toggleFocusSink = (state = initialState, { payload: sinkInFocus }) => {
  return {
    ...state,
    lastUpdate: Date.now(),
    sinkInFocus,
  };
};

const reducer = handleActions(
  {
    [actions.toggleFocusSink]: toggleFocusSink,
  },
  initialState,
);

export default reducer;
