import { createActions } from 'redux-actions';

import { createFetchAction } from '#/redux/modules/async/actions';
import {
  getApiPlaybackEndpoints,
  getPodcastsControls,
} from '#/redux/modules/accedoOne/selectors';
import { getCurrentVideo } from '#/redux/modules/appPlayer/selectors';
import { getRecentlyWatchedAssetsByReferenceIds } from '#/redux/modules/brightcove/selectors';

const SAVE_CONTAINER_DATA = 'SAVE_CONTAINER_DATA';
const CLEAR_CONTAINER_DATA = 'CLEAR_CONTAINER_DATA';

const SAVE_PLAYLIST_QUERY = 'SAVE_PLAYLIST_QUERY';
const SAVE_PLAYLIST_QUERY_VIDEO = 'SAVE_PLAYLIST_QUERY_VIDEO';

const SAVE_TOPIC_QUERY = 'SAVE_TOPIC_QUERY';
const SAVE_TOPIC_QUERY_VIDEO = 'SAVE_TOPIC_QUERY_VIDEO';

const SAVE_SEARCH_QUERY = 'SAVE_SEARCH_QUERY';
const SAVE_SEARCH_QUERY_VIDEO = 'SAVE_SEARCH_QUERY_VIDEO';

const CLEAR_PLAYLIST_QUERY = 'CLEAR_PLAYLIST_QUERY';
const CLEAR_PLAYLIST_DATA = 'CLEAR_PLAYLIST_DATA';

const CLEAR_TOPIC_QUERY = 'CLEAR_TOPIC_QUERY';
const CLEAR_TOPIC_DATA = 'CLEAR_TOPIC_DATA';

const CLEAR_SEARCH_DATA = 'CLEAR_SEARCH_DATA';
const CLEAR_SEARCH_QUERY = 'CLEAR_SEARCH_QUERY';

const SAVE_VIDEOS_COUNT = 'SAVE_VIDEOS_COUNT';
const SAVE_ASSET_DATA = 'SAVE_ASSET_DATA';
const SAVE_ASSETS_BY_REFERENCE = 'SAVE_ASSETS_BY_REFERENCE';
const SAVE_SPONSORED_VIDEOS = 'SAVE_SPONSORED_VIDEOS';
const SAVE_RECENTLY_WATCHED_ASSETS_BY_REFERENCE =
  'SAVE_RECENTLY_WATCHED_ASSETS_BY_REFERENCE';
const CLEAR_RECENTLY_WATCHED_ASSETS_BY_REFERENCE =
  'CLEAR_RECENTLY_WATCHED_ASSETS_BY_REFERENCE';
const CLEAR_ASSETS_BY_REFERENCE = 'CLEAR_ASSETS_BY_REFERENCE';
const CLEAR_ASSET_DATA = 'CLEAR_ASSET_DATA';
const SAVE_PLAYLIST_FETCH = 'SAVE_PLAYLIST_FETCH';
const CLEAR_PLAYLIST_FETCH = 'CLEAR_PLAYLIST_FETCH';
const SAVE_RECENTLY_WATCHED_QUERY = 'SAVE_RECENTLY_WATCHED_QUERY';
const CLEAR_RECENTLY_WATCHED_QUERY = 'CLEAR_RECENTLY_WATCHED_QUERY';
const SAVE_RECENTLY_WATCHED_QUERY_VIDEO = 'SAVE_RECENTLY_WATCHED_QUERY_VIDEO';
const SAVE_RECENTLY_WATCHED_DATA = 'SAVE_RECENTLY_WATCHED_DATA';
const CLEAR_RECENTLY_WATCHED_DATA = 'CLEAR_RECENTLY_WATCHED_DATA';

const actionOptions = {
  prefix: 'sensical/brightcove',
};

export const actions = createActions(
  {},
  SAVE_CONTAINER_DATA,
  CLEAR_CONTAINER_DATA,
  SAVE_PLAYLIST_QUERY,
  SAVE_PLAYLIST_QUERY_VIDEO,
  SAVE_SEARCH_QUERY_VIDEO,
  SAVE_SEARCH_QUERY,
  SAVE_VIDEOS_COUNT,
  SAVE_TOPIC_QUERY_VIDEO,
  SAVE_TOPIC_QUERY,
  CLEAR_SEARCH_DATA,
  CLEAR_SEARCH_QUERY,
  CLEAR_TOPIC_QUERY,
  CLEAR_TOPIC_DATA,
  CLEAR_PLAYLIST_QUERY,
  CLEAR_PLAYLIST_DATA,
  SAVE_ASSET_DATA,
  SAVE_ASSETS_BY_REFERENCE,
  SAVE_RECENTLY_WATCHED_QUERY,
  CLEAR_RECENTLY_WATCHED_QUERY,
  SAVE_SPONSORED_VIDEOS,
  SAVE_RECENTLY_WATCHED_QUERY_VIDEO,
  SAVE_RECENTLY_WATCHED_DATA,
  CLEAR_RECENTLY_WATCHED_DATA,
  SAVE_RECENTLY_WATCHED_ASSETS_BY_REFERENCE,
  CLEAR_RECENTLY_WATCHED_ASSETS_BY_REFERENCE,
  CLEAR_ASSETS_BY_REFERENCE,
  CLEAR_ASSET_DATA,
  SAVE_PLAYLIST_FETCH,
  CLEAR_PLAYLIST_FETCH,
  actionOptions,
);

const SEARCH_QUERY_PREFIX = '?q=%2B';
const SORT_BY_NAME = 'sort=name';
const SORT_BY_REFERENCE_ID = 'sort=reference_id';
const SORT_BY_CREATED_AT = 'sort=-created_at';

const createBrightcoveAction = (asyncId, endpointId, successAction) =>
  createFetchAction(asyncId, successAction, async (getState, data) => {
    const {
      url: host,
      endpoints,
      extraParams,
      headers,
    } = getApiPlaybackEndpoints(getState());
    const { path, method } = endpoints[endpointId];

    let requestUrlBase = host + path;
    requestUrlBase = requestUrlBase.replace(
      /\{\{([^}]+)\}\}/g,
      replaceString => {
        const term = replaceString.replace(/\{/g, '').replace(/\}/g, '');
        if (extraParams[term]) {
          return `${extraParams[term]}`;
        }
        return replaceString;
      },
    );

    const requestHeaders = {
      'Content-Type': 'application/json',
      ...headers,
    };

    return Promise.all(
      data.queries.map(query => {
        let queryUrl = requestUrlBase;
        const { isPodcast } = getCurrentVideo(getState());
        const { adsEnabled } = isPodcast
          ? getPodcastsControls(getState())
          : { adsEnabled: true };
        queryUrl = queryUrl.replace(/\{\{([^}]+)\}\}/g, replaceString => {
          const term = replaceString.replace(/\{/g, '').replace(/\}/g, '');
          if (query[term]) {
            return `${query[term]}`;
          }
          throw new Error(`Parameter ${term} mapped to is required`);
        });

        if (query.playlistId) {
          // if playlist
          queryUrl = `${requestUrlBase}/${query.playlistId}?limit=${
            query?.limit ? query.limit : 100
          }&offset=${query?.offset ? query.offset : 0}`;
        } else if (query.show_id && query.season_number) {
          // if show season
          queryUrl = `${requestUrlBase}${SEARCH_QUERY_PREFIX}season_number:${
            query.season_number
          }${encodeURIComponent(' +')}show_id:${
            query.show_id
          }${encodeURIComponent(' +')}tags:${
            query.age
          }&${SORT_BY_REFERENCE_ID}&limit=${query.limit}&offset=${
            query.offset
          }`;
        } else if (query.show_id && query.season_number === null) {
          queryUrl = `${requestUrlBase}${SEARCH_QUERY_PREFIX}show_id:${
            query.show_id
          }${encodeURIComponent(' +')}tags:${
            query.age
          }&${SORT_BY_CREATED_AT}&limit=${query.limit}&offset=${query.offset}`;
        } else if (query.topicId) {
          // if topic
          queryUrl = `${requestUrlBase}${SEARCH_QUERY_PREFIX}tags:${
            query.topicId
          }${encodeURIComponent(' +')}tags:${query.age}&${SORT_BY_NAME}&limit=${
            query.limit
          }&offset=${query.offset}`;
        } else if (query?.referenceIds) {
          // if by references (API breaks with leading %20 only for this for some reason)
          const queryParams =
            (query?.referenceIds?.length
              ? `q=reference_id:${query.referenceIds.join('%20reference_id:')}`
              : '') + (query?.limit ? `&limit=${query.limit}` : '');
          queryUrl += queryParams ? `?${queryParams}` : '';
        }
        if (query.adConfigId && adsEnabled) {
          if (!queryUrl.split('').find(s => s === '?')) {
            queryUrl += '?';
          }
          queryUrl += `ad_config_id=${query.adConfigId}`;
        }

        return fetch(queryUrl, {
          method,
          headers: requestHeaders,
        });
      }),
    )
      .then(
        responses => {
          return Promise.all(responses.map(resp => resp.json()));
        },
        error => ({ error }),
      )
      .then(responsesJson => {
        let result = {};
        responsesJson.forEach((resp, index) => {
          if (resp.error) {
            return { error: resp };
          }
          if (data.queries[index].containerId) {
            result[data.queries[index].containerId] = resp.videos;
          } else {
            result = resp;
          }
        });
        return result;
      });
  });

export const ASYNC_LOAD_CONTAINERS = 'ASYNC_LOAD_CONTAINERS';
export const queryBrightCovePlaylists = ({ queries }) => {
  return createBrightcoveAction(
    ASYNC_LOAD_CONTAINERS,
    'getPlaylist',
    actions.saveContainerData,
  )({
    queries,
  });
};

export const loadContainers = ({ queries }) => dispatch => {
  dispatch(queryBrightCovePlaylists({ queries }));
};

export const saveContainerData = payload => {
  return {
    type: `${actionOptions.prefix}/${SAVE_CONTAINER_DATA}`,
    payload,
  };
};

export const clearContainerData = payload => {
  return {
    type: `${actionOptions.prefix}/${CLEAR_CONTAINER_DATA}`,
    payload,
  };
};

export const clearTopicQuery = payload => {
  return {
    type: `${actionOptions.prefix}/${CLEAR_TOPIC_QUERY}`,
    payload,
  };
};

export const clearTopicData = payload => {
  return {
    type: `${actionOptions.prefix}/${CLEAR_TOPIC_DATA}`,
    payload,
  };
};

export const clearSearchQuery = payload => {
  return {
    type: `${actionOptions.prefix}/${CLEAR_SEARCH_QUERY}`,
    payload,
  };
};

export const clearSearchData = payload => {
  return {
    type: `${actionOptions.prefix}/${CLEAR_SEARCH_DATA}`,
    payload,
  };
};

export const clearPlaylistQuery = payload => {
  return {
    type: `${actionOptions.prefix}/${CLEAR_PLAYLIST_QUERY}`,
    payload,
  };
};

export const clearPlaylistData = payload => {
  return {
    type: `${actionOptions.prefix}/${CLEAR_PLAYLIST_DATA}`,
    payload,
  };
};

export const clearRecentlyWatchedQuery = payload => {
  return {
    type: `${actionOptions.prefix}/${CLEAR_RECENTLY_WATCHED_QUERY}`,
    payload,
  };
};

export const saveRecentlyWatchedData = payload => {
  return {
    type: `${actionOptions.prefix}/${SAVE_RECENTLY_WATCHED_DATA}`,
    payload,
  };
};

export const clearRecentlyWatchedData = payload => {
  return {
    type: `${actionOptions.prefix}/${CLEAR_RECENTLY_WATCHED_DATA}`,
    payload,
  };
};

export const ASYNC_LOAD_ASSET = 'ASYNC_LOAD_ASSET';

export const ASYNC_LOAD_ASSETS = 'ASYNC_LOAD_ASSETS_BY_REFERENCE_IDS';

export const stopLoadingRecentlyWatched = () => async dispatch => {
  await dispatch(
    actions.saveRecentlyWatchedData({
      hasMoreResults: false,
    }),
  );
  dispatch(actions.saveRecentlyWatchedQueryVideo({}));
};

export const ASYNC_LOAD_RECENTLY_WATCHED_ASSETS =
  'ASYNC_LOAD_RECENTLY_WATCHED_ASSETS_BY_REFERENCE_IDS';

export const loadRecentlyWatchedAssetsByReferenceIds = (
  containerId = null,
  queries,
  mergeData = false,
) => (dispatch, getState) => {
  dispatch(
    createBrightcoveAction(
      ASYNC_LOAD_RECENTLY_WATCHED_ASSETS,
      'search',
      async data => {
        if (data?.videos) {
          // Data from brightcove isn't sorted.
          // Sort here using the order of referenceIds
          const referenceIds = queries[0]?.referenceIds;
          const sortedData = data.videos.slice(0).sort((l, r) => {
            const lId = l.reference_id;
            const rId = r.reference_id;
            return referenceIds.indexOf(lId) - referenceIds.indexOf(rId);
          });

          if (mergeData) {
            data.videos = [
              ...(getRecentlyWatchedAssetsByReferenceIds(getState()) || []),
              ...sortedData,
            ];
            dispatch(
              actions.saveRecentlyWatchedData({
                hasMoreResults: true,
              }),
            );
          } else {
            data.videos = sortedData;
          }

          dispatch(actions.saveRecentlyWatchedAssetsByReference(data));
          if (containerId) {
            dispatch(
              actions.saveRecentlyWatchedQueryVideo({
                [containerId]: [...data.videos],
              }),
            );
          }
        } else {
          console.warn('[RecentlyWatched]: No video info fetched');
          dispatch(stopLoadingRecentlyWatched());
        }

        return {
          type: ASYNC_LOAD_RECENTLY_WATCHED_ASSETS,
        };
      },
    )({
      queries,
    }),
  );
};

export const loadAssetsByReferenceIds = queries => {
  return createBrightcoveAction(
    ASYNC_LOAD_ASSETS,
    'search',
    actions.saveAssetsByReference,
  )({
    queries,
  });
};

export const loadAsset = queries => {
  return createBrightcoveAction(
    ASYNC_LOAD_ASSET,
    'getAsset',
    actions.saveAssetData,
  )({
    queries,
  });
};

export const saveAssetData = payload => {
  return {
    type: `${actionOptions.prefix}/${SAVE_ASSET_DATA}`,
    payload,
  };
};

export const clearAssetData = payload => {
  return {
    type: `${actionOptions.prefix}/${CLEAR_ASSET_DATA}`,
    payload,
  };
};

export const saveTopicQuery = payload => {
  return {
    type: `${actionOptions.prefix}/${SAVE_TOPIC_QUERY}`,
    payload,
  };
};

export const ASYNC_PERFORM_TOPIC_QUERY = 'ASYNC_PERFORM_TOPIC_QUERY';
export const performTopicQuery = queries => {
  return createBrightcoveAction(
    ASYNC_PERFORM_TOPIC_QUERY,
    'search',
    actions.saveTopicQueryVideo,
  )({
    queries,
  });
};

export const saveTopicQueryVideo = payload => {
  return {
    type: `${actionOptions.prefix}/${SAVE_TOPIC_QUERY_VIDEO}`,
    payload,
  };
};

export const saveSearchQuery = payload => {
  return {
    type: `${actionOptions.prefix}/${SAVE_SEARCH_QUERY}`,
    payload,
  };
};

export const ASYNC_PERFORM_SEARCH = 'ASYNC_PERFORM_SEARCH';
export const performSearch = queries => {
  return createBrightcoveAction(
    ASYNC_PERFORM_SEARCH,
    'search',
    actions.saveSearchQueryVideo,
  )({
    queries,
  });
};

export const saveSearchQueryVideo = payload => {
  return {
    type: `${actionOptions.prefix}/${SAVE_SEARCH_QUERY_VIDEO}`,
    payload,
  };
};

export const savePlaylistQuery = payload => {
  return {
    type: `${actionOptions.prefix}/${SAVE_PLAYLIST_QUERY}`,
    payload,
  };
};

export const saveRecentlyWatchedQuery = payload => {
  return {
    type: `${actionOptions.prefix}/${SAVE_RECENTLY_WATCHED_QUERY}`,
    payload,
  };
};

export const ASYNC_PERFORM_PLAYLIST_FETCH = 'ASYNC_PERFORM_PLAYLIST_FETCH';
export const HANDLE_PLAYLIST_QUERY_VIDEO = 'HANDLE_PLAYLIST_QUERY_VIDEO';
export const performPlaylistFetch = queries => {
  return createBrightcoveAction(
    ASYNC_PERFORM_PLAYLIST_FETCH,
    'getPlaylist',
    actions.savePlaylistFetch,
  )({
    queries,
  });
};

export const ASYNC_PERFORM_PLAYLIST_QUERY = 'ASYNC_PERFORM_PLAYLIST_QUERY';
export const performPlaylistQuery = queries => {
  return createBrightcoveAction(
    ASYNC_PERFORM_PLAYLIST_QUERY,
    'getPlaylist',
    actions.savePlaylistQueryVideo,
  )({
    queries,
  });
};

export const savePlaylistQueryVideo = payload => {
  return {
    type: `${actionOptions.prefix}/${SAVE_PLAYLIST_QUERY_VIDEO}`,
    payload,
  };
};

export const ASYNC_GET_VIDEOS_COUNT = 'ASYNC_GET_VIDEOS_COUNT';
export const getVideosCount = queries => {
  return createBrightcoveAction(
    ASYNC_GET_VIDEOS_COUNT,
    'search',
    actions.saveVideosCount,
  )({
    queries,
  });
};

export const saveVideosCount = payload => {
  return {
    type: `${actionOptions.prefix}/${SAVE_VIDEOS_COUNT}`,
    payload,
  };
};
export const saveSponsoredVideos = payload => {
  return {
    type: `${actionOptions.prefix}/${SAVE_SPONSORED_VIDEOS}`,
    payload,
  };
};
