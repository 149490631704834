import { handleActions } from 'redux-actions';
import { actions } from './actions';

const initialState = {
  containers: {},
  asset: {},
  loading: null,
  assetsByReferenceId: null,
  sponsoredVideos: [],
  recentlyWatchedAssetsByReferenceId: null,
  playlistFetch: null,
  recentlyWatchedData: {
    hasMoreResults: null,
    containerId: null,
    count: null,
    totalPages: null,
    limit: 20,
  },
  recentlyWatchedQuery: {
    size: 0,
    page: 0,
    offset: 0,
    started: false,
  },
  playlistQuery: {
    id: null,
    playlistId: null,
    creator_id: null,
    page: 0,
    offset: 0,
  },
  playlistData: {
    containerId: null,
    hasMoreResults: null,
    limit: 100,
  },
  searchQuery: {
    age: null,
    creator_id: null,
    show_id: null,
    season_number: 1,
    page: 0,
    offset: 0,
    id: '',
  },
  searchData: {
    containerId: null,
    count: null,
    totalPages: null,
    limit: 100,
  },
  topicQuery: {
    age: null,
    id: '',
    offset: 0,
    page: 0,
    topicId: null,
    topicTitle: null,
  },
  topicData: {
    containerId: null,
    count: null,
    totalPages: null,
    limit: 100,
  },
};

const saveContainerDataReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    containers: {
      ...state.containers,
      ...payload,
    },
  };
};

const clearContainerDataReducer = (state = initialState) => {
  return {
    ...state,
    containers: {},
  };
};

const savePlaylistQueryReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    loading: true,
    playlistQuery: {
      ...state.playlistQuery,
      ...payload,
    },
  };
};

const clearPlaylistFetchReducer = (state = initialState) => ({
  ...state,
  playlistFetch: null,
});

const savePlaylistFetchReducer = (state = initialState, { payload }) => ({
  ...state,
  playlistFetch: payload,
});

const savePlaylistQueryVideoReducer = (state = initialState, { payload }) => {
  const playlistData = { ...state.playlistData };
  const playlistQuery = { ...state.playlistQuery };
  const containers = { ...state.containers };

  if (payload?.videos?.length === state.playlistData.limit) {
    playlistData.hasMoreResults = true;
  } else {
    playlistData.hasMoreResults = false;
  }

  if (!state.playlistData?.containerId) {
    playlistData.containerId = playlistQuery.id;
    containers[playlistData.containerId] = payload?.videos ?? [];
  } else {
    containers[playlistData.containerId] = [
      ...state.containers[playlistData.containerId],
      ...(payload?.videos ?? []),
    ];
  }

  return {
    ...state,
    loading: false,
    playlistData,
    containers,
  };
};

const saveSearchQueryReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    loading: true,
    searchQuery: payload,
  };
};

const saveSearchQueryVideoReducer = (state = initialState, { payload }) => {
  const searchData = { ...state.searchData, count: payload.count };
  const containers = { ...state.containers };
  if (!state.searchData?.containerId) {
    searchData.containerId = new Date().getTime();
    containers[searchData.containerId] = payload.videos;
  } else {
    containers[searchData.containerId] = [
      ...state.containers[searchData.containerId],
      ...payload.videos,
    ];
  }

  searchData.totalPages = Math.ceil(payload.count / searchData.limit);

  return {
    ...state,
    loading: false,
    searchData,
    containers,
  };
};

const saveTopicQueryReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    loading: true,
    topicQuery: payload,
  };
};

const saveTopicQueryVideoReducer = (state = initialState, { payload }) => {
  const topicData = { ...state.topicData, count: payload.count };
  const containers = { ...state.containers };

  if (!state.topicData?.containerId) {
    topicData.containerId = new Date().getTime();
    containers[topicData.containerId] = payload.videos;
  } else {
    containers[topicData.containerId] = [
      ...state.containers[topicData.containerId],
      ...payload.videos,
    ];
  }
  topicData.totalPages = Math.ceil(payload.count / topicData.limit);

  return {
    ...state,
    loading: false,
    topicData,
    containers,
  };
};

const clearTopicQueryReducer = (state = initialState) => {
  return {
    ...state,
    topicQuery: {
      age: null,
      id: '',
      offset: 0,
      page: 0,
      topicId: null,
      topicTitle: null,
    },
    loading: null,
  };
};

const clearTopicDataReducer = (state = initialState) => {
  return {
    ...state,
    topicData: {
      containerId: null,
      count: null,
      totalPages: null,
      limit: 100,
    },
  };
};

const clearSearchDataReducer = (state = initialState) => {
  return {
    ...state,
    searchData: {
      containerId: null,
      count: null,
      totalPages: null,
      limit: 100,
    },
  };
};

const clearSearchQueryReducer = (state = initialState) => {
  return {
    ...state,
    searchQuery: {
      age: null,
      creator_id: null,
      show_id: null,
      season_number: 1,
      page: 0,
      id: '',
    },
    loading: null,
  };
};

const clearPlaylistDataReducer = (state = initialState) => {
  return {
    ...state,
    playlistData: {
      containerId: null,
      hasMoreResults: null,
      limit: 100,
    },
  };
};

const clearPlaylistQueryReducer = (state = initialState) => {
  return {
    ...state,
    playlistQuery: {
      id: null,
      playlistId: null,
      creator_id: null,
      page: 0,
      offset: 0,
    },
    loading: null,
  };
};

const saveVideosCountReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    count: payload,
  };
};

const saveAssetDataReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    asset: payload,
  };
};

const saveSponsoredVideosReducer = (state = initialState, { payload }) => {
  const { sponsoredVideos, ...rest } = state || [];
  !sponsoredVideos?.includes(payload) && sponsoredVideos.splice(0, 0, payload);
  return {
    ...rest,
    sponsoredVideos: sponsoredVideos,
  };
};

const saveAssetsByReferenceReducer = (state = initialState, { payload }) => {
  const { assetsByReferenceId, ...rest } = state || {};
  return {
    ...rest,
    assetsByReferenceId: {
      ...(assetsByReferenceId || {}),
      ...payload,
    },
  };
};

const saveRecentlyWatchedAssetsByReferenceReducer = (
  state = initialState,
  { payload },
) => {
  return {
    ...state,
    recentlyWatchedAssetsByReferenceId: [...payload?.videos],
  };
};

const clearRecentlyWatchedAssetsByReferenceReducer = (state = initialState) => {
  return {
    ...state,
    recentlyWatchedAssetsByReferenceId: null,
  };
};

const clearAssetsByReferenceReducer = (state = initialState) => {
  return {
    ...state,
    assetsByReferenceId: null,
  };
};

const clearAssetDataReducer = (state = initialState) => {
  return {
    ...state,
    asset: {},
  };
};

const saveRecentlyWatchedQueryVideoReducer = (
  state = initialState,
  { payload },
) => {
  return {
    ...state,
    loading: false,
    containers: {
      ...state.containers,
      ...payload,
    },
  };
};

const saveRecentlyWatchedDataReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    loading: true,
    recentlyWatchedData: {
      ...state.recentlyWatchedData,
      ...payload,
    },
  };
};

const clearRecentlyWatchedDataReducer = (state = initialState) => {
  return {
    ...state,
    loading: null,
    recentlyWatchedData: {
      containerId: null,
      hasMoreResults: false,
      totalPages: null,
      limit: 20,
    },
  };
};

const saveRecentlyWatchedQueryReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    loading: true,
    recentlyWatchedQuery: {
      ...state.recentlyWatchedQuery,
      ...payload,
    },
  };
};

const clearRecentlyWatchedQueryReducer = (state = initialState) => {
  return {
    ...state,
    loading: null,
    recentlyWatchedQuery: {
      size: 0,
      page: 0,
      offset: 0,
      started: false,
    },
  };
};

const reducer = handleActions(
  {
    [actions.saveContainerData]: saveContainerDataReducer,
    [actions.clearContainerData]: clearContainerDataReducer,
    [actions.saveSearchQuery]: saveSearchQueryReducer,
    [actions.saveSearchQueryVideo]: saveSearchQueryVideoReducer,
    [actions.clearSearchData]: clearSearchDataReducer,
    [actions.clearSearchQuery]: clearSearchQueryReducer,
    [actions.saveTopicQuery]: saveTopicQueryReducer,
    [actions.saveTopicQueryVideo]: saveTopicQueryVideoReducer,
    [actions.clearTopicQuery]: clearTopicQueryReducer,
    [actions.clearTopicData]: clearTopicDataReducer,
    [actions.savePlaylistQuery]: savePlaylistQueryReducer,
    [actions.savePlaylistQueryVideo]: savePlaylistQueryVideoReducer,
    [actions.saveRecentlyWatchedQuery]: saveRecentlyWatchedQueryReducer,
    [actions.clearRecentlyWatchedQuery]: clearRecentlyWatchedQueryReducer,
    [actions.saveRecentlyWatchedData]: saveRecentlyWatchedDataReducer,
    [actions.clearRecentlyWatchedData]: clearRecentlyWatchedDataReducer,
    [actions.saveRecentlyWatchedQueryVideo]: saveRecentlyWatchedQueryVideoReducer,
    [actions.clearPlaylistData]: clearPlaylistDataReducer,
    [actions.clearPlaylistQuery]: clearPlaylistQueryReducer,
    [actions.saveVideosCount]: saveVideosCountReducer,
    [actions.saveAssetData]: saveAssetDataReducer,
    [actions.saveSponsoredVideos]: saveSponsoredVideosReducer,
    [actions.saveAssetsByReference]: saveAssetsByReferenceReducer,
    [actions.saveRecentlyWatchedAssetsByReference]: saveRecentlyWatchedAssetsByReferenceReducer,
    [actions.clearRecentlyWatchedAssetsByReference]: clearRecentlyWatchedAssetsByReferenceReducer,
    [actions.clearAssetsByReference]: clearAssetsByReferenceReducer,
    [actions.clearAssetData]: clearAssetDataReducer,
    [actions.savePlaylistFetch]: savePlaylistFetchReducer,
    [actions.clearPlaylistFetch]: clearPlaylistFetchReducer,
  },
  initialState,
);

export default reducer;
