import { createActions } from 'redux-actions';
import firestoreApi from '#/services/firestore/api';

import { getContainerShows, getContainerPodcasts, getHero } from './selectors';

import {
  getCurrentProfileMinAge,
  getTopShowsCollectionName,
} from '#/redux/modules/onboarding/selectors';

import {
  addProfileAnalyticsEvent,
  registrationCompleteAnalyticsEvent,
  blockContentAnalyticsEvent,
  unblockContentAnalyticsEvent,
  welcomeModalAnalyticsEvent,
  welcomeModalCTAClickedAnalyticsEvent,
  welcomeModalPlayIconClickedAnalyticsEvent,
  welcomeModalCancelClickedAnalyticsEvent,
} from '#/config/constants';

const SAVE_ALL_TOPICS = 'SAVE_ALL_TOPICS';
const SAVE_ALL_SHOWS = 'SAVE_ALL_SHOWS';
const SAVE_TOP_SHOWS = 'SAVE_TOP_SHOWS';
const SAVE_HERO_CONTENT = 'SAVE_HERO_CONTENT';
const CLEAR_HERO_CONTENT = 'CLEAR_HERO_CONTENT';
const CLEAR_CONTAINER_SHOWS = 'CLEAR_CONTAINER_SHOWS';
const SAVE_CONTAINER_SHOWS = 'SAVE_CONTAINER_SHOWS';
const SAVE_CONTAINER_PODCASTS = 'SAVE_CONTAINER_PODCASTS';
const SAVE_CONTENT = 'SAVE_CONTENT';
const CLEAR_CONTENT_DETAILS = 'CLEAR_CONTENT_DETAILS';
const SAVE_AVATARS = 'SAVE_AVATARS';
const SEND_SCREEN_VIEW = 'SEND_SCREEN_VIEW';
const SEND_ADD_PROFILE = 'SEND_ADD_PROFILE';
const SEND_REGISTRATION_COMPLETE = 'SEND_REGISTRATION_COMPLETE';
const SEND_BLOCKED_CONTENT = 'SEND_BLOCKED_CONTENT';
const SEND_UNBLOCKED_CONTENT = 'SEND_UNBLOCKED_CONTENT';
const SEND_WELCOME_MODAL_CTA_CLICKED = 'SEND_WELCOME_MODAL_CTA_CLICKED';
const SEND_WELCOME_MODAL_PLAY_ICON_CLICKED =
  'SEND_WELCOME_MODAL_PLAY_ICON_CLICKED';
const SEND_WELCOME_MODAL_CANCEL_CLICKED = 'SEND_WELCOME_MODAL_CANCEL_CLICKED';

const actionOptions = {
  prefix: 'sensical/firestore',
};

export const sendScreenView = screenName => async () => {
  if (!screenName || screenName === '') {
    return;
  }

  firestoreApi.sendScreenViewAnalytics(screenName);

  return {
    type: `${actionOptions.prefix}/${SEND_SCREEN_VIEW}`,
  };
};

export const sendAddProfile = async () => {
  firestoreApi.sendAnalytics(addProfileAnalyticsEvent);

  return {
    type: `${actionOptions.prefix}/${SEND_ADD_PROFILE}`,
  };
};

export const sendBlockContent = async data => {
  firestoreApi.sendAnalytics(blockContentAnalyticsEvent, {
    ...data,
  });

  return {
    type: `${actionOptions.prefix}/${SEND_BLOCKED_CONTENT}`,
  };
};

export const sendUnblockContent = async data => {
  firestoreApi.sendAnalytics(unblockContentAnalyticsEvent, {
    ...data,
  });

  return {
    type: `${actionOptions.prefix}/${SEND_UNBLOCKED_CONTENT}`,
  };
};

export const sendRegistrationComplete = async () => {
  firestoreApi.sendAnalytics(registrationCompleteAnalyticsEvent, {
    registered: true,
  });

  return {
    type: `${actionOptions.prefix}/${SEND_REGISTRATION_COMPLETE}`,
  };
};
export const sendGuestAgeGroupClicked = async (pageName, analyticName) => {
  firestoreApi.sendAnalytics(analyticName, {
    page_name: pageName,
  });

  return {
    type: `${actionOptions.prefix}/${SEND_SCREEN_VIEW}`,
  };
};
export const sendGuestAgeGroupLiveClicked = async (pageName, analyticName) => {
  firestoreApi.sendAnalytics(analyticName, {
    page_name: pageName,
  });

  return {
    type: `${actionOptions.prefix}/${SEND_SCREEN_VIEW}`,
  };
};

export const sendWelcomeModal = async pageName => {
  firestoreApi.sendAnalytics(welcomeModalAnalyticsEvent, {
    page_name: pageName,
  });

  return {
    type: `${actionOptions.prefix}/${SEND_SCREEN_VIEW}`,
  };
};

export const sendWelcomeModalCTAClicked = async (pageName, contentTitle) => {
  firestoreApi.sendAnalytics(welcomeModalCTAClickedAnalyticsEvent, {
    page_name: pageName,
    content_title: contentTitle,
  });

  return {
    type: `${actionOptions.prefix}/${SEND_WELCOME_MODAL_CTA_CLICKED}`,
  };
};

export const sendWelcomeModalPlayIconClicked = async (
  pageName,
  contentTitle,
) => {
  firestoreApi.sendAnalytics(welcomeModalPlayIconClickedAnalyticsEvent, {
    page_name: pageName,
    content_title: contentTitle,
  });

  return {
    type: `${actionOptions.prefix}/${SEND_WELCOME_MODAL_PLAY_ICON_CLICKED}`,
  };
};

export const sendWelcomeModalCancelClicked = async (pageName, contentTitle) => {
  firestoreApi.sendAnalytics(welcomeModalCancelClickedAnalyticsEvent, {
    page_name: pageName,
    content_title: contentTitle,
  });

  return {
    type: `${actionOptions.prefix}/${SEND_WELCOME_MODAL_CANCEL_CLICKED}`,
  };
};

export const actions = {
  ...createActions(
    {},
    SAVE_ALL_TOPICS,
    SAVE_ALL_SHOWS,
    SAVE_TOP_SHOWS,
    SAVE_HERO_CONTENT,
    CLEAR_HERO_CONTENT,
    CLEAR_CONTAINER_SHOWS,
    SAVE_CONTAINER_SHOWS,
    SAVE_CONTAINER_PODCASTS,
    SAVE_CONTENT,
    CLEAR_CONTENT_DETAILS,
    SAVE_AVATARS,
    actionOptions,
  ),
  sendScreenView,
};

export const {
  saveAllTopics,
  saveAllShows,
  saveTopShows,
  saveHeroContent,
  clearHeroContent,
  saveContainerShows,
  clearContainerShows,
  saveContainerPodcasts,
  saveContent,
  clearContentDetails,
  saveAvatars,
} = actions;

export const loadAllTopics = () => async (dispatch, getState) => {
  try {
    const age = getCurrentProfileMinAge(getState());
    const res = await firestoreApi.getAvailableTopics(age);
    res.sort((a, b) => a.name.localeCompare(b.name));
    dispatch(saveAllTopics(res));
  } catch (error) {
    // do logic to a possible redispatch
  }
};

export const loadAllShows = () => async (dispatch, getState) => {
  try {
    const age = getCurrentProfileMinAge(getState());
    const res = await firestoreApi.getAvailableShows(age);
    dispatch(saveAllShows(res));
  } catch (error) {
    // do logic to a possible redispatch
  }
};

export const loadContainerShows = collectionName => async (
  dispatch,
  getState,
) => {
  const isPodcast = collectionName?.includes('podcast');
  const age = isPodcast ? getCurrentProfileMinAge(getState()) : null;
  const position = isPodcast ? 'position' : null;
  const getContainer = isPodcast ? getContainerPodcasts : getContainerShows;
  const saveContainer = isPodcast ? saveContainerPodcasts : saveContainerShows;
  const container = getContainer(getState());

  if (container.collection === collectionName && !isPodcast) {
    return; // data already stored
  }

  try {
    const res = await firestoreApi.getAllByCollection(
      collectionName,
      position,
      age,
    );
    dispatch(saveContainer({ data: res, collectionName }));
  } catch (error) {
    // TODO: logic to a possible redispatch
  }
};

export const loadTopShows = () => async (dispatch, getState) => {
  try {
    const collectionName = getTopShowsCollectionName(getState());
    const topShows = await firestoreApi.getAllByCollection(collectionName);
    dispatch(saveTopShows(topShows));
  } catch (e) {
    // Need to clear stale data if API call fails
    dispatch(saveTopShows([]));
  }
};

export const loadHeroContent = collectionName => async (dispatch, getState) => {
  const hero = getHero(getState());
  if (hero.collection === collectionName) {
    return;
  } // already loaded this hero information

  try {
    const res = await firestoreApi.getAllByCollection(
      collectionName,
      'position',
    );
    dispatch(saveHeroContent({ data: res, collectionName }));
  } catch (error) {
    // do logic to a possible redispatch
  }
};

export const getContentById = (contentId, collectionName) => async dispatch => {
  try {
    const res = await firestoreApi.getContentById(contentId, collectionName);
    if (res.length === 0 && collectionName?.includes('shows')) {
      const resp = await firestoreApi.getContentById(
        contentId,
        'podcast-shows',
      );
      dispatch(saveContent(resp));
    } else {
      dispatch(saveContent(res));
    }
  } catch (error) {
    // do logic to a possible redispatch
  }
};

export const getContentByIdDeeplink = (
  contentId,
  collectionName,
) => async () => {
  try {
    const res = await firestoreApi.getContentById(contentId, collectionName);
    if (res.length === 0 && collectionName?.includes('shows')) {
      const resp = await firestoreApi.getContentById(
        contentId,
        'podcast-shows',
      );
      return resp?.[0] || null;
    }
    return res?.[0] || null;
  } catch (error) {
    // do logic to a possible redispatch
  }
};

export const getTopicById = topicId => async () => {
  try {
    const res = await firestoreApi.getTopic(topicId);
    if (!res || !res.length) {
      return null;
    }
    return res?.[0];
  } catch (error) {
    // do logic to a possible redispatch
  }
};

export const loadAvatars = async dispatch => {
  const response = await firestoreApi.getAvatars();
  dispatch(saveAvatars(response));
};
