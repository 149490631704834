import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { pageRedux } from '@accedo/vdkweb-tv-ui';
import classNames from 'classnames';
import { focusManager } from '@accedo/vdkweb-navigation';
import { FocusDiv, ProfileImage } from '#/widgets/ui';
import PinGateRedirect, {
  enterPin,
} from '#/components/PinGateRedirect/PinGateRedirect';
import routes, { mainRoutes, authFlowRoutes } from '#/utils/routes';

import { getCurrentAgeMetadata } from '#/redux/modules/accedoOne/selectors';
import {
  actions as identityActions,
  logicalSwitchToChildProfile,
  logout,
} from '#/redux/modules/identity/actions';
import { actions as onboardingActions } from '#/redux/modules/onboarding/actions';
import {
  getParentMode,
  getParentUserId,
} from '#/redux/modules/identity/selectors';
import {
  getCurrentProfile,
  getCurrentProfileId,
  getCurrentProfileDeepLink,
} from '#/redux/modules/onboarding/selectors';

import styles from './mainHeader.scss';
import MenuIcon from './MenuIcon/MenuIcon';
import TVMenuIcon from './TVMenuIcon/TVMenuIcon';

import ParentZone from '#/static/images/icons/menu-parent-zone.png';
import ParentZoneSelected from '#/static/images/icons/menu-parent-zone-selected.png';

import MenuLogoTv from '#/static/images/logos/menu-logo-tv.svg';
import { HEADER, HOME, MOBILEBOTTOMTABBAR } from '#/utils/navigationHelper';
import { isWorkstation, isTouchDevice } from '#/utils/componentStyleConfig';
import { getIsHeaderActive } from '#/redux/modules/menu/selectors';

import { isGuestMode } from '#/redux/modules/user/selectors';
import { logout as GuestLogout } from '#/redux/modules/identity/identityGuest/actions';
import { ageMetadata } from '#/components/AuthFlow/GuestProfile/GuestProfile';
import { AgeCircle } from '#/widgets/ui/AgeCircle/AgeCircle';
import { actions as modalActions } from '#/redux/modules/modal/actions';
import { signupStates, GUEST_MODAL_TYPE } from '#/config/constants';
import BottomTabBar from '#/components/BottomTabBar/BottomTabBar';
import {
  // deeplinkOutsidePlayer,
  // deepLinkQuit,
  exitDeeplinkWorkflow,
} from '#/redux/modules/lifecycle/actions';
import {
  getDeeplinkOutsidePlayer,
  getDeepLinkStarted,
} from '#/redux/modules/lifecycle/selectors';

const ROUTE_HOME = '/main/home';

const {
  CONTAINER,
  MENU,
  PROFILE_BUTTON,
  MENU_ITEMS,
  PARENT_ZONE_BUTTON,
} = HEADER;

const navMap = {
  CONTAINER: {
    id: CONTAINER,
    forwardFocus: MENU,
  },
  PROFILE_BUTTON: {
    id: PROFILE_BUTTON,
    parent: CONTAINER,
  },
  MENU: {
    id: MENU,
    parent: CONTAINER,
    useLastFocus: true,
  },
  PARENT_ZONE_BUTTON: {
    id: PARENT_ZONE_BUTTON,
    parent: CONTAINER,
  },
};

function PlayerHeader({ menu, hide, history }) {
  const dispatch = useDispatch();
  const isHeaderActive = useSelector(getIsHeaderActive);
  const selectedAgeMetadata = useSelector(state =>
    getCurrentAgeMetadata(state),
  );
  const parentMode = useSelector(getParentMode);
  const { name, id: targetProfileId, avatar } =
    useSelector(getCurrentProfile) || {};
  const [pinGateRedirectOptions, setPinGateRedirectOptions] = useState({});
  const { pathname } = history?.location;
  const menuItems = Object.values(MENU_ITEMS);
  const getMenuNavId = index => menuItems[index];
  const guestMode = useSelector(isGuestMode);
  const deepLinkStarted = useSelector(getDeepLinkStarted);
  const deeplinkFromOutside = useSelector(getDeeplinkOutsidePlayer);
  const deeplinkedProfile = useSelector(getCurrentProfileDeepLink);
  const profileId = useSelector(getCurrentProfileId);
  const parentUserId = useSelector(getParentUserId);

  const onSwitchToGuestProfileSelection = useCallback(() => {
    dispatch(
      onboardingActions.setSignupState({
        signupState: signupStates.GUEST_WELCOME,
      }),
    );
    dispatch(GuestLogout(authFlowRoutes.GUEST_PROFILE));
    dispatch(exitDeeplinkWorkflow());
  });
  const onSwitchToProfileSelection = useCallback(() => {
    if (parentMode) {
      dispatch(identityActions.setPin(null));
    }
    dispatch(identityActions.setParentMode(true));
    dispatch(onboardingActions.setSelectedProfile(null));
    dispatch(exitDeeplinkWorkflow());
    history.replace('/');
    history.push(routes.PROFILE_SELECTION);
  }, [parentMode]);

  const onSwitchToParentZone = useCallback(() => {
    if (
      profileId === parentUserId &&
      (deepLinkStarted || deeplinkFromOutside || deeplinkedProfile)
    ) {
      dispatch(exitDeeplinkWorkflow());
      dispatch(identityActions.setParentMode(true));
      dispatch(onboardingActions.setSelectedProfile(null));
      history.replace('/');
      history.goBack();
      return;
    }
    if (guestMode) {
      dispatch(
        modalActions.openModal({
          modalId: 'GuestProfileModal',
          modalData: {
            modalType: GUEST_MODAL_TYPE.parentZoneBlocked,
          },
          successAction: () => {
            dispatch(
              onboardingActions.setSignupState({
                signupState: signupStates.CREATE_ACCOUNT,
              }),
            );
            dispatch(GuestLogout(authFlowRoutes.ACCOUNT_CREATION));
          },
        }),
      );
    } else if (isTouchDevice()) {
      dispatch(
        modalActions.openModal({
          modalId: 'MobileParentZone',
          successAction: () => {
            dispatch(logout());
            dispatch(modalActions.closeModal());
          },
        }),
      );
    } else {
      setPinGateRedirectOptions({
        to: mainRoutes.PARENT_ZONE,
        unsetProfile: false,
        unsetPin: false,
      });
      if (!parentMode) {
        enterPin(dispatch);
      }
    }
  }, [parentMode, guestMode]);

  const onNavigateToChildRoute = useCallback(
    async route => {
      if (deepLinkStarted || deeplinkFromOutside || deeplinkedProfile) {
        dispatch(exitDeeplinkWorkflow());
        history.replace('/');
        if (profileId === parentUserId) {
          dispatch(identityActions.setParentMode(true));
          dispatch(onboardingActions.setSelectedProfile(null));
          history.goBack();
          route.includes('livetv') && history.goBack();
          return;
        }
        if (parentMode) {
          await dispatch(logicalSwitchToChildProfile(targetProfileId));
        }
      }
      route && history.push(route);
    },
    [
      parentMode,
      targetProfileId,
      deepLinkStarted,
      deeplinkFromOutside,
      deeplinkedProfile,
    ],
  );

  const TopMenu = useMemo(() => {
    const { color, ageRange } = ageMetadata[
      `${selectedAgeMetadata?.ageGroupValue}`
    ];
    return (
      <>
        {/* Desktop version. IDs used to show/hide via media queries. */}
        <div
          id={styles.desktopAppLogoHidden}
          className={styles.appLogoContainer}
        >
          <img src={selectedAgeMetadata?.logoUri} alt="SENSICAL" />
        </div>
        <div
          id={styles.desktopMenuIconsHidden}
          className={styles.iconContainer}
        >
          {menu?.menuItems.map(item => {
            return (
              <MenuIcon
                onNavigate={onNavigateToChildRoute}
                entryId={item}
                key={item}
              />
            );
          })}
        </div>
        <div
          id={styles.desktopAvatarContainerHidden}
          className={styles.avatarContainer}
        >
          <span onClick={onSwitchToParentZone}>
            <img
              className={styles.settingsIcon}
              src={parentMode ? ParentZoneSelected : ParentZone}
              alt="Settings"
            />
          </span>
          {guestMode ? (
            <span
              className={styles.profileImage}
              onClick={onSwitchToGuestProfileSelection}
            >
              <AgeCircle
                ageRange={ageRange}
                color={color}
                styleClasses={{
                  containerClass: classNames(styles.circleContainer),
                  borderClass: classNames(styles.circleBorder),
                  circleClass: classNames(styles.circle),
                  whiteBorderClass: classNames(styles.whiteCircleBorder),
                }}
              />
            </span>
          ) : (
            <ProfileImage src={avatar} onClick={onSwitchToProfileSelection} />
          )}
        </div>

        {/* Mobile version. IDs used to show/hide via media queries. */}
        <div id={styles.mobileParentZoneIconHidden}>
          <span onClick={onSwitchToParentZone}>
            <img
              className={styles.settingsIcon}
              src={parentMode ? ParentZoneSelected : ParentZone}
              alt="Settings"
            />
          </span>
        </div>
        <div
          id={styles.mobileAppLogoHidden}
          className={styles.appLogoContainer}
        >
          <img src={selectedAgeMetadata?.logoUri} alt="SENSICAL" />
        </div>
        <div
          id={styles.mobileProfileIconHidden}
          className={styles.avatarContainer}
        >
          {guestMode ? (
            <span
              className={styles.profileImage}
              onClick={onSwitchToGuestProfileSelection}
            >
              <AgeCircle
                ageRange={ageRange}
                color={color}
                styleClasses={{
                  containerClass: classNames(styles.circleContainer),
                  borderClass: classNames(styles.circleBorder),
                  circleClass: classNames(styles.circle),
                  whiteBorderClass: classNames(styles.whiteCircleBorder),
                }}
              />
            </span>
          ) : (
            <ProfileImage src={avatar} onClick={onSwitchToProfileSelection} />
          )}
        </div>
      </>
    );
  }, [menu, name, avatar, parentMode]);

  const generateMenuNav = useCallback(() => {
    const navIds = menuItems.map((value, i) => {
      return {
        parent: navMap.CONTAINER.id,
        id: getMenuNavId(i),
        ...(i !== 0
          ? { nextup: getMenuNavId(i - 1) }
          : { nextup: PROFILE_BUTTON }),
        ...(i !== menuItems.length - 1
          ? { nextdown: getMenuNavId(i + 1) }
          : { nextdown: PARENT_ZONE_BUTTON }),
      };
    });
    navMap.MENU.forwardFocus = getMenuNavId(0);
    return navIds;
  }, []);

  const tvMenu = useMemo(() => {
    const { color, ageRange } = ageMetadata[
      `${selectedAgeMetadata?.ageGroupValue}`
    ];
    return (
      <>
        <FocusDiv nav={navMap.MENU} className={styles.iconContainer}>
          {guestMode ? (
            <FocusDiv
              nav={{ ...navMap.PROFILE_BUTTON, nextdown: getMenuNavId(0) }}
              classNameFocused={styles.tvFocused}
              onClick={onSwitchToGuestProfileSelection}
            >
              <AgeCircle
                ageRange={ageRange}
                color={color}
                styleClasses={{
                  containerClass: classNames(styles.circleContainer),
                  borderClass: classNames(styles.circleBorder),
                  circleClass: classNames(styles.circle),
                }}
              />
            </FocusDiv>
          ) : (
            <Link
              onClick={
                guestMode
                  ? onSwitchToGuestProfileSelection
                  : onSwitchToProfileSelection
              }
              to="#"
            >
              <TVMenuIcon
                src={avatar}
                nav={{
                  ...navMap.PROFILE_BUTTON,
                  nextdown: getMenuNavId(0),
                }}
                name={name}
                ariaLabel="Profile"
              />
            </Link>
          )}
          {menu?.menuItems.map((item, index) => {
            return (
              <MenuIcon
                isTv={!isWorkstation()}
                nav={generateMenuNav()[index]}
                onNavigate={onNavigateToChildRoute}
                entryId={item}
                key={item}
              />
            );
          })}
          <TVMenuIcon
            nav={{
              ...navMap.PARENT_ZONE_BUTTON,
              nextup: getMenuNavId(menuItems.length - 1),
            }}
            name="PARENTZONE"
            selected={parentMode}
            onClick={onSwitchToParentZone}
            ariaLabel="Parent Zone"
          />
        </FocusDiv>

        <div className={styles.appLogoContainer}>
          <img src={MenuLogoTv} alt="SENSICAL" />
        </div>
      </>
    );
  }, [menu, name, avatar, parentMode]);

  useEffect(() => {
    if (!isWorkstation()) {
      navMap.CONTAINER.internal = {
        nextright: () =>
          pathname === ROUTE_HOME
            ? focusManager.changeFocus(HOME.PAGE)
            : dispatch(pageRedux.actions.pageFocusCurrent()),
      };
    }
  }, [pathname]);

  // TODO: Refactor child redirect to sepearate component
  return (
    <>
      <div id={CONTAINER}>
        <FocusDiv
          nav={navMap.CONTAINER}
          className={classNames(styles.container, {
            [styles.tv]: !isWorkstation(),
          })}
          style={{
            backgroundColor: selectedAgeMetadata?.colorScheme?.toolbarPrimary,
            visibility: hide || !isHeaderActive ? 'hidden' : 'visible',
            opacity: hide || !isHeaderActive ? 0 : 1,
          }}
        >
          <PinGateRedirect {...pinGateRedirectOptions} />
          {isWorkstation() ? TopMenu : tvMenu}
        </FocusDiv>
      </div>

      <div
        id={MOBILEBOTTOMTABBAR.CONTAINER}
        style={{
          backgroundColor: selectedAgeMetadata?.colorScheme?.toolbarPrimary,
          visibility: hide || !isHeaderActive ? 'hidden' : 'visible',
          opacity: hide || !isHeaderActive ? 0 : 1,
        }}
      >
        {isWorkstation() ? <BottomTabBar /> : tvMenu}
      </div>
    </>
  );
}

PlayerHeader.propTypes = {
  menu: PropTypes.object,
  hide: PropTypes.bool,
  history: PropTypes.any,
};

export default withRouter(PlayerHeader);
