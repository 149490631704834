import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { getStyles, getClientRect } from '#/utils/getElementStyle';
import usePrevious from '#/hooks/usePrevious';
import redux from '#/redux/modules';

import styles from './scroll.scss';

const SCROLL_CONTAINER = 'SCROLL_CONTAINER';
const {
  scroll: {
    actions: { setScrolled, scrollTrigger },
  },
} = redux;

const SAFE_MARGIN = 0;

const Scroll = ({
  enabled = false,
  focusedElement,
  focusScrollPush = 0,
  children,
  hasBackgroundVideo = false,
  headerHeightOverride = -1,
}) => {
  const dispatch = useDispatch();
  const [yAxis, setYAxis] = useState(0);
  const prevFocusedId = usePrevious(
    focusedElement ? focusedElement.getAttribute('id') : '',
  );
  const scrollRef = useRef(null);
  const viewPortRef = useRef(null);

  function headerHeight() {
    if (headerHeightOverride > 0) {
      return headerHeightOverride;
    }
    return hasBackgroundVideo
      ? window.innerHeight -
          document.getElementById(SCROLL_CONTAINER).getBoundingClientRect().top
      : 0;
  }

  function getMinScroll() {
    return 0;
  }

  /**
   * Retrieves the maximum scroll using the wrapper's height and its margin / padding
   * @returns {number} The maximum value for translateY to reach
   */
  const getMaxScroll = useCallback(() => {
    if (!scrollRef.current || !viewPortRef.current) {
      return 0;
    }

    const visibleHeight = window.innerHeight;
    const {
      height: fullHeight,
      marginBottom: scrollMarginBottom,
      paddingBottom: scrollPaddingBottom,
    } = getClientRect(scrollRef.current, true);
    const { top: containerTop } = getClientRect(viewPortRef.current);
    const { marginBottom: elementMarginBottom } = getStyles(focusedElement);
    const heightDifference = fullHeight - visibleHeight;
    return (
      heightDifference +
      containerTop +
      SAFE_MARGIN -
      (scrollMarginBottom + scrollPaddingBottom) +
      elementMarginBottom
    );
  }, [focusedElement]);

  /**
   * Returns the new Y axis of the scroll down
   * The new axis will be considering the next element, ending up at the top of the screen
   * @returns {number} of new Y axis
   */
  // eslint-disable-next-line no-unused-vars
  function getScrollDownAllAxis() {
    const { top: elementTop, marginTop: elementMarginTop } = getClientRect(
      focusedElement,
      true,
    );

    return Math.ceil(
      yAxis - elementTop + elementMarginTop + headerHeight() + focusScrollPush,
    );
  }

  /**
   * Returns the new Y axis of the scroll down
   * The new axis will be considering the next element, ending up at the bottom of the screen
   * @returns {number} of new Y axis
   */
  function getScrollDownJustAxis() {
    const {
      top: elementTop,
      marginBottom: elementMarginBottom,
      height: elementHeight,
    } = getClientRect(focusedElement, true);

    return Math.ceil(
      yAxis -
        (elementTop -
          window.innerHeight +
          elementHeight +
          elementMarginBottom +
          focusScrollPush),
    );
  }

  /**
   * Sets the new Y axis for a scroll up
   * Based on the current Y axis, we rest it to the focus component's  top position
   * If there's a header visible, we compensate that height
   * @returns {void}
   */
  function scrollUp() {
    const currentElement = focusedElement;
    const minScroll = getMinScroll();
    const { top: elementTop, marginTop: elementMarginTop } = getClientRect(
      currentElement,
      true,
    );

    const newYAxis = Math.ceil(
      yAxis - elementTop + elementMarginTop + headerHeight() + focusScrollPush,
    );

    setYAxis(newYAxis < minScroll ? newYAxis : 0);
  }

  /**
   * Sets the new Y axis for a scroll down
   * The new axis is calculated using the focus element's top minus the scroll wrapper's top
   * It also validates that the new axis does not supass the maximum scroll
   * @returns {void}
   */
  function scrollDown() {
    const maxScroll = getMaxScroll();
    const newYAxis = getScrollDownJustAxis();
    setYAxis(-newYAxis > maxScroll ? -maxScroll : newYAxis);
  }

  useEffect(() => {
    if (!focusedElement || !enabled) {
      return;
    }
    const id = focusedElement?.getAttribute('id');
    if (id && id !== prevFocusedId) {
      /** Element top and bottom margins are not considered */
      const {
        top: elementTop,
        bottom: elementBottom,
        marginTop,
        marginBottom,
      } = getClientRect(focusedElement, true);
      if (elementTop + marginTop < headerHeight()) {
        dispatch(scrollTrigger('up'));
        scrollUp();
      } else if (
        elementBottom + marginBottom >
        window.innerHeight - SAFE_MARGIN
      ) {
        dispatch(scrollTrigger('down'));
        scrollDown();
      }
    }
  }, [focusedElement]);

  useEffect(() => {
    dispatch(setScrolled(yAxis < 0));
  }, [yAxis]);

  return (
    <div
      ref={viewPortRef}
      className={classNames({
        [styles.scrollContainer]: enabled,
        [styles.fixedContainer]: hasBackgroundVideo,
      })}
      id={SCROLL_CONTAINER}
    >
      <div
        ref={scrollRef}
        className={styles.scroll}
        style={{ transform: `translateY(${yAxis}px)` }}
      >
        {children}
      </div>
    </div>
  );
};

Scroll.propTypes = {
  enabled: PropTypes.bool,
  children: PropTypes.node,
  focusedElement: PropTypes.object,
  focusScrollPush: PropTypes.number,
  hasBackgroundVideo: PropTypes.bool,
  headerHeightOverride: PropTypes.number,
};

export default Scroll;
