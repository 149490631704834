import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { TabView } from '#/components';

import {
  getCurrentProfile,
  getMinAge,
  getProfiles,
} from '#/redux/modules/onboarding/selectors';
import { loadSensicalBenefits } from '#/redux/modules/accedoOne/actions';
import {
  getAgeMetadata,
  getBenefitsUrl,
  getSensicalBenefits,
  getLearningReportsConfig,
} from '#/redux/modules/accedoOne/selectors';
import { getLoginLoadingSpinnerState } from '#/redux/modules/spinner/selectors';

import { getAssetsByReferenceIds } from '#/redux/modules/brightcove/selectors';
import { loadAssetsByReferenceIds } from '#/redux/modules/brightcove/actions';
import { toggleLoginSpinner } from '#/redux/modules/spinner/actions';

import {
  pageTitle,
  emptyReportPlaceholder,
  generalBenefitsTitle,
  generalBenefitsDescription,
  noActivityTitle,
  noActivityDescription,
  activityDescription,
  pageContainer,
  tabView,
  learnedText,
  hidden,
} from './learningReports.scss';
import ProfileSwitcher from './ProfileSwitcher/ProfileSwitcher';
import TimePeriodSwitcher from './TimePeriodSwitcher/TimePeriodSwitcher';
// import TotalTimeView from './TotalTimeView/TotalTimeView';
import LearningItem from './LearningItem/LearningItem';

import { timePeriodFilters } from './helpers/constants';

const PAGE_TITLE = 'What are they watching?';

const tabSchema = [
  { label: 'Benefits', id: 'benefits' },
  { label: 'Channels', id: 'topics' },
];

const getLearnedText = (name, label) => `what ${name} learned this ${label}`;

const initialTabId = tabSchema[0].id;
const initialTimeSpan = Object.keys(timePeriodFilters)[0];

export const LearningReports = () => {
  const [currentTabId, setCurrentTabId] = useState(initialTabId);
  const currentProfile = useSelector(getCurrentProfile) || {};
  const [selectedProfileId, setSelectedProfileId] = useState(
    currentProfile?.id,
  );

  const [currentTimeSpan, setCurrentTimeSpan] = useState(initialTimeSpan);

  const prevTab = useRef(initialTabId);
  const prevProfileId = useRef(currentProfile?.id);
  const prevTimeSpan = useRef(initialTimeSpan);

  const profiles = useSelector(getProfiles) || [];
  const loadingSpinnerState = useSelector(getLoginLoadingSpinnerState);
  const dispatch = useDispatch();

  const profileMap = useMemo(() => {
    if (!profiles?.length) {
      return {};
    }

    return profiles.reduce(
      (acc, curr) => ({
        ...acc,
        [curr?.id]: curr,
      }),
      {},
    );
  }, [profiles?.length]);

  const { name, age, ...response } = profileMap[selectedProfileId] || {};

  const learningReportData = response?.[currentTimeSpan] || {};

  const learningItems = learningReportData[currentTabId] || [];

  const buildQuery = learningItems
    ?.map(({ title, mostWatchedItems } = {}) => ({
      containerId: title,
      referenceIds: mostWatchedItems?.map(({ videoId }) => videoId) || [],
    }))
    ?.filter(({ referenceIds } = {}) => Boolean(referenceIds));

  const mostWatchedAssets = useSelector(getAssetsByReferenceIds);

  useEffect(() => {
    if (!mostWatchedAssets) {
      dispatch(toggleLoginSpinner(true));
    } else {
      dispatch(toggleLoginSpinner(false));
    }
  }, [Boolean(mostWatchedAssets), Boolean(loadingSpinnerState)]); // CUSTSUPP-20909: Add "Boolean(loadingSpinnerState)" 'cause this effect only executed once leaving the spinner on

  useEffect(() => {
    if (!Object.keys(mostWatchedAssets || {}).length) {
      dispatch(loadAssetsByReferenceIds(buildQuery));
    }
  }, []);

  useEffect(() => {
    const tabChanged = prevTab.current !== currentTabId;
    const timeSpanChanged = prevTimeSpan.current !== currentTimeSpan;
    const profileIdChanged = prevProfileId.current !== selectedProfileId;

    if (tabChanged || timeSpanChanged || profileIdChanged) {
      dispatch(loadAssetsByReferenceIds(buildQuery));

      prevTab.current = currentTabId;
      prevTimeSpan.current = currentTimeSpan;
      prevProfileId.current = selectedProfileId;
    }
  }, [currentTabId, currentTimeSpan, selectedProfileId]);

  const benefitsUrl = useSelector(state => getBenefitsUrl(state));

  const benefitsData = useSelector(state => getSensicalBenefits(state));

  const selectedAgeMetadata = useSelector(state => getAgeMetadata(age, state));
  const generalBenefitsCopy = selectedAgeMetadata?.benefitsCopy;

  const {
    reminder,
    newSubscriber: {
      title: newSubscriberTitle,
      subtitle: newSubscriberSubtitle,
    },
  } = useSelector(getLearningReportsConfig);

  useEffect(() => {
    if (!benefitsData) {
      dispatch(loadSensicalBenefits(benefitsUrl));
    }
  }, []);

  const benefits = useMemo(() => {
    if (!benefitsData) {
      return {};
    }

    return Object.keys(benefitsData || {}).reduce((acc, key) => {
      const benefitsKey = key?.replace('-', ' ').toLowerCase();

      return {
        ...acc,
        [benefitsKey]: benefitsData[key],
      };
    }, {});
  }, [Boolean(benefitsData)]);

  const minAge = useSelector(state => getMinAge(state, age));

  return (
    <div className={pageContainer}>
      <h2 className={pageTitle}>{PAGE_TITLE}</h2>
      <ProfileSwitcher
        profiles={profiles}
        selectedProfileId={selectedProfileId}
        onProfileSelect={setSelectedProfileId}
      />
      <TimePeriodSwitcher
        currentTimeSpan={currentTimeSpan}
        setCurrentTimeSpan={setCurrentTimeSpan}
      />
      <p className={activityDescription}>{reminder}</p>
      {/* <TotalTimeView
        totalTimeWatched={totalTimeWatched}
        currentProfile={selectedProfileData}
      /> */}
      <TabView
        className={tabView}
        tabsSchema={tabSchema}
        currentTabId={currentTabId}
        onTabClick={setCurrentTabId}
      />
      <span className={cx({ [hidden]: !learningItems.length })}>
        <h2
          className={cx(generalBenefitsTitle, {
            [hidden]: currentTabId === 'topics',
          })}
        >
          What are Sensical Benefits
        </h2>
        <p
          className={cx(generalBenefitsDescription, {
            [hidden]: currentTabId === 'topics',
          })}
        >
          {generalBenefitsCopy}
        </p>
        <p className={learnedText}>
          {getLearnedText(
            name,
            timePeriodFilters[currentTimeSpan]?.labelLearnedText,
          )}
        </p>
      </span>
      <span
        className={cx(
          { [hidden]: learningItems.length },
          emptyReportPlaceholder,
        )}
      >
        <h4 className={noActivityTitle}>{newSubscriberTitle}</h4>
        <p className={noActivityDescription}>{newSubscriberSubtitle}</p>
      </span>
      {learningItems?.map((itemProps = {}, idx) => {
        const { title } = itemProps || {};

        const { description } =
          (currentTabId === 'benefits' &&
            benefits?.[title?.toLowerCase()]?.[minAge]) ||
          {};

        return (
          <LearningItem
            {...itemProps}
            key={`${title}-${idx}`}
            mostWatched={mostWatchedAssets?.[title]}
            currentTimeSpan={currentTimeSpan}
            description={description}
          />
        );
      })}
    </div>
  );
};

LearningReports.propTypes = {
  history: PropTypes.object,
};

export default React.memo(LearningReports);
