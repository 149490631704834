import { useState, useEffect } from 'react';

const MemoryStorage = {
  setItem(item) {
    this.item = item;
  },
  getItem() {
    return this.item || 'null';
  },
};

const getStorageEngine = () => {
  const isBrowser = typeof window !== 'undefined';
  return isBrowser ? localStorage : MemoryStorage;
};

const storage = getStorageEngine();

export const useLocalStorage = (key, initialValue) => {
  const loadStoredValue = () => {
    try {
      const item = storage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.warn(
        `An error occurred loading the localStorage key “${key}”:`,
        error,
      );
      return initialValue;
    }
  };

  const [storedValue, setStoredValue] = useState(loadStoredValue);

  const setValue = value => {
    try {
      const valueToStore =
        typeof value === 'function' ? value(storedValue) : value;

      storage.setItem(key, JSON.stringify(valueToStore));

      setStoredValue(valueToStore);
    } catch (error) {
      console.warn(`Error setting the localStorage key “${key}”:`, error);
    }
  };

  useEffect(() => {
    setStoredValue(loadStoredValue());
  }, []);

  return [storedValue, setValue];
};
