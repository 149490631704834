import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '#/redux/modules/appPlayer';
import { setTextToSpeech } from '#/redux/modules/system/actions';

const companionLibraryUrl =
  'http://localhost:12345/scfs/cl/js/vizio-companion-lib.js';

// Custom hook to load the local companion library
// that exists on device through inline scripting
const useVizioLibrary = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    document.addEventListener('VIZIO_LIBRARY_DID_LOAD', e => {
      console.warn(e, 'VIZIO LIBRARY DID LOAD');
      const vizioRef = window.VIZIO;

      vizioRef?.getDeviceId(deviceId =>
        console.warn(deviceId, 'VIZIO DEVICE ID'),
      );
      vizioRef?.setClosedCaptionHandler(enabled => {
        dispatch(actions.setVideoCaption({ videoCaptionEnabled: enabled }));
      });

      vizioRef?.setClosedCaptionStyleChangeHandler(ccStyles => {
        dispatch(actions.setVideoCaptionStyles({ ccStyles }));
      });

      vizioRef?.setContentChangeHandler(contentUrl => {
        window.location.href = contentUrl;
      });
    });

    document.addEventListener('VIZIO_TTS_ENABLED', () => {
      dispatch(setTextToSpeech(true));
      console.warn('VIZIO VIZIO_TTS_ENABLED');
    });
    document.addEventListener('VIZIO_TTS_DISABLED', () => {
      dispatch(setTextToSpeech(false));
      console.warn('VIZIO VIZIO_TTS_DISABLED');
    });

    const script = document.createElement('script');
    script.src = companionLibraryUrl;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.removeEventListener('VIZIO_LIBRARY_DID_LOAD', () => {
        console.warn('REMOVING VIZIO LIBRARY LISTENER');
      });
      document.body.removeChild(script);
    };
  }, []);
};

export default useVizioLibrary;
