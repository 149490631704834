import { ASYNC_LOGIN } from './actions';
import { getAsyncState } from '../async/selectors';

let rootSelector = state => state.user;

export const setRootSelector = selector => {
  rootSelector = selector;
};

export const appFirstLogin = state => rootSelector(state)?.appFirstLogin;
export const isAgeVerified = state => rootSelector(state)?.ageVerified;
export const getSubscriptionStatus = state =>
  rootSelector(state)?.subscriptionStatus;
export const isUserLogged = state => rootSelector(state)?.isUserLogged;
export const getToken = state => rootSelector(state)?.token;

export const async = {
  [ASYNC_LOGIN]: state => getAsyncState(ASYNC_LOGIN, state),
};

export const getLogoutRedirectPath = state => {
  return rootSelector(state)?.logoutRedirectPath;
};

export const isGuestMode = state => rootSelector(state)?.guestMode;
