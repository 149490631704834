import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FocusButton } from '@accedo/vdkweb-tv-ui';
import { withFocus } from '@accedo/vdkweb-navigation';

import classNames from 'classnames';
import { FocusDiv } from '#/widgets/ui';
import { ROLES } from '#/utils/accessibility';
import MenuIconFocused from '#/static/images/icons/menu-icon-focused.svg';
import styles from './tvMenuIcon.scss';

const TVMenuIcon = ({
  src,
  nav,
  name,
  onClick,
  isFocused,
  selected = false,
  ariaLabel,
}) => {
  const [icon, setIcon] = useState(null);

  const fileName = !selected
    ? `menu-${name.replace(' ', '').toLowerCase()}.svg`
    : `menu-${name.replace(' ', '').toLowerCase()}-selected.svg`;

  useEffect(() => {
    if (src) {
      setIcon(src);
    } else {
      import(`#/static/images/icons/${fileName}`)
        .then(iconModule => {
          setIcon(iconModule);
        })
        .catch(() => {
          console.error(`Cannot find icon module: ${name} `);
        });
    }
  }, [name, selected]);

  const render = () => {
    if (!icon) {
      return null;
    }
    return (
      <FocusDiv
        nav={nav}
        ariaLabel={ariaLabel}
        style={{ outline: 'none' }}
        role={ROLES.NAVIGATION}
      >
        <FocusButton nav={nav} onClick={onClick} theme={styles}>
          <div
            className={selected ? styles.selectedOverlay : null}
            aria-hidden="true"
          />
          <img
            className={classNames(styles.menuIcon, { [styles.circular]: src })}
            src={src || icon.default}
            alt={name}
          />
          <img
            className={classNames(styles.menuIconFocused, {
              [styles.isFocused]: isFocused,
            })}
            src={MenuIconFocused}
            alt={`${name}Focused`}
          />
          <div
            className={classNames(styles.menuIconLabel, {
              [styles.isFocused]: isFocused,
            })}
          >
            {name}
          </div>
        </FocusButton>
      </FocusDiv>
    );
  };

  return <>{render()}</>;
};

TVMenuIcon.propTypes = {
  src: PropTypes.string,
  nav: PropTypes.object,
  name: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  isFocused: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

export default withFocus(TVMenuIcon);
