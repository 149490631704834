import React, { useMemo } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { mainRoutes } from '#/utils/routes';

import redux from '#/redux/modules';
import styles from './menuIcon.scss';
import TVMenuIcon from '../TVMenuIcon/TVMenuIcon';

const {
  accedoOne: {
    selectors: { assetWithId },
  },
} = redux;

const MenuIcon = ({ nav, isTv, entryId, onNavigate, history, ariaLabel }) => {
  const item = useSelector(state => assetWithId(state, entryId));
  const returnIconSelection = useMemo(() => {
    if (history.location.pathname === mainRoutes.PARENT_ZONE) {
      return false;
    }
    const { pathname } = history.location;
    // match showdetails, creatordetails
    if (/main\/home\/.+/.test(pathname)) {
      return false;
    }
    if (
      pathname.split('/')[2] ===
      `${item.displayText.replace(' ', '')}`.toLowerCase()
    ) {
      return true;
    }
    return false;
  }, [history, item]);
  const route = mainRoutes[item.displayText.replace(' ', '').toUpperCase()];

  return isTv ? (
    <TVMenuIcon
      nav={nav}
      name={item?.displayText}
      selected={returnIconSelection}
      onClick={() => onNavigate(route)}
      ariaLabel={ariaLabel || item?.displayText}
    />
  ) : (
    <Link onClick={() => onNavigate(route)} to={route}>
      <div
        className={`${styles.menuItemContainer} ${
          returnIconSelection ? styles.iconSelected : null
        }`}
      >
        <img
          className={styles.menuIcon}
          src={
            returnIconSelection
              ? item?.iconSelected.fileUrl
              : item?.icon.fileUrl
          }
          alt={item.displayText}
        />
      </div>
    </Link>
  );
};

MenuIcon.propTypes = {
  nav: PropTypes.object,
  isTv: PropTypes.bool,
  entryId: PropTypes.string,
  onNavigate: PropTypes.func,
  history: PropTypes.any,
  ariaLabel: PropTypes.string,
};

export default withRouter(MenuIcon);
