/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */

import {
  createActions,
  createAction as actionCreatorFactory,
} from 'redux-actions';
import moment from 'moment-mini';
import xdk from '@accedo/xdk-core';
import { livePlayerFormatter } from '#/utils/time';
import routes, { mainRoutes } from '#/utils/routes';

import {
  getVideoPlayTime,
  getVideoAnalytics,
  getPlaybackTimer,
} from './selectors';
import {
  updateProfileWatchHistory,
  updateProfileLiveHistory,
  deleteCustomDataVideoBookmark,
  parentalTimeLimitHeartbeat,
} from '#/redux/modules/identity/actions';
import { getGroupId } from '#/redux/modules/identity/selectors';
import {
  getCurrentProfileAgeGroup,
  getCurrentProfileId,
} from '#/redux/modules/onboarding/selectors';

import firestoreApi from '#/services/firestore/api';
import { buildAdTagParameters } from '#/services/ads/ads';
import {
  playerConstants,
  videoAnalytics,
  XMLTV_TIME_FORMAT,
  ageGroupAnalyticsValues,
  ageRanges,
} from '#/config/constants';
import { getSessionIdTs } from '#/redux/modules/accedoOne/selectors';
import { isGuestMode } from '#/redux/modules/user/selectors';

const SET_EPG = 'SET_EPG';
const SET_PREROLL_AD_DETAILS = 'SET_PREROLL_AD_DETAILS';
const ADD_PREROLL_AD = 'ADD_PREROLL_AD';
const CLEAR_PREROLL_ADS = 'CLEAR_PREROLL_ADS';
const SET_AD_DETAILS_LOADED = 'SET_AD_DETAILS_LOADED';
const SET_AD_PLAYED = 'SET_AD_PLAYED';
const SET_VIDEO_PAUSE = 'SET_VIDEO_PAUSE';
const SET_VIDEO_PLAY = 'SET_VIDEO_PLAY';
const SET_VIDEO_ENDED = 'SET_VIDEO_ENDED';
const SET_VIDEO_FULL_SCREEN = 'SET_VIDEO_FULL_SCREEN';
const SET_VIDEO_CAPTION = 'SET_VIDEO_CAPTION';
const SET_VIDEO_CAPTION_STYLES = 'SET_VIDEO_CAPTION_STYLES';
const SET_VIDEO_SEEK = 'SET_VIDEO_SEEK';
const SET_VIDEO_SEEKED = 'SET_VIDEO_SEEKED';
const SET_VIDEO_TIME = 'SET_VIDEO_TIME';
const SET_VIDEO_PLAY_TIME = 'SET_VIDEO_PLAY_TIME';
const CLEAR_VIDEO_PLAY_TIME = 'CLEAR_VIDEO_PLAY_TIME';
const SET_VIDEO_CONTENT = 'SET_VIDEO_CONTENT';
const SET_VIDEO_PLAYLIST = 'SET_VIDEO_PLAYLIST';
const SET_VIDEO_BOOKMARK = 'SET_VIDEO_BOOKMARK';
const CLEAR_VIDEO_AND_BOOKMARK = 'CLEAR_VIDEO_AND_BOOKMARK';
const SET_VIDEO_FULLSCREEN_BOOKMARK = 'SET_VIDEO_FULLSCREEN_BOOKMARK';
const CLEAR_VIDEO_FULLSCREEN_BOOKMARK = 'CLEAR_VIDEO_FULLSCREEN_BOOKMARK';
const SEND_VIDEO_WATCH_HISTORY = 'SEND_VIDEO_WATCH_HISTORY';
const SEND_VIDEO_LIVE_HISTORY = 'SEND_VIDEO_LIVE_HISTORY';
const SET_BACKGROUND_PLAYER_FULLSCREEN = 'SET_BACKGROUND_PLAYER_FULLSCREEN';

const VIDEO_INITIATED = 'VIDEO_INITIATED';
const VIDEO_START = 'VIDEO_START';
const VIDEO_PROGRESS = 'VIDEO_PROGRESS';
const VIDEO_COMPLETE = 'VIDEO_COMPLETE';
const VIDEO_CONTROL = 'VIDEO_CONTROL';
const VIDEO_HEARTBEAT = 'VIDEO_HEARTBEAT';
const VIDEO_INTERRUPTION = 'VIDEO_INTERRUPTION';
const VIDEO_BUFFERING_START = 'VIDEO_BUFFERING_START';
const VIDEO_QUALITY_CHANGE = 'VIDEO_QUALITY_CHANGE';
const VIDEO_QUALITY_DOWNSHIFT = 'VIDEO_QUALITY_DOWNSHIFT';
const SET_ANALYTICS = 'SET_ANALYTICS';
const SEND_VIDEO_ANALYTICS = 'SEND_VIDEO_ANALYTICS';
const SET_PLAYBACK_TIMER = 'SET_PLAYBACK_TIMER';
const CLEAR_PLAYBACK_TIMER = 'CLEAR_PLAYBACK_TIMER';

export const START_BOOKMARKING = 'sensical/player/START_BOOKMARKING';
export const STOP_BOOKMARKING = 'sensical/player/STOP_BOOKMARKING';
export const START_PLAYBACK = 'sensical/player/SET_VIDEO_PLAY_TIME';

export const startBookmarking = actionCreatorFactory(START_BOOKMARKING);
export const stopBookmarking = actionCreatorFactory(STOP_BOOKMARKING);

const actionOptions = {
  prefix: 'sensical/player',
};

export const actions = {
  ...createActions(
    {},
    SET_VIDEO_PAUSE,
    SET_VIDEO_PLAY,
    SET_VIDEO_ENDED,
    SET_VIDEO_FULL_SCREEN,
    SET_VIDEO_CAPTION,
    SET_VIDEO_CAPTION_STYLES,
    SET_VIDEO_SEEK,
    SET_VIDEO_SEEKED,
    SET_EPG,
    SET_PREROLL_AD_DETAILS,
    ADD_PREROLL_AD,
    CLEAR_PREROLL_ADS,
    SET_AD_DETAILS_LOADED,
    SET_AD_PLAYED,
    SET_VIDEO_TIME,
    SET_VIDEO_PLAY_TIME,
    CLEAR_VIDEO_PLAY_TIME,
    SET_VIDEO_CONTENT,
    SET_VIDEO_PLAYLIST,
    SET_VIDEO_BOOKMARK,
    CLEAR_VIDEO_AND_BOOKMARK,
    SET_VIDEO_FULLSCREEN_BOOKMARK,
    CLEAR_VIDEO_FULLSCREEN_BOOKMARK,
    SET_ANALYTICS,
    SET_BACKGROUND_PLAYER_FULLSCREEN,
    SET_PLAYBACK_TIMER,
    CLEAR_PLAYBACK_TIMER,
    actionOptions,
  ),
  startBookmarking,
  stopBookmarking,
  sendVideoWatchHistory,
  sendVideoLiveHistory,
  videoInitiated,
  videoStart,
  videoQualityChange,
  videoComplete,
  videoHeartbeat,
  videoBufferStart,
  videoControl,
  videoInterruption,
  startPlaybackTimer,
  stopPlaybackTimer,
  pausePlayback,
  resumePlayback,
  exitPlayback,
  findAdDetails,
};

export const {
  setVideoPause,
  setVideoPlay,
  setVideoEnded,
  setVideoFullScreen,
  setVideoCaption,
  setVideoCaptionStyles,
  setVideoSeek,
  setVideoSeeked,
  setVideoTime,
  setEpg,
  setPrerollAdDetails,
  addPrerollAd,
  clearPrerollAds,
  setAdDetailsLoaded,
  setAdPlayed,
  setVideoPlayTime,
  clearVideoPlayTime,
  setVideoContent,
  setVideoBookmark,
  clearVideoAndBookmark,
  setVideoFullscreenBookmark,
  clearVideoFullscreenBookmark,
  setAnalytics,
  setBackgroundPlayerFullscreen,
  setVideoPlaylist,
} = actions;

export const sendVideoWatchHistory = payload => (dispatch, getState) => {
  const startTime = getVideoPlayTime(getState());
  if (startTime) {
    dispatch(updateProfileWatchHistory(startTime));
    dispatch(clearVideoPlayTime());
  }

  return {
    type: `${actionOptions.prefix}/${SEND_VIDEO_WATCH_HISTORY}`,
    payload,
  };
};

export const loadEpg = url => async dispatch => {
  try {
    return fetch(url, {
      method: 'GET',
    })
      .then(res => res.text())
      .then(data => {
        const parser = new DOMParser();
        const parsedXml = parser.parseFromString(data, 'text/xml');

        const keys = ['title', 'sub-title', 'desc'];
        const nodeList = parsedXml.getElementsByTagName('programme');
        const programList = [];

        if (nodeList.length === 0) {
          // eslint-disable-next-line no-throw-literal
          throw 'Error parsing the XML or no programmes found in the XML';
        }

        nodeList?.forEach(item => {
          const start = moment.utc(
            item.getAttribute('start'),
            XMLTV_TIME_FORMAT,
          );
          const stop = moment.utc(item.getAttribute('stop'), XMLTV_TIME_FORMAT);

          const parsedItem = {
            start: start.unix(),
            stop: stop.unix(),
            timeRange: `${livePlayerFormatter(
              start,
              true,
            )} - ${livePlayerFormatter(stop)}`,
            channel: item.getAttribute('channel'),
            ...keys.reduce((acc, key) => {
              acc[key] = item.getElementsByTagName(key)?.[0]?.innerHTML;
              return acc;
            }, {}),
          };

          programList.push(parsedItem);
        });

        dispatch(setEpg({ [url]: programList }));
      });
  } catch (e) {
    return null;
  }
};

export const findAdDetails = (vmapUrl, videoDuration) => async (
  dispatch,
  getState,
) => {
  const parser = new DOMParser();

  // SSAI Ads targeting
  const currentProfileId = getCurrentProfileId(getState());
  const ageGroup = getCurrentProfileAgeGroup(getState());
  const ageRange = ageRanges[ageGroup];
  const guestMode = isGuestMode(getState());
  const sessionIdT = getSessionIdTs(getState());
  const adParams = guestMode
    ? await buildAdTagParameters(sessionIdT, ageRange)
    : await buildAdTagParameters(currentProfileId, ageRange);
  const targetedVmapUrl = vmapUrl.includes('?')
    ? `${vmapUrl}&${adParams}`
    : `${vmapUrl}?${adParams}`;

  const vmap = await (await fetch(targetedVmapUrl)).text();
  const parsedXml = parser.parseFromString(vmap, 'text/xml');
  const adBreaks = parsedXml.getElementsByTagName('vmap:AdBreak');

  const brightcoveSource = parsedXml
    .getElementsByTagName('vmap:Extensions')[0]
    ?.getElementsByTagName('bc:Brightcove')[0]
    ?.getAttribute('contenturi');

  const adBreaksTiminigs = Array.prototype.map.call(adBreaks, adBreak => {
    // Only handle pre-roll ads
    const timeOffset = adBreak.attributes.timeOffset.value;
    if (timeOffset !== 'start') {
      return Promise.resolve();
    }

    const ads = adBreak
      .getElementsByTagName('vmap:AdSource')[0]
      ?.getElementsByTagName('vmap:VASTData')[0]
      ?.getElementsByTagName('VAST')[0]
      ?.getElementsByTagName('Ad');

    const totalBreakDuration =
      ads &&
      Array.prototype.reduce.call(
        ads,
        (totalMs, ad) => {
          const adDuration = ad
            ?.getElementsByTagName('InLine')[0]
            ?.getElementsByTagName('Creatives')[0]
            ?.getElementsByTagName('Creative')[0]
            ?.getElementsByTagName('Linear')[0]
            ?.getElementsByTagName('Duration')[0];

          if (!adDuration) {
            return totalMs;
          }

          const adDurationMs = moment
            .duration(adDuration.textContent, 'hh:mm:ss.SSSS')
            .as('milliseconds');

          // Set individual ad timings
          dispatch(addPrerollAd(adDurationMs));

          return totalMs + adDurationMs;
        },
        0,
      );

    return totalBreakDuration || 0;
  });

  const totalDuration = adBreaksTiminigs.reduce(
    (total, breakDuration) => total + breakDuration,
    0,
  );
  await dispatch(setPrerollAdDetails(totalDuration));
  dispatch(setAdDetailsLoaded(true));
  return brightcoveSource;
};

export const sendVideoLiveHistory = (id, url) => (dispatch, getState) => {
  const startTime = getVideoPlayTime(getState());
  if (startTime) {
    dispatch(updateProfileLiveHistory(id, url, startTime));
    dispatch(clearVideoPlayTime());
  }

  return {
    type: `${actionOptions.prefix}/${SEND_VIDEO_LIVE_HISTORY}`,
    payload: {
      id,
      url,
    },
  };
};

const sendPlayerAnalyticEvent = ({ event, payload = {} }) => (
  dispatch,
  getState,
) => {
  const analytics = getVideoAnalytics(getState());

  let currentTime =
    (payload.videoCurrentTime || xdk.media?.getCurrentTime()) * 1000;
  let percentComplete = currentTime / analytics.videoDuration;

  if (event === videoAnalytics.events.VIDEO_COMPLETE) {
    currentTime = analytics.videoDuration;
    percentComplete = 1;
  }

  const evtPayload = {
    video_id: analytics.videoId,
    video_duration: analytics.videoDuration,
    video_current_time: currentTime,
    video_percent_complete: percentComplete,
    video_bitrate: analytics.videoBitrate,
    video_dimensions: analytics.videoDimensions,
    video_streaming_type: analytics.streamType,
    video_initiation_method: analytics.initiationMethod,
    video_player_type: analytics.playerType,
    video_age_group: analytics.videoAgeGroup,
  };

  if (payload.timeToInitialize) {
    evtPayload.time_to_initialize = payload.timeToInitialize;
  }

  if (payload.videoBitratePreshift) {
    evtPayload.video_bitrate_preshift = payload.videoBitratePreshift;
    evtPayload.video_bitrate_postshift = analytics.videoBitrate;
  }

  if (payload.videoDimensionsPreshift) {
    evtPayload.video_resolution_pre_shift = payload.videoDimensionsPreshift;
    evtPayload.video_resolution_post_shift = analytics.videoDimensions;
  }

  if (payload.videoControlType) {
    evtPayload.video_player_control_type = payload.videoControlType;
  }

  if (payload.videoError) {
    evtPayload.video_interruption_type = payload.videoError;
  }

  firestoreApi.sendAnalytics(event, evtPayload);

  return {
    type: `${actionOptions.prefix}/${SEND_VIDEO_ANALYTICS}`,
  };
};

export const videoInitiated = ({
  streamType,
  playerType,
  bitrate = 0,
}) => async (dispatch, getState) => {
  const appPlayerState = getState().modules.appPlayer;
  const brightcoveState = getState().modules.brightcove;
  const ageGroup = getCurrentProfileAgeGroup(getState());

  await dispatch(
    setAnalytics({
      videoAgeGroup: ageGroup,
      videoId: brightcoveState.asset.reference_id,
      videoDuration: brightcoveState.asset.duration,
      videoBitrate: bitrate,
      videoDimensions: '0 x 0',
      streamType,
      initiationMethod: appPlayerState.videoContent.initiation,
      initializeTime: Date.now(),
      playerType,
    }),
  );

  dispatch(
    sendPlayerAnalyticEvent({
      event: videoAnalytics.events.VIDEO_INITIATED,
      payload: {
        videoBitrate: bitrate,
        videoCurrentTime: appPlayerState.videoContent.currentVideo.time || 0,
      },
    }),
  );

  return {
    type: `${actionOptions.prefix}/${VIDEO_INITIATED}`,
  };
};

export const videoStart = ({ width, height, bitrate }) => async (
  dispatch,
  getState,
) => {
  await dispatch(
    setAnalytics({
      videoBitrate: bitrate,
      videoDimensions: `${width} x ${height}`,
    }),
  );

  const { initializeTime } = getVideoAnalytics(getState());
  dispatch(
    sendPlayerAnalyticEvent({
      event: videoAnalytics.events.VIDEO_START,
      payload: {
        timeToInitialize: Date.now() - initializeTime,
      },
    }),
  );

  return {
    type: `${actionOptions.prefix}/${VIDEO_START}`,
  };
};

export const videoQualityChange = ({ width, height, bitrate }) => async (
  dispatch,
  getState,
) => {
  const analytics = getVideoAnalytics(getState());

  if (bitrate < analytics.videoBitrate) {
    const videoBitratePreshift = analytics.videoBitrate;
    const videoDimensionsPreshift = analytics.videoDimensions;

    await dispatch(
      setAnalytics({
        videoBitrate: bitrate,
        videoDimensions: `${width} x ${height}`,
      }),
    );

    dispatch(
      sendPlayerAnalyticEvent({
        event: videoAnalytics.events.VIDEO_QUALITY_DOWNSHIFT,
        payload: {
          videoBitratePreshift,
          videoDimensionsPreshift,
        },
      }),
    );

    return {
      type: `${actionOptions.prefix}/${VIDEO_QUALITY_DOWNSHIFT}`,
    };
  }

  dispatch(
    setAnalytics({
      videoBitrate: bitrate,
      videoDimensions: `${width}x${height}`,
    }),
  );

  return {
    type: `${actionOptions.prefix}/${VIDEO_QUALITY_CHANGE}`,
  };
};

export const videoComplete = videoReferenceId => dispatch => {
  dispatch(
    sendPlayerAnalyticEvent({
      event: videoAnalytics.events.VIDEO_COMPLETE,
    }),
  );

  dispatch(deleteCustomDataVideoBookmark(videoReferenceId));

  return {
    type: `${actionOptions.prefix}/${VIDEO_COMPLETE}`,
  };
};

export const videoHeartbeat = () => dispatch => {
  dispatch(
    sendPlayerAnalyticEvent({
      event: videoAnalytics.events.VIDEO_HEARTBEAT,
    }),
  );

  return {
    type: `${actionOptions.prefix}/${VIDEO_HEARTBEAT}`,
  };
};

export const videoBufferStart = () => dispatch => {
  dispatch(
    sendPlayerAnalyticEvent({
      event: videoAnalytics.events.VIDEO_BUFFER,
    }),
  );

  return {
    type: `${actionOptions.prefix}/${VIDEO_BUFFERING_START}`,
  };
};

export const videoControl = controlType => dispatch => {
  dispatch(
    sendPlayerAnalyticEvent({
      event: videoAnalytics.events.VIDEO_CONTROL,
      payload: {
        videoControlType: controlType,
      },
    }),
  );

  // If playback was not paused during seek, play event is fired before seek end.
  // Check if media is in playing state to compensate.
  if (
    controlType === videoAnalytics.controls.seeked &&
    xdk.media?.getState() === 'playing'
  ) {
    dispatch(
      sendPlayerAnalyticEvent({
        event: videoAnalytics.events.VIDEO_CONTROL,
        payload: {
          videoControlType: videoAnalytics.controls.play,
        },
      }),
    );
  }

  return {
    type: `${actionOptions.prefix}/${VIDEO_CONTROL}`,
  };
};

export const videoInterruption = (videoError, videoCurrentTime) => (
  dispatch,
  getState,
) => {
  if (videoError !== videoAnalytics.events.NETWORK_ERROR) {
    videoError = videoAnalytics.events.GENERIC_ERROR;
  }

  dispatch(
    sendPlayerAnalyticEvent({
      event: videoAnalytics.events.VIDEO_INTERRUPTION,
      payload: {
        videoError,
        videoCurrentTime,
      },
    }),
  );

  return {
    type: `${actionOptions.prefix}/${VIDEO_INTERRUPTION}`,
  };
};

export const videoProgress = videoCurrentTime => dispatch => {
  dispatch(
    sendPlayerAnalyticEvent({
      event: videoAnalytics.events.VIDEO_PROGRESS,
      payload: {
        videoCurrentTime,
      },
    }),
  );

  return {
    type: `${actionOptions.prefix}/${VIDEO_PROGRESS}`,
  };
};

// Player actions
export const pausePlayback = () => () => {
  xdk.media?.pause();
};

export const resumePlayback = () => () => {
  xdk.media?.play();
};

// Playback timer
export const startPlaybackTimer = () => async dispatch => {
  await dispatch(stopPlaybackTimer());

  const interval = setInterval(() => {
    dispatch(parentalTimeLimitHeartbeat(playerConstants.HEARTBEAT_TIMEOUT));
  }, playerConstants.HEARTBEAT_TIMEOUT);
  dispatch(actions.setPlaybackTimer(interval));
};

export const stopPlaybackTimer = () => async (dispatch, getState) => {
  const interval = getPlaybackTimer(getState());
  clearInterval(interval);
  dispatch(actions.clearPlaybackTimer());
};

// Exit player
export const exitPlayback = history => () => {
  // CUSTSUPP-21092 History listen moved to src/components/App/Route.js
  history.goBack();
};
