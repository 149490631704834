let rootSelector = state => state.lifecycle;

export const setRootSelector = selector => {
  rootSelector = selector;
};

export const getAppStarted = state => rootSelector(state)?.appStarted;

export const getDeepLinkStarted = state => rootSelector(state)?.deepLinkStarted;

export const getUserAutoloaded = state => rootSelector(state)?.userAutoloaded;

export const getDeeplinkOutsidePlayer = state =>
  rootSelector(state)?.deeplinkOutsidePlayer;
