export const TOKEN = 'vindicia-auth-token';
export const REFRESH_TOKEN = 'vindicia-refresh-token';
export const TOKEN_EXPIRATION_DATE = 'vindicia-auth-token-exp-date';

const get = name => {
  try {
    return localStorage.getItem(name);
  } catch (error) {
    console.error(error);
    return null;
  }
};

const clearUser = () => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
};

const setUserLogin = ({ access_token, refresh_token, expires_in }) => {
  try {
    if (refresh_token) {
      localStorage.removeItem(REFRESH_TOKEN);
      localStorage.setItem(REFRESH_TOKEN, refresh_token);
    }

    localStorage.removeItem(TOKEN);
    localStorage.removeItem(TOKEN_EXPIRATION_DATE);

    localStorage.setItem(TOKEN, access_token);

    const toExpire = expires_in * 0.95 * 1000;
    const currentDate = new Date();
    const expirationDate = new Date(currentDate.getTime() + toExpire);

    localStorage.setItem(TOKEN_EXPIRATION_DATE, expirationDate.getTime());
  } catch (error) {
    console.error(error);
  }
};

export default {
  get,
  clearUser,
  setUserLogin,
};
