import React, { useEffect } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { withFocus, focusManager } from '@accedo/vdkweb-navigation';
import styles from './BaseModal.scss';
import { isWorkstation } from '#/utils/componentStyleConfig';
import { actions } from '#/redux/modules/modal/actions';
import {
  getFinallyAction,
  getSuccessAction,
  getModal,
} from '#/redux/modules/modal/selectors';
import {
  CALLBACK,
  REMOVE_CALLBACK,
} from '#/redux/middleware/callbackMiddleware';

import { CloseX, Button, FocusButton } from '#/widgets/ui';

const BaseModal = ({
  children,
  className,
  showSaveButton = true,
  saveButtonClassName,
  cancelButtonClassName,
  saveButtonContainerClassName,
  saveButtonText = 'Save',
  saveButtonDisabled = false,
  showCancelButton = false,
  cancelButtonText = 'Cancel',
  cancelButtonDisabled = true,
  showCloseX = true,
  closeAction,
  saveAction,
  cancelAction,
  closeIconClassName,
  nav,
  footerContent,
  footerContentClassName,
}) => {
  const dispatch = useDispatch();
  const successAction = useSelector(getSuccessAction);
  const finallyAction = useSelector(getFinallyAction);

  const modalId = useSelector(getModal);
  const isShortModal = modalId === 'DataSharePolicyModal';

  useEffect(() => {
    // XDK focus capture
    if (nav) {
      focusManager.changeFocus(nav.parent);
    }

    // Remove unused callbacks from middleware to prevent memory leaks
    return () => {
      if (successAction.type === CALLBACK) {
        successAction.type = REMOVE_CALLBACK;
        dispatch(successAction);
      }
      if (finallyAction.type === CALLBACK) {
        finallyAction.type = REMOVE_CALLBACK;
        dispatch(finallyAction);
      }
    };
  }, []);

  return (
    <div
      tabIndex="-1"
      id={nav?.parent || nav?.id}
      className={cx(
        styles.modalBox,
        className,
        isShortModal && styles.shortModal,
      )}
    >
      {isWorkstation() && showCloseX ? (
        <span
          className={cx(styles.closex, closeIconClassName)}
          onClick={() => {
            if (!closeAction) {
              dispatch(finallyAction);
              dispatch(actions.closeModal());
            } else {
              dispatch(closeAction());
            }
          }}
        >
          <CloseX />
        </span>
      ) : null}
      <div className={styles.modalContent}>{children}</div>
      <div
        className={cx(styles.saveButtonContainer, saveButtonContainerClassName)}
      >
        {isWorkstation() && showSaveButton ? (
          <Button
            disabled={saveButtonDisabled}
            selected={!saveButtonDisabled}
            onClick={() => {
              if (!saveAction) {
                dispatch(successAction);
                dispatch(actions.closeModal());
              } else {
                dispatch(saveAction());
              }
            }}
            className={cx(
              showCancelButton ? styles.primaryCtaMargin : '',
              saveButtonClassName,
            )}
          >
            {saveButtonText}
          </Button>
        ) : null}
        {!isWorkstation() && showSaveButton ? (
          <FocusButton
            disabled={saveButtonDisabled}
            selected={!saveButtonDisabled}
            onClick={() => {
              if (!saveAction) {
                dispatch(successAction);
                dispatch(actions.closeModal());
              } else {
                dispatch(saveAction());
              }
            }}
            className={cx(
              showCancelButton ? styles.primaryCtaMargin : '',
              saveButtonClassName,
            )}
            nav={nav}
            ariaLabel={saveButtonText}
            aria-describedby={nav?.parent}
          >
            {saveButtonText}
          </FocusButton>
        ) : null}
        {showCancelButton ? (
          <Button
            selected={!cancelButtonDisabled}
            onClick={() => {
              if (!cancelAction) {
                dispatch(finallyAction);
                dispatch(actions.closeModal());
              } else {
                dispatch(cancelAction());
              }
            }}
            className={cx(cancelButtonClassName)}
          >
            {cancelButtonText}
          </Button>
        ) : null}
      </div>
      <div className={cx(footerContentClassName)}>{footerContent || null}</div>
    </div>
  );
};

BaseModal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  closeIconClassName: PropTypes.string,
  showSaveButton: PropTypes.bool,
  saveButtonText: PropTypes.string,
  saveButtonDisabled: PropTypes.bool,
  showCancelButton: PropTypes.bool,
  cancelButtonText: PropTypes.string,
  cancelButtonDisabled: PropTypes.bool,
  closeAction: PropTypes.func,
  saveAction: PropTypes.func,
  cancelAction: PropTypes.func,
  saveButtonClassName: PropTypes.string,
  cancelButtonClassName: PropTypes.string,
  saveButtonContainerClassName: PropTypes.string,
  showCloseX: PropTypes.bool,
  nav: PropTypes.object,
  footerContent: PropTypes.node,
  footerContentClassName: PropTypes.string,
};

export default withFocus(BaseModal);
