let rootSelector = state => state.analytics;

export const setRootSelector = selector => {
  rootSelector = selector;
};

export const getPlatform = state => rootSelector(state)?.platform;
export const getModel = state => rootSelector(state)?.model;
export const getAppVersion = state => rootSelector(state)?.version;
export const getApiLevel = state => rootSelector(state)?.apiLevel;
export const isFireTV = state => rootSelector(state)?.isFireTV;
