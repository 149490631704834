import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import styles from './AvatarModal.scss';
import { Avatar } from '#/widgets/ui';

import { getDraftProfile } from '#/redux/modules/onboarding/selectors';

import { getAvatars } from '#/redux/modules/firestore/selectors';

const AvatarModal = ({ successAction }) => {
  const dispatch = useDispatch();
  const profile = useSelector(getDraftProfile);
  const fieldOptions = useSelector(getAvatars);
  const fieldSelected = profile.avatar;
  successAction.payload = fieldSelected;

  const optionsButtons = Object.entries(fieldOptions).map(([k, v]) => (
    <span
      key={k}
      onClick={() => {
        successAction.payload = v;
        dispatch(successAction);
      }}
      className={cx(styles.avatar, { [styles.selected]: fieldSelected === v })}
    >
      <Avatar profile={{ avatar: v }} />
    </span>
  ));

  return (
    <>
      <h2 className={styles.title}>Choose Your Avatar</h2>
      <span className={styles.monthModalContent}>{optionsButtons}</span>
    </>
  );
};

AvatarModal.propTypes = {
  successAction: PropTypes.object,
};

export default AvatarModal;
