import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// Focus handling
import { TV_CONTENT_MODAL, VIZIO_KEYBOARD } from '#/utils/navigationHelper';

import { InfoForm, InfoInput } from '#/widgets/forms';
import { DigitEntry, FocusAnchor, FocusDiv } from '#/widgets/ui';
import BaseModal from '../../common/BaseModal/BaseModal';
import FocusModal from '../../common/FocusModal/FocusModal';

import {
  switchProfile,
  updateAccountData,
} from '#/redux/modules/identity/actions';
import {
  getSuccessAction,
  getModalData,
} from '#/redux/modules/modal/selectors';
import { getParentUserId } from '#/redux/modules/identity/selectors';
import { actions as modalActions } from '#/redux/modules/modal/actions';
import { CALLBACK } from '#/redux/middleware/callbackMiddleware';

import { vindiciaErrors } from '#/config/strings';
import styles from './PinModal.scss';
import { isVIZIO } from '#/utils/componentStyleConfig';

const { CONTAINER, TV_CONTENT_MODAL_ACTION_BUTTON } = TV_CONTENT_MODAL;

const navMap = {
  CONTAINER: {
    id: CONTAINER,
  },
  FORGOT_PIN: {
    id: 'FORGOT_PIN',
  },
  PIN_ENTRY: {
    id: 'PIN_ENTRY',
  },
  PASSWORD: {
    id: 'PASSWORD',
  },
  ACTION_BUTTON: {
    id: TV_CONTENT_MODAL_ACTION_BUTTON,
  },
  KEYBOARD: {
    id: VIZIO_KEYBOARD,
    nextup: TV_CONTENT_MODAL_ACTION_BUTTON,
    parent: CONTAINER,
  },
};

const EnterPassword = ({ onPasswordEntered, passwordError = null }) => {
  const [valid, setValid] = useState(false);
  const [password, setPassword] = useState(null);

  return (
    <BaseModal
      showSaveButton
      saveButtonText="Continue"
      saveButtonDisabled={!valid}
      saveAction={() => () => onPasswordEntered(password)}
      saveButtonClassName={styles.modalBtn}
      nav={{
        id: navMap.ACTION_BUTTON.id,
        parent: navMap.CONTAINER.id,
        nextup: navMap.PASSWORD.id,
        nextdown: isVIZIO() ? navMap.KEYBOARD.id : null,
      }}
    >
      <FocusDiv
        className={styles.pinModalContainer}
        nav={{ id: navMap.CONTAINER.id, forwardFocus: navMap.PASSWORD.id }}
      >
        <h2>Enter Password</h2>
        <p className={styles.description}>
          To reset your PIN, please enter your password.{'\n'}
        </p>
        <InfoForm
          onChange={(data, isValid) => {
            setValid(isValid);
            isValid && setPassword(data.password);
          }}
        >
          <InfoInput
            fieldName="password"
            fieldLabel="Password"
            inputAttributes={{
              type: 'password',
              pattern: '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$',
              required: 'required',
            }}
            externalErrorMessage={passwordError}
            nav={{
              id: navMap.PASSWORD.id,
              parent: navMap.CONTAINER.id,
              nextdown: navMap.ACTION_BUTTON.id,
              keyboard: navMap.KEYBOARD,
            }}
          />
        </InfoForm>
      </FocusDiv>
    </BaseModal>
  );
};
EnterPassword.propTypes = {
  onPasswordEntered: PropTypes.func,
  passwordError: PropTypes.string,
};

const SetNewPin = ({ onPinEntered, onExitPinRecovery }) => {
  const dispatch = useDispatch();
  const [pinValid, setPinValid] = useState(false);
  const [pin, setPin] = useState(null);

  return (
    <BaseModal
      showSaveButton
      saveButtonText="Continue"
      saveButtonDisabled={!pinValid}
      saveButtonClassName={styles.modalBtn}
      saveAction={() => () => onPinEntered(pin)}
      closeAction={() => () => onExitPinRecovery()}
      nav={{
        id: navMap.ACTION_BUTTON.id,
        parent: navMap.CONTAINER.id,
        nextup: navMap.PIN_ENTRY.id,
        nextdown: isVIZIO() ? navMap.KEYBOARD.id : null,
      }}
    >
      <FocusDiv
        className={styles.pinModalContainer}
        nav={{ id: navMap.CONTAINER.id, forwardFocus: navMap.PIN_ENTRY.id }}
      >
        <h2>Enter New PIN</h2>
        <p className={styles.description}>Please enter your new PIN.{'\n'}</p>
        <DigitEntry
          nav={{
            id: navMap.PIN_ENTRY.id,
            nextdown: navMap.ACTION_BUTTON.id,
            keyboard: navMap.KEYBOARD,
          }}
          onChange={(isValid, value) => {
            setPinValid(isValid);
            isValid && setPin(value);
          }}
          onSubmit={() => {
            if (isVIZIO()) {
              return;
            }
            dispatch(() => onPinEntered(pin));
          }}
        />
      </FocusDiv>
    </BaseModal>
  );
};
SetNewPin.propTypes = {
  onPinEntered: PropTypes.func,
  onExitPinRecovery: PropTypes.func,
};

const UnlockWithPin = ({ onPinEntered, onForgotPin }) => {
  const [pinValid, setPinValid] = useState(false);
  const dispatch = useDispatch();
  const successAction = useSelector(getSuccessAction);

  return (
    <BaseModal
      showSaveButton
      saveButtonText="Continue"
      saveButtonDisabled={!pinValid}
      saveButtonClassName={styles.modalBtn}
      nav={{
        id: navMap.ACTION_BUTTON.id,
        parent: navMap.CONTAINER.id,
        nextup: navMap.PIN_ENTRY.id,
        nextdown: isVIZIO() ? navMap.KEYBOARD.id : null,
      }}
    >
      <FocusDiv
        nav={{ id: navMap.CONTAINER.id, forwardFocus: navMap.PIN_ENTRY.id }}
        className={styles.pinModalContainer}
      >
        <h2>Enter PIN</h2>
        <p className={styles.description}>
          To continue, please enter your parent PIN code.{'\n'}
        </p>
        <span className={styles.forgotpin}>
          <FocusAnchor
            nav={{
              id: navMap.FORGOT_PIN.id,
              nextdown: navMap.PIN_ENTRY.id,
            }}
            onClick={onForgotPin}
            href="#"
          >
            Forgot PIN?
          </FocusAnchor>
        </span>
        <DigitEntry
          nav={{
            id: navMap.PIN_ENTRY.id,
            nextup: navMap.FORGOT_PIN.id,
            nextdown: navMap.ACTION_BUTTON.id,
            keyboard: navMap.KEYBOARD,
          }}
          onChange={(valid, value) => {
            setPinValid(valid);
            valid && onPinEntered(value);
          }}
          onSubmit={() => {
            if (isVIZIO()) {
              return;
            }
            dispatch(successAction);
            dispatch(modalActions.closeModal());
          }}
        />
      </FocusDiv>
    </BaseModal>
  );
};
UnlockWithPin.propTypes = {
  onPinEntered: PropTypes.func,
  onForgotPin: PropTypes.func,
};

const NewAccountPinMobile = ({ onPinEntered }) => {
  const [pinValid, setPinValid] = useState(false);
  const dispatch = useDispatch();
  const successAction = useSelector(getSuccessAction);

  return (
    <BaseModal
      showSaveButton
      saveButtonText="Save PIN"
      saveButtonDisabled={!pinValid}
      saveButtonClassName={styles.modalBtn}
      nav={{
        id: navMap.ACTION_BUTTON.id,
        parent: navMap.CONTAINER.id,
        nextup: navMap.PIN_ENTRY.id,
        nextdown: isVIZIO() ? navMap.KEYBOARD.id : null,
      }}
    >
      <FocusDiv
        nav={{ id: navMap.CONTAINER.id, forwardFocus: navMap.PIN_ENTRY.id }}
        className={styles.pinModalContainer}
      >
        <h2 className={styles.modalTitle}>Parent PIN</h2>
        <p className={styles.description}>
          Set a 4 digit parental PIN code.{'\n'}
        </p>
        <DigitEntry
          nav={{
            id: navMap.PIN_ENTRY.id,
            nextup: navMap.FORGOT_PIN.id,
            nextdown: navMap.ACTION_BUTTON.id,
            keyboard: navMap.KEYBOARD,
          }}
          onChange={(valid, value) => {
            setPinValid(valid);
            valid && onPinEntered(value);
          }}
          onSubmit={() => {
            if (isVIZIO()) {
              return;
            }
            dispatch(successAction);
            dispatch(modalActions.closeModal());
          }}
        />
        <p className={styles.helperText}>Enter a 4 digit PIN</p>
      </FocusDiv>
    </BaseModal>
  );
};
NewAccountPinMobile.propTypes = {
  onPinEntered: PropTypes.func,
};

const PinModal = ({ successAction }) => {
  const dispatch = useDispatch();
  const parentUserId = useSelector(getParentUserId);
  const [pinRecovery, setPinRecovery] = useState(false);
  const [password, setPassword] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const { isNewAccountMobile } = useSelector(getModalData);

  const onExitPinRecovery = useCallback(async () => {
    setPinRecovery(false);
    setPassword(null);
    dispatch(modalActions.closeModal());
  }, [password]);

  const onPasswordEntered = useCallback(
    async passwordEntered => {
      try {
        await dispatch(
          switchProfile({
            targetProfileId: parentUserId,
            credentialType: 'password',
            credential: passwordEntered,
            setErrors: false,
          }),
        );
        setPassword(passwordEntered);
      } catch (e) {
        setPasswordError(vindiciaErrors.incorrectPasswordError);
      }
    },
    [parentUserId],
  );

  const onPinEntered = useCallback(
    async pin => {
      await dispatch(updateAccountData(parentUserId, { pin }));
      await onExitPinRecovery();
    },
    [parentUserId, onExitPinRecovery],
  );

  // TODO: Success action should be set in BaseModal, safer implementation
  return (
    <FocusModal>
      {isNewAccountMobile && (
        <NewAccountPinMobile
          onPinEntered={pin => {
            successAction.payload =
              successAction.type === CALLBACK
                ? { ...successAction.payload, param: pin }
                : pin;
          }}
        />
      )}
      {!pinRecovery && !isNewAccountMobile && (
        <UnlockWithPin
          onPinEntered={pin => {
            successAction.payload =
              successAction.type === CALLBACK
                ? { ...successAction.payload, param: pin }
                : pin;
          }}
          onForgotPin={e => {
            e?.preventDefault();
            setPinRecovery(true);
          }}
        />
      )}
      {pinRecovery && !password && !isNewAccountMobile && (
        <EnterPassword {...{ onPasswordEntered, passwordError }} />
      )}
      {pinRecovery && password && !isNewAccountMobile && (
        <SetNewPin {...{ onPinEntered, onExitPinRecovery }} />
      )}
    </FocusModal>
  );
};
PinModal.propTypes = {
  successAction: PropTypes.object,
};

export default PinModal;
