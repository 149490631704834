import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { setHeader } from '#/redux/modules/menu/actions';

function useToggleHeader() {
  const dispatch = useDispatch();

  const toggleHeader = useCallback(toggle => {
    dispatch(setHeader(toggle));
  }, []);

  return toggleHeader;
}

export default useToggleHeader;
