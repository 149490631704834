import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './MediaQueryTest.scss';

const format = ([keys, values]) =>
  keys.flatMap(key => values.map(value => `(${key}: ${value})`));

// Wrapper for format that duplicates all the keys (original key + any-prefixed key)
// E.g. ['hover'] will produce ['hover', 'any-hover']
const formatAny = ([keys, values]) =>
  format([keys.flatMap(key => [key, `any-${key}`]), values]);

const combine = (first, second) =>
  first.length && second.length
    ? first.flatMap(f => second.map(s => `${f} and ${s}`))
    : (first.length && first) || (second.length && second);

// Create media query strings to test in window.matchMedia
// mediaQueries format:
// [
//   [['hover'], ['none']]
//   [['pointer'], ['none']]
// ]
const combineQueries = (mediaQueries, formatFunc) =>
  mediaQueries.map(formatFunc).reduce(combine, []);

const MediaQueryTest = ({ queries, addAny = false }) => {
  const mediaQueries = combineQueries(queries, addAny ? formatAny : format);
  return (
    <>
      <div>Media Queries ({mediaQueries.length})</div>
      {mediaQueries.map(query => {
        const match = window.matchMedia(query).matches;
        return (
          <div className={cx({ [styles.match]: match })}>
            Query {query}: {match.toString()}
          </div>
        );
      })}
    </>
  );
};

MediaQueryTest.propTypes = {
  queries: PropTypes.array,
  addAny: PropTypes.bool,
};

export default MediaQueryTest;
