import { createStore, compose, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import promiseMiddleware from 'redux-promise';

import historyConfig from '#/config/history';

import { rootReducer } from './reducer';
import analyticsMiddleware from './middleware/analytics';
import callbackMiddleware from './middleware/callbackMiddleware';
import { accedoOneMiddleware } from './modules/accedoOne/accedoOne';

// Persistence
import { stateSaver, loadState } from './utils/persistence';

let initState = {};

if (typeof window === 'object') {
  initState = window.__data;
}
initState = loadState(initState);

export const configureStore = ({
  historyType = historyConfig.appHistory,
  initialState = initState,
  services,
}) => {
  // This is where we collect our Redux middlewares
  // which all our actions will pass.
  const middleware = [
    // The thunk middleware handles thunk actions,
    // typically used for batching actions together and
    // provide async functionality such as making server
    // requests, etc.
    // All thunk actions will get access to our
    // collected services via an extra parameter.
    // Thus we can connect them to any service we want.
    callbackMiddleware,
    accedoOneMiddleware,
    thunkMiddleware.withExtraArgument(services),

    // The promise middleware manages actions which return
    // a promise. It will then handle resolve and reject
    analyticsMiddleware,
    promiseMiddleware,
  ];

  const tools = [];

  if (__LOCAL__) {
    const newDevToolsExtensionExists =
      typeof window === 'object' &&
      typeof window.__REDUX_DEVTOOLS_EXTENSION__ === 'function';
    const devToolsExtensionExists =
      typeof window === 'object' &&
      typeof window.devToolsExtension === 'function';

    if (newDevToolsExtensionExists) {
      tools.push(window.__REDUX_DEVTOOLS_EXTENSION__());
    } else if (devToolsExtensionExists) {
      tools.push(window.devToolsExtension());
    }
  }

  const finalCreateStore = compose(
    applyMiddleware(...middleware),
    ...tools,
  )(createStore);

  const store = finalCreateStore(rootReducer, initialState);

  const saveState = stateSaver();
  store.subscribe(() => {
    saveState(store.getState());
  });

  // Hot Module Replacement
  if (__LOCAL__ && module.hot) {
    module.hot.accept('./reducer', () => {
      const { rootReducer: newRootReducer } = require('./reducer');

      store.replaceReducer(newRootReducer);
    });
  }

  return {
    store,
    history: historyType,
  };
};
