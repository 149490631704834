import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import classNames from 'classnames';
import { FocusDiv } from '#/widgets/ui';
import { loadSensicalBenefits } from '#/redux/modules/accedoOne/actions';
import {
  getBenefitsUrl,
  getSensicalBenefits,
  getPodcastsControls,
} from '#/redux/modules/accedoOne/selectors';
import { actions as modalActions } from '#/redux/modules/modal/actions';
import { imgixTileResizeParameters } from '#/config/constants';
import getVw from '#/utils/getVw';
import imgixUrl from '#/utils/imgix';
import styles from './about.scss';
import { getHorizontalNav } from '#/utils/navigationHelper';
import { isWorkstation, isiOS } from '#/utils/componentStyleConfig';
import { ROLES, setSpeech } from '#/utils/accessibility';

const SENSICAL_TAG_ITEM = 'SENSICAL_TAG_ITEM';
const getItemId = index => `${SENSICAL_TAG_ITEM}_${index}`;

const About = ({ nav, content, ageTheme }) => {
  const benefitsUrl = useSelector(state => getBenefitsUrl(state));
  const [tagIndex, setCurrentTagIndex] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadSensicalBenefits(benefitsUrl));
  }, []);
  const benefits = useSelector(state => getSensicalBenefits(state));
  const tags = content?.sensical_benefits;
  const { imgixParamsForCover } = useSelector(getPodcastsControls);
  const imgUrl =
    content?.key_art_horizontal_image_path ||
    content?.key_art_square_image_path;
  const imgSrc = useMemo(
    () =>
      content?.media_type?.toLowerCase() === 'podcast'
        ? `${imgUrl}?${imgixParamsForCover}`
        : imgixUrl(imgUrl, imgixTileResizeParameters.CONTENT_DETAILS),
    [imgUrl],
  );

  const openTagDetails = benefitId => {
    const selectedBenefits = benefits[benefitId];
    dispatch(
      modalActions.openModal({
        modalId: 'BenefitModal',
        modalData: selectedBenefits[ageTheme.minAge],
      }),
    );
  };

  const tagItems = tags?.map((item, index) => getItemId(index));
  const tagItemNavMaps = {
    ...getHorizontalNav(tagItems, {
      parent: nav?.id,
    }),
  };

  useEffect(() => {
    setSpeech(
      `${content?.name} ${content?.description} Why it’s Sensical ${content?.why_sensical}`,
    );
  }, []);

  useEffect(() => {
    nav.forwardFocus = getItemId(0);
  }, []);

  return (
    <FocusDiv nav={nav}>
      <div
        className={classNames(styles.mainContainer, {
          [styles.ios]: isiOS(),
        })}
      >
        <div className={styles.aboutWrapper}>
          <div className={styles.aboutContainer}>
            <p className={styles.titleText}>{content?.name}</p>
            <p className={styles.descriptionText}>{content?.description}</p>
          </div>
          <div className={styles.sensicalContainer}>
            <p className={styles.titleText}>Why it’s Sensical</p>
            <p className={styles.descriptionText}>{content?.why_sensical}</p>
            <div className={styles.tagsWrapper}>
              {tags?.map((item, index) => {
                return (
                  <FocusDiv
                    nav={tagItemNavMaps[getItemId(index)]}
                    key={item.tag}
                    onClick={() => {
                      openTagDetails(item.tag);
                    }}
                    onFocus={() => {
                      setCurrentTagIndex(item.tag);
                      setSpeech(`${item.name}, ${ROLES.BUTTON}`);
                    }}
                    useNativeFocus={false}
                    onBlur={() => setCurrentTagIndex(null)}
                    className={classNames(styles.tagContainer, {
                      [styles.isFocused]: tagIndex === item.tag,
                      [styles.isTv]: !isWorkstation(),
                    })}
                  >
                    <p aria-hidden="true">{item.name}</p>
                  </FocusDiv>
                );
              })}
            </div>
          </div>
        </div>
        <div>
          <img
            src={imgSrc}
            alt={content?.name}
            className={styles.image}
            style={{
              borderRadius: ageTheme?.tileRounded ? getVw(24) : '0px',
            }}
          />
        </div>
      </div>
    </FocusDiv>
  );
};

About.propTypes = {
  nav: PropTypes.object,
  content: PropTypes.object,
  ageTheme: PropTypes.object,
};

export default About;
