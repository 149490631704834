/* eslint-disable no-script-url */

import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Page } from '@accedo/vdkweb-tv-ui';

import ErrorDispatcher from '../../ErrorDispatcher/ErrorDispatcher';
import ProfileCard from '../../ProfileCard/ProfileCard';
import { actions as modalActions } from '#/redux/modules/modal/actions';
import {
  ASYNC_SWITCH_PROFILE,
  logicalSwitchToChildProfile,
  switchToParentProfile,
  checkParentalTimeLimit,
} from '#/redux/modules/identity/actions';
import {
  getSavedProfiles,
  getCurrentProfile,
} from '#/redux/modules/onboarding/selectors';
import {
  actions as onboardingActions,
  newDraftProfile,
} from '#/redux/modules/onboarding/actions';
import { sendScreenView } from '#/redux/modules/firestore/actions';
import { getSensicalTvConfig } from '#/redux/modules/accedoOne/selectors';

import firestoreApi from '#/services/firestore/api';
import { FocusButton, FocusDiv } from '#/widgets/ui';
import { profileSelectionTitle } from '#/config/strings';
import { authScreenNames } from '#/config/constants';
import { PROFILESELECTION, getHorizontalNav } from '#/utils/navigationHelper';
import GreenBackground from '#/static/images/background-images/Green.svg';
import Logo from '#/static/images/icons/logo-login.svg';
import styles from './TVProfileSelection.scss';
import routes from '#/utils/routes';
import { ROLES, setSpeech } from '#/utils/accessibility';

const scrollIndexRight = 2;
const scrollIndexLeft = 3;

const ProfileSelection = ({ history }) => {
  const dispatch = useDispatch();
  const { logoUri = Logo } = useSelector(getSensicalTvConfig) || {};
  const baseballCardsContainer = useRef(null);
  const [focusedProfile, setFocusedProfile] = useState(null);
  const currentProfile = useSelector(getCurrentProfile);
  const profiles = useSelector(getSavedProfiles);
  const [scrollLength, setScrollLength] = useState(160);

  useEffect(() => {
    setScrollLength(
      document.getElementById(PROFILESELECTION.CARDS_CONTAINER).scrollWidth /
        profiles.length,
    );
  }, [baseballCardsContainer.current]);

  const editAvatar = useCallback(id => {
    dispatch(onboardingActions.editProfile({ profileId: id }));
    dispatch(
      modalActions.openModal({
        modalId: 'AvatarModalTV',
        successAction: {
          type: String(onboardingActions.setProfileAvatar),
        },
        finallyAction: {
          type: String(onboardingActions.discardProfile),
        },
        modalData: {
          returnFocus: PROFILESELECTION.CARDS_CONTAINER,
        },
      }),
    );
  }, []);

  const addProfile = useCallback(() => {
    dispatch(
      modalActions.openModal({
        modalId: 'PinModal',
        successAction: async pin => {
          await dispatch(switchToParentProfile({ credential: pin }));
          await dispatch(newDraftProfile());
          history.push(routes.PROFILE_ADDITION);
        },
      }),
    );
  }, []);

  const onSwitch = useCallback(async profileId => {
    const timeLimitReached = await dispatch(
      checkParentalTimeLimit({ profileId }),
    );
    if (!timeLimitReached) {
      await dispatch(logicalSwitchToChildProfile(profileId));
      history.push(routes.HOME);
    }
  }, []);

  // todo: maybe refactor to swimlane/scroll component
  const handleScroll = (prev, next) => {
    if (prev < next && next > scrollIndexRight) {
      requestAnimationFrame(() => {
        document.getElementById(PROFILESELECTION.CARDS_CONTAINER).scrollLeft =
          scrollLength * next;
      });
    } else if (prev > next && next < profiles.length - scrollIndexLeft) {
      requestAnimationFrame(() => {
        document.getElementById(PROFILESELECTION.CARDS_CONTAINER).scrollLeft =
          scrollLength * next;
      });
    }
  };

  const baseballCards = useMemo(() => {
    const components = profiles
      ? profiles?.map((item, index) => `ITEM-${index}`)
      : [];
    const profilesNavMap = {
      ...getHorizontalNav(components, {}),
    };

    return profiles.map((profile, index) => {
      const {
        parentalTimeLimit = 0,
        timeWatched: { duration: durationWatched = 0 } = {},
      } = profile;

      const timeLimitReached =
        parentalTimeLimit !== 0 && durationWatched > parentalTimeLimit;

      return (
        <div>
          <ProfileCard
            locked={timeLimitReached}
            key={profile.id}
            profile={profile}
            editMode={false} // bypass check so can edit without changing card style
            onSwitch={() => {
              onSwitch(profile.id);
            }}
            onClick={() => {
              onSwitch(profile.id);
            }}
            onEdit={() => editAvatar(profile.id)}
            nav={{
              ...profilesNavMap[`ITEM-${index}`],
              nextdown: `${PROFILESELECTION.CHANGE_AVATAR_BTN}-${index}`,
              parent: PROFILESELECTION.CARDS_CONTAINER,
            }}
            onFocus={() => {
              const prev = focusedProfile;
              setFocusedProfile(index);
              handleScroll(prev, index);
              setSpeech(`${profile.name}, ${ROLES.BUTTON}`);
            }}
          />
          <FocusButton
            onClick={() => {
              editAvatar(profile.id);
            }}
            tertiary
            className={styles.changeAvatarBtn}
            hidden={focusedProfile !== index}
            nav={{
              id: `${PROFILESELECTION.CHANGE_AVATAR_BTN}-${index}`,
              nextup: `ITEM-${index}`,
              nextdown: PROFILESELECTION.ADD_PROFILE_BTN,
              parent: PROFILESELECTION.CARDS_CONTAINER,
            }}
            ariaLabel="Change avatar"
          >
            Change avatar
          </FocusButton>
        </div>
      );
    });
  }, [profiles, currentProfile, focusedProfile]);

  useEffect(() => {
    profiles.forEach(({ id: profileId }) => {
      dispatch(checkParentalTimeLimit({ profileId, showModal: false }));
    });
    firestoreApi.updateDefaultUserProperties({
      age_group: 'parent',
    });
    dispatch(sendScreenView(authScreenNames.PROFILE_SELECTION));
  }, []);

  return (
    <Page
      className={styles.profileSelection}
      style={{ backgroundImage: `url(${GreenBackground})` }}
      nav={{
        id: PROFILESELECTION.PAGE,
        forwardFocus: PROFILESELECTION.CARDS_CONTAINER,
      }}
    >
      <ErrorDispatcher errorId={ASYNC_SWITCH_PROFILE} />
      <img className={styles.logo} src={logoUri} alt="Logo" />
      <h2>{profileSelectionTitle}</h2>
      <FocusDiv
        ref={baseballCardsContainer}
        nav={{
          id: PROFILESELECTION.CARDS_CONTAINER,
          parent: PROFILESELECTION.PAGE,
          forwardFocus: profiles.length > 0 ? 'ITEM-0' : '',
          useLastFocus: true,
        }}
        className={styles.baseballCardsContainer}
      >
        {baseballCards}
      </FocusDiv>
      <div>
        <FocusButton
          className={styles.addProfileButton}
          onClick={addProfile}
          nav={{
            id: PROFILESELECTION.ADD_PROFILE_BTN,
            nextup: PROFILESELECTION.CARDS_CONTAINER,
            nextdown: PROFILESELECTION.PRIVACY_POLICY,
            parent: PROFILESELECTION.PAGE,
          }}
          useNativeFocus
          onFocus={() => setSpeech(`Add profile ${ROLES.BUTTON}`)}
        >
          Add profile
        </FocusButton>
        <FocusDiv
          className={styles.privacyPolicy}
          classNameFocused={styles.privacyPolicyFocused}
          onClick={() =>
            dispatch(
              modalActions.openModal({ modalId: 'PrivacyAgreementModal' }),
            )
          }
          nav={{
            id: PROFILESELECTION.PRIVACY_POLICY,
            nextup: PROFILESELECTION.ADD_PROFILE_BTN,
            parent: PROFILESELECTION.PAGE,
          }}
          onFocus={() => setSpeech(`Sensical Privacy Policy, ${ROLES.BUTTON}`)}
          useNativeFocus
        >
          Sensical Privacy Policy
        </FocusDiv>
      </div>
    </Page>
  );
};

ProfileSelection.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ProfileSelection);
