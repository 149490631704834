import React, { useEffect } from 'react';
import classNames from 'classnames';
import { withFocus } from '@accedo/vdkweb-navigation';
import PropTypes from 'prop-types';
import styles from './focusDiv.scss';
import { setNativeFocus } from '#/utils/accessibility';

const FocusDiv = ({
  nav,
  noId = false,
  className,
  classNameFocused = '',
  style = {},
  ref,
  children,
  role,
  ariaLabel,
  onClick,
  useNativeFocus = true,
  ...props
}) => {
  useEffect(() => {
    if (!useNativeFocus) {
      return;
    }
    nav?.isFocused && setNativeFocus(nav?.id);
  }, [nav]);

  return (
    <div
      onClick={onClick}
      ref={ref}
      tabIndex={-1}
      id={!noId ? nav.id : undefined}
      className={classNames(className, styles.focusDiv, {
        [classNameFocused]: nav.isFocused,
      })}
      style={style}
      role={role}
      aria-label={ariaLabel}
      {...props}
    >
      {children}
    </div>
  );
};

FocusDiv.propTypes = {
  nav: PropTypes.object,
  noId: PropTypes.bool,
  className: PropTypes.string,
  classNameFocused: PropTypes.string,
  style: PropTypes.object,
  ref: PropTypes.node,
  children: PropTypes.node,
  role: PropTypes.string,
  ariaLabel: PropTypes.string,
  onClick: PropTypes.func,
  useNativeFocus: PropTypes.bool,
};

export default withFocus(FocusDiv);
