import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FocusDiv } from '@accedo/vdkweb-tv-ui';
import { useSelector } from 'react-redux';
import { getHero } from '#/redux/modules/firestore/selectors';
import { getEntryById } from '#/redux/modules/accedoOne/selectors';

import getVw from '#/utils/getVw';
import imgixUrl from '#/utils/imgix';
import { subRoutes } from '#/utils/routes';
import { getHorizontalNav } from '#/utils/navigationHelper';
import {
  containersTypeAlias,
  imgixTileResizeParameters,
  SWIMLANE_LENGTH,
} from '#/config/constants';

import ShowCard from '#/components/ShowCard/ShowCard';
import Carousel from '#/components/Carousel/Carousel';
import styles from './heroSwimlaneFactory.scss';

// hero data loaded on profile switch from TVAppContent
const HeroSwimlaneFactory = ({
  heroItems,
  nav = { id: '' },
  ageTheme,
  history,
  onFocus,
  enablePodcasts = false,
  containerId,
}) => {
  const items = heroItems || useSelector(getHero).items;
  const getShowPlaylistId = useCallback(show => show?.playlist_id || '0', []);
  const { separatorColor, backgroundColor } =
    useSelector(state => getEntryById(state, containerId)) || {};
  const collectionNavMap = useMemo(() => {
    const navigatableItems = items
      .slice(0, SWIMLANE_LENGTH)
      .map((item, index) => `${nav.id}-HERO-${index}`);
    if (items.length > SWIMLANE_LENGTH) {
      navigatableItems.push(`${nav.id}-see-all`);
    }

    return {
      ...getHorizontalNav(
        navigatableItems,
        {
          parent: nav.id,
        },
        true,
      ),
    };
  }, [nav, items]);

  const heroCards = useMemo(() => {
    if (!items?.length) {
      return [];
    }

    const cards = items
      .slice(0, SWIMLANE_LENGTH)
      .sort((itemA, itemB) => (itemA?.position > itemB?.position ? 1 : -1))
      .map((item, index) => (
        <ShowCard
          className={styles.cardContainer}
          nav={collectionNavMap[`${nav.id}-HERO-${index}`]}
          key={`${nav.id}-HERO-${index}`}
          id={item.id}
          name={item.name}
          playlistId={getShowPlaylistId(item)}
          hero
          imgUrl={item.key_art_horizontal_image_path}
          roundedCorners={ageTheme?.tileRounded}
          onItemFocus={onFocus}
          podcastShow={
            enablePodcasts || item?.media_type?.toLowerCase() === 'podcast'
          }
        />
      ));

    if (items.length > SWIMLANE_LENGTH) {
      const path = history.location?.pathname?.split('/');

      // might have to do something to get focus state proper on see all cards
      // since i cant add the focused class to it like this, needs to be its own simple div comp
      // that takes a focusedClass that only applies when nav.isFocused.. for now hover will suffice
      cards.push(
        <FocusDiv
          nav={collectionNavMap[`${nav.id}-see-all`]}
          key={`${nav.id}-see-all`}
          onClick={() =>
            history.push(
              `/${path[1]}/${path[2]}/${subRoutes.SEE_ALL}/${
                items[SWIMLANE_LENGTH].id
              }/${' '}/${containersTypeAlias.CONTAINER_HERO}`,
            )
          }
          className={styles.seeAllShows}
          style={{ borderRadius: ageTheme?.tileRounded ? getVw(24) : '0px' }}
        >
          <div
            className={styles.seeAllImage}
            style={{
              backgroundImage: `url(${imgixUrl(
                items[SWIMLANE_LENGTH].key_art_horizontal_image_path,
                imgixTileResizeParameters.SHOWS,
              )})`,
            }}
          >
            <div />
          </div>
          <div className={styles.seeAllText}>
            <p>See all</p>
          </div>
        </FocusDiv>,
      );
    }

    if (cards.length) {
      cards.unshift(cards.pop());
    }

    return cards;
  }, [items, collectionNavMap]);

  const heroSwimlaneNav = {
    ...nav,
    forwardFocus: `${nav.id}-HERO-0`,
    useLastFocus: true,
  };
  return heroCards.length ? (
    <Carousel
      items={heroCards}
      tileContainerClass={styles.tileExpandHeight}
      nav={heroSwimlaneNav}
      separatorColor={separatorColor}
      backgroundColor={backgroundColor}
    />
  ) : null;
};
HeroSwimlaneFactory.propTypes = {
  heroItems: PropTypes.array,
  nav: PropTypes.object,
  ageTheme: PropTypes.object,
  history: PropTypes.object,
  onFocus: PropTypes.func,
  enablePodcasts: PropTypes.bool,
  containerId: PropTypes.string,
};

export default withRouter(HeroSwimlaneFactory);
