import React, { useMemo, useCallback, useEffect, useState } from 'react';
import ReactScrollDetector from 'react-scroll-detector';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { focusManager } from '@accedo/vdkweb-navigation';
import { Page } from '@accedo/vdkweb-tv-ui';
import { setVideoContent } from '#/redux/modules/appPlayer/actions';
import FavoriteButton from '#/widgets/ui/Buttons/FavoriteButton';
import { FocusDiv } from '#/widgets/ui';

import {
  getCustomDataShowsFavorites,
  getCustomDataTopicsFavorites,
  getCustomDataVideoFavorites,
  getCustomDataTopicsFavoritesById,
} from '#/redux/modules/identity/selectors';

import {
  performTopicQuery,
  performPlaylistQuery,
  clearTopicQuery,
  clearTopicData,
  saveTopicQuery,
  clearPlaylistQuery,
  clearPlaylistData,
  savePlaylistQuery,
  clearAssetData,
  saveRecentlyWatchedQuery,
  clearRecentlyWatchedQuery,
  clearRecentlyWatchedData,
  actions as brightcoveActions,
} from '#/redux/modules/brightcove/actions';
import {
  getCurrentAgeMetadata,
  getRecentlyWatchedLimit,
  getEntryById,
} from '#/redux/modules/accedoOne/selectors';

import {
  getTopicQueryParams,
  getTopicData,
  getPlaylistQueryParams,
  getPlaylistData,
  getContainerData,
  getLoadingState,
  getRecentlyWatchedQueryParams,
  getRecentlyWatchedData,
} from '#/redux/modules/brightcove/selectors';
import {
  getCurrentProfileAgeGroup,
  getCurrentProfileMinAge,
  getCurrentProfileId,
  getCurrentProfile,
} from '#/redux/modules/onboarding/selectors';
import { getParentUserId } from '#/redux/modules/identity/identityUser/selectors';
import { getDeepLinkStarted } from '#/redux/modules/lifecycle/selectors';
import {
  getContainerShows,
  getTopics,
} from '#/redux/modules/firestore/selectors';
import { getHorizontalNav, SEE_ALL } from '#/utils/navigationHelper';
import {
  ageGroupsKeys,
  containersTypeAlias,
  customRoutes,
  ageCategoryGroup,
  videoAnalytics,
  SEE_ALL_SCREEN,
  GUEST_MODAL_TYPE,
  signupStates,
} from '#/config/constants';
import VideoCard from '#/components/VideoCard/VideoCard';
import ShowCard from '#/components/ShowCard/ShowCard';
import TopicCard from '#/components/TopicCard/TopicCard';

import { toggleLoginSpinner } from '#/redux/modules/spinner/actions';

import {
  updateTopicFavoriteById,
  loadCustomDataRecentlyWatched,
} from '#/redux/modules/identity/actions';

import assembleCurrentVideo from '#/utils/assembleCurrentVideo';

import styles from './seeall.scss';
import { isWorkstation, isMobile } from '#/utils/componentStyleConfig';
import useToggleHeader from '#/hooks/useToggleHeader';
import Scroll from '#/components/Scroll/Scroll';
import useUpdateScroll from '#/hooks/useUpdateScroll';

import { getGridItemNav, getItemId } from '#/utils/gridNavUtils';
import { mainRoutes, authFlowRoutes } from '#/utils/routes';
import { TVContentHeader } from '#/components/TVContentHeader/TVContentHeader';

import { isGuestMode } from '#/redux/modules/user/selectors';
import { actions as modalActions } from '#/redux/modules/modal/actions';
import { actions as onboardingActions } from '#/redux/modules/onboarding/actions';
import { logout } from '#/redux/modules/identity/identityGuest/actions';
import BackArrowButton from '#/widgets/ui/Buttons/BackArrowButton';
import {
  // deepLinkQuit,
  exitDeeplinkWorkflow,
} from '#/redux/modules/lifecycle/actions';
import { getIsHeaderActive } from '#/redux/modules/menu/selectors';
import { isContentBlocked } from '../../utils/isContentBlocked';

const { PAGE, CONTAINER, SEE_ALL_FAVORITE_BUTTON } = SEE_ALL;

const navMap = {
  PAGE: {
    id: PAGE,
    forwardFocus: CONTAINER,
  },
  SEE_ALL_FAVORITE_BUTTON: {
    id: SEE_ALL_FAVORITE_BUTTON,
    nextdown: CONTAINER,
    parent: PAGE,
  },
  CONTAINER: {
    id: CONTAINER,
    nextup: SEE_ALL_FAVORITE_BUTTON,
    parent: PAGE,
    forwardFocus: getItemId(0),
  },
};

const {
  CONTAINER_SHOWS,
  CONTAINER_TOPICS,
  CONTAINER_OVP_PLAYLIST,
  CONTAINER_RECENTLY_WATCHED,
  TVCONTAINER_FAV_SHOWS,
  TVCONTAINER_FAV_TOPICS,
  TVCONTAINER_FAV_VIDEOS,
} = containersTypeAlias;

const SeeAll = ({ match, history, location }) => {
  const dispatch = useDispatch();
  const currentProfile = useSelector(getCurrentProfile);
  const guestMode = useSelector(isGuestMode);
  // Hooks related to getting age category
  const selectedAgeMetadata = useSelector(state =>
    getCurrentAgeMetadata(state),
  );

  const selectedAgeGroup = useSelector(state =>
    getCurrentProfileAgeGroup(state),
  );

  const topics = useSelector(getTopics);
  const id = match?.params?.id;
  const title = decodeURIComponent(match?.params?.title);
  const type = match?.params?.type;
  const currentTopic = useMemo(() => {
    return topics.find(t => t.id === id);
  }, [topics, id]);

  // Deep linking ------------------------------------------------------------------
  const currentMinAge = useSelector(getCurrentProfileMinAge);
  const deepLinkStarted = useSelector(getDeepLinkStarted);
  const profileId = useSelector(getCurrentProfileId);
  const parentUserId = useSelector(getParentUserId);
  const topicMinAgeMatch =
    profileId !== parentUserId
      ? currentMinAge >= currentTopic?.ages?.[0]
      : false;
  const onNavigateBack = useMemo(() => {
    return deepLinkStarted ? () => dispatch(exitDeeplinkWorkflow()) : () => {};
  }, [deepLinkStarted]);
  // Deep linking END --------------------------------------------------------------

  // check if playlist is available in topic
  const topicPlaylistId = useMemo(
    () =>
      currentTopic?.playlists?.find(e => e.age === selectedAgeMetadata?.minAge)
        ?.playlist_id || '0',
    [currentTopic, selectedAgeMetadata],
  );

  const playlistId = match?.params?.playlistId || topicPlaylistId;

  if (type === CONTAINER_TOPICS && playlistId !== '0') {
    const [, path1, path2, path3] = location?.pathname?.split('/');
    const pathname = `/${path1}/${path2}/${path3}/${id}/${title}/${CONTAINER_OVP_PLAYLIST}/${playlistId}`;
    history.replace({ pathname }); // redirect using playlist
  }

  const toggleHeader = useToggleHeader();
  const isHeaderActive = useSelector(getIsHeaderActive);
  const [focusedElement, updateScroll] = useUpdateScroll(CONTAINER);

  const topicFavorite = useSelector(state =>
    getCustomDataTopicsFavoritesById(state, id),
  );
  const isTVFavoritesContainer =
    [
      TVCONTAINER_FAV_SHOWS,
      TVCONTAINER_FAV_TOPICS,
      TVCONTAINER_FAV_VIDEOS,
    ].find(container => container === type) !== undefined;

  let items = [];
  let queryParams = {};
  let queryData = {};
  let containerItems = [];
  let loadingState = null;
  let recentlyWatchedPageSize = null;
  let recentlyWatchedTitle = '';
  const [recentlyWatchedReady, setRecentlyWatchedReady] = useState(false);
  switch (type) {
    case CONTAINER_OVP_PLAYLIST:
      items = useSelector(state => getContainerData(state, [id]))[id];
      queryParams = useSelector(state => getPlaylistQueryParams(state));
      queryData = useSelector(state => getPlaylistData(state));
      loadingState = useSelector(state => getLoadingState(state));
      break;
    case CONTAINER_TOPICS:
      queryParams = useSelector(state => getTopicQueryParams(state));
      queryData = useSelector(state => getTopicData(state));
      containerItems =
        useSelector(state =>
          getContainerData(state, [queryData?.containerId]),
        ) || {};
      items =
        containerItems?.hasData && queryData?.containerId
          ? containerItems[queryData?.containerId]
          : [];
      loadingState = useSelector(state => getLoadingState(state));
      break;
    case CONTAINER_SHOWS:
      ({ items } = useSelector(getContainerShows));
      break;
    case TVCONTAINER_FAV_SHOWS:
      items = useSelector(getCustomDataShowsFavorites);
      break;
    case TVCONTAINER_FAV_TOPICS:
      items = useSelector(getCustomDataTopicsFavorites);
      break;
    case TVCONTAINER_FAV_VIDEOS:
      items = useSelector(getCustomDataVideoFavorites);
      break;
    case CONTAINER_RECENTLY_WATCHED: {
      items = useSelector(state => getContainerData(state, [id]))[id];
      queryParams = useSelector(getRecentlyWatchedQueryParams);
      queryData = useSelector(getRecentlyWatchedData);
      loadingState = useSelector(getLoadingState);
      recentlyWatchedPageSize = useSelector(getRecentlyWatchedLimit);
      const { displayText, displayTextForGuestMode } = useSelector(state =>
        getEntryById(state, id),
      );
      recentlyWatchedTitle = guestMode ? displayTextForGuestMode : displayText;
      break;
    }
    default:
      break;
  }

  let ageCategory = ageCategoryGroup.MIN_AGE_EIGHT_UP;

  switch (selectedAgeGroup) {
    case ageGroupsKeys.TWO_UP:
      ageCategory = ageCategoryGroup.MIN_AGE_TWO_UP;
      break;
    case ageGroupsKeys.FIVE_UP:
      ageCategory = ageCategoryGroup.MIN_AGE_FIVE_UP;
      break;
    default:
      ageCategory = ageCategoryGroup.MIN_AGE_EIGHT_UP;
      break;
  }

  if (type === CONTAINER_TOPICS) {
    useEffect(() => {
      if (queryParams.topicId !== id) {
        dispatch(
          saveTopicQuery({
            ...queryParams,
            topicId: id,
            topicTitle: title,
            age: ageCategory,
            limit: queryData.limit,
            offset: 0,
          }),
        );
        return;
      }

      if (
        queryParams.topicId === id &&
        queryParams.topicTitle &&
        queryParams.age
      ) {
        dispatch(performTopicQuery([queryParams]));
      }
    }, [queryParams]);
  } else if (type === CONTAINER_OVP_PLAYLIST) {
    useEffect(() => {
      if (queryParams.playlistId !== playlistId) {
        dispatch(
          savePlaylistQuery({
            ...queryParams,
            id,
            playlistId,
            limit: queryData.limit,
            offset: 0,
          }),
        );
        return;
      }

      if (queryParams.playlistId === playlistId) {
        dispatch(performPlaylistQuery([queryParams]));
      }
    }, [queryParams]);
  } else if (type === CONTAINER_SHOWS) {
    useEffect(() => {
      requestAnimationFrame(() => {
        dispatch(toggleLoginSpinner(false));
      });
    }, []);
  } else if (isTVFavoritesContainer) {
    useEffect(() => {
      requestAnimationFrame(() => {
        dispatch(toggleLoginSpinner(false));
      });
    }, []);
  } else if (type === CONTAINER_RECENTLY_WATCHED) {
    useEffect(() => {
      const prepareSellAll = async () => {
        // Set the containerId on brightcove for SeeAll page
        await dispatch(
          brightcoveActions.saveRecentlyWatchedData({
            containerId: id,
            hasMoreResults: true,
          }),
        );
        // Start with a empty playlist
        await dispatch(brightcoveActions.saveContainerData({ [id]: [] }));
        setRecentlyWatchedReady(true);
      };
      prepareSellAll();
    }, []);
    useEffect(() => {
      if (!recentlyWatchedReady) {
        return;
      }

      if (!queryData.hasMoreResults) {
        requestAnimationFrame(() => {
          dispatch(toggleLoginSpinner(false));
        });
        return;
      }

      if (!queryParams.started) {
        dispatch(
          saveRecentlyWatchedQuery({
            ...queryParams,
            size: recentlyWatchedPageSize,
            page: 0,
            started: true,
          }),
        );
        return;
      }

      dispatch(loadCustomDataRecentlyWatched(id, queryParams, !!items?.length));
    }, [queryParams, recentlyWatchedReady]);
  }

  useEffect(() => {
    if (loadingState) {
      dispatch(toggleLoginSpinner(true));
    } else if (loadingState === false) {
      requestAnimationFrame(() => {
        dispatch(toggleLoginSpinner(false));
      });
    }
  }, [loadingState]);

  useEffect(() => {
    return () => {
      dispatch(clearTopicQuery());
      dispatch(clearTopicData());
      dispatch(clearPlaylistQuery());
      dispatch(clearPlaylistData());
      dispatch(clearRecentlyWatchedQuery());
      dispatch(clearRecentlyWatchedData());
    };
  }, []);

  useEffect(() => {
    if (
      !isWorkstation() ||
      (parentUserId &&
        profileId &&
        parentUserId === profileId &&
        deepLinkStarted)
    ) {
      toggleHeader(false);
    } else {
      toggleHeader(true);
    }
  }, [isHeaderActive, deepLinkStarted]);

  useEffect(() => {
    if (items?.length) {
      focusManager.changeFocus(getItemId(0));
    }
  }, [items]);

  const components = items?.map((item, index) => `ITEM-${index}`) || [];

  const carrouselNavMap = {
    ...getHorizontalNav(components, {}),
  };

  const handleScrollBottom = () => {
    if (
      !loadingState &&
      (queryParams?.page < queryData?.totalPages - 1 ||
        queryData?.hasMoreResults)
    ) {
      const queryObj = {
        ...queryParams,
        /* eslint-disable-next-line no-plusplus */
        page: ++queryParams.page,
      };
      queryObj.offset = queryData.limit * queryParams.page;
      switch (type) {
        case CONTAINER_TOPICS:
          dispatch(saveTopicQuery(queryObj));
          break;
        case CONTAINER_OVP_PLAYLIST:
          dispatch(savePlaylistQuery(queryObj));
          break;
        case CONTAINER_RECENTLY_WATCHED:
          dispatch(saveRecentlyWatchedQuery(queryObj));
          break;
        default:
          break;
      }
    }
  };

  const getImagePath = useCallback(
    item =>
      item?.images?.find(e => e.age === selectedAgeMetadata?.minAge)
        .image_path ||
      item?.image_path ||
      '',
    [selectedAgeMetadata],
  );
  const getPlaylistId = useCallback(
    item =>
      item?.playlists?.find(e => e.age === selectedAgeMetadata?.minAge)
        ?.playlist_id || '0',
    [selectedAgeMetadata],
  );
  const getShowPlaylistId = useCallback(show => show?.playlist_id || '0', []);

  const onToggleTopicFavorite = useCallback(async () => {
    if (guestMode) {
      dispatch(
        modalActions.openModal({
          modalId: 'GuestProfileModal',
          modalData: {
            modalType: GUEST_MODAL_TYPE.favoritesBlocked,
          },
          successAction: () => {
            dispatch(
              onboardingActions.setSignupState({
                signupState: signupStates.CREATE_ACCOUNT,
              }),
            );
            dispatch(logout(authFlowRoutes.ACCOUNT_CREATION));
          },
        }),
      );
    } else {
      requestAnimationFrame(() => {
        dispatch(toggleLoginSpinner(true));
      });
      const topicData = {
        id,
        name: currentTopic?.name,
        image_path: getImagePath(currentTopic),
      };
      await dispatch(
        updateTopicFavoriteById(id, topicFavorite === null, topicData),
      );
      requestAnimationFrame(() => {
        dispatch(toggleLoginSpinner(false));
      });
    }
  }, [currentTopic, topicFavorite, id, getImagePath]);

  return (
    <ReactScrollDetector
      debounceTime={500}
      accuracy={70}
      onScrollBottom={handleScrollBottom}
    >
      <div
        className={classNames(styles.videoOverlay)}
        style={{
          background: selectedAgeMetadata?.colorScheme?.backgroundPrimary,
        }}
      />
      <Page
        nav={navMap.PAGE}
        className={classNames(styles.mainContainer, {
          [styles.isTv]: !isWorkstation(),
        })}
        autofocus={false}
      >
        {isTVFavoritesContainer ? (
          <TVContentHeader
            visible={!isWorkstation()}
            title={`${SEE_ALL_SCREEN.FAVORITES_TITLE_PREFIX} ${title}`}
          />
        ) : (
          <div className={styles.titleContainer}>
            <BackArrowButton
              className={styles.backButton}
              onNavigateBack={onNavigateBack}
              black
            />
            <p className={styles.titleText}>
              {type !== CONTAINER_RECENTLY_WATCHED
                ? currentTopic?.name || title
                : recentlyWatchedTitle || title}
            </p>
            {(type === CONTAINER_TOPICS || type === CONTAINER_OVP_PLAYLIST) && (
              <div className={styles.favoriteContainer}>
                {currentTopic && topicMinAgeMatch && (
                  <FavoriteButton
                    nav={navMap.SEE_ALL_FAVORITE_BUTTON}
                    onClick={onToggleTopicFavorite}
                    isFavorited={topicFavorite !== null}
                    favoriteButtonClassName={classNames(styles.favoriteButton, {
                      [styles.isTv]: !isWorkstation(),
                    })}
                  />
                )}
              </div>
            )}
          </div>
        )}

        <Scroll
          focusedElement={focusedElement}
          enabled={!isWorkstation()}
          focusScrollPush={200}
        >
          <FocusDiv nav={navMap.CONTAINER}>
            <div
              className={classNames({
                [styles.grid]: !isWorkstation() || isMobile(),
              })}
            >
              {items?.map((item, index) => {
                switch (type) {
                  case TVCONTAINER_FAV_SHOWS:
                    return (
                      <ShowCard
                        nav={getGridItemNav(
                          navMap.CONTAINER.id,
                          index,
                          4,
                          items,
                        )}
                        key={getItemId(index)}
                        id={item.id}
                        name={item.name}
                        imgUrl={item.key_art_horizontal_image_path}
                        roundedCorners={selectedAgeMetadata?.tileRounded}
                        onItemFocus={updateScroll}
                      />
                    );
                  case TVCONTAINER_FAV_TOPICS:
                    return (
                      <TopicCard
                        className={classNames(styles.topicCard)}
                        nav={getGridItemNav(
                          navMap.CONTAINER.id,
                          index,
                          4,
                          items,
                        )}
                        key={getItemId(index)}
                        id={item.id}
                        title={item.name}
                        imgUrl={getImagePath(item)}
                        playlistId={getPlaylistId(item)}
                        roundedCorners={selectedAgeMetadata?.tileRounded}
                        onItemFocus={updateScroll}
                        onClick={() =>
                          history.push({
                            pathname: `${mainRoutes.SEE_ALL_EXPLORE}/${item.id}/${item.name}/${CONTAINER_TOPICS}`,
                          })
                        }
                      />
                    );
                  case TVCONTAINER_FAV_VIDEOS:
                    return (
                      <VideoCard
                        nav={
                          !isWorkstation()
                            ? getGridItemNav(
                                navMap.CONTAINER.id,
                                index,
                                4,
                                items,
                              )
                            : carrouselNavMap[getItemId(index)]
                        }
                        key={getItemId(index)}
                        imgUrl={
                          item.custom_fields?.episode_key_art_horizontal ||
                          item.thumbnail
                        }
                        title={item.name}
                        referenceId={item.reference_id}
                        showTitle={item.custom_fields?.subtitle}
                        duration={item.duration}
                        classes={{
                          videoCardInfo: styles.videoCardInfo,
                          videoCardProgress: styles.videoCardProgress,
                          videoCardImage: styles.videoCardImage,
                          videoCard: classNames(styles.videoCard, {
                            [styles.isTv]: !isWorkstation(),
                          }),
                        }}
                        roundedCorners={selectedAgeMetadata?.tileRounded}
                        onClick={() => {
                          dispatch(clearAssetData());
                          dispatch(
                            setVideoContent({
                              playlists: [queryData?.containerId],
                              currentPlaylistIndex: 0,
                              currentVideoIndex: index,
                              currentVideo: assembleCurrentVideo(item),
                              initiation: videoAnalytics.initiation.MANUAL,
                            }),
                          );
                        }}
                        onItemFocus={updateScroll}
                        blocked={isContentBlocked(currentProfile, item)}
                      />
                    );
                  case CONTAINER_RECENTLY_WATCHED:
                  case CONTAINER_OVP_PLAYLIST:
                  case CONTAINER_TOPICS:
                    return (
                      <VideoCard
                        nav={
                          !isWorkstation()
                            ? getGridItemNav(
                                navMap.CONTAINER.id,
                                index,
                                4,
                                items,
                              )
                            : carrouselNavMap[getItemId(index)]
                        }
                        key={getItemId(index)}
                        imgUrl={
                          item.custom_fields?.episode_key_art_horizontal ||
                          item?.custom_fields?.episode_key_art_square ||
                          item.thumbnail
                        }
                        title={item.name}
                        referenceId={item.reference_id}
                        showTitle={item.custom_fields?.subtitle}
                        duration={item.duration}
                        classes={{
                          videoCardInfo: styles.videoCardInfo,
                          videoCardProgress: styles.videoCardProgress,
                          videoCardImage: styles.videoCardImage,
                          videoCard: classNames(styles.videoCard, {
                            [styles.isTv]: !isWorkstation(),
                          }),
                        }}
                        roundedCorners={selectedAgeMetadata?.tileRounded}
                        onClick={() => {
                          dispatch(clearAssetData());
                          dispatch(
                            setVideoContent({
                              playlists: [queryData?.containerId],
                              currentPlaylistIndex: 0,
                              currentVideoIndex: index,
                              currentVideo: assembleCurrentVideo(item),
                              initiation: videoAnalytics.initiation.MANUAL,
                            }),
                          );
                        }}
                        onItemFocus={updateScroll}
                        podcastShow={
                          item.custom_fields?.media_type?.toLowerCase() ===
                          'podcast'
                        }
                        blocked={isContentBlocked(currentProfile, item)}
                      />
                    );
                  case CONTAINER_SHOWS:
                    return (
                      <Link to={`${customRoutes.SHOW_DETAILS}/${item?.id}`}>
                        <ShowCard
                          nav={
                            !isWorkstation()
                              ? getGridItemNav(
                                  navMap.CONTAINER.id,
                                  index,
                                  4,
                                  items,
                                )
                              : carrouselNavMap[getItemId(index)]
                          }
                          key={getItemId(index)}
                          id={item.id}
                          name={item.name}
                          imgUrl={item.key_art_horizontal_image_path}
                          onItemFocus={updateScroll}
                          playlistId={getShowPlaylistId(item)}
                          podcastShow={
                            item?.media_type?.toLowerCase() === 'podcast'
                          }
                        />
                      </Link>
                    );
                  default:
                    return null;
                }
              })}
            </div>
          </FocusDiv>
        </Scroll>
      </Page>
    </ReactScrollDetector>
  );
};

SeeAll.propTypes = {
  match: PropTypes.any,
  history: PropTypes.object,
  location: PropTypes.object,
};

export default withRouter(SeeAll);
