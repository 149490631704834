import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

import config from '#/config';
import reduxModules from '#/redux/modules';
import TVAppContent from './TVAppContent';
import { forceNewSession } from '#/redux/modules/accedoOne/accedoOne';
import { loadAvatars } from '#/redux/modules/firestore/actions';
import { getUser } from '#/redux/modules/identity/actions';
import LandscapeModal from '../../widgets/ui/LandscapeModal/LandscapeModal';

const { titleTemplate, meta } = config.app.head;
const { actions } = reduxModules.lifecycle;
const {
  actions: { setClient, loadAllAssets, loadAllEntries, loadAllMetadata },
} = reduxModules.accedoOne;

// An application shell for listening to mount
// events and trigger appropriate lifecycle
// actions for your app. These would typically
// be used for analytics and similar.
class AppShell extends Component {
  async componentDidMount() {
    await forceNewSession(this.props.dispatch, setClient);
    await Promise.all([
      await this.props.dispatch(loadAllMetadata),
      await this.props.dispatch(getUser()),
    ]);
    await Promise.all([
      this.props.dispatch(loadAllAssets),
      this.props.dispatch(loadAllEntries),
      this.props.dispatch(loadAvatars),
    ]);
    this.appStart();
  }

  componentWillUnmount() {
    this.appQuit();
  }

  appStart() {
    this.props.dispatch(actions.appStart());
  }

  appQuit() {
    this.props.dispatch(actions.appQuit());
  }

  render() {
    return (
      <div>
        <Helmet
          defaultTitle="Sensical"
          titleTemplate={titleTemplate}
          meta={meta}
          style={[
            {
              type: 'text/css',
              cssText: `
                body, html {
                    width: 100%;
                    height: 100%;
                    margin: 0 auto;
                }
            `,
            },
          ]}
        />
        <LandscapeModal />
        <TVAppContent {...this.props} />
      </div>
    );
  }
}

AppShell.propTypes = {
  dispatch: PropTypes.func,
};

export default connect()(AppShell);
