import { REDUX_STORE_SAVED_KEYS } from '#/config/constants';

const KEY_SEPARATOR = ':';

const MemoryStorage = {
  setItem(item) {
    this.item = item;
  },
  getItem() {
    return this.item || 'null';
  },
};

const getStorageEngine = () => {
  const isBrowser = typeof window !== 'undefined';
  return isBrowser ? localStorage : MemoryStorage;
};

const storage = getStorageEngine();

export const persistenceLayer = (reducer, initialState, ACTION_PREFIX) => (
  state,
  action,
) => {
  if (action.type.startsWith(ACTION_PREFIX)) {
    return reducer(state, action);
  }
  if (state) {
    // State passed but without calling store action
    return {
      ...initialState,
      ...state,
    };
  }
  return initialState;
};

export const loadState = (initState = {}) => {
  REDUX_STORE_SAVED_KEYS.forEach(keyPath => {
    const serializedKey = keyPath.join(KEY_SEPARATOR);
    const serializedValue = storage.getItem(serializedKey);
    const deserializedValue = JSON.parse(serializedValue);

    keyPath.reduce((currentSegment, key) => {
      // Reached the last key, nextSegment is the leaf
      if (key === keyPath.slice(-1)[0]) {
        if (deserializedValue !== null) {
          currentSegment[key] = deserializedValue;
        }
        return currentSegment[key];
      }

      // Not reached tree leaf, iterate
      currentSegment[key] = currentSegment?.[key] || {};
      return currentSegment[key];
    }, initState);
  });
  return initState;
};

export const stateSaver = (lastState = {}) => newState => {
  REDUX_STORE_SAVED_KEYS.forEach(keyPath => {
    const [lastValue, newValue] = keyPath.reduce(
      ([stateA, stateB], key) => [stateA?.[key], stateB?.[key]],
      [lastState, newState],
    );

    // Unchanged value for the keypath, nothing to save
    if (lastValue === newValue) {
      return;
    }

    const serializedKey = keyPath.join(KEY_SEPARATOR);
    const serializedValue = JSON.stringify(
      newValue !== undefined ? newValue : null,
    );
    storage.setItem(serializedKey, serializedValue);
  });
  lastState = newState;
};
