import { createActions } from 'redux-actions';

import { createFetchAction } from '#/redux/modules/async/actions';

const LOGOUT_USER = 'LOGOUT_USER';
const LOGIN_RECEIVED = 'LOGIN_RECEIVED';
const AUTH_CODE_SUCCESS = 'AUTH_CODE_SUCCESS';
const REFRESH_TOKEN = 'REFRESH_TOKEN';
const SET_AGE_VERIFIED = 'SET_AGE_VERIFIED';
const RESET_USER_STATE = 'RESET_USER_STATE';
const SET_LOGOUT_REDIRECT_PATH = 'SET_LOGOUT_REDIRECT_PATH';
const CLEAR_LOGOUT_REDIRECT_PATH = 'CLEAR_LOGOUT_REDIRECT_PATH';
const ENABLE_GUEST_MODE = 'ENABLE_GUEST_MODE';
const DISABLE_GUEST_MODE = 'DISABLE_GUEST_MODE';

const actionOptions = {
  prefix: 'quibi/user',
};

export const actions = createActions(
  LOGIN_RECEIVED,
  REFRESH_TOKEN,
  AUTH_CODE_SUCCESS,
  LOGOUT_USER,
  SET_AGE_VERIFIED,
  RESET_USER_STATE,
  SET_LOGOUT_REDIRECT_PATH,
  CLEAR_LOGOUT_REDIRECT_PATH,
  ENABLE_GUEST_MODE,
  DISABLE_GUEST_MODE,
  actionOptions,
);
export const ASYNC_LOGIN = 'login';
export const ASYNC_REFRESH = 'refresh';

const logInAction = () => {
  // return quibi.login({
  //   clientId,
  //   username: email,
  //   password,
  // });
};

export const login = createFetchAction(
  ASYNC_LOGIN,
  actions.loginReceived,
  logInAction,
);

export const { authCodeSuccess } = actions;

export const { logoutUser } = actions;
