import { useCallback, useState } from 'react';
import { focusManager } from '@accedo/vdkweb-navigation';

import useLatest from './useLatest';

function useUpdateScroll() {
  const [activeElement, setActiveElement] = useState();
  const currentElementIdRef = useLatest(
    activeElement ? activeElement.getAttribute('id') : '',
  );

  const updateScroll = useCallback(explicitId => {
    const activeElementId = explicitId || focusManager.getCurrentFocus();
    const currentElementId = currentElementIdRef.current;
    if (activeElementId !== currentElementId) {
      setActiveElement(document.getElementById(activeElementId));
    }
  }, []);

  return [activeElement, updateScroll];
}

export default useUpdateScroll;
