import React from 'react';
import { useSelector } from 'react-redux';
// import cx from 'classnames';
import { getModalData } from '#/redux/modules/modal/selectors';
import BaseModal from '../../common/BaseModal/BaseModal';
import styles from './AboutAgeGroupModal.scss';
import blankAvatar from '#/static/images/icons/selectAvatar.svg';

// focus handling
import FocusModal from '../../common/FocusModal/FocusModal';
import { FocusDiv } from '#/widgets/ui';
import { isWorkstation } from '#/utils/componentStyleConfig';

const AboutAgeGroupModal = () => {
  const { imageUrl, minAge, maxAge, info1, info2, info3 } = useSelector(
    getModalData,
  );
  const infoBlocks = info2.split('·');

  return (
    <FocusModal>
      <BaseModal showSaveButton={false} showCloseX={!!isWorkstation}>
        <FocusDiv className={styles.container}>
          <div className={styles.modalImage}>
            <img alt="profile" src={imageUrl || blankAvatar} />
          </div>
          <h1 className={styles.title}>{`Ages ${minAge}-${maxAge}`}</h1>
          <div className={styles.textBlock}>
            <p>{info1}</p>
            <div className={styles.textBlockList}>
              <ul>
                {infoBlocks
                  .filter(infoText => infoText.length > 1)
                  .map(infoBlock => (
                    <li>{infoBlock}</li>
                  ))}
              </ul>
            </div>
            <p>{info3}</p>
          </div>
        </FocusDiv>
      </BaseModal>
    </FocusModal>
  );
};

export default AboutAgeGroupModal;
