/* eslint-disable no-undef */

import md5 from 'md5';
import { palSdkSource } from '#/config/constants';

const isClient = typeof window !== 'undefined';

// Load Google PAL SDK scripts
const loadPalSdk = async () => {
  if (!isClient) {
    throw new Error('PAL sdk is only available in the browser');
  }

  await new Promise((resolve, reject) => {
    const palScript = document.createElement('script');
    palScript.src = palSdkSource;
    palScript.onload = () => {
      resolve();
    };
    palScript.onerror = () => {
      reject();
    };
    document.head.appendChild(palScript);
  });

  const consentSettings = new goog.pal.ConsentSettings();
  consentSettings.allowStorage = false;
  return new goog.pal.NonceLoader(consentSettings);
};

const pNonceLoader = loadPalSdk().catch(e => {
  if (isClient) {
    throw e;
  }
});

const generateNonce = async ppid => {
  const nonceLoader = await pNonceLoader;
  const request = new goog.pal.NonceRequest();
  request.adWillAutoPlay = true;
  request.adWillPlayMuted = true;
  request.continuousPlayback = false;
  request.descriptionUrl = 'https://sensical.com';
  request.iconsSupported = true;
  request.playerType = 'videojs';
  request.playerVersion = '7.11.4';
  request.ppid = ppid;
  request.videoHeight = 480;
  request.videoWidth = 640;

  const manager = await nonceLoader.loadNonceManager(request);
  return manager.getNonce();
};

// Manage ad parameters
export const buildAdTagParameters = async (userId, ageRange) => {
  const ppid = md5(userId);
  const pall = await generateNonce(ppid).catch(() => null);
  const age = ageRange;

  // Device identifier
  const rdid = md5(navigator.userAgent);
  const is_lat = '0';
  const idtype = 'msai';

  const adTagVariables = {
    ppid,
    ...(pall && { pall }),
    age,

    rdid,
    is_lat,
    idtype,
  };
  return new URLSearchParams(adTagVariables).toString();
};
