import React from 'react';

const PageNotFound = () => {
  return (
    <div
      style={{
        backgroundColor: 'white',
        position: 'absolute',
        top: '0px',
        bottom: '0px',
        width: '100%',
      }}
    >
      <h1>Page not found!</h1>
    </div>
  );
};

export default PageNotFound;
