import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import xdk from '@accedo/xdk-core';

import { toggleLoginSpinner } from '#/redux/modules/spinner/actions';
import {
  setVideoPlayTime,
  startPlaybackTimer,
  stopPlaybackTimer,
} from '#/redux/modules/appPlayer/actions';
import { getVideoCaptionEnabled } from '#/redux/modules/appPlayer/selectors';
import ClosedCaption from '#/components/Player/ClosedCaption';
import styles from './LivePlayer.scss';
import { playerConstants } from '#/config/constants';
import { isPortable } from '#/utils/componentStyleConfig';
import TilePlayIcon from '#/static/images/icons/tile-play.png';

const getPlayer = () => xdk.media?.__player?.getPlayerElement() || null;
const playDelay = 5 * 1000;

const LivePlayer = ({ url }) => {
  const dispatch = useDispatch();
  const videoContainerRef = useRef();
  const ccEnabled = useSelector(getVideoCaptionEnabled);
  const playButtonContainer = useRef(null);

  const onStateChanged = async ({ from, to }) => {
    const MEDIASTATE = xdk.CONSTANT.MEDIA.STATE;
    if (from === MEDIASTATE.CONNECTING && to !== MEDIASTATE.STOPPED) {
      // loaded live stream, load subtitles if they exist and set live stream start play time
      await xdk.media.getSubtitles();
      if (ccEnabled) {
        await xdk.media.showSubtitle(0);
      }
      dispatch(setVideoPlayTime());
    }
    if (to === MEDIASTATE.PLAYING) {
      dispatch(startPlaybackTimer());
    } else if (to === MEDIASTATE.STOPPED || to === MEDIASTATE.PAUSED) {
      dispatch(stopPlaybackTimer());
    }
  };

  useEffect(() => {
    if (url && videoContainerRef.current) {
      xdk.media?.load(url, {
        parentNode: videoContainerRef.current,
        live: true,
      });
      xdk.environment.addEventListener(
        xdk.environment.MEDIA.STATE_CHANGED,
        onStateChanged,
      );

      requestAnimationFrame(() => {
        dispatch(toggleLoginSpinner(false));
      });

      const container = videoContainerRef.current?.firstChild;
      // const video = container?.firstChild; // uncomment if needed
      const play = () => {
        getPlayer()?.play();
      };

      if (isPortable()) {
        container?.classList?.add?.(playerConstants.MOBILE_LIVE);
      } else {
        playButtonContainer.current = document.createElement('div');
        const playButton = document.createElement('img');
        const spinner = container.querySelector('.vjs-loading-spinner');

        playButton.src = TilePlayIcon;
        playButton.alt = 'Play';
        playButton.addEventListener('click', play);
        playButton.classList.add('play-button');
        playButtonContainer.current.classList.add(styles.playButtonContainer);

        playButtonContainer.current.appendChild(playButton);
        container.insertBefore(playButtonContainer.current, spinner);
      }

      return () => {
        if (container.contains(playButtonContainer.current)) {
          playButtonContainer.current?.children?.[0]?.removeEventListener(
            'click',
            play,
          );
          container.removeChild(playButtonContainer.current);
          playButtonContainer.current = null;
        }
        xdk.environment.removeEventListener(
          xdk.environment.MEDIA.STATE_CHANGED,
          onStateChanged,
        );
        xdk.media?.deinit();
      };
    }
    return () => xdk.media?.deinit();
  }, [url, videoContainerRef.current]);

  useEffect(() => {
    if (isPortable()) {
      return;
    }

    const playTimer = setTimeout(async () => {
      const container = videoContainerRef.current?.firstChild;
      if (!container) {
        return;
      }

      try {
        // Try play. An error is thrown if autoplay is blocked.
        getPlayer()
          ?.play()
          .catch(() => {
            // Hide spinner so user is more prompt to click on the play button.
            container
              ?.querySelector('.vjs-loading-spinner')
              .classList.add('vjs-hidden');
          });
      } catch (error) {
        // Supress any unexpected error
      }
    }, playDelay);
    return () => clearTimeout(playTimer);
  }, []);

  return (
    <>
      <div className={styles.container} ref={videoContainerRef} />
      {ccEnabled && <ClosedCaption />}
    </>
  );
};

LivePlayer.propTypes = {
  url: PropTypes.string,
};

export default LivePlayer;
