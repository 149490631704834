export const isContentBlocked = (profile, contentItem) => {
  const showBlocked =
    profile?.showsBlocked?.find(
      show => show?.id === contentItem?.custom_fields?.show_id,
    ) != null;
  const videoBlocked =
    profile?.videosBlocked?.find(
      video => video?.reference_id === contentItem?.reference_id,
    ) != null;

  return showBlocked || videoBlocked;
};
