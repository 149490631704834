import xdk from '@accedo/xdk-core';

export const isWorkstation = () => {
  return xdk.platform === 'workstation';
};

export const isVIZIO = () => {
  return /VIZIO|SmartCast|Conjure/i.test(navigator.userAgent);
};

export const isLowEndDevice = () =>
  navigator.userAgent.includes('mtka') || navigator.userAgent.includes('mtke');

export const isTizen = () => {
  return (
    /Tizen/i.test(navigator.userAgent) && /SAMSUNG/i.test(navigator.userAgent)
  );
};

const mobileRegex = /iPhone|iPad|iPod|Android/i;

// without the check for window it may crash at compiling
// if used outside a component -.-
export const isMobile = () =>
  typeof window !== 'undefined' && mobileRegex.test(navigator.userAgent);

// iPad on iOS 13 detection
const isiPad = () =>
  navigator.userAgent.includes('Mac') && 'ontouchend' in document;

export const isiOS = () => {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.userAgent) || isiPad()
  );
};

export const isAndroid = () => /Android/i.test(navigator.userAgent);

// Use same media query in JS to programatically detect touch capabilities
export const isTouchDevice = () =>
  typeof window !== 'undefined' &&
  window.matchMedia(
    '(hover: none) and (pointer: coarse), (hover: hover) and (pointer: coarse)',
  ).matches;

export const isPortable = () => isMobile() || isiOS() || isTouchDevice();

export const isSmallScreen = () =>
  window.matchMedia('(orientation: portrait) and (max-width: 480px)').matches ||
  window.matchMedia('(orientation: landscape) and (max-height: 480px)').matches;

export const isLandscapeScreen = () =>
  window.matchMedia('(orientation: landscape)').matches;
export const isSmallDesktopScreen = () =>
  window.matchMedia('(max-width: 1024px)').matches;
export const isGeneralDesktopScreen = () =>
  window.matchMedia('(max-width: 1280px)').matches;
export const isMediumDesktopScreen = () =>
  window.matchMedia('(max-width: 1728px)').matches;
export const isLargeDesktopScreen = () =>
  window.matchMedia('(min-width: 1729px)').matches;

export const isChromeBrowserIos = () =>
  /Chrome/.test(navigator.userAgent) &&
  /iP(ad|hone|od)/.test(navigator.userAgent);
