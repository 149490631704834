const getLinearNav = (list, nav, loop = false, isHorizontal) =>
  list.reduce((navMap, navId, i) => {
    const isFirst = i === 0;
    const isLast = i === list.length - 1;
    const nextIndex = isLast ? 0 : i + 1;
    const prevIndex = isFirst ? list.length - 1 : i - 1;

    return {
      ...navMap,
      [navId]: {
        id: navId,
        ...((loop || !isFirst) && {
          [isHorizontal ? 'nextleft' : 'nextup']: list[prevIndex],
        }),
        ...((loop || !isLast) && {
          [isHorizontal ? 'nextright' : 'nextdown']: list[nextIndex],
        }),
        ...nav,
      },
    };
  }, {});

export const getHorizontalNav = (list, nav, loop) =>
  getLinearNav(list, nav, loop, true);

export const getVerticalNav = (list, nav, loop) =>
  getLinearNav(list, nav, loop, false);

export const HEADER = {
  CONTAINER: 'HEADER_CONTAINER',
  MENU: 'HEADER_MENU',
  PROFILE_BUTTON: 'PROFILE_BUTTON',
  MENU_ITEMS: {
    HOME: 'HEADER_MENU_HOME',
    LIVE_TV: 'HEADER_MENU_LIVE_TV',
    EXPLORE: 'HEADER_MENU_EXPLORE',
    FAVORITES: 'HEADER_MENU_FAVORITES',
  },
  PARENT_ZONE_BUTTON: 'PARENT_ZONE_BUTTON',
};

export const MOBILEBOTTOMTABBAR = {
  CONTAINER: 'BOTTOM_TAB_BAR_CONTAINER',
  MENU_ITEMS: {
    HOME: 'HEADER_MENU_HOME',
    LIVE_TV: 'HEADER_MENU_LIVE_TV',
    EXPLORE: 'HEADER_MENU_EXPLORE',
    FAVORITES: 'HEADER_MENU_FAVORITES',
  },
};

export const WELCOME = {
  PAGE: 'WELCOME_PAGE',
  CONTAINER: 'WELCOME_CONTAINER',
  SIGNUP_BUTTON: 'SIGNUP_BUTTON',
  SIGNIN_BUTTON: 'SIGNIN_BUTTON',
};

export const SIGNUP = {
  PAGE: 'SIGNUP_PAGE',
  CONTAINER: 'SIGNUP_CONTAINER',
  NEWCODE_BUTTON: 'SIGNUP_NEWCODE_BUTTON',
};

export const SIGNIN = {
  PAGE: 'SIGNIN_PAGE',
  CONTAINER: 'SIGNIN_CONTAINER',
  SIGNIN_CONTAINER_LEFT: 'SIGNIN_CONTAINER_LEFT',
  SIGNIN_CONTAINER_RIGHT: 'SIGNIN_CONTAINER_RIGHT',
  EMAIL_INPUT: 'EMAIL_INPUT',
  PASSWORD_INPUT: 'PASSWORD_INPUT',
  SIGNIN_BUTTON: 'SIGNIN_BUTTON',
  NEWCODE_BUTTON: 'NEWCODE_BUTTON',
  KEYBOARD: 'KEYBOARD',
};

export const PROFILECREATION = {
  PAGE: 'PROFILECREATION_PAGE',
  CONTAINER: 'PROFILECREATION_CONTAINER',
  FORM: 'PROFILECREATION_FORM',
  NAME_INPUT: 'NAME_INPUT',
  AGE_INPUT: 'AGE_INPUT',
  BIRTHDAY_INPUT: 'BIRTHDAY_INPUT',
  SELECT_AVATAR: 'SELECT_AVATAR',
  SAVE_BUTTON: 'SAVE_BUTTON',
  ADD_ANOTHER_BUTTON: 'ADD_ANOTHER_BUTTON',
  KEYBOARD: 'KEYBOARD',
  PAGE_DESCRIPTION: 'PAGE_DESCRIPTION',
};

export const PROFILESELECTION = {
  PAGE: 'PROFILESELECTION_PAGE',
  CARDS_CONTAINER: 'PROFILESELECTION_CARDS_CONTAINER',
  CARD: 'PROFILESELECTION_CARD',
  CHANGE_AVATAR_BTN: 'PROFILESELECTION_CHANGE_AVATAR_BUTTON',
  ADD_PROFILE_BTN: 'PROFILESELECTION_ADD_PROFILE_BUTTON',
  PRIVACY_POLICY: 'PROFILESELECTION_PRIVACY_POLICY',
};

export const AGEMODAL = {
  SAVE_BUTTON: 'AGE_MODAL_SAVE_BUTTON',
  KEYPAD: 'AGE_MODAL_KEYPAD',
  LAYOUT: `key-2 key-3 key-4
           key-5 key-6 key-7
           key-8 key-9 key-10`,
  navOverride: {
    parent: 'AGE_MODAL_CONTAINER',
    id: 'AGE_MODAL_SAVE_BUTTON',
    nextup: 'AGE_MODAL_KEYPAD',
  },
};

export const MONTHMODAL = {
  SAVE_BUTTON: 'MONTH_MODAL_SAVE_BUTTON',
  KEYPAD: 'MONTH_MODAL_KEYPAD',
  LAYOUT: `key-Jan key-Feb key-Mar
           key-Apr key-May key-Jun
           key-Jul key-Aug key-Sep
           key-Oct key-Nov key-Dec`,
  navOverride: {
    parent: 'MONTH_MODAL_CONTAINER',
    id: 'MONTH_MODAL_SAVE_BUTTON',
    nextup: 'MONTH_MODAL_KEYPAD',
  },
};

export const AVATARMODAL = {
  SAVE_BUTTON: 'AVATAR_MODAL_SAVE_BUTTON',
  SELECT: 'AVATAR_MODAL_SELECT',
  navOverride: {
    parent: 'AVATAR_MODAL_CONTAINER',
    id: 'AVATAR_MODAL_SAVE_BUTTON',
    nextup: 'AVATAR_MODAL_SELECT',
  },
};

export const HOME = {
  PAGE: 'HOME_PAGE',
  CONTAINER: 'HOME_CONTAINER',
  CAROUSEL_CONTAINER: 'CAROUSEL_CONTAINER',
};

export const EXPLORE = {
  PAGE: 'EXPLORE_PAGE',
  CONTAINER: 'EXPLORE_CONTAINER',
  TOP_SHOWS_CONTAINER: 'TOP_SHOWS_CONTAINER',
  TABS_CONTAINER: 'TABS_CONTAINER',
  TAB_ITEM_0: 'TAB_ITEM_0',
  GRID_CONTAINER: 'GRID_CONTAINER',
};

export const FAVORITES = {
  PAGE: 'FAVORITES_PAGE',
  CONTAINER: 'FAVORITES_CONTAINER',
  FAV_SHOWS_CONTAINER: 'FAV_SHOWS_CONTAINER',
  FAV_PODCASTS_CONTAINER: 'FAV_PODCASTS_CONTAINER',
  FAV_TOPICS_CONTAINER: 'FAV_TOPICS_CONTAINER',
  FAV_VIDEOS_CONTAINER: 'FAV_VIDEOS_CONTAINER',
  FAV_PODCASTS_EPISODES_CONTAINER: 'FAV_PODCASTS_EPISODES_CONTAINER',
};

export const LIVE_TV = {
  PAGE: 'LIVE_TV_PAGE',
  CONTAINER: 'LIVE_TV_CONTAINER',
  PLAYER_CC_BUTTON: 'LIVE_CC_BUTTON',
};

export const PLAYER = {
  CONTAINER: 'PLAYER_CONTAINER',
  CONTROL: 'PLAYER_CONTROL',
  TRANSPORT_CONTROLS: 'PLAYER_TRANSPORT_CONTROLS',
  PLAY_PAUSE: 'PLAYER_PLAY_PAUSE',
  PREV_TRACK: 'PREV_TRACK',
  NEXT_TRACK: 'NEXT_TRACK',
  RW: 'PLAYER_RW',
  FF: 'PLAYER_FF',
  CAROUSEL_CONTAINER: 'PLAYER_CAROUSEL_CONTAINER',
  UP_NEXT_VIDEO: 'PLAYER_UP_NEXT_VIDEO',
  FOCUS_SINK: 'PLAYER_FOCUS_SINK',
};

export const PLAYER_TOP_OVERLAY = {
  CONTAINER: 'PLAYER_TOP_CONTAINER',
  CC_BUTTON: 'PLAYER_CC_BUTTON',
  FAV_BUTTON: 'PLAYER_FAV_BUTTON',
  ALL_EPISODES_BUTTON: 'PLAYER_ALL_EPISODES_BUTTON',
  HIDE_BUTTON: 'PLAYER_HIDE_BUTTON',
};

export const SEE_ALL = {
  PAGE: 'SEE_ALL_PAGE',
  CONTAINER: 'SEE_ALL_CONTAINER',
  SEE_ALL_HEADER: 'SEE_ALL_HEADER',
  SEE_ALL_FAVORITE_BUTTON: 'SEE_ALL_FAVORITE_BUTTON',
};

export const CONTENT_DETAILS = {
  PAGE: 'CONTENT_DETAILS_PAGE',
  CONTAINER: 'CONTENT_DETAILS',
  FAVORITE_BUTTON: 'FAVORITE_BUTTON',
  TABS: 'TABS',
  TAB_ITEM_0: 'TAB_ITEM_0',
  TABVIEW_CONTAINER: 'TABVIEW_CONTAINER',
  TAGS_CONTAINER: 'TAGS_CONTAINER',
  BENEFIT_MODAL: 'BENEFIT_MODAL',
};

export const PARENT_ZONE = {
  PAGE: 'PARENT_ZONE',
  CONTAINER: 'PARENT_ZONE_CONTAINER',
  PARENT_ZONE_MENU_CONTAINER: 'PARENT_ZONE_MENU_CONTAINER',
  PARENT_ZONE_MODAL_CONTAINER: 'PARENT_ZONE_MODAL_CONTAINER',
  PARENT_ZONE_SIGN_OUT_BTN: 'PARENT_ZONE_SIGN_OUT_BTN',
  PARENTZONE_TV_MENU_ITEM: 'PARENTZONE_TV_MENU_ITEM',
};

export const TV_CONTENT_MODAL = {
  CONTAINER: 'TV_CONTENT_MODAL',
  TV_CONTENT_MODAL_ACTION_BUTTON: 'TV_CONTENT_MODAL_ACTION_BUTTON',
  TV_CONTENT_MODAL_CANCEL_BUTTON: 'TV_CONTENT_MODAL_CANCEL_BUTTON',
  CONTENT_AREA: 'CONTENT_AREA',
};
export const AGE_UP_MODAL = {
  CONTAINER: 'AGE_UP_MODAL',
  PARENT_INFO_MODAL: 'PARENT_INFO_MODAL',
  WATCH_NOW: 'WATCH_NOW',
  CONTENT_AREA: 'CONTENT_AREA',
};

export const WELCOME_MODAL = {
  CONTAINER: 'WELCOME_MODAL',
  CONTENT_AREA: 'CONTENT_AREA',
  WATCH_NOW: 'WATCH_NOW',
};

export const BLOCKED_CONTENT_MODAL = {
  CONTAINER: 'BLOCKED_CONTENT_MODAL',
  CANCEL_BUTTON: 'CANCEL_BUTTON',
};

export const GUEST_PROFILE_MODAL = {
  SIGN_IN_LINK: 'GUEST_PROFILE_SIGN_IN_LINK',
};

export const GUEST_PROFILE_SELECTION = {
  GUEST_PROFILE_PAGE: 'GUEST_PROFILE_PAGE',
  GUEST_PROFILE_CONTAINER: 'GUEST_PROFILE_CONTAINER',
  GUEST_PROFILE_FOOTER: 'GUEST_PROFILE_FOOTER',
  SIGN_IN_LINK: 'SIGN_IN_LINK',
  PROFILE_CARDS_CONTAINER: 'CARDS_CONTAINER',
  PROFILE_CARD: 'PROFILE_CARD',
  SIGN_UP_LINK: 'SIGN_UP_LINK',
};

export const FAVORITES_PAGE = {
  CONTAINER: 'CONTAINER',
  SIGN_UP_LINK: 'SIGN_UP_LINK',
  SIGN_IN_LINK: 'SIGN_IN_LINK',
};

export const VIZIO_KEYBOARD = 'VIZIO_KEYBOARD';

const navIdMap = {
  TEST_PAGE: 'TEST_PAGE',
  EPG_PAGE: 'EPG_PAGE',
  MENU: {
    HEADER: {
      CONTAINER: 'HEADER_CONTAINER',
      MENU: 'HEADER_MENU',
      USER_CONTROL: 'USER_CONTROL',
      SEARCH_BUTTON: 'SEARCH_BUTTON',
      SETTING_BUTTON: 'SETTING_BUTTON',
    },
    FOOTER: {
      MENU: 'FOOTER_MENU',
    },
  },
  PAGE: {
    CONTAINER: {
      PAGE: 'CONTAINER_PAGE',
      LAYOUT: 'CONTAINER_LAYOUT',
    },
    MOVIE_DETAILS: {
      PAGE: 'MOVIE_DETAILS_PAGE',
      CONTAINER: 'MOVIE_DETAILS_CONTAINER',
      COVER_IMAGE: 'MOVIE_DETAILS_COVER_IMAGE',
      TRAILER_BUTTON: 'MOVIE_DETAILS_TRAILER_BUTTON',
      FAVOURITE_BUTTON: 'MOVIE_DETAILS_FAVOURITE_BUTTON',
      CAROUSEL: 'MOVIE_DETAILS_CAROUSEL',
    },
    LIVE: {
      PAGE: 'LIVE_PAGE',
    },
  },
  PLAYER: {
    CONTROL: 'PLAYER_CONTROL',
    PLAY_PAUSE: 'PLAYER_PLAY_PAUSE',
    RW: 'PLAYER_RW',
    FF: 'PLAYER_FF',
    CAROUSEL_CONTAINER: 'PLAYER_CAROUSEL_CONTAINER',
  },
};

export { navIdMap };

export const FILTER_CONTENT = {
  GRID_CONTAINER: 'GRID_CONTAINER',
  CONTAINER: 'CONTENT_FILTERING_MODAL',
  TV_CONTENT_MODAL_HIDE_VIDEO_BUTTON: 'TV_CONTENT_MODAL_HIDE_VIDEO_BUTTON',
  TV_CONTENT_MODAL_HIDE_SERIES_BUTTON: 'TV_CONTENT_MODAL_HIDE_SERIES_BUTTON',
  TV_CONTENT_MODAL_ACTION_BUTTON: 'TV_CONTENT_MODAL_ACTION_BUTTON',
};

export default {
  HEADER,
  HOME,
  PLAYER,
};
