import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
  guestMode,
  actions as identityActions,
} from '#/redux/modules/identity/actions';
import {
  getParentMode,
  getParentUserId,
} from '#/redux/modules/identity/selectors';
import {
  getAppStarted,
  getUserAutoloaded,
} from '#/redux/modules/lifecycle/selectors';
import {
  deepLinkStart,
  deeplinkOutsidePlayer,
} from '#/redux/modules/lifecycle/actions';
import {
  deeplinks,
  containersTypeAlias,
  SWIMLANE_LENGTH,
} from '#/config/constants';
import {
  getTopicById,
  getContentByIdDeeplink,
} from '#/redux/modules/firestore/actions';
import {
  getCurrentProfile,
  getCurrentProfileId,
} from '#/redux/modules/onboarding/selectors';
import { actions as onboardingActions } from '#/redux/modules/onboarding/actions';
import { useSpinnerOnStart } from '#/hooks/useSpinnerOnStart';
import {
  performPlaylistFetch,
  actions as brightcoveActions,
} from '#/redux/modules/brightcove/actions';
import { getPlaylistFetch } from '#/redux/modules/brightcove/selectors';
import { getEntryByPlaylistId } from '#/redux/modules/accedoOne/selectors';
import { mainRoutes } from '#/utils/routes';

const { LIVE_TV, COLLECTION_VIEWALL, CHANNEL_TOPIC, SHOW_DETAILS } = deeplinks;

const { CONTAINER_TOPICS, CONTAINER_OVP_PLAYLIST } = containersTypeAlias;

const { SEE_ALL_HOME, SEE_ALL_EXPLORE, LIVETV } = mainRoutes;

const useHandleProfileSelection = () => {
  const dispatch = useDispatch();
  const userAutoloaded = useSelector(getUserAutoloaded);
  const profileId = useSelector(getCurrentProfileId);
  const parentUserId = useSelector(getParentUserId);
  const parentMode = useSelector(getParentMode);
  const profile = useSelector(getCurrentProfile) ?? null;

  if (parentMode && userAutoloaded && profile === null) {
    dispatch(onboardingActions.setSelectedProfile(parentUserId));
    dispatch(identityActions.setParentMode(false));
    return parentUserId;
  }
  if (profileId && profileId === parentUserId) {
    return true;
  }
  return profile;
};

const DeeplinkRedirect = ({ history, match, location, deeplink }) => {
  // id type may differ depending on the deeplink
  const id = match?.params?.id;
  const dispatch = useDispatch();
  const appStarted = useSelector(getAppStarted);
  const userAutoloaded = useSelector(getUserAutoloaded);
  const kidProfile = useHandleProfileSelection();
  const playlist = useSelector(getPlaylistFetch);
  const playlistItems = useSelector(state => getEntryByPlaylistId(state, id));
  // show spinner until app is ready
  useSpinnerOnStart();

  useEffect(() => {
    dispatch(deepLinkStart());
  }, []);

  const handleChannelTopic = useCallback(async () => {
    // get single topic info as topics won't load
    // until a profile (user/guest) is set up
    const topic = await dispatch(getTopicById(id));
    // cannot deeplink
    if (!topic) {
      history.replace('/');
      return;
    }

    dispatch(deeplinkOutsidePlayer(true));
    dispatch(onboardingActions.setProfileDeepLink(true));

    // topic information
    const topicTitle = encodeURIComponent(topic.name);
    const pathname = `${SEE_ALL_EXPLORE}/${id}/${topicTitle}/${CONTAINER_TOPICS}`;
    const newEntry = { pathname };

    // Asume guest
    if (!userAutoloaded) {
      const age = topic?.ages?.[0]; // use min age guest profile
      await dispatch(guestMode({ age }));
      history.replace('/');
      history.push(newEntry);
      return;
    }

    if (kidProfile) {
      history.replace('/');
      history.push(newEntry);
    }
  }, [userAutoloaded]);

  const handleCollectionPlaylist = useCallback(async () => {
    if (!id) {
      history.replace('/');
      return;
    }

    // fetch playlist from brightcove
    // it will be stored on playlistFetch
    dispatch(
      performPlaylistFetch([
        {
          offset: 0,
          page: 0,
          playlistId: id,
        },
      ]),
    );
  }, [userAutoloaded]);

  useEffect(() => {
    if (!playlist) {
      return;
    }

    if (!playlist?.videos?.length) {
      history.replace('/');
      dispatch(brightcoveActions.clearPlaylistFetch());
      return;
    }

    const video =
      playlist?.videos?.[SWIMLANE_LENGTH] ??
      playlist?.videos?.[playlist?.videos?.length];
    const assetId = video?.id;

    dispatch(deeplinkOutsidePlayer(true));
    dispatch(onboardingActions.setProfileDeepLink(true));

    const title = encodeURIComponent(playlistItems?.displayText || video?.name);
    const pathname = `${SEE_ALL_HOME}/${assetId}/${title}/${CONTAINER_OVP_PLAYLIST}/${id}`;
    const newEntry = { pathname };
    const onRedirect = () => {
      history.replace('/');
      history.push(newEntry);
      dispatch(brightcoveActions.clearPlaylistFetch());
    };

    // Asume guest
    if (!userAutoloaded) {
      // Use the age_range of the first video to set the age
      const age = parseInt(
        playlist?.videos?.[0]?.custom_fields?.age_range.substring(0, 1),
        10,
      );
      (async () => {
        await dispatch(guestMode({ age }));
        onRedirect();
      })();
      return;
    }

    if (kidProfile) {
      onRedirect();
    }
  }, [playlist]);

  const handleShowDetails = useCallback(async () => {
    if (!id) {
      history.replace('/');
      return;
    }

    const content = await dispatch(getContentByIdDeeplink(id, 'shows'));

    // verify deeplink exist
    if (!content) {
      history.replace('/');
      return;
    }

    dispatch(deeplinkOutsidePlayer(true));
    dispatch(onboardingActions.setProfileDeepLink(true));

    const playlistId = content?.playlist_id;
    const pathname = `/main/home/showdetails/${id}${
      playlistId ? `/${playlistId}` : ''
    }`;
    const newEntry = { pathname };

    if (!userAutoloaded) {
      const age = content?.ages?.[0];
      await dispatch(guestMode({ age }));
      history.replace('/');
      history.push(newEntry);
      return;
    }

    if (kidProfile) {
      history.replace('/');
      history.push(newEntry);
    }
  }, [userAutoloaded]);

  const handleLiveTV = useCallback(async () => {
    if (!location?.search) {
      history.replace('/');
      return;
    }

    const [, ageGroup, value] =
      location?.search?.match?.(/^\?.*(ageGroup)=(\d*)/) || [];

    // no query for age group
    if (ageGroup !== 'ageGroup') {
      history.replace('/');
      return;
    }

    const ageFromQuery = parseInt(value ?? 2, 10);
    // eslint-disable-next-line no-nested-ternary
    const age = ageFromQuery >= 8 ? 8 : ageFromQuery >= 5 ? 5 : 2;
    const pathname = `${LIVETV}/${age}`;
    const newEntry = { pathname };
    dispatch(onboardingActions.setProfileDeepLink(true));

    // Assume guest
    if (!userAutoloaded) {
      await dispatch(guestMode({ age }));
      history.replace('/');
      history.push(newEntry);
      return;
    }

    if (kidProfile) {
      history.replace('/');
      history.push(newEntry);
    }
  }, [userAutoloaded]);

  useEffect(() => {
    if (!appStarted) {
      return;
    }

    switch (deeplink) {
      case LIVE_TV:
        handleLiveTV();
        break;
      case COLLECTION_VIEWALL:
        handleCollectionPlaylist();
        break;
      case CHANNEL_TOPIC:
        handleChannelTopic();
        break;
      case SHOW_DETAILS:
        handleShowDetails();
        break;
      default:
        // no deeplink detected, going back to "/"
        history.replace('/');
        break;
    }
  }, [appStarted]);

  return <></>;
};

DeeplinkRedirect.propTypes = {
  match: PropTypes.any,
  location: PropTypes.object,
  history: PropTypes.object,
  // route: PropTypes.object,
  deeplink: PropTypes.object,
};

export default withRouter(DeeplinkRedirect);
