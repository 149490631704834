import React from 'react';
import xdk from '@accedo/xdk-core';
import PropTypes from 'prop-types';
import logger from '@accedo/xdk-log';
import cx from 'classnames';
import screenfull from 'screenfull';
import styles from './PlayerFullscreenButton.scss';
import fullscreenIcon from '#/static/images/icons/player-fullscreen.svg';
import { isPortable } from '#/utils/componentStyleConfig';

const { debug } = logger({
  logTag: '[widgets/buttons/PlayerFullscreenButton]',
});

const videoElement = 'sensicle-vjs-html5-player-container_html5_api';
// const containerElement = 'sensicle-vjs-html5-player-container';

const mobileFSRequest = () => {
  // Required to pause, transition, then play for Safari bug fix
  // Safari automatically pauses the video when changing window screen or
  // transitioning to fullscreen, this triggered error state in player in a loop
  const shouldPauseBeforeTransition =
    xdk.media?.getState() !== 'paused' && xdk.media?.getState() !== 'stopped';
  shouldPauseBeforeTransition && xdk.media?.pause();
  // const videoplayer = isiOS()
  //   ? document.getElementById(videoElement)
  //   : document.getElementById(containerElement);
  const videoplayer = document.getElementById(videoElement);
  if (typeof videoplayer.webkitSupportsFullscreen !== 'undefined') {
    videoplayer.webkitEnterFullscreen();
  } else if (typeof videoplayer.requestFullscreen !== 'undefined') {
    videoplayer.requestFullscreen();
  } else if (typeof videoplayer.msRequestFullscreen !== 'undefined') {
    videoplayer.msRequestFullscreen();
  } else {
    throw new Error('fullscreenElement is not supported by this browser');
  }
  if (shouldPauseBeforeTransition) {
    setTimeout(() => xdk.media?.play(), 100);
  }
  // isAndroid() && xdk?._vjs?.controls(true);
};

const onFullscreen = container => async () => {
  if (screenfull.isFullscreen) {
    screenfull.exit();
  } else {
    try {
      if (isPortable()) {
        mobileFSRequest();
      } else {
        await screenfull.request(container);
      }
    } catch (e) {
      debug(`Failed to enter fullscreen: ${e.toString()}`);
      throw new Error(e);
    }
  }
};

const PlayerFullscreenButton = ({ fullscreenLayout, container, className }) => {
  return (
    <img
      id="PlayerFullscreenButton"
      className={cx(
        styles.playerFullscreenButton,
        fullscreenLayout ? styles.fullscreen : styles.background,
        {
          [styles.mobile]: isPortable(),
        },
        className,
      )}
      src={fullscreenIcon}
      onClick={onFullscreen(container)}
      alt="Fullscreen"
      role="presentation"
    />
  );
};
PlayerFullscreenButton.propTypes = {
  fullscreenLayout: PropTypes.bool,
  container: PropTypes.object,
  className: PropTypes.string,
};

export default PlayerFullscreenButton;
