import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';
import { /* useLocation, */ withRouter } from 'react-router-dom';
import routes, { authFlowRoutes, deeplinkRoutes } from '#/utils/routes';
// import DeeplinkRedirect from '#/components/DeeplinkRedirect/DeeplinkRedirect';
import RedirectWithQuery from '#/components/App/RedirectWithQuery';
import { getAppStarted } from '#/redux/modules/lifecycle/selectors';
import { isUserLogged, isAgeVerified } from '#/redux/modules/user/selectors';
import { getParentMode } from '#/redux/modules/identity/selectors';
import { getGeoBlocking } from '#/redux/modules/accedoOne/selectors';

const basicPropTypes = {
  component: PropTypes.func,
  fallback: PropTypes.func,
  routesToGuard: PropTypes.array,
  location: PropTypes.object,
  children: PropTypes.node,
};

export const CanNavigateDesktop = ({
  // allows mobile device to access normal app flow
  component: Component,
  ...props
}) => {
  return <Component {...props} />;
};
CanNavigateDesktop.propTypes = basicPropTypes;
export const CanNavigateDesktopMobile = ({
  component: Component,
  fallback: Fallback = () => (
    <RedirectWithQuery to={authFlowRoutes.GUEST_PROFILE} />
  ),
  ...props
}) => {
  return <Fallback {...props} />;
};
CanNavigateDesktopMobile.propTypes = basicPropTypes;

export const CanNavigateGeoBloking = ({
  component: Component,
  fallback: Fallback = () => <RedirectWithQuery to={routes.GEOBLOCK_GATE} />,
  ...props
}) => {
  const { geoBlocked = false } = useSelector(getGeoBlocking) || {};
  return geoBlocked ? <Fallback {...props} /> : <Component {...props} />;
};

CanNavigateGeoBloking.propTypes = basicPropTypes;

export const CanNavigateAuth = ({
  component: Component,
  fallback: Fallback = () => (
    <RedirectWithQuery push to={authFlowRoutes.ROOT} />
  ),
  ...props
}) => {
  const loggedinStatus = useSelector(isUserLogged);
  const appStarted = useSelector(getAppStarted);
  const [authState, setAuthState] = useState(loggedinStatus);
  useEffect(() => {
    setAuthState(loggedinStatus);
  }, [appStarted]);
  return authState ? <Component {...props} /> : <Fallback {...props} />;
};
CanNavigateAuth.propTypes = basicPropTypes;

export const CanNavigateUnAuth = ({
  component: Component,
  fallback: Fallback = () => <RedirectWithQuery push to={routes.HOME} />,
  ...props
}) => {
  const loggedinStatus = useSelector(isUserLogged);
  const appStarted = useSelector(getAppStarted);
  const [authState, setAuthState] = useState(loggedinStatus);
  useEffect(() => {
    setAuthState(loggedinStatus);
  }, [appStarted]);
  return !authState ? <Component {...props} /> : <Fallback {...props} />;
};
CanNavigateUnAuth.propTypes = basicPropTypes;

export const CanNavigateAgeNotVerified = ({
  component: Component,
  fallback: Fallback,
  ...props
}) => {
  const appStarted = useSelector(getAppStarted);
  const verified = useSelector(isAgeVerified);
  const [ageVerifiedState, setAgeVerifiedState] = useState(verified);
  useEffect(() => {
    setAgeVerifiedState(verified);
  }, [appStarted]);
  return !ageVerifiedState ? <Component {...props} /> : <Fallback {...props} />;
};
CanNavigateAgeNotVerified.propTypes = basicPropTypes;

export const CanNavigateAgeVerified = ({
  component: Component,
  fallback: Fallback,
  ...props
}) => {
  const appStarted = useSelector(getAppStarted);
  const verified = useSelector(isAgeVerified);
  const [ageVerifiedState, setAgeVerifiedState] = useState(verified);
  useEffect(() => {
    setAgeVerifiedState(verified);
  }, [appStarted]);
  return ageVerifiedState ? <Component {...props} /> : <Fallback {...props} />;
};
CanNavigateAgeVerified.propTypes = basicPropTypes;

export const CanNavigateChildContext = ({
  component: Component,
  fallback: Fallback,
  ...props
}) => {
  const appStarted = useSelector(getAppStarted);
  const parentMode = useSelector(getParentMode);
  const [parentModeState, setParentModeState] = useState(parentMode);

  useEffect(() => {
    setParentModeState(parentMode);
  }, [appStarted]);

  return !parentModeState ? <Component {...props} /> : <Fallback {...props} />;
};
CanNavigateChildContext.propTypes = basicPropTypes;

export const CanNavigateParentContext = ({
  component: Component,
  fallback: Fallback,
  ...props
}) => {
  const appStarted = useSelector(getAppStarted);
  const parentMode = useSelector(getParentMode);
  const [parentModeState, setParentModeState] = useState(parentMode);

  useEffect(() => {
    setParentModeState(parentMode);
  }, [appStarted]);
  return parentModeState ? <Component {...props} /> : <Fallback {...props} />;
};
CanNavigateParentContext.propTypes = basicPropTypes;

export const CanNavigateDeeplink = withRouter(
  ({
    component: Component,
    fallback: Fallback = ({ deeplinkPath }) => (
      <RedirectWithQuery
        push={false}
        to={`${deeplinkRoutes.ROOT}${deeplinkPath}`}
      />
    ),
    location,
    ...props
  }) => {
    const appStarted = useSelector(getAppStarted);
    // const location = useLocation();
    // if not started, we consider this a deeplink
    return appStarted ? (
      <Component {...props} location={location} />
    ) : (
      <Fallback deeplinkPath={location.pathname} />
    );
  },
);
CanNavigateDeeplink.propTypes = basicPropTypes;

// Helper guards
const CanNavigateFilterRoutes = ({
  component: Component,
  routesToGuard,
  children,
  ...props
}) => {
  const {
    location: { pathname: path },
  } = props;

  // current location is not guarded, nothing to check, does exact check for routes not ending in forward slash,
  // otherwise checks inclusion of the base route name in the current path
  if (
    routesToGuard &&
    !routesToGuard.find(route =>
      route.slice(-1) === '/' ? path.includes(route) : route === path,
    )
  ) {
    return <Component {...props} />;
  }
  return <>{children}</>;
};
CanNavigateFilterRoutes.propTypes = basicPropTypes;

// Routing guard factory
/*
 * createGuardedRoute takes a single guard or an array of guards to be nested.
 * In array form, each array element is a routing guard with parameters, and the component param
 * may not be specified except on the final array element.
 */
export const createGuardedRoute = guards => {
  const guardsArray = Array.isArray(guards) ? guards : [guards];
  const [{ canNavigate: CanNavigate, ...rest }, ...restGuards] = guardsArray;

  // Base case
  if (guardsArray.length === 1) {
    return props => {
      return (
        <CanNavigateFilterRoutes {...rest} {...props}>
          <CanNavigate {...rest} {...props} />
        </CanNavigateFilterRoutes>
      );
    };
  }

  return createGuardedRoute({
    canNavigate: CanNavigate,
    ...rest,
    component: createGuardedRoute(restGuards),
  });
};
