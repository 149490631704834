import React from 'react';
import PropTypes from 'prop-types';
import { FocusDiv } from '@accedo/vdkweb-tv-ui';
import { isWorkstation } from '#/utils/componentStyleConfig';

import BaseModal from '../BaseModal/BaseModal';
import FocusModal from '../FocusModal/FocusModal';
import styles from './ContentModal.scss';
import { TV_CONTENT_MODAL } from '#/utils/navigationHelper';

const { CONTAINER, TV_CONTENT_MODAL_ACTION_BUTTON } = TV_CONTENT_MODAL;
const navMap = {
  CONTAINER: {
    id: CONTAINER,
  },
  ACTION_BUTTON: {
    id: TV_CONTENT_MODAL_ACTION_BUTTON,
  },
};

const WebContentModal = ({ children }) => (
  <BaseModal showSaveButton={false} className={styles.webModal}>
    <div className={styles.content}>{children}</div>
  </BaseModal>
);
WebContentModal.propTypes = {
  children: PropTypes.node,
};

const TVContentModal = ({ children }) => {
  return (
    <FocusModal>
      <BaseModal
        showSaveButton
        saveButtonText="OK"
        showCloseX={false}
        saveButtonContainerClassName={styles.buttonContainer}
        saveButtonClassName={styles.saveButton}
        className={styles.tvModal}
        nav={{
          id: navMap.ACTION_BUTTON.id,
          parent: navMap.CONTAINER.id,
        }}
      >
        <FocusDiv
          nav={{
            id: navMap.CONTAINER.id,
            forwardFocus: navMap.ACTION_BUTTON.id,
          }}
          className={styles.focusModal}
        >
          {children}
        </FocusDiv>
      </BaseModal>
    </FocusModal>
  );
};
TVContentModal.propTypes = {
  children: PropTypes.node,
};

export default !isWorkstation() ? TVContentModal : WebContentModal;
