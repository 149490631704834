import { handleActions } from 'redux-actions';

import { generateKey, encryptObject } from '#/utils/encryptionUtils';

import { persistenceLayer } from '../../utils/persistence';

import {
  IDENTITY_ACTION_PREFIX,
  actions,
  ASYNC_SWITCH_PROFILE,
} from './actions';

import { vindiciaErrors } from '#/config/strings';

const {
  operation: {
    alreadyExistEmail,
    tvCodeExpired,
    userCodeAlreadyApproved,
    authenticationRequired,
    invalidPin,
    wrongPasswordError,
  },
  unknownError,
} = vindiciaErrors;

// Initial state
const initialState = {
  processId: null,
  verificationEmail: null,
  accessToken: null,
  refreshToken: null,
  pkat: null,
  groupId: null,
  parentUserId: null,
  parentAccount: {},
  parentMode: false,
  onboardingSuccess: false,
  pin: null,
  customData: {},
  errors: {},
  sessionData: {},
};

// Helpers
const parseVindiciaError = (
  { fieldErrors, operationError, customError },
  actionType,
) => {
  if (fieldErrors) {
    const fieldErrorsAggregated = fieldErrors.reduce(
      (acc, { field, code, message }) => {
        acc[field] = {
          ...acc[field],
          [code]: [...(acc[field]?.[code] || []), message],
        };
        return acc;
      },
      {},
    );
    return {
      errors: { fieldErrors: fieldErrorsAggregated },
    };
  }
  if (operationError) {
    const opError =
      typeof operationError.reduce === 'function'
        ? operationError
        : [{ ...operationError }];
    const operationErrorAggregated = opError.reduce(
      (acc, { code, message }) => {
        acc[code] = [...(acc[code] || []), message];
        return acc;
      },
      {},
    );
    if (operationErrorAggregated['already-exist-email']) {
      return {
        errors: { errorMessage: alreadyExistEmail },
      };
    }
    if (operationErrorAggregated.user_code_expired) {
      return {
        errors: { errorMessage: tvCodeExpired },
      };
    }
    if (operationErrorAggregated.user_code_already_approved) {
      return {
        errors: { errorMessage: userCodeAlreadyApproved },
      };
    }
    if (operationErrorAggregated['authentication-required']) {
      if (actionType === ASYNC_SWITCH_PROFILE) {
        return { errors: { errorMessage: invalidPin } };
      }
      return {
        errors: { errorMessage: authenticationRequired },
      };
    }
    if (operationErrorAggregated['invalid-credential']) {
      return {
        errors: { errorMessage: wrongPasswordError },
      };
    }
  }
  if (customError) {
    return {
      errors: { errorMessage: customError },
    };
  }
  return {
    errors: { errorMessage: unknownError },
  };
};

// Reducers
const handleResetState = () => ({ ...initialState });

const handleSetAccessToken = (state = initialState, { payload }) => ({
  ...state,
  accessToken: payload,
});

const handleSetRefreshToken = (state = initialState, { payload }) => ({
  ...state,
  refreshToken: payload,
});

const handleSetVerificationEmail = (
  state = initialState,
  { payload: verificationEmail },
) => ({
  ...state,
  verificationEmail,
});

const handleSetPkat = (state = initialState, { payload }) => ({
  ...state,
  pkat: payload,
});

const handleSetGroupId = (state = initialState, { payload }) => ({
  ...state,
  groupId: payload,
});

const handleSetParentUserId = (state = initialState, { payload }) => ({
  ...state,
  parentUserId: payload,
});

const handleSetParentAccountInfo = (state = initialState, { payload }) => ({
  ...state,
  parentAccount: {
    ...state.parentAccount,
    ...payload,
  },
});

const handleSetParentCustomData = (
  state = initialState,
  { payload: customData },
) => ({
  ...state,
  parentAccount: {
    ...state.parentAccount,
    ...customData,
  },
});

const handleSetPin = (state = initialState, { payload: pin }) => ({
  ...state,
  pin,
});

const handleSetError = (
  state = initialState,
  { payload: { actionType, errors } },
) => ({
  ...state,
  errors: {
    ...state.errors,
    [actionType]: {
      ...parseVindiciaError(errors, actionType),
    },
  },
});

const handleDiscardError = (state = initialState, { payload: actionType }) => ({
  ...state,
  errors: {
    ...state.errors,
    [actionType]: undefined,
  },
});

const handleSetParentMode = (
  state = initialState,
  { payload: parentMode },
) => ({
  ...state,
  parentMode,
});

const handleSetOnboardingSuccess = (state = initialState) => ({
  ...state,
  onboardingSuccess: true,
});

const handleSetProcessId = (state = initialState, { payload: processId }) => ({
  ...state,
  processId,
});

const handleSaveCustomDataVideoBookmark = (
  state = initialState,
  { payload = [{}] },
) => {
  const newState = {
    ...state,
    customData: {
      ...state.customData,
    },
  };

  const { brightcoveId, progress } = payload[0];

  newState.customData.bookmark = {
    videoId: Number(brightcoveId),
    progress,
  };

  return newState;
};

const handleClearCustomDataRecentlyWatched = (state = initialState) => {
  return {
    ...state,
    customData: {
      ...state.customData,
      recentlyWatched: null,
    },
  };
};

const handleSaveCustomDataRecentlyWatched = (
  state = initialState,
  { payload = [] },
) => {
  return {
    ...state,
    customData: {
      ...state.customData,
      recentlyWatched: payload,
    },
  };
};

const handleSaveCustomDataShowsFavorites = (
  state = initialState,
  { payload = [] },
) => ({
  ...state,
  customData: {
    ...state.customData,
    showsFavorites: [...payload],
  },
});
const handleSaveCustomDataPodcastsFavorites = (
  state = initialState,
  { payload = [] },
) => ({
  ...state,
  customData: {
    ...state.customData,
    podcastsFavorites: [...payload],
  },
});

const handleSaveCustomDataVideoFavorites = (
  state = initialState,
  { payload = [] },
) => ({
  ...state,
  customData: {
    ...state.customData,
    videoFavorites: [...payload],
  },
});

const handleSaveCustomDataPodcastsEpisodesFavorites = (
  state = initialState,
  { payload = [] },
) => ({
  ...state,
  customData: {
    ...state.customData,
    podcastsEpisodesFavorites: [...payload],
  },
});

const handleSaveCustomDataTopicsFavorites = (
  state = initialState,
  { payload = [] },
) => ({
  ...state,
  customData: {
    ...state.customData,
    topicsFavorites: [...payload],
  },
});

// TODO: implement handleDeleteCustomDataTopicsFavorites

// TODO: implement same functions for video and show

const handleAddSingleCustomDataTopicsFavorite = (
  state = initialState,
  { payload = {} },
) => {
  const topicsFavorites = state.customData.topicsFavorites
    ? [...state.customData.topicsFavorites]
    : [];
  topicsFavorites.push(payload);
  return {
    ...state,
    customData: {
      ...state.customData,
      topicsFavorites,
    },
  };
};

const handleDeleteSingleCustomDataTopicsFavorite = (
  state = initialState,
  { payload = '' },
) => {
  const topicsFavorites = state.customData?.topicsFavorites
    ? [...state.customData.topicsFavorites].filter(
        tf => tf.id?.toString() !== payload?.toString(),
      )
    : [];
  return {
    ...state,
    customData: {
      ...state.customData,
      topicsFavorites,
    },
  };
};

const handleAddSingleCustomDataShowsFavorite = (
  state = initialState,
  { payload = {} },
) => {
  const showsFavorites = state.customData.showsFavorites
    ? [...state.customData.showsFavorites]
    : [];
  showsFavorites.push(payload);
  return {
    ...state,
    customData: {
      ...state.customData,
      showsFavorites,
    },
  };
};

const handleAddSingleCustomDataPodcastsFavorite = (
  state = initialState,
  { payload = {} },
) => {
  const podcastsFavorites = state.customData.podcastsFavorites
    ? [...state.customData.podcastsFavorites]
    : [];
  podcastsFavorites.push(payload);
  return {
    ...state,
    customData: {
      ...state.customData,
      podcastsFavorites,
    },
  };
};

const handleDeleteSingleCustomDataShowsFavorite = (
  state = initialState,
  { payload = '' },
) => {
  const showsFavorites = state.customData?.showsFavorites
    ? [...state.customData.showsFavorites].filter(
        tf => tf.id?.toString() !== payload?.toString(),
      )
    : [];
  return {
    ...state,
    customData: {
      ...state.customData,
      showsFavorites,
    },
  };
};

const handleDeleteSingleCustomDataPodcastsFavorite = (
  state = initialState,
  { payload = '' },
) => {
  const podcastsFavorites = state.customData?.podcastsFavorites
    ? [...state.customData.podcastsFavorites].filter(
        pf => pf.id?.toString() !== payload?.toString(),
      )
    : [];
  return {
    ...state,
    customData: {
      ...state.customData,
      podcastsFavorites,
    },
  };
};

const handleAddSingleCustomDataVideoFavorite = (
  state = initialState,
  { payload = {} },
) => {
  const videoFavorites = state.customData.videoFavorites
    ? [...state.customData.videoFavorites]
    : [];
  videoFavorites.push(payload);
  return {
    ...state,
    customData: {
      ...state.customData,
      videoFavorites,
    },
  };
};

const handleAddSingleCustomDataPodcastsEpisodesFavorite = (
  state = initialState,
  { payload = {} },
) => {
  const podcastsEpisodesFavorites = state.customData.podcastsEpisodesFavorites
    ? [...state.customData.podcastsEpisodesFavorites]
    : [];
  podcastsEpisodesFavorites.push(payload);
  return {
    ...state,
    customData: {
      ...state.customData,
      podcastsEpisodesFavorites,
    },
  };
};

const handleDeleteSingleCustomDataVideoFavorite = (
  state = initialState,
  { payload = '' },
) => {
  const videoFavorites = state.customData?.videoFavorites
    ? [...state.customData.videoFavorites].filter(
        tf => tf.id?.toString() !== payload?.toString(),
      )
    : [];
  return {
    ...state,
    customData: {
      ...state.customData,
      videoFavorites,
    },
  };
};

const handleDeleteSingleCustomDataPodcastsEpisodesFavorite = (
  state = initialState,
  { payload = '' },
) => {
  const podcastsEpisodesFavorites = state.customData?.podcastsEpisodesFavorites
    ? [...state.customData.podcastsEpisodesFavorites].filter(
        tf => tf.id?.toString() !== payload?.toString(),
      )
    : [];
  return {
    ...state,
    customData: {
      ...state.customData,
      podcastsEpisodesFavorites,
    },
  };
};

const handleAddSingleCustomDataVideoBlocked = (
  state = initialState,
  { payload = {} },
) => {
  const videosBlocked = state.customData.videosBlocked
    ? [...state.customData.videosBlocked]
    : [];
  videosBlocked.push(payload);
  return {
    ...state,
    customData: {
      ...state.customData,
      videosBlocked,
    },
  };
};

const handleDeleteSingleCustomDataVideoBlocked = (
  state = initialState,
  { payload = '' },
) => {
  const videosBlocked = state.customData?.videosBlocked
    ? [...state.customData.videosBlocked].filter(
        tf => tf.id?.toString() !== payload?.toString(),
      )
    : [];
  return {
    ...state,
    customData: {
      ...state.customData,
      videosBlocked,
    },
  };
};

const handleAddSingleCustomDataShowBlocked = (
  state = initialState,
  { payload = {} },
) => {
  const showsBlocked = state.customData.showsBlocked
    ? [...state.customData.showsBlocked]
    : [];
  showsBlocked.push(payload);
  return {
    ...state,
    customData: {
      ...state.customData,
      showsBlocked,
    },
  };
};

const handleDeleteSingleCustomDataShowBlocked = (
  state = initialState,
  { payload = '' },
) => {
  const showsBlocked = state.customData?.showsBlocked
    ? [...state.customData.showsBlocked].filter(
        tf => tf.id?.toString() !== payload?.toString(),
      )
    : [];
  return {
    ...state,
    customData: {
      ...state.customData,
      showsBlocked,
    },
  };
};

const handleClearCustomDataVideoBookmark = (state = initialState) => ({
  ...state,
  customData: {
    ...state.customData,
    bookmark: {},
  },
});

const handleSaveSessionData = (state = initialState, { payload = {} }) => {
  const { encData } = state.sessionData;
  if (encData) {
    return {
      ...state,
      sessionData: {
        ...state.sessionData,
        ...encryptObject(payload, encData),
      },
    };
  }

  const data = generateKey(20);
  return {
    ...state,
    sessionData: {
      ...encryptObject(payload, data),
      encData: data,
    },
  };
};

const handleClearSessionData = (state = initialState) => ({
  ...state,
  sessionData: {},
});

const handleSaveCustomDataDisplayedModals = (
  state = initialState,
  { payload = {} },
) => {
  return {
    ...state,
    customData: {
      ...state.customData,
      displayedModals: [...(state.customData.displayedModals || []), payload],
    },
  };
};
const handleDeleteCustomDataDisplayedModals = (state = initialState) => {
  return {
    ...state,
    customData: {
      ...state.customData,
      displayedModals: null,
    },
  };
};

const handleSaveCustomDataVideosBlocked = (
  state = initialState,
  { payload = {} },
) => {
  return {
    ...state,
    customData: {
      ...state.customData,
      videosBlocked: payload || [],
    },
  };
};

const handleSaveCustomDataShowsBlocked = (
  state = initialState,
  { payload = {} },
) => {
  return {
    ...state,
    customData: {
      ...state.customData,
      showsBlocked: payload || [],
    },
  };
};

const reducer = handleActions(
  {
    [actions.resetState]: handleResetState,
    [actions.setVerificationEmail]: handleSetVerificationEmail,
    [actions.setPkat]: handleSetPkat,
    [actions.setGroupId]: handleSetGroupId,
    [actions.setParentUserId]: handleSetParentUserId,
    [actions.setParentAccountInfo]: handleSetParentAccountInfo,
    [actions.setParentCustomData]: handleSetParentCustomData,
    [actions.setPin]: handleSetPin,
    [actions.setError]: handleSetError,
    [actions.discardError]: handleDiscardError,
    [actions.setParentMode]: handleSetParentMode,
    [actions.setOnboardingSuccess]: handleSetOnboardingSuccess,
    [actions.setProcessId]: handleSetProcessId,
    [actions.saveCustomDataVideoBookmark]: handleSaveCustomDataVideoBookmark,
    [actions.saveCustomDataShowsFavorites]: handleSaveCustomDataShowsFavorites,
    [actions.deleteCustomDataDisplayedModals]: handleDeleteCustomDataDisplayedModals,
    [actions.saveCustomDataDisplayedModals]: handleSaveCustomDataDisplayedModals,
    [actions.saveCustomDataVideosBlocked]: handleSaveCustomDataVideosBlocked,
    [actions.saveCustomDataShowsBlocked]: handleSaveCustomDataShowsBlocked,
    [actions.clearCustomDataRecentlyWatched]: handleClearCustomDataRecentlyWatched,
    [actions.saveCustomDataRecentlyWatched]: handleSaveCustomDataRecentlyWatched,
    [actions.saveCustomDataPodcastsFavorites]: handleSaveCustomDataPodcastsFavorites,
    [actions.saveCustomDataVideoFavorites]: handleSaveCustomDataVideoFavorites,
    [actions.saveCustomDataPodcastsEpisodesFavorites]: handleSaveCustomDataPodcastsEpisodesFavorites,
    [actions.saveCustomDataTopicsFavorites]: handleSaveCustomDataTopicsFavorites,
    [actions.addSingleCustomDataTopicsFavorite]: handleAddSingleCustomDataTopicsFavorite,
    [actions.deleteSingleCustomDataTopicsFavorite]: handleDeleteSingleCustomDataTopicsFavorite,
    [actions.addSingleCustomDataPodcastsFavorite]: handleAddSingleCustomDataPodcastsFavorite,
    [actions.addSingleCustomDataShowsFavorite]: handleAddSingleCustomDataShowsFavorite,
    [actions.deleteSingleCustomDataPodcastsFavorite]: handleDeleteSingleCustomDataPodcastsFavorite,
    [actions.deleteSingleCustomDataShowsFavorite]: handleDeleteSingleCustomDataShowsFavorite,
    [actions.addSingleCustomDataVideoFavorite]: handleAddSingleCustomDataVideoFavorite,
    [actions.deleteSingleCustomDataVideoFavorite]: handleDeleteSingleCustomDataVideoFavorite,
    [actions.addSingleCustomDataPodcastsEpisodesFavorite]: handleAddSingleCustomDataPodcastsEpisodesFavorite,
    [actions.deleteSingleCustomDataPodcastsEpisodesFavorite]: handleDeleteSingleCustomDataPodcastsEpisodesFavorite,
    [actions.addSingleCustomDataVideoBlocked]: handleAddSingleCustomDataVideoBlocked,
    [actions.deleteSingleCustomDataVideoBlocked]: handleDeleteSingleCustomDataVideoBlocked,
    [actions.addSingleCustomDataShowBlocked]: handleAddSingleCustomDataShowBlocked,
    [actions.deleteSingleCustomDataShowBlocked]: handleDeleteSingleCustomDataShowBlocked,
    [actions.clearCustomDataVideoBookmark]: handleClearCustomDataVideoBookmark,
    [actions.setAccessToken]: handleSetAccessToken,
    [actions.setRefreshToken]: handleSetRefreshToken,
    [actions.saveSessionData]: handleSaveSessionData,
    [actions.clearSessionData]: handleClearSessionData,
  },
  initialState,
);

export default persistenceLayer(reducer, initialState, IDENTITY_ACTION_PREFIX);
