import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { focusManager } from '@accedo/vdkweb-navigation';
import { pageRedux } from '@accedo/vdkweb-tv-ui';
import { vKey } from '@accedo/xdk-virtual-key';
import { environment } from '@accedo/xdk-core';
import { isWorkstation } from '#/utils/componentStyleConfig';
import { actions as modalActions } from '#/redux/modules/modal/actions';

const FocusModal = ({
  children,
  isSticky = false,
  shouldReturnFocus = true,
}) => {
  const dispatch = useDispatch();
  const [returnFocusId] = useState(focusManager.getCurrentFocus());

  const backKeyHandler = useCallback(({ id }) => {
    const { BACK } = vKey;
    if (id === BACK.id && !isSticky && !isWorkstation()) {
      dispatch(modalActions.closeModal());
    }
  }, []);

  useEffect(() => {
    environment.addEventListener(environment.SYSTEM.KEYDOWN, backKeyHandler);

    return () => {
      environment.removeEventListener(
        environment.SYSTEM.KEYDOWN,
        backKeyHandler,
      );
      // Return focus
      if (!shouldReturnFocus) {
        return;
      }
      if (returnFocusId) {
        focusManager.changeFocus(returnFocusId);
      } else {
        dispatch(pageRedux.actions.pageFocusCurrent());
      }
    };
  }, []);

  return <>{children}</>;
};
FocusModal.propTypes = {
  children: PropTypes.node,
  isSticky: PropTypes.bool,
  shouldReturnFocus: PropTypes.bool,
};

export default FocusModal;
