import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import RedirectWithQuery from '#/components/App/RedirectWithQuery';

import {
  isUserLogged,
  getLogoutRedirectPath,
} from '#/redux/modules/user/selectors';
import { actions as userActions } from '#/redux/modules/user/actions';
import { authFlowRoutes } from '#/utils/routes';

const LogoutRedirect = () => {
  const isUserLoggedIn = useSelector(isUserLogged);
  const logoutRedirectPath = useSelector(getLogoutRedirectPath);
  const dispatch = useDispatch();

  const redirect = useMemo(() => {
    if (isUserLoggedIn) {
      return null;
    }

    if (logoutRedirectPath) {
      setTimeout(() => {
        dispatch(userActions.clearLogoutRedirectPath());
      }, 1000);
      return <RedirectWithQuery to={logoutRedirectPath} />;
    }

    return <RedirectWithQuery to={authFlowRoutes.GUEST_PROFILE} />;
  }, [isUserLoggedIn, logoutRedirectPath]);

  return <>{redirect}</>;
};

export default LogoutRedirect;
