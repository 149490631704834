import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { InfoForm, InfoInput } from '#/widgets/forms';
import { Button } from '#/widgets/ui';

import ErrorDispatcher from '../../ErrorDispatcher/ErrorDispatcher';
import {
  CONFIRM_PASSWORD,
  confirmPassword,
} from '#/redux/modules/identity/actions';

import { authFlowRoutes } from '#/utils/routes';
import Skiddle from '#/static/images/icons/skiddle-green.svg';
import styles from './ForgotPasswordConfirmation.scss';
import { passwordHintText } from '../../../config/strings';

const PasswordConfirmInProgress = () => {
  return (
    <>
      <h1 className={styles.title}>Resetting password</h1>
      <p>Please wait while we change your password.</p>
    </>
  );
};

const PasswordConfirmed = () => {
  return (
    <>
      <h1 className={styles.title}>Your password has been reset!</h1>
      <p>
        We've changed your password, please sign in again with your new
        password.
      </p>
    </>
  );
};

const ForgotPasswordConfirmation = ({ location: { search } }) => {
  const dispatch = useDispatch();
  const token = new URLSearchParams(search).get('token_value');
  const [inProgress, setInProgress] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [newPassword, setNewPassword] = useState(null);
  const [isPasswordValid, setIsPasswordValid] = useState(false);

  const submitPassword = useCallback(async () => {
    try {
      setInProgress(true);
      await dispatch(confirmPassword(token, newPassword));
      setConfirmed(true);
    } finally {
      setInProgress(false);
    }
  }, [token, newPassword]);

  const onValidInput = useCallback(status => {
    setIsPasswordValid(status);
  }, []);

  const passwordEntryForm = useMemo(
    () => (
      <InfoForm
        onChange={(data, isValid) => {
          isValid && setNewPassword(data.newPassword);
        }}
      >
        <InfoInput
          fieldName="newPassword"
          fieldLabel="New password"
          placeHolder="Minimum 8 characters"
          inputAttributes={{
            type: 'password',
            pattern: '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$',
            required: 'required',
          }}
          onValidInput={onValidInput}
        />
        <label className={styles.passwordHintText} htmlFor="newPassword">
          {passwordHintText}
        </label>
        <p />
        <Button onClick={submitPassword} disabled={!isPasswordValid}>
          Change Password
        </Button>
      </InfoForm>
    ),
    [submitPassword, isPasswordValid],
  );

  return (
    <div className={styles.forgotPasswordConfirmation}>
      <ErrorDispatcher errorId={CONFIRM_PASSWORD} />
      <div className={styles.content}>
        <img className={styles.logo} src={Skiddle} alt="Logo" />
        {inProgress && <PasswordConfirmInProgress />}
        {confirmed && <PasswordConfirmed />}
        {!inProgress && !confirmed && passwordEntryForm}
        <Link to={authFlowRoutes.SIGN_IN}>Sign in</Link>
      </div>
    </div>
  );
};
ForgotPasswordConfirmation.propTypes = {
  location: PropTypes.any,
};

export default ForgotPasswordConfirmation;
