import { customDataFieldTypes } from '#/config/constants';

const {
  // TIME_WATCHED,
  // RECENTLY_WATCHED,
  RECENTLY_WATCHED_V2,
  // LIVE_HISTORY,
  // WATCH_HISTORY,
  // BOOKMARKS,
  BOOKMARKS_V2,
  // SHOWS_FAVORITES,
  // PODCASTS_FAVORITES,
  // TOPICS_FAVORITES,
  // VIDEO_FAVORITES,
  // PODCASTS_EPISODES_FAVORITES,
} = customDataFieldTypes;

let rootSelector = state => state.guest;

export const setRootSelector = selector => {
  rootSelector = selector;
};

export const getRootState = state => rootSelector(state);

export const getStoredCustomData = (state, age) => {
  return getRootState(state).localStorage?.[age];
};

export const getRecentlyWatchedByAge = (state, age) => {
  return getStoredCustomData(state, age)?.[RECENTLY_WATCHED_V2];
};

export const getBookmaksByAge = (state, age) => {
  return getStoredCustomData(state, age)?.[BOOKMARKS_V2];
};

export const getCustomDataVideoBookmark = state =>
  rootSelector(state)?.customData?.bookmark;

export const getCustomDataRecentlyWatched = state =>
  rootSelector(state)?.customData?.recentlyWatched;

export const getCustomDataRecentlyWatchedIds = state =>
  getCustomDataRecentlyWatched(state)?.map(i => i.videoId);

export const getHasCustomDataByAge = (state, age) => {
  return (
    !!getRecentlyWatchedByAge(state, age)?.length ||
    !!getBookmaksByAge(state, age)?.length
  );
};
