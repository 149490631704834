import React, { useCallback, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { withFocus } from '@accedo/vdkweb-navigation';
import classNames from 'classnames';
import imgixUrl from '#/utils/imgix';
import {
  isPortable,
  isWorkstation,
  isMobile,
  isTouchDevice,
} from '#/utils/componentStyleConfig';
import leftArrow from '#/static/images/icons/carousel-left.svg';
import rightArrow from '#/static/images/icons/carousel-right.svg';
import styles from './swimlane.scss';
import {
  SWIMLANE_RIGHT_ARROW,
  SWIMLANE_LEFT_ARROW,
  imgixTileResizeParameters,
} from '#/config/constants';

const Swimlane = ({
  title,
  onTitleClick = null,
  items,
  tileContainerClass,
  className,
  isTv,
  navId,
  separatorColor,
  backgroundColor,
  swimlaneSponsorData,
  backgroundImageUrls,
}) => {
  const totalOffset = useRef(0);
  const translationAmount = useRef(0);
  const [translateContainerRef, leftArrowRef, rightArrowRef] = [
    useRef(),
    useRef(),
    useRef(),
  ];

  useLayoutEffect(() => {
    if (!isPortable()) {
      if (
        totalOffset.current + translationAmount.current > 0 ||
        totalOffset.current === 0
      ) {
        leftArrowRef.current.style.visibility = 'hidden';
        leftArrowRef.current.style.opacity = 0;
      }
      if (translateContainerRef.current.offsetWidth < window.innerWidth) {
        rightArrowRef.current.style.visibility = 'hidden';
        rightArrowRef.current.style.opacity = 0;
      } else {
        rightArrowRef.current.style.visibility = 'visible';
        rightArrowRef.current.style.opacity = 1;
      }
      translationAmount.current =
        translateContainerRef.current.children[1]?.offsetLeft * 1.5;
    }
  }, [translateContainerRef, leftArrowRef, rightArrowRef, items]);

  const onArrowClick = useCallback(
    moveRight => {
      if (
        moveRight &&
        translateContainerRef.current.offsetWidth + totalOffset.current >
          window.innerWidth
      ) {
        totalOffset.current -= translationAmount.current;
        if (
          translateContainerRef.current.offsetWidth + totalOffset.current <
          window.innerWidth
        ) {
          rightArrowRef.current.style.opacity = 0;
          rightArrowRef.current.style.visibility = 'hidden';
        }
        leftArrowRef.current.style.visibility = 'visible';
        leftArrowRef.current.style.opacity = 1;
      } else if (!moveRight && totalOffset.current < 0) {
        totalOffset.current += translationAmount.current;
        if (!totalOffset.current) {
          leftArrowRef.current.style.opacity = 0;
          leftArrowRef.current.style.visibility = 'hidden';
        }
        rightArrowRef.current.style.visibility = 'visible';
        rightArrowRef.current.style.opacity = 1;
      }
      translateContainerRef.current.style.transform = `translateX(${totalOffset.current}px)`;
    },
    [translateContainerRef, leftArrowRef, rightArrowRef],
  );

  const HandleBackgroundImg = useCallback(() => {
    switch (true) {
      case isTouchDevice() && backgroundImageUrls?.tablet:
        return `url(${backgroundImageUrls?.tablet})`;
      case isMobile() && backgroundImageUrls?.phone:
        return `url(${backgroundImageUrls?.phone})`;
      case isWorkstation() && backgroundImageUrls?.desktop:
        return `url(${backgroundImageUrls?.desktop})`;
      default:
        return `url(${imgixUrl(
          swimlaneSponsorData?.backgroundImageUrl,
          imgixTileResizeParameters.SWIMLANE,
        )})`;
    }
  }, [backgroundImageUrls, swimlaneSponsorData]);
  return (
    <div
      className={classNames(styles.container, className || '', {
        [styles.isTv]: isTv,
      })}
      style={
        swimlaneSponsorData
          ? {
              backgroundImage: HandleBackgroundImg(),
              borderBottomColor: separatorColor || 'transparent',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundColor: backgroundColor || 'transparent',
            }
          : {
              borderBottomColor: separatorColor || 'transparent',
              backgroundColor: backgroundColor || 'transparent',
            }
      }
    >
      <div
        onClick={onTitleClick || (() => {})}
        className={classNames({ [styles.titleWrapper]: title })}
      >
        <div className={styles.sponsoredContainer}>
          <p className={styles.title}>{title || ''}</p>
          {swimlaneSponsorData && (
            <div className={styles.sponsoredTile}>
              <p>{swimlaneSponsorData?.displaySubtext}</p>
            </div>
          )}
        </div>
        {swimlaneSponsorData && (
          <img
            className={styles.sponsoredImg}
            src={swimlaneSponsorData?.iconImageUrl}
            alt="Sponsered Tile"
          />
        )}
      </div>
      <div
        ref={translateContainerRef}
        className={classNames(styles.itemContainer, tileContainerClass || '')}
      >
        {items}
      </div>
      {!isPortable() && (
        <div ref={leftArrowRef} className={styles.leftArrow}>
          <div
            id={`${SWIMLANE_LEFT_ARROW + navId}`}
            style={{ backgroundImage: `url(${leftArrow})` }}
            onClick={() => onArrowClick(false)}
          />
        </div>
      )}
      {!isPortable() && (
        <div ref={rightArrowRef} className={styles.rightArrow}>
          <div
            id={`${SWIMLANE_RIGHT_ARROW + navId}`}
            style={{ backgroundImage: `url(${rightArrow})` }}
            onClick={() => onArrowClick(true)}
          />
        </div>
      )}
    </div>
  );
};
Swimlane.propTypes = {
  navId: PropTypes.string,
  isTv: PropTypes.bool,
  title: PropTypes.string,
  onTitleClick: PropTypes.func,
  items: PropTypes.array,
  tileContainerClass: PropTypes.string,
  className: PropTypes.string,
  separatorColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  swimlaneSponsorData: PropTypes.object,
  backgroundImageUrls: PropTypes.object,
};

export default withFocus(Swimlane);
