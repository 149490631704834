import React from 'react';
import PropTypes from 'prop-types';

import styles from './Arrow.scss';

const scrollPropTypes = {
  scrollAction: PropTypes.func,
  visible: PropTypes.bool,
};

export const LeftArrow = ({ scrollAction, visible = false }) => (
  <div
    className={
      visible
        ? `${styles.arrowLeftContainer}`
        : `${styles.arrowLeftContainer} ${styles.invisibleArrowContainer}`
    }
    onClick={() => scrollAction({ back: true })}
  >
    <div className={styles.arrowLeft} alt="Arrow Left">
      &#10140;
    </div>
  </div>
);
LeftArrow.propTypes = scrollPropTypes;

export const RightArrow = ({ scrollAction, visible = false }) => (
  <div
    className={
      visible
        ? `${styles.arrowRightContainer}`
        : `${styles.arrowRightContainer} ${styles.invisibleArrowContainer}`
    }
    onClick={() => scrollAction({ back: false })}
  >
    <div className={styles.arrowRight} alt="Arrow Right">
      &#10140;
    </div>
  </div>
);
RightArrow.propTypes = scrollPropTypes;
