export const getItemId = index => `GRID-ITEM-${index}`;

const getClosestBelowItemIndex = ({ index, columns, data }) => {
  const itemBelowDirect = data[index + columns];

  if (itemBelowDirect) {
    return index + columns;
  }

  const itemsBelowLength = index % columns;

  for (
    let o = index + columns - 1;
    o > index + columns - itemsBelowLength - 1;
    o -= 1
  ) {
    if (data[o]) {
      return o;
    }
  }
};

export const getGridItemNav = (parentId = '', index, columns, data) => {
  const isFirstItem = index % columns === 0;
  const isLastItem = (index + 1) % columns === 0;
  const nav = {
    id: getItemId(index),
    parent: parentId,
    ...(data[index - columns] && {
      nextup: getItemId(index - columns),
    }),
    ...(!isFirstItem && data[index - 1] && { nextleft: getItemId(index - 1) }),
    ...(!isLastItem && data[index + 1] && { nextright: getItemId(index + 1) }),
  };

  const itemBelowIndex = getClosestBelowItemIndex({
    index,
    columns,
    data,
  });

  if (itemBelowIndex) {
    nav.nextdown = getItemId(itemBelowIndex);
  }

  return nav;
};
