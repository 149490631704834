let rootSelector = state => state.identity;

export const setRootSelector = selector => {
  rootSelector = selector;
};

export const getRootState = state => rootSelector(state);

// Base selectors
export const getProcessId = state => rootSelector(state)?.processId;
export const getVerificationEmail = state =>
  rootSelector(state)?.verificationEmail;
export const getPkat = state => rootSelector(state)?.pkat;
export const getGroupId = state => rootSelector(state)?.groupId;
export const getParentUserId = state => rootSelector(state)?.parentUserId;
export const getParentAccount = state => rootSelector(state)?.parentAccount;
export const getPin = state => rootSelector(state)?.pin;
export const getError = actionType => state =>
  rootSelector(state)?.errors[actionType]?.errors;
export const getParentMode = state => rootSelector(state)?.parentMode;
export const getOnboardingSuccess = state =>
  rootSelector(state)?.onboardingSuccess;
export const getCustomDataVideoBookmark = state =>
  rootSelector(state)?.customData?.bookmark;
export const getCustomDataShowsFavorites = state =>
  rootSelector(state)?.customData?.showsFavorites;
export const getCustomDataShowsFavoritesById = (state, id) => {
  return (
    rootSelector(state)?.customData?.showsFavorites?.find(
      sf => sf.id?.toString() === id?.toString(),
    ) || null
  );
};
export const getCustomDataPodcastsFavorites = state =>
  rootSelector(state)?.customData?.podcastsFavorites;
export const getCustomDataPodcastsFavoritesById = (state, id) => {
  return (
    rootSelector(state)?.customData?.podcastsFavorites?.find(
      pf => pf.id?.toString() === id?.toString(),
    ) || null
  );
};
export const getCustomDataVideoFavorites = state =>
  rootSelector(state)?.customData?.videoFavorites;
export const getCustomDataVideoFavoritesById = (state, id) => {
  return (
    rootSelector(state)?.customData?.videoFavorites?.find(
      vf => vf.id?.toString() === id?.toString(),
    ) || null
  );
};
export const getCustomDataPodcastsEpisodesFavorites = state =>
  rootSelector(state)?.customData?.podcastsEpisodesFavorites;
export const getCustomDataPodcastsEpisodesFavoritesById = (state, id) => {
  return (
    rootSelector(state)?.customData?.podcastsEpisodesFavorites?.find(
      vf => vf.id?.toString() === id?.toString(),
    ) || null
  );
};
export const getCustomDataTopicsFavorites = state =>
  rootSelector(state)?.customData?.topicsFavorites;
export const getCustomDataTopicsFavoritesById = (state, id) => {
  return (
    rootSelector(state)?.customData?.topicsFavorites?.find(
      tf => tf.id?.toString() === id?.toString(),
    ) || null
  );
};

export const getAccessToken = state => rootSelector(state)?.accessToken;

export const getRefreshToken = state => rootSelector(state)?.refreshToken;

export const getSessionData = state => rootSelector(state)?.sessionData;

export const getCustomDataRecentlyWatched = state =>
  rootSelector(state)?.customData?.recentlyWatched;

export const getCustomDataRecentlyWatchedIds = state =>
  getCustomDataRecentlyWatched(state)?.map(i => i.videoId);

export const getCustomDataDisplayedModals = state =>
  rootSelector(state)?.customData?.displayedModals;

export const getCustomDataVideosBlocked = state =>
  rootSelector(state)?.customData?.videosBlocked;

export const getCustomDataShowsBlocked = state =>
  rootSelector(state)?.customData?.showsBlocked;
