import { createAction } from 'redux-actions';

export const CALLBACK = 'callbackMiddleware/CALLBACK';
export const REMOVE_CALLBACK = 'callbackMiddleware/REMOVE_CALLBACK';
const callbackAction = createAction(CALLBACK, functionId => ({
  functionId,
  param: null,
}));

const functionReferences = {};

/*
The callback middleware allows the storage of function references (which are not serializable) in a redux store by
replacing them with an FSA action that can be stored instead and dispatched to execute the function.

The middleware will look at all and only the top level keys
of an FSA action's payload, and if it finds a function reference, save it with a unique id
and replace it with an action that, when dispatched, will trigger the function exection

Dispatching a CALLBACK action will execute the specified function.
Dispatching a REMOVE_CALLBACK action will remove the callback reference.

Components using this middleware should always dispatch REMOVE_CALLBACK to prevent memory leaks
weather the CALLBACK was invoked or not.
*/
const callbackMiddleware = () => next => action => {
  if (typeof action === 'object') {
    // action is stored function, execute it
    if (action.type === CALLBACK) {
      return functionReferences[action.payload.functionId](
        action.payload.param,
      );
    }
    if (action.type === REMOVE_CALLBACK) {
      return delete functionReferences[action.payload.functionId];
    }

    // normal action, look for functions to store
    Object.entries(action.payload || {}).forEach(([key, value]) => {
      if (typeof value === 'function') {
        const functionId = `function${Math.random()
          .toString()
          .slice(2, 10)}`;
        functionReferences[functionId] = value;
        action.payload[key] = callbackAction(functionId);
      }
    });
  }
  return next(action);
};

export default callbackMiddleware;
