import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FocusDiv } from '#/widgets/ui';
import { getAssetData } from '#/redux/modules/brightcove/selectors';
import { setVideoFullscreenBookmark } from '#/redux/modules/appPlayer/actions';
import routes from '#/utils/routes';
import { customRoutes } from '#/config/constants';
import { ROLES } from '#/utils/accessibility';
import styles from './PlayerEpisodesButton.scss';

const EpisodesButton = ({ history, nav = {} }) => {
  const dispatch = useDispatch();
  const videoInfo = useSelector(state => getAssetData(state));

  const buttonClick = useCallback(async () => {
    if (history.location.state?.fromDetails) {
      history.goBack();
      return;
    }

    if (
      history.location.pathname === routes.PLAYER ||
      history.location.pathname === routes.BASE_PLAYER
    ) {
      await dispatch(setVideoFullscreenBookmark());
    }

    history.push(
      `${customRoutes.SHOW_DETAILS}/${videoInfo.custom_fields.show_id}`,
    );
  }, [videoInfo]);

  if (!videoInfo?.custom_fields?.show_id) {
    return null;
  }

  return (
    <FocusDiv
      nav={nav}
      onClick={buttonClick}
      className={styles.playerEpisodesButton}
      classNameFocused={styles.isFocused}
      ariaLabel="All Episodes"
      role={ROLES.BUTTON}
    >
      All Episodes
    </FocusDiv>
  );
};

EpisodesButton.propTypes = {
  history: PropTypes.any,
  nav: PropTypes.object,
};

const PlayerEpisodesButton = withRouter(EpisodesButton);

export default PlayerEpisodesButton;
