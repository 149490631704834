let rootSelector = state => state.firestore;

export const setRootSelector = selector => {
  rootSelector = selector;
};

export const getTopics = state => rootSelector(state)?.topics;

export const getShows = state => rootSelector(state)?.shows;

export const getContainerShows = state => rootSelector(state)?.containerShows;

export const getContainerPodcasts = state =>
  rootSelector(state)?.containerPodcasts;

export const getTopShows = state => rootSelector(state)?.topShows;

export const getHero = state => rootSelector(state)?.hero;

export const getContentDetails = state => {
  const contents = rootSelector(state)?.contents;
  if (contents.length > 0) {
    return contents[0];
  }
};

export const getAvatars = state =>
  rootSelector(state)?.avatars.map(avatar => avatar.image_path);
export const getFirstAvatar = state => getAvatars(state)?.[0];
