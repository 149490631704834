import { startUsage, stopUsage } from '#/services/analytics/analytics';
import { playerConstants } from '#/config/constants';
import reduxModules from '../modules';

const {
  lifecycle: { actions: lifeCycleActions },
  appPlayer: {
    actions: appPlayerActions,
    selectors: { getNormalizedVideoPosition },
  },
  identity: {
    actions: { updateProfileBookmark },
  },
} = reduxModules;

let bookmarkingTimer;
const intervalsBeforeBookmark =
  playerConstants.BOOKMARKING_TIMEOUT / playerConstants.HEARTBEAT_TIMEOUT;
let intervalCounter = 0;

const analytics = store => next => action => {
  if (action) {
    if (action.type === lifeCycleActions.APP_START) {
      startUsage();
    } else if (action.type === lifeCycleActions.APP_QUIT) {
      stopUsage();
    } else if (
      action.type === appPlayerActions.START_BOOKMARKING ||
      action.type === appPlayerActions.START_PLAYBACK
    ) {
      bookmarkingTimer && clearInterval(bookmarkingTimer);
      bookmarkingTimer = setInterval(() => {
        store.dispatch(appPlayerActions.videoHeartbeat());
        if (
          playerConstants.CUSTOM_BOOKMARKING_ENABLED &&
          window.location.href.indexOf('player') > -1
        ) {
          intervalCounter += 1;
          if (intervalCounter === intervalsBeforeBookmark) {
            const normalizedVideoPosition = getNormalizedVideoPosition(
              store.getState(),
            );
            store.dispatch(updateProfileBookmark(normalizedVideoPosition));
            intervalCounter = 0;
          }
        }
      }, playerConstants.HEARTBEAT_TIMEOUT);
    } else if (action.type === appPlayerActions.STOP_BOOKMARKING) {
      bookmarkingTimer && clearInterval(bookmarkingTimer);
      bookmarkingTimer = null;
      intervalCounter = 0;
    }
  }

  return next(action);
};

export default analytics;
