import packageJson from '../../package.json';

const videojs = {
  default: true,
  id: 'videojs',
  importer: () => import('../players/web/videojs.js'),
  extensions: [
    {
      type: 'subtitle',
      importer: () => import('../players/web/subtitleExtensionExternal.js'),
      config: {
        location: 'external',
      },
    },
    {
      type: 'subtitle',
      importer: () => import('../players/web/subtitleExtensionInternal.js'),
      config: {
        location: 'internal',
      },
    },
  ],
};

export const knownPlayers = { videojs };

const loadWith = (...player) => configInvoker => async () => {
  const conf = await configInvoker();
  conf.default.players = [...player, ...conf.default.players];
  return conf;
};

export const enabledPlayers = () => Object.values(knownPlayers);
const loadWithAll = loadWith(...enabledPlayers());

const CONFIG = {
  version: packageJson.version,
  devices: {
    packages: [
      {
        id: 'webos',
        detection: () => import('@accedo/xdk-device-lg-webos/esm/detection.js'),
        defaultConfig: () =>
          import('@accedo/xdk-device-lg-webos/esm/defaultConfig.js'),
        DevicePackage: () =>
          import('@accedo/xdk-device-lg-webos/esm/DevicePackage.js'),
      },
      {
        id: 'tizen',
        detection: () =>
          import('@accedo/xdk-device-samsung-tizen/esm/detection.js'),
        defaultConfig: loadWithAll(() =>
          import('@accedo/xdk-device-samsung-tizen/esm/defaultConfig.js'),
        ),
        DevicePackage: () =>
          import('@accedo/xdk-device-samsung-tizen/esm/DevicePackage.js'),
      },
      {
        id: 'vizio-smartcast',
        detection: () =>
          import('@accedo/xdk-device-vizio-smartcast-contrib/esm/detection.js'),
        defaultConfig: loadWithAll(() =>
          import(
            '@accedo/xdk-device-vizio-smartcast-contrib/esm/defaultConfig.js'
          ),
        ),
        DevicePackage: () =>
          import(
            '@accedo/xdk-device-vizio-smartcast-contrib/esm/DevicePackage.js'
          ),
      },
      {
        id: 'workstation',
        detection: async () => ({
          default: true,
        }),
        defaultConfig: loadWithAll(() =>
          import('@accedo/xdk-device-workstation/esm/defaultConfig.js'),
        ),
        DevicePackage: () =>
          import('@accedo/xdk-device-workstation/esm/DevicePackage.js'),
      },
    ],
    details: {
      workstation: {
        cookieName: 'sensicletv',
        network: {
          polling: {
            interval: 10,
            url: 'https://jsonplaceholder.typicode.com/posts/1',
          },
        },
      },
      webos: {
        cookieName: 'sensicletv',
        network: {
          polling: {
            interval: 10,
            url: 'https://jsonplaceholder.typicode.com/posts/1',
          },
        },
      },
      tizen: {
        cookieName: 'sensicletv',
        network: {
          polling: {
            interval: 10,
            url: 'https://jsonplaceholder.typicode.com/posts/1',
          },
        },
      },
      'vizio-smartcast': {
        cookieName: 'vizio-smartcast',
      },
    },
  },
  logging: {
    level: -1,
  },
};

export default CONFIG;
