import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Page } from '@accedo/vdkweb-tv-ui';
import classNames from 'classnames';
import { focusManager } from '@accedo/vdkweb-navigation';
import { actions } from '#/redux/modules/brightcove/actions';
import styles from './layout.scss';

// Placeholders
import LearningIcon from './learning.svg';
import AccountIcon from './account.svg';
import KidsProfilesIcon from './kids-profiles.svg';
import ParentalControlIcon from './parental-controls.svg';
import ContentFilteringIcon from './content-filtering.svg';
import PolicyIcon from './policy.svg';
import FAQIcon from './faqs.svg';
import HelpIcon from './help.svg';
import LogoutIcon from './logout.svg';
import TermOfServiceIcon from '#/static/images/icons/TermOfService.svg';
import ChildrensPrivacyPolicyIcon from '#/static/images/icons/ChildrensPrivacyPolicy.svg';

import { AccountInfo } from './AccountInfo/AccountInfo';
import { FAQ } from './FAQ/FAQ';
import { Help } from './Help/Help';
import { KidsProfile } from './KidsProfile/KidsProfile';
import { LearningReports } from './LearningReports/LearningReports';
import { ParentalControls } from './ParentalControls/ParentalControls';
import { ContentFiltering } from './ContentFiltering/ContentFiltering';
import { PrivacyPolicy } from './PrivacyPolicy/PrivacyPolicy';
import Document from './Document/Document';
import { toggleLoginSpinner } from '#/redux/modules/spinner/actions';
import { parentZoneTitle, parentZone } from '#/config/strings';
import {
  parentZoneLayoutIdToScreenNames,
  parentZoneScreenName,
  parentZoneSignOutButtonText,
} from '#/config/constants';

// Redux store
import { sendScreenView } from '#/redux/modules/firestore/actions';
import { logout } from '#/redux/modules/identity/actions';
import {
  loadTermsOfService,
  loadChildrenPrivacyPolicy,
} from '#/redux/modules/accedoOne/actions';
import {
  getTermsOfService,
  getChildrenPrivacyPolicy,
  getPrivacyPolicy,
  getHelp,
} from '#/redux/modules/accedoOne/selectors';

import { isWorkstation } from '#/utils/componentStyleConfig';
import { TVContentHeader } from '#/components/TVContentHeader/TVContentHeader';
import { setSpeech, ROLES, setNativeFocus } from '#/utils/accessibility';
import {
  PARENT_ZONE,
  HEADER,
  getHorizontalNav,
} from '#/utils/navigationHelper';
import ParentZoneIcon from '#/static/images/icons/parentzone-tv.svg';
import { actions as modalActions } from '#/redux/modules/modal/actions';
import { FocusButton, FocusDiv } from '#/widgets/ui';

const {
  PAGE,
  CONTAINER,
  PARENT_ZONE_MENU_CONTAINER,
  PARENT_ZONE_SIGN_OUT_BTN,
  PARENTZONE_TV_MENU_ITEM,
} = PARENT_ZONE;

const navMap = {
  PAGE: {
    id: PAGE,
    forwardFocus: CONTAINER,
  },
  CONTAINER: {
    id: CONTAINER,
    forwardFocus: PARENT_ZONE_SIGN_OUT_BTN,
    nextleft: HEADER.PARENT_ZONE_BUTTON,
    useLastFocus: true,
  },
  PARENT_ZONE_MENU_CONTAINER: {
    id: PARENT_ZONE_MENU_CONTAINER,
    nextdown: PARENT_ZONE_SIGN_OUT_BTN,
    parent: CONTAINER,
    useLastFocus: true,
    forwardFocus: `${PARENTZONE_TV_MENU_ITEM}_0`,
  },
  PARENT_ZONE_SIGN_OUT_BTN: {
    id: PARENT_ZONE_SIGN_OUT_BTN,
    nextup: PARENT_ZONE_MENU_CONTAINER,
    parent: CONTAINER,
  },
};

// Layout
const firstMenu = [
  {
    id: 0,
    displayText: parentZone.learningReports,
    icon: LearningIcon,
    component: <LearningReports />,
    noSubContentStyle: true,
  },
  {
    id: 1,
    displayText: parentZone.accountInfo,
    icon: AccountIcon,
    component: <AccountInfo />,
  },
  {
    id: 2,
    displayText: parentZone.kidsProfile,
    icon: KidsProfilesIcon,
    component: <KidsProfile />,
  },
  {
    id: 3,
    displayText: parentZone.parentalControls,
    icon: ParentalControlIcon,
    component: <ParentalControls />,
  },
  {
    id: 4,
    displayText: parentZone.contentFiltering,
    icon: ContentFilteringIcon,
    component: <ContentFiltering />,
  },
];

const secondMenu = [
  {
    id: 7,
    displayText: parentZone.termsOfService,
    icon: TermOfServiceIcon,
    component: <Document selector={getTermsOfService} />,
  },
  {
    id: 4,
    displayText: parentZone.privacyPolicy,
    icon: PolicyIcon,
    component: <PrivacyPolicy />,
  },
  {
    id: 8,
    displayText: parentZone.childrenPrivacyPolicy,
    icon: ChildrensPrivacyPolicyIcon,
    component: <Document selector={getChildrenPrivacyPolicy} />,
  },
  {
    id: 5,
    displayText: parentZone.faq,
    icon: FAQIcon,
    component: <FAQ />,
  },
  {
    id: 6,
    displayText: parentZone.help,
    icon: HelpIcon,
    component: <Help />,
  },
];
const tvMenu = [
  {
    displayText: parentZone.termsOfService,
    selector: getTermsOfService,
  },
  {
    displayText: parentZone.privacyPolicy,
    selector: getPrivacyPolicy,
  },
  {
    displayText: parentZone.childrenPrivacyPolicy,
    selector: getChildrenPrivacyPolicy,
  },
  {
    displayText: parentZone.help,
    selector: getHelp,
  },
];

const ParentZone = () => {
  const dispatch = useDispatch();
  const [selectedMenuItem, setSelectedMenuItem] = useState(firstMenu[0]);
  const [currentMenuIndex, setCurrentMenuIndex] = useState(null);
  const getItemId = index => `${PARENTZONE_TV_MENU_ITEM}_${index}`;
  const tvMenuItemsNav = tvMenu?.map((item, index) => getItemId(index));
  const tvMenuItemsNavMap = {
    ...getHorizontalNav(tvMenuItemsNav, {
      parent: navMap.PARENT_ZONE_MENU_CONTAINER.id,
      internal: {
        nextleft: () => setCurrentMenuIndex(null),
        nextdown: () => setCurrentMenuIndex(null),
      },
    }),
  };

  useEffect(() => {
    dispatch(sendScreenView(parentZoneScreenName));
    dispatch(loadTermsOfService);
    dispatch(loadChildrenPrivacyPolicy);
    return () => dispatch(actions.clearAssetsByReference());
  }, []);

  useEffect(() => {
    dispatch(
      sendScreenView(parentZoneLayoutIdToScreenNames[selectedMenuItem.id]),
    );
  }, [selectedMenuItem]);

  useEffect(() => {
    if (!isWorkstation()) {
      focusManager.changeFocus(PARENT_ZONE_SIGN_OUT_BTN);
      setNativeFocus(navMap.CONTAINER.id);
      requestAnimationFrame(() => {
        dispatch(toggleLoginSpinner(false));
      });
    }
  }, []);

  return (
    <>
      {!isWorkstation() ? (
        <Page nav={navMap.PAGE}>
          <FocusDiv className={styles.tvContainer} nav={navMap.CONTAINER}>
            <TVContentHeader
              visible={!isWorkstation()}
              title={parentZoneScreenName}
            />
            <div className={styles.tvContentContainer}>
              <img
                className={styles.parentZoneLogo}
                src={ParentZoneIcon}
                alt={parentZoneScreenName}
              />
              <div className={styles.parentZoneTitle}>{parentZone.title}</div>
              <div className={styles.parentZoneSubtitle}>
                {parentZone.subtitle}
              </div>
              <FocusDiv nav={navMap.PARENT_ZONE_MENU_CONTAINER}>
                <div className={styles.tvParentZoneMenuContainer}>
                  {tvMenu?.map((item, index) => {
                    return (
                      <>
                        <FocusDiv
                          nav={tvMenuItemsNavMap[getItemId(index)]}
                          ariaLabel={item.displayText}
                          className={classNames(styles.displayText, {
                            [styles.isFocused]: index === currentMenuIndex,
                          })}
                          onFocus={() => {
                            setCurrentMenuIndex(index);
                            setSpeech(`${item.displayText}, ${ROLES.BUTTON}`);
                          }}
                          onClick={() =>
                            dispatch(
                              modalActions.openModal({
                                modalId: 'ParentZoneModalTV',
                                modalData: {
                                  selector: item.selector,
                                  title: item.displayText,
                                },
                              }),
                            )
                          }
                          useNativeFocus={false}
                        >
                          {item.displayText}
                        </FocusDiv>
                        {`${index < tvMenu.length - 1 ? ' | ' : ''}`}
                      </>
                    );
                  })}
                </div>
              </FocusDiv>
              <FocusButton
                nav={navMap.PARENT_ZONE_SIGN_OUT_BTN}
                className={styles.tvParentZoneSignOutBtn}
                onClick={() => dispatch(logout())}
                tertiary
                onFocus={() => setSpeech(parentZoneSignOutButtonText)}
                useNativeFocus={false}
              >
                {parentZoneSignOutButtonText}
              </FocusButton>
            </div>
          </FocusDiv>
        </Page>
      ) : (
        <>
          <Page className={styles.mainContainer} autofocus>
            <FocusDiv className={styles.contentContainer}>
              <div className={styles.leftContainer}>
                <div className={styles.titleLogo}>
                  <h2 className={styles.title}>{parentZoneTitle}</h2>
                </div>
                <div className={styles.menu}>
                  {firstMenu?.map((item, index) => {
                    return (
                      <div key={item.id}>
                        <div
                          onClick={() => setSelectedMenuItem(item)}
                          className={`${styles.itemContainer}
                      ${
                        selectedMenuItem.id === item.id
                          ? styles.itemSelected
                          : null
                      }
                      ${index !== 0 ? styles.smallDivider : null}`}
                        >
                          <img
                            src={item.icon}
                            className={styles.itemImg}
                            alt={item.displayText}
                          />
                          <p className={styles.itemText}>{item.displayText}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className={`${styles.menu}`}>
                  {secondMenu?.map((item, index) => {
                    return (
                      <div key={item.id}>
                        <div
                          onClick={() => setSelectedMenuItem(item)}
                          className={`${styles.itemContainer}
                      ${
                        selectedMenuItem.id === item.id
                          ? styles.itemSelected
                          : null
                      }
                      ${index !== 0 ? styles.smallDivider : null}
                      `}
                        >
                          <img
                            src={item.icon}
                            className={styles.itemImg}
                            alt={item.displayText}
                          />
                          <p className={styles.itemText}>{item.displayText}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div
                  onClick={() => dispatch(logout())}
                  className={styles.signoutMenu}
                >
                  <img src={LogoutIcon} alt="Logout" />
                  <p className={styles.signoutText}>Sign out</p>
                </div>
              </div>
              <div className={styles.rightContainer}>
                <p className={styles.titleText}>
                  {selectedMenuItem.displayText}
                </p>
                <div className={styles.rightContentContainer}>
                  <div
                    className={
                      !selectedMenuItem.noSubContentStyle
                        ? styles.rightSubContentContainer
                        : undefined
                    }
                  >
                    {selectedMenuItem.component}
                  </div>
                </div>
              </div>
            </FocusDiv>
          </Page>
        </>
      )}
    </>
  );
};

export default ParentZone;
