import React, { useCallback, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { withFocus } from '@accedo/vdkweb-navigation';
import { isMobile } from '#/utils/componentStyleConfig';
import leftArrow from '#/static/images/icons/carousel-left.svg';
import rightArrow from '#/static/images/icons/carousel-right.svg';
import styles from './carousel.scss';

const CAROUSEL_LOOP_LIMIT = 3;

const Carousel = ({
  items,
  tileContainerClass,
  className,
  separatorColor,
  backgroundColor,
}) => {
  const totalOffset = useRef(0);
  const translationAmount = useRef(0);
  const translateContainerRef = useRef();
  const currentOffset = useRef(0);
  const startOffset = useRef(0);
  const offsetAmount = useRef(0);

  useLayoutEffect(() => {
    if (items.length > CAROUSEL_LOOP_LIMIT) {
      translationAmount.current =
        translateContainerRef.current.children[1]?.offsetLeft;
      translateContainerRef.current.style.transform = `translateX(-${translationAmount.current}px)`;
      totalOffset.current = -1 * translationAmount.current;
    }
  }, [translateContainerRef, items.length]);

  const onArrowClick = useCallback(
    moveRight => {
      if (moveRight) {
        const tile = translateContainerRef.current.firstChild;
        totalOffset.current -= translationAmount.current;
        requestAnimationFrame(() => {
          translateContainerRef.current.style.transform = `translateX(${totalOffset.current}px)`;
          tile.parentNode.appendChild(tile);
          translateContainerRef.current.style.left = `${totalOffset.current *
            -1 -
            translationAmount.current}px`;
        });
      } else {
        const tile = translateContainerRef.current.lastChild;
        totalOffset.current += translationAmount.current;
        requestAnimationFrame(() => {
          translateContainerRef.current.style.transform = `translateX(${totalOffset.current}px)`;
          tile.parentNode.prepend(tile);
          translateContainerRef.current.style.left = `${totalOffset.current *
            -1 -
            translationAmount.current}px`;
        });
      }
    },
    [translateContainerRef],
  );
  const HandleOnTouchMove = useCallback(
    evt => {
      currentOffset.current =
        evt.nativeEvent?.touches[0]?.clientX - startOffset.current;
      offsetAmount.current = totalOffset.current + currentOffset.current;
      translateContainerRef.current.style.transform = `translateX(${offsetAmount.current}px)`;
    },
    [translateContainerRef, currentOffset, totalOffset],
  );
  const HandleOnTouchEnd = useCallback(() => {
    if (currentOffset.current > 0) {
      const tile = translateContainerRef.current.lastChild;
      totalOffset.current += translationAmount.current;
      requestAnimationFrame(() => {
        translateContainerRef.current.style.transform = `translateX(${totalOffset.current}px)`;
        tile.parentNode.prepend(tile);
        translateContainerRef.current.style.left = `${totalOffset.current * -1 -
          translationAmount.current}px`;
      });
    } else {
      const tile = translateContainerRef.current.firstChild;
      totalOffset.current -= translationAmount.current;
      requestAnimationFrame(() => {
        translateContainerRef.current.style.transform = `translateX(${totalOffset.current}px)`;
        tile.parentNode.appendChild(tile);
        translateContainerRef.current.style.left = `${totalOffset.current * -1 -
          translationAmount.current}px`;
      });
    }
  }, [translateContainerRef, totalOffset, currentOffset]);

  const HandleOnTouchStart = evt => {
    startOffset.current = evt?.nativeEvent?.touches[0]?.clientX;
  };
  return (
    <div
      className={`${styles.container} ${className || ''}`}
      style={{
        borderBottomColor: separatorColor || 'transparent',
        backgroundColor: backgroundColor || 'transparent',
      }}
    >
      <div
        ref={translateContainerRef}
        className={`${styles.itemContainer} ${tileContainerClass || ''}`}
        onTouchMove={evt => HandleOnTouchMove(evt)}
        onTouchEnd={() => HandleOnTouchEnd()}
        onTouchStart={evt => HandleOnTouchStart(evt)}
      >
        {items}
      </div>
      {items.length > CAROUSEL_LOOP_LIMIT && !isMobile() && (
        <>
          <div className={styles.leftArrow}>
            <div
              style={{ backgroundImage: `url(${leftArrow})` }}
              onClick={() => onArrowClick(false)}
            />
          </div>
          <div className={styles.rightArrow}>
            <div
              style={{ backgroundImage: `url(${rightArrow})` }}
              onClick={() => onArrowClick(true)}
            />
          </div>
        </>
      )}
    </div>
  );
};
Carousel.propTypes = {
  items: PropTypes.array,
  tileContainerClass: PropTypes.string,
  className: PropTypes.string,
  separatorColor: PropTypes.string,
  backgroundColor: PropTypes.string,
};

export default withFocus(Carousel);
