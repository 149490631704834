import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './AgeCircle.scss';
import { isMobile } from '#/utils/componentStyleConfig';

const mobileSize = 40;
const getDimension = size => {
  return isMobile
    ? { height: `${size - mobileSize}px`, width: `${size - mobileSize}px` }
    : { height: `${size}px`, width: `${size}px` };
};
const getRadius = size => {
  return isMobile
    ? { borderRadius: `${(size - mobileSize) / 2}px` }
    : { borderRadius: `${size / 2}px` };
};

export const AgeCircle = ({ styleClasses = {}, ageRange, color, size = 0 }) => {
  const {
    containerClass,
    borderClass,
    whiteBorderClass,
    circleClass,
    textClass,
    rangeClass,
  } = styleClasses;
  const colorBorder = styles[`${color}Border`];
  const { whiteBorder } = styles;
  const colorCircle = styles[`${color}`];
  const useSize = !!size;
  const containerStyle = useSize ? getDimension(size) : {};
  const borderStyle = useSize ? { ...containerStyle, ...getRadius(size) } : {};
  const circleStyle = useSize
    ? { ...getDimension(size - 20), ...getRadius(size - 20) }
    : {};
  return (
    <div
      className={cx(styles.circleContainer, containerClass)}
      style={containerStyle}
    >
      <div
        className={cx(
          styles.circleBorder,
          whiteBorder,
          borderClass,
          whiteBorderClass,
        )}
        style={borderStyle}
      />
      <div
        className={cx(styles.circleBorder, colorBorder, borderClass)}
        style={borderStyle}
      />
      <div
        className={cx(styles.circle, colorCircle, circleClass)}
        style={circleStyle}
      >
        <p className={cx(styles.text, textClass)}>ages</p>
        <h2 className={cx(styles.range, rangeClass)}>{ageRange}</h2>
      </div>
    </div>
  );
};

AgeCircle.propTypes = {
  styleClasses: PropTypes.object,
  ageRange: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
};
