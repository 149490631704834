import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import blankAvatar from '#/static/images/icons/selectAvatar.svg';

import styles from './Avatar.scss';

export const Avatar = ({ profile, className }) => (
  <img
    src={profile.avatar || blankAvatar}
    className={cx(styles.avatar, className)}
    alt="Profile avatar"
  />
);
Avatar.propTypes = {
  className: PropTypes.string,
  profile: PropTypes.object,
};

export default Avatar;
