import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import screenfull from 'screenfull';
import xdk, { PlayerConstants as Events } from '@accedo/xdk-core';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import styles from './player.scss';
import { actions } from '#/redux/modules/appPlayer';
import { updateProfileRecentlyWatched } from '#/redux/modules/identity/actions';
import { loadAsset } from '#/redux/modules/brightcove/actions';
import {
  getAssetData,
  getContainers,
} from '#/redux/modules/brightcove/selectors';
import {
  getPlayerState,
  getVideoPlayTime,
  getPrerollAdDuration,
  getPrerollAdByTime,
  getAdsCount,
  getAdPlayed,
  getAdDetailsLoaded,
  getVideoCaptionEnabled,
} from '#/redux/modules/appPlayer/selectors';
import { playerConstants, videoAnalytics } from '#/config/constants';
import { envConfig } from '#/config/env';
import PlayerOverlay from '#/components/Player/PlayerOverlay';
import UpNextVideo from './UpNextVideo';
import AdIndicator from './AdIndicator';
import {
  isPortable,
  isWorkstation,
  isVIZIO,
  isTizen,
  isMobile,
  isiOS,
} from '#/utils/componentStyleConfig';
import {
  getDeepLinkStarted,
  getDeeplinkOutsidePlayer,
} from '#/redux/modules/lifecycle/selectors';

import ClosedCaption from './ClosedCaption';

const {
  BUFFERING: { PROGRESS },
} = Events;

const {
  setVideoPause,
  setVideoPlay,
  setVideoEnded,
  setVideoFullScreen,
  setVideoSeek,
  setVideoSeeked,
  setVideoPlayTime,
  setVideoBookmark,
  setVideoFullscreenBookmark,
  startBookmarking,
  stopBookmarking,
  sendVideoWatchHistory,
  videoInitiated,
  videoStart,
  videoQualityChange,
  videoComplete,
  videoBufferStart,
  videoControl,
  videoInterruption,
  videoProgress,
  startPlaybackTimer,
  stopPlaybackTimer,
  findAdDetails,
  setAdPlayed,
  setAdDetailsLoaded,
} = actions;

const getPlayer = () => xdk.media?.__player?.getPlayerElement() || null;
const playDelay = 5 * 1000;

function VODPlayer({
  videoId,
  videoTitle,
  videoTime = 0,
  videoTextTracks,
  isFullScreenPlayer = false,
  isBackgroundPlayerFullscreen,
  container,
  withUI = true,
  fromHome = false,
}) {
  const dispatch = useDispatch();
  const videoContainerRef = useRef();
  const pauseTimerRef = useRef();
  const startedVideo = useRef(false);
  const [triggerResume, setTriggerResume] = useState(false);
  const [mediaUrl, setMediaUrl] = useState();
  const videoInfo = useSelector(state => getAssetData(state));
  const playerState = useSelector(state => getPlayerState(state));
  const ccEnabled = useSelector(getVideoCaptionEnabled);
  const containers = useSelector(getContainers);
  const [isPodcast, setIsPodcast] = useState(
    videoInfo?.custom_fields?.media_type?.toLowerCase() === 'podcast',
  );
  const deepLinkStarted = useSelector(getDeepLinkStarted);
  const deeplinkOutsidePlayer = useSelector(getDeeplinkOutsidePlayer);

  // Ads
  const [playTime, setPlayTime] = useState(0);
  const { adSequence, adEndTime } = useSelector(
    state => getPrerollAdByTime(state, playTime * 1000),
    shallowEqual,
  );
  const adsCount = useSelector(getAdsCount);
  const prerollAdDuration = useSelector(getPrerollAdDuration);
  const adPlayed = useSelector(getAdPlayed);
  const adDetailsLoaded = useSelector(getAdDetailsLoaded);

  // Playback
  const videoPlayTime = useSelector(getVideoPlayTime);
  const playerInitialResumed = useRef(false);
  const playerFirstPlay = useRef(false);
  const hasPassedProgress = useRef({
    ten: false,
    twentyFive: false,
    fifty: false,
    seventyFive: false,
  });
  const [playerControlsContainer, setPlayerControlsContainer] = useState();
  const [seconds, setSeconds] = useState(0);
  const [showUpNext, setShowUpNext] = useState(false);
  const UP_NEXT_TIMER_INTERVAL = 1000;
  const remainingTimeinSec = xdk.media?.__player?._vjs?.remainingTime();
  const closedCaptionURL = useMemo(() => {
    if (videoTextTracks) {
      return videoTextTracks?.find(item => item.kind === 'captions')?.src;
    }
    const assetId = videoId.toString();
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < containers.length; i++) {
      const asset = containers[i].find(a => a.id === assetId);
      if (asset) {
        return asset?.text_tracks?.find(item => item.kind === 'captions')?.src;
      }
    }
  }, [videoId, videoTextTracks]);
  const ccIsHidden = isMobile()
    ? false
    : playerControlsContainer?.style?.opacity === '1';

  const onPause = useCallback(() => {
    dispatch(setVideoPause());
    pauseTimerRef.current && clearTimeout(pauseTimerRef.current);
    pauseTimerRef.current = setTimeout(() => {
      dispatch(sendVideoWatchHistory());
      pauseTimerRef.current = null;
    }, playerConstants.WATCH_HISTORY_TIMEOUT); // send watch history after 10 seconds being interrupted
  });

  const onPlay = useCallback(() => {
    dispatch(setVideoPlay());

    if (pauseTimerRef.current) {
      clearTimeout(pauseTimerRef.current);
      pauseTimerRef.current = null;
    }
  });

  const onTimeUpdate = useCallback(
    ({ time, duration }) => {
      setTriggerResume(true);
      const percentComplete = Math.floor((time / duration) * 100) / 100;
      setPlayTime(time);
      // SSAI
      // Enable player controls if we are past the pre-roll ad duration
      const progressControl =
        xdk.media?.__player._vjs.controlBar.progressControl;
      if (adDetailsLoaded && time * 1000 > prerollAdDuration) {
        !progressControl.enabled() && progressControl.enable();
      }
      // Set video play time for watch history if past pre-roll ad duration
      if (!videoPlayTime && time * 1000 > prerollAdDuration) {
        dispatch(setVideoPlayTime());
      }
      if (videoPlayTime && time * 1000 < prerollAdDuration) {
        dispatch(sendVideoWatchHistory());
      }
      if (!adPlayed && time * 1000 > prerollAdDuration) {
        dispatch(setAdPlayed(true));
      }

      // Handle very short videos missing events with small tolerances ~40 seconds
      if (
        percentComplete >= 0.75 &&
        percentComplete <= 0.76 &&
        !hasPassedProgress.current.seventyFive
      ) {
        hasPassedProgress.current.seventyFive = true;
        dispatch(videoProgress(duration * 0.75));
        return;
      }

      if (
        percentComplete >= 0.5 &&
        percentComplete <= 0.51 &&
        !hasPassedProgress.current.fifty
      ) {
        hasPassedProgress.current.fifty = true;
        dispatch(videoProgress(duration * 0.5));
        return;
      }

      if (
        percentComplete >= 0.25 &&
        percentComplete <= 0.26 &&
        !hasPassedProgress.current.twentyFive
      ) {
        hasPassedProgress.current.twentyFive = true;
        dispatch(videoProgress(duration * 0.25));
        return;
      }

      if (
        percentComplete >= 0.1 &&
        percentComplete <= 0.11 &&
        !hasPassedProgress.current.ten
      ) {
        hasPassedProgress.current.ten = true;
        dispatch(videoProgress(duration * 0.1));
      }
    },
    [adDetailsLoaded, prerollAdDuration, videoPlayTime, adPlayed],
  );

  const onBuffering = ({ type }) => {
    if (type === PROGRESS) {
      dispatch(videoBufferStart());
    }
  };

  const onEnded = useCallback(() => {
    dispatch(videoComplete(videoInfo.reference_id));
    // When video ends, stop behaviour seeks back to 0
    // Remove listener here to stop buffering events post finish
    xdk.environment.removeEventListener(
      xdk.environment.MEDIA.BUFFERING,
      onBuffering,
    );
    // send onEnd event and reset flag
    dispatch(setVideoEnded());
    dispatch(setVideoEnded(false));
  });

  const onSeeking = useCallback(() => {
    dispatch(setVideoSeek());
  });

  const onSeeked = useCallback(() => {
    dispatch(setVideoSeeked());
  });

  const onFullscreen = useCallback(() => {
    if (!isFullScreenPlayer) {
      const video = videoContainerRef.current.firstChild;
      dispatch(setVideoFullScreen());
      if (screenfull.isFullscreen) {
        video.classList.add(playerConstants.FULL_SCREEN_CLASS);
        dispatch(videoControl(videoAnalytics.controls.enterFullscreen));
      } else {
        video.classList.remove(playerConstants.FULL_SCREEN_CLASS);
        dispatch(videoControl(videoAnalytics.controls.exitFullscreen));
      }
    }
  });
  const onFullscreenMobile = useCallback(() => {
    if (!isFullScreenPlayer) {
      dispatch(setVideoFullScreen());
      const videoContainer = document.querySelector(
        '#sensicle-vjs-html5-player-container',
      );
      if (screenfull.isFullscreen) {
        dispatch(videoControl(videoAnalytics.controls.enterFullscreen));
        videoContainer.classList.add(styles.mobileFullscreen);
      } else {
        videoContainer.classList.remove(styles.mobileFullscreen);
        dispatch(videoControl(videoAnalytics.controls.exitFullscreen));
      }
    }
  });

  const onQualityChanged = useCallback(() => {
    if (startedVideo.current) {
      const defaultHeight = xdk.media?.__player?._playerObject?.clientHeight;
      const defaultWidth = xdk.media?.__player?._playerObject?.clientWidth;
      const qualityLevels = xdk.media?.getBitrates();
      qualityLevels
        ? dispatch(
            videoQualityChange({
              width: qualityLevels?.width,
              height: qualityLevels?.height,
              bitrate: qualityLevels?.bitrate,
            }),
          )
        : dispatch(
            videoQualityChange({
              width: defaultWidth,
              height: defaultHeight,
              bitrate: 0,
            }),
          );
    }
  });

  const onError = useCallback(({ error }) => {
    dispatch(videoInterruption(error?.e, error?.currentTime));
  });

  const onStateChanged = async ({ from, to }) => {
    const MEDIASTATE = xdk.CONSTANT.MEDIA.STATE;
    if (from === MEDIASTATE.CONNECTING && to !== MEDIASTATE.STOPPED) {
      // after content is loaded we need to add to recently watched, start bookmarking timer.
      dispatch(updateProfileRecentlyWatched(videoInfo));
      dispatch(startBookmarking());

      const defaultHeight = xdk.media?.__player?._playerObject?.clientHeight;
      const defaultWidth = xdk.media?.__player?._playerObject?.clientWidth;
      // delay by 1s to get defined bitrate/dimensions
      setTimeout(() => {
        const qualityLevels = xdk.media?.getBitrates();
        qualityLevels
          ? dispatch(
              videoStart({
                width: qualityLevels?.width,
                height: qualityLevels?.height,
                bitrate: qualityLevels?.bitrate,
              }),
            )
          : dispatch(
              videoStart({
                width: defaultWidth,
                height: defaultHeight,
                bitrate: 0,
              }),
            );
      }, 1000);

      startedVideo.current = true;
    }
    if (to === MEDIASTATE.PLAYING) {
      dispatch(startPlaybackTimer());
    } else if (to === MEDIASTATE.STOPPED || to === MEDIASTATE.PAUSED) {
      dispatch(stopPlaybackTimer());
    }
  };

  useEffect(() => {
    if (playerState && startedVideo.current) {
      if (playerInitialResumed.current && playerFirstPlay.current) {
        dispatch(videoControl(playerState));
        return;
      }

      if (playerState === 'Resume') {
        playerFirstPlay.current = true;
      }
      if (playerState === 'Seek End') {
        playerInitialResumed.current = true;
        playerFirstPlay.current = true;
      }
    }
  }, [playerState]);

  useEffect(() => {
    return () => {
      if (!isFullScreenPlayer && xdk.media?.getState() !== 'stopped') {
        dispatch(setVideoBookmark());
      }

      if (fromHome && xdk.media?.getState() !== 'stopped') {
        dispatch(setVideoFullscreenBookmark());
      }
      pauseTimerRef.current && clearTimeout(pauseTimerRef.current);
      dispatch(stopBookmarking());
      dispatch(sendVideoWatchHistory());
      xdk.media?.deinit();
      setMediaUrl(xdk.media.getURL());
    };
  }, []);

  useEffect(() => {
    const adConfigId = (() => {
      if (isTizen()) {
        return envConfig.adConfigIdTizen;
      }
      if (isVIZIO()) {
        return envConfig.adConfigIdVizio;
      }
      return envConfig.adConfigId;
    })();
    if (videoId !== Number(videoInfo?.id)) {
      pauseTimerRef.current && clearTimeout(pauseTimerRef.current);
      dispatch(stopBookmarking());
      dispatch(sendVideoWatchHistory());
      dispatch(loadAsset([{ videoId, adConfigId }]));
    }
  }, [videoId]);

  useEffect(() => {
    if (triggerResume) {
      if (videoTime) {
        playerInitialResumed.current = false;
        xdk.media?.seek(videoTime);
      } else {
        playerInitialResumed.current = true;
      }
    }
  }, [triggerResume, videoTime]);

  useEffect(() => {
    let video;
    (async () => {
      if (videoId === Number(videoInfo?.id) && videoContainerRef.current) {
        startedVideo.current = false;
        playerFirstPlay.current = false;
        playerInitialResumed.current = false;
        hasPassedProgress.current = {
          ten: (videoTime * 1000) / videoInfo.duration >= 0.1,
          twentyFive: (videoTime * 1000) / videoInfo.duration >= 0.25,
          fifty: (videoTime * 1000) / videoInfo.duration >= 0.5,
          seventyFive: (videoTime * 1000) / videoInfo.duration >= 0.75,
        };

        // const testVmap = 'http://localhost:8080/vmap2.xml';
        const { src: brightcoveSource, vmap } = videoInfo.sources.find(
          item =>
            item.type === 'application/x-mpegURL' &&
            item.src.indexOf('https') > -1,
        ); // use HLS sources

        let source;
        // Don't fetch ad info if background player is active
        if (fromHome && !isBackgroundPlayerFullscreen) {
          source = brightcoveSource;
        } else {
          source = vmap
            ? await dispatch(findAdDetails(vmap, videoInfo.duration))
            : brightcoveSource;
        }

        if (!vmap) {
          dispatch(setAdDetailsLoaded(true));
        }

        const thumbnailTrack = videoInfo.text_tracks.find(
          item => item.label === 'thumbnails' && item.src.indexOf('https') > -1,
        )?.src;
        const ccTrack = videoInfo.text_tracks.find(
          item => item.kind === 'captions',
        );

        if (ccTrack?.src.indexOf('https') === -1 && ccTrack.sources?.length) {
          const httpsTrack = ccTrack.sources.find(
            track => track.src.indexOf('https') > -1,
          )?.src;

          if (httpsTrack) {
            ccTrack.src = httpsTrack;
          } else {
            ccTrack.src = null;
          }
        }

        dispatch(
          videoInitiated({
            streamType: videoAnalytics.streamType.VOD,
            playerType: isFullScreenPlayer
              ? videoAnalytics.player.FULLSCREEN
              : videoAnalytics.player.BACKGROUND,
            bitrate: 0,
          }),
        );

        xdk.media?.load(source, {
          parentNode: videoContainerRef.current,
          sec: videoTime,
          title: videoTitle,
          live: false,
          thumbnailTrack,
          ccTrack,
        });
        xdk.media?.__player._vjs.controlBar.progressControl.disable();
        setMediaUrl(xdk.media.getURL());

        video = videoContainerRef.current.firstChild.firstChild;

        if (isPortable()) {
          videoContainerRef.current.firstChild.classList.add(
            playerConstants.MOBILE_VOD,
          );
          if (isiOS()) {
            videoContainerRef.current.firstChild.classList.add(
              'vjs-big-play-centered',
            );
            video.classList.add(playerConstants.IOS_VIDEO);
          }
          if (!isiOS() && closedCaptionURL) {
            video.setAttribute('crossorigin', 'anonymous');
            const captionTrack = document.createElement('track');
            captionTrack.setAttribute('src', closedCaptionURL);
            video.appendChild(captionTrack);
          }
        }

        video.addEventListener('pause', onPause);
        video.addEventListener('play', onPlay);
        video.addEventListener('ended', onEnded);
        video.addEventListener('seeking', onSeeking);
        video.addEventListener('seeked', onSeeked);
        xdk.environment.addEventListener(
          xdk.environment.MEDIA.STATE_CHANGED,
          onStateChanged,
        );
        xdk.environment.addEventListener(
          xdk.environment.MEDIA.BUFFERING,
          onBuffering,
        );
        xdk.environment.addEventListener(xdk.environment.MEDIA.ERROR, onError);
        xdk.media?.__player._vjs.qualityLevels().on('change', onQualityChanged);
        if (isPortable()) {
          videoContainerRef.current.firstChild.classList.add(
            playerConstants.FULL_SCREEN_CLASS,
          );
        } else {
          screenfull.on('change', onFullscreen);
        }

        videoContainerRef.current.firstChild.classList.add(
          styles.vjsPlayerCustom,
        );

        if (isFullScreenPlayer || document.fullscreenElement) {
          dispatch(setVideoFullScreen({ forceFullScreen: true }));
          !isPortable() &&
            videoContainerRef.current.firstChild.classList.add(
              playerConstants.FULL_SCREEN_CLASS,
            );
        } else {
          dispatch(setVideoFullScreen({ resetFullScreen: true }));
        }

        const playerControlsEl = videoContainerRef.current.getElementsByClassName(
          playerConstants.PLAYER_CONTROLS_CONTAINER_CLASS,
        )[0];
        setPlayerControlsContainer(playerControlsEl);
      }
    })();

    return () => {
      video?.removeEventListener('pause', onPause);
      video?.removeEventListener('play', onPlay);
      video?.removeEventListener('ended', onEnded);
      video?.removeEventListener('seeking', onSeeking);
      video?.removeEventListener('seeked', onSeeked);
      xdk.environment.removeEventListener(
        xdk.environment.MEDIA.STATE_CHANGED,
        onStateChanged,
      );
      xdk.environment.removeEventListener(
        xdk.environment.MEDIA.BUFFERING,
        onBuffering,
      );
      xdk.environment.removeEventListener(xdk.environment.MEDIA.ERROR, onError);
      xdk.media?.__player?._vjs
        ?.qualityLevels()
        .off('change', onQualityChanged);
      if (screenfull !== undefined && screenfull.off !== undefined) {
        screenfull.off('change', onFullscreen);
      }
    };
  }, [videoId, videoInfo, videoContainerRef]);

  useEffect(() => {
    if (mediaUrl) {
      xdk.environment.addEventListener(
        xdk.environment.MEDIA.TIME_UPDATE,
        onTimeUpdate,
      );
    }

    return () => {
      xdk.environment.removeEventListener(
        xdk.environment.MEDIA.TIME_UPDATE,
        onTimeUpdate,
      );
    };
  }, [mediaUrl, adDetailsLoaded, prerollAdDuration, videoPlayTime, adPlayed]);

  useEffect(() => {
    if (!remainingTimeinSec) {
      return;
    }

    if (!isWorkstation()) {
      const secondsInterval = setInterval(() => {
        setSeconds(sec => sec + 1);
      }, UP_NEXT_TIMER_INTERVAL);

      if (remainingTimeinSec <= 10) {
        setShowUpNext(true);
      }

      return () => {
        clearInterval(secondsInterval);

        setShowUpNext(false);
      };
    }
  }, [seconds, remainingTimeinSec, showUpNext]);

  useEffect(() => {
    if (videoInfo?.custom_fields?.media_type?.toLowerCase() === 'podcast') {
      videoContainerRef?.current?.firstChild?.classList?.add?.(
        playerConstants.MOBILE_VOD_PODCAST,
      );
      setIsPodcast(true);
    } else {
      videoContainerRef?.current?.firstChild?.classList?.remove?.(
        playerConstants.MOBILE_VOD_PODCAST,
      );
      setIsPodcast(false);
    }
  }, [videoInfo]);

  useEffect(() => {
    // Run only on deeplinks to player on desktop web
    if (isPortable() || !(deepLinkStarted && !deeplinkOutsidePlayer)) {
      return;
    }

    const playTimer = setTimeout(async () => {
      const videoContainer = videoContainerRef.current?.firstChild;
      if (!videoContainer) {
        return;
      }

      try {
        // Try play. An error is thrown if autoplay is blocked.
        getPlayer()
          ?.play()
          .catch(() => {
            // Hide spinner so user is more prompt to click on the play button.
            videoContainer
              ?.querySelector('.vjs-loading-spinner')
              .classList.add('vjs-hidden');

            // switch pause icon to play icon
            videoContainer
              ?.querySelector('.vjs-play-control')
              .classList.add('vjs-paused');
          });
      } catch (error) {
        // Supress any unexpected error
      }
    }, playDelay);
    return () => clearTimeout(playTimer);
  }, [deepLinkStarted, deeplinkOutsidePlayer]);

  useEffect(() => {
    if (isPortable()) {
      const onFullscreenChange = evt => {
        evt.preventDefault();
        onFullscreenMobile();
      };
      // Chrome, Safari and Opera
      document.addEventListener('webkitfullscreenchange', onFullscreenChange);
      // Standard syntax
      document.addEventListener('fullscreenchange', onFullscreenChange);
      // Firefox
      document.addEventListener('mozfullscreenchange', onFullscreenChange);
      // IE / Edge
      document.addEventListener('msfullscreenchange', onFullscreenChange);
      return () => {
        // Standard syntax
        document.removeEventListener('fullscreenchange', onFullscreenChange);
        // Firefox
        document.removeEventListener('mozfullscreenchange', onFullscreenChange);
        // Chrome, Safari and Opera
        document.removeEventListener(
          'webkitfullscreenchange',
          onFullscreenChange,
        );
        // IE / Edge
        document.removeEventListener('msfullscreenchange', onFullscreenChange);
      };
    }
  }, []);

  return (
    <div
      className={`${
        isFullScreenPlayer
          ? styles.fullscreenVideoWrapper
          : styles.homeVideoWrapper
      }`}
    >
      {!isPodcast && adDetailsLoaded && prerollAdDuration && !adPlayed ? (
        <AdIndicator {...{ playTime, adSequence, adEndTime, adsCount }} />
      ) : null}
      {isPodcast && (
        <div
          className={styles.podcastPlayerImage}
          style={{
            backgroundImage: `url(${videoInfo?.custom_fields?.episode_key_art_square})`,
          }}
        />
      )}
      <div
        style={{ pointerEvents: isPortable() ? 'auto' : 'none' }}
        ref={videoContainerRef}
      />
      <UpNextVideo
        remainingTimeinSec={remainingTimeinSec}
        fromHome={fromHome}
        showUpNext={showUpNext}
        withUI={withUI}
      />
      {withUI && playerControlsContainer && (
        <PlayerOverlay
          container={playerControlsContainer}
          fullscreenContainer={container}
          fromHome={fromHome}
          currentTime={playTime}
          playerState={playerState}
        />
      )}
      {!isMobile() && closedCaptionURL && ccEnabled && adPlayed && (
        <ClosedCaption
          adDuration={prerollAdDuration || 0}
          currentTime={playTime}
          captionUrl={closedCaptionURL}
          isHidden={ccIsHidden}
        />
      )}
    </div>
  );
}

VODPlayer.propTypes = {
  videoId: PropTypes.number,
  videoTitle: PropTypes.string,
  videoTime: PropTypes.number,
  videoTextTracks: PropTypes.object,
  isFullScreenPlayer: PropTypes.bool,
  isBackgroundPlayerFullscreen: PropTypes.bool,
  container: PropTypes.object,
  withUI: PropTypes.bool,
  fromHome: PropTypes.bool,
};

export default VODPlayer;
