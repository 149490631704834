export const imgixUrl = (url, style) => {
  if (!url) {
    return '';
  }

  let newUrl = `${url}?`;
  Object.keys(style).forEach(key => {
    newUrl += `${key}=${style[key]}&`;
  });
  return newUrl;
};

export default imgixUrl;
