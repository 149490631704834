import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import GreenBackground from '#/static/images/background-images/Green.svg';
import styles from './Footer.scss';

const Footer = ({ className } = {}) => (
  <div
    className={cx(styles.footer, className)}
    style={{ backgroundImage: `url(${GreenBackground})` }}
  />
);

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
