import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { ProfileImage } from '#/widgets/ui';

import { profileSwitcherImage, profileSwitcher } from './profileSwitcher.scss';

const ProfileSwitcher = ({
  profiles = [],
  selectedProfileId,
  onProfileSelect,
}) => {
  const profileList = useMemo(() => {
    if (!profiles?.length) {
      return [];
    }

    return profiles?.sort(profile => profile.id === selectedProfileId && -1);
  }, [profiles?.length]);

  return (
    <div className={profileSwitcher}>
      {profileList.map(({ avatar, id, name } = {}) => (
        <ProfileImage
          key={id}
          name={name}
          isSelected={id === selectedProfileId}
          src={avatar}
          className={profileSwitcherImage}
          onClick={() => onProfileSelect(id)}
        />
      ))}
    </div>
  );
};

ProfileSwitcher.propTypes = {
  profiles: PropTypes.array,
  onProfileSelect: PropTypes.func,
  selectedProfileId: PropTypes.number,
};

export default React.memo(ProfileSwitcher, (prevProps, nextProps) => {
  return prevProps.selectedProfileId === nextProps.selectedProfileId;
});
