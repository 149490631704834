import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withFocus } from '@accedo/vdkweb-navigation';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import {
  getContainerData,
  getAssetData,
} from '#/redux/modules/brightcove/selectors';
import {
  loadAsset,
  saveSponsoredVideos,
} from '#/redux/modules/brightcove/actions';
import {
  heroBannerEnabled,
  getEntryById,
} from '#/redux/modules/accedoOne/selectors';
import { getCurrentProfile } from '#/redux/modules/onboarding/selectors';
import {
  getCustomDataVideosBlocked,
  getCustomDataShowsBlocked,
} from '#/redux/modules/identity/selectors';

import getVw from '#/utils/getVw';
import imgixUrl from '#/utils/imgix';
import { subRoutes, mainRoutes } from '#/utils/routes';
import {
  containersTypeAlias,
  imgixTileResizeParameters,
  SWIMLANE_LENGTH,
  SWIMLANE_RIGHT_ARROW,
  SWIMLANE_LEFT_ARROW,
  mainPageNames,
} from '#/config/constants';

import VideoCard from '#/components/VideoCard/VideoCard';
import Swimlane from '#/components/Swimlane/Swimlane';
import styles from './playlistSwimlaneFactory.scss';
import { isWorkstation } from '#/utils/componentStyleConfig';
import { getDurationLabel, setSpeech } from '#/utils/accessibility';
import { isItemOutsideScreen } from '#/utils/isItemOutsideScreen';
import { FocusDiv } from '#/widgets/ui';

import usePrevious from '#/hooks/usePrevious';
import { isContentBlocked } from '../../../utils/isContentBlocked';

const SeeAllCard = ({
  nav,
  onClick,
  className,
  style,
  children,
  ariaLabel,
}) => {
  return (
    <FocusDiv
      id={nav.id}
      onClick={onClick}
      className={`${className} ${nav.isFocused ? 'focused' : ''}`}
      style={style}
      aria-label={ariaLabel}
    >
      {children}
    </FocusDiv>
  );
};
const FocusSeeAllCard = withFocus(SeeAllCard);

SeeAllCard.propTypes = {
  nav: PropTypes.object,
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.any,
  style: PropTypes.any,
  ariaLabel: PropTypes.string,
};

// playlist data loaded in Home
const PlaylistSwimlaneFactory = ({
  title,
  videos,
  containerId,
  playlistId,
  onTileClick,
  nav = { id: '' },
  ageTheme,
  enableSeeAll = true,
  className,
  history,
  onFocus,
  fromHome = false,
  location,
  enablePodcasts = false,
  enableAllUpNextVideo = false,
  recentlyWatched = false,
  livestreamTileEntry,
  sponsoredTileEntry,
  livestreamTilePosition,
  sponsoredTilePosition,
  swimlaneSponsor,
}) => {
  const dispatch = useDispatch();
  const currentProfile = useSelector(getCurrentProfile);
  const customDataShowsBlocked = useSelector(getCustomDataShowsBlocked);
  const customDataVideosBlocked = useSelector(getCustomDataVideosBlocked);
  const items =
    videos ||
    useSelector(state => getContainerData(state, [containerId]))[containerId] ||
    [];

  const livestreamTileEntryData =
    useSelector(state => getEntryById(state, livestreamTileEntry)) || null;
  const sponsoredTileEntryData =
    useSelector(state => getEntryById(state, sponsoredTileEntry)) || null;
  const swimlaneSponsorData =
    useSelector(state => getEntryById(state, swimlaneSponsor)) || null;
  const backgroundImageUrls =
    useSelector(state =>
      getEntryById(state, swimlaneSponsorData?.backgroundImageUrls),
    ) || null;
  const sponsoredTileVideo = useSelector(state => getAssetData(state)) || null;
  const { separatorColor, backgroundColor, pageLimit } =
    useSelector(state => getEntryById(state, containerId)) || {};
  const { enabled: bgPlayerDisabled } = useSelector(heroBannerEnabled);
  const getItemId = useCallback(index => `${nav.id}-VIDEO-${index}`, []);
  const SEE_ALL = `${nav.id}-see-all`;
  const [currentIndex, setCurrentIndex] = useState(0);
  const previousIndex = usePrevious(currentIndex);
  const currentPath = location?.pathname?.split('/').slice(-1)[0];
  const limit = pageLimit || SWIMLANE_LENGTH;

  const [rowChanged, setRowChanged] = useState(true);

  const handleNavigation = useCallback((nextIndex, itemsLength) => {
    if (nextIndex < 0 || nextIndex > itemsLength - 1) {
      return;
    }
    setRowChanged(false);
    setCurrentIndex(nextIndex);
    return false;
  }, []);
  const generateItemNavs = useCallback(
    i => {
      return {
        parent: nav.id,
        id: getItemId(i),
        ...(i !== 0 ? { nextleft: getItemId(i - 1) } : {}),
        ...(i + 1 === limit && enableSeeAll
          ? { nextright: SEE_ALL }
          : i !== items.length - 1
          ? { nextright: getItemId(i + 1) }
          : {}),
        internal: {
          nextright: () => handleNavigation(i + 1, items.length),
          nextleft: () => handleNavigation(i - 1, items.length),
          nextup: () => setRowChanged(true),
          nextdown: () => setRowChanged(true),
        },
      };
    },
    [items],
  );

  useEffect(() => {
    if (sponsoredTileEntryData?.brightcoveVideoId) {
      dispatch(
        loadAsset([{ videoId: sponsoredTileEntryData?.brightcoveVideoId }]),
      );
      dispatch(saveSponsoredVideos(sponsoredTileEntryData?.brightcoveVideoId));
    }
  }, [sponsoredTileEntryData?.brightcoveVideoId]);

  useEffect(() => {
    const currentItemId =
      currentIndex === limit && enableSeeAll
        ? SEE_ALL
        : getItemId(currentIndex);
    if (!isWorkstation() && isItemOutsideScreen(currentItemId)) {
      if (previousIndex && previousIndex < currentIndex) {
        document.getElementById(SWIMLANE_RIGHT_ARROW + nav.id)?.click();
      } else if (previousIndex && previousIndex > currentIndex) {
        document.getElementById(SWIMLANE_LEFT_ARROW + nav.id)?.click();
      }
    }
  }, [currentIndex, previousIndex]);

  const createAriaLabel = useCallback(
    item => {
      const label = `${item.name}, ${
        item.custom_fields?.subtitle
      }  ${getDurationLabel(item.duration)}`;
      if (rowChanged) {
        return `${title || 'Videos'}, ${label}`;
      }
      return label;
    },
    [rowChanged],
  );

  const navigateSeeAll = useCallback(() => {
    if (items?.length === 0) {
      return;
    }
    let redirectpath;

    if (recentlyWatched) {
      redirectpath = `${mainRoutes.RECENTLY_WATCHED}/${containerId}/${containersTypeAlias.CONTAINER_RECENTLY_WATCHED}`;
    } else {
      const path = history.location?.pathname?.split('/');
      const containerAlias =
        currentPath === mainPageNames.FAVORITES && !isWorkstation()
          ? containersTypeAlias.TVCONTAINER_FAV_VIDEOS
          : containersTypeAlias.CONTAINER_OVP_PLAYLIST;

      redirectpath = `/${path[1]}/${path[2]}/${subRoutes.SEE_ALL}/${items[limit].id}/${title}/${containerAlias}/${playlistId}`;
    }

    history.push(redirectpath);
  }, [items]);

  const HandleLiveStream = () => {
    const pathname = `${mainRoutes.LIVETV}/${currentProfile?.age}`;
    history.replace('/');
    history.push(pathname);
  };

  const videoCards = useMemo(() => {
    const sponseredItem = items;
    livestreamTileEntryData &&
      sponseredItem[livestreamTilePosition] !== {} &&
      sponseredItem.splice(livestreamTilePosition, 0, {});
    sponsoredTileEntryData &&
      sponseredItem[sponsoredTilePosition] !== {} &&
      sponseredItem.splice(sponsoredTilePosition, 0, sponsoredTileVideo);

    if (
      livestreamTileEntryData &&
      sponsoredTileEntryData &&
      livestreamTilePosition >= sponsoredTilePosition
    ) {
      livestreamTilePosition += 1;
    }
    const cards = sponseredItem
      .slice(0, enableAllUpNextVideo ? items.length : limit)
      .map((item, index) =>
        (livestreamTileEntryData || sponsoredTileEntryData) &&
        (index === livestreamTilePosition ||
          index === sponsoredTilePosition) ? (
          index === livestreamTilePosition ? (
            <VideoCard
              nav={generateItemNavs(index)}
              key={getItemId(index)}
              videoId={null}
              imgUrl={livestreamTileEntryData?.imageUrl}
              title={livestreamTileEntryData?.title}
              showTitle={livestreamTileEntryData?.displaySubtext}
              referenceId={null}
              roundedCorners={ageTheme?.tileRounded}
              onClick={HandleLiveStream}
              onItemFocus={onFocus}
              className={classNames(styles.seeAllContainer, currentPath || '', {
                [styles.isTv]: !isWorkstation(),
              })}
              fromHome={fromHome}
              duration={null}
              ariaLabel="Live Tile"
              replace
              blocked={false}
              livestreamTileEntryData={livestreamTileEntryData}
            />
          ) : (
            <VideoCard
              nav={generateItemNavs(index)}
              key={getItemId(index)}
              videoId={Number(sponsoredTileVideo?.id)}
              imgUrl={
                sponsoredTileVideo.custom_fields?.episode_key_art_horizontal ||
                sponsoredTileVideo.custom_fields?.episode_key_art_square ||
                sponsoredTileVideo.thumbnail
              }
              title={sponsoredTileVideo?.name}
              showTitle={sponsoredTileVideo?.custom_fields?.subtitle}
              referenceId={sponsoredTileVideo?.reference_id}
              roundedCorners={ageTheme?.tileRounded}
              onClick={onTileClick(sponsoredTileVideo, index)}
              onItemFocus={onFocus}
              className={classNames(styles.seeAllContainer, currentPath || '', {
                [styles.isTv]: !isWorkstation(),
              })}
              fromHome={fromHome}
              duration={sponsoredTileVideo.duration}
              ariaLabel={createAriaLabel(sponsoredTileVideo)}
              replace
              podcastShow={false}
              blocked={false}
              sponsoredTileEntryData={sponsoredTileEntryData}
            />
          )
        ) : (
          <VideoCard
            nav={generateItemNavs(index)}
            key={getItemId(index)}
            videoId={bgPlayerDisabled ? undefined : Number(item.id)}
            imgUrl={
              item.custom_fields?.episode_key_art_horizontal ||
              item.custom_fields?.episode_key_art_square ||
              item.thumbnail
            }
            title={item.name}
            showTitle={item.custom_fields?.subtitle}
            duration={item.duration}
            roundedCorners={ageTheme?.tileRounded}
            onClick={item.onClick ? item.onClick : onTileClick(item, index)}
            onItemFocus={onFocus}
            className={classNames(styles.seeAllContainer, currentPath || '', {
              [styles.isTv]: !isWorkstation(),
            })}
            referenceId={item.reference_id}
            fromHome={fromHome}
            ariaLabel={createAriaLabel(item)}
            podcastShow={
              enablePodcasts ||
              item.custom_fields?.media_type?.toLowerCase() === 'podcast'
            }
            replace
            blocked={isContentBlocked(currentProfile, item)}
          />
        ),
      );

    if (
      (items.length > limit && enableSeeAll) ||
      (items.length && recentlyWatched)
    ) {
      const thumbnail = recentlyWatched
        ? items[0]?.thumbnail
        : items[limit]?.thumbnail;

      cards.push(
        <FocusSeeAllCard
          nav={{
            id: SEE_ALL,
            nextleft: getItemId(limit - 1),
            parent: nav.id,
          }}
          key={SEE_ALL}
          onClick={navigateSeeAll}
          className={classNames(styles.seeAllContainer, currentPath || '', {
            [styles.isTv]: !isWorkstation(),
          })}
          style={{
            borderRadius: ageTheme?.tileRounded ? getVw(24) : '0px',
            outline: 'none',
          }}
          onFocus={() => {
            setSpeech(`See all, ${title}`);
            onFocus();
          }}
          ariaLabel="See All"
        >
          <img
            aria-hidden="true"
            className={styles.seeAllImage}
            src={
              imgixUrl(
                items[limit]?.custom_fields?.episode_key_art_horizontal,
                imgixTileResizeParameters.VIDEOS,
              ) || thumbnail
            }
            alt=""
          />
          <div id={SEE_ALL} aria-hidden="true">
            <p>See all</p>
          </div>
        </FocusSeeAllCard>,
      );
    }

    return cards;
  }, [
    items,
    rowChanged,
    customDataShowsBlocked,
    customDataVideosBlocked,
    currentProfile?.videosBlocked?.length,
  ]);

  const videosSwimlaneNav = {
    ...nav,
    forwardFocus: `${nav.id}-VIDEO-0`,
    useLastFocus: true,
  };

  return videoCards.length ? (
    <Swimlane
      isTv={!isWorkstation()}
      className={className}
      items={videoCards}
      title={title}
      onTitleClick={navigateSeeAll}
      nav={videosSwimlaneNav}
      navId={nav.id}
      separatorColor={separatorColor}
      backgroundColor={backgroundColor}
      swimlaneSponsorData={swimlaneSponsorData}
      backgroundImageUrls={backgroundImageUrls}
    />
  ) : null;
};
PlaylistSwimlaneFactory.propTypes = {
  title: PropTypes.string,
  videos: PropTypes.array,
  containerId: PropTypes.string,
  playlistId: PropTypes.string,
  onTileClick: PropTypes.func,
  nav: PropTypes.object,
  ageTheme: PropTypes.object,
  history: PropTypes.object,
  className: PropTypes.string,
  enableSeeAll: PropTypes.bool,
  onFocus: PropTypes.func,
  fromHome: PropTypes.bool,
  enablePodcasts: PropTypes.bool,
  enableAllUpNextVideo: PropTypes.bool,
  recentlyWatched: PropTypes.bool,
  location: PropTypes.object,
  livestreamTileEntry: PropTypes.object,
  sponsoredTileEntry: PropTypes.object,
  swimlaneSponsor: PropTypes.object,
  livestreamTilePosition: PropTypes.number,
  sponsoredTilePosition: PropTypes.number,
};

export default withRouter(PlaylistSwimlaneFactory);
