import { envConfig } from '#/config/env';

const firestoreConfig = envConfig.FIRESTORE_CONFIG;
export default {
  FIREBASE_APP_SCRIPT:
    'https://www.gstatic.com/firebasejs/8.4.1/firebase-app.js',
  FIREBASE_STORE_SCRIPT:
    'https://www.gstatic.com/firebasejs/8.4.1/firebase-firestore.js',
  FIREBASE_ANALYTICS_SCRIPT:
    'https://www.gstatic.com/firebasejs/8.4.1/firebase-analytics.js',
  FIREBASE_INSTALLATIONS_SCRIPT:
    'https://www.gstatic.com/firebasejs/8.4.1/firebase-installations.js',
  apiKey: firestoreConfig.FIRESTORE_KEY,
  authDomain: firestoreConfig.FIRESTORE_DOMAIN,
  projectId: firestoreConfig.FIRESTORE_PROJECT,
  storageBucket: firestoreConfig.FIRESTORE_STORAGE,
  messagingSenderId: firestoreConfig.FIRESTORE_MESSENGER,
  appId: firestoreConfig.FIRESTORE_APP,
  measurementId: firestoreConfig.FIRESTORE_MEASUREMENT,
};
