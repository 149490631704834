import { handleActions } from 'redux-actions';
import { setIsSuspended, setTextToSpeech } from './actions';

const initialState = {
  isAppSuspended: false,
  suspendedCounter: 0,
  ttsEnabled: false,
};

const setTextToSpeechReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    ttsEnabled: payload,
  };
};
const setIsSuspendedReducer = (state = initialState, { payload }) => {
  const counter = payload
    ? (state.suspendedCounter += 1)
    : state.suspendedCounter;

  return {
    ...state,
    isAppSuspended: payload,
    suspendedCounter: counter,
  };
};

const reducer = handleActions(
  {
    [setIsSuspended]: setIsSuspendedReducer,
    [setTextToSpeech]: setTextToSpeechReducer,
  },
  initialState,
);

export default reducer;
