import { handleActions } from 'redux-actions';
import {
  appStart,
  appQuit,
  deepLinkStart,
  deepLinkQuit,
  userAutoloaded,
  deeplinkOutsidePlayer,
} from './actions';

const initialState = {
  appStarted: false,
  deepLinkStarted: false,
  userAutoloaded: null,
  deeplinkOutsidePlayer: null,
};

const handleAppStart = (state = initialState) => ({
  ...state,
  appStarted: true,
});

const handleAppQuit = (state = initialState) => ({
  ...state,
  appStarted: false,
});

const handleDeepLinkQuit = (state = initialState) => ({
  ...state,
  deepLinkStarted: false,
});

const handleDeepLinkStart = (state = initialState) => ({
  ...state,
  deepLinkStarted: true,
});

const handleUserAutoloaded = (state = initialState, { payload }) => ({
  ...state,
  userAutoloaded: payload,
});

const handleDeeplinkOutsidePlayer = (state = initialState, { payload }) => ({
  ...state,
  deeplinkOutsidePlayer: payload,
});

const reducer = handleActions(
  {
    [appStart]: handleAppStart,
    [appQuit]: handleAppQuit,
    [deepLinkQuit]: handleDeepLinkQuit,
    [deepLinkStart]: handleDeepLinkStart,
    [userAutoloaded]: handleUserAutoloaded,
    [deeplinkOutsidePlayer]: handleDeeplinkOutsidePlayer,
  },
  initialState,
);

export default reducer;
