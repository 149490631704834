import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actions as identityActions } from '#/redux/modules/identity/actions';
import { getParentUserId } from '#/redux/modules/identity/identityUser/selectors';
import { getCurrentProfileId } from '#/redux/modules/onboarding/selectors';
import { getDeepLinkStarted } from '#/redux/modules/lifecycle/selectors';
import styles from './BackArrowButton.scss';
import backArrowIcon from '#/static/images/icons/arrow-back.svg';
import backArrowIconBlack from '#/static/images/icons/arrow-back-black.svg';

const BackArrowButton = ({
  history,
  className,
  onNavigateBack = null,
  black = false,
}) => {
  const dispatch = useDispatch();
  const profileId = useSelector(getCurrentProfileId);
  const parentUserId = useSelector(getParentUserId);
  const deepLinkStarted = useSelector(getDeepLinkStarted);
  const onDeepLinkBack = () => {
    if (profileId === parentUserId && deepLinkStarted) {
      dispatch(identityActions.setParentMode(true));
    }
  };
  return (
    <img
      className={`${styles.backArrowButton} ${className && className}`}
      src={black ? backArrowIconBlack : backArrowIcon}
      onClick={() => {
        onDeepLinkBack();
        onNavigateBack?.();
        history.goBack();
      }}
      alt="Back"
      role="presentation"
    />
  );
};
BackArrowButton.propTypes = {
  history: PropTypes.object,
  className: PropTypes.string,
  onNavigateBack: PropTypes.func,
  black: PropTypes.bool,
};

export default withRouter(BackArrowButton);
