let rootSelector = state => state.modal;

export const setRootSelector = selector => {
  rootSelector = selector;
};

export const isOpen = state => rootSelector(state)?.open;
export const getModal = state => rootSelector(state)?.modalId;
export const getSuccessAction = state => rootSelector(state)?.successAction;
export const getFinallyAction = state => rootSelector(state)?.finallyAction;
export const getModalData = state => rootSelector(state)?.modalData;
export const getinputFocus = state => rootSelector(state)?.inputFocus;
