/* eslint-disable react/no-danger */

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { requestFAQ } from '#/redux/modules/accedoOne/actions';
import { getFAQ } from '#/redux/modules/accedoOne/selectors';

import parseTextForLinks from '#/utils/parseTextForLinks';
import styles from './FAQ.scss';

export const FAQ = () => {
  const dispatch = useDispatch();

  const faqText = useSelector(state => getFAQ(state));

  useEffect(() => {
    dispatch(requestFAQ);
  }, []);

  return (
    <div>
      {faqText && faqText.questions
        ? faqText.questions.map((object, i) => {
            return (
              <div className={styles.faqText} key={i}>
                <p className={styles.question}> {object.question}</p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: object.answer && parseTextForLinks(object.answer),
                  }}
                />
              </div>
            );
          })
        : null}
    </div>
  );
};

FAQ.propTypes = {};

export default FAQ;
