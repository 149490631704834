import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '#/redux/modules/user/actions';
import { sendScreenView } from '#/redux/modules/firestore/actions';
import { actions as modalActions } from '#/redux/modules/modal/actions';
import {
  onboardingStepOneDesc,
  onboardingStepOneTitle,
  onboardingStepOnePrivacy,
  onboardingStepOneDescMobile,
  enterBirthYearHelperText,
} from '#/config/strings';
import {
  getSensicalTvConfig,
  getGreenLogoUri,
} from '#/redux/modules/accedoOne/selectors';
import { Button, StepIndicator, DigitEntry } from '#/widgets/ui';
import { authScreenNames } from '#/config/constants';
import Logo from '#/static/images/icons/logo.svg';
import Exit from '#/static/images/icons/exit.svg';
import GreenBackground from '#/static/images/background-images/Green.svg';

import authStyles from '../authflow.scss';
import CancelButton from '../CancelButton';

import { authFlowRoutes } from '#/utils/routes';
import styles from './AgeGate.scss';

import { isTouchDevice } from '#/utils/componentStyleConfig';

const AgeGate = ({ history }) => {
  const dispatch = useDispatch();
  const [ageValid, setAgeValid] = useState(false);
  const [year, setYear] = useState(null);
  const [showPrivacy, setShowPrivacy] = useState(true);

  const { logoUri = '' } =
    useSelector(state => getSensicalTvConfig(state)) || {};

  const greenLogoUri = useSelector(getGreenLogoUri) || '';

  const onSubmit = useCallback(() => {
    if (ageValid) {
      dispatch(actions.setAgeVerified({ ageVerified: true }));

      if (isTouchDevice()) {
        history.push(authFlowRoutes.ACCOUNT_CREATION);
      } else {
        history.push(authFlowRoutes.PROFILE_CREATION);
      }
    }
  }, [ageValid, history, dispatch]);

  useEffect(() => {
    dispatch(sendScreenView(authScreenNames.AGE_GATE));
  }, []);

  return (
    <div
      className={cx(
        authStyles.authFlow,
        authStyles.onboardFlow,
        styles.ageGate,
      )}
      style={{ backgroundImage: `url(${GreenBackground})` }}
    >
      <CancelButton />
      {!isTouchDevice() && (
        <StepIndicator step="1" className={styles.stepIndicator} />
      )}
      <img
        className={styles.logo}
        src={isTouchDevice() ? greenLogoUri : logoUri || Logo}
        alt="Logo"
      />
      <div
        className={`${authStyles.pageDescription} ${authStyles.verifyAgeDescription}`}
      >
        <h1 className={authStyles.title}>{onboardingStepOneTitle}</h1>
        <p className={authStyles.stepOneDesc}>
          {isTouchDevice()
            ? onboardingStepOneDescMobile
            : onboardingStepOneDesc}
        </p>
      </div>
      <div className={styles.ageEntryContainer}>
        <DigitEntry
          onChange={(valid, value) => {
            setAgeValid(valid);
            setYear(value);
          }}
          onSubmit={onSubmit}
          validator={value => {
            const currentYear = new Date().getFullYear();
            return currentYear - value >= 18 && currentYear - value < 124;
          }}
        />
        {isTouchDevice() && !(!!year && !ageValid) && (
          <div className={authStyles.helperText}>
            {enterBirthYearHelperText}
          </div>
        )}
        {
          <p
            className={cx(styles.error, {
              [styles.visible]: !!year && !ageValid,
            })}
          >
            You must be an adult to sign up for Sensical
          </p>
        }
      </div>
      <Button
        onClick={onSubmit}
        selected={ageValid}
        className={((!ageValid && 'static') || '', styles.btnContinue)}
      >
        Continue
      </Button>
      {showPrivacy && (
        <div className={authStyles.privacyContainer}>
          <span>
            {onboardingStepOnePrivacy}
            <span
              className={authStyles.privacyAction}
              onClick={() =>
                dispatch(
                  modalActions.openModal({ modalId: 'PrivacyAgreementModal' }),
                )
              }
            >
              privacy policy
            </span>
          </span>

          <div
            className={authStyles.privacyClose}
            style={{ backgroundImage: `url(${Exit})` }}
            onClick={() => setShowPrivacy(false)}
            alt="Close"
          />
        </div>
      )}
    </div>
  );
};
AgeGate.propTypes = {
  history: PropTypes.object,
};

export default AgeGate;
