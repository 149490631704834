import { handleActions } from 'redux-actions';
import xdk from '@accedo/xdk-core';
import { actions } from './actions';
import { videoAnalytics } from '#/config/constants';

const initialState = {
  playerState: '',
  fullScreen: false,
  playerEnded: false,
  videoCaptionEnabled: false,
  videoCaptionStyles: {},
  videoContent: {
    playlists: [],
    currentPlaylistIndex: null,
    currentVideoIndex: null,
    currentVideo: {
      time: 0,
      videoId: null,
      videoTitle: '',
      videoShowTitle: '',
      videoReferenceId: null,
      isPodcast: false,
    },
    // initiation needed with videoContent so that when old video unmounts
    // it can use the old value within the analytics object without being overriden by
    // setting video content
    initiation: 'autoplay',
    bgBookmarkEnabled: true,
  },
  bookmarkedVideoContent: null, // same structure as videoContent when set
  fullscreenBookmarkedVideoContent: null, // same structure as videoContent when set
  analytics: {
    playTime: 0,
    videoId: null,
    videoAgeGroup: null,
    videoDuration: null,
    videoBitrate: null,
    videoDimensions: null,
    streamType: 'On Demand', // 'On Demand' or 'Linear'
    initiationMethod: 'autoplay', // 'autoplay' or 'manual'
    initializeTime: 0,
    playerType: 'background', // 'background' or 'fullscreen'
  },
  epgData: {},
  adDetails: {
    preroll: {
      duration: 0,
      ads: [],
    },
  },
  adDetailsLoaded: false,
  adPlayed: false,
  playbackTimer: null,
  backgroundPlayerFullscreen: false,
};

const setVideoPauseReducer = (state = initialState) => {
  return {
    ...state,
    playerState: videoAnalytics.controls.pause,
  };
};

const setVideoPlayReducer = (state = initialState) => {
  if (state.playerState === videoAnalytics.controls.seek) {
    return state;
  }

  return {
    ...state,
    playerState: videoAnalytics.controls.play,
  };
};

const setVideoSeekReducer = (state = initialState) => {
  return {
    ...state,
    playerState: videoAnalytics.controls.seek,
  };
};

const setVideoSeekedReducer = (state = initialState) => {
  return {
    ...state,
    playerState: videoAnalytics.controls.seeked,
  };
};

const setVideoEndedReducer = (state = initialState, { payload = true }) => {
  return {
    ...state,
    playerEnded: payload,
  };
};

const setVideoFullScreenReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    fullScreen: payload?.resetFullScreen
      ? false
      : payload?.forceFullScreen || !state.fullScreen,
  };
};

const setVideoCaptionReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    videoCaptionEnabled: payload?.videoCaptionEnabled,
  };
};

const setVideoCaptionStylesReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    videoCaptionStyles: payload,
  };
};

const setVideoTimeReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    videoContent: {
      ...state.videoContent,
      currentVideo: {
        ...state.videoContent.currentVideo,
        time: payload || 0,
      },
    },
  };
};

const setVideoPlayTimeReducer = (state = initialState) => {
  return {
    ...state,
    analytics: {
      ...state.analytics,
      playTime: Date.now(),
    },
  };
};

const clearVideoPlayTimeReducer = (state = initialState) => {
  return {
    ...state,
    analytics: {
      ...state.analytics,
      playTime: 0,
    },
  };
};

const setVideoContentReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    videoContent: payload,
  };
};

const setVideoBookmarkReducer = (state = initialState) => {
  if (state.videoContent.bgBookmarkEnabled === false) {
    return state;
  }
  const newState = {
    ...state,
    bookmarkedVideoContent: {
      ...state.videoContent,
    },
  };
  newState.bookmarkedVideoContent.currentVideo.time =
    xdk.media?.getCurrentTime() || 0;
  return newState;
};

const clearVideoAndBookmarkReducer = (state = initialState) => {
  return {
    ...state,
    videoContent: initialState.videoContent,
    bookmarkedVideoContent: initialState.bookmarkedVideoContent,
  };
};

const setVideoFullscreenBookmarkReducer = (state = initialState) => {
  const newState = {
    ...state,
    fullscreenBookmarkedVideoContent: {
      ...state.videoContent,
    },
  };
  newState.fullscreenBookmarkedVideoContent.currentVideo.time =
    xdk.media?.getCurrentTime() || 0;
  return newState;
};

const clearVideoFullscreenBookmarkReducer = (state = initialState) => {
  return {
    ...state,
    fullscreenBookmarkedVideoContent:
      initialState.fullscreenBookmarkedVideoContent,
  };
};

const setAnalyticsReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    analytics: {
      ...state.analytics,
      ...payload,
    },
  };
};

const setEpgReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    epgData: { ...state.epgData, ...payload },
  };
};

const setPlaybackTimerReducer = (
  state = initialState,
  { payload: playbackTimer },
) => ({
  ...state,
  playbackTimer,
});

const clearPlaybackTimerReducer = (state = initialState) => ({
  ...state,
  playbackTimer: null,
});

const setBackgroundPlayerFullscreenReducer = (
  state = initialState,
  { payload },
) => {
  return {
    ...state,
    backgroundPlayerFullscreen: payload,
  };
};

// Ads
const setPrerollAdDetailsReducer = (
  state = initialState,
  { payload: duration },
) => ({
  ...state,
  adDetails: {
    ...state?.adDetails,
    preroll: {
      ...state?.adDetails?.preroll,
      duration,
    },
  },
});

const addPrerollAdReducer = (
  state = initialState,
  { payload: adDurationMs },
) => ({
  ...state,
  adDetails: {
    ...state?.adDetails,
    preroll: {
      ...state?.adDetails?.preroll,
      ads: [...(state?.adDetails?.preroll?.ads || []), adDurationMs],
    },
  },
});

const clearPrerollAdsReducer = (state = initialState) => ({
  ...state,
  adDetails: {
    ...state?.adDetails,
    preroll: {
      ...state?.adDetails?.preroll,
      ads: [],
    },
  },
});

const setAdDetailsLoadedReducer = (
  state = initialState,
  { payload: adDetailsLoaded },
) => ({
  ...state,
  adDetailsLoaded,
});

const setAdPlayedReducer = (state = initialState, { payload: adPlayed }) => ({
  ...state,
  adPlayed,
});

const setVideoPlaylistReducer = (state = initialState, { payload }) => {
  return {
    ...state,
    videoContent: {
      ...state.videoContent,
      playlists: payload,
    },
  };
};

const reducer = handleActions(
  {
    [actions.setVideoPause]: setVideoPauseReducer,
    [actions.setVideoPlay]: setVideoPlayReducer,
    [actions.setVideoEnded]: setVideoEndedReducer,
    [actions.setVideoFullScreen]: setVideoFullScreenReducer,
    [actions.setVideoCaption]: setVideoCaptionReducer,
    [actions.setVideoCaptionStyles]: setVideoCaptionStylesReducer,
    [actions.setVideoSeek]: setVideoSeekReducer,
    [actions.setVideoSeeked]: setVideoSeekedReducer,
    [actions.setVideoTime]: setVideoTimeReducer,
    [actions.setEpg]: setEpgReducer,
    [actions.setPrerollAdDetails]: setPrerollAdDetailsReducer,
    [actions.addPrerollAd]: addPrerollAdReducer,
    [actions.clearPrerollAds]: clearPrerollAdsReducer,
    [actions.setAdDetailsLoaded]: setAdDetailsLoadedReducer,
    [actions.setAdPlayed]: setAdPlayedReducer,
    [actions.setVideoPlayTime]: setVideoPlayTimeReducer,
    [actions.clearVideoPlayTime]: clearVideoPlayTimeReducer,
    [actions.setVideoContent]: setVideoContentReducer,
    [actions.setVideoBookmark]: setVideoBookmarkReducer,
    [actions.clearVideoAndBookmark]: clearVideoAndBookmarkReducer,
    [actions.setVideoFullscreenBookmark]: setVideoFullscreenBookmarkReducer,
    [actions.clearVideoFullscreenBookmark]: clearVideoFullscreenBookmarkReducer,
    [actions.setAnalytics]: setAnalyticsReducer,
    [actions.setPlaybackTimer]: setPlaybackTimerReducer,
    [actions.clearPlaybackTimer]: clearPlaybackTimerReducer,
    [actions.setBackgroundPlayerFullscreen]: setBackgroundPlayerFullscreenReducer,
    [actions.setVideoPlaylist]: setVideoPlaylistReducer,
  },
  initialState,
);

export default reducer;
