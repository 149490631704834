import {
  apiIds,
  ageGroupsKeys,
  ageCategoryGroup,
  containersAgeGroup,
  featureSpecificConfigs,
} from '#/config/constants';
import {
  getCurrentProfileAgeGroup,
  getProfileAgeGroup,
} from '#/redux/modules/onboarding/selectors';

const {
  GUEST_MODE_DATA_SYNC_UP,
  AGE_UP,
  CONTENT_BLOCKING,
} = featureSpecificConfigs;

let rootSelector = state => state.accedoOne;

export const setRootSelector = selector => {
  rootSelector = selector;
};

export const getFeatureSteps = state => {
  const featureSteps = rootSelector(state)?.entries?.[
    rootSelector(state)?.metadata?.onboardingEntryId
  ];
  if (!featureSteps) {
    return [];
  }
  return featureSteps.items.map(
    entryId => rootSelector(state)?.entries[entryId],
  );
};

export const assetWithId = (state, id) => {
  const asset = rootSelector(state)?.entries?.[id];
  if (asset) {
    const formatedAsset = {};
    formatedAsset.displayText = asset.displayText;
    formatedAsset.icon = { ...asset.icon[0] };
    formatedAsset.iconSelected = { ...asset.iconSelected[0] };
    return formatedAsset;
  }
  return undefined;
};

export const getPage = (state, id) => {
  const menuItem = rootSelector(state)?.entries?.[id];
  const page = rootSelector(state)?.entries?.[menuItem.page];
  return page;
};

export const getEntryById = (state, id) => {
  return rootSelector(state)?.entries?.[id];
};

export const menuEntry = state => {
  const entry = rootSelector(state)?.entries?.[
    rootSelector(state)?.metadata?.mainMenuEntryId
  ];
  if (entry) {
    const formatedEntry = {};
    formatedEntry.menuItems = entry.menuItems;
    return formatedEntry;
  }
  return undefined;
};

export const heroBannerEnabled = state => {
  const menu = menuEntry(state);

  if (menu) {
    const homeEntry = getEntryById(state, menu.menuItems[0]);
    const homeContainers = getEntryById(state, homeEntry.page);

    let ageContainers;
    try {
      const ageGroup = getCurrentProfileAgeGroup(state);
      switch (ageGroup) {
        case ageGroupsKeys.TWO_UP:
          ageContainers = homeContainers[containersAgeGroup.CONTAINER_2Up];
          break;
        case ageGroupsKeys.FIVE_UP:
          ageContainers = homeContainers[containersAgeGroup.CONTAINER_5Up];
          break;
        default:
          ageContainers = homeContainers[containersAgeGroup.CONTAINER_8Up];
          break;
      }
    } catch (e) {
      ageContainers = homeContainers[containersAgeGroup.CONTAINER_2Up];
    }

    const heroEntry = getEntryById(state, ageContainers[0]);
    if (heroEntry) {
      return {
        enabled: heroEntry.disableInWeb || false,
        collection: heroEntry.fsCollectionName,
      };
    }
  }

  return {
    enabled: false,
  };
};

export const getRequireEmailVerification = state => {
  return rootSelector(state)?.metadata?.requireEmailVerification;
};

export const getOnboardEndpoint = (state, id) => {
  const endpoints = rootSelector(state)?.metadata?.apiIdentityEndpoint;
  const endpoint = endpoints?.endpoints?.[id];
  const result = {};
  if (endpoint) {
    result.url = endpoints?.url + endpoint?.path;
    result.settings = { method: endpoint?.method };
    return result;
  }
  return undefined;
};

export const getApiPlaybackEndpoints = state => {
  const endpoints = rootSelector(state)?.metadata?.apiPlaybackEndpoint;
  return endpoints;
};

export const getApiIdentityEndpoints = state => {
  const endpoints = rootSelector(state)?.metadata?.[apiIds.IDENTITY_API];
  return endpoints;
};

export const getApiOidcEndpoints = state => {
  const endpoints = rootSelector(state)?.metadata?.[apiIds.OIDC_API];
  return endpoints;
};

export const getApiCustomFieldsEndpoints = state => {
  const endpoints = rootSelector(state)?.metadata?.[apiIds.CUSTOM_DATA_API];
  return endpoints;
};

export const getSensicalTvConfig = state =>
  rootSelector(state)?.metadata?.sensicalTvConfig;

export const getAgeMetadata = (age, state) => {
  const ageGroup = getProfileAgeGroup({ age })(state);
  const ageGroupsMetadata = rootSelector(state)?.metadata?.ageGroupConfig;

  switch (ageGroup) {
    case ageGroupsKeys.TWO_UP:
      return {
        ...ageGroupsMetadata?.configurations2Up,
        minAge: ageCategoryGroup.MIN_AGE_TWO_UP,
      };
    case ageGroupsKeys.FIVE_UP:
      return {
        ...ageGroupsMetadata?.configurations5Up,
        minAge: ageCategoryGroup.MIN_AGE_FIVE_UP,
      };
    default:
      return {
        ...ageGroupsMetadata?.configurations8Up,
        minAge: ageCategoryGroup.MIN_AGE_EIGHT_UP,
      };
  }
};

export const getCurrentAgeMetadata = state => {
  const ageGroup = getCurrentProfileAgeGroup(state);
  const ageGroupsMetadata = rootSelector(state)?.metadata?.ageGroupConfig;

  switch (ageGroup) {
    case ageGroupsKeys.TWO_UP:
      return {
        ...ageGroupsMetadata?.configurations2Up,
        minAge: ageCategoryGroup.MIN_AGE_TWO_UP,
      };
    case ageGroupsKeys.FIVE_UP:
      return {
        ...ageGroupsMetadata?.configurations5Up,
        minAge: ageCategoryGroup.MIN_AGE_FIVE_UP,
      };
    default:
      return {
        ...ageGroupsMetadata?.configurations8Up,
        minAge: ageCategoryGroup.MIN_AGE_EIGHT_UP,
      };
  }
};

export const getSpinner = state => {
  const spinner = rootSelector(state)?.assets?.loadingSpinner;
  return spinner;
};

export const getOnboardData = (state, payload) => {
  const data = rootSelector(state)?.onboarding?.[payload.toString()];
  return data;
};

export const getTermsOfServiceUrl = state =>
  rootSelector(state)?.metadata?.termsOfServiceUrl;

export const getTermsOfService = state => rootSelector(state)?.termsOfService;

export const getChildrenPrivacyPolicyUrl = state =>
  rootSelector(state)?.metadata?.childrenPrivacyPolicyUrl;

export const getChildrenPrivacyPolicy = state =>
  rootSelector(state)?.childrenPrivacyPolicy;

export const getBenefitsUrl = state => {
  const url = rootSelector(state)?.metadata?.sensicalBenefitsUrl;
  return url;
};

export const getSensicalBenefits = state => {
  const benefits = rootSelector(state)?.benefits;
  return benefits;
};

export const getLearningReportsConfig = state =>
  rootSelector(state)?.metadata?.learningReportsConfig;

export const getPrivacyPolicy = state => {
  return rootSelector(state)?.privacyPolicy;
};

export const getFAQ = state => {
  return rootSelector(state)?.faq;
};

export const getHelp = state => {
  return rootSelector(state)?.help;
};

export const getOidcClientId = state => {
  return rootSelector(state)?.metadata?.[apiIds.OIDC_API]?.extraParams
    ?.oidcClientId;
};

export const getGeoBlocking = state => {
  return rootSelector(state)?.metadata?.geoBlocking;
};

export const getDynamicLinks = state => {
  return rootSelector(state)?.metadata?.dynamicLinks;
};

export const getAgeGroupConfig = state => {
  return rootSelector(state)?.metadata?.ageGroupConfig;
};
export const getNoRegistration = state => {
  return rootSelector(state)?.metadata?.noRegistration;
};
export const getWhiteLogoUri = state => {
  return rootSelector(state)?.metadata?.whiteLogoUri;
};
export const getBlackLogoUri = state => {
  return rootSelector(state)?.metadata?.blackLogoUri;
};
export const getGreenLogoUri = state => {
  return rootSelector(state)?.metadata?.secondaryLogoUri;
};
export const getSessionIdTs = state => {
  return rootSelector(state)?.sessionIdTs;
};
export const getMobileRedirectInfo = state => {
  return rootSelector(state)?.metadata?.mobileRedirect;
};
export const getPodcastsControls = state => {
  return rootSelector(state)?.metadata?.podcasts;
};

export const getRecentlyWatchedLimit = state => {
  return (
    rootSelector(state)?.metadata?.noRegistration?.recentlyWatchedLimit || 20
  );
};

export const getFavoritesLimit = state => {
  return rootSelector(state)?.metadata?.noRegistration?.favoritesLimit || 10;
};

export const getBookmarksLimit = state => {
  return rootSelector(state)?.metadata?.noRegistration?.bookmarksLimit || 20;
};

export const getFeatureSpecificConfig = (state, key) => {
  return rootSelector(state)?.metadata?.featureSpecificConfigs?.[key];
};

export const getGuestModeDataSyncUp = state => {
  return getFeatureSpecificConfig(state, GUEST_MODE_DATA_SYNC_UP);
};

export const getRWSyncUpConfirmationModal = state => {
  return (
    getGuestModeDataSyncUp(state)?.recentlyWatchedSyncUpConfirmationModal || {}
  );
};

export const getAgeUp = state => {
  return getFeatureSpecificConfig(state, AGE_UP);
};

export const getContentBlocking = state => {
  return getFeatureSpecificConfig(state, CONTENT_BLOCKING);
};

export const getEntryByPlaylistId = (state, id) => {
  const entriesData = rootSelector(state)?.entries;
  let entryData = {};
  if (entriesData) {
    Object.keys(entriesData).forEach(entry => {
      if (entriesData[entry]?.playlistId === id) {
        entryData = entriesData[entry];
      }
    });
    return entryData;
  }
  return null;
};

const getGuestProfileContext = state => {
  const unauthenticatedWelcomeAgeContainer = rootSelector(state)?.metadata
    ?.featureSpecificConfigs.welcomeAgeSelector;
  const getUnauthenticatedWelcomeAgeContainer = rootSelector(state)?.entries?.[
    unauthenticatedWelcomeAgeContainer
  ];
  return getUnauthenticatedWelcomeAgeContainer;
};

export const getGuestProfileContent = state => {
  const getUnauthenticatedWelcomeAgeContainer = getGuestProfileContext(state);
  if (!getUnauthenticatedWelcomeAgeContainer) {
    return {
      setAllEntriesByContent: {},
      totalCount: 0,
      parentSignInButtonText: '',
      signUpButtonText: '',
    };
  }
  const setAllEntriesByContent = {};
  const {
    tiles,
    parentSignInButtonText,
    signUpButtonText,
  } = getUnauthenticatedWelcomeAgeContainer;
  const getAllEntries = tiles
    .map(entryId => {
      const entry = rootSelector(state)?.entries[entryId];
      if (entry) {
        setAllEntriesByContent[entry.typeOfContent] =
          setAllEntriesByContent[entry.typeOfContent] || [];
        setAllEntriesByContent[entry.typeOfContent].push(entry);
        return entry;
      }
      return null;
    })
    .filter(x => x);

  return {
    setAllEntriesByContent,
    totalCount: getAllEntries.length,
    parentSignInButtonText,
    signUpButtonText,
  };
};

export const getGuestLoadingBackground = state => {
  const getUnauthenticatedWelcomeAgeContainer = getGuestProfileContext(state);
  if (!getUnauthenticatedWelcomeAgeContainer) {
    return {};
  }

  const {
    backgroundImageUrl1920x1080: largeDesktopBckgLoader,
    backgroundImageUrl1728x1117: mediumDesktopBckgLoader,
    backgroundImageUrl1280x832: desktopBckgLoader,
    backgroundImageUrlIpadMiniLandscape: ipadMiniLandscape,
    // NOTE: Added in control specifically for web mobile usage
    backgroundImageUrlIpadMiniPortraitWeb: ipadMiniPotrait,
  } = getUnauthenticatedWelcomeAgeContainer;

  return {
    largeDesktopBckgLoader,
    mediumDesktopBckgLoader,
    desktopBckgLoader,
    ipadMiniLandscape,
    ipadMiniPotrait,
  };
};
