import { klass, ISubtitleStrategy } from '@accedo/xdk-core';
import logger from '@accedo/xdk-log';

const { debug } = logger({
  logTag: '[players/videojs/ExternalSubtitles]',
});
const SubtitleStrategy = klass.create(
  [ISubtitleStrategy],
  {},
  {
    __player: null,
    __subtitles: [],
    __currentSubtitleId: null,

    prepare(player) {
      if (!player) {
        debug(
          'Fail to prepare the videojs Subtitle Track Strategy without the XDK player object.',
        );
        return;
      }
      this.__player = player._vjs;
    },

    // getSubtitles is chained through xdk media -> Subtitle -> extension files
    // This sets the subtitle array within Subtitle to enable calling show() and hide() afterwards
    async getSubtitles() {
      this.__subtitles = this.__player.textTracks();
      return Promise.resolve([
        {
          id: 0, // IMPORTANT: getSubtitles must returns items with id (locally) unique field
          type: 'external',
        },
      ]);
    },

    async showSubtitle() {
      for (let i = 0; i < this.__subtitles.length; i += 1) {
        if (this.__subtitles[i].kind !== 'metadata') {
          this.__subtitles[i].mode = 'showing';
        }
      }
    },

    async hideSubtitle() {
      for (let i = 0; i < this.__subtitles.length; i += 1) {
        if (this.__subtitles[i].kind !== 'metadata') {
          this.__subtitles[i].mode = 'disabled';
        }
      }
    },

    async getCurrentSubtitle() {
      debug('getCurrentSubtitle');
    },
  },
);

export default SubtitleStrategy;
