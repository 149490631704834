import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Page, FocusDiv } from '@accedo/vdkweb-tv-ui';
import TabView from '#/components/TabView/TabView';
import { PlaylistSwimlaneFactory } from '#/components/SwimlaneFactory';
import { onVideoTileClick } from '#/components/Swimlane/utils';
import { getVerticalNav, PLAYER } from '#/utils/navigationHelper';
import {
  getContainerData,
  getPlaylistData,
  getPlaylistQueryParams,
} from '#/redux/modules/brightcove/selectors';
import {
  performPlaylistQuery,
  savePlaylistQuery,
} from '#/redux/modules/brightcove/actions';
import { getCurrentAgeMetadata } from '#/redux/modules/accedoOne/selectors';
import {
  hasVideoEnded,
  getPlaylistIds,
  getPlayerVideoIndex,
  getPlayerContainerIndex,
} from '#/redux/modules/appPlayer/selectors';
import { setVideoPlaylist } from '#/redux/modules/appPlayer/actions';

import { getCurrentProfile } from '#/redux/modules/onboarding/selectors';

import styles from './playerMoreContent.scss';
import { playerConstants, videoAnalytics } from '#/config/constants';
import { isWorkstation } from '#/utils/componentStyleConfig';

const { CONTROL, CAROUSEL_CONTAINER, TRANSPORT_CONTROLS, CONTAINER } = PLAYER;

const navMap = {
  CONTAINER: {
    id: CONTROL,
    nextup: TRANSPORT_CONTROLS,
    parent: CONTAINER,
    forwardFocus: CAROUSEL_CONTAINER,
    useLastFocus: true,
  },
  ...getVerticalNav([CAROUSEL_CONTAINER], {
    parent: CONTROL,
  }),
};

const navIds = getVerticalNav([playerConstants.UP_NEXT_CAROUSEL_ID], {
  parent: CAROUSEL_CONTAINER,
});

const tabSchema = [{ label: 'Up Next', id: 0 }];

const PlayerMoreContent = ({
  history,
  fromHome = false,
  location: { search },
}) => {
  const dispatch = useDispatch();
  const currentProfile = useSelector(getCurrentProfile);
  const onVideoEndFlag = useSelector(hasVideoEnded);
  const collection = new URLSearchParams(search).get('collectionid');
  const playlistIds = useSelector(getPlaylistIds);
  const getPlaylistId = collection ? [collection] : playlistIds;
  const videoIndex = useSelector(getPlayerVideoIndex);
  const videoItems = useSelector(state =>
    getContainerData(state, getPlaylistId),
  );
  const videoContainerIndex = useSelector(getPlayerContainerIndex);
  const selectedAgeMetadata = useSelector(getCurrentAgeMetadata);
  const upNextItemCount = videoItems[getPlaylistId]?.length;

  const swimlaneData = useMemo(() => {
    const data = [];
    const callback = onVideoTileClick(getPlaylistId, dispatch, fromHome);

    // Loop through videos in containers starting after the current video index
    let startingVideoIndex = videoIndex + 1;
    for (
      let playlistIdIndex = 0;
      playlistIdIndex < getPlaylistId.length;
      playlistIdIndex += 1
    ) {
      const currentContainerIndex =
        (playlistIdIndex + videoContainerIndex) % getPlaylistId.length;
      const currentVideoItems =
        videoItems[getPlaylistId[currentContainerIndex]];

      for (let i = startingVideoIndex; i < currentVideoItems?.length; i += 1) {
        const item = { ...currentVideoItems?.[i] }; // copy by reference to not affect other click handlers based on item for BG player
        item.onClick = callback(currentContainerIndex)(item, i);

        data.push(item);
        if (data.length === upNextItemCount) {
          break;
        }
      }

      if (data.length === upNextItemCount) {
        break;
      }
      startingVideoIndex = 0;
    }

    // If looping after the current video index and other containers is not enough
    // append videos before current index in same container
    if (data.length < upNextItemCount) {
      for (
        let currentVideoIndex = 0;
        currentVideoIndex <= videoIndex - 1;
        currentVideoIndex += 1
      ) {
        const item = {
          ...videoItems[getPlaylistId[videoContainerIndex]]?.[
            currentVideoIndex
          ],
        }; // copy by reference to not affect other click handlers based on item for BG player
        item.onClick = callback(videoContainerIndex)(item, currentVideoIndex);

        data.push(item);
        if (data.length === upNextItemCount) {
          break;
        }
      }
    }

    return data;
  }, [videoItems, videoContainerIndex, videoIndex]);

  // Effect to trigger next content on video end
  useEffect(() => {
    const findNextUnblockedVideo = arr => {
      const res = arr.findIndex(vid =>
        currentProfile.videosBlocked.every(
          x => x.reference_id !== vid.reference_id,
        ),
      );

      return res;
    };
    const findNextUnblockedShow = arr => {
      const res = arr.findIndex(vid =>
        currentProfile.showsBlocked.every(
          x => x.id !== vid.custom_fields.show_id,
        ),
      );

      return res;
    };
    if (onVideoEndFlag && isWorkstation()) {
      // go back if no other up next content, otherwise play next video
      swimlaneData[0]
        ? currentProfile?.videosBlocked?.find(
            video => video.reference_id === swimlaneData[0].reference_id,
          )
          ? swimlaneData[findNextUnblockedVideo(swimlaneData)]?.onClick(
              videoAnalytics.initiation.AUTO,
            ) || history.goBack()
          : currentProfile?.showsBlocked?.find(
              show => show?.id === swimlaneData[0]?.custom_fields.show_id,
            )
          ? swimlaneData[findNextUnblockedShow(swimlaneData)]?.onClick(
              videoAnalytics.initiation.AUTO,
            ) || history.goBack()
          : swimlaneData[0].onClick(videoAnalytics.initiation.AUTO)
        : history.goBack();
    }
  }, [onVideoEndFlag, swimlaneData]);

  useEffect(() => {
    if (onVideoEndFlag && !isWorkstation()) {
      swimlaneData[0].onClick(videoAnalytics.initiation.AUTO);
    }
  }, [onVideoEndFlag]);

  useEffect(() => {
    if (videoItems) {
      navMap[CAROUSEL_CONTAINER].forwardFocus =
        playerConstants.UP_NEXT_CAROUSEL_ID;
    }
  }, [videoItems]);

  let queryData;
  let queryParams;

  if (collection) {
    queryData = useSelector(state => getPlaylistData(state));
    queryParams = useSelector(state => getPlaylistQueryParams(state));

    useEffect(() => {
      if (!queryParams.creator_id && queryParams.playlistId !== collection) {
        const queryObj = { ...queryParams };

        queryObj.limit = queryData.limit;
        queryObj.offset = 0;
        queryObj.playlistId = collection;
        queryObj.id = collection;
        dispatch(savePlaylistQuery(queryObj));
        dispatch(setVideoPlaylist([collection]));
      } else if (queryParams.playlistId === collection) {
        dispatch(performPlaylistQuery([queryParams]));
        dispatch(setVideoPlaylist([collection]));
      }
    }, [queryParams]);
  }

  return swimlaneData.length ? (
    <Page autofocus={false} nav={navMap.CONTAINER}>
      <FocusDiv nav={navMap[CAROUSEL_CONTAINER]}>
        <TabView
          className={styles.tabs}
          tabsSchema={tabSchema}
          initialTabId={tabSchema[0].id}
        />
        <PlaylistSwimlaneFactory
          className={styles.swimlane}
          nav={navIds[playerConstants.UP_NEXT_CAROUSEL_ID]}
          videos={swimlaneData}
          enableSeeAll={false}
          ageTheme={selectedAgeMetadata}
          fromHome
          enableAllUpNextVideo
        />
      </FocusDiv>
    </Page>
  ) : null;
};

PlayerMoreContent.propTypes = {
  history: PropTypes.object,
  fromHome: PropTypes.bool,
  location: PropTypes.any,
};

export default withRouter(PlayerMoreContent);
