import React from 'react';
import { useSelector } from 'react-redux';

import FocusDiv from '@accedo/vdkweb-tv-ui/lib/FocusDiv/FocusDiv';
import BaseModal from '../../common/BaseModal/BaseModal';

import { getContentBlocking } from '#/redux/modules/accedoOne/selectors';
import { BLOCKED_CONTENT_MODAL } from '#/utils/navigationHelper';
import styles from './BlockedContentModal.scss';
import FocusModal from '../../common/FocusModal/FocusModal';

const { CONTAINER, CANCEL_BUTTON } = BLOCKED_CONTENT_MODAL;

const BlockedContentModal = () => {
  const contentBlocking = useSelector(getContentBlocking);
  const {
    videoBlockedModal: { title, description, ctaClose },
  } = contentBlocking;

  return (
    <FocusModal>
      <BaseModal
        showSaveButton={false}
        showCancelButton
        cancelButtonText={ctaClose}
        cancelButtonClassName={styles.cancelButton}
        cancelButtonDisabled={false}
        nav={{
          parent: CONTAINER,
          id: CANCEL_BUTTON,
        }}
      >
        <FocusDiv nav={{ id: CONTAINER, forwardFocus: CANCEL_BUTTON }}>
          <div className={styles.modalContainer}>
            <h2>{title}</h2>
            <p className={styles.description}>{description}</p>
          </div>
        </FocusDiv>
      </BaseModal>
    </FocusModal>
  );
};

export default BlockedContentModal;
