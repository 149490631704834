import React from 'react';
import { useSelector } from 'react-redux';

import {
  getNewestProfileAgeGroup,
  getProfiles,
} from '#/redux/modules/onboarding/selectors';

import { likeWhatModalDialogs, welcomeTitleBase } from '#/config/strings';
import { ageRanges } from '#/config/constants';
import { constructString } from '#/config/helpers';
import logoUri from '#/static/images/logos/SensicalCSM.svg';

import BaseModal from '../../common/BaseModal/BaseModal';

import {
  modalContainer,
  content,
  closeIcon,
  logoImage,
  titleText,
  primaryText,
  secondaryText,
} from './likeWhatModal.scss';

const { title, body, extra } = likeWhatModalDialogs;

const LikeWhatModal = () => {
  const category = useSelector(getNewestProfileAgeGroup);
  const profiles = useSelector(getProfiles);

  const [{ name } = {}] = profiles?.slice(-1) || [];

  return (
    <BaseModal
      className={modalContainer}
      closeIconClassName={closeIcon}
      showSaveButton={false}
    >
      <div className={content}>
        <img className={logoImage} src={logoUri} alt="logo" />
        <h2 className={titleText}>{welcomeTitleBase}</h2>
        <p className={primaryText}>
          {constructString(title, { name, range: ageRanges[category] })}
        </p>
        <p className={secondaryText}>{body[category]}</p>
        <p className={secondaryText}>{extra}</p>
      </div>
    </BaseModal>
  );
};

export default LikeWhatModal;
