import React from 'react';
import styles from './LandscapeModal.scss';
import svg from '#/static/images/icons/rotate-phone.svg';

const LandscapeModal = () => {
  return (
    <div id="LandscapeModal" className={styles.landscapeContainer}>
      <div className={styles.svg}>
        <img alt="Rotating Phone" src={svg} />
      </div>
      <div className={styles.text}>
        Landscape view is not available. Please rotate your device
      </div>
    </div>
  );
};

export default LandscapeModal;
