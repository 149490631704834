import React from 'react';
import { useSelector } from 'react-redux';

import { getSensicalTvConfig } from '#/redux/modules/accedoOne/selectors';

import Logo from '#/static/images/icons/logo-login.svg';
import styles from './MobileGate.scss';

const MobileGate = () => {
  const { logoUri = Logo } = useSelector(getSensicalTvConfig) || {};

  return (
    <div className={styles.mobileGate}>
      <div className={styles.content}>
        <img className={styles.logo} src={logoUri} alt="Logo" />
        <h1>Let's start watching.</h1>
        <p>Soon, you can watch from your mobile phone's browser.</p>
        <p>
          In the meantime, sign in to Sensical.tv on your computer to watch
          videos or see what your child has been watching.
        </p>
      </div>
    </div>
  );
};

export default MobileGate;
