import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { focusManager } from '@accedo/vdkweb-navigation';
import cx from 'classnames';
import moment from 'moment-mini';

import { environment } from '@accedo/xdk-core';
import { vKey } from '@accedo/xdk-virtual-key';
import { isOpen } from '#/redux/modules/modal/selectors';

import {
  heroBannerEnabled,
  getGuestLoadingBackground,
  getSpinner,
} from '#/redux/modules/accedoOne/selectors';
import { getCurrentProfile } from '#/redux/modules/onboarding/selectors';
import {
  getLoadingSpinnerState,
  getLoginLoadingSpinnerState,
} from '#/redux/modules/spinner/selectors';
import { clearVideoAndBookmark } from '#/redux/modules/appPlayer/actions';
import {
  loadAllShows,
  loadTopShows,
  loadAllTopics,
  loadHeroContent,
  clearHeroContent,
} from '#/redux/modules/firestore/actions';
import { actions as modalActions } from '#/redux/modules/modal/actions';

import {
  loadCustomDataShowsFavorites,
  loadCustomDataRecentlyWatched,
  loadCustomDataPodcastsFavorites,
  loadCustomDataTopicsFavorites,
  loadCustomDataVideoFavorites,
  // loadCustomDataPodcastsEpisodesFavorites,
  setGuestMode,
  clearCustomDataRecentlyWatched,
  loadCustomDataDisplayedModals,
} from '#/redux/modules/identity/actions';
import { getDeepLinkStarted } from '#/redux/modules/lifecycle/selectors';
import {
  setGuestMode as setGuetsModeSelectors,
  getCustomDataVideosBlocked,
  getCustomDataShowsBlocked,
} from '#/redux/modules/identity/selectors';

import { isWorkstation, isVIZIO } from '#/utils/componentStyleConfig';
import useVizioLibrary from '#/hooks/useVizioLibrary';
// import SplashScreen from '#/components/AuthFlow/SplashScreen/SplashScreen';
import LoadingSpinner from '#/components/LoadingSpinner/LoadingSpinner';
import FocusSink from '#/components/FocusSink/FocusSink';

import { isGuestMode } from '#/redux/modules/user/selectors';
import { getAgeRange } from '#/utils/getAgeRange';
import styles from './tvApp.scss';
import { aggregateBirthdate } from '../../utils/aggregateBirthdate';

const TVAppContent = ({ children }) => {
  focusManager.setPersistTrail(true);
  const deepLinkStarted = useSelector(getDeepLinkStarted);
  const dispatch = useDispatch();
  const currentProfile = useSelector(getCurrentProfile);
  const isHeroBannerEnabled = useSelector(heroBannerEnabled);
  const loadingSpinnerState = useSelector(getLoadingSpinnerState);
  const loginSpinnerState = useSelector(getLoginLoadingSpinnerState);
  const isModalOpen = useSelector(isOpen);
  const guestMode = useSelector(isGuestMode);
  const ageRange = getAgeRange(currentProfile?.age);
  const customDataShowsBlocked = useSelector(getCustomDataShowsBlocked);
  const customDataVideosBlocked = useSelector(getCustomDataVideosBlocked);
  const spinnerIcon = useSelector(getSpinner);
  const spinnerBckg = useSelector(getGuestLoadingBackground);

  if (isVIZIO()) {
    useVizioLibrary();
  }

  useEffect(() => {
    setGuestMode(guestMode);
    setGuetsModeSelectors(guestMode);
  }, [guestMode]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (currentProfile?.age && !deepLinkStarted) {
        dispatch(clearCustomDataRecentlyWatched());
        dispatch(clearVideoAndBookmark());
        dispatch(loadAllShows());
        dispatch(loadTopShows());
        dispatch(loadAllTopics());
        dispatch(loadCustomDataShowsFavorites());
        dispatch(loadCustomDataRecentlyWatched());
        dispatch(loadCustomDataPodcastsFavorites());
        dispatch(loadCustomDataTopicsFavorites());
        dispatch(loadCustomDataVideoFavorites());

        if (isHeroBannerEnabled.enabled) {
          dispatch(loadHeroContent(isHeroBannerEnabled.collection));
        } else {
          dispatch(clearHeroContent());
        }

        const birthDate = aggregateBirthdate(
          currentProfile.age,
          currentProfile.birthdayMonth,
        );

        const currentMonthDay15 = moment().date(15);
        const day15OfMonth = moment()
          .date(15)
          .format('YYYY-MM-DD');
        const yearDiff = moment(day15OfMonth, 'YYYY-MM-DD').diff(
          moment(birthDate, 'YYYY-MM-DD'),
          'years',
        );

        const kidAgeUpDuringBirthMonth =
          currentProfile.birthdayMonth === moment().month() + 1 &&
          moment().isAfter(currentMonthDay15) &&
          (yearDiff === 5 || yearDiff === 8);
        const kidAgeUpAfterBirthMonth =
          moment().isAfter(currentMonthDay15) &&
          currentProfile.birthdayMonth !== moment().month() + 1 &&
          (yearDiff !== 5 || yearDiff !== 8);

        if (!guestMode && ageRange > 2) {
          dispatch(loadCustomDataDisplayedModals());
          if (
            !currentProfile?.displayedModals ||
            !currentProfile.displayedModals.some(
              obj => obj.id === `ageUp_${ageRange}up`,
            )
          ) {
            if (kidAgeUpDuringBirthMonth || kidAgeUpAfterBirthMonth) {
              dispatch(
                modalActions.openModal({
                  modalId: 'AgeUpModal',
                  modalData: {
                    ageGroupValue: ageRange,
                    kidAgeUpDuringBirthMonth,
                    kidAgeUpAfterBirthMonth,
                  },
                }),
              );
            }
          }
        }
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, [
    currentProfile?.id,
    isHeroBannerEnabled.enabled,
    customDataShowsBlocked,
    customDataVideosBlocked,
    deepLinkStarted,
  ]);
  const backKeyHandler = useCallback(
    ({ id }) => {
      const { BACK } = vKey;
      if (id === BACK.id) {
        !isWorkstation() &&
          !isModalOpen &&
          !focusManager.getCurrentFocus().startsWith('PLAYER') &&
          window.history.back();
      }
    },
    [isModalOpen],
  );

  useEffect(() => {
    if (!isWorkstation()) {
      environment.addEventListener(environment.SYSTEM.KEYDOWN, backKeyHandler);
      return () => {
        environment.removeEventListener(
          environment.SYSTEM.KEYDOWN,
          backKeyHandler,
        );
      };
    }
  }, [isModalOpen]);

  return (
    <div
      className={cx(styles.root, {
        [styles.tv]: !isWorkstation(),
      })}
    >
      <FocusSink />
      {/* TODO: Causing issues on TV, fix before enabling it */}
      {/* {!isWorkstation() && <SplashScreen />} */}
      {children}
      <LoadingSpinner
        showLoading={loadingSpinnerState}
        showLoginSpinner={loginSpinnerState}
        spinnerIcon={spinnerIcon}
        spinnerBckg={spinnerBckg}
      />
    </div>
  );
};

TVAppContent.propTypes = {
  children: PropTypes.node,
};

export default TVAppContent;
