// Combined identityActions
import * as identityUser from './identityUser/actions';
import * as identityGuest from './identityGuest/actions';

/**
 * Dynamically dispatch the right action (user or guest)
 * If the action is not implemented on the counterpart,
 * it will provide a dummy action without effects.
 */

let guestModeActive = false;
export const setGuestMode = isGuest => {
  guestModeActive = isGuest;
};

const enableLogs = false;
const defaultAction = () => async () => {};
const getDefaultAction = (name, missing) => {
  // Dummy action that maches the signature () => async () => {}
  // Only add logs in dev
  if (process.env.NODE_ENV === 'production' || !enableLogs) {
    return defaultAction;
  }

  return () => async () => {
    // eslint-disable-next-line no-console
    console.log(`Action ${name} not implemented on ${missing}`);
  };
};

const getAllNames = (user, guest) => [
  ...Object.keys(guest),
  ...Object.keys(user),
];

const allExports = getAllNames(identityUser, identityGuest);

// eslint-disable-next-line valid-jsdoc
/**
 * Combine 2 actions into one that will change behaviour when
 * guestMode is enabled
 *
 * If the action is not present or implemented on any of the
 * identity actions, the dummy action defaultAction will be used
 *
 * @param {Object} actions object with the accumulated actions
 * @param {String} name name of the action
 * @param {Function} user action from identityUser
 * @param {Function} guest action from identityGuest
 * @returns acctions object including the new action
 */
const reduceActions = (actions, name, user, guest) => {
  const userAction = user || getDefaultAction(name, 'user');
  const guestAction = guest || getDefaultAction(name, 'guest');
  const action = (...args) =>
    guestModeActive ? guestAction(...args) : userAction(...args);
  // if you need toString from guest action, call toString(true)
  // or could be changed to use guestModeActive flag instead
  // you could also import actions directly from identityUser/Guest
  // that will return the specific action type
  action.toString = guestMode =>
    guestMode ? guestAction?.toString?.() : userAction?.toString?.();
  return {
    ...actions,
    [name]: action,
  };
};

/**
 * Combine identityUser and identityGuest exports
 * There are only 3 types in the exports:
 *
 * String: for action names and the prefix
 * Function: actions with the signature: () => async () => {}
 * Object: the "actions" created with "createActions"
 */
const identityActions = allExports.reduce((acc, key) => {
  const isString =
    typeof identityGuest[key] === 'string' ||
    typeof identityUser[key] === 'string';
  const guestValue = identityGuest[key];
  const userValue = identityUser[key];

  // handle constants like action names or prefixes
  if (isString) {
    return { ...acc, [key]: userValue || guestValue };
  }

  // unique case for object actions
  if (key === 'actions') {
    const actionNames = getAllNames(userValue, guestValue || []);
    const actions = actionNames.reduce((acts, name) => {
      const user = userValue?.[name];
      const guest = guestValue?.[name];
      return reduceActions(acts, name, user, guest);
    }, {});
    return { ...acc, actions };
  }

  // action (function)
  return reduceActions(acc, key, userValue, guestValue);
}, {});

/**
 * IMPORTANT
 * You need to export here again any new value/function
 * that you create on identityUser and identityGuest
 * because you cannot dynamically export keys of an object
 * Only constants, functions and the object 'actions'
 */
export const {
  ADD_PROFILE, // constants
  ADD_SINGLE_CUSTOM_DATA_SHOWS_FAVORITE,
  ADD_SINGLE_CUSTOM_DATA_PODCASTS_FAVORITE,
  ADD_SINGLE_CUSTOM_DATA_TOPICS_FAVORITE,
  ADD_SINGLE_CUSTOM_DATA_VIDEO_FAVORITE,
  ADD_SINGLE_CUSTOM_DATA_PODCASTS_EPISODES_FAVORITE,
  ADD_UNSAVED_PROFILES,
  ASYNC_ADD_ACCOUNT_CUSTOM_DATA,
  ASYNC_ADD_PROFILE_BASIC,
  ASYNC_ADD_PROFILE_CUSTOM_DATA,
  ASYNC_ADD_SHOWS_FAVORITE_CUSTOM_DATA,
  ASYNC_ADD_PODCASTS_FAVORITE_CUSTOM_DATA,
  ASYNC_ADD_TOPICS_FAVORITE_CUSTOM_DATA,
  ASYNC_ADD_VIDEO_FAVORITE_CUSTOM_DATA,
  ASYNC_ADD_PODCASTS_EPISODES_FAVORITE_CUSTOM_DATA,
  ASYNC_APPROVE_USER_CODE,
  ASYNC_CONFIRM_TOKEN,
  ASYNC_DELETE_ACCOUNT,
  ASYNC_DELETE_PROFILE_BOOKMARK_BY_ID,
  ASYNC_DELETE_SHOWS_FAVORITE_CUSTOM_DATA,
  ASYNC_DELETE_PODCASTS_FAVORITE_CUSTOM_DATA,
  ASYNC_DELETE_TOPICS_FAVORITE_CUSTOM_DATA,
  ASYNC_DELETE_VIDEO_FAVORITE_CUSTOM_DATA,
  ASYNC_DELETE_PODCASTS_EPISODES_FAVORITE_CUSTOM_DATA,
  ASYNC_EXTEND_TIME_ALLOWANCE,
  ASYNC_GET_CURRENT_USER,
  ASYNC_IS_CODE_VALIDATED,
  ASYNC_LOAD_GROUP,
  ASYNC_LOAD_PROFILES_BASIC,
  ASYNC_LOAD_PROFILE_BOOKMARK_BY_ID,
  ASYNC_LOAD_PROFILE_CUSTOM_DATA,
  ASYNC_LOAD_PROFILE_SHOWS_FAVORITES,
  ASYNC_LOAD_PROFILE_RECENTLY_WATCHED,
  ASYNC_LOAD_PROFILE_PODCASTS_FAVORITES,
  ASYNC_LOAD_PROFILE_TOPICS_FAVORITES,
  ASYNC_LOAD_PROFILE_VIDEO_FAVORITES,
  ASYNC_LOAD_PROFILE_PODCASTS_EPISODES_FAVORITES,
  ASYNC_LOAD_PROFILE_DISPLAYED_MODALS,
  ASYNC_LOAD_PROFILE_VIDEOS_BLOCKED,
  ASYNC_LOAD_PROFILE_SHOWS_BLOCKED,
  ASYNC_LOOKUP_BOOKMARK,
  ASYNC_LOOKUP_SHOW_FAVORITE,
  ASYNC_LOOKUP_PODCAST_FAVORITE,
  ASYNC_LOOKUP_TOPIC_FAVORITE,
  ASYNC_LOOKUP_VIDEO_FAVORITE,
  ASYNC_LOOKUP_PODCASTS_EPISODES_FAVORITE,
  ASYNC_LOOKUP_DISPLAYED_MODALS,
  ASYNC_LOOKUP_VIDEOS_BLOCKED,
  ASYNC_LOOKUP_SHOWS_BLOCKED,
  ASYNC_UPDATE_PROFILE_DISPLAYED_MODALS,
  ASYNC_UPDATE_VIDEOS_BLOCKED,
  ASYNC_UPDATE_SHOWS_BLOCKED,
  ASYNC_ONBOARD_USER,
  ASYNC_PROCESS_STEP,
  ASYNC_RECOVER_PASSWORD,
  ASYNC_REMOVE_PROFILE,
  ASYNC_SESSION_END,
  ASYNC_SESSION_START,
  ASYNC_SUBMIT_TOKEN,
  ASYNC_SWITCH_PROFILE,
  ASYNC_SWITCH_TO_CHILD_PROFILE,
  ASYNC_SWITCH_TO_PARENT_PROFILE,
  ASYNC_UPDATE_ACCOUNT_CUSTOM_DATA,
  ASYNC_UPDATE_ACCOUNT_DATA,
  ASYNC_UPDATE_AUTHN_IDENTIFIER,
  ASYNC_UPDATE_PARENTAL_TIME_LIMIT,
  ASYNC_UPDATE_PASSWORD,
  ASYNC_UPDATE_PROFILE_BASIC,
  ASYNC_UPDATE_PROFILE_BOOKMARK,
  ASYNC_UPDATE_PROFILE_CUSTOM_DATA,
  ASYNC_UPDATE_PROFILE_LIVE_HISTORY,
  ASYNC_UPDATE_PROFILE_RECENTLY_WATCHED,
  ASYNC_UPDATE_PROFILE_WATCH_HISTORY,
  ASYNC_UPDATE_SHOW_FAVORITE,
  ASYNC_UPDATE_PODCAST_FAVORITE,
  ASYNC_UPDATE_TOPIC_FAVORITE,
  ASYNC_UPDATE_VIDEO_FAVORITE,
  ASYNC_UPDATE_PODCASTS_EPISODES_FAVORITE,
  ASYNC_VERIFY_GET_ACTIVATION_CODE,
  ASYNC_VERIFY_USER_CODE,
  CLEAR_CUSTOM_DATA_VIDEO_BOOKMARK,
  CLEAR_CUSTOM_DATA_RECENTLY_WATCHED,
  CLEAR_SESSION_DATA,
  CONFIRM_PASSWORD,
  CREATE_ACCOUNT,
  DELETE_SINGLE_CUSTOM_DATA_SHOWS_FAVORITE,
  DELETE_SINGLE_CUSTOM_DATA_PODCASTS_FAVORITE,
  DELETE_SINGLE_CUSTOM_DATA_TOPICS_FAVORITE,
  DELETE_SINGLE_CUSTOM_DATA_VIDEO_FAVORITE,
  DELETE_SINGLE_CUSTOM_DATA_PODCASTS_EPISODES_FAVORITE,
  DISCARD_ERROR,
  ENABLE_GUEST_MODE,
  GET_CURRENT_USER,
  IDENTITY_ACTION_PREFIX,
  IDENTITY_GUEST_ACTION_PREFIX,
  LINK_DEVICE,
  LOAD_PROFILES,
  LOGICAL_SWITCH_TO_CHILD_PROFILE,
  LOGIN,
  LOGOUT,
  RESET_STATE,
  SAVE_CUSTOM_DATA_SHOWS_FAVORITES,
  SAVE_CUSTOM_DATA_RECENTLY_WATCHED,
  SAVE_CUSTOM_DATA_PODCASTS_FAVORITES,
  SAVE_CUSTOM_DATA_TOPICS_FAVORITES,
  SAVE_CUSTOM_DATA_VIDEO_BOOKMARK,
  SAVE_CUSTOM_DATA_VIDEO_FAVORITES,
  SAVE_CUSTOM_DATA_PODCASTS_EPISODES_FAVORITES,
  SAVE_CUSTOM_DATA_DISPLAYED_MODALS,
  DELETE_CUSTOM_DATA_DISPLAYED_MODALS,
  SAVE_SESSION_DATA,
  SET_ACCESS_TOKEN,
  SET_ERROR,
  SET_GROUP_ID,
  SET_ONBOARDING_SUCCESS,
  SET_PARENT_ACCOUNT_INFO,
  SET_PARENT_CUSTOM_DATA,
  SET_PARENT_MODE,
  SET_PARENT_USER_ID,
  SET_PIN,
  SET_PKAT,
  SET_PROCESS_ID,
  SET_REFRESH_TOKEN,
  SET_VERIFICATION_EMAIL,
  UPDATE_ACCOUNT,
  UPDATE_PROFILE,
  ADD_SINGLE_CUSTOM_DATA_RECENTLY_WATCHED,
  WIPE_STORED_CUSTOM_DATA,
  SAVE_CUSTOM_DATA_VIDEOS_BLOCKED,
  SAVE_CUSTOM_DATA_SHOWS_BLOCKED,
  OVERRIDE_STORED_CUSTOM_DATA,
  ADD_SINGLE_CUSTOM_DATA_SHOW_BLOCKED,
  DELETE_SINGLE_CUSTOM_DATA_VIDEO_BLOCKED,
  ADD_SINGLE_CUSTOM_DATA_VIDEO_BLOCKED,
  DELETE_SINGLE_CUSTOM_DATA_SHOW_BLOCKED,
  actions, // only this one is of type object
  addAccountCustomData, // functions
  addProfile,
  addProfileBasic,
  addProfileCustomData,
  addShowFavoritesCustomData,
  addPodcastFavoritesCustomData,
  addTopicFavoritesCustomData,
  addUnsavedProfiles,
  addVideoFavoritesCustomData,
  addPodcastsEpisodesFavoritesCustomData,
  approveUserCode,
  checkActivationCode,
  checkParentalTimeLimit,
  clearCustomDataVideoBookmark,
  clearCustomDataRecentlyWatched,
  confirmPassword,
  confirmToken,
  createAccount,
  deleteAccount,
  deleteCustomDataVideoBookmark,
  deleteShowFavoritesCustomData,
  deletePodcastFavoritesCustomData,
  deleteTopicFavoritesCustomData,
  deleteVideoFavoritesCustomData,
  deletePodcastsEpisodesFavoritesCustomData,
  extendTimeAllowance,
  getNewActivationCode,
  getUser,
  getUserCall,
  guestMode,
  linkDevice,
  loadCustomDataShowsFavorites,
  loadCustomDataRecentlyWatched,
  loadCustomDataPodcastsFavorites,
  loadCustomDataTopicsFavorites,
  loadCustomDataVideoBookmark,
  loadCustomDataVideoFavorites,
  loadCustomDataPodcastsEpisodesFavorites,
  loadCustomDataDisplayedModals,
  loadCustomDataVideosBlocked,
  loadCustomDataShowsBlocked,
  loadGroup,
  loadProfileCustomData,
  loadProfiles,
  loadProfilesBasic,
  logicalSwitchToChildProfile,
  login,
  logout,
  onboardWithEmail,
  parentalTimeLimitHeartbeat,
  processStep,
  recoverPassword,
  removeProfile,
  saveCustomDataVideoBookmark,
  sessionEnd,
  sessionStart,
  submitToken,
  switchProfile,
  switchToChildProfile,
  switchToParentProfile,
  updateAccount,
  updateAccountCustomData,
  updateAccountData,
  updateAuthnIdentifier,
  updateParentalTimeLimit,
  updatePassword,
  updateProfile,
  updateProfileBasic,
  updateProfileBookmark,
  updateProfileCustomData,
  updateProfileLiveHistory,
  updateProfileRecentlyWatched,
  updateProfileWatchHistory,
  updateShowFavoriteById,
  updatePodcastFavoriteById,
  updateTopicFavoriteById,
  updateVideoFavoriteById,
  updateVideosBlockedById,
  updateShowsBlockedById,
  updatePodcastsEpisodesFavoriteById,
  verifyUserCode,
  updateCustomDataDisplayedModals,
  clearCustomDataDisplayedModals,
} = identityActions;
