import axios from 'axios';

const ACCEDO_ONE_URL = 'https://api.one.accedo.tv/';

const api = {};

api.getAllEntries = async sessionKey => {
  const url = `${ACCEDO_ONE_URL}content/entries`;
  const size = 50; // Max limit from Control API

  let response = await axios.get(url, {
    params: {
      sessionKey,
      size,
    },
  });

  let offset = 1;
  let { entries = [] } = response.data || {};
  const { total = 0 } = response.data?.pagination || {};

  while (total > offset * size) {
    /* eslint-disable-next-line no-await-in-loop */
    response = await axios.get(url, {
      params: {
        sessionKey,
        offset,
        size,
      },
    });

    entries = [...entries, ...(response.data?.entries || [])];
    offset += 1;
  }

  return entries;
};

api.getAllAssets = async sessionKey => {
  const url = `${ACCEDO_ONE_URL}asset`;

  const response = await axios.get(url, {
    params: {
      sessionKey,
    },
  });
  const assets = response.data;

  return assets;
};

api.getAllMetadata = async sessionKey => {
  const url = `${ACCEDO_ONE_URL}metadata`;

  const response = await axios.get(url, {
    params: {
      sessionKey,
    },
  });
  const metadata = response.data;

  return metadata;
};

api.getSensicalBenefits = async url => {
  const response = await axios.get(url);
  return response.data;
};

export default api;
