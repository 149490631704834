import { handleActions } from 'redux-actions';

import { actions } from './actions';

const initialState = {};

const dismiss = (state = initialState, { payload }) => {
  return {
    ...state,
    ...{
      [payload]: {
        ...state[payload],
        inProgress: false,
        hasError: false,
        errorMsg: '',
        uninitialized: true,
      },
    },
  };
};

const inProgress = (state = initialState, { payload }) => ({
  ...state,
  ...{
    [payload]: {
      ...state[payload],
      inProgress: true,
      hasError: false,
      errorMsg: '',
    },
  },
});

const success = (state = initialState, { payload }) => ({
  ...state,
  ...{
    [payload]: {
      ...state[payload],
      inProgress: false,
    },
  },
});

const failure = (state = initialState, { payload }) => ({
  ...state,
  ...{
    [payload.id]: {
      ...state[payload.id],
      inProgress: false,
      hasError: true,
      errorMsg: payload.errorMsg,
    },
  },
});

const reducer = handleActions(
  new Map([
    [actions.inProgress, inProgress],
    [actions.success, success],
    [actions.failure, failure],
    [actions.dismissAuthError, dismiss],
  ]),
  initialState,
);

export default reducer;
