import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { FocusDiv } from '@accedo/vdkweb-tv-ui';
import { focusManager } from '@accedo/vdkweb-navigation';
import { PLAYER } from '#/utils/navigationHelper';
import { getContainerData } from '#/redux/modules/brightcove/selectors';
import { getCurrentAgeMetadata } from '#/redux/modules/accedoOne/selectors';
import {
  getPlaylistIds,
  getPlayerVideoIndex,
  getPlayerContainerIndex,
  hasVideoEnded,
} from '#/redux/modules/appPlayer/selectors';
import { setVideoContent } from '#/redux/modules/appPlayer/actions';
import { clearAssetData } from '#/redux/modules/brightcove/actions';
import assembleCurrentVideo from '#/utils/assembleCurrentVideo';

import styles from './upNextVideo.scss';
import { videoAnalytics } from '#/config/constants';
import VideoCard from '#/components/VideoCard/VideoCard';

const { UP_NEXT_VIDEO } = PLAYER;

const UpNextVideo = ({ remainingTimeinSec, fromHome, showUpNext, withUI }) => {
  const dispatch = useDispatch();
  const playlistIds = useSelector(getPlaylistIds);
  const videoIndex = useSelector(getPlayerVideoIndex);
  const videoItems = useSelector(state => getContainerData(state, playlistIds));
  const videoContainerIndex = useSelector(getPlayerContainerIndex);
  const currentPlaylistIndex = playlistIds[videoContainerIndex];
  const selectedAgeMetadata = useSelector(getCurrentAgeMetadata);
  useEffect(() => {
    for (let i = 0; i < videoItems[currentPlaylistIndex]?.length; i += 1) {
      if (JSON.stringify(videoItems[currentPlaylistIndex]?.[i]) === '{}') {
        videoItems[currentPlaylistIndex].splice(i, 1);
      }
    }
  }, [videoItems, currentPlaylistIndex]);
  const upNextVideoItem =
    videoItems[currentPlaylistIndex]?.[videoIndex + 1] || {};
  const isVideoEnded = useSelector(hasVideoEnded);
  const returnFocus = focusManager.getCurrentFocus();

  const getTimerText = remainingTime =>
    `Up next in ${remainingTime.toFixed()} seconds`;

  useEffect(() => {
    focusManager.changeFocus(showUpNext ? UP_NEXT_VIDEO : returnFocus);
  }, [showUpNext]);

  // Effect to trigger next video while on background player
  useEffect(() => {
    if (fromHome && !withUI && isVideoEnded && upNextVideoItem) {
      dispatch(
        setVideoContent({
          playlists: [playlistIds],
          currentPlaylistIndex: videoContainerIndex,
          currentVideoIndex: videoIndex,
          currentVideo: assembleCurrentVideo(upNextVideoItem),
          initiation: videoAnalytics.initiation.AUTO,
        }),
      );
    }
  }, [isVideoEnded, upNextVideoItem]);

  return showUpNext && upNextVideoItem && remainingTimeinSec > 0 ? (
    <>
      <div className={styles.upNextOverlay} />
      <FocusDiv className={styles.upNextContainer}>
        <div className={styles.upNextTimer}>
          {getTimerText(remainingTimeinSec)}
        </div>
        <VideoCard
          nav={{ id: UP_NEXT_VIDEO }}
          referenceId={upNextVideoItem?.reference_id}
          imgUrl={
            upNextVideoItem?.custom_fields?.episode_key_art_horizontal ||
            upNextVideoItem?.custom_fields?.episode_key_art_square ||
            upNextVideoItem?.thumbnail
          }
          title={upNextVideoItem?.name}
          showTitle={upNextVideoItem?.custom_fields?.subtitle}
          duration={upNextVideoItem?.duration}
          roundedCorners={selectedAgeMetadata?.tileRounded}
          classes={{
            videoCard: styles.videoCard,
          }}
          onClick={() => {
            dispatch(clearAssetData());
            dispatch(
              setVideoContent({
                playlists: [currentPlaylistIndex],
                currentPlaylistIndex: 0,
                currentVideoIndex: videoIndex + 1,
                currentVideo: assembleCurrentVideo(upNextVideoItem),
                initiation: videoAnalytics.initiation.MANUAL,
              }),
            );
          }}
          podcastShow={
            upNextVideoItem?.custom_fields?.media_type?.toLowerCase() ===
            'podcast'
          }
        />
      </FocusDiv>
    </>
  ) : null;
};

UpNextVideo.propTypes = {
  remainingTimeinSec: PropTypes.number,
  fromHome: PropTypes.bool,
  showUpNext: PropTypes.bool,
  withUI: PropTypes.bool,
};

export default UpNextVideo;
