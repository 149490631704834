import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { withFocus, focusManager } from '@accedo/vdkweb-navigation';
import { getDurationLabel } from '#/utils/accessibility';
import getVw from '#/utils/getVw';
import imgixUrl from '#/utils/imgix';
import { videoTileTimeFormatter } from '#/utils/time';
import { getCurrentVideo } from '#/redux/modules/appPlayer/selectors';
import { getCurrentProfileBookmarkById } from '#/redux/modules/onboarding/selectors';
import { actions as modalActions } from '#/redux/modules/modal/actions';
import {
  getContentBlocking,
  getPodcastsControls,
} from '#/redux/modules/accedoOne/selectors';
import { imgixTileResizeParameters, videoAnalytics } from '#/config/constants';
import { FocusDiv } from '#/widgets/ui';

import ProgressBar from '#/components/ProgressBar/ProgressBar';
import TilePlayIcon from '#/static/images/icons/tile-play.png';
import HideIcon from '#/static/images/icons/hide.svg';
import PodcastIcon from '#/static/images/icons/PodcastIcon.png';
import styles from './videocard.scss';
import { isWorkstation } from '#/utils/componentStyleConfig';

// still need an override to do a backpress if same content as previous when going to shows page
const Container = ({
  nav,
  children,
  className,
  onClick,
  onMouseOver,
  style,
  history,
  fromDetails,
  goBack,
  ariaLabel,
  replace,
  blocked,
  livestreamTileEntryData = null,
}) => {
  const dispatch = useDispatch();
  const contentBlocking = useSelector(getContentBlocking);
  const { blockedVideoText } = contentBlocking;
  if (goBack) {
    return (
      <div
        tabIndex={0}
        className={`${className} ${nav.isFocused ? styles.focused : ''}`}
        onMouseOver={onMouseOver}
        style={style}
        onClick={() => history.goBack()}
      >
        {children}
      </div>
    );
  }

  return !isWorkstation() && history.location.pathname.endsWith('home') ? (
    <FocusDiv
      className={`${className} ${nav.isFocused ? styles.focused : ''}`}
      style={style}
      nav={nav}
      ariaLabel={ariaLabel}
    >
      <div
        aria-label="hidden"
        onClick={() => onClick(videoAnalytics.initiation.MANUAL)}
      >
        {children}
      </div>
    </FocusDiv>
  ) : (
    <FocusDiv
      nav={nav}
      ariaLabel={ariaLabel}
      className={`${className} ${nav.isFocused ? styles.focused : ''}`}
      style={style}
    >
      {blocked ? (
        <>
          <div
            className={styles.blockedOverlay}
            onClick={() => {
              dispatch(
                modalActions.openModal({
                  modalId: 'BlockedContentModal',
                }),
              );
            }}
          >
            <img src={HideIcon} className={styles.hideIcon} alt="Hide Icon" />
            <span>{blockedVideoText}</span>
          </div>
          <div aria-label="hidden">{children}</div>
        </>
      ) : (
        <Link
          tabIndex={!isWorkstation() ? -1 : 0}
          to={{
            pathname: livestreamTileEntryData ? '/main/livetv' : '/player',
            state: {
              fromDetails: fromDetails || history.location.state?.fromDetails,
            },
          }}
          onClick={() => onClick(videoAnalytics.initiation.MANUAL)}
          onMouseOver={onMouseOver}
          replace={replace}
        >
          <div aria-label="hidden">{children}</div>
        </Link>
      )}
    </FocusDiv>
  );
};

const FocusContainer = withFocus(withRouter(Container));

const MovieCard = ({
  nav = {},
  videoId,
  referenceId = '',
  roundedCorners = true,
  imgUrl,
  title = '',
  showTitle = '',
  seasonInfo = '',
  duration,
  classes,
  onClick,
  fromDetails = false,
  goBack,
  onItemFocus,
  ariaLabel,
  podcastShow = false,
  replace = false,
  blocked = false,
  livestreamTileEntryData = null,
  sponsoredTileEntryData = null,
}) => {
  const currentVideo = useSelector(state => getCurrentVideo(state));
  const { imgixParamsForCover } = useSelector(getPodcastsControls);
  // const contentBlocking = useSelector(getContentBlocking);
  // const { blockedVideoText } = contentBlocking;
  const bookmarkProgress =
    useSelector(state => getCurrentProfileBookmarkById(state, referenceId))
      ?.progress || 0;
  const imgSrc = useMemo(() => {
    // const gradient = 'linear-gradient(135deg, transparent, rgba(0,0,0,0.6))';
    return `url(${
      !podcastShow
        ? imgixUrl(imgUrl, imgixTileResizeParameters.VIDEOS)
        : `${imgUrl}?${imgixParamsForCover}`
    })`;
  }, [imgUrl]);
  const onFocus = useCallback(() => {
    if (onItemFocus) {
      onItemFocus(nav.id);
    }
    focusManager.changeFocus(nav.id);
  }, []);

  // TODO: add 'now playing' tag on video card for TV
  return (
    <FocusContainer
      fromDetails={fromDetails}
      nav={nav}
      className={classNames(styles.card, classes?.videoCard, {
        [styles.isTv]: !isWorkstation(),
      })}
      livestreamTileEntryData={livestreamTileEntryData}
      sponsoredTileEntryData={sponsoredTileEntryData}
      onClick={() => {
        onClick();
      }}
      onMouseOver={!isWorkstation() ? () => {} : onFocus}
      onFocus={onFocus}
      style={{
        borderRadius: roundedCorners ? getVw(24) : '0px',
        outline: 'none',
      }}
      goBack={goBack}
      ariaLabel={
        ariaLabel ||
        `${showTitle} ${title} ${seasonInfo} ${getDurationLabel(duration)}`
      }
      replace={replace}
      blocked={blocked}
    >
      {(livestreamTileEntryData || sponsoredTileEntryData) && (
        <div className={styles.liveContainer}>
          {sponsoredTileEntryData && (
            <div className={styles.sponsoredTile}>
              <p>SPONSORED</p>
            </div>
          )}
          {livestreamTileEntryData && (
            <div className={styles.liveTile}>
              <p>LIVE</p>
            </div>
          )}
        </div>
      )}
      <div
        className={classNames(
          styles.cardImageContainer,
          classes?.videoCardImage,
        )}
      >
        <div
          style={{ background: imgSrc }}
          className={classNames(styles.cardImage, classes?.videoCardImage)}
          alt={title}
        >
          {podcastShow && (
            <img
              src={PodcastIcon}
              className={styles.podcastIcon}
              alt="Podcast Icon"
            />
          )}
          <img
            className={styles.cardImageMobile}
            src={TilePlayIcon}
            alt="Play"
          />
        </div>
      </div>
      <div
        className={classNames(styles.cardInfoContainer, classes?.videoCardInfo)}
      >
        {bookmarkProgress && duration ? (
          <ProgressBar durationMs={duration} progressMs={bookmarkProgress} />
        ) : null}
        <div className={styles.episodeInfoContainer}>
          {seasonInfo && (
            <>
              <p>{seasonInfo}</p>
              <p>{' - '}</p>
            </>
          )}
          {title && <p>{title}</p>}
        </div>
        {showTitle && (
          <div className={styles.titleContainer}>
            <p>{showTitle.toUpperCase()}</p>
          </div>
        )}
      </div>
      {duration && (
        <div className={styles.durationContainer}>
          <p>{videoTileTimeFormatter(duration)}</p>
        </div>
      )}
      {currentVideo.videoId === videoId && !isWorkstation() && (
        <div
          className={`${styles.playingStatus} ${classes?.videoPlayingStatus}`}
        >
          <p>Now Playing</p>
        </div>
      )}
    </FocusContainer>
  );
};

Container.propTypes = {
  nav: PropTypes.object,
  children: PropTypes.any,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  style: PropTypes.object,
  history: PropTypes.object,
  fromDetails: PropTypes.bool,
  goBack: PropTypes.bool,
  ariaLabel: PropTypes.bool,
  replace: PropTypes.bool,
  blocked: PropTypes.bool,
  livestreamTileEntryData: PropTypes.object,
};

MovieCard.propTypes = {
  nav: PropTypes.object,
  videoId: PropTypes.number,
  referenceId: PropTypes.string,
  imgUrl: PropTypes.string,
  title: PropTypes.string,
  showTitle: PropTypes.string,
  seasonInfo: PropTypes.string,
  duration: PropTypes.number,
  roundedCorners: PropTypes.bool,
  classes: PropTypes.object,
  onClick: PropTypes.func,
  fromDetails: PropTypes.bool,
  goBack: PropTypes.bool,
  onItemFocus: PropTypes.func,
  ariaLabel: PropTypes.string,
  podcastShow: PropTypes.bool,
  replace: PropTypes.bool,
  blocked: PropTypes.bool,
  livestreamTileEntryData: PropTypes.object,
  sponsoredTileEntryData: PropTypes.object,
};

export default withRouter(MovieCard);
