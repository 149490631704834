import 'core-js/stable'; // For general polyfills and shims
import 'whatwg-fetch'; // For fetch polyfill
/* eslint-disable-next-line no-unused-vars */
import { Promise } from 'core-js/features/promise';

// XDK4 Configuration for this app
import xdkConfigStore from '@accedo/xdk-config';
import config from './xdk';

// XDK4 Common Config Store
xdkConfigStore.load(config);
