import React, { useMemo, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Page, FocusDiv } from '@accedo/vdkweb-tv-ui';
import { focusManager } from '@accedo/vdkweb-navigation';
import classNames from 'classnames';
import {
  FSCollectionSwimlaneFactory,
  TopicSwimlaneFactory,
  PlaylistSwimlaneFactory,
} from '#/components/SwimlaneFactory';
import VideoCard from '#/components/VideoCard/VideoCard';
import { toggleLoginSpinner } from '#/redux/modules/spinner/actions';
import { setVideoContent } from '#/redux/modules/appPlayer/actions';
import {
  videoAnalytics,
  VIDEO_FAVORITES_PLAYLIST_ID,
  PODCASTS_EPISODES_FAVORITES_PLAYLIST_ID,
  FAVORITES_SCREEN,
  mainScreenNames,
} from '#/config/constants';
import { myStuffTexts } from '#/config/strings';
import { saveContainerData } from '#/redux/modules/brightcove/actions';
import { getCurrentProfile } from '#/redux/modules/onboarding/selectors';
import {
  getCustomDataShowsFavorites,
  getCustomDataPodcastsFavorites,
  getCustomDataTopicsFavorites,
  getCustomDataVideoFavorites,
  getCustomDataPodcastsEpisodesFavorites,
  getCustomDataVideosBlocked,
  getCustomDataShowsBlocked,
} from '#/redux/modules/identity/selectors';

import {
  loadCustomDataShowsFavorites,
  loadCustomDataRecentlyWatched,
  loadCustomDataPodcastsFavorites,
  loadCustomDataTopicsFavorites,
  loadCustomDataVideoFavorites,
  loadCustomDataPodcastsEpisodesFavorites,
} from '#/redux/modules/identity/actions';

import { getCurrentAgeMetadata } from '#/redux/modules/accedoOne/selectors';
import { getVerticalNav, FAVORITES, HEADER } from '#/utils/navigationHelper';

import assembleCurrentVideo from '#/utils/assembleCurrentVideo';

import styles from './Favorites.scss';

import { isWorkstation, isMobile } from '#/utils/componentStyleConfig';
import { setSpeech } from '#/utils/accessibility';
import Scroll from '#/components/Scroll/Scroll';
import {
  TV_CONTENT_HEADER,
  TVContentHeader,
} from '#/components/TVContentHeader/TVContentHeader';
import useUpdateScroll from '#/hooks/useUpdateScroll';
import GuestFavorite from './GuestFavorite/GuestFavorite';
import { isGuestMode } from '#/redux/modules/user/selectors';
import { isContentBlocked } from '../../utils/isContentBlocked';

const {
  PAGE,
  CONTAINER,
  FAV_SHOWS_CONTAINER,
  FAV_PODCASTS_CONTAINER,
  FAV_TOPICS_CONTAINER,
  FAV_VIDEOS_CONTAINER,
  FAV_PODCASTS_EPISODES_CONTAINER,
} = FAVORITES;

const navMap = {
  PAGE: {
    id: PAGE,
    forwardFocus: CONTAINER,
  },
  CONTAINER: {
    id: CONTAINER,
    nextleft: HEADER.MENU_ITEMS.FAVORITES,
    useLastFocus: true,
  },
};

const FavoritesPage = () => {
  const dispatch = useDispatch();
  const currentProfile = useSelector(getCurrentProfile);
  const showsFavoritesContainer = {
    displayText: FAVORITES_SCREEN.SHOWS,
  };
  const podcastsFavoritesContainer = {
    displayText: FAVORITES_SCREEN.PODCASTS,
  };
  const videoFavoritesContainer = {
    displayText: FAVORITES_SCREEN.VIDEOS,
  };
  const podcastsEpisodesFavoritesContainer = {
    displayText: FAVORITES_SCREEN.PODCASTS_EPISODES,
  };
  const topicsFavoritesContainer = {
    displayText: FAVORITES_SCREEN.CHANNELS,
  };

  const customDataShowsBlocked = useSelector(getCustomDataShowsBlocked);
  const customDataVideosBlocked = useSelector(getCustomDataVideosBlocked);
  const podcastsFavorites = useSelector(getCustomDataPodcastsFavorites);
  const showsFavorites = useSelector(getCustomDataShowsFavorites);
  const topicsFavorites = useSelector(getCustomDataTopicsFavorites);
  const videoFavorites = useSelector(getCustomDataVideoFavorites);
  const isPodcast = true;
  const podcastsEpisodesFavorites = useSelector(
    getCustomDataPodcastsEpisodesFavorites,
  );
  const selectedAgeMetadata = useSelector(getCurrentAgeMetadata);

  const [focusedElement, updateScroll] = useUpdateScroll(navMap.PAGE);

  const getSwimlaneNav = useCallback(
    id => {
      const containers = [
        showsFavorites?.length ? FAV_SHOWS_CONTAINER : null,
        podcastsFavorites?.length ? FAV_PODCASTS_CONTAINER : null,
        topicsFavorites?.length ? FAV_TOPICS_CONTAINER : null,
        videoFavorites?.length ? FAV_VIDEOS_CONTAINER : null,
        podcastsEpisodesFavorites?.length
          ? FAV_PODCASTS_EPISODES_CONTAINER
          : null,
      ];
      const swimlanes = containers
        .filter(swimlane => swimlane)
        .map(favorite => favorite);
      const navIds = getVerticalNav(
        swimlanes.map(item => `${item}`),
        {
          parent: CONTAINER,
          useLastFocus: true,
        },
      );
      const [topContainer] = Object.keys(navIds);
      navMap.CONTAINER = { ...navMap.CONTAINER, forwardFocus: topContainer };
      return navIds[id];
    },
    [
      showsFavorites,
      podcastsFavorites,
      topicsFavorites,
      videoFavorites,
      podcastsEpisodesFavorites,
    ],
  );

  useEffect(() => {
    dispatch(loadCustomDataShowsFavorites());
    dispatch(loadCustomDataRecentlyWatched());
    dispatch(loadCustomDataPodcastsFavorites());
    dispatch(loadCustomDataTopicsFavorites());
    dispatch(loadCustomDataVideoFavorites());
    dispatch(loadCustomDataPodcastsEpisodesFavorites());
  }, []);

  useEffect(() => {
    if (
      showsFavorites &&
      videoFavorites &&
      podcastsEpisodesFavorites &&
      topicsFavorites &&
      podcastsFavorites
    ) {
      requestAnimationFrame(() => {
        dispatch(toggleLoginSpinner(false));
      });
    }
  }, [
    showsFavorites,
    podcastsFavorites,
    videoFavorites,
    podcastsEpisodesFavorites,
    topicsFavorites,
  ]);

  useEffect(() => {
    if (!focusedElement) {
      navMap.CONTAINER.forwardFocus = undefined;
    }

    !isWorkstation() &&
      focusManager.changeFocus(
        focusedElement ? PAGE : HEADER.MENU_ITEMS.FAVORITES,
      );
  }, [focusedElement]);

  const onNoFavoritesContainerFocus = useCallback(() => {
    if (!isWorkstation()) {
      setSpeech(
        `${myStuffTexts.NO_FAVORITES_YET} ${myStuffTexts.NO_FAVORITES_DESCRIPTION}`,
      );
    }
  }, []);

  const showsFavoritesComp = useMemo(() => {
    if (!showsFavorites?.length) {
      return null;
    }
    return (
      <FSCollectionSwimlaneFactory
        collectionItems={showsFavorites}
        nav={getSwimlaneNav(FAV_SHOWS_CONTAINER)}
        key="id-shows-fav-container"
        ageTheme={selectedAgeMetadata}
        title={FAVORITES_SCREEN.SHOWS}
        enableSeeAll={!isWorkstation()}
        onFocus={updateScroll}
      />
    );
  }, [showsFavoritesContainer, showsFavorites, selectedAgeMetadata]);

  const podcastsFavoritesComp = useMemo(() => {
    if (!podcastsFavorites?.length) {
      return null;
    }
    return (
      <FSCollectionSwimlaneFactory
        collectionItems={podcastsFavorites}
        nav={getSwimlaneNav(FAV_PODCASTS_CONTAINER)}
        key="id-podcasts-fav-container"
        ageTheme={selectedAgeMetadata}
        title={FAVORITES_SCREEN.PODCASTS}
        enableSeeAll={!isWorkstation()}
        onFocus={updateScroll}
        enablePodcasts={isPodcast}
      />
    );
  }, [podcastsFavoritesContainer, podcastsFavorites, selectedAgeMetadata]);

  const topicsFavoritesComp = useMemo(() => {
    if (!topicsFavorites?.length) {
      return null;
    }

    return (
      <TopicSwimlaneFactory
        topics={topicsFavorites}
        nav={getSwimlaneNav(FAV_TOPICS_CONTAINER)}
        key="id-topics-fav-container"
        ageTheme={selectedAgeMetadata}
        title={FAVORITES_SCREEN.CHANNELS}
        enableSeeAll={!isWorkstation()}
        onFocus={updateScroll}
      />
    );
  }, [topicsFavoritesContainer, topicsFavorites, selectedAgeMetadata]);

  const videoFavoritesComp = useMemo(() => {
    if (!videoFavorites?.length) {
      return null;
    }

    if (!isWorkstation()) {
      videoFavorites.forEach((item, index) => {
        item.onClick = async () => {
          await dispatch(
            saveContainerData({
              [VIDEO_FAVORITES_PLAYLIST_ID]: videoFavorites,
            }),
          );

          dispatch(
            setVideoContent({
              playlists: [VIDEO_FAVORITES_PLAYLIST_ID],
              currentPlaylistIndex: 0,
              currentVideoIndex: index,
              currentVideo: assembleCurrentVideo(item),
              initiation: videoAnalytics.initiation.MANUAL,
            }),
          );
        };
      });

      return (
        <PlaylistSwimlaneFactory
          videos={videoFavorites}
          nav={getSwimlaneNav(FAV_VIDEOS_CONTAINER)}
          key="id-topics-fav-container"
          ageTheme={selectedAgeMetadata}
          title={FAVORITES_SCREEN.VIDEOS}
          enableSeeAll={!isWorkstation()}
          onFocus={updateScroll}
        />
      );
    }
    return (
      <div className={styles.videoContainer}>
        <div className={styles.videoTitleContainer}>
          <p className={styles.videoTitleText}>{FAVORITES_SCREEN.VIDEOS}</p>
        </div>
        <div className={styles.mobileGridContainer}>
          {videoFavorites.map((item, index) => (
            <VideoCard
              onItemFocus={updateScroll}
              key={index}
              imgUrl={
                item.custom_fields?.episode_key_art_horizontal || item.thumbnail
              }
              title={item.name}
              referenceId={item.reference_id}
              showTitle={item.custom_fields?.subtitle}
              duration={item.duration}
              classes={{
                videoCardInfo: styles.videoCardInfo,
                videoCardProgress: styles.videoCardProgress,
                videoCardImage: styles.videoCardImage,
                videoCard: styles.videoCard,
              }}
              blocked={isContentBlocked(currentProfile, item)}
              roundedCorners={selectedAgeMetadata?.tileRounded}
              onClick={async () => {
                await dispatch(
                  saveContainerData({
                    [VIDEO_FAVORITES_PLAYLIST_ID]: videoFavorites,
                  }),
                );

                dispatch(
                  setVideoContent({
                    playlists: [VIDEO_FAVORITES_PLAYLIST_ID],
                    currentPlaylistIndex: 0,
                    currentVideoIndex: index,
                    currentVideo: assembleCurrentVideo(item),
                    initiation: videoAnalytics.initiation.MANUAL,
                  }),
                );
              }}
            />
          ))}
        </div>
      </div>
    );
  }, [
    videoFavoritesContainer,
    videoFavorites,
    selectedAgeMetadata,
    customDataShowsBlocked,
    customDataVideosBlocked,
  ]);

  const podcastsEpisodesFavoritesComp = useMemo(() => {
    if (!podcastsEpisodesFavorites?.length) {
      return null;
    }

    if (!isWorkstation()) {
      podcastsEpisodesFavorites.forEach((item, index) => {
        item.onClick = async () => {
          await dispatch(
            saveContainerData({
              [PODCASTS_EPISODES_FAVORITES_PLAYLIST_ID]: podcastsEpisodesFavorites,
            }),
          );

          dispatch(
            setVideoContent({
              playlists: [PODCASTS_EPISODES_FAVORITES_PLAYLIST_ID],
              currentPlaylistIndex: 0,
              currentVideoIndex: index,
              currentVideo: assembleCurrentVideo(item, isPodcast),
              initiation: videoAnalytics.initiation.MANUAL,
            }),
          );
        };
      });

      return (
        <PlaylistSwimlaneFactory
          videos={podcastsEpisodesFavorites}
          nav={getSwimlaneNav(FAV_PODCASTS_EPISODES_CONTAINER)}
          key="id-topics-fav-container"
          ageTheme={selectedAgeMetadata}
          title={FAVORITES_SCREEN.PODCASTS_EPISODES}
          enableSeeAll={!isWorkstation()}
          onFocus={updateScroll}
          enablePodcasts={isPodcast}
        />
      );
    }
    return (
      <div className={styles.videoContainer}>
        <div className={styles.videoTitleContainer}>
          <p className={styles.videoTitleText}>
            {FAVORITES_SCREEN.PODCASTS_EPISODES}
          </p>
        </div>
        <div className={styles.mobileGridContainer}>
          {podcastsEpisodesFavorites.map((item, index) => (
            <VideoCard
              onItemFocus={updateScroll}
              key={index}
              imgUrl={
                item.custom_fields?.episode_key_art_horizontal ||
                item.custom_fields?.episode_key_art_square ||
                item.thumbnail
              }
              title={item.name}
              referenceId={item.reference_id}
              showTitle={item.custom_fields?.subtitle}
              duration={item.duration}
              classes={{
                videoCardInfo: styles.videoCardInfo,
                videoCardProgress: styles.videoCardProgress,
                videoCard: styles.videoCardPodcast,
                videoCardImage: styles.podcastEpisodeImageMobile,
              }}
              roundedCorners={selectedAgeMetadata?.tileRounded}
              podcastShow={isPodcast}
              blocked={isContentBlocked(currentProfile, item)}
              onClick={async () => {
                await dispatch(
                  saveContainerData({
                    [PODCASTS_EPISODES_FAVORITES_PLAYLIST_ID]: podcastsEpisodesFavorites,
                  }),
                );

                dispatch(
                  setVideoContent({
                    playlists: [PODCASTS_EPISODES_FAVORITES_PLAYLIST_ID],
                    currentPlaylistIndex: 0,
                    currentVideoIndex: index,
                    currentVideo: assembleCurrentVideo(item, isPodcast),
                    initiation: videoAnalytics.initiation.MANUAL,
                  }),
                );
              }}
            />
          ))}
        </div>
      </div>
    );
  }, [
    podcastsEpisodesFavoritesContainer,
    podcastsEpisodesFavorites,
    selectedAgeMetadata,
  ]);

  return (
    <div
      className={classNames(styles.scrollContainer, {
        [styles.isTv]: !isWorkstation(),
      })}
    >
      <Page className={styles.favoritesContainer} nav={navMap.PAGE}>
        <TVContentHeader
          visible={!isWorkstation()}
          title={mainScreenNames.favorites}
        />
        <Scroll
          enabled={!isWorkstation()}
          focusedElement={focusedElement}
          focusScrollPush={180}
          headerHeightOverride={
            document.getElementById(TV_CONTENT_HEADER)?.offsetHeight
          }
        >
          {showsFavorites?.length > 0 ||
          podcastsFavorites?.length > 0 ||
          topicsFavorites?.length > 0 ||
          podcastsEpisodesFavorites?.length > 0 ||
          videoFavorites?.length > 0 ? (
            <FocusDiv nav={navMap.CONTAINER} className={styles.compContainer}>
              <div className={styles.favShows}>{showsFavoritesComp}</div>
              <div className={styles.favShows}>{podcastsFavoritesComp}</div>
              <div className={styles.favShows}>{topicsFavoritesComp}</div>
              <div className={styles.favShows}>{videoFavoritesComp}</div>
              <div className={styles.favShows}>
                {podcastsEpisodesFavoritesComp}
              </div>
            </FocusDiv>
          ) : (
            <FocusDiv
              nav={navMap.CONTAINER}
              className={styles.noFavoritesContainer}
              onFocus={onNoFavoritesContainerFocus}
            >
              <div className={styles.noFavoritesTitle}>
                {myStuffTexts.NO_FAVORITES_YET}
              </div>
              <div className={styles.noFavoritesDescription}>
                {myStuffTexts.NO_FAVORITES_DESCRIPTION}
              </div>
            </FocusDiv>
          )}
          {isMobile() && <div className={styles.bottomSpacerMobile} />}
        </Scroll>
      </Page>
    </div>
  );
};

const Favorites = () => {
  const guestMode = useSelector(isGuestMode);

  const FavoriteToRender = guestMode ? GuestFavorite : FavoritesPage;

  return <FavoriteToRender />;
};

export default Favorites;
