import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import RedirectWithQuery from '#/components/App/RedirectWithQuery';

import {
  getTvCode,
  getCurrentProfile,
} from '#/redux/modules/onboarding/selectors';
import { isUserLogged } from '#/redux/modules/user/selectors';

import routes from '#/utils/routes';

// Component to guide user to activate page, if code exists, or select a profile
const LoginRedirect = () => {
  const savedTvCode = useSelector(getTvCode);
  const currentProfile = useSelector(getCurrentProfile);
  const isUserLoggedIn = useSelector(isUserLogged);

  const redirect = useMemo(() => {
    if (!isUserLoggedIn) {
      return null;
    }

    const profilesRedirect = currentProfile ? (
      <RedirectWithQuery push to={routes.HOME} />
    ) : (
      <RedirectWithQuery push to={routes.PROFILE_SELECTION} />
    );
    return savedTvCode ? (
      <RedirectWithQuery push to={routes.ACTIVATE_COMPLETE} />
    ) : (
      profilesRedirect
    );
  }, [isUserLoggedIn, savedTvCode, currentProfile]);

  return <>{redirect}</>;
};

export default LoginRedirect;
