import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import LoginRedirect from '../LoginRedirect/LoginRedirect';
import { Button, StepIndicator } from '#/widgets/ui';
import { authScreenNames } from '#/config/constants';
import Skiddle from '#/static/images/icons/skiddle-green.svg';
import GreenBackground from '#/static/images/background-images/Green.svg';

import {
  getEmailVerified,
  getLinkExpired,
  getAccount,
  getTvCode,
} from '#/redux/modules/onboarding/selectors';
import { login } from '#/redux/modules/identity/actions';
import { getPkat } from '#/redux/modules/identity/selectors';
import {
  sendScreenView,
  sendRegistrationComplete,
} from '#/redux/modules/firestore/actions';
import routes, { authFlowRoutes } from '#/utils/routes';
import authStyles from '../authflow.scss';
import styles from './emailverification.scss';

import { getApiIdentityEndpoints } from '#/redux/modules/accedoOne/selectors';
import { actions as onboardingActions } from '#/redux/modules/onboarding/actions';

import { SecureRequest } from '#/services/vindicia/SecureRequest.tsx';

const { setEmailVerified } = onboardingActions;

const LinkExpiredPage = ({ resendEmail }) => {
  return (
    <div
      className={cx(authStyles.authFlow, styles.emailVerification)}
      style={{ backgroundImage: `url(${GreenBackground})` }}
    >
      <div className={styles.content}>
        <img className={styles.logo} src={Skiddle} alt="Logo" />
        <div className={authStyles.pageDescription}>
          <h1 className={authStyles.title}>Link expired</h1>
          <p>
            The previous email sent for verification has expired after 48 hours.
          </p>
        </div>
        <div className={authStyles.buttonsGroup}>
          <Button onClick={resendEmail}>Resend Email</Button>
          <div>
            <Link to={authFlowRoutes.ACCOUNT_CREATION}>
              Change email address
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
LinkExpiredPage.propTypes = {
  resendEmail: PropTypes.func,
};

const EmailVerifiedPage = () => {
  const dispatch = useDispatch();
  const account = useSelector(getAccount);

  useEffect(() => {
    dispatch(sendRegistrationComplete());
    dispatch(sendScreenView(authScreenNames.EMAIL_VERIFICATION_COMPLETE));
  }, []);

  return (
    <div
      className={cx(authStyles.authFlow, styles.emailVerification)}
      style={{ backgroundImage: `url(${GreenBackground})` }}
    >
      <LoginRedirect />
      <div className={styles.content}>
        <img className={styles.logo} src={Skiddle} alt="Logo" />
        <div className={authStyles.pageDescription}>
          <h1 className={authStyles.title}>Email verified</h1>
          <p>Great You're ready to start watching.</p>
        </div>
        <Link to="#0" onClick={() => dispatch(login(account))}>
          <Button>Start watching</Button>
        </Link>
      </div>
    </div>
  );
};

const EmailConfirmation = ({ resendEmail }) => {
  const dispatch = useDispatch();
  const account = useSelector(getAccount);
  const tvCode = useSelector(state => getTvCode(state));

  useEffect(() => {
    dispatch(sendScreenView(authScreenNames.EMAIL_VERIFICATION));
  }, []);

  return (
    <div
      className={cx(
        authStyles.authFlow,
        authStyles.onboardFlow,
        styles.emailVerification,
      )}
      style={{ backgroundImage: `url(${GreenBackground})` }}
    >
      <StepIndicator step="3b" />
      <div className={authStyles.pageDescription}>
        <h1 className={authStyles.title}>
          Almost done... Confirm your account
        </h1>
        <p>
          We sent an email to{'\n'}
          <b>{account.email}</b>
          {'\n\n'}
          Check your email and click on the link to continue. The link will
          expire in 48 hours.
        </p>
      </div>
      <div className={cx(authStyles.buttonsGroup, styles.resendButtons)}>
        <div>
          <Link onClick={resendEmail} to={authFlowRoutes.EMAIL_VERIFICATION}>
            Resend email
          </Link>
          {'\t'}
          <Link to={authFlowRoutes.ACCOUNT_CREATION}>Change email</Link>
        </div>
        <div>
          <Link to={tvCode ? routes.ACTIVATE_COMPLETE : routes.HOME}>
            <Button className="static" selected={false} disabled>
              Start watching
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
EmailConfirmation.propTypes = {
  resendEmail: PropTypes.func,
};

const EmailVerification = () => {
  const dispatch = useDispatch();
  const emailVerified = useSelector(getEmailVerified);
  const linkExpired = useSelector(getLinkExpired);
  const identityEnpoints = useSelector(state => getApiIdentityEndpoints(state));
  const account = useSelector(getAccount);
  const pkat = useSelector(getPkat);

  const checkIfEmailVerified = useRef();

  // TODO: Refactor these calls to store
  if (pkat && !checkIfEmailVerified?.current) {
    checkIfEmailVerified.current = setInterval(async () => {
      const secureRequests = new SecureRequest(identityEnpoints);
      const response = await secureRequests.performSecureRequest('login', {
        authnIdentifier: account.email,
        credential: account.credential,
      });
      if (response.userAuthenticated) {
        dispatch(setEmailVerified(true));
      }
    }, 5000);
  }

  useEffect(() => {
    if (emailVerified) {
      clearInterval(checkIfEmailVerified.current);
    }
  }, [emailVerified]);

  const resendEmail = async () => {
    const secureRequests = new SecureRequest(identityEnpoints);
    await secureRequests.performSecureRequest(
      'resendEmail',
      {},
      `?authnIdentifier=${account.email}&pkat=${pkat}`,
    );
  };

  if (emailVerified) {
    return <EmailVerifiedPage />;
  }
  if (linkExpired) {
    return <LinkExpiredPage resendEmail={resendEmail} />;
  }
  return <EmailConfirmation resendEmail={resendEmail} />;
};

export default EmailVerification;
